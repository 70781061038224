import classNames from "classnames";
import { FiAlertTriangle } from "react-icons/fi";
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";
import { MdInfoOutline, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastBox from "./ToastBox";
import styles from "./ToastBox.module.scss";

interface CloseButtonProps {
  closeToast?: () => void;
}
const CloseButton: React.FC<CloseButtonProps> = ({ closeToast }) => (
  <button
    onClick={closeToast}
    className={classNames("btn btn-empty", styles["btn-close"])}
  >
    <MdClose />
  </button>
);

const useToast = () => {
  const notify = (
    type: "info" | "success" | "error" | "warning" | "default",
    msg: string,
    title?: string,
    confirmButton?: () => void
  ) => {
    let icon = <MdInfoOutline />;
    if (type === "success") icon = <FaRegCheckCircle />;
    else if (type === "error") icon = <FaRegTimesCircle />;
    else if (type === "warning") icon = <FiAlertTriangle />;

    const toastBox = (
      <ToastBox
        msg={msg}
        title={title && title}
        icon={icon}
        confirmButton={confirmButton && confirmButton}
      />
    );

    return toast(toastBox, {
      className: styles[`toast-${type}`],
      closeButton: <CloseButton />,
    });
  };
  return { notify };
};

export default useToast;
