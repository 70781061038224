import { createSlice } from "@reduxjs/toolkit";
import { MeetingPointType } from "../../config/types/MeetingPointType";

interface MeetingPointState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: Date | number;
  data: MeetingPointType[];
  labels: { id: string; value: string }[];
  remove?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  update?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  add?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
}

const initialState: MeetingPointState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  labels: [],
  data: [],
  remove: undefined,
  update: undefined,
  add: undefined,
};

const slice = createSlice({
  name: "meetingPoint",
  initialState,
  reducers: {
    meetingPointClearState: (meetingPoint) => {
      meetingPoint.remove = undefined;
      meetingPoint.error = false;
      meetingPoint.update = undefined;
      meetingPoint.add = undefined;
    },
    meetingPointRequest: (meetingPoint) => {
      meetingPoint.loading = true;
    },
    meetingPointReceived: (meetingPoint, action) => {
      console.log("meetingPointReceived", action.payload);
      meetingPoint.data = action.payload;
      meetingPoint.loading = false;
      meetingPoint.lastFetch = Date.now();
    },
    meetingPointRequestFailed: (meetingPoint, action) => {
      meetingPoint.loading = false;
      meetingPoint.error = action.payload;
    },
    meetingPointRemoving: (meetingPoint) => {
      meetingPoint.remove = { loading: true };
    },
    meetingPointRemoved: (meetingPoint, action) => {
      meetingPoint.data = meetingPoint.data.filter(
        (item) => item._id !== action.payload
      );
      meetingPoint.remove = { success: true };
    },
    meetingPointRemoveFailed: (meetingPoint, action) => {
      meetingPoint.remove = { error: action.payload };
    },
    meetingPointAdding: (meetingPoint) => {
      meetingPoint.add = { loading: true };
    },
    meetingPointAdded: (meetingPoint, action) => {
      console.log("meetingPointAdded", action.payload);
      meetingPoint.data = [...meetingPoint.data, action.payload];
      meetingPoint.add = { success: true };
    },

    meetingPointAddFailed: (meetingPoint, action) => {
      meetingPoint.add = { error: action.payload };
    },
    meetingPointUpdating: (meetingPoint) => {
      meetingPoint.update = { loading: true };
    },
    meetingPointUpdated: (meetingPoint, action) => {
      console.log("meetingPointUpdated", action.payload);
      const index = meetingPoint.data.findIndex(
        (item) => item._id === action.payload.id
      );
      meetingPoint.data[index] = action.payload;
      meetingPoint.update = { success: true };
    },
    meetingPointUpdateFailed: (meetingPoint, action) => {
      meetingPoint.update = { error: action.payload };
    },
  },
});

export const {
  meetingPointClearState,
  meetingPointRequest,
  meetingPointReceived,
  meetingPointRequestFailed,
  meetingPointRemoving,
  meetingPointRemoved,
  meetingPointRemoveFailed,
  meetingPointAdding,
  meetingPointAdded,
  meetingPointAddFailed,
  meetingPointUpdating,
  meetingPointUpdated,
  meetingPointUpdateFailed,
} = slice.actions;

export default slice.reducer;
