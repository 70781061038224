// Slot > TimeOptions
import React from "react";
import styles from "./Slot.module.scss";
import TimeOptions from "./TimeOptions";

interface SlotProps {
  rowIndex: number;
  value: string[]; // ["09:30", "18:30"]
  onChange(index: number, value: string): void;
}

const Slot: React.FC<SlotProps> = ({ rowIndex, value, children, onChange }) => {
  const startIndex = rowIndex + rowIndex; // value index for start
  const endIndex = rowIndex + rowIndex + 1; // value index for end

  const handleClose = (index: number) => {
    // When timepicker close, do checking
    // If start time is bigger than end time, set end time same as start time
    // If end time is bigger than next start time, set next timeslot same as this end time
    if (index % 2 === 0) {
      // For start time
      if (
        new Date(`2020-01-01 ${value[index]}:00`) >
        new Date(`2020-01-01 ${value[index + 1]}:00`)
      ) {
        // Start time is bigger than end time
        // Set end time equal to start time
        onChange(index + 1, value[index]);
      }
    } else {
      // For end time
      if (index < value.length - 1) {
        // If there is timeslot after current timeslot
        if (`${value[index]}:00` > `${value[index + 1]}:00`) {
          // Set next timeslot value to be same as current value
          onChange(index + 1, value[index]);
          onChange(index + 2, value[index]);
        }
      }
    }
  };

  return (
    <div className={styles["slot"]}>
      <div className={styles["time-options"]}>
        <TimeOptions
          value={value[startIndex]}
          onChange={(value: string) => onChange(startIndex, value)}
          onClose={() => handleClose(startIndex)}
          previousTime={value[startIndex - 1]}
        />
      </div>
      <div className={styles["separator"]}></div>
      <div className={styles["time-options"]}>
        <TimeOptions
          value={value[endIndex]}
          onChange={(value: string) => onChange(endIndex, value)}
          onClose={() => handleClose(endIndex)}
          previousTime={value[endIndex - 1]}
        />
      </div>
      <div className={styles["time-slot-actions"]}>{children}</div>
    </div>
  );
};

export default Slot;
