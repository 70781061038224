import { Link } from "react-router-dom";
import ListItem from "../ui/ListItem";
import { Col, Row } from "../ui/Grid";
import BusinessHourDisplay from "../business/BusinessHourDisplay";
import { ILocationDataType } from "../../config/types/LocationDataType";
import LocationDisplay from "../common/LocationDisplay";

interface BookmarkBusinessLocationProps {
  data: ILocationDataType[];
}

const BookmarkBusinessLocation: React.FC<BookmarkBusinessLocationProps> = ({
  data,
}) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <div key={index} className="mb-25">
            {/* <Row>
              <Col> */}
            <h4 className="mb-10">{item.name}</h4>
            {/* </Col>
              <Col>
                <Link to="#" className="link">
                  Book Now
                </Link>
              </Col>
            </Row> */}
            <ListItem label="Address">
              <LocationDisplay data={item} />
            </ListItem>
            {item.phone && <ListItem label="Phone">{item.phone}</ListItem>}
            {item.website && <ListItem label="State">{item.website}</ListItem>}
            {item.email && <ListItem label="Email">{item.email}</ListItem>}
            {item.hours && <BusinessHourDisplay data={item.hours} group />}
          </div>
        );
      })}
    </>
  );
};

export default BookmarkBusinessLocation;
