import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config/global";

interface setupState {
  loading: boolean;
  error?: string;
  success: boolean;
  checkURL?: { loading?: boolean; pass?: boolean; failed?: string };
  registerStep: number;
}

const initialState: setupState = {
  loading: true,
  error: undefined,
  success: false,
  checkURL: undefined,
  registerStep: 0,
};

const slice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    setupClearState: (setup) => {
      setup.loading = false;
      setup.error = undefined;
      setup.success = false;
      setup.checkURL = undefined;
    },
    setupCheckURL: (setup) => {
      console.log("setupCheckURL");
      setup.checkURL = { loading: true };
    },
    setupURLChecked: (
      setup,
      action: PayloadAction<{ exist?: boolean; available?: boolean }>
    ) => {
      console.log("setupReceived", action.payload);
      const { exist, available } = action.payload;
      if (exist) setup.checkURL = { failed: "ID has taken" };
      else if (available) setup.checkURL = { pass: true };
      else setup.checkURL = undefined;
    },
    setupCheckURLFailed: (setup, action: PayloadAction<string>) => {
      console.log("setupCheckURLFailed", action.payload);
      setup.loading = false;
      setup.checkURL = { failed: "Failed to check the ID" };
    },
    setupSubmit: (setup) => {
      setup.loading = true;
      setup.error = undefined;
      setup.success = false;
    },
    setupSubmitted: (
      setup,
      action: PayloadAction<{ registerStep: number }>
    ) => {
      setup.registerStep = action.payload.registerStep;
      setup.loading = false;
      setup.success = true;
    },
    setupSubmitFailed: (setup, action: PayloadAction<string>) => {
      console.log("setupSubmitFailed", action.payload);
      setup.loading = false;
      setup.error = action.payload;
    },
    setupCreated: (setup, action: PayloadAction<{ registerStep: number }>) => {
      console.log("setupCreated", action.payload);
      // if (!config.localENV) {
      //   sessionStorage.setItem("addppointment", JSON.stringify(action.payload));
      // }
      setup.registerStep = action.payload.registerStep;
      setup.loading = false;
      setup.success = true;
    },
  },
});

export const {
  setupClearState,
  setupCheckURL,
  setupURLChecked,
  setupCheckURLFailed,
  setupSubmit,
  setupSubmitted,
  setupSubmitFailed,
  setupCreated,
} = slice.actions;

export default slice.reducer;
