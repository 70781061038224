import { Link } from "react-router-dom";
import { DropdownSelect } from "../ui/dropdown";
import { Col, Row } from "../ui/Grid";

import styles from "./DashboardTodayBooking.module.scss";
import DashboardTodayBookingTable from "./DashboardTodayBookingTable";
import { getDate } from "../../utils/datetime";
import useDashboardTodayBooking from "./useDashboardTodayBooking";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { businessURL } from "../../config/url";

interface TableProps {}

const DashboardTodayBooking: React.FC<TableProps> = () => {
  const { filter, getTableData } = useDashboardTodayBooking();
  const { data, othersData } = getTableData();
  const { today } = useTypedSelector((state) => state.entities.businessBooking);

  // const menuList = [{ name: "All", onClick: () => console.log("All Clicked") }];
  console.log("DashboardTodayBooking");

  return (
    <div className={styles["today-booking-chart"]}>
      <div className={styles["title"]}>
        <h3>Today Booking</h3>
        <span className="desc">{getDate(new Date(), "DD MMM")}</span>
      </div>
      {today.length === 0 ? (
        <p className="text-secondary text-italic">
          You do not have any booking today
        </p>
      ) : (
        <>
          <Row className="pt-10 pb-10">
            {/* <Col>
              <DropdownSelect
                menuList={filter}
                selected={selected}
                type="menu"
              />
            </Col> */}
            <Col>
              <div className={styles["total"]}>
                Today Bookings
                <b>{today.length}</b>
              </div>
            </Col>
          </Row>
          <div className={styles["body"]}>
            {data && <DashboardTodayBookingTable data={data} type="Services" />}
            {othersData && (
              <div className="pt-10 text-center">
                <Link to={businessURL.BusinessToday} className="link">
                  Show all
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardTodayBooking;
