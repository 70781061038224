import useDocumentTitle from "../../hooks/useDocumentTitle";
import { AppForm } from "../form";
import { LayoutBody } from "../layout/LayoutFullHeight";
import BusinessRegisterFooter from "./BusinessRegisterFooter";
import { useActions } from "../../hooks/useActions";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessProfile";
import BusinessInfo from "../businessProfile/BusinessInfo";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const BusinessRegisterBusinessInfo: React.FC = () => {
  useDocumentTitle("Register a Business - Business Info");
  const { error } = useTypedSelector((state) => state.entities.setup);

  const { createBusiness } = useActions();

  return (
    <AppForm
      className="flex-layout"
      initialValues={InitialValues}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        createBusiness(values);
      }}
    >
      <LayoutBody>
        <h3 className="mb-3">Business Info</h3>
        <div className="desc mb-20">
          Some information to help customer understand about your business.
        </div>
        {error && <p className="error-msg mb-20">{error}</p>}
        <BusinessInfo />
      </LayoutBody>
      <BusinessRegisterFooter step={4} />
    </AppForm>
  );
};

export default BusinessRegisterBusinessInfo;
