const servicesCategory = [
  {
    _id: "category1",
    name: "Category 1",
  },
  {
    _id: "category2",
    name: "Category 2",
  },
  {
    _id: "category3",
    name: "Category 3",
  },
  {
    _id: "category4",
    name: "Category 4",
  },
];

export function getServicesCategory() {
  return servicesCategory;
  // return [];
}
