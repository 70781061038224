import { createSlice } from "@reduxjs/toolkit";
import { ResourceType } from "../../config/types/ResourceType";

interface ResourceState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: Date | number;
  data: ResourceType[];
  labels: { id: string; value: string }[];
  remove?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  duplicate?: {
    loading?: boolean;
    success?: ResourceType;
    error?: string;
  };
  update?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
    resourceId?: string; // New/Edit Service
  };
  create?: { loading?: boolean; success?: boolean; error?: string };
}

const initialState: ResourceState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  labels: [],
  data: [],
  remove: undefined,
  duplicate: undefined,
  update: undefined,
  create: undefined,
};

const slice = createSlice({
  name: "resourceAdmin",
  initialState,
  reducers: {
    resourceClearState: (resource) => {
      resource.remove = undefined;
      resource.duplicate = undefined;
      resource.error = false;
      resource.update = undefined;
    },
    resourceRequest: (resource) => {
      resource.loading = true;
    },
    resourceReceived: (resource, action) => {
      const resources = action.payload;
      console.log("resourceReceived", resources);
      resource.data = resources;
      resource.loading = false;
      resource.lastFetch = Date.now();
      if (resources.length > 0) {
        const labels = resources.map((i: ResourceType) => {
          return { value: i.label, id: i._id } || null;
        });
        resource.labels = labels;
      }
    },
    resourceRequestFailed: (resource, action) => {
      resource.loading = false;
      resource.error = action.payload;
    },
    resourceRemoving: (resource) => {
      resource.remove = { loading: true };
    },
    resourceRemoved: (resource, action) => {
      resource.data = resource.data.filter(
        (item) => item._id !== action.payload
      );
      resource.remove = { success: true };
    },
    resourceRemoveFailed: (resource, action) => {
      resource.remove = { error: action.payload };
    },
    resourceDuplicating: (resource) => {
      resource.duplicate = { loading: true };
    },
    resourceDuplicated: (resource, action) => {
      resource.data = [...resource.data, action.payload];
      resource.duplicate = { success: action.payload };
    },
    resourceDuplicateFailed: (resource, action) => {
      resource.duplicate = { error: action.payload };
    },
    resourceUpdating: (resource, action) => {
      resource.update = { loading: true };
      if (action.payload && action.payload.type) {
        const { type, id, value } = action.payload;
        const index = resource.data.findIndex((item) => item._id === id);
        // if (type === "walk-in") {
        //   resource.data[index].walkIn = value;
        // } else if (type === "online-booking") {
        //   resource.data[index] = value;
        // }
      }
    },
    resourceUpdated: (resource, action) => {
      console.log("resourceUpdated", action.payload);
      const index = resource.data.findIndex(
        (item) => item._id === action.payload.id
      );
      resource.data[index] = action.payload;
      resource.update = { success: true, resourceId: action.payload.id };
    },
    resourceUpdateFailed: (resource, action) => {
      resource.update = { error: action.payload };
    },
    resourceAdding: (resource) => {
      resource.update = { loading: true };
    },
    resourceAdded: (resource, action) => {
      console.log("resourceAdded", action.payload, action.payload.id);
      resource.data = [...resource.data, action.payload];
      resource.update = { success: true, resourceId: action.payload.id };
      // resource.newresource = action.payload;
    },
    resourceAddFailed: (resource, action) => {
      resource.update = { error: action.payload };
    },
  },
});

export const {
  resourceClearState,
  resourceRequest,
  resourceReceived,
  resourceRequestFailed,
  resourceRemoving,
  resourceRemoved,
  resourceRemoveFailed,
  resourceDuplicating,
  resourceDuplicated,
  resourceDuplicateFailed,
  resourceUpdating,
  resourceUpdated,
  resourceUpdateFailed,
  resourceAdding,
  resourceAdded,
  resourceAddFailed,
} = slice.actions;

export default slice.reducer;
