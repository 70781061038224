import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./PersonalProfile.module.scss";
import { AppButton, AppInput, AppForm, AppDisplay } from "../form";
import {
  Validation,
  InitialValues,
} from "../../config/form/formPersonalProfile";
import { ProfilePic } from "../ui/avatar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { personalURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import { EBtnCategory } from "../form/AppButton";
import useToast from "../ui/toasts/useToast";

const currentYear = new Date().getFullYear();

const PersonalProfile: React.FC = () => {
  const [initVal, setInitVal] = useState(InitialValues);
  const { savePersonalName, clearUserState, uploadProfilePicture } =
    useActions();
  const { loading, data, photoUpload } = useTypedSelector(
    (state) => state.entities.user
  );
  const { notify } = useToast();

  useEffect(() => {
    if (data) {
      setInitVal({
        ...initVal,
        profilePic: data.profilePic ? data.profilePic : "",
        displayName: data.displayName,
        birthYear: currentYear - data.birthYear,
        gender: data.gender,
        email: data.email,
      });
    }
  }, [data]);

  useEffect(() => {
    if (photoUpload?.success) {
      notify("success", "Your photo has been updated");
      clearUserState();
    }
    if (photoUpload?.error) {
      notify("error", photoUpload.error);
      clearUserState();
    }
  }, [photoUpload]);

  return (
    <AppForm
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        // onSubmit(values);
        savePersonalName(values);
      }}
    >
      <div className={styles["wrapper"]}>
        <div className="mb-30">
          <ProfilePic
            role="personal"
            name={initVal.displayName}
            uploadLabel="Change Profile Photo"
            imageSrc={initVal.profilePic}
            uploaded={photoUpload?.success}
            onUploadPicture={uploadProfilePicture}
          />
        </div>
        <AppInput
          name="displayName"
          label="Name"
          helperMsg="Your name will be used for booking"
        />
        <AppInput
          name="email"
          label="Email"
          type="email"
          helperMsg={
            <Link
              to={personalURL.PersonalChangeEmail}
              className={classNames("link", styles["link"])}
            >
              Change Email
            </Link>
          }
          readonly={true}
        />
        <AppDisplay name="birthYear" label="Age" />
        <AppDisplay
          label="Gender"
          displayValue={data && data.gender === "1" ? "Male" : "Female"}
        />
        <AppButton
          type="submit"
          category={EBtnCategory.Primary}
          loading={loading}
          className="float-left mt-20"
        >
          Save
        </AppButton>
      </div>
    </AppForm>
  );
};

export default PersonalProfile;
