import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";

export type BookmarkType = "friend" | "bookmark";

interface BookmarkRemoveDialogProps {
  id: string;
  setVisible(arg: boolean): void;
  onRemove?(): void;
  type: BookmarkType;
}

const BookmarkRemoveDialog: React.FC<BookmarkRemoveDialogProps> = ({
  setVisible,
  onRemove,
  id,
  type,
}) => {
  const { removeBookmark, removeFriend } = useActions();

  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      type === "bookmark" ? removeBookmark(id) : removeFriend(id);
      setVisible(false);
      onRemove && onRemove();
    },
  };

  return (
    <DialogBox
      title="Remove?"
      message="Are you sure you want to remove it from your list?"
      confirm={handleConfirm}
      cancel="Cancel"
      onClose={() => setVisible(false)}
    />
  );
};

export default BookmarkRemoveDialog;
