import * as yup from "yup";
import { InitialValues as ServicePeriodInit } from "./formServicePeriod";
import { InitialValues as BookingPeriodInit } from "./formBookingPeriod";

export const InitialValues = {
  _id: "",
  // Resource Info --
  photo: "",
  name: "",
  label: "",
  quantity: 1,
  desc: "",
  bookingType: 0,
  // Booking Slot --
  slotInterval: 5,
  bookingCapacity: 1,
  dailyCapacity: "",
  // maxSlotSelection: 1,
  // Service Period --
  ...ServicePeriodInit,
  // Booking Period --
  ...BookingPeriodInit,
  resources: [],
  staff: [],
  location: [],
  customerRecord: false,
  privateBooking: false,
};

export const ResourceInfoValidation = yup.object().shape({
  photo: yup.string().notRequired(),
  name: yup
    .string()
    .max(50, "Maximum 50 characters")
    .required("Please enter the resource name"),
  label: yup.string().required("Please select a label"),
  quantity: yup.number().required("Please enter number of resource you have"),
  desc: yup.string().max(300, "Maximum 300 characters"),
});

export const BookingSlotValidation = yup.object().shape({
  slotInterval: yup
    .number()
    .min(5, "Minimum 5 minutes for slot interval")
    .required("Please enter the slot interval"),
  bookingCapacity: yup
    .number()
    .min(1, "Minimum 1 for capacity")
    .required("Please enter the booking capacity"),
  // maxSlotSelection: yup
  //   .number()
  //   .min(1, "Minimum 1 slot")
  //   .required("Please enter your Slot"),
});

export const ServiceResourceValidation = yup.object().shape({
  resources: yup.array().notRequired(),
});

export const ResourceStaffValidation = yup.object().shape({
  staff: yup.string(),
});

export const ResourceLocationValidation = yup.object().shape({
  location: yup.string(),
});

export const CustomerValidation = yup.object().shape({
  customerRecord: yup.boolean(),
  privateBooking: yup.boolean(),
});
