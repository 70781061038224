import { createSelector } from "reselect";
import { IServiceDataType } from "../../config/types/ServiceDataType";

export const getWalkInServices = createSelector(
  (state: { entities: { serviceAdmin: { data: IServiceDataType[] } } }) =>
    state.entities.serviceAdmin.data,
  (services) =>
    services
      ? services.filter((serviceAdmin) => serviceAdmin.walkIn === true)
      : null
);
