// TimeSlot > Slot > TimeOptions
import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import styles from "./Timeslot.module.scss";
import Slot from "./Slot";
import { Switch, AppButton } from "../../form";
import { EBtnCategory } from "../../form/AppButton";
import {
  defaultHour,
  defaultOffDay,
} from "../../../config/option/optionBusiness";

type CurrentTargetType = {
  name: string;
  value: string[];
};

interface TimeSlotProps {
  name: string;
  value: string[];
  onChange(arg: { currentTarget: CurrentTargetType }): void;
}

const Timeslot: React.FC<TimeSlotProps> = ({
  children,
  name,
  value,
  onChange,
}) => {
  // console.log("name", name);
  // console.log("value", value);
  const [enable, setEnable] = useState(false);
  const [slotNo, setSlotNo] = useState(1); // Number of slot
  useEffect(() => {
    if (value[0] !== "Off") {
      setEnable(true);
      setSlotNo(value.length / 2);
    } else {
      setEnable(false);
    }
  }, [value]);

  const handleAddSlot = () => {
    // Max 3 timeslot
    // Add 1 slot number
    // Add 2 new value with value same as last value
    slotNo < 3 && setSlotNo(slotNo + 1);
    const preset = value[value.length - 1];
    onChange({
      currentTarget: { name, value: [...value, preset, preset] },
    });
  };
  const handleRemoveSlot = () => {
    // Remove 1 slot number
    // Remove last 2 value from value
    slotNo > 1 && setSlotNo(slotNo - 1);
    value.splice(-2, 2);
    onChange({ currentTarget: { name, value } });
  };
  const handleSwitch = (isEnable: boolean) => {
    // If slot enable, set default time
    console.log("handleSwitch", isEnable);
    setEnable(isEnable);
    onChange({
      currentTarget: {
        name,
        value: isEnable ? defaultHour : defaultOffDay,
        // value: isEnable ? ["09:00", "18:00"] : ["Off", "Off"],
      },
    });
  };
  const handleTimeChange = (index: number, time: string) => {
    // If timepicker changed, update value in parent state
    value[index] = time;
    onChange({ currentTarget: { name, value } });
  };

  const renderSlot = () => {
    let output = [];
    for (let i = 0; i < slotNo; i++)
      output.push(
        <Slot key={i} rowIndex={i} value={value} onChange={handleTimeChange}>
          <div className={styles["btn-remove"]}>
            {i !== 0 && (
              <AppButton
                category={EBtnCategory.Link}
                type="button"
                onClick={() => handleRemoveSlot()}
              >
                <IoMdClose />
              </AppButton>
            )}
          </div>

          <div className={styles["btn-add"]}>
            {slotNo - 1 === i && i !== 2 && (
              <AppButton
                category={EBtnCategory.Link}
                type="button"
                onClick={() => handleAddSlot()}
              >
                Add hours
              </AppButton>
            )}
          </div>
        </Slot>
      );
    return output;
  };
  return (
    <div className={styles["container"]}>
      {children}
      <div className={styles["switch"]}>
        <Switch checked={enable} onChange={(status) => handleSwitch(status)} />
      </div>
      <div className={styles["slot"]}>{enable && renderSlot()}</div>
    </div>
  );
};

export default Timeslot;
