import { useState, useEffect } from "react";
import { Portal } from "react-portal";
import classnames from "classnames";
import { IoClose } from "react-icons/io5";
import styles from "./Modal.module.scss";
import classNames from "classnames";

interface ModalBoxProps {
  type?: "personal" | "business" | "dialog";
  size?: "small" | "medium" | "large" | "full";
  onClose(): void;
  overlayClose?: boolean;
  autoHeight?: boolean;
}

export const ModalBox: React.FC<ModalBoxProps> = ({
  type, // type: personal, business
  size = "medium",
  children,
  onClose,
  overlayClose, // overlayClose: close modalbox when click on overlay
  autoHeight, // boolean
}) => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const handleOverlayClose = () => {
    if (overlayClose) {
      setActive(false);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  const handleClose = () => {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };
  return (
    <Portal>
      <div className={styles["overlay"]} onClick={(e) => e.stopPropagation()}>
        <div
          className={classNames(
            active ? styles["active"] : styles["inactive"],
            styles["wrapper"]
          )}
          onClick={() => handleOverlayClose()}
        >
          <div
            className={classnames(
              styles["container"],
              type && styles[`type-${type}`],
              size && styles[`size-${size}`],
              autoHeight && styles["modal-auto-height"]
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles["btn-close"]}
              onClick={() => handleClose()}
            >
              <IoClose />
            </button>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
