import * as reducers from "../reducer/businessBookingReducer";
import { apiCallBegan } from "../middleware/api";
import {
  getUpcomingBookings,
  getPastBookings,
  getServingBookings,
} from "../../data/dataAppts";
import { fetch } from "../storeConfig";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { BusinessBookingFormType } from "../../config/form/formBusinessBooking";
import config from "../../config/global";
import { bookingDateTime } from "../../utils/booking";
import { ICreateBookingInputType } from "../../components/createBooking/CreateBookingSubmit";
import { RootState } from "../store";

interface DispatchType {
  type: string;
}

const url = "/business-booking";

export const clearBusinessBookingState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.bookingClearState.type });
  };

export const clearBusinessBookingSingleDayState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.bookingSingleDayClearState.type });
  };

export const getBusinessTodayBooking =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { businessBooking: { lastFetch: { today: number } } };
    }
  ) => {
    const { today } = getState().entities.businessBooking.lastFetch;
    const fetchFlag = fetch(today);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/today",
        data: config.localENV && [
          ...getUpcomingBookings(),
          ...getPastBookings(),
          ...getServingBookings(),
        ],
        onStart: reducers.bookingRequest.type,
        onSuccessDispatch: {
          type: reducers.bookingReceived.type,
          payload: "today",
        },
        onError: reducers.bookingRequestFailed.type,
      })
    );
  };

export const getBusinessUpcomingBooking =
  (loadMore?: boolean) =>
  (dispatch: (arg: DispatchType) => void, getState: () => RootState) => {
    if (loadMore) {
      const skip = getState().entities.businessBooking.upcoming.data.length;
      return dispatch(
        apiCallBegan({
          url: url + "/upcoming/" + skip,
          onStart: reducers.bookingLoadingMore.type,
          onSuccessDispatch: {
            type: reducers.bookingUpcomingReceived.type,
            payload: { loadMore: true },
          },
          // onError: reducers.bookingRequestFailed.type,
        })
      );
    } else {
      const { lastFetch } = getState().entities.businessBooking.upcoming;
      const fetchFlag = fetch(lastFetch);
      if (!fetchFlag) return;
      return dispatch(
        apiCallBegan({
          url: url + "/upcoming/0",
          onStart: reducers.bookingRequest.type,
          onSuccessDispatch: {
            type: reducers.bookingUpcomingReceived.type,
            payload: { loadMore: false },
          },
          onError: reducers.bookingRequestFailed.type,
        })
      );
    }
  };

export const getBusinessBookingByDate =
  (date: string, loadMore?: boolean) =>
  (dispatch: (arg: DispatchType) => void, getState: () => RootState) => {
    console.log("getBusinessBookingByDate", date);
    const endpoint = url + "/single-day";
    if (loadMore) {
      const singleDayData = getState().entities.businessBooking.singleDay.data;
      const page = singleDayData ? singleDayData.length.toString() : "0";
      console.log("page", page);
      return dispatch(
        apiCallBegan({
          url: endpoint,
          method: "post",
          data: { date, page },
          onStart: reducers.bookingByDateLoadingMore.type,
          // onSuccess: reducers.bookingByDateReceived.type,
          onSuccessDispatch: {
            type: reducers.bookingByDateReceived.type,
            payload: { loadMore: true },
          },
          onError: reducers.bookingByDateFailed.type,
        })
      );
    } else {
      return dispatch(
        apiCallBegan({
          url: endpoint,
          method: "post",
          data: { date, page: "0" },
          onStart: reducers.bookingByDateRequest.type,
          // onSuccess: reducers.bookingByDateReceived.type,
          onSuccessDispatch: {
            type: reducers.bookingByDateReceived.type,
            payload: { loadMore: false },
          },
          onError: reducers.bookingByDateFailed.type,
        })
      );
    }
  };

export const getSingleBusinessBooking =
  (bookingId: string) => (dispatch: (arg: DispatchType) => void) => {
    // console.log("getSingleBusinessBooking");
    return dispatch(
      apiCallBegan({
        url: url + "/single",
        method: "post",
        data: config.localENV ? [] : { bookingId },
        onStart: reducers.bookingLoading.type,
        onSuccess: reducers.bookingSingleReceived.type,
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const skipBusinessBooking =
  (bookingId: string, bookingStatus: number) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("skipbusinessBooking", bookingId, bookingStatus);
    return dispatch(
      apiCallBegan({
        url: url + "/skip",
        method: "post",
        data: { bookingId, bookingStatus },
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Skip,
        },
      })
    );
  };

export const startBusinessBooking =
  (bookingId: string, bookingStatus: number) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("startbusinessBooking", bookingId, bookingStatus);
    return dispatch(
      apiCallBegan({
        url: url + "/start",
        method: "post",
        data: { bookingId, bookingStatus },
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Start,
        },
      })
    );
  };

export const completeBusinessBooking =
  (bookingId: string, bookingStatus: number) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("completebusinessBooking", bookingId, bookingStatus);
    return dispatch(
      apiCallBegan({
        url: url + "/complete",
        method: "post",
        data: { bookingId, bookingStatus },
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Complete,
        },
      })
    );
  };

export const cancelBusinessBooking =
  (bookingId: string, bookingStatus: number, message: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("cancelBusinessBooking", bookingId, bookingStatus, message);
    return dispatch(
      apiCallBegan({
        url: url + "/cancel",
        method: "post",
        data: { bookingId, bookingStatus, message },
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Cancel,
        },
      })
    );
  };

export const transferBusinessBooking =
  (booking: IBookingDataType, transfer: BusinessBookingFormType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("oldBooking", booking);
    const dateTime = bookingDateTime(transfer.meetDateTime);
    const newBooking = {
      //TODO: must check
      ...booking,
      ...dateTime,
      service: transfer.service ? transfer.service : "",
      resource: transfer.resource ? transfer.resource : "",
      provider: transfer.provider ? transfer.provider : "",
    };
    console.log("newBooking", newBooking);
    return dispatch(
      apiCallBegan({
        url,
        data: booking,
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Transfer,
        },
      })
    );
  };

export const rescheduleBusinessBooking =
  (booking: IBookingDataType, reschedule: BusinessBookingFormType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("oldBooking", booking);
    const dateTime = bookingDateTime(reschedule.meetDateTime);
    const newBooking = {
      //TODO: must check
      ...booking,
      ...dateTime,
      service: booking.service ? booking.service : "",
      // resource: booking.resource ? booking.resource._id : "",
      // provider: booking.provider ? booking.provider._id : "",
    };
    console.log("newBooking", newBooking);
    return dispatch(
      apiCallBegan({
        url,
        data: booking,
        onSuccessDispatch: {
          type: reducers.bookingStatusAdminUpdate.type,
          payload: reducers.BookingActionType.Reschedule,
        },
      })
    );
  };

export const createBusinessBooking =
  (bookingData: ICreateBookingInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("bookingData", bookingData);
    const {
      selectedService,
      selectedProvider,
      selectedCustomer,
      selectedDateTime,
      selectedLocation,
      onsiteService,
    } = bookingData;
    const dateTime = bookingDateTime(selectedDateTime);
    const data = {
      ...dateTime,
      media: config.media,
      note: selectedCustomer.note,
      onsiteService,
      customerEmail: selectedCustomer.email,
      customerDisplayName: selectedCustomer.displayName,
      customerUserId: selectedCustomer.customer,
      locationId: selectedLocation._id,
      serviceId: selectedService._id,
      providerId: selectedProvider?._id,
    };
    console.log("data", data);
    return dispatch(
      apiCallBegan({
        url: url + "/create-booking",
        method: "POST",
        data,
        onStart: reducers.bookingLoading.type,
        onSuccess: reducers.bookingCreated.type,
        onError: reducers.bookingCreateFailed.type,
      })
    );
  };
