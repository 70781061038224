import { combineReducers } from "redux";
import authReducer from "./reducer/authReducer";
import registerReducer from "./reducer/registerReducer";
import userReducer from "./reducer/userReducer";
import bookmarkReducer from "./reducer/bookmarkReducer";
import businessReducer from "./reducer/businessReducer";
import friendReducer from "./reducer/friendReducer";
import personalBookingReducer from "./reducer/personalBookingReducer";
import businessAdminReducer from "./reducer/businessAdminReducer";
import setupReducer from "./reducer/setupReducer";
import serviceAdminReducer from "./reducer/serviceAdminReducer";
import resourceAdminReducer from "./reducer/resourceAdminReducer";
import customerReducer from "./reducer/customerReducer";
import staffReducer from "./reducer/staffReducer";
import locationReducer from "./reducer/locationReducer";
import providerAdminReducer from "./reducer/providerAdminReducer";
import invitationReducer from "./reducer/invitationReducer";
import businessBookingReducer from "./reducer/businessBookingReducer";
import meetingPointReducer from "./reducer/meetingPointReducer";
import walkInReducer from "./reducer/walkInReducer";
import servingReducer from "./reducer/servingReducer";
import capacityReducer from "./reducer/capacityReducer";
import usageReducer from "./reducer/usageReducer";
import notificationReducer from "./reducer/notificationReducer";
import tokenReducer from "./reducer/tokenReducer";
import messageReducer from "./reducer/messageReducer";
import businessMessageReducer from "./reducer/businessMessageReducer";
import serviceCategoryAdminReducer from "./reducer/serviceCategoryAdminReducer";
import businessNotificationReducer from "./reducer/businessNotificationReducer";

const appReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  user: userReducer,
  bookmark: bookmarkReducer,
  business: businessReducer,
  friend: friendReducer,
  personalBooking: personalBookingReducer,
  businessAdmin: businessAdminReducer,
  setup: setupReducer,
  serviceAdmin: serviceAdminReducer,
  resourceAdmin: resourceAdminReducer,
  customer: customerReducer,
  staff: staffReducer,
  location: locationReducer,
  providerAdmin: providerAdminReducer,
  invitation: invitationReducer,
  businessBooking: businessBookingReducer,
  meetingPoint: meetingPointReducer,
  walkIn: walkInReducer,
  serving: servingReducer,
  capacity: capacityReducer,
  usage: usageReducer,
  notification: notificationReducer,
  token: tokenReducer,
  message: messageReducer,
  businessMessage: businessMessageReducer,
  serviceCategory: serviceCategoryAdminReducer,
  businessNotification: businessNotificationReducer,
});

const entitiesReducer = (state: any, action: any) => {
  // console.log("rootReducer action", action);
  // console.log("rootReducer state", state);
  if (action.type === "auth/authLogout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default entitiesReducer;
