import styles from "./CustomBusinessHoursList.module.scss";
import CustomBusinessHoursItem from "./CustomBusinessHoursItem";
import { ICustomDayInputType } from "../../config/types/CustomDayDataType";

interface ICustomBusinessHoursListProps {
  existingData: ICustomDayInputType[];
  newData: ICustomDayInputType[];
  removeIds?: string[];
  errorRow?: { _id: string; msg: string };

  onRemove(id: string): void;
  onChangeDate(id: string, date: string): void;
  onEdit(data: ICustomDayInputType): void;
  onEditNew(data: ICustomDayInputType): void;
  onAddNew(): void;
  onRemoveNew(_id: string): void;
}

const CustomBusinessHoursList: React.FC<ICustomBusinessHoursListProps> = ({
  existingData,
  newData,
  removeIds,
  errorRow,
  onRemove,
  onChangeDate,
  onEdit,
  onEditNew,
  onAddNew,
  onRemoveNew,
}) => {
  function renderItem(data: ICustomDayInputType[], type: "new" | "edit") {
    return data.map((item, index) => {
      if (removeIds && removeIds.includes(item._id)) return null;
      return (
        <li className={styles["slot"]} key={index}>
          <CustomBusinessHoursItem
            data={item}
            onChange={type === "new" ? onEditNew : onEdit}
            onChangeDate={onChangeDate}
            onRemove={() =>
              type === "new" ? onRemoveNew(item._id) : onRemove(item._id)
            }
            isNew={type === "new" ? true : false}
            error={
              errorRow && errorRow._id === item._id ? errorRow.msg : undefined
            }
          />
        </li>
      );
    });
  }

  return (
    <>
      {existingData.length === 0 && newData.length === 0 ? (
        <div className={styles["empty"]}>
          <p>
            You can set your opening hours for a specific day that is different
            from your normal business hours or set a day off for upcoming
            holidays.
          </p>
          <button
            type="button"
            className="btn btn-link mt-10"
            onClick={() => onAddNew()}
          >
            Add a date
          </button>
        </div>
      ) : (
        <>
          <p className="desc">
            Set your opening hours for a specific day that is different from
            your normal business hours
          </p>
          <ul className={styles["slots"]}>
            {existingData.length > 0 && renderItem(existingData, "edit")}
            {newData.length > 0 && renderItem(newData, "new")}
          </ul>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => onAddNew()}
          >
            Add a date
          </button>
        </>
      )}
    </>
  );
};

export default CustomBusinessHoursList;
