const users = [
  {
    _id: "user1",
    country: "Malaysia",
    email: "ck@simplicitic.com",
    userUid: "usr-K8lVQAHcsjrcayffA48Hn",
    birthYear: 1992,
    city: "Klang",
    displayName: "CK One",
    gender: "1",
    pin: "9876",
    postcode: "41100",
    profilePic: "",
    state: "Selangor",
    business: "business1",
  },
  {
    _id: "user2",
    country: "Malaysia",
    email: "ck@gmail.com",
    userUid: "usr-herwreysdfh",
    birthYear: 1990,
    city: "Subang",
    displayName: "Second CK",
    gender: "2",
    pin: "1470",
    postcode: "43210",
    profilePic: "",
    state: "Selangor",
    business: "business2",
  },
  {
    _id: "user3",
    country: "Malaysia",
    email: "anwar@gmail.com",
    userUid: "usr-reweryrweyf",
    birthYear: 1982,
    city: "Shah Alam",
    displayName: "Anwar Ibrahim",
    gender: "1",
    pin: "6725",
    postcode: "63021",
    profilePic: "",
    state: "Selangor",
  },
  {
    _id: "user4",
    country: "Malaysia",
    email: "najib@yahoo.com",
    userUid: "usr-asdgsadgasd",
    birthYear: 1997,
    city: "Kuala Lumpur",
    displayName: "Najib Razak",
    gender: "2",
    pin: "4389",
    postcode: "96430",
    profilePic: "",
    state: "Kuala Lumpur",
    business: "business3",
  },
];

export function getUsers() {
  return users;
}

export function getUser(id) {
  const user = users.find((m) => m._id === id);
  return user ? user : users[0];
}
