export const setupBusiness = {
  title: "Create a business",
  list: ["Business Info", "Business Contact", "Business Hours"],
};

export const registerBusiness = {
  title: "",
  list: [
    "User Account",
    "Email Verification",
    "Personal Profile",
    "Business Info",
    "Business Contact",
    "Business Hours",
  ],
};

export const addNewServices = {
  title: "Create a service",
  list: [
    "Service Info",
    "Booking Slot",
    "Service Period",
    "Booking Period",
    "Provider",
    "Customer",
  ],
};
