import { useFormikContext } from "formik";
import CalendarMini from "../ui/CalendarMini";

interface AppCalendarProps {
  name: string;
  inputDay?: string;
  inputMonth?: string;
  inputYear?: string;
}

interface FormikProps {
  [name: string]: Date & string;
}

const AppCalendar: React.FC<AppCalendarProps> = ({
  name,
  inputDay,
  inputMonth,
  inputYear,
}) => {
  const { setFieldValue, values } = useFormikContext<FormikProps>();

  const handleChange = (val: Date | Date[]) => {
    if (val && val instanceof Date) {
      setFieldValue(name, val.toDateString());
      inputDay && setFieldValue(inputDay, val.getDate());
      inputMonth && setFieldValue(inputMonth, val.getMonth() + 1);
      inputYear && setFieldValue(inputYear, val.getFullYear());
    }
  };

  return (
    <CalendarMini
      onChange={handleChange}
      value={values[name] ? new Date(values[name]) : new Date()}
    />
  );
};

export default AppCalendar;
