import InputContainer from "./InputContainer";
import AppTimePicker from "./AppTimePicker";
import styles from "./AppDurationHour.module.scss";

interface InputProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
}

const AppDurationHour: React.FC<InputProps> = ({
  name,
  label,
  labelBlock,
  labelWidth,
  helperMsg,
}) => {
  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      //   error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className={styles["datetime"]}>
        <AppTimePicker name="timefrom" />
        <div className={styles["to"]}>To</div>
        <AppTimePicker name="timeto" />
      </div>
    </InputContainer>
  );
};

export default AppDurationHour;
