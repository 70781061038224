import { useState, useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import Loader from "../../components/ui/Loader";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import BusinessQRCode from "../../components/businessProfile/BusinessQRCode";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 3;
const BusinessQRCodePage: React.FC = () => {
  useDocumentTitle("QR Code - AddPointment");
  const { getLocation } = useActions();
  const { header, footer } = useBusinessProfile(index, true);
  const { request, data } = useTypedSelector(
    (state) => state.entities.location
  );

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <LayoutFullHeight>
        <LayoutBody>
          {header()}
          {request?.loading && <Loader />}
          {request?.error && <div className="error-msg">{request.error}</div>}
          {/* {!request && data.length > 0 && console.log("data-----", data[0])} */}
          {!request && data.length > 0 && (
            <BusinessQRCode
              data={data[0].locationUid}
              name={data[0].businessName}
            />
          )}
        </LayoutBody>
      </LayoutFullHeight>
    </PageWithAside>
  );
};

export default BusinessQRCodePage;
