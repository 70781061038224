import { useEffect, useState } from "react";
import { IServicesCategoryDataType } from "../../config/types/ServiceCategoryDataType";
import { useActions } from "../../hooks/useActions";
import styles from "./ServicesCategoryItem.module.scss";
import AppButton, { EBtnCategory } from "../form/AppButton";
import { FaRegTrashAlt } from "react-icons/fa";
import { Col, Row } from "../ui/Grid";
import classNames from "classnames";

interface ServicesCategoryItemProps {
  category?: IServicesCategoryDataType;
}

const ServicesCategoryItem: React.FC<ServicesCategoryItemProps> = ({
  category,
}) => {
  const [isEdit, setIsEdit] = useState(category && true);
  const [inputValue, setInputValue] = useState<string | undefined>(
    category?.name
  );
  const {
    updateAdminServicesCategory,
    removeAdminServicesCategory,
    addAdminServicesCategory,
  } = useActions();

  useEffect(() => {
    category && setInputValue(category.name);
  }, [category]);

  const handleSubmit = (_id?: string) => {
    if (inputValue) {
      if (_id) {
        // Update
        updateAdminServicesCategory(_id, inputValue);
        setIsEdit(true);
      } else {
        // Create
        addAdminServicesCategory(inputValue);
        setInputValue(undefined);
      }
    }
  };

  return (
    <Row className={styles["item"]}>
      <Col width="400px">
        <input
          onKeyDown={(e) => e.key === "Enter" && handleSubmit(category?._id)}
          maxLength={50}
          type="text"
          value={inputValue ? inputValue : ""}
          onChange={(e) => setInputValue(e.target.value)}
          className={`input-control ${styles["input-control"]}`}
          disabled={isEdit ? true : false}
          placeholder="Category Name"
        />
      </Col>
      <Col className={styles["actions"]}>
        {category ? (
          <>
            {isEdit ? (
              <AppButton
                type="button"
                category={EBtnCategory.PrimaryOutline}
                onClick={() => setIsEdit(false)}
                className={styles["btn-edit"]}
              >
                Edit
              </AppButton>
            ) : (
              <AppButton
                type="submit"
                category={EBtnCategory.Primary}
                className={styles["btn-edit"]}
                onClick={() => handleSubmit(category?._id)}
              >
                Save
              </AppButton>
            )}
            <button
              type="button"
              className={classNames("btn-empty", styles["btn-delete"])}
              onClick={() => removeAdminServicesCategory(category._id)}
            >
              <FaRegTrashAlt />
            </button>
          </>
        ) : (
          <AppButton
            type="button"
            category={EBtnCategory.Primary}
            disabled={inputValue ? false : true}
            onClick={() => handleSubmit()}
          >
            Add Category
          </AppButton>
        )}
      </Col>
    </Row>
  );
};

export default ServicesCategoryItem;
