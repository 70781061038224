import styles from "./LayoutFullHeight.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FaChevronLeft } from "react-icons/fa";

interface LayoutHeaderProps {
  title: string;
  desc?: string;
  backUrl?: string;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  children,
  title,
  desc,
  backUrl,
}) => {
  return (
    <div className={styles["header"]}>
      <div className="wrapper">
        <div className="page-title">
          <h1>
            {backUrl && (
              <Link
                className={classNames(styles["btn-header-back"])}
                to={backUrl}
              >
                <FaChevronLeft />
              </Link>
            )}
            {title}
          </h1>
          {desc && <p className="desc">{desc}</p>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default LayoutHeader;
