import { Middleware } from "redux";
import { io, Socket } from "socket.io-client";
import config from "../../config/global";
import EVENTS from "../../config/events/BookingEvents";
import {
  meetupStatusUpdate,
  meetupNewCreated,
  bookingStatusUpdate,
  bookingUpcomingReceived,
  bookingNewCreated,
} from "../reducer/personalBookingReducer";
import { bookingStatusAdminUpdate } from "../reducer/businessBookingReducer";
import { authLogin, authStateReceived } from "../reducer/authReducer";
import { notificationNewCreated } from "../reducer/notificationReducer";
import { businessNotificationNewCreated } from "../reducer/businessNotificationReducer";

const bookingMiddleware: Middleware = (store) => {
  const socket = io(config.socket, { withCredentials: true });
  let listenersAreMapped = false;
  // console.log("config.api", config.api);
  return (next) => (action) => {
    if (!listenersAreMapped) {
      socket.on(EVENTS.SERVER.BOOKING_STATUS_UPDATED, (booking) => {
        console.log("BOOKING_STATUS_UPDATED", booking._id);
        store.dispatch(bookingStatusUpdate(booking));
      });
      socket.on(EVENTS.SERVER.MEETUP_CREATED, (meetup) => {
        console.log("MEETUP_CREATED", meetup);
        socket.emit(EVENTS.CLIENT.BOOKING_LISTENER, meetup._id);
        store.dispatch(meetupNewCreated(meetup));
      });
      socket.on(EVENTS.SERVER.MEETUP_STATUS_UPDATED, (booking) => {
        console.log("MEETUP_STATUS_UPDATED", booking);
        // TODO: not sure how to dispatch
        // store.dispatch(bookingStatusUpdate(booking));
      });
      socket.on(EVENTS.SERVER.MEETUP_CANCELLED, (meetup) => {
        console.log("MEETUP_CANCELLED", meetup);
        store.dispatch(bookingStatusUpdate(meetup));
      });
      socket.on(EVENTS.SERVER.BOOKING_CREATED, (booking) => {
        console.log("BOOKING_CREATED", booking);
        socket.emit(EVENTS.CLIENT.BOOKING_LISTENER, booking._id);
        store.dispatch(bookingNewCreated(booking));
      });
      socket.on(EVENTS.SERVER.NOTIFICATION_CREATED, (notification) => {
        console.log("NOTIFICATION_CREATED", notification);
        store.dispatch(notificationNewCreated(notification));
      });
      socket.on(EVENTS.SERVER.BUSINESS_NOTIFICATION_CREATED, (notification) => {
        console.log("BUSINESS_NOTIFICATION_CREATED", notification);
        store.dispatch(businessNotificationNewCreated(notification));
      });
      listenersAreMapped = true;
    }
    // if (meetupStatusUpdate.match(action)) {
    //   console.log("match--------", action.payload.data);
    //   socket.emit(EVENTS.CLIENT.UPDATE_MEETUP_STATUS, action.payload.data);
    // }
    if (bookingStatusAdminUpdate.match(action)) {
      socket.emit(EVENTS.CLIENT.UPDATE_BOOKING_STATUS, action.payload.data);
    }
    if (bookingUpcomingReceived.match(action)) {
      const bookingIds = action.payload.map((a) => a._id);
      socket.emit(EVENTS.CLIENT.BOOKING_LISTENER, bookingIds);
    }
    if (authLogin.match(action) || authStateReceived.match(action)) {
      console.log("userId", action.payload.userId);
      socket.emit(EVENTS.CLIENT.USER_LOG_IN, action.payload.userId);
      socket.emit(EVENTS.CLIENT.BUSINESS_LOG_IN, action.payload.business);
    }
    next(action);
  };
};

export default bookingMiddleware;
