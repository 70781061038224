import axios from "axios";
import useSWR from "swr";
import config from "../../config/global";

const fetcher = async (id: string) => {
  const { data } = await axios.get(`${config.api}/make-booking/${id}`, {
    withCredentials: true,
  });
  console.log("capacity", data);
  return data;
};

function useCapacity(id: string) {
  const { data, error } = useSWR(id, fetcher);
  return {
    capacityData: data,
  };
}

export default useCapacity;
