import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
// import { DropdownMenu } from "../ui/dropdown";
import DropdownSelect from "../ui/dropdown/DropdownSelect";

interface FormikContextProps {
  [name: string]: string;
}

export type SelectOptionsType = {
  name: string;
  desc?: string;
  value: string | number;
  disabled?: boolean;
};

interface SelectPanelProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  inputWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  options: SelectOptionsType[];
  menuAlign?: "left" | "right";
  placeholder?: string;
  disabled?: boolean;
}

const AppSelectMenu: React.FC<SelectPanelProps> = ({
  name,
  options,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  inputWidth,
  menuAlign,
  placeholder,
  disabled,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();
  const selected = options.find((x) => x.value === values[name]);

  const menuList =
    options.length > 0
      ? options.map((item) => ({
          ...item,
          onClick: () => setFieldValue(name, item.value),
        }))
      : [];

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div style={{ maxWidth: inputWidth ? inputWidth : "none" }}>
        <DropdownSelect
          menuList={menuList}
          menuAlign={menuAlign}
          fullWidth
          placeholder={placeholder}
          selected={selected && selected.name}
          disabled={disabled}
        />
      </div>
    </InputContainer>
  );
};

export default AppSelectMenu;
