import ListItem from "../ui/ListItem";
import { getDate, getTime } from "../../utils/datetime";
import { IBookingDataType } from "../../config/types/BookingDataType";
import prefixId, { idType } from "../../utils/prefixID";
import { queueNumber } from "../../utils/booking";
import LocationDisplay from "../common/LocationDisplay";

interface InfoProps {
  data: IBookingDataType;
}

const BookingInfo: React.FC<InfoProps> = ({ data }) => {
  return (
    <>
      <ListItem label="Date">{getDate(data.meetDateTime)}</ListItem>
      <ListItem label="Time">{getTime(data.meetDateTime)}</ListItem>
      <ListItem label="Queue No.">
        {queueNumber(data.bookingNo, data.serviceData.label)}
      </ListItem>
      <ListItem label="Service">{data.serviceData.name}</ListItem>
      {data.onsiteService && (
        <ListItem label="Location">
          <LocationDisplay data={data.onsiteService} />
        </ListItem>
      )}
      <ListItem label="Booking ID">
        {prefixId(data.bookingUid, idType.Booking)}
      </ListItem>
      {/* <ListItem label="Location">{data.locationData.name}</ListItem> */}
    </>
  );
};

export default BookingInfo;
