import { useFormikContext } from "formik";
import { useRef, useEffect, useState, KeyboardEvent } from "react";
import styles from "./VerificationCode.module.scss";

interface VerificationProps {
  size: number;
  name: string;
  type?: string;
  disabled?: boolean;
  isForm?: boolean;
  autoFocus?: boolean;
  onComplete?: (arg: boolean) => void;
}

interface FormikContextProps {
  [name: string]: string;
}

const VerificationCode: React.FC<VerificationProps> = ({
  size,
  name,
  disabled,
  type = "text",
  isForm,
  autoFocus,
  onComplete,
}) => {
  const { setFieldValue, errors } = useFormikContext<FormikContextProps>();
  const [value, setValue] = useState<string>();

  const handleKeyDown = (e: KeyboardEvent) => {
    const keyCode = e.key;
    if (value?.length === size && keyCode !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    if (inputValue.length === size) {
      setFieldValue(name, inputValue);
      onComplete && onComplete(true);
    } else onComplete && onComplete(false);
  };

  return (
    <div className={isForm ? styles["align-left"] : styles["align-center"]}>
      <input
        type={type}
        autoComplete="off"
        autoFocus={autoFocus}
        size={size}
        maxLength={size}
        name={name}
        className={styles["input"]}
        style={{ width: `${size * 1.5}em` }}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
      {errors && errors[name] && (
        <div className="desc mt-3 error-msg">{errors[name]}</div>
      )}
    </div>
  );
};

export default VerificationCode;
