import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LayoutHeader } from "../../components/layout/LayoutFullHeight";
import { businessProviderURL, businessURL } from "../../config/url";
import { AppButton } from "../../components/form";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useToast from "../../components/ui/toasts/useToast";
import useBusinessProvider from "../../components/providers/useBusinessProvider";
import { EBtnCategory } from "../../components/form/AppButton";
import { IProviderDataType } from "../../config/types/ProviderDataType";

const useBusinessProviderPage = (id?: string) => {
  const { getAdminProviders, clearAdminProviderState } = useActions();
  const navigate = useNavigate();
  const { notify } = useToast();
  const [provider, setProvider] = useState<IProviderDataType>();
  const { request, data, success, error } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const { getProviderName } = useBusinessProvider();

  useEffect(() => {
    getAdminProviders();
  }, []);

  useEffect(() => {
    if (!request) {
      const selected = data.find((item) => item._id === id);
      selected
        ? setProvider(selected)
        : navigate(businessURL.BusinessProviders);
    }
  }, [request, data]);

  useEffect(() => {
    if (success) {
      console.log("success", success);
      notify("success", success);
      clearAdminProviderState();
    } else if (error) {
      notify("error", error);
      clearAdminProviderState();
    }
  }, [success, error]);

  const bookingType = getProviderName(provider?.profession);

  const providerName = provider ? provider.name : "Provider";
  useDocumentTitle(providerName);

  const submenuList = [
    {
      name: "Provider Info",
      url: `${businessURL.BusinessProviders}/${id}/${businessProviderURL.Info}`,
    },
    {
      name: "Available Period",
      url: `${businessURL.BusinessProviders}/${id}/${businessProviderURL.AvailablePeriod}`,
    },
    {
      name: "Services",
      url: `${businessURL.BusinessProviders}/${id}/${businessProviderURL.Services}`,
    },
  ];
  const header = <LayoutHeader title={providerName} />;

  const footer = (
    <>
      <AppButton
        category={EBtnCategory.Primary}
        type="submit"
        className="mr-10 mb-20"
      >
        Save
      </AppButton>
      <Link to={businessURL.BusinessProviders} className="link">
        Cancel
      </Link>
    </>
  );

  const submenu = [submenuList[0], submenuList[1], submenuList[2]];

  return { provider, header, submenu, footer, bookingType };
};

export default useBusinessProviderPage;
