import { getLocation } from "./dataLocations";

const services = [
  {
    _id: "service1",
    bookingType: 102,
    business: "business1",
    businessUrl: "ckonebusines",
    bookingCapacity: 5,
    dailyCapacity: "[0,180,180,180,180,60,0]",
    creator: "user1",
    desc: "Hair cut service",
    duration: 15,
    label: "A",
    name: "Hair Cut",
    onlineBooking: {
      enable: true,
      minDay: 5,
      maxDay: 6,
      note: "7",
      _id: "620b5af3ffa838560a717745",
    },
    servicePeriod:
      '[["Off","Off"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","12:00"],["Off","Off"]]',
    servicePeriodOption: 1,
    servicePeriodSum: "[0,540,540,540,540,180,0]",
    slotInterval: 15,
    walkIn: false,
    serviceUid: "svc-N0I9hAGuU4N2EVJ_xrmNH",
    location: ["location1"],
    locationUid: ["loc-ogMYqgR4BbsAY-VHCiYkT"],
    category: "cat1",
    categoryName: "My First Category",
  },
  {
    _id: "service2",
    bookingType: 102,
    business: "business1",
    businessUrl: "ckonebusines",
    bookingCapacity: 1,
    dailyCapacity: "[0,18,18,18,18,6,0]",
    creator: "user1",
    desc: "Hair cut service",
    duration: 30,
    label: "B",
    name: "Second hair cut",
    onlineBooking: {
      enable: false,
      minDay: 2,
      maxDay: 7,
      note: "",
      _id: "620a184f6b14a9636550809f",
    },
    servicePeriod:
      '[["Off","Off"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","12:00"],["Off","Off"]]',
    servicePeriodOption: 1,
    servicePeriodSum: "[0,540,540,540,540,180,0]",
    slotInterval: 30,
    walkIn: true,
    serviceUid: "svc-Tw8rOjTchgU0ysCMjZeLB",
    location: ["location1"],
    locationUid: ["loc-ogMYqgR4BbsAY-VHCiYkT"],
    category: "cat1",
    categoryName: "First Category",
  },
  {
    _id: "service3",
    bookingType: 102,
    business: "business1",
    businessUrl: "ckonebusines",
    bookingCapacity: 1,
    dailyCapacity: "[0,108,108,108,108,36,0]",
    creator: "user1",
    desc: "test desc",
    duration: 5,
    label: "C",
    name: "CK Test service",
    onlineBooking: {
      enable: false,
      minDay: 3,
      maxDay: 5,
      note: "",
      _id: "620a184f6b14a9636550809f",
    },
    privateBooking: false,
    servicePeriod:
      '[["Off","Off"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","12:00"],["Off","Off"]]',
    servicePeriodOption: 1,
    servicePeriodSum: "[0,540,540,540,540,180,0]",
    slotInterval: 5,
    walkIn: true,
    location: ["location1"],
    locationUid: ["loc-ogMYqgR4BbsAY-VHCiYkT"],
    serviceUid: "svc-5o5pMTmPes2kjge34B7XQ",
    category: "cat2",
    categoryName: "The Second Category",
  },
];

export function getServices() {
  return services;
  // return [];
}

export function getService(id) {
  const service = services.find((m) => m._id === id);
  return service ? service : services[0];
}

export function getWalkInServices() {
  const service = services.filter((m) => m.walkIn === true);
  return service;
}
