import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import BusinessCustomer from "../../components/customer/BusinessCustomer";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const BusinessCustomerPage: React.FC = () => {
  const { getCustomers, getCustomerTags, clearCustomerState } = useActions();
  const { request, data, success, error } = useTypedSelector(
    (state) => state.entities.customer
  );

  const { notify } = useToast();

  useEffect(() => {
    getCustomers();
    // getCustomerTags();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearCustomerState();
    }
    if (error) {
      notify("error", error);
      clearCustomerState();
    }
  }, [success, error]);

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Customer"
          pageDesc="List of customers that has been made booking with you before"
          role="business"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && <BusinessCustomer />}
        <MenuCreateNew role="business" />
      </div>
    </PageWithAside>
  );
};

export default BusinessCustomerPage;
