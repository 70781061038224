import Header from "../../components/layout/Header";
import PageWithAside from "../../components/layout/PageWithAside";
import BusinessNowServing from "../../components/businessTools/BusinessNowServing";
import Loader from "../../components/ui/Loader";
import useGetBookingType from "../../hooks/useGetBookingType";

const BusinessNowServingPage: React.FC = () => {
  const { loading } = useGetBookingType();

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Now Serving"
          pageDesc="Display the current serving customer's id number and the meeting point"
          role="business"
        />
        {loading ? <Loader /> : <BusinessNowServing />}
      </div>
    </PageWithAside>
  );
};

export default BusinessNowServingPage;
