// import "react-toastify/dist/ReactToastify.css";
import styles from "./ToastBox.module.scss";

interface ToastBoxProps {
  msg: string;
  title?: string;
  icon?: Object;
  confirmButton?(): void;
}

const ToastBox: React.FC<ToastBoxProps> = ({
  msg,
  title,
  icon,
  confirmButton,
}) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["icon"]}>{icon && icon}</div>
      <div className={styles["message"]}>
        {title && <h4>{title}</h4>}
        {msg && <p>{msg}</p>}
        {confirmButton && (
          <button
            type="button"
            className="btn btn-primary-link float-right"
            onClick={() => confirmButton()}
          >
            Confirm
          </button>
        )}
      </div>
    </div>
  );
};

export default ToastBox;
