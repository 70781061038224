import { createSelector } from "reselect";
import { IProviderDataType } from "../../config/types/ProviderDataType";

export const getWalkInProviders = createSelector(
  (state: { entities: { providerAdmin: { data: IProviderDataType[] } } }) =>
    state.entities.providerAdmin.data,
  (providers) => null
  // providers
  //   ? providers.filter((providerAdmin) => providerAdmin.walkIn === true)
  //   : null
);
