import { ModalBox } from "./modal";
import styles from "./DialogBox.module.scss";
import { AppButton } from "../form";
import { EBtnCategory } from "../form/AppButton";

interface DialogBoxProps {
  title?: string;
  message?: string;
  cancel?: string;
  confirm?: { label?: string; onClick: () => void };
  onClose: () => void;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  title,
  message,
  cancel,
  confirm,
  onClose,
}) => {
  return (
    <ModalBox onClose={onClose} type="dialog">
      <div className={styles["wrapper"]}>
        {title && <h4>{title}</h4>}
        {message && <div className={styles["content"]}>{message}</div>}
      </div>
      <div className={styles["footer"]}>
        {cancel && (
          <AppButton
            type="button"
            category={EBtnCategory.PrimaryOutline}
            size="small"
            onClick={() => onClose()}
          >
            {cancel}
          </AppButton>
        )}
        {confirm && (
          <AppButton
            type="button"
            category={EBtnCategory.Primary}
            size="small"
            onClick={() => confirm.onClick()}
          >
            {confirm.label ? confirm.label : "Confirm"}
          </AppButton>
        )}
      </div>
    </ModalBox>
  );
};

export default DialogBox;
