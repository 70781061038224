import React, { useState, useEffect } from "react";
import styles from "./CreateBookingTime.module.scss";
import { getDate, getDayName } from "../../utils/datetime";
import useTimeslotPicker from "./useTimeslotPicker";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { ICustomDayDataType } from "../../config/types/CustomDayDataType";
import useCapacity from "./useCapacity";
import Loader from "../ui/Loader";
import { IProviderDataType } from "../../config/types/ProviderDataType";

interface CreateBookingTimeProps {
  onBook(date: Date): void;
  selectedDate: number;
  selectedService: IServiceDataType;
  selectedProvider?: IProviderDataType;
  customBusinessHours: ICustomDayDataType[];
}

function getIdDate(timestamp: number) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const output =
    year +
    "-" +
    month.toString().padStart(2, "0") +
    "-" +
    day.toString().padStart(2, "0");
  return output;
}

const CreateBookingTime: React.FC<CreateBookingTimeProps> = ({
  onBook,
  selectedDate,
  selectedService,
  selectedProvider,
  customBusinessHours,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();
  const [timeRange, setTimeRange] = useState<string[]>();
  const MAXDAY = new Date();
  MAXDAY.setDate(MAXDAY.getDate() + 30);

  const businessHours = selectedProvider
    ? selectedProvider.hours
    : selectedService.servicePeriod;
  const capacity = selectedProvider ? 1 : selectedService.bookingCapacity;
  const interval = selectedService.slotInterval;
  const uidDate = getDate(selectedDate, "YYYY MM DD", true);
  const uid = selectedProvider
    ? `provider-capacity/${selectedProvider._id}-${uidDate}`
    : `service-capacity/${selectedService._id}-${uidDate}`;
  console.log("uid", uid);

  const { capacityData } = useCapacity(uid);
  console.log("capacityData", capacityData);

  useEffect(() => {
    // Clear selected time
    console.log("selectedDate", selectedDate);
    selectedDate && setSelectedDateTime(undefined);

    // Check for custom day
    const dateInString = getDate(selectedDate, "YYYY MM DD", true);
    const customDay =
      customBusinessHours && customBusinessHours.length > 0
        ? customBusinessHours.find((i) => i.dateInString === dateInString)
        : undefined;
    if (customDay?.businessHours) {
      setTimeRange(JSON.parse(customDay.businessHours));
    } else {
      // Set time range based on selected date
      const day = new Date(selectedDate).getDay();
      const hours: string[][] = businessHours && JSON.parse(businessHours);
      hours && setTimeRange(hours[day]);
    }
  }, [selectedDate]);
  // const { capacityData } = useCapacity("test");

  useEffect(() => {
    setLoaded(true);
  }, []);

  const { generateList } = useTimeslotPicker({
    timeRange,
    date: getDate(selectedDate, "YYYY MM DD"),
    capacity,
    interval,
    dataCapacity: capacityData?.capacityBySlot,
  });

  const handleSelected = (val: Date) => {
    console.log(val);
    setSelectedDateTime(val);
    onBook(val);
  };

  if (!loaded) return <Loader />;
  return (
    <div className={styles["container"]}>
      {selectedDate && (
        <>
          <h4 className="text-center">
            {getDate(selectedDate, "DD MMMM YYYY")} ({getDayName(selectedDate)})
          </h4>
          <div className={styles["time-slot"]}>
            <ul>{generateList(handleSelected, selectedDateTime)}</ul>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateBookingTime;
