import styles from "./MoreButton.module.scss";
import { RiMoreFill } from "react-icons/ri";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";
import { Dropdown } from "./dropdown";
import DropdownItemList, {
  DropdownItemListType,
} from "./dropdown/DropdownItemList";
import { EBtnCategory } from "../form/AppButton";

interface ButtonProps {
  category?: EBtnCategory; // primary-outline
  menuList: DropdownItemListType[];
  type?: "button" | "submit";
  label: string;
  // maxWidth?: string;
  minWidth?: number;
  onClick?(): void;
}

const MoreButton: React.FC<ButtonProps> = ({
  category,
  menuList,
  label,
  type = "button",
  // maxWidth,
  minWidth,
  onClick,
}) => {
  return (
    <div className={classNames(styles["container"])}>
      {onClick ? (
        <div className={styles["btn-click"]}>
          <button
            type={type}
            className={classNames(
              styles["btn"],
              category && `btn-${category}`,
              category && styles[`btn-${category}`]
            )}
            onClick={onClick}
          >
            {label}
          </button>
          <Dropdown
            menu={<DropdownItemList menuList={menuList} />}
            className={classNames(
              styles["more"],
              category && `btn-${category}`,
              category && styles[`btn-${category}`]
            )}
            minWidth={minWidth}
          >
            <RiMoreFill />
          </Dropdown>
        </div>
      ) : (
        <Dropdown
          menu={<DropdownItemList menuList={menuList} />}
          minWidth={minWidth}
        >
          <div className={classNames("btn", category && `btn-${category}`)}>
            {label}
            <span className={styles["icon"]}>
              <RiMoreFill />
            </span>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default MoreButton;
