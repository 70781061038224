import * as yup from "yup";
import { ETokenType } from "../types/TokenType";
import { passwordField, emailField } from "./validationFields";

export const TokenInitialValues = {
  email: "",
  tokenId: "",
  tokenType: ETokenType.resetPassword,
  code: "",
};

export const TokenValidation = yup.object().shape({
  email: emailField.required("Email is required"),
  tokenId: yup.string().required("Token ID is required"),
  tokenType: yup.number().required("Token type is required"),
  code: yup.string().min(6).max(6).required("Please enter the code"),
});

export interface ITokenValidateInputType {
  email: string;
  tokenId: string;
  tokenType: number;
  code: string;
}

export const ResetPwdInitialValues = {
  ...TokenInitialValues,
  password: "",
  reEnterPassword: "",
};

export const ResetPwdValidation = yup.object().shape({
  password: passwordField.required("Please enter your new password"),
  reEnterPassword: yup
    .string()
    .required("Please enter again your new password")
    .oneOf([yup.ref("password"), null], "Your new password doesn't match"),
});

export interface IResetPasswordInputType extends ITokenValidateInputType {
  password: string;
  reEnterPassword: string;
}
