import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutHeader } from "../../components/layout/LayoutFullHeight";
import { businessServiceURL, businessURL } from "../../config/url";
import { AppButton } from "../../components/form";
import ServiceAmount from "../../components/services/ServiceAmount";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useToast from "../../components/ui/toasts/useToast";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { Link } from "react-router-dom";
import { EBtnCategory } from "../../components/form/AppButton";
import { IServiceInputType } from "../../config/form/formService";

const useBusinessServicePage = (id?: string) => {
  const { getAdminServices, clearAdminServiceState } = useActions();
  const navigate = useNavigate();
  const { notify } = useToast();
  const [service, setService] = useState<IServiceDataType>();
  const { request, data, success, error } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  useEffect(() => {
    getAdminServices();
  }, []);

  useEffect(() => {
    if (!request) {
      // console.log("id-------", id);
      const selected = data.find((item) => item._id === id);
      selected ? setService(selected) : navigate(businessURL.BusinessServices);
      // console.log("get service", service);
    }
  }, [data, request]);

  useEffect(() => {
    if (success) {
      console.log("success", success);
      notify("success", success);
      clearAdminServiceState();
    } else if (error) {
      notify("error", "Failed to update the service. " + error);
      clearAdminServiceState();
    }
  }, [success, error]);

  const bookingType = service ? service.bookingType : 0;
  const serviceName = service ? service.name : "";
  useDocumentTitle(serviceName);

  const submenuList = [
    {
      name: "Overview",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Overview}`,
    },
    {
      name: "Service Info",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Info}`,
    },
    {
      name: "Booking Slot",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.BookingSlot}`,
    },
    {
      name: "Service Period",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.ServicePeriod}`,
    },
    {
      name: "Booking Period",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.BookingPeriod}`,
    },
    {
      name: "Provider",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Provider}`,
    },
    {
      name: "Location",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Location}`,
    },
    {
      name: "Advanced",
      url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Advanced}`,
    },
    // {
    //   name: resourceName,
    //   url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Resources}`,
    // },
    // {
    //   name: "Staff",
    //   url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Staff}`,
    // },
    // {
    //   name: "Customer",
    //   url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Customer}`,
    // },
    // {
    //   name: "Custom",
    //   url: `${businessURL.BusinessServices}/${id}/${businessServiceURL.Custom}`,
    // },
  ];
  const header = <LayoutHeader title={serviceName} />;

  const footer = (
    <>
      <AppButton
        category={EBtnCategory.Primary}
        type="submit"
        className="mr-20 mb-20"
      >
        Save
      </AppButton>
      <Link to={businessURL.BusinessServices} className="link">
        Cancel
      </Link>
    </>
  );
  // TODO: temporary submenu
  const submenu = [
    submenuList[0],
    submenuList[1],
    submenuList[2],
    submenuList[3],
    submenuList[4],
    submenuList[5],
    submenuList[6],
    submenuList[7],
    // submenuList[8],
    // submenuList[9],
  ];

  return { service, header, submenu, footer, bookingType };
};

export default useBusinessServicePage;
