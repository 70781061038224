import * as reducers from "../reducer/usageReducer";
import { apiCallBegan } from "../middleware/api";
import { getUsage as testUsage } from "../../data/dataUsage";

interface DispatchType {
  type: string;
}

const url = "/usage";

export const getUsage =
  (date: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("getUsage", date);
    return dispatch(
      apiCallBegan({
        url,
        data: testUsage(), // TODO: temporary
        onStart: reducers.usageRequest.type,
        onSuccess: reducers.usageReceived.type,
        onError: reducers.usageRequestFailed.type,
      })
    );
  };
