import classNames from "classnames";
import styles from "./ServiceAmount.module.scss";

const ServiceAmount: React.FC = () => {
  return null;
  // return (
  //   <>
  //     <span className={classNames(styles["value"], "h4 mr-5")}>RM0.50</span>
  //     <span className="desc">Per booking</span>
  //   </>
  // );
};

export default ServiceAmount;
