import styles from "./InputLabel.module.scss";

interface InputLabelProps {
  name?: string;
  labelWidth?: string;
  labelBlock?: boolean;
}

const InputLabel: React.FC<InputLabelProps> = ({
  name,
  labelWidth,
  labelBlock,
  children,
}) => {
  return (
    <div
      style={{
        width: !labelBlock ? (labelWidth ? labelWidth : "8em") : "auto",
      }}
    >
      <label className={styles["input-label"]} htmlFor={name && name}>
        {children}
      </label>
    </div>
  );
};

export default InputLabel;
