import { useParams } from "react-router-dom";
import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import useBusinessProviderPage from "./useBusinessProviderPage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import ProviderServices from "../../components/providers/ProviderServices";

const ProviderServicesPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { request: requestProvider } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const { request: requestService } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { provider, submenu, header } = useBusinessProviderPage(id);
  const { getAdminServices } = useActions();

  useEffect(() => {
    getAdminServices();
  }, []);

  return (
    <PageWithAside
      role="business"
      submenuName="Provider"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessProviders}
    >
      {header}
      <div className="wrapper">
        {requestProvider?.loading || (requestService?.loading && <Loader />)}
        {requestProvider?.error && (
          <div className="error-msg">{requestProvider.error}</div>
        )}
        {!requestProvider && !requestService && provider && (
          <ProviderServices provider={provider} />
        )}
      </div>
    </PageWithAside>
  );
};

export default ProviderServicesPage;
