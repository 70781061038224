import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  FaUserPlus,
  FaStore,
  FaUserFriends,
  FaRegCalendarPlus,
} from "react-icons/fa";
import { ImUsers, ImUserPlus } from "react-icons/im";
import { IoBriefcaseOutline } from "react-icons/io5";
import { BsCalendar2Plus } from "react-icons/bs";

import { businessURL, personalURL } from "../../config/url";
import { Dropdown } from "./dropdown";
import ModalMakeBooking from "../personal/ModalMakeBooking";

import styles from "./MenuCreateNew.module.scss";
import classNames from "classnames";
import DropdownItemList from "./dropdown/DropdownItemList";
import ModalAdminCreateBooking from "../createBooking/ModalAdminCreateBooking";
import ModalAddCustomer from "../customer/ModalAddCustomer";

interface MenuProps {
  role: "business" | "personal";
}

type MenuListType = {
  icon?: JSX.Element;
  name?: string;
  link?(): void;
  desc?: string;
  separator?: boolean;
}[];

const MenuCreateNew: React.FC<MenuProps> = ({ role }) => {
  const navigate = useNavigate();
  const [makeBooking, setMakeBooking] = useState(false);
  const [createBooking, setCreateBooking] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);

  const personalMenu = [
    {
      icon: <FaUserFriends />,
      name: "Host a meetup",
      desc: "Host a personal meetup with friends",
      onClick: () => navigate(personalURL.CreateMeetup),
    },
    // {
    //   icon: <FaStore />,
    //   name: "Business Booking",
    //   desc: "Book a service with service providers",
    //   onClick: () => setMakeBooking(true),
    // },
    // { separator: true },
    // {
    //   icon: <FaUserPlus />,
    //   name: "Invite Friend",
    //   desc: "Add a new friend to your list",
    //   link: () => setMakeBooking(true),
    // },
  ];

  const businessMenu = [
    {
      icon: <FaRegCalendarPlus />,
      name: "Create Booking",
      desc: "Create new booking for customer",
      onClick: () => setCreateBooking(true),
    },
    { separator: true },
    {
      icon: <IoBriefcaseOutline />,
      name: "Add Service",
      desc: "Create new service to accept booking",
      onClick: () => navigate(businessURL.BusinessServiceNew),
    },
    // { name: "New Staff" },
    {
      icon: <ImUserPlus />,
      name: "Add Customer",
      desc: "Invite new customer to make booking",
      onClick: () => setAddCustomer(true),
    },
  ];

  const activeMenu: MenuListType =
    role === "business" ? businessMenu : personalMenu;

  return (
    <div className={styles["container"]}>
      <Dropdown
        minWidth={350}
        menu={<DropdownItemList menuList={activeMenu} />}
        className={classNames(
          styles["button"],
          role === "business" && styles["business"]
        )}
      >
        +
      </Dropdown>
      {makeBooking && (
        <ModalMakeBooking onClose={() => setMakeBooking(false)} />
      )}
      {createBooking && (
        <ModalAdminCreateBooking onClose={() => setCreateBooking(false)} />
      )}
      {addCustomer && (
        <ModalAddCustomer onClose={() => setAddCustomer(false)} />
      )}
    </div>
  );
};

export default MenuCreateNew;
