import { Route, Navigate } from "react-router-dom";
import { personalURL } from "../config/url";
import PersonalHomePage from "../pages/personal/PersonalHomePage";
import PersonalCalendarPage from "../pages/personal/PersonalCalendarPage";
import PersonalSchedulePage from "../pages/personal/PersonalSchedulePage";
import PersonalFriendsPage from "../pages/personal/PersonalFriendsPage";
import PersonalBookmarkPage from "../pages/personal/PersonalBookmarkPage";
import PersonalCreateMeetupPage from "../pages/personal/PersonalCreateMeetupPage";
import PersonalRouter from "./PersonalRouter";

const personalRoutes = () => {
  return (
    <Route path={personalURL.Personal} element={<PersonalRouter />}>
      <Route path={personalURL.PersonalHome} element={<PersonalHomePage />} />
      <Route
        path={personalURL.CreateMeetup}
        element={<PersonalCreateMeetupPage />}
      />
      <Route
        path={personalURL.PersonalCalendar}
        element={<PersonalCalendarPage />}
      />
      <Route
        path={personalURL.PersonalSchedule}
        element={<PersonalSchedulePage />}
      />
      <Route
        path={personalURL.PersonalFriends}
        element={<PersonalFriendsPage />}
      />
      <Route
        path={personalURL.PersonalBookmark}
        element={<PersonalBookmarkPage />}
      />
      <Route
        index
        element={<Navigate to={personalURL.PersonalHome} replace />}
      />
    </Route>
  );
};

export default personalRoutes;
