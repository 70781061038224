import { useEffect, useState } from "react";
import { AppButton, AppForm } from "../../components/form";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import VerificationCode from "../../components/form/VerificationCode";
import { InitialValues, Validation } from "../../config/form/formRegisterEmail";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useRegisterStep from "./useRegisterStep";
import IconEmail from "../../assets/icons/Email.svg";
import ResendCode from "../../components/ui/ResendCode";
import Loader from "../../components/ui/Loader";
import { useNavigate } from "react-router";
import { authURL } from "../../config/url";
import { EBtnCategory } from "../../components/form/AppButton";
import { ETokenType } from "../../config/types/TokenType";

const AuthRegisterEmailPage: React.FC = () => {
  useDocumentTitle("Email Verification");
  const {
    registerEmail,
    createToken,
    clearRegisterState,
    clearTokenState,
    logout,
  } = useActions();
  const {
    loading,
    data: regData,
    error: regError,
    tokenId,
  } = useTypedSelector((state) => state.entities.register);
  const { tokenId: newTokenId, error: tokenError } = useTypedSelector(
    (state) => state.entities.token
  );
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const [error, setError] = useState(regError);
  const [initVal, setInitVal] = useState(InitialValues);
  const [btnEnable, setBtnEnable] = useState(false);
  const [email, setEmail] = useState<string>();
  useRegisterStep(1);

  useEffect(() => {
    // if (!tokenId || !newTokenId)
    console.log("tokenId", tokenId);
    tokenId && setInitVal({ ...initVal, tokenId });
    // else navigate(authURL.Register);
  }, [tokenId]);

  useEffect(() => {
    console.log("newTokenId", newTokenId);
    newTokenId && setInitVal({ ...initVal, tokenId: newTokenId });
  }, [newTokenId]);

  // useEffect(() => {
  //   if (newTokenId) {
  //     console.log("newTokenId", newTokenId);
  //     setInitVal({ ...initVal, tokenId: newTokenId });
  //   }
  // }, [newTokenId]);

  useEffect(() => {
    regData && setEmail(regData.email);
    userData && setEmail(userData.email);
  }, [regData, userData]);

  useEffect(() => {
    regError && setError(regError);
    tokenError && setError(tokenError);
  }, [regError, tokenError]);

  useEffect(() => {
    return () => {
      clearTokenState();
      clearRegisterState();
    };
  }, []);

  if (!email || !initVal.tokenId) {
    return <Loader />;
  }
  return (
    <LayoutCenterBox>
      <div className="mb-20 text-center">
        <img
          src={IconEmail}
          width="200"
          height="200"
          alt="Email Verification"
        />
        <h1>Email Verification</h1>
        <p>Just one quick check to make sure you're really you.</p>
        {error && <p className="error-msg">{error}</p>}
      </div>

      <AppForm
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          console.log("values", values);
          actions.setSubmitting(false);
          registerEmail(values);
        }}
      >
        <div className="text-center">Please enter the code sent to</div>
        <div className="mt-2 mb-2 h4 text-center">{email}</div>
        <div className="desc text-center mb-20">
          (Remember to check your Spam folder)
        </div>
        <VerificationCode
          size={6}
          name="code"
          onComplete={(complete) => setBtnEnable(complete)}
        />
        <div className="mb-30 mt-5 text-center">
          <ResendCode
            onClick={() =>
              createToken({ tokenType: ETokenType.registerEmail, email })
            }
          />
          .
        </div>

        <AppButton
          category={EBtnCategory.Primary}
          type="submit"
          btnBlock
          size="large"
          loading={loading || !btnEnable}
        >
          Verify Email (Step 2 of 3)
        </AppButton>
        <div className="mt-10 text-center">
          <AppButton className="btn btn-link" onClick={() => logout()}>
            Cancel
          </AppButton>
        </div>
      </AppForm>
    </LayoutCenterBox>
  );
};

export default AuthRegisterEmailPage;
