import { useEffect, useState } from "react";
import { ModalBox, ModalHeader, ModalBody, ModalFooter } from "../ui/modal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CustomBusinessHoursList from "./CustomBusinessHoursList";
import Loader from "../ui/Loader";
import useCustomBusinessHours from "./useCustomBusinessHours";
import { Col, Row } from "../ui/Grid";

// {
//   _id: "customday1",
//   business: "business1",
//   creator: "creator1",
//   customType: ECustomDayType.BusinessHours,
//   date: 9,
//   month: 7,
//   year: 2022,
//   businessHours: '["10:30","19:30"]',
//   businessHoursSum: 540,
//   dateInString: "2022-07-09",
//   monthInString: "2022-07",
//   fullDate: new Date(),
//   location: "location1",
//   timestamp: 1657728000000,
// },

interface IModalCustomBusinessHoursProps {
  onClose(): void;
}

const ModalCustomBusinessHours: React.FC<IModalCustomBusinessHoursProps> = ({
  onClose,
}) => {
  const { success, loading } = useTypedSelector(
    (state) => state.entities.location
  );
  const [isLoading, setIsLoading] = useState(true);

  const {
    handleRemove,
    handleSubmit,
    handleEditNew,
    handleEdit,
    handleChangeDate,
    handleAddNew,
    listEdit,
    listNew,
    errorRow,
    errorMsg,
    removeIds,
  } = useCustomBusinessHours();

  useEffect(() => {
    success && onClose();
  }, [success]);

  useEffect(() => {
    setIsLoading(loading ? true : false);
  }, [loading]);

  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      // size="small"
    >
      <ModalHeader title="Custom Business Hours" />
      <ModalBody>
        <div className="py-15 px-25">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <CustomBusinessHoursList
                existingData={listEdit}
                newData={listNew}
                onChangeDate={handleChangeDate}
                onEdit={handleEdit}
                onEditNew={handleEditNew}
                onAddNew={handleAddNew}
                onRemoveNew={(id) => handleRemove(id, true)}
                onRemove={(id) => handleRemove(id)}
                removeIds={removeIds}
                errorRow={errorRow}
              />
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            {errorMsg && <div className="mt-5 desc error-msg">{errorMsg}</div>}
          </Col>
          <Col>
            <button
              type="button"
              className="btn btn-link mr-10"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </ModalBox>
  );
};

export default ModalCustomBusinessHours;
