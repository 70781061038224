import React from "react";
import classnames from "classnames";
import { FaChevronLeft } from "react-icons/fa";
import styles from "./Modal.module.scss";

interface ModalHeaderProps {
  status?: string;
  title?: string;
  label?: JSX.Element;
  className?: string;
  backURL?(): void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  status,
  className,
  label,
  backURL,
}) => {
  return (
    <header className={classnames(styles["header"], className && className)}>
      {backURL && (
        <button onClick={() => backURL()} className="btn">
          <FaChevronLeft />
        </button>
      )}
      <div className={styles["title"]}>{title}</div>
      {label && label}
    </header>
  );
};
