import { useFormikContext } from "formik";
import Hours from "../common/hours";

interface FormikContextProps {
  [key: string]: string;
}

interface IAppHours {
  name: string;
  hoursSumName?: string;
}

const AppHours: React.FC<IAppHours> = ({ name, hoursSumName }) => {
  const { values, setFieldValue } = useFormikContext<FormikContextProps>();

  const handleChangeHour = (hours: string, hoursSum: string) => {
    setFieldValue(name, hours);
    hoursSumName && setFieldValue(hoursSumName, hoursSum);
  };

  return <Hours data={values[name]} onChange={handleChangeHour} />;
};

export default AppHours;
