import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTypedSelector } from "./useTypedSelector";
import { businessSetupURL } from "../config/url";
import useToast from "../components/ui/toasts/useToast";

const useBusinessRegisterStep = () => {
  const [loading, setLoading] = useState(true);
  const { data, request } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const navigate = useNavigate();
  const { notify } = useToast();

  useEffect(() => {
    if (!request && data && data.registerStep < 3) {
      if (data.registerStep === 1) {
        navigate(businessSetupURL.Contact);
      } else if (data.registerStep === 2) navigate(businessSetupURL.Hours);
      notify("info", "Please complete your business setup");
    } else setLoading(false);
  }, [data]);

  return { loading };
};

export default useBusinessRegisterStep;
