import { dayName } from "../../config/dateTimeFormat";
import useBusiness from "../../hooks/useBusiness";
import ListItem from "../ui/ListItem";
import styles from "./BusinessHourDisplay.module.scss";

interface BusinessHourDisplayProps {
  data: string;
  group?: boolean;
}

const BusinessHourDisplay: React.FC<BusinessHourDisplayProps> = ({
  data,
  group,
}) => {
  const { displayBusinessHours } = useBusiness();
  const hours: string[][] = JSON.parse(data);

  // const generateTime = (item: string[]) => {
  //   const output = item.map((time, key) => {
  //     if (time === "Off") return key === 0 ? null : <span key={key}>Off</span>;
  //     return key % 2 === 0 ? (
  //       <span key={key}>{convertTimeString(time)} - </span>
  //     ) : (
  //       <Fragment key={key}>
  //         <span>{convertTimeString(time)} </span>
  //         <br />
  //       </Fragment>
  //     );
  //   });
  //   return output;
  // };

  if (group)
    return (
      <ListItem label="Hours">
        {hours.map((item, index) => {
          return (
            <div className={styles["group"]} key={index}>
              <div key={index} className={styles["day-name"]}>
                {dayName[index]}
              </div>
              <div className={styles["hours"]}>
                {displayBusinessHours(item)}
              </div>
            </div>
          );
        })}
      </ListItem>
    );
  return (
    <>
      {hours.map((item, index) => {
        return (
          <ListItem
            key={index}
            label={dayName[index]}
            className={styles["item"]}
          >
            {displayBusinessHours(item)}
          </ListItem>
        );
      })}
    </>
  );
};

export default BusinessHourDisplay;
