import * as yup from "yup";

export const InitialValues = {
	name: "",
	label: "",
};

export const Validation = yup.object().shape({
	name: yup
		.string()
		.max(50, "Maximum 50 characters")
		.required("Please enter the name"),
	label: yup.string().required("Please enter the label"),
});

export interface IServiceDuplicateInputType {
	name: string;
	label: string;
}
