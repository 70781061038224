import useDocumentTitle from "../../hooks/useDocumentTitle";
import { AppForm } from "../form";
import { LayoutBody } from "../layout/LayoutFullHeight";
import BusinessRegisterFooter from "./BusinessRegisterFooter";
import { useActions } from "../../hooks/useActions";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessContact";
import BusinessContact from "../businessProfile/BusinessContact";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const BusinessRegisterBusinessContact: React.FC = () => {
  useDocumentTitle("Register a Business - Business Contact");
  const { error } = useTypedSelector((state) => state.entities.setup);
  const { data: regData } = useTypedSelector(
    (state) => state.entities.register
  );
  const { setupContact } = useActions();

  return (
    <AppForm
      className="flex-layout"
      initialValues={{
        ...InitialValues,
        name: "Main Location",
        email: regData?.email,
        country: regData?.country,
        isMain: true,
      }}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        console.log("values", values);
        setupContact(values);
      }}
    >
      <LayoutBody>
        <h3 className="mb-3">Business Contact</h3>
        <div className="desc mb-20">
          Enter your business contact so that customer can reach you easily.
        </div>
        {error && <p className="error-msg mb-20">{error}</p>}
        <BusinessContact />
      </LayoutBody>
      <BusinessRegisterFooter step={5} />
    </AppForm>
  );
};

export default BusinessRegisterBusinessContact;
