import { ICustomDayDataType } from "../../../config/types/CustomDayDataType";
import useCalendarSlot, {
  DataType,
  onClickBookingType,
  onSelectDateType,
} from "./useCalendarSlot";

interface CalendarBodyProps {
  currentMonth: number;
  currentYear: number;
  minDate?: Date;
  maxDate?: Date;
  selectedDate?: Date;
  data?: DataType[];
  onClickBooking?: onClickBookingType;
  onSelectDate?: onSelectDateType;
  type: "mini" | "full";
  showNeighboringMonth?: boolean;
  businessHours?: string;
  customBusinessHours?: ICustomDayDataType[];
}

const CalendarBody: React.FC<CalendarBodyProps> = ({
  currentMonth,
  currentYear,
  minDate,
  maxDate,
  selectedDate,
  data,
  onClickBooking,
  onSelectDate,
  type,
  showNeighboringMonth,
  businessHours,
  customBusinessHours,
}) => {
  const { rows } = useCalendarSlot({
    currentMonth,
    currentYear,
    type,
    data,
    minDate,
    maxDate,
    selectedDate,
    onClickBooking,
    onSelectDate,
    showNeighboringMonth,
    businessHours,
    customBusinessHours,
  });

  return (
    <tbody>
      {rows.map((d, i) => {
        return <tr key={i}>{d}</tr>;
      })}
    </tbody>
  );
};

export default CalendarBody;
