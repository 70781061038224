import classNames from "classnames";
import styles from "./EmptyData.module.scss";

interface EmptyDataProps {
  size?: "large" | "small";
  title?: string;
  image?: string;
  icon?: JSX.Element;
}

const EmptyData: React.FC<EmptyDataProps> = ({
  size = "medium",
  title,
  image,
  icon,
  children,
}) => {
  const titleClassName = size === "large" ? "h3" : "h4";
  const descClassName = size === "large" ? "text-secondary" : "desc";
  return (
    <div className={classNames("text-center", styles["container"])}>
      <div className={classNames(styles["image"], styles[`image-${size}`])}>
        {image && <img src={image} alt={title} />}
      </div>
      <div className={classNames(styles["icon"], styles[`icon-${size}`])}>
        {icon && icon}
      </div>
      <div className={titleClassName}>{title}</div>
      <div className={classNames(descClassName, styles["desc"])}>
        {children}
      </div>
    </div>
  );
};

export default EmptyData;
