import { useEffect, useState } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessInfo from "../../components/businessProfile/BusinessInfo";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AppForm } from "../../components/form";
import Loader from "../../components/ui/Loader";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessProfile";
import useToast from "../../components/ui/toasts/useToast";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 0;
const BusinessInfoPage: React.FC = () => {
  useDocumentTitle("Profile - AddPointment");
  const { updateBusinessProfile, clearBusinessState } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { notify } = useToast();
  const { header, footer } = useBusinessProfile(index, true);
  const [initVal, setInitVal] = useState(InitialValues);

  useEffect(() => {
    if (success) {
      notify("success", "Business info has been updated.");
      clearBusinessState();
    }
    if (error) {
      notify("error", error);
      clearBusinessState();
    }
  }, [success, error]);

  useEffect(() => {
    if (data) {
      setInitVal({
        ...initVal,
        ...data,
      });
    }
  }, [data]);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <AppForm
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          updateBusinessProfile(values);
        }}
        validationSchema={Validation}
      >
        <LayoutFullHeight>
          <LayoutBody>
            {header()}
            {request?.loading && <Loader />}
            {request?.error && <div className="error-msg">{request.error}</div>}
            {!request && data && (
              <>
                <BusinessInfo isEdit={true} />
                {footer()}
              </>
            )}
          </LayoutBody>
        </LayoutFullHeight>
      </AppForm>
    </PageWithAside>
  );
};

export default BusinessInfoPage;
