import {
  personalURL,
  businessURL,
  businessProfileURL,
  businessReportURL,
} from "./url";
import { BsCalendar, BsTag, BsPersonLinesFill } from "react-icons/bs";
import {
  IoHomeOutline,
  IoStorefrontOutline,
  IoBriefcaseOutline,
  IoCalendarOutline,
} from "react-icons/io5";
import { FaUserFriends, FaUserTie, FaRegIdCard } from "react-icons/fa";
import { ImUsers } from "react-icons/im";

export const personalMenu = [
  {
    name: "Home",
    url: personalURL.PersonalHome,
    icon: <IoHomeOutline />,
  },
  {
    name: "Schedule",
    url: personalURL.PersonalSchedule,
    icon: <BsCalendar />,
  },
  {
    name: "Friends",
    url: personalURL.PersonalFriends,
    icon: <FaUserFriends />,
  },
  {
    name: "Bookmark",
    url: personalURL.PersonalBookmark,
    icon: <BsTag />,
  },
  {
    name: "Profile",
    url: personalURL.PersonalProfile,
    icon: <BsPersonLinesFill />,
  },
];

export const businessMenu = [
  {
    name: "Dashboard",
    url: businessURL.BusinessDashboard,
    icon: <IoStorefrontOutline />,
  },
  {
    name: "Bookings",
    url: businessURL.BusinessToday,
    icon: <IoCalendarOutline />,
    submenu: [
      { name: "Today", url: businessURL.BusinessToday },
      { name: "Schedule", url: businessURL.BusinessSchedule },
      // { name: "Waiting List", url: businessURL.BusinessWaitingList },
    ],
  },
  {
    name: "Customer",
    url: businessURL.BusinessClientele,
    icon: <ImUsers />,
  },
  {
    name: "Services",
    url: businessURL.BusinessServices,
    icon: <IoBriefcaseOutline />,
  },
  {
    name: "Provider",
    url: businessURL.BusinessProviders,
    icon: <FaUserTie />,
  },
  // {
  //   name: "Resources",
  //   url: businessURL.BusinessResources,
  //   icon: <FiArchive />,
  // },
  {
    name: "Profile",
    url: businessURL.BusinessProfile,
    icon: <FaRegIdCard />,
  },
  // {
  //   name: "Tools",
  //   url: businessURL.BusinessNowServing,
  //   icon: <CgScreen />,
  //   submenu: [
  //     { name: "Now Serving", url: businessURL.BusinessNowServing },
  //     { name: "Meeting Point", url: businessURL.BusinessMeetingPoint },
  //     { name: "Walk-in", url: businessURL.BusinessWalkIn },
  //     { name: "Online Booking", url: businessURL.BusinessOnlineBooking },
  //   ],
  // },
  // {
  //   name: "Reports",
  //   url: businessURL.BusinessReport,
  //   icon: <SiGoogleanalytics />,
  // },
];
