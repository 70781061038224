import { Route, Navigate } from "react-router-dom";
import { personalURL } from "../config/url";
import PersonalChangeEmailPage from "../pages/personal/PersonalChangeEmailPage";
import PersonalChangePhonePage from "../pages/personal/PersonalChangePhonePage";
import PersonalProfilePage from "../pages/personal/PersonalProfilePage";
import PersonalPinNumberPage from "../pages/personal/PersonalPinNumberPage";
import PersonalChangePasswordPage from "../pages/personal/PersonalChangePasswordPage";
import PersonalContactPage from "../pages/personal/PersonalContactPage";
import PersonalRouter from "./PersonalRouter";
import PersonalSettingPage from "../pages/personal/PersonalSettingPage";

const personalProfileRoutes = () => {
  return (
    <Route path={personalURL.PersonalProfile} element={<PersonalRouter />}>
      <Route
        path={personalURL.PersonalChangePhone}
        element={<PersonalChangePhonePage />}
      />
      <Route
        path={personalURL.PersonalChangeEmail}
        element={<PersonalChangeEmailPage />}
      />
      <Route
        path={personalURL.PersonalSetting}
        element={<PersonalSettingPage />}
      />
      {/* <Route
        path={personalURL.PersonalProfilePin}
        element={<PersonalPinNumberPage />}
      />
      <Route
        path={personalURL.PersonalChangePassword}
        element={<PersonalChangePasswordPage />}
      /> */}
      <Route
        path={personalURL.PersonalContact}
        element={<PersonalContactPage />}
      />
      <Route
        path={personalURL.PersonalProfileInfo}
        element={<PersonalProfilePage />}
      />
      <Route
        index
        element={<Navigate to={personalURL.PersonalProfileInfo} replace />}
      />
    </Route>
  );
};

export default personalProfileRoutes;
