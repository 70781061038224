import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import BusinessSchedule from "../../components/businessBooking/BusinessSchedule";
import Loader from "../../components/ui/Loader";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useBookingStatusResponse from "../../components/businessBooking/useBookingStatusResponse";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const BusinessSchedulePage: React.FC = () => {
  const { getBusinessUpcomingBooking } = useActions();
  const { request, upcoming } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  useBookingStatusResponse();

  useEffect(() => {
    getBusinessUpcomingBooking();
  }, []);
  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Schedule"
          pageDesc="List of bookings that has been scheduled. You can view and manage each booking"
          role="business"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && upcoming && <BusinessSchedule />}
        <MenuCreateNew role="business" />
      </div>
    </PageWithAside>
  );
};

export default BusinessSchedulePage;
