import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomDayDataType } from "../../config/types/CustomDayDataType";
import { ILocationDataType } from "../../config/types/LocationDataType";

interface locationState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting location data
  error?: string;
  lastFetch?: number;
  success?: string;
  data: ILocationDataType[];
  customDay: ICustomDayDataType[];
  // update?: {
  //   loading?: boolean;
  //   success?: boolean;
  //   error?: string;
  // };
  // remove?: {
  //   loading?: boolean;
  //   success?: boolean;
  //   error?: string;
  // };
  // add?: {
  //   loading?: boolean;
  //   success?: ILocationDataType;
  //   error?: string;
  // };
}

const initialState: locationState = {
  loading: false,
  request: undefined,
  error: undefined,
  lastFetch: undefined,
  success: undefined,
  data: [],
  customDay: [],
  // update: undefined,
  // remove: undefined,
  // add: undefined,
};

const slice = createSlice({
  name: "location",
  initialState,
  reducers: {
    locationClearState: (location) => {
      location.loading = false;
      location.success = undefined;
      location.error = undefined;
    },
    locationRequest: (location) => {
      location.request = { loading: true };
    },
    locationReceived: (
      location,
      action: PayloadAction<ILocationDataType[]>
    ) => {
      console.log("locationReceived", action.payload);
      location.data = action.payload;
      location.request = undefined;
      location.lastFetch = Date.now();
    },
    locationRequestFailed: (location, action: PayloadAction<string>) => {
      console.log("locationRequestFailed", action.payload);
      location.loading = false;
      location.request = { error: action.payload };
    },
    locationLoading: (location) => {
      location.loading = true;
      location.success = undefined;
      location.error = undefined;
    },
    locationFailed: (location, action: PayloadAction<string>) => {
      location.loading = false;
      location.error = action.payload;
    },
    locationUpdated: (location, action: PayloadAction<ILocationDataType>) => {
      console.log("locationUpdated", action.payload);
      const index = location.data.findIndex(
        (item) => item._id === action.payload._id
      );
      location.data[index] = action.payload;
      location.loading = false;
      location.success = "Location has been updated";
    },
    locationRemoved: (
      location,
      action: PayloadAction<{ actionData: string }>
    ) => {
      location.data = location.data.filter(
        (item) => item._id !== action.payload.actionData
      );
      location.loading = false;
      location.success = "Location has been removed";
    },
    locationAdded: (location, action: PayloadAction<ILocationDataType>) => {
      location.data = [...location.data, action.payload];
      location.loading = false;
      location.success = "New location has been created";
    },
    locationCustomDayReceived: (
      location,
      action: PayloadAction<ICustomDayDataType[]>
    ) => {
      console.log("locationCustomDayReceived", action.payload);
      location.customDay = action.payload;
      location.loading = false;
    },
    locationCustomDayUpdated: (location) => {
      location.loading = false;
      location.success = "Hours have been updated";
    },
  },
});

export const {
  locationClearState,
  locationRequest,
  locationReceived,
  locationRequestFailed,
  locationLoading,
  locationFailed,
  locationUpdated,
  locationRemoved,
  locationAdded,
  locationCustomDayReceived,
  locationCustomDayUpdated,
} = slice.actions;

export default slice.reducer;
