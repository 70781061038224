import { useFormikContext } from "formik";

interface FormikContextProps {
  [key: string]: string | [key: string];
}

const AppErrors = () => {
  const { errors } = useFormikContext<FormikContextProps>();

  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }
  console.log("errors", errors);
  return (
    <div className="mt-3 error-msg">
      <span className="text-semibold">Validation failed: </span>
      {errors[Object.keys(errors)[0]]}
    </div>
  );
};
export default AppErrors;
