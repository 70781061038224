import { useState } from "react";
import classNames from "classnames";

import styles from "./TodayBooking.module.scss";
import { ApptStatus, getApptStatus } from "../appointment/ApptStatus";
import ModalPersonalBooking from "../personalBooking/ModalPersonalBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import useTodayBooking from "./useTodayBooking";
import { getTodayBooking } from "../../store/selector";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getDate } from "../../utils/datetime";

const TodayBooking: React.FC = () => {
  const data: (IBookingDataType & IMeetupDataType)[] =
    useTypedSelector(getTodayBooking);
  console.log("TodayBooking", data);

  const { nav, filteredData, wrapperRef, sliderWidth, margin, cardWidth } =
    useTodayBooking(data);

  const [selectedAppt, setSelectedAppt] = useState<string | null>();
  if (!data || data.length === 0) return null;

  // const onApptView = (id: string) => {
  //   const today = data.find((appt) => appt._id === id);
  //   if (today) {
  //     setSelectedAppt(today);
  //   }
  // };

  return (
    <>
      <div className={styles["today-booking"]}>
        <h3>Today</h3>
        <span className="desc">{getDate(new Date(), "DD MMM")}</span>
        <div className={styles["container"]}>
          <div ref={wrapperRef} className={styles["wrapper"]}>
            <ul
              className={styles["slider"]}
              style={{ width: sliderWidth, marginLeft: margin }}
            >
              {filteredData.map((item, index) => {
                const apptStatusNo = getApptStatus(item.status);
                return (
                  <li key={index} style={{ width: cardWidth }}>
                    <div
                      onClick={() => setSelectedAppt(item.id)}
                      style={{ width: cardWidth - 20 }}
                      className={classNames(
                        styles["card"],
                        styles[`card-${apptStatusNo?.category}`]
                      )}
                    >
                      <div className={styles["appt-status"]}>
                        <ApptStatus
                          dateTime={item.meetDate}
                          statusNo={item.status}
                          timestamp={item.meetDateTimestamp}
                        />
                      </div>
                      <div className={styles["booking-info"]}>
                        {item.queueNo && (
                          <>
                            <div className="desc">Queue No.</div>
                            <div className={styles["booking-no"]}>
                              {item.queueNo}
                            </div>
                          </>
                        )}
                        <div className="h5 mb-5">{item.subject}</div>
                        <div className="desc">{item.desc}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {nav}
        </div>
      </div>
      {selectedAppt && (
        <ModalPersonalBooking
          onClose={() => setSelectedAppt(null)}
          // data={selectedAppt}
          id={selectedAppt}
        />
      )}
    </>
  );
};

export default TodayBooking;
