import React from "react";
import classNames from "classnames";
import styles from "./CreateBookingTime.module.scss";
import { getTime } from "../../utils/datetime";

type DataCapacityType = { [key: number]: { [key: number]: number } };

interface IuseTimeslotPickerProps {
  timeRange?: string[];
  date: string;
  capacity: number;
  interval: number;
  dataCapacity?: DataCapacityType;
}

const useTimeslotPicker = ({
  timeRange,
  date,
  capacity,
  interval,
  dataCapacity,
}: IuseTimeslotPickerProps) => {
  function calculateCapacity(itemHour: number, itemMinutes: number) {
    let currentCapacity = 0;
    if (dataCapacity && itemHour in dataCapacity) {
      if (interval <= 5) {
        currentCapacity = dataCapacity[itemHour][itemMinutes];
      } else {
        // If interval more than 5, get the highest capacity
        const capacityArray = [];
        for (let i = itemMinutes; i < itemMinutes + interval; i += 5) {
          const result = dataCapacity[itemHour][i]
            ? dataCapacity[itemHour][i]
            : 0;
          capacityArray.push(result);
        }
        currentCapacity = Math.max(...capacityArray);
      }
    }
    // console.log(itemHour, itemMinutes, "currentCapacity", currentCapacity);
    return currentCapacity && currentCapacity > 0
      ? (currentCapacity / capacity) * 100
      : 0;
  }

  function generateSeperator(flag: number, itemHour: number, item: Date) {
    return flag !== itemHour ? (
      <li className={styles["separator"]}>
        {new Date(item).toLocaleString("en-AU", {
          hour: "numeric",
          hour12: true,
        })}
      </li>
    ) : (
      false
    );
  }

  function generateTimeValue() {
    if (timeRange) {
      console.log("timeRange", timeRange);
      let timeValue: Date[] = [];
      for (
        let d = new Date(date + " " + timeRange[0]);
        d < new Date(date + " " + timeRange[1]);
        d.setTime(d.getTime() + interval * 60 * 1000)
      ) {
        timeValue.push(new Date(d));
      }
      if (timeRange.length > 1) {
        for (
          let d = new Date(date + " " + timeRange[2]);
          d < new Date(date + " " + timeRange[3]);
          d.setTime(d.getTime() + interval * 60 * 1000)
        ) {
          timeValue.push(new Date(d));
        }
      }
      if (timeRange.length > 3) {
        for (
          let d = new Date(date + " " + timeRange[4]);
          d < new Date(date + " " + timeRange[5]);
          d.setTime(d.getTime() + interval * 60 * 1000)
        ) {
          timeValue.push(new Date(d));
        }
      }
      return timeValue;
    }
    return null;
  }

  function generateList(onChange: (val: Date) => void, value?: Date) {
    const timeValue = generateTimeValue();
    let flag = 1; // Hour flag
    const output =
      timeValue &&
      timeValue.map((item, index) => {
        const itemHour = item.getHours();
        const itemMinutes = item.getMinutes();
        const capacityPercent = calculateCapacity(itemHour, itemMinutes);
        const fulled = capacityPercent >= 100;

        const separator = generateSeperator(flag, itemHour, item);
        flag = itemHour;
        return (
          <React.Fragment key={index}>
            {separator && separator}
            <li className={styles["item"]}>
              <button
                type="button"
                className={classNames(
                  styles["btn-time"],
                  value &&
                    getTime(value) === getTime(item) &&
                    styles["selected"],
                  fulled && styles["fulled"]
                )}
                // disabled={fulled}
                onClick={() => onChange(item)}
              >
                <span
                  className={styles["time-bar"]}
                  style={{ width: capacityPercent + "%" }}
                ></span>
                <span className={styles["time-text"]}>{getTime(item)}</span>
              </button>
            </li>
          </React.Fragment>
        );
      });
    return output;
  }
  return { generateList };
};

export default useTimeslotPicker;
