import IconDoctor from "../../assets/icons/Doctor.svg";
import IconDentist from "../../assets/icons/Dentist.svg";
import IconPractitioner from "../../assets/icons/Practitioner.svg";
import IconStylist from "../../assets/icons/Stylist.svg";
import IconSpecialist from "../../assets/icons/Specialist.svg";
import IconTrainer from "../../assets/icons/Trainer.svg";
import IconStaff from "../../assets/icons/Staff.svg";
import IconTechnician from "../../assets/icons/Technician.svg";

export const providerAvailablePeriodOption = [
  {
    name: "Same as business hour",
    desc: "The service period will be the same as your business hour",
    value: 1,
  },
  {
    name: "Custom provider hour",
    desc: "The provider available period will be difference than your business hour",
    value: 2,
  },
  // {
  //   name: "Custom day and time",
  //   desc: "The service will only available on certain day and time",
  //   value: 3,
  // },
];

// Provider Profession Type Name
export const providerType = [
  {
    name: "Doctor",
    value: 1,
  },
  {
    name: "Dentist",
    value: 2,
  },
  {
    name: "Stylist",
    value: 3,
  },
  {
    name: "Practitioner",
    value: 4,
  },
  {
    name: "Specialist",
    value: 5,
  },
  {
    name: "Trainer",
    value: 6,
  },
  {
    name: "Technician",
    value: 7,
  },
  {
    name: "Staff",
    value: 8,
  },
];
