import * as yup from "yup";
import {
  InitialValues as ServicePeriodInit,
  IServicePeriodType,
  ServicePeriodValidation,
} from "./formServicePeriod";
import {
  IBookingPeriodType,
  InitialValues as BookingPeriodInit,
  BookingPeriodValidation,
} from "./formBookingPeriod";

export const InitialValues = {
  // Service Info --
  name: "",
  label: "",
  desc: "",
  bookingType: 0,
  imageUrl: "",
  category: undefined,
  categoryName: undefined,
  // Booking Slot --
  duration: 5,
  slotInterval: 5,
  bookingCapacity: 1,
  dailyCapacity: "",
  maxSlotSelection: 1,
  ...ServicePeriodInit,
  ...BookingPeriodInit,
  onsiteService: false,
  autoDelete: true,
  requestCustomerName: true,
};

export const Validation = yup.object().shape({
  name: yup
    .string()
    .max(50, "Maximum 50 characters")
    .required("Please enter the service name"),
  label: yup.string().required("Please select a label"),
  desc: yup.string().max(300, "Maximum 300 characters"),
  duration: yup
    .number()
    .min(5, "Minimum 5 minutes for duration")
    .required("Please enter service duration"),
  slotInterval: yup
    .number()
    .min(5, "Minimum 5 minutes for slot interval")
    .required("Please enter the slot interval"),
  bookingCapacity: yup
    .number()
    .min(1, "Minimum 1 for capacity")
    .required("Please enter the booking capacity"),
  ...ServicePeriodValidation,
  ...BookingPeriodValidation,
});

export interface IServiceInputType
  extends IServicePeriodType,
    IBookingPeriodType {
  name: string;
  label: string;
  desc: string;
  bookingType: number;
  imageUrl: string;
  category?: string;
  categoryName?: string;
  duration: number;
  slotInterval: number;
  onsiteService: boolean;
  bookingCapacity: number;
  dailyCapacity: string;
  maxSlotSelection: number;
  autoDelete: boolean;
  requestCustomerName: boolean;
}
