import config from "../config/global";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const loadRecaptcha = () => {
  const script = document.createElement("script");

  script.src = `https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`;
  script.id = "recaptcha-script";
  script.async = true;

  document.body.append(script);
};

export const removeRecaptcha = () => {
  const script = document.getElementById("recaptcha-script");
  if (script) {
    script.remove();
  }

  const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
  if (recaptchaElems.length) {
    recaptchaElems[0].remove();
  }
};

export async function getRecaptchaToken(action: string) {
  // let token = "";
  // await window.grecaptcha
  //   .execute(config.recaptchaSiteKey, { action })
  //   .then((res: string) => {
  //     token = res;
  //   })
  //   .error(() => {
  //     return { error: true };
  //   });
  // return token;
  try {
    const token = await window.grecaptcha.execute(config.recaptchaSiteKey, {
      action,
    });
    return token;
  } catch (error) {
    console.log("error", error);
  }
}
