import { createSlice } from "@reduxjs/toolkit";
import { UsageType } from "../../config/types/UsageType";

interface UsageState {
  loading: boolean;
  error: boolean | string;
  data?: UsageType;
}

const initialState: UsageState = {
  loading: true,
  error: false,
  data: undefined,
};

const slice = createSlice({
  name: "usage",
  initialState,
  reducers: {
    usageClearState: (usage) => {
      usage.error = false;
    },
    usageRequest: (usage) => {
      usage.loading = true;
    },
    usageReceived: (usage, action) => {
      console.log("usageReceived", action.payload);
      usage.data = action.payload;
      usage.loading = false;
    },
    usageRequestFailed: (usage, action) => {
      usage.loading = false;
      usage.error = action.payload;
    },
  },
});

export const {
  usageClearState,
  usageRequest,
  usageReceived,
  usageRequestFailed,
} = slice.actions;

export default slice.reducer;
