import classnames from "classnames";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import styles from "./SubMenu.module.scss";
import { NavLink } from "react-router-dom";

interface AsideProps {
  role: "personal" | "business";
  name: string;
  list: { name: string; url: string }[];
  goUpLevel?: string;
}

const SubMenu: React.FC<AsideProps> = ({ name, role, list, goUpLevel }) => {
  return (
    <div className={styles["container"]}>
      <h3>
        {goUpLevel && (
          <Link to={goUpLevel} className={styles["btn-back"]}>
            <FaChevronLeft />
          </Link>
        )}
        {name}
      </h3>
      <ul>
        {list.map((item, index) => {
          return (
            <li key={index}>
              <NavLink
                to={item.url}
                className={({ isActive }) =>
                  classnames(isActive && styles["active"])
                }
              >
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SubMenu;
