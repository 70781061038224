import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import styles from "./AppInput.module.scss";
import classNames from "classnames";
import useCharacterCount from "./useCharacterCount";

interface InputProps {
  type?: string;
  readonly?: boolean;
  disabled?: boolean;
  name: string;
  label?: string;
  defaultValue?: string | number;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  placeholder?: string;
  inputWidth?: string;
  prefix?: string;
  suffix?: string | JSX.Element;
  minLength?: number;
  maxLength?: number;
  maxCharacter?: number;
  onBlur?: () => void;
  onClick?: () => void;
  itemAfter?: string | JSX.Element;
  autoFocus?: boolean;
}

interface FormikContextProps {
  [key: string]: string | [key: string];
}

const AppInput: React.FC<InputProps> = ({
  name,
  defaultValue,
  readonly,
  disabled,
  helperMsg,
  label,
  labelWidth,
  placeholder,
  type = "text",
  labelBlock,
  inputWidth,
  prefix,
  suffix,
  minLength = 0,
  maxLength = 255,
  maxCharacter,
  onBlur,
  onClick,
  itemAfter,
  autoFocus,
}) => {
  const { handleChange, errors, values } =
    useFormikContext<FormikContextProps>();
  const { characterLeft } = useCharacterCount(values[name], maxCharacter);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className={styles["container"]}>
        <div
          className={classNames(
            styles["input"],
            suffix && styles["has-suffix"],
            prefix && styles["has-prefix"]
          )}
          style={{ width: inputWidth ? inputWidth : "100%" }}
        >
          <input
            id={name}
            // ref={ref}
            type={type}
            name={name}
            autoComplete="off"
            autoFocus={autoFocus}
            value={defaultValue ? defaultValue : values[name]}
            // defaultValue={defaultValue}
            onChange={handleChange}
            onClick={onClick}
            placeholder={placeholder}
            className="input-control"
            readOnly={readonly ? true : false}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxCharacter ? maxCharacter : maxLength}
            onBlur={onBlur}
          />
          {maxCharacter && (
            <span className={styles["count"]}>{characterLeft}</span>
          )}
          {suffix && <div className={styles["suffix"]}>{suffix}</div>}
        </div>
        {itemAfter && itemAfter}
      </div>
    </InputContainer>
  );
};

export default AppInput;
