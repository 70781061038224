import * as reducers from "../reducer/meetingPointReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getMeetingPoints as testMeetingPoints } from "../../data/dataMeetingPoint";
import { MeetingPointFormType } from "../../config/form/formMeetingPoint";

interface DispatchType {
  type: string;
}

const url = "/meeting-point";

export const clearMeetingPointState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.meetingPointClearState.type });
  };

export const getMeetingPoints =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { meetingPoint: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.meetingPoint;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        data: testMeetingPoints(), // TODO: temporary
        onStart: reducers.meetingPointRequest.type,
        onSuccess: reducers.meetingPointReceived.type,
        onError: reducers.meetingPointRequestFailed.type,
      })
    );
  };

export const addMeetingPoint =
  (data: MeetingPointFormType) => (dispatch: (arg: DispatchType) => void) => {
    const meetingPointData = {
      ...data,
      id: "meeting-point-new", // TODO: temporary
    };
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: meetingPointData, // TODO: use data
        method: "post",
        onStart: reducers.meetingPointAdding.type,
        onSuccess: reducers.meetingPointAdded.type,
        onError: reducers.meetingPointAddFailed.type,
      })
    );
  };

export const updateMeetingPoint =
  (id: string, data: MeetingPointFormType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("updateMeetingPoint", id);
    console.log("data", data);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data,
        method: "post",
        onStart: reducers.meetingPointUpdating.type,
        onSuccess: reducers.meetingPointUpdated.type,
        onError: reducers.meetingPointUpdateFailed.type,
      })
    );
  };

export const removeMeetingPoint =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeMeetingPoint", id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: id,
        method: "post",
        onStart: reducers.meetingPointRemoving.type,
        onSuccess: reducers.meetingPointRemoved.type,
        onError: reducers.meetingPointRemoveFailed.type,
      })
    );
  };
