import * as yup from "yup";
import { genderField } from "./validationFields";
import { pin } from "./patterns";
import {
  BaseInitialValues as ProfileBaseInit,
  BaseValidation as ProfileBaseValidate,
} from "./formPersonalProfile";
import {
  InitialValues as ContactBaseInit,
  ContactValidation as ContactBaseValidate,
  IPersonalContactInputType,
} from "./formPersonalContact";

export const InitialValues = {
  ...ProfileBaseInit,
  ...ContactBaseInit,
  age: "",
  pin: "",
};

export const Validation = yup.object().shape({
  ...ProfileBaseValidate,
  ...ContactBaseValidate,
  gender: genderField.required("Please select your gender"),
  age: yup
    .number()
    .required("Please enter your current age")
    .min(1, "Please enter your current age correctly")
    .max(100, "Please enter your current age correctly"),
  pin: yup
    .string()
    .required("Please enter your PIN number")
    .min(4, "PIN number must be 4 digit")
    .max(4, "PIN number must be 4 digit")
    .matches(pin, "PIN number must be 4 digit"),
});

export interface IRegisterProfileInputType extends IPersonalContactInputType {
  displayName: string;
  gender: string;
  age: string;
  pin: string;
}
