import Table from "../ui/table";
import { ApptDateCalendar } from "../appointment/ApptDateTime";
import { ApptStatus } from "../appointment/ApptStatus";
import { getTime } from "../../utils/datetime";
import styles from "./ScheduleList.module.scss";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import ApptTypeName from "../appointment/ApptTypeName";
import BookingActionMenu, { EBookingActionMenuType } from "./BookingActionMenu";
import prefixId, { idType } from "../../utils/prefixID";

interface TableProps {
  data: IBookingDataType[];
  onApptView(id: string): void;
}

const TableScheduleList: React.FC<TableProps> = ({ data, onApptView }) => {
  const columns = [
    {
      label: "Date",
      name: "date",
      colWidth: "80px",
      colMaxWidth: "100px",
      cellClass: styles["col-date"],
      content: (booking: { meetDateTime: Date }) => (
        <>
          <ApptDateCalendar date={booking.meetDateTime} />
        </>
      ),
    },
    {
      label: "Time",
      name: "time",
      colWidth: "100px",
      colMaxWidth: "120px",
      cellClass: styles["col-time"],
      content: (booking: {
        meetDateTime: Date;
        meetDate: string;
        bookingStatus: number;
        meetDateTimestamp: number;
      }) => (
        <div>
          <span className="mr-5">{getTime(booking.meetDateTime)}</span>
          <ApptStatus
            statusNo={booking.bookingStatus}
            dateTime={booking.meetDate}
            timestamp={booking.meetDateTimestamp}
            dot
          />
          {/* <div className="desc pl-15">{getDayName(booking.meetDateTime)}</div> */}
        </div>
      ),
    },
    // TODO: customer displayname is compulsory
    {
      label: "Customer",
      name: "customer",
      colWidth: "15%",
      colMaxWidth: "20%",
      cellClass: styles["col-customer"],
      content: (booking: {
        customerData: { displayName: string; email?: string };
        customerUid: string;
      }) => (
        <>
          {booking.customerData.displayName && booking.customerData.displayName}
          {/* <div className="desc">
            {prefixId(booking.customerUid, idType.Customer)}
          </div> */}
          {/* {booking.customerData.displayName} */}
        </>
      ),
    },
    {
      label: "Service",
      name: "service",
      colWidth: "15%",
      cellClass: styles["col-service"],
      content: (booking: IBookingDataType & IMeetupDataType) => (
        <ApptTypeName data={booking} />
      ),
    },
    // TODO: temporary hide
    // {
    //   label: "Staff",
    //   name: "staff",
    //   colWidth: "10%",
    //   colMaxWidth: "15%",
    //   cellClass: styles["col-staff"],
    //   content: (booking: { service: { staff: string } }) => (
    //     <>{booking.service.staff}</>
    //   ),
    // },
    {
      name: "status",
      colWidth: "150px",
      colMaxWidth: "150px",
      cellClass: styles["col-status"],
      content: (booking: {
        bookingStatus: number;
        meetDate: string;
        meetDateTimestamp: number;
      }) => (
        <ApptStatus
          statusNo={booking.bookingStatus}
          dateTime={booking.meetDate}
          timestamp={booking.meetDateTimestamp}
        />
      ),
    },
    {
      name: "action",
      colWidth: "100px",
      colMaxWidth: "120px",
      cellClass: styles["col-action"],
      disableItemClick: true,
      content: (booking: IBookingDataType) => (
        <BookingActionMenu
          data={booking}
          type={EBookingActionMenuType.TableAction}
          onView={onApptView}
        />
      ),
    },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "80px",
      cellClass: styles["col-actions"],
      disableItemClick: true,
      content: (booking: IBookingDataType) => (
        <BookingActionMenu
          data={booking}
          type={EBookingActionMenuType.Table}
          onView={onApptView}
        />
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      className={styles["table"]}
      itemClick={(booking: { _id: string }) => onApptView(booking._id)}
    />
  );
};

export default TableScheduleList;
