import TableProviders from "./TableProviders";
import { AppButton } from "../../components/form";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import EmptyData from "../ui/EmptyData";
import iconProvider from "../../assets/images/provider.svg";
import { EBtnCategory } from "../form/AppButton";
import { useState } from "react";
import ModalAddProvider from "./ModalAddProvider";

const BusinessProviders: React.FC = ({}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { data } = useTypedSelector((state) => state.entities.providerAdmin);

  if (!data || data.length === 0)
    return (
      <>
        <EmptyData
          title="Manage your provider"
          size="large"
          image={iconProvider}
        >
          <p>
            This is where you can manage your staff members and let user make
            appointment with them. Provider can be a doctor, stylist,
            specialist, practitioner or technician.
          </p>
          <button
            className="btn btn-primary-outline mt-10"
            type="button"
            onClick={() => setModalVisible(true)}
          >
            Add Provider
          </button>
        </EmptyData>

        {modalVisible && (
          <ModalAddProvider onClose={() => setModalVisible(false)} />
        )}
      </>
    );

  return (
    <>
      <TableProviders data={data} />
      <AppButton
        type="button"
        category={EBtnCategory.PrimaryOutline}
        className="mt-30"
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Add New Provider
      </AppButton>

      {modalVisible && (
        <ModalAddProvider onClose={() => setModalVisible(false)} />
      )}
    </>
  );
};

export default BusinessProviders;
