import classNames from "classnames";
import styles from "./Tooltip.module.scss";
import {
  FaQuestionCircle,
  FaExclamationCircle,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";

interface TooltipProps {
  message: string;
  position?: "left" | "right" | "top" | "bottom";
  type?: "info" | "tips";
}

const Tooltips: React.FC<TooltipProps> = ({
  message,
  position = "top",
  type,
  children,
}) => {
  const [icon, setIcon] = useState<React.ReactElement>();

  useEffect(() => {
    if (type) {
      if (type === "info") setIcon(<BsExclamationCircle />);
      else if (type === "tips") setIcon(<FaRegQuestionCircle />);
    }
  }, [type]);
  return (
    <div className={styles["tooltip"]}>
      {children}
      {icon && <span className={styles["icon"]}>{icon}</span>}
      <span
        className={classNames(
          styles["tooltiptext"],
          position === "left" && styles["tooltip-left"],
          position === "right" && styles["tooltip-right"],
          position === "top" && styles["tooltip-top"],
          position === "bottom" && styles["tooltip-bottom"]
        )}
      >
        {message}
      </span>
    </div>
  );
};
export default Tooltips;
