import useIsMobileView from "../../../hooks/useIsMobileView";

const useTableColumn = () => {
  const isMobileView = useIsMobileView();
  const setWidth = (colWidth?: string, colMaxWith?: string) => {
    const width = !isMobileView && colWidth ? colWidth : null;
    const maxWidth = !isMobileView && colMaxWith ? colMaxWith : null;
    const output =  width
      ? { minWidth: colWidth, flexBasis: colWidth}
      : { width: "auto" };
      return maxWidth ? {...output, maxWidth} : output
  };
  return setWidth;
};

export default useTableColumn;
