import * as reducers from "../reducer/walkInReducer";
import { fetch } from "../storeConfig";
import { bookingDateTime } from "../../utils/booking";
import { apiCallBegan } from "../middleware/api";
import { getWalkInServices } from "../../data/dataServices";
import { getWalkInResources } from "../../data/dataResources";
import { getWalkInProviders } from "../../data/dataProviders";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { ResourceType } from "../../config/types/ResourceType";
import { IProviderDataType } from "../../config/types/ProviderDataType";

interface DispatchType {
  type: string;
}

const url = "/walkin";

export const getWalkIn =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { walkIn: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.walkIn;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    const services = getWalkInServices();
    const resources = getWalkInResources();
    const providers = getWalkInProviders();
    return dispatch(
      apiCallBegan({
        url,
        data: { services, resources, providers }, // TODO: temporary
        onStart: reducers.walkInRequest.type,
        onSuccess: reducers.walkInReceived.type,
        onError: reducers.walkInRequestFailed.type,
      })
    );
  };

export const registerWalkIn =
  (data: {
    [key: string]: IServiceDataType | ResourceType | IProviderDataType;
  }) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { business: { data: IBusinessDataType } } }
  ) => {
    const { data: business } = getState().entities.business;
    const dateTime = bookingDateTime(new Date());
    const bookingData = {
      ...data,
      ...dateTime,
      business,
    };
    console.log("bookingData", bookingData);
    return dispatch(
      apiCallBegan({
        url,
        data: "1234", // TODO: temporary
        onStart: reducers.walkInRegister.type,
        onSuccess: reducers.walkInRegistered.type,
        onError: reducers.walkInRegisterFailed.type,
      })
    );
  };
