import { useEffect } from "react";
import { useFormikContext } from "formik";
import BusinessHourDisplay from "../business/BusinessHourDisplay";
import { AppHours } from "../form";
import { AppSelectMenu } from "../form";
import { servicePeriodOption } from "../../config/option/optionService";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import Loader from "../ui/Loader";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { IProviderDataType } from "../../config/types/ProviderDataType";
// import { defaultHours } from "../../config/option/optionBusiness";

interface FormikContextProps {
  [name: string]: boolean & string;
}

interface ServiceHourProps {
  service?: IServiceDataType;
  optionName: string;
  hoursName: string;
  hoursSumName: string;
}

const ServiceHour: React.FC<ServiceHourProps> = ({
  service,
  optionName,
  hoursName,
  hoursSumName,
}) => {
  const { getLocation, getAdminProviders } = useActions();
  const { data: locationData, request } = useTypedSelector(
    (state) => state.entities.location
  );

  const { data: providerData } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );

  useEffect(() => {
    getAdminProviders();
  }, [providerData]);

  const { setFieldValue, values } = useFormikContext<FormikContextProps>();

  useEffect(() => {
    if (values[optionName] === 1 && locationData.length > 0) {
      console.log("same business hour");
      setFieldValue(hoursName, locationData[0].hours);
      setFieldValue(hoursSumName, locationData[0].hoursSum);
    }
  }, [values, locationData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (request || locationData.length === 0) return <Loader />;

  const providerServicePeriodList: IProviderDataType[] = [];

  service?.providers?.map((id) => {
    const item = providerData.find((provider) => provider._id === id);

    item && providerServicePeriodList.push(item);
  });

  return (
    <>
      {providerServicePeriodList.length > 0 ? (
        providerServicePeriodList.map((provider: IProviderDataType) => (
          <div className="mb-30" key={provider._id}>
            <h4 className="mb-3">{provider.name}</h4>
            {provider.hours ? (
              <BusinessHourDisplay data={provider.hours} />
            ) : (
              <p className="text-italic">No Service Period</p>
            )}
          </div>
        ))
      ) : (
        <>
          <AppSelectMenu
            name={optionName}
            inputWidth="300px"
            options={servicePeriodOption}
          />

          {values[optionName] === 1 && (
            <BusinessHourDisplay data={locationData[0].hours} />
          )}
          {values[optionName] === 2 && (
            <AppHours name={hoursName} hoursSumName={hoursSumName} />
          )}
        </>
      )}
    </>
  );
};

export default ServiceHour;
