import {
  personalURL,
  businessProfileURL,
  businessReportURL,
  businessURL,
} from "./url";

export const personalProfileSubmenu = [
  {
    name: "Personal Info",
    url: personalURL.PersonalProfileInfo,
  },
  {
    name: "Personal Contact",
    url: personalURL.PersonalContact,
  },
  {
    name: "Settings",
    url: personalURL.PersonalSetting,
  },
  // {
  //   name: "PIN Number",
  //   url: personalURL.PersonalProfilePin,
  // },
  // {
  //   name: "Change Password",
  //   url: personalURL.PersonalChangePassword,
  // },
];

export const businessProfileSubmenu = [
  {
    name: "Business Info",
    url: businessProfileURL.Info,
  },
  {
    name: "Business Contact",
    url: businessProfileURL.Contact,
  },
  {
    name: "Business Hours",
    url: businessProfileURL.Hours,
  },
  {
    name: "QR Code",
    url: businessProfileURL.QRCode,
  },
  // {
  //   name: "Holidays",
  //   url: businessProfileURL.Holidays,
  // },
  // {
  //   name: "Location",
  //   url: businessProfileURL.Locations,
  // },
  // {
  //   name: "Staff",
  //   url: businessProfileURL.Staff,
  // },
];

export const businessReportSubmenu = [
  {
    name: "Services",
    url: `${businessURL.BusinessReport}/${businessReportURL.Services}`,
  },
  {
    name: "Resources",
    url: `${businessURL.BusinessReport}/${businessReportURL.Resources}`,
  },
  {
    name: "Providers",
    url: `${businessURL.BusinessReport}/${businessReportURL.Providers}`,
  },
  {
    name: "Staff",
    url: `${businessURL.BusinessReport}/${businessReportURL.Staff}`,
  },
  {
    name: "Customer",
    url: `${businessURL.BusinessReport}/${businessReportURL.Customer}`,
  },
  {
    name: "Usage",
    url: `${businessURL.BusinessReport}/${businessReportURL.Usage}`,
  },
];
