import * as reducers from "../reducer/messageReducer";
import { apiCallBegan } from "../middleware/api";

interface DispatchType {
  type: string;
}

const url = "/message";

export const clearBusinessMessageState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.messageClearState.type });
  };

export const sendBusinessMessages =
  (message: string, businessId: string, bookingId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("sendBusinessMessages");
    return dispatch(
      apiCallBegan({
        url: url + "/send-to-business",
        data: { message, businessId, bookingId },
        method: "post",
        // onStart: reducers.sendToBusiness.type,
        onSuccess: reducers.sendToBusiness.type,
        onError: reducers.messageSendFailed.type,
      })
    );
  };
