import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import Switch from "./Switch";

interface InputProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
}
interface FormikContextProps {
  [name: string]: boolean;
}

const AppSwitch: React.FC<InputProps> = ({
  name,
  label,
  labelBlock,
  labelWidth,
  helperMsg,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className="mt-8">
        <Switch
          checked={values[name]}
          onChange={(val: boolean) => {
            setFieldValue(name, val);
            // console.log("name", name);
          }}
        />
      </div>
    </InputContainer>
  );
};

export default AppSwitch;
