import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { personalURL } from "../../config/url";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MoreButton from "../ui/MoreButton";
import { useActions } from "../../hooks/useActions";
import { DropdownItemListType } from "../ui/dropdown/DropdownItemList";
import { checkMeetupDay, EMeetupDay } from "../../utils/datetime";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { EBtnCategory } from "../form/AppButton";

const usePersonalMeetupActions = (
  data: IMeetupDataType,
  onApptView?: (id: string) => void
) => {
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const navigate = useNavigate();
  const {
    acceptMeetup,
    confirmMeetup,
    rejectMeetup,
    cancelMeetup,
    notAttendMeetup,
  } = useActions();

  // const participant = data.participants.find((p) => p.user === userData?._id);
  const MEETUPDAY = checkMeetupDay(data.meetDateTime);
  // const otherParticipants = data.participantsData.filter(
  //   (p) => p.userId !== userData?._id
  // );
  // function newMeetupMsg() {
  //   const participantsName = otherParticipants
  //     .map((p) => p.displayName)
  //     .filter((e) => e !== undefined);
  //   const firstMsg =
  //     participantsName.length > 0 ? ` with ${participantsName[0]}` : "";
  //   const moreMsg =
  //     participantsName.length > 1
  //       ? ` and ${participantsName.length - 1} more...`
  //       : "";
  //   return `Create a new meetup ${firstMsg}${moreMsg}`;
  // }

  const menuItemView = {
    name: "View",
    onClick: () => onApptView && onApptView(data._id),
    desc: "View meetup details and participants status",
  };

  const menuItemReject = {
    name: "Reject",
    onClick: () => rejectMeetup(data._id, data.host),
    desc: `Reject the meetup invitation`,
  };

  // const menuItemNewMeetup = {
  //   name: "New Meetup",
  //   onClick: () =>
  //     navigate(personalURL.CreateMeetup, {
  //       state: data.attendees.map((userId) => userId),
  //     }),
  //   desc: "Host a new meetup with the confirmed attendees",
  // };

  const menuItemNotAttend = {
    name: "Not attend",
    onClick: () => notAttendMeetup(data._id, data.host),
    desc: `Not able to attend the meetup and notify the host`,
  };

  const menuItemCancel = {
    name: "Cancel",
    onClick: () => cancelMeetup(data._id, data.host),
    desc: `Cancel the meetup and notify the participants`,
  };

  const modalMenuList: DropdownItemListType[] = [];
  const tableMenuList: DropdownItemListType[] = [menuItemView];

  // if (participant?.isHost) {
  //   modalMenuList.push();
  // }

  if (data.bookingStatus < EBookingStatusValue.complete) {
    // Action before the booking complete
    if (
      data.userStatus === EBookingStatusValue.inviteByNewFriend &&
      MEETUPDAY >= EMeetupDay.today
    ) {
      // New friend to reject the invitation
      modalMenuList.push({ separator: true }, menuItemReject);
      tableMenuList.push({ separator: true }, menuItemReject);
    }

    if (
      data.userStatus === EBookingStatusValue.inviteByFriend &&
      MEETUPDAY >= EMeetupDay.today &&
      data.creator != userData?._id
    ) {
      // Participant not able to attend
      // console.log("MEETUPDAY 1", MEETUPDAY);
      modalMenuList.push({ separator: true }, menuItemNotAttend);
      tableMenuList.push({ separator: true }, menuItemNotAttend);
    }

    if (
      data.userStatus === EBookingStatusValue.confirm &&
      MEETUPDAY >= EMeetupDay.today
      // data.creator != userData?._id
    ) {
      // Participant not able to attend
      // console.log("MEETUPDAY 2", MEETUPDAY);
      modalMenuList.push({ separator: true }, menuItemNotAttend);
      tableMenuList.push({ separator: true }, menuItemNotAttend);
    }

    if (MEETUPDAY >= EMeetupDay.today && data.creator === userData?._id) {
      // Host to cancel the meeup
      modalMenuList.push({ separator: true }, menuItemCancel);
      tableMenuList.push({ separator: true }, menuItemCancel);
    }
  }

  const modalButton = (onClose: () => void) => {
    if (
      data.bookingStatus < EBookingStatusValue.complete &&
      MEETUPDAY >= EMeetupDay.today
    ) {
      const STATUS = data.userStatus;
      if (
        STATUS === EBookingStatusValue.inviteByNewFriend ||
        STATUS === EBookingStatusValue.inviteByFriend
      ) {
        // New friend accept and confirm the meetup || Friend confirm the meetup
        const handleClick = () => {
          STATUS === EBookingStatusValue.inviteByNewFriend
            ? acceptMeetup(data._id, data.host)
            : confirmMeetup(data._id, data.host);
        };
        return (
          <MoreButton
            menuList={modalMenuList}
            label="Confirm"
            category={EBtnCategory.Primary}
            onClick={() => handleClick()}
            minWidth={250}
          />
        );
      }
    }
    if (modalMenuList.length > 0) {
      return (
        <MoreButton
          menuList={modalMenuList}
          label="Close"
          category={EBtnCategory.PrimaryOutline}
          onClick={() => onClose()}
          minWidth={250}
        />
      );
    }
    return (
      <button
        role="button"
        className="btn btn-primary-outline"
        onClick={() => onClose()}
      >
        Close
      </button>
    );
    // }
  };
  return { tableMenuList, modalButton };
};

export default usePersonalMeetupActions;
