import styles from "./LayoutCenterBox.module.scss";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

interface LayoutCenterBoxProps {
  close?: boolean;
}

const LayoutCenterBox: React.FC<LayoutCenterBoxProps> = ({
  children,
  close,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles["outer"]}>
      {close && (
        <button
          className={classNames("btn-empty", styles["btn-close"])}
          type="button"
          onClick={() => navigate(-1)}
        >
          <FaTimes />
        </button>
      )}
      <div className={styles["container"]}>{children}</div>
    </div>
  );
};

export default LayoutCenterBox;
