import React from "react";
import styles from "./Tab.module.scss";
import { TabItemsType } from "./index";

interface TabHeaderProps {
  items: TabItemsType;
  active: number;
  onTabClick(id: number): void;
}

const TabHeader: React.FC<TabHeaderProps> = ({ items, active, onTabClick }) => {
  return (
    <header className={styles["tab-header"]}>
      <ul>
        {items.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => onTabClick(index)}
              className={active === index ? styles["active"] : ""}
            >
              {item.number && item.number > 0 ? (
                <span className={styles.number}>{item.number}</span>
              ) : null}
              {item.name}
            </li>
          );
        })}
      </ul>
    </header>
  );
};

export default TabHeader;
