import React, { useState, useEffect, useRef } from "react";

const useReleaseOutsite = () => {
  const [toggle, setToggle] = useState(false);
  const btnRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        (wrapperRef.current &&
          wrapperRef.current.contains(event.target as Node)) ||
        (btnRef.current && btnRef.current.contains(event.target as Node))
      ) {
        return;
      }
      setToggle(false);
    };
    document.addEventListener("click", listener, { capture: true });
    return () => {
      document.removeEventListener("click", listener, { capture: true });
    };
  }, []);
  return { btnRef, wrapperRef, toggle, setToggle };
};

export default useReleaseOutsite;
