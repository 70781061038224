import * as reducers from "../reducer/bookmarkReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getBookmarkBusinesses } from "../../data/dataBookmarkBusiness";
import { IBookmarkDataType } from "../../config/types/BookmarkDataType";

interface DispatchType {
	type: string;
}

const url = "/bookmark";
const data = getBookmarkBusinesses();

export const clearBookmarkState =
	() => (dispatch: (arg: DispatchType) => void) => {
		return dispatch({ type: reducers.bookmarkClearState.type });
	};

export const getBookmarks =
	() =>
	(
		dispatch: (arg: DispatchType) => void,
		getState: () => { entities: { bookmark: { lastFetch: number } } }
	) => {
		const { lastFetch } = getState().entities.bookmark;
		const fetchFlag = fetch(lastFetch);
		if (!fetchFlag) return;
		return dispatch(
			apiCallBegan({
				url: url + "/all",
				method: "get",
				data, // TODO: temporary
				onStart: reducers.bookmarkRequest.type,
				onSuccess: reducers.bookmarkReceived.type,
				onError: reducers.bookmarkRequestFailed.type,
			})
		);
	};

export const removeBookmark =
	(id: string) =>
	(
		dispatch: (arg: DispatchType) => void,
		getState: () => { entities: { bookmark: { data: IBookmarkDataType[] } } }
	) => {
		const { data } = getState().entities.bookmark;
		return dispatch(
			apiCallBegan({
				url: url + "/remove",
				method: "post",
				data: { id },
				// onStart: reducers.bookmarkRemoving.type,
				onStartDispatch: { type: reducers.bookmarkRemoving.type, payload: id },
				onSuccess: reducers.bookmarkRemoved.type,
				// onSuccessDispatch: {
				//   type: reducers.bookmarkRemoveFailed.type,
				//   payload: data,
				// },
				onErrorDispatch: {
					type: reducers.bookmarkRemoveFailed.type,
					payload: data,
				},
			})
		);
	};
