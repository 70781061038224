import styles from "./TableProvider.module.scss";
import { useNavigate } from "react-router";
import Table, { TableDataType } from "../ui/table";
import ProviderActionMenu from "./ProviderActionMenu";
import { businessProviderURL, businessURL } from "../../config/url";
import useBusinessProvider from "./useBusinessProvider";
import Avatar from "../ui/avatar/AvatarPic";

interface TableProps {
  data: TableDataType;
}

const TableProviders: React.FC<TableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { getProviderName } = useBusinessProvider();
  const columns = [
    {
      label: "Name",
      name: "name",
      // colWidth: "25%",
      // colMaxWidth: "30%",
      content: (provider: { name: string; imageUrl: string }) => (
        <div className={styles["name"]}>
          <Avatar size="small" src={provider.imageUrl} />
          <div className={styles["provider-name"]}>
            <div className="h5">{provider.name}</div>
          </div>
        </div>
      ),
    },
    {
      label: "Profession",
      name: "profession",
      colWidth: "20%",
      colMaxWidth: "25%",
      content: (provider: { profession: number }) => {
        return <>{getProviderName(provider.profession)}</>;
      },
    },
    {
      label: "Services",
      name: "services",
      colWidth: "20%",
      colMaxWidth: "25%",
      content: (providers: { services: string[] }) => {
        const number = providers.services.length;
        return (
          <span>
            <span className="text-semibold">{number}</span>{" "}
            {number > 1 ? "Services" : "Service"}
          </span>
        );
      },
    },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "90px",
      disableItemClick: true,
      content: (providers: {
        _id: string;
        name: string;
        profession: number;
      }) => (
        <ProviderActionMenu
          id={providers._id}
          name={providers.name}
          type="table"
          bookingType={getProviderName(providers.profession)}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        itemClick={(provider: { _id: string }) =>
          navigate(
            `${businessURL.BusinessProviders}/${provider._id}/${businessProviderURL.Info}`
          )
        }
      />
    </>
  );
};

export default TableProviders;
