import { useFormikContext } from "formik";
import classNames from "classnames";
import { useEffect, useState } from "react";

import InputContainer from "./InputContainer";
import CheckBox from "./CheckBox";

import styles from "./AppDuration.module.scss";
import { DropdownSelect } from "../ui/dropdown";

interface InputProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element;
  checkBoxLabel?: string;
  checkBoxValue?: string | number;
}

interface FormikContextProps {
  [name: string]: string;
}

const AppDuration: React.FC<InputProps> = ({
  name,
  label,
  labelBlock,
  labelWidth,
  helperMsg,
  checkBoxLabel,
  checkBoxValue,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();
  /* Value is number of minutes */

  const [duration, setDuration] = useState<{ hour: number; min: number }>({
    hour: 0,
    min: 5,
  });
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    if (values[name]) {
      if (checkBoxValue && checkBoxValue === values[name]) {
        setChecked(true);
      } else {
        const value = parseInt(values[name]);
        setDuration({ hour: Math.floor(value / 60), min: value % 60 });
      }
    }
  }, [values]);

  const handleClick = (type: "h" | "m", value: number) => {
    if (type === "h") {
      setDuration({ ...duration, hour: value });
      setFieldValue(name, value * 60 + duration.min);
    } else {
      setDuration({ ...duration, min: value });
      setFieldValue(name, duration.hour * 60 + value);
    }
  };

  const handleCheck = () => {
    if (!checked) {
      setDuration({ hour: 0, min: 0 });
      setFieldValue(name, checkBoxValue);
    } else {
      setDuration({ hour: 0, min: 5 });
      setFieldValue(name, 5);
    }
    setChecked(checked ? false : true);
  };

  const HourList = [];
  for (let h = 0; h <= 12; h++) {
    HourList[h] = {
      name: h.toString(),
      onClick: () => handleClick("h", h),
    };
  }

  const MinuteList = [];
  for (let m = 0; m < 60; m += 5) {
    MinuteList[m] = {
      name: m.toString(),
      onClick: () => handleClick("m", m),
    };
  }

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className={styles["container"]}>
        <div
          className={classNames(styles["input"], checked && styles["checked"])}
        >
          <DropdownSelect
            menuList={HourList}
            className={styles["dropdown"]}
            selected={duration.hour.toString()}
            fullWidth
          />
          <div className={classNames(styles["label"], styles["hour-label"])}>
            Hour
          </div>
          <DropdownSelect
            menuList={MinuteList}
            className={styles["dropdown"]}
            selected={duration.min.toString()}
            fullWidth
          />
          <div className={classNames(styles["label"], styles["min-label"])}>
            Min
          </div>
        </div>
        {checkBoxValue && (
          <div
            role="checkbox"
            onClick={() => handleCheck()}
            className={styles["checkbox"]}
          >
            <CheckBox checked={checked} />
            {checkBoxLabel}
          </div>
        )}
      </div>
    </InputContainer>
  );
};

export default AppDuration;
