import { FaCheck } from "react-icons/fa";
import styles from "./CheckBox.module.scss";
import classNames from "classnames";

interface CheckBoxProps {
  checked?: boolean;
  size?: "lg";
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, size }) => {
  return (
    <div
      className={classNames(
        styles["checkbox"],
        checked && styles["checked"],
        size && styles[size]
      )}
    >
      {checked && <FaCheck />}
    </div>
  );
};

export default CheckBox;
