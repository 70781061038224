import * as yup from "yup";
import { EReCaptchaType } from "../types/ReCaptchaType";
import { emailField } from "./validationFields";

export const InitialValues = {
  email: "",
  recaptchaType: EReCaptchaType.ForgotPassword,
  recaptchaCode: "",
};

export const Validation = yup.object().shape({
  email: emailField.required("Please enter your email"),
});

export interface IForgotPasswordInputType {
  email: string;
  recaptchaType: string;
  recaptchaCode: string;
}
