// To show data
import classnames from "classnames";
import { useFormikContext } from "formik";
import InputLabel from "./InputLabel";
import styles from "./AppDisplay.module.scss";

interface DisplayProps {
  name?: string;
  displayValue?: string | number;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
}

interface FormikContextProps {
  [key: string]: string | [key: string];
}

const AppDisplay: React.FC<DisplayProps> = ({
  name,
  displayValue,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
}) => {
  const { values } = useFormikContext<FormikContextProps>();

  return (
    <div
      className={classnames(
        styles["input-group"],
        !labelBlock && styles["input-inline"]
      )}
    >
      {label && (
        <InputLabel name={name} labelWidth={labelWidth} labelBlock={labelBlock}>
          {label}
        </InputLabel>
      )}
      <div className={styles["input-container"]}>
        {displayValue && displayValue}
        {name && values[name]}
        {helperMsg && <div className="desc mt-3">{helperMsg}</div>}
      </div>
    </div>
  );
};

export default AppDisplay;
