export default {
  env: process.env.NODE_ENV,
  bookingUrl: process.env.REACT_APP_BOOKING_PAGE_URL,
  api: process.env.REACT_APP_API_URL || "",
  socket: process.env.REACT_APP_SOCKET_URL || "",
  localENV: false,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
  media: 1, // 1: desktop, 2: mobile
  qrcodePath: "https://qrcode.addpointment.com",
};
