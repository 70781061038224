import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Demo: https://codesandbox.io/s/wonderful-pine-i7fs3?file=/src/Demo.tsx:675-743

interface ImageCropperProps {
  type: "big" | "medium" | "small";
  data: string;
  setCropper: (arg: any) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  type,
  data,
  setCropper,
}) => {
  // const [cropData, setCropData] = useState("#");
  // const [cropper, setCropper] = useState<any>();

  // const getCropData = () => {
  //   if (typeof cropper !== "undefined") {
  //     setCropData(cropper.getCroppedCanvas().toDataURL());
  //   }
  // };

  return (
    <Cropper
      style={{ height: 400, width: "100%" }}
      // zoomTo={2}
      initialAspectRatio={type === "big" ? 3 : type === "medium" ? 1.5 : 1}
      src={data}
      // viewMode={1}
      guides={true}
      aspectRatio={type === "big" ? 3 : type === "medium" ? 1.5 : 1}
      minCropBoxHeight={200}
      minCropBoxWidth={200}
      background={false}
      responsive={true}
      autoCropArea={1}
      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      onInitialized={(instance) => {
        setCropper(instance);
      }}
    />
  );
};

export default ImageCropper;
