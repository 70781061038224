import * as yup from "yup";
import { IUserContactType } from "../types/UserDataType";
import {
  postcodeField,
  cityField,
  stateField,
  countryField,
} from "./validationFields";

export const InitialValues = {
  city: "",
  country: "",
  postcode: "",
  state: "",
};

export const ContactValidation = {
  city: cityField.required("Please enter your city"),
  country: countryField.required("Please enter your country"),
  postcode: postcodeField.required("Please enter your postcode"),
  state: stateField.required("Please enter your state"),
};

export const Validation = yup.object().shape({
  ...ContactValidation,
});

export interface IPersonalContactInputType extends IUserContactType {}
