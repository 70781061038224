import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import TimeslotPicker from "../ui/TimeslotPicker";
import { getDate } from "../../utils/datetime";
import { DataCapacityType } from "../../config/types/CapacityType";

interface DatePickerProps {
  initialValue?: Date;
  name: string;
  helperMsg?: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  timeRange: string;
  interval: number;
  dateName?: string;
  capacity: number;
  dataCapacity?: DataCapacityType;
}

interface FormikProps {
  [name: string]: Date & string;
}

const AppTimeslotPicker: React.FC<DatePickerProps> = ({
  initialValue,
  name,
  helperMsg,
  label,
  labelBlock,
  labelWidth,
  timeRange,
  interval,
  dateName,
  capacity,
  dataCapacity,
}) => {
  const { setFieldValue, errors, values } = useFormikContext<FormikProps>();
  return (
    <>
      {/* <InputContainer
        name={name}
        label={label}
        labelBlock={labelBlock}
        labelWidth={labelWidth}
        helperMsg={helperMsg}
        error={errors[name]}
      > */}
      <TimeslotPicker
        timeRange={timeRange}
        value={values[name]}
        onChange={(val: Date) => setFieldValue(name, val)}
        interval={interval}
        capacity={capacity}
        date={dateName && values[dateName] && getDate(values[dateName])}
        dataCapacity={dataCapacity}
      />
      {/* </InputContainer> */}
    </>
  );
};

export default AppTimeslotPicker;
