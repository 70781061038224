import { Link } from "react-router-dom";
import { businessSetupURL } from "../../config/url";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import styles from "./HomeInvitation.module.scss";

const HomeStartBusiness = () => {
  const { data: business, request } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  if (!business && !request?.loading)
    return (
      <div className={styles["business"]}>
        <div className="h4">Register a business</div>
        <p>Setup your business to start accepting customer's bookings</p>
        <Link to={businessSetupURL.Info} className="btn btn-primary-outline">
          Setup Now
        </Link>
      </div>
    );
  return null;
};

export default HomeStartBusiness;
