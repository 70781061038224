import { useFormikContext } from "formik";

import Switch from "../form/Switch";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";

import styles from "./ServiceCustomer.module.scss";

interface FormikContextProps {
  [name: string]: boolean;
}

const ServiceCustomer: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<FormikContextProps>();

  return (
    <>
      <h3 className="mb-3">Customer</h3>
      <div className="desc mb-20">Manage your customer.</div>
      <Row>
        <Col width="60%">
          <ul className={styles["wrapper"]}>
            <li>
              <div className={styles["label"]}>
                <div className="h4 mb-3">Customer Record</div>
                <div className="desc">
                  Record booking of each customer for future reference.
                </div>
              </div>
              <Switch
                checked={values["customerRecord"]}
                onChange={(val: boolean) => {
                  setFieldValue("customerRecord", val);
                }}
              />
            </li>
            <li>
              <div className={styles["label"]}>
                <div className="h4 mb-3">Private Booking</div>
                <div className="desc">
                  Make this service as private. Only customer added to your
                  customer list can make booking.
                </div>
              </div>
              <Switch
                checked={values["privateBooking"]}
                onChange={(val: boolean) => {
                  setFieldValue("privateBooking", val);
                }}
              />
            </li>
            {/* <li>
                  <div className={styles["label"]}>
                    <div className="h4 mb-3">Record customer name</div>
                    <div className="desc">
                      Select this will record customer name for every booking.
                      Customer will be informed when they make booking.
                    </div>
                  </div>
                  <Switch
                    checked={values["customerRecordName"]}
                    onChange={(val: boolean) => {
                      setFieldValue("customerRecordName", val);
                    }}
                  />
                </li> */}
          </ul>
        </Col>
        <Col width="30%">
          <Guideline title="Customer">
            <p>
              With <b>Customer Database</b>, you can have the customer record
              for each booking. You can insert note for your staff reference.
            </p>
            <p>
              With <b>Private Booking</b>, you can control the booking by only
              allowing customer under your database to make booking.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServiceCustomer;
