import { NavLink, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

import styles from "./MainMenu.module.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";

interface MenuItemProps {
  name: string;
  url: string;
  icon: JSX.Element;
  // exact?: boolean;
  submenu?: {
    name: string;
    url: string;
  }[];
}

const MainMenuItem: React.FC<{ data: MenuItemProps }> = ({ data }) => {
  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === data.url ||
      location.pathname.includes(data.url)
    ) {
      setActive(true);
    }
  }, []);
  const handleExpand = () => {
    // if (location.pathname !== data.url) {
    expand ? setExpand(false) : setExpand(true);
    // }
  };
  if (!data.submenu)
    return (
      <NavLink
        to={data.url}
        className={({ isActive }) =>
          isActive
            ? classNames(styles["menu-item"], styles["active"])
            : styles["menu-item"]
        }
        // exact={data.exact}
        // className={styles["menu-item"]}
      >
        <span className={styles["icon"]}>{data.icon}</span>
        <span className={styles["name"]}>{data.name}</span>
      </NavLink>
    );
  return (
    <div
      className={classNames(
        styles["menu-has-children"],
        expand && styles["submenu-expanded"],
        active && styles["submenu-active"]
      )}
    >
      <div
        className={styles["menu-item"]}
        role="button"
        onClick={() => {
          handleExpand();
        }}
      >
        <span className={styles["icon"]}>{data.icon}</span>
        <span className={styles["name"]}>{data.name}</span>
        <span className={styles["dropdown-icon"]}>
          <FaChevronDown />
        </span>
      </div>
      <div className={styles["sub-menu"]}>
        <ul>
          {data.submenu.map((val, index) => {
            return (
              <li key={index}>
                <NavLink to={val.url}>{val.name}</NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MainMenuItem;
