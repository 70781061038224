import * as reducers from "../reducer/authReducer";
// import hashCode from "../../utils/hashcode";
import { fetch } from "../storeConfig";
import { apiCallBegan, IapiCallBegan } from "../middleware/api";
import { ILoginInputType } from "../../config/form/formLogin";
import { IForgotPasswordInputType } from "../../config/form/formForgotPwd";
import { IResetPasswordInputType } from "../../config/form/formResetPwd";
import config from "../../config/global";
import { IDeleteAccountInputType } from "../../config/form/formDeleteAccount";

interface DispatchType {
  type: string;
  payload?: IapiCallBegan | string;
}

const url = "/auth";

export const clearAuthState = () => (dispatch: (arg: DispatchType) => void) => {
  return dispatch({ type: reducers.authClearState.type });
};

export const businessCreated =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.authBusinessCreated.type });
  };

export const logout = () => (dispatch: (arg: DispatchType) => void) => {
  // return dispatch({ type: reducers.authLogout.type });
  return dispatch(
    apiCallBegan({
      url: url + "/logout",
      method: "post",
      onStart: reducers.authRequest.type,
      onSuccess: reducers.authLogout.type,
      onError: reducers.authRequestFailed.type,
    })
  );
};

export const login =
  (data: ILoginInputType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("login", data);
    // const password = hashCode(data.password);
    const userData = {
      logUsername: data.username,
      logPassword: data.password,
      logRecaptchaType: data.recaptchaType,
      logRecaptchaCode: data.recaptchaCode,
      logMedia: config.media,
    };
    return dispatch(
      apiCallBegan({
        url: url + "/login",
        method: "post",
        data: userData,
        onStart: reducers.authRequest.type,
        onSuccess: reducers.authLogin.type,
        // onSuccessDispatch: {
        //   type: reducers.authLogin.type,
        //   payload: { remember: data.remember },
        // },
        onError: reducers.authRequestFailed.type,
      })
    );
  };

export const forgotPwd =
  (data: IForgotPasswordInputType) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { auth: { lastFetch: number } } }
  ) => {
    console.log("forgotPwd", data);
    const { lastFetch } = getState().entities.auth;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag)
      return dispatch({
        type: reducers.authRequestFailed.type,
        payload: "Please wait for 10 minutes before submit another request.",
      });
    return dispatch(
      apiCallBegan({
        url: url + "/forgot-password",
        method: "post",
        data,
        onStart: reducers.authRequest.type,
        onSuccessDispatch: { type: reducers.authForgotPwd.type, payload: data },
        // onSuccess: reducers.authForgotPwd.type,
        onError: reducers.authRequestFailed.type,
      })
    );
  };

export const resetPwd =
  (data: IResetPasswordInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("resetPwd", data);
    return dispatch(
      apiCallBegan({
        url: url + "/reset-password",
        method: "post",
        data,
        onStart: reducers.authRequest.type,
        onSuccess: reducers.authResetPwd.type,
        onError: reducers.authRequestFailed.type,
      })
    );
  };

export const authState = () => (dispatch: (arg: DispatchType) => void) => {
  return dispatch(
    apiCallBegan({
      url: url + "/state",
      data: config.localENV ? true : undefined,
      method: "get",
      onStart: reducers.authStateRequest.type,
      onSuccess: reducers.authStateReceived.type,
      onError: reducers.authStateRequestFailed.type,
    })
  );
};

export const deleteAccount =
  (data: IDeleteAccountInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/delete",
        data: data,
        method: "post",
        onStart: reducers.authRequest.type,
        onSuccess: reducers.authDeleteAccount.type,
        onError: reducers.authRequestFailed.type,
      })
    );
  };

// Not in use
export const refreshToken = () => (dispatch: (arg: DispatchType) => void) => {
  return dispatch({
    type: reducers.authRefreshToken.type,
    payload: "Please wait for 10 minutes before submit another request.",
  });
};
