import { useFormikContext } from "formik";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";
import DropdownPanel from "../ui/dropdown/DropdownPanel";
import InputContainer from "./InputContainer";
import styles from "./AppSelectPanel.module.scss";

interface SelectPanelProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  options: SelectOptionsType[];
  filter?: string;
  cta?: string;
  ctaClick?(): void;
  noPadding?: boolean; // Padding around the dropdown.
}

export type SelectOptionsType = {
  name: string;
  value: string | number;
  desc?: string;
};

interface FormikContextProps {
  [name: string]: string;
}

const AppSelectPanel: React.FC<SelectPanelProps> = ({
  name,
  options,
  filter,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  cta,
  ctaClick,
  noPadding,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();

  const ctaBtn = cta ? (
    <button
      type="button"
      className={classNames(styles["btn-create-new"], "btn btn-link")}
      onClick={ctaClick && ctaClick}
    >
      <span className={styles["add-icon"]}>
        <IoMdAddCircleOutline />
      </span>
      {cta}
    </button>
  ) : null;

  const menuList =
    options.length > 0
      ? options.map((item, index) => ({
          ...item,
          item: item.name,
          id: index,
          onClick: () => setFieldValue(name, item.value),
        }))
      : [];

  const selected =
    values[name] && menuList.find((i) => i.value === values[name]);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <DropdownPanel
        menuList={menuList}
        className={styles["button"]}
        cta={ctaBtn}
        filter={filter}
        noPadding={noPadding}
      >
        <div className={styles["label"]}>{selected ? selected.name : ""}</div>
        <div className={styles["icon"]}>
          <FaChevronDown />
        </div>
      </DropdownPanel>
    </InputContainer>
  );
};

export default AppSelectPanel;
