import styles from "./AppButton.module.scss";
import classNames from "classnames";

export enum EBtnCategory {
  Primary = "primary",
  PrimaryOutline = "primary-outline",
  Link = "link",
}

interface ButtonProps {
  category?: EBtnCategory; // primary-outline
  type?: "button" | "submit" | "reset";
  size?: "large" | "small";
  onClick?(): void;
  // value?: string;
  disabled?: boolean;
  loading?: boolean;
  name?: string;
  btnBlock?: boolean; // Make button full width
  className?: string;
}

const AppButton: React.FC<ButtonProps> = ({
  children,
  type = "button",
  size,
  // value,
  disabled,
  category,
  loading,
  name,
  btnBlock,
  onClick,
  className,
}) => {
  // console.log("button loading", loading);
  return (
    <button
      type={type}
      name={name}
      // value={value}
      onClick={onClick}
      disabled={loading || disabled}
      className={classNames(
        "btn",
        category && `btn-${category}`,
        btnBlock && styles["btn-block"],
        size && styles[`btn-${size}`],
        className && className
      )}
    >
      {children}
    </button>
  );
};

export default AppButton;
