import classNames from "classnames";
import React from "react";
import styles from "./MessageList.module.scss";
import { INotificationDataType } from "../../../config/types/NotificationDataType";

interface IMessageList {
  onClick(i: INotificationDataType): void;
}

const MessageList: React.FC<IMessageList> = ({ onClick }) => {
  return <div>Message List Code Here</div>;
};

export default MessageList;
