import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import DropdownPanel, { DropdownPanelMenuType } from "./dropdown/DropdownPanel";
import styles from "./ListTable.module.scss";
import classNames from "classnames";

interface ListTableProps {
  title?: string;
  data: ListTableDataType[];
  label?: string;
  panelList?: DropdownPanelMenuType[];
  panelFilter?: string;
  panelEmptyMsg?: string;
  onClickPanel?(): void;
  panelLabel?: string;
  onDelete?(id: string): void;
  onEdit?(id: string): void;
}

export type ListTableDataType = {
  id: string;
  content: string | JSX.Element;
  notDelete?: boolean;
};

const ListTable: React.FC<ListTableProps> = ({
  title,
  data,
  label,
  panelList,
  panelFilter,
  panelEmptyMsg,
  onClickPanel,
  panelLabel,
  onDelete,
  onEdit,
}) => {
  const listCTA =
    onClickPanel && panelLabel ? (
      <button
        type="button"
        className={classNames("btn btn-link", styles["btn-cta"])}
        onClick={onClickPanel}
      >
        {panelLabel}
      </button>
    ) : null;

  // console.log("serviceProviderdata", data);

  return (
    <>
      {title && <h4 className="mb-10">{title}</h4>}
      {data && data.length > 0 && (
        <ul className={styles["data-list"]}>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <div className={styles["col-data"]}>{item.content}</div>
                {onEdit && (
                  <div className={styles["col-edit"]}>
                    <button
                      type="button"
                      className="btn-link btn-empty"
                      onClick={() => onEdit(item.id)}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {onDelete && (
                  <div className={styles["col-delete"]}>
                    {!item.notDelete && (
                      <button
                        type="button"
                        className="btn-empty"
                        onClick={() => onDelete(item.id)}
                      >
                        <FaRegTrashAlt />
                      </button>
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {panelList && (
        <DropdownPanel
          menuList={panelList}
          menuEmptyMsg={panelEmptyMsg}
          cta={listCTA}
          filter={panelFilter}
          className={styles["btn-toggle"]}
          noPadding
          fixWidth
        >
          <span className={styles["icon"]}>
            <IoMdAddCircleOutline />
          </span>{" "}
          {label}
        </DropdownPanel>
      )}
      {!panelList && label && (
        <button
          type="button"
          className={classNames("btn", styles["btn-toggle"])}
          onClick={onClickPanel}
        >
          <span className={styles["icon"]}>
            <IoMdAddCircleOutline />
          </span>{" "}
          {label}
        </button>
      )}
    </>
  );
};

export default ListTable;
