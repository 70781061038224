import { useState, useEffect } from "react";

const useCharacterCount = (value: string | [key: string], max?: number) => {
  const [counting, setCounting] = useState<number>(0);

  useEffect(() => {
    if (max) {
      value ? setCounting(value.length) : setCounting(0);
    }
  }, [value, max]);

  const characterLeft = max ? max - counting : null;

  return { characterLeft };
};

export default useCharacterCount;
