import { AppForm } from "../../components/form";
import PageWithSteps from "../../components/layout/PageWithSteps";
import {
  LayoutBody,
  LayoutFullHeight,
  LayoutHeader,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessContact from "../../components/businessProfile/BusinessContact";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessContact";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useActions } from "../../hooks/useActions";
import useBusinessSetup from "./useBusinessSetup";
import { setupBusiness } from "../../config/steps";
import BusinessSetupFooter from "../../components/business/BusinessSetupFooter";

const BusinessSetupContactPage: React.FC = () => {
  useDocumentTitle("Create a Business - AddPointment");
  const { header } = useBusinessProfile(1);
  const { setupContact } = useActions();
  useBusinessSetup(2);

  return (
    <PageWithSteps activeStep={1} steps={setupBusiness}>
      <AppForm
        initialValues={{
          ...InitialValues,
          name: "Main Location",
          isMain: true,
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("values", values);
          setupContact(values);
        }}
        validationSchema={Validation}
      >
        <LayoutFullHeight>
          <LayoutHeader title="New Business" />
          <LayoutBody>
            {header()}
            <BusinessContact />
          </LayoutBody>
          <BusinessSetupFooter step={2} />
        </LayoutFullHeight>
      </AppForm>
    </PageWithSteps>
  );
};

export default BusinessSetupContactPage;
