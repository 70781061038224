import { useEffect, useState } from "react";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { ModalBox, ModalHeader, ModalBody, ModalFooter } from "../ui/modal";
import BusinessBookingForm from "./BusinessBookingForm";
import { AppForm, AppButton } from "../form";
import {
  Validation,
  InitialValues,
  BusinessBookingFormType,
} from "../../config/form/formBusinessBooking";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";

interface ModalTransferProps {
  onClose(): void;
  data: IBookingDataType;
}

const ModalTransfer: React.FC<ModalTransferProps> = ({ onClose, data }) => {
  const [initVal, setInitVal] = useState(InitialValues);
  const [error, setError] = useState(false);
  const { transferBusinessBooking, clearBusinessBookingState } = useActions();
  const { success } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const { notify } = useToast();

  useEffect(() => {
    setInitVal({
      ...initVal,
      apptDate: new Date(),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearBusinessBookingState();
      onClose();
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values: BusinessBookingFormType) => {
    if (!values.service && !values.resource && !values.provider) {
      setError(true);
    } else {
      setError(false);
      transferBusinessBooking(data, values);
    }
  };

  return (
    <ModalBox onClose={onClose} type="business" size="large">
      <ModalHeader
        title={`Transfer for customer ${data.customerData.displayName}`}
      />
      <AppForm
        className="flex-layout"
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("submit transfer value", values);
          handleSubmit(values);
        }}
        validationSchema={Validation}
      >
        <ModalBody>
          {error && (
            <div className="desc error-msg pt-10 text-center">
              Please select the type you want to transfer to
            </div>
          )}
          <BusinessBookingForm type="transfer" />
        </ModalBody>
        <ModalFooter>
          <AppButton
            type="button"
            onClick={onClose}
            className="btn btn-link mr-10"
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            Transfer
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalTransfer;
