import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import styles from "./CreateBooking.module.scss";

interface ICreateBookingTitleProps {
  title?: string;
  onBack?: () => void;
}

const CreateBookingTitle: React.FC<ICreateBookingTitleProps> = ({
  title,
  onBack,
}) => {
  return (
    <h4 className={styles["page-title"]}>
      {onBack && (
        <button onClick={() => onBack()} className="btn">
          <BiLeftArrowAlt />
        </button>
      )}
      {title}
    </h4>
  );
};

export default CreateBookingTitle;
