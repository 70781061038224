import IconFacility from "../../assets/icons/Facility.svg";
import IconRoom from "../../assets/icons/Room.svg";
import IconTable from "../../assets/icons/Table.svg";
import IconVenue from "../../assets/icons/Venue.svg";

// Resource Type
export const resourceType = [
  {
    icon: IconRoom,
    name: "Room",
    desc: "Book for meeting room or ballroom based on number of attendees",
    value: 201,
  },
  {
    icon: IconTable,
    name: "Table",
    desc: "Book a table at restaurant based on the number of guests",
    value: 202,
  },
  {
    icon: IconFacility,
    name: "Facility",
    desc: "For a place, amenity, or piece of equipment provided for a particular purpose",
    value: 203,
  },
  {
    icon: IconVenue,
    name: "Venue",
    desc: "Book for for studio, events hall or sport venue based on number of people",
    value: 204,
  },
];