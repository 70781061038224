function getRange(percent: number) {
  if (percent < 20) {
    return "low";
  } else if (percent < 80) {
    return "medium";
  } else if (percent < 90) {
    return "high";
  } else {
    return "very-high";
  }
}
export default getRange;
