import * as reducers from "../reducer/serviceAdminReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getServices as testServices } from "../../data/dataServices";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { IServiceDuplicateInputType } from "../../config/form/formServiceDuplicate";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { StaffType } from "../../config/types/StaffType";
import { IServicePeriodType } from "../../config/form/formServicePeriod";
import { IBookingPeriodType } from "../../config/form/formBookingPeriod";
import { InitialValues } from "../../config/form/formService";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import config from "../../config/global";
import { IServiceInputType } from "../../config/form/formService";

interface DispatchType {
  type: string;
}

const url = "/service-admin";

export const clearAdminServiceState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.serviceClearState.type });
  };

export const getAdminServices =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { serviceAdmin: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.serviceAdmin;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/me",
        method: "get",
        data: config.localENV ? testServices() : null,
        onStart: reducers.serviceRequest.type,
        onSuccess: reducers.serviceReceived.type,
        onError: reducers.serviceRequestFailed.type,
      })
    );
  };

export const removeAdminService =
  (_id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { serviceAdmin: { data: IServiceDataType[] } } }
  ) => {
    console.log("removeService", _id);
    const { data } = getState().entities.serviceAdmin;
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: { _id },
        method: "post",
        onStartDispatch: { type: reducers.serviceRemoving.type, payload: _id },
        onSuccess: reducers.serviceRemoved.type,
        onErrorDispatch: {
          type: reducers.serviceRemoveFailed.type,
          payload: data,
        },
      })
    );
  };

export const duplicateAdminService =
  (data: IServiceDuplicateInputType, id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { serviceAdmin: { data: IServiceDataType[] } } }
  ) => {
    console.log("duplicateService", data, id);
    const { data: services } = getState().entities.serviceAdmin;
    let duplicatedData;
    if (services) {
      const service = services.find((s) => s._id === id);
      duplicatedData = {
        ...service,
        ...data,
        _id: undefined,
        serviceUid: undefined,
      };
    }
    console.log(duplicatedData);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: duplicatedData,
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceDuplicated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const addAdminService =
  (data: IServiceInputType) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        businessAdmin: { data: IBusinessDataType };
        location: { data: ILocationDataType[] };
        staff: { currentUser: StaffType };
      };
    }
  ) => {
    console.log("categoryName", data.categoryName);
    const { data: business } = getState().entities.businessAdmin;
    const { data: locations } = getState().entities.location;
    // const { currentUser: staff } = getState().entities.staff;
    // console.log(business);

    console.log("category", data.category);
    const serviceData = {
      bookingType: data.bookingType,
      bookingCapacity: data.bookingCapacity,
      dailyCapacity: data.dailyCapacity,
      duration: data.duration,
      label: data.label,
      name: data.name,
      desc: data.desc,
      category: data.category,
      categoryName: data.categoryName,
      slotInterval: data.slotInterval,
      businessUrl: business.businessUrl,
      servicePeriod: data.servicePeriod,
      servicePeriodOption: data.servicePeriodOption,
      servicePeriodSum: data.servicePeriodSum,
      onlineBooking: data.onlineBooking,
      onlineBookingMinDay: data.onlineBookingMinDay,
      onlineBookingMaxDay: data.onlineBookingMaxDay,
      onlineBookingNote: data.onlineBookingNote,
      onlineBookingPrivateBooking: data.onlineBookingPrivateBooking,
      walkIn: data.walkIn,
      walkInPrivateBooking: data.walkInPrivateBooking,

      location: [locations[0]._id],
      locationUid: [locations[0].locationUid],
      autoDelete: data.autoDelete,
      requestCustomerName: data.requestCustomerName,

      // staff: [staff._id],
    };
    console.log("addService", serviceData);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: serviceData,
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceAdded.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const updateAdminService =
  (data: IServiceDataType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("data", data);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data,
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const updateAdminServicePeriod =
  (data: IServicePeriodType, _id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    // const serviceData = {
    //   servicePeriod: data,
    // };
    console.log("updateAdminServicePeriod", data);
    return dispatch(
      apiCallBegan({
        url: url + "/service-period",
        data: { data, _id },
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const updateAdminServiceBookingPeriod =
  (data: IBookingPeriodType, _id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("updateAdminServiceBookingPeriod", data);
    return dispatch(
      apiCallBegan({
        url: url + "/booking-period",
        data: { data, _id },
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

// export const getAdminServicesProvider =
// 	(_id: string) =>
// 	(
// 		dispatch: (arg: DispatchType) => void
// 		// getState: () => { entities: { serviceAdmin: { lastFetch: number } } }
// 	) => {
// 		// const { lastFetch } = getState().entities.serviceAdmin;
// 		// const fetchFlag = fetch(lastFetch);
// 		// if (!fetchFlag) return;
// 		console.log("getServicesProvider", _id);
// 		return dispatch(
// 			apiCallBegan({
// 				url: url + "/get-providers",
// 				method: "post",
// 				data: { _id },
// 				onStart: reducers.serviceProviderRequest.type,
// 				onSuccess: reducers.serviceProviderReceived.type,
// 				onError: reducers.serviceProviderRequestFailed.type,
// 			})
// 		);
// 	};

export const addAdminServicesProvider =
  (_id: string, provider: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("addServiceProvider", _id, provider);

    return dispatch(
      apiCallBegan({
        url: url + "/add-provider",
        data: { _id, provider },
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceProviderAdded.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const removeAdminServicesProvider =
  (_id: string, provider: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeServiceProvider", _id, provider);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-provider",
        data: { _id, provider },
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceProviderRemoved.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const addAdminServiceLocation =
  (serviceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("addAdminServiceLocation", serviceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/add-location",
        // data: { serviceId, id },
        data: { ...InitialValues, id: "service-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const removeAdminServiceLocation =
  (serviceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeAdminServiceLocation", serviceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-location",
        // data: { serviceId, id },
        data: { ...InitialValues, id: "service-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const addAdminServiceStaff =
  (serviceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("addAdminServiceStaff", serviceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/add-staff",
        // data: { serviceId, id },
        data: { ...InitialValues, id: "service-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const removeAdminServiceStaff =
  (serviceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeAdminServiceStaff", serviceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-staff",
        // data: { serviceId, id },
        data: { ...InitialValues, id: "service-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.serviceLoading.type,
        onSuccess: reducers.serviceUpdated.type,
        onError: reducers.serviceFailed.type,
      })
    );
  };

export const updateAdminServiceWalkIn =
  (id: string, value: boolean) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { serviceAdmin: { data: IServiceDataType[] } } }
  ) => {
    console.log("updateAdminServiceWalkIn", value);
    const { data: services } = getState().entities.serviceAdmin;
    return dispatch(
      apiCallBegan({
        url: url + "/booking-period",
        data: { id, data: { walkIn: value } },
        method: "post",
        onStartDispatch: {
          type: reducers.serviceUpdating.type,
          payload: { type: "walk-in", id, value },
        },
        // onSuccess: reducers.serviceUpdated.type,
        onErrorDispatch: {
          type: reducers.serviceUpdateFailed.type,
          payload: services,
        },
      })
    );
  };

export const updateAdminServiceOnlineBooking =
  (id: string, value: boolean) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { serviceAdmin: { data: IServiceDataType[] } } }
  ) => {
    const { data: services } = getState().entities.serviceAdmin;
    console.log("updateAdminServiceOnlineBooking", id, value);

    return dispatch(
      apiCallBegan({
        url: url + "/booking-period",
        data: { id, data: { onlineBooking: { enable: value } } },
        method: "post",
        onStartDispatch: {
          type: reducers.serviceUpdating.type,
          payload: { type: "online-booking", id, value },
        },
        // onSuccess: reducers.serviceUpdated.type,
        onErrorDispatch: {
          type: reducers.serviceUpdateFailed.type,
          payload: services,
        },
      })
    );
  };

export const uploadAdminServicePhoto =
  (photo: string, _id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("uploadAdminServicePhoto", _id);
    return dispatch(
      apiCallBegan({
        url: url + "/upload-service-photo",
        data: { photo, _id },
        method: "post",
        onStart: reducers.servicePhotoUploading.type,
        onSuccess: reducers.servicePhotoUploaded.type,
        onError: reducers.servicePhotoUploadFailed.type,
      })
    );
  };

// Not in use
export const deleteAdminService =
  (_id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeService", _id);
    return dispatch(
      apiCallBegan({
        url: url + "/delete",
        data: { _id },
        method: "post",
        onSuccess: reducers.serviceDeleted.type,
      })
    );
  };
