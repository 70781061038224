import { useState, useEffect } from "react";
import TimePicker from "../../ui/timepicker";
import styles from "./TimeOptions.module.scss";
import { convertTimeString } from "../../../utils/datetime";

interface TimeOptionsProps {
  value: string; // value: 18:30
  onChange(value: string): void; // value: 18:30
  previousTime?: string;
  onClose?(): void;
}

const TimeOptions: React.FC<TimeOptionsProps> = ({
  value,
  onChange,
  onClose,
  previousTime,
}) => {
  const [displayTime, setDisplayTime] = useState<string | null>();
  // const previousTime = "12:30";
  useEffect(() => {
    if (value) {
      const result = convertTimeString(value);
      setDisplayTime(result);
    }
  }, [value]);

  function generateLoop(length: number) {
    const arr = [];
    for (let value = 0; value < length; value++) {
      arr.push(value);
    }
    return arr;
  }

  const handleDisabledHours: () => number[] | undefined = () => {
    if (previousTime) {
      const hour = previousTime.split(":")[0];
      return generateLoop(Number(hour));
    }
  };
  const handleDisabledMinutes: () => number[] | undefined = () => {
    if (previousTime) {
      const hour = previousTime.split(":")[0];
      const minute = previousTime.split(":")[1];
      if (Number(hour)) {
        return generateLoop(Number(minute));
      }
    }
  };
  return (
    <>
      <TimePicker
        onChange={(value: string) => onChange(value)}
        value={new Date("2020-01-01 " + value)}
        disabledHours={handleDisabledHours()}
        disabledMinutes={handleDisabledMinutes()}
        onClose={onClose}
        minuteStep={5}
      >
        <div className={styles["input"]}>{displayTime}</div>
      </TimePicker>
    </>
  );
};

export default TimeOptions;
