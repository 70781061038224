import * as yup from "yup";

export const InitialValues = {
  service: "",
  resource: "",
  provider: "",
  apptDate: new Date(),
  meetDateTime: "",
};

// Validation
export const Validation = yup.object().shape({
  service: yup.string().notRequired(),
  resource: yup.string().notRequired(),
  provider: yup.string().notRequired(),
  apptDate: yup.string().required("Please select a date"),
  meetDateTime: yup.string().required("Please select a timeslot"),
});

export interface BusinessBookingFormType {
  service?: string;
  resource?: string;
  provider?: string;
  apptDate: Date;
  meetDateTime: Date;
}
