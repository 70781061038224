import useDocumentTitle from "../../hooks/useDocumentTitle";
import PageWithSteps from "../../components/layout/PageWithSteps";
import { AppForm } from "../../components/form";
import {
  LayoutHeader,
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import { Validation } from "../../config/form/formService";
import useCreateServicePage from "./useCreateServicePage";

const CreateServicePage: React.FC = () => {
  useDocumentTitle("Create new service");
  const {
    initVal,
    steps,
    currentStep,
    handleContinue,
    renderFooter,
    renderContent,
  } = useCreateServicePage();

  return (
    <PageWithSteps activeStep={currentStep} steps={steps} backBtn>
      <AppForm
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log(values);
          handleContinue(values);
        }}
        validationSchema={Validation}
      >
        <LayoutFullHeight>
          <LayoutHeader title="New Service" />
          <LayoutBody>{renderContent}</LayoutBody>
          {renderFooter}
        </LayoutFullHeight>
      </AppForm>
    </PageWithSteps>
  );
};

export default CreateServicePage;
