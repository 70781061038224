import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import { InitialValues, Validation } from "../../config/form/formBookingPeriod";
import useBusinessServicePage from "./useBusinessServicePage";
import BookingPeriod from "../../components/common/BookingPeriod";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";

const ServiceBookingPeriodPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { service, submenu, header, footer } = useBusinessServicePage(id);
  const [initVal, setInitVal] = useState(InitialValues);
  const { updateAdminServiceBookingPeriod } = useActions();

  useEffect(() => {
    if (service) {
      setInitVal({
        ...initVal,

        onlineBooking: service.onlineBooking,
        onlineBookingMinDay: service.onlineBookingMinDay,
        onlineBookingMaxDay: service.onlineBookingMaxDay,
        onlineBookingNote: service.onlineBookingNote,
        onlineBookingPrivateBooking: service.onlineBookingPrivateBooking,

        walkIn: service.walkIn,
        walkInPrivateBooking: service.walkInPrivateBooking,
      });
    }
  }, [service]);
  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && service && (
          <AppForm
            initialValues={initVal}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              console.log("==========", values);
              updateAdminServiceBookingPeriod(values, id);
            }}
            validationSchema={Validation}
          >
            <BookingPeriod />
            {footer}
          </AppForm>
        )}
      </div>
    </PageWithAside>
  );
};

export default ServiceBookingPeriodPage;
