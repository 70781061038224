import { useState } from "react";
import ListTable, { ListTableDataType } from "../ui/ListTable";
import ModalLocation from "./ModalLocation";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import LocationRemoveDialog from "../customer/LocationRemoveDialog";

const BusinessLocationList: React.FC = () => {
  const { data } = useTypedSelector((state) => state.entities.location);
  const [modalLocation, setModalLocation] = useState<{
    _id: string | null;
  } | null>(null);
  const [dialog, setDialog] = useState<string | null>(null);

  const handleEdit = (id: string) => {
    if (data) {
      const index = data.findIndex((item) => item._id === id);
      console.log(data[index]);
      setModalLocation(data[index]);
    }
  };

  const locations: ListTableDataType[] = [];
  if (data && data.length > 0) {
    data.forEach((el) => {
      locations.push({
        id: el._id,
        content: <div className="h5">{el.name}</div>,
        notDelete: el.isMain,
      });
    });
  }

  return (
    <>
      <ListTable
        title="Location Name"
        data={locations}
        label="Add New Location"
        onDelete={(id) => setDialog(id)}
        onEdit={handleEdit}
        onClickPanel={() => setModalLocation({ _id: null })}
      />
      {modalLocation && (
        <ModalLocation
          data={modalLocation}
          onClose={() => {
            setModalLocation(null);
          }}
        />
      )}
      {dialog && (
        <LocationRemoveDialog
          setVisible={(arg) => setDialog(arg)}
          id={dialog}
        />
      )}
    </>
  );
};

export default BusinessLocationList;
