import Guideline from "../ui/Guideline";
import { QRCodeSVG } from "qrcode.react";
import { Col, Row } from "../ui/Grid";
import config from "../../config/global";
import color from "../../config/color";
import styles from "./BusinessQRCode.module.scss";

interface BusinessQRCodeProps {
  data: string;
  name?: string;
}
const BusinessQRCode: React.FC<BusinessQRCodeProps> = ({ data, name }) => {
  return (
    <Row>
      <Col width="70%">
        <div className={styles["container"]}>
          <div className={styles["qrcode"]}>
            <QRCodeSVG
              value={`${config.qrcodePath}/loc-${data}`}
              // bgColor={color.firstColor}
              // bgColor="#ffffff"
              // fgColor="#fff"
              size={300}
            />
          </div>
          {name && <h4 className="text-center mt-10">{name}</h4>}
          <p className="text-center">Scan & Book</p>
        </div>
      </Col>
      <Col width="30%">
        <Guideline title="QR Code">
          <p>
            Print the QR Code and place it in front of your store. Your customer
            can scan the QR Code using AddPointment mobile app to make booking
            when walk-in.
          </p>
        </Guideline>
      </Col>
    </Row>
  );
};

export default BusinessQRCode;
