import * as reducers from "../reducer/businessAdminReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getBusiness as dataBusiness } from "../../data/dataBusinesses";
import { IBusinessInfoInputType } from "../../config/form/formBusinessProfile";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/business-admin";

export const clearBusinessState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({
      type: reducers.businessClearState.type,
    });
  };

export const getSelfBusiness =
  (refresh?: boolean) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { businessAdmin: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.businessAdmin;
    const fetchFlag = fetch(lastFetch);
    if (!refresh && !fetchFlag) return;
    console.log("getSelfBusiness");
    return dispatch(
      apiCallBegan({
        url: url + "/me",
        data: config.localENV && dataBusiness("business1"),
        method: "get",
        onStart: reducers.businessRequest.type,
        onSuccess: reducers.businessReceived.type,
        onError: reducers.businessRequestFailed.type,
      })
    );
  };

export const updateBusinessProfile =
  (data: IBusinessInfoInputType) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data,
        method: "post",
        onStart: reducers.businessUpdating.type,
        onSuccess: reducers.businessUpdated.type,
        onError: reducers.businessUpdateFailed.type,
      })
    );
  };

export const uploadBusinessLogo =
  (photo: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/upload-logo",
        method: "post",
        data: { photo },
        onStart: reducers.businessLogoUploading.type,
        onSuccess: reducers.businessLogoUploaded.type,
        onError: reducers.businessLogoUploadFailed.type,
      })
    );
  };

export const uploadBusinessCover =
  (photo: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/upload-cover-photo",
        method: "post",
        data: { photo },
        onStart: reducers.businessCoverUploading.type,
        onSuccess: reducers.businessCoverUploaded.type,
        onError: reducers.businessCoverUploadFailed.type,
      })
    );
  };
