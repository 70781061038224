import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import PersonalBookmark from "../../components/bookmark/PersonalBookmark";
import Header from "../../components/layout/Header";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const PersonalBookmarkPage: React.FC = () => {
  const { getBookmarks, clearBookmarkState } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.bookmark
  );
  const { notify } = useToast();

  useEffect(() => {
    getBookmarks();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", "The business has been  from your bookmark.");
      clearBookmarkState();
    } else if (error) {
      notify("error", "Failed to remove the business. Please try again.");
      clearBookmarkState();
    }
  }, [success, error]);

  return (
    <PageWithAside role="personal">
      <div className="wrapper">
        <Header
          pageTitle="Bookmark"
          pageDesc="List of businesses that have been added to your bookmark list"
          role="personal"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && <PersonalBookmark />}
        <MenuCreateNew role="personal" />
      </div>
    </PageWithAside>
  );
};
export default PersonalBookmarkPage;
