import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { AppButton, AppInput, AppForm } from "../../components/form";
import AppCountryRegion from "../../components/form/AppCountryRegion";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { authURL, webURL } from "../../config/url";
import {
  InitialValues,
  Validation,
  IRegisterInputType,
} from "../../config/form/formRegister";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useRegisterStep from "./useRegisterStep";
import { EBtnCategory } from "../../components/form/AppButton";
import {
  getRecaptchaToken,
  loadRecaptcha,
  removeRecaptcha,
} from "../../utils/googleRecaptcha";
import { EReCaptchaType } from "../../config/types/ReCaptchaType";

const AuthRegisterPage: React.FC = () => {
  useDocumentTitle("Register");
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [initVal, setInitVal] = useState(InitialValues);
  const { register, clearAuthState } = useActions();
  const { loading, error: responseError } = useTypedSelector(
    (state) => state.entities.register
  );
  useRegisterStep(0);
  const initialValues: IRegisterInputType = InitialValues;
  const defaultEmail = searchParams.get("email");

  useEffect(() => {
    if (defaultEmail) {
      setInitVal({ ...initVal, email: defaultEmail });
    }
  }, [defaultEmail]);

  useEffect(() => {
    if (responseError) {
      setError(responseError);
      clearAuthState();
    }
  }, [responseError]);

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <LayoutCenterBox>
      <div className="text-center mb-20">
        <h1>Register</h1>
        <p>
          Already have an account?{" "}
          <Link to={authURL.Login} className="link">
            Login
          </Link>
        </p>
        {error && <p className="error-msg">{error}</p>}
      </div>
      <AppForm
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={async (values, actions) => {
          const recaptchaCode = await getRecaptchaToken(
            EReCaptchaType.Register
          );
          if (!recaptchaCode)
            setError(
              "Invalid recaptcha. Please refresh the page and try again"
            );
          else {
            register({ ...values, recaptchaCode });
            actions.setSubmitting(false);
          }
        }}
      >
        <AppCountryRegion
          labelBlock
          name="country"
          label="Country"
          helperMsg="Select your current country"
        />
        <AppInput
          name="email"
          type="email"
          label="Email"
          helperMsg="This will be your username"
          labelBlock
        />
        <AppInput
          name="password"
          type="password"
          label="Password"
          helperMsg="Use 8 or more characters with a mix of letters, numbers & symbols"
          labelBlock
        />
        <div className="display-hide">
          <AppInput name="recaptchaType" type="text" />
        </div>

        <AppButton
          type="submit"
          category={EBtnCategory.Primary}
          className="mt-20"
          btnBlock
          size="large"
          loading={loading}
        >
          Create Account (Step 1 of 3)
        </AppButton>

        <div className="mt-20 text-center desc">
          By signing up, you are agree to AddPointment's{" "}
          <a href={webURL.terms} rel="noreferrer" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={webURL.policy} rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
        </div>
      </AppForm>
    </LayoutCenterBox>
  );
};

export default AuthRegisterPage;
