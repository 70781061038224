import { useEffect } from "react";
import styles from "./PersonalSettingPage.module.scss";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import MenuCreateNew from "../../components/ui/MenuCreateNew";
import { personalProfileSubmenu } from "../../config/submenu";
import ChangePassword from "../../components/personal/ChangePassword";
import { Col, Row } from "../../components/ui/Grid";
import DeleteAccount from "../../components/personal/DeleteAccount";

const PersonalSettingPage: React.FC = () => {
  const { getPersonalProfile, clearUserState, changePwd } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.user
  );
  const { notify } = useToast();

  useEffect(() => {
    clearUserState();
    getPersonalProfile();
  }, []);

  return (
    <PageWithAside
      role="personal"
      submenuName="Profile"
      submenuList={personalProfileSubmenu}
      noPadding
    >
      <div className="wrapper container">
        <Header pageTitle="Settings" role="personal" />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {/* {!request && data && <PersonalProfile />} */}
        {!request && data && (
          <div className={styles["wrapper"]}>
            <Row className="mb-20 pb-20 separator">
              <Col>
                <h4>PIN Number</h4>
                <div className="mt-5 desc">
                  PIN number will be used to verify your identity for your
                  booking during the meet-up.
                </div>
              </Col>
              <Col width="150px">
                <div className={styles["pin"]}>{data.pin}</div>
              </Col>
            </Row>
            <ChangePassword />
            <DeleteAccount />
            <MenuCreateNew role="personal" />
          </div>
        )}
      </div>
    </PageWithAside>
  );
};

export default PersonalSettingPage;
