import InputContainer from "./InputContainer";
import VerificationCode from "./VerificationCode";

interface AppInputCodeProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element;
  codeLength: number;
  isForm?: boolean;
  type?: string;
}

const AppInputCode: React.FC<AppInputCodeProps> = ({
  name,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  codeLength,
  isForm,
  type,
}) => {
  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      // error={errors[name]}
      helperMsg={helperMsg}
    >
      <VerificationCode
        size={codeLength}
        name={name}
        isForm={isForm}
        type={type}
      />
    </InputContainer>
  );
};

export default AppInputCode;
