import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authURL } from "../config/url";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";

const PersonalRouter: React.FC = () => {
  const { user } = useTypedSelector((state) => state.entities.auth);
  const { getPersonalProfile, getSelfBusiness } = useActions();

  useEffect(() => {
    if (user) {
      console.log("user", user);
      getPersonalProfile();
      getSelfBusiness();
    }
  }, [user]);

  return user ? <Outlet /> : <Navigate to={authURL.Login} />;
};

export default PersonalRouter;
