import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserDataType } from "../../config/types/UserDataType";
import config from "../../config/global";

type TSearchResult = { email: string; _id?: string };

interface IUserState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting user data
  error?: string;
  lastFetch?: Date | number;
  data?: IUserDataType;
  success?: string;
  tokenId?: string;
  searchResult?: TSearchResult;
  photoUpload?: { loading?: boolean; success?: boolean; error?: string };
}

const initialState: IUserState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  lastFetch: undefined,
  data: undefined,
  success: undefined,
  tokenId: undefined,
  searchResult: undefined,
  photoUpload: undefined,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userClearState: (user) => {
      user.error = undefined;
      user.success = undefined;
      user.request = undefined;
      user.loading = false;
      user.tokenId = undefined;
      user.searchResult = undefined;
      user.photoUpload = undefined;
    },
    userRequest: (user) => {
      user.request = { loading: true };
    },
    userReceived: (user, action: PayloadAction<IUserDataType>) => {
      console.log("userReceived", action.payload);
      user.data = action.payload;
      user.request = undefined;
      if (action.payload.pin) user.lastFetch = Date.now();
    },
    userRequestFailed: (user, action: PayloadAction<string>) => {
      user.request = { error: action.payload };
    },
    userLoading: (user) => {
      user.loading = true;
      user.success = undefined;
      user.error = undefined;
    },
    userFailed: (user, action: PayloadAction<string>) => {
      user.loading = false;
      user.error = action.payload;
    },
    userSaved: (user, action: PayloadAction<IUserDataType>) => {
      console.log("saved", action.payload);
      user.loading = false;
      user.success = "Your profile has been updated";
      user.data = { ...user.data, ...action.payload };
    },
    userSearchResult: (user, action: PayloadAction<TSearchResult>) => {
      console.log("userSearchResult", action.payload);
      user.searchResult = action.payload;
    },
    userEmailVerifying: (user, action: PayloadAction<{ tokenId: string }>) => {
      user.loading = false;
      user.tokenId = !config.localENV
        ? action.payload.tokenId
        : "61b1cf6d8d9e424fc1524d6b";
    },
    userEmailChanged: (user, action: PayloadAction<IUserDataType>) => {
      console.log("verified");
      user.tokenId = undefined;
      user.loading = false;
      user.success = "Your email has been updated";
      user.error = undefined;
      user.data = { ...user.data, ...action.payload };
    },
    userPwdChanged: (user) => {
      user.loading = false;
      user.success = "Your password has been updated";
    },
    userPhotoUploading: (user) => {
      user.photoUpload = { loading: true };
    },
    userPhotoUploaded: (user, action: PayloadAction<IUserDataType>) => {
      user.data = action.payload;
      user.photoUpload = { success: true };
    },
    userPhotoUploadFailed: (user, action: PayloadAction<string>) => {
      user.photoUpload = { error: action.payload };
    },
  },
});

export const {
  userClearState,
  userRequest,
  userReceived,
  userRequestFailed,
  userLoading,
  userFailed,
  userSaved,
  userSearchResult,
  userEmailVerifying,
  userEmailChanged,
  userPwdChanged,
  userPhotoUploading,
  userPhotoUploaded,
  userPhotoUploadFailed,
} = slice.actions;

export default slice.reducer;
