import { useEffect, useState } from "react";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import CreateBookingService from "./CreateBookingService";
import CreateBookingDateTime from "./CreateBookingDateTime";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CreateBookingCustomer from "./CreateBookingCustomer";
import { useActions } from "../../hooks/useActions";
import CreateBookingSubmit from "./CreateBookingSubmit";
import CreateBookingLocation from "./CreateBookingLocation";
import { IOnsiteServiceInputType } from "../../config/form/formOnsiteService";
import { ICustomerDataType } from "../../config/types/CustomerDataType";
import { ICreateBookingCustomerInputType } from "../../config/form/formCreateBookingCustomer";
import CreateBookingProvider from "./CreateBookingProvider";
import { IProviderDataType } from "../../config/types/ProviderDataType";

export enum ESteps {
  SelectService,
  SelectProvider,
  SelectDateTime,
  UserLocation,
  UserInfo,
  SubmitBooking,
}

const useCreateBooking = ({
  preselectCustomer,
}: {
  preselectCustomer?: ICustomerDataType;
}) => {
  const [step, setStep] = useState(ESteps.SelectService);
  const [selectedService, setSelectedService] = useState<IServiceDataType>();
  const [selectedProvider, setSelectedProvider] = useState<IProviderDataType>();
  const [selectedLocation, setSelectedLocation] = useState<ILocationDataType>();
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();
  const [selectedCustomer, setSelectedCustomer] =
    useState<ICreateBookingCustomerInputType>();
  const [onsiteService, setOnsiteService] = useState<IOnsiteServiceInputType>();

  const { getLocation } = useActions();
  const { data: locations } = useTypedSelector(
    (state) => state.entities.location
  );

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (locations && locations.length === 1) {
      setSelectedLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    console.log("preselectCustomer", preselectCustomer);
    preselectCustomer && setSelectedCustomer(preselectCustomer);
  }, [preselectCustomer]);

  function handleBack(stepTo?: number) {
    const toStep = stepTo !== undefined ? stepTo : step - 1;
    // if (toStep <= ESteps.SelectService) setSelectedService(undefined);
    // if (toStep <= ESteps.SelectProvider) setSelectedProvider(undefined);
    // if (toStep <= ESteps.SelectDateTime) setSelectedDateTime(undefined);
    // if (toStep <= ESteps.UserLocation) setOnsiteService(undefined);
    // if (toStep <= ESteps.UserInfo) setSelectedCustomer(undefined);
    if (toStep <= ESteps.SelectService) handleReset();
    else setStep(toStep);
  }

  function handleNext(stepTo?: number) {
    setStep(stepTo ? stepTo : step + 1);
  }

  function handleReset() {
    setStep(ESteps.SelectService);
    setSelectedService(undefined);
    setSelectedProvider(undefined);
    setSelectedDateTime(undefined);
    setOnsiteService(undefined);
    setSelectedCustomer(undefined);
  }

  function renderStep() {
    if (step === ESteps.SelectService)
      return (
        <CreateBookingService
          onNext={(service, stepTo) => {
            setSelectedService(service);
            handleNext(stepTo);
          }}
        />
      );
    else if (step === ESteps.SelectProvider && selectedService)
      return (
        <CreateBookingProvider
          onBack={handleBack}
          service={selectedService}
          onNext={(provider) => {
            setSelectedProvider(provider);
            handleNext();
          }}
        />
      );
    else if (step === ESteps.SelectDateTime && selectedService)
      return (
        <CreateBookingDateTime
          onBack={handleBack}
          service={selectedService}
          provider={selectedProvider}
          onNext={(val, stepTo) => {
            setSelectedDateTime(val);
            handleNext(stepTo);
          }}
        />
      );
    else if (
      step === ESteps.UserLocation &&
      selectedDateTime &&
      selectedService
    )
      return (
        <CreateBookingLocation
          onBack={handleBack}
          onNext={(values) => {
            setOnsiteService(values);
            handleNext();
          }}
          service={selectedService}
          provider={selectedProvider}
          dateTime={selectedDateTime}
        />
      );
    else if (
      step === ESteps.UserInfo &&
      selectedDateTime &&
      selectedService &&
      locations.length > 0
    )
      return (
        <CreateBookingCustomer
          onBack={handleBack}
          selectedCustomer={selectedCustomer}
          onNext={(values) => {
            setSelectedCustomer(values);
            handleNext();
          }}
          service={selectedService}
          provider={selectedProvider}
          dateTime={selectedDateTime}
          location={locations[0]}
          onsiteService={onsiteService}
        />
      );
    else if (
      step === ESteps.SubmitBooking &&
      selectedCustomer &&
      selectedDateTime &&
      selectedLocation &&
      selectedService
    )
      return (
        <CreateBookingSubmit
          bookingData={{
            selectedCustomer,
            selectedProvider,
            selectedDateTime,
            selectedLocation,
            selectedService,
            onsiteService,
          }}
          onBack={() => handleBack(ESteps.SelectDateTime)}
          onReset={() => handleReset()}
        />
      );
  }

  return { renderStep };
};

export default useCreateBooking;
