import * as reducers from "../reducer/customerReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getCustomers as customersData } from "../../data/dataCustomers";
import { getCustomerRecord as dataCustomerRecord } from "../../data/dataAppts";
import { getNotes } from "../../data/dataNotes";
import { getTags } from "../../data/dataTags";
import { AddCustomerInputType } from "../../config/form/formAddCustomer";
import { AddCustomerNoteType } from "../../config/form/formAddCustomerNote";
import { ICustomerDataType } from "../../config/types/CustomerDataType";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/customer-admin";

export const clearCustomerState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({
      type: reducers.customerClearState.type,
    });
  };

export const getCustomers =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { customer: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.customer;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/all",
        method: "get",
        data: customersData(), // TODO: temporary
        onStart: reducers.customerRequest.type,
        onSuccess: reducers.customerReceived.type,
        onError: reducers.customerRequestFailed.type,
      })
    );
  };

export const addCustomer =
  (data: AddCustomerInputType) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        method: "post",
        data,
        onStart: reducers.customerLoading.type,
        onSuccess: reducers.customerAdded.type,
        onError: reducers.customerFailed.type,
      })
    );
  };

export const removeCustomer =
  (id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { customer: { data: ICustomerDataType[] } } }
  ) => {
    console.log("removeCustomer", id);
    const { data } = getState().entities.customer;
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        method: "post",
        data: { id },
        onStartDispatch: { type: reducers.customerRemoving.type, payload: id },
        onSuccess: reducers.customerRemoved.type,
        // onError: reducers.customerRemoveFailed.type,
        onErrorDispatch: {
          type: reducers.customerRemoveFailed.type,
          payload: data,
        },
      })
    );
  };

export const getCustomerRecord =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("getCustomerRecord", id); // customer ID
    return dispatch(
      apiCallBegan({
        url: url + "/record",
        method: "post",
        data: config.localENV ? dataCustomerRecord(id) : { id },
        onStart: reducers.customerLoading.type,
        onSuccess: reducers.customerRecordReceived.type,
        onError: reducers.customerFailed.type,
      })
    );
  };

export const getCustomerTags =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/tags",
        method: "get",
        data: getTags("user1"), // TODO: pass businessId
        onStart: reducers.allTagsRequest.type,
        onSuccess: reducers.allTagsReceived.type,
        onError: reducers.allTagsFailed.type,
      })
    );
  };

export const addCustomerTag =
  (tagId: string, customerId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/tags/add",
        method: "post",
        data: { tagId, customerId },
        onStart: reducers.tagUpdating.type,
        onSuccessDispatch: {
          type: reducers.tagUpdated.type,
          payload: { type: "add" },
        },
        onError: reducers.tagUpdateFailed.type,
      })
    );
  };

export const addCustomerNewTag =
  (tagName: string, customerId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/tags/new",
        method: "post",
        data: { tagName, customerId },
        onStart: reducers.tagUpdating.type,
        onSuccessDispatch: {
          type: reducers.tagUpdated.type,
          payload: { type: "new" },
        },
        onError: reducers.tagUpdateFailed.type,
      })
    );
  };

export const removeCustomerTag =
  (tagId: string, customerId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeCustomerTag", tagId, customerId);
    return dispatch(
      apiCallBegan({
        url: url + "/tags/remove",
        method: "post",
        data: { tagId, customerId },
        onStart: reducers.tagUpdating.type,
        onSuccessDispatch: {
          type: reducers.tagUpdated.type,
          payload: { type: "remove" },
        },
        onError: reducers.tagUpdateFailed.type,
      })
    );
  };

export const getCustomerNotes =
  (customerId: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("getCustomerNotes", customerId);
    return dispatch(
      apiCallBegan({
        url: url + "/notes",
        method: "get",
        data: getNotes(), // TODO: pass customerId
        onStart: reducers.customerNotesRequest.type,
        onSuccess: reducers.customerNotesReceived.type,
        onError: reducers.customerNotesFailed.type,
      })
    );
  };

export const addCustomerNote =
  (data: AddCustomerNoteType) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/notes/add",
        method: "post",
        data,
        onStart: reducers.customerNoteAdding.type,
        onSuccess: reducers.customerNoteAdded.type,
        onError: reducers.customerNoteAddFailed.type,
      })
    );
  };
