import classnames from "classnames";
import styles from "./Table.module.scss";
import { TableColumnsType, TableDataType } from "./index";
import useTableColumn from "./useTableColumn";

interface TableBodyProps {
  data: TableDataType;
  columns: TableColumnsType;
  itemClick?(arg: { _id: string }): void;
  // rowLimit?: number;
  // pageSelect?: number;
}

const TableBody: React.FC<TableBodyProps> = ({
  data,
  columns,
  itemClick,
  // rowLimit,
  // pageSelect,
}) => {
  // console.log("show RowLimit>> ", rowLimit);
  const setWidth = useTableColumn();

  return (
    <div className={styles["content"]}>
      {data.map((item, itemIndex) => (
        <ul
          key={itemIndex}
          // key={item.id}
          className={classnames(itemClick && styles["clickable"])}
        >
          {columns.map((column, index) => (
            <li
              key={index}
              className={classnames(
                column.name && column.name,
                column.cellClass && column.cellClass,
                column.alignTop && styles["align-top"]
              )}
              onClick={() =>
                itemClick && !column.disableItemClick ? itemClick(item) : false
              }
              style={setWidth(column.colWidth, column.colMaxWidth)}
            >
              {column.content(item, itemIndex)}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default TableBody;
