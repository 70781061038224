import { personalURL } from "../../config/url";
import { useNavigate } from "react-router-dom";
import { DropdownSelect } from "../ui/dropdown";
import styles from "./SwitchViewDropdown.module.scss";

interface SwitchViewDropdownProps {
  label: string;
}

const SwitchViewDropdown: React.FC<SwitchViewDropdownProps> = ({ label }) => {
  const navigate = useNavigate();
  const menuList = [
    {
      name: "List View",
      onClick: () => navigate(personalURL.PersonalSchedule),
    },
    {
      name: "Calendar View",
      onClick: () => navigate(personalURL.PersonalCalendar),
    },
  ];
  return (
    <div className={styles["view-switch"]}>
      <DropdownSelect menuList={menuList} selected={label} type="menu" />
    </div>
  );
};

export default SwitchViewDropdown;
