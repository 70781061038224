// Location: Personal > Friend > AddFriend

import * as yup from "yup";
import { emailField } from "./validationFields";

export const InitialValues = {
  email: "",
};

// Validation
export const Validation = yup.object().shape({
  email: emailField.required("Please enter an email"),
});
