import React, { useState, useEffect, useRef } from "react";
import NotificationList from "./NotificationList";
import { useActions } from "../../../hooks/useActions";
import { IBusinessNotificationDataType } from "../../../config/types/BusinessNotificationDataType";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import config from "../../../config/global";
import ModalBooking from "../../businessBooking/ModalBooking";
import NotificationToggle from "./NotificationToggle";
import NotificationItem from "./NotificationItem";
import useBusinessNotificationMessage from "./useBusinessNotificationMessage";

const BusinessNotification = () => {
  const [id, setId] = useState<string | null>(null);
  const {
    clearBusinessNotificationsState,
    getBusinessNotifications,
    openBusinessNotifications,
    readBusinessNotification,
    readAllBusinessNotification,
  } = useActions();

  const { unopened, data, loading } = useTypedSelector(
    (state) => state.entities.businessNotification
  );
  const { getBusinessNotificationMessage } = useBusinessNotificationMessage();

  useEffect(() => {
    getBusinessNotifications();
    clearBusinessNotificationsState();
  }, []);

  const handleClick = (notification: IBusinessNotificationDataType) => {
    // console.log("businessNotification", notification.appt);
    readBusinessNotification(notification._id);
    setId(notification.appt);
  };

  return (
    <>
      <NotificationToggle
        unopened={unopened}
        onOpen={() => unopened > 0 && openBusinessNotifications()}
      >
        <NotificationList
          onReadAll={readAllBusinessNotification}
          loading={loading}
        >
          {data.map((item, index) => {
            return (
              <NotificationItem
                key={index}
                onClick={() => handleClick(item)}
                opened={item.read}
                image={item.userFromProfilePic}
                imageType="personal"
                message={getBusinessNotificationMessage(item)}
                date={item.createdAt}
              />
            );
          })}
        </NotificationList>
      </NotificationToggle>
      {id && <ModalBooking id={id} onClose={() => setId(null)} />}
    </>
  );
};

export default BusinessNotification;
