import classNames from "classnames";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import Table, { TableDataType } from "../ui/table";

import styles from "./DashboardTodayBooking.module.scss";

interface TableProps {
  type: "Services" | "Providers" | "Resources";
  data: TableDataType;
}

const DashboardTodayBookingTable: React.FC<TableProps> = ({ data, type }) => {
  console.log("data", data);
  const columns = [
    {
      label: type,
      name: "service",
      colWidth: "60%",
      cellClass: styles["col-name"],
      content: (service: IServiceDataType) => (
        <div>
          <span className={styles["label"]}>{service.label}</span>{" "}
          {service.name}
        </div>
      ),
    },
    {
      label: "Incomplete",
      name: "incomplete",
      colWidth: "20%",
      cellClass: `text-center ${styles["col-incomplete"]}`,
      content: (booking: { incomplete: number }) => (
        <span
          className={classNames(
            booking.incomplete === 0 ? "text-secondary" : "text-semibold"
          )}
        >
          {booking.incomplete}
        </span>
      ),
    },
    {
      label: "Completed",
      name: "completed",
      colWidth: "20%",
      cellClass: `text-center ${styles["col-completed"]}`,
      content: (booking: { completed: number }) => (
        <span
          className={classNames(booking.completed === 0 && "text-secondary")}
        >
          {booking.completed}
        </span>
      ),
    },
  ];
  return (
    <Table
      data={data}
      columns={columns}
      className={styles["table"]}
      // itemClick={(booking: { id: string }) => onApptView(booking.id)}
    />
  );
};

export default DashboardTodayBookingTable;
