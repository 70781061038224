import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";

interface SelectProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  options: OptionsType;
  inputWidth?: string;
  disabled?: boolean;
}

type OptionsType = {
  name: string;
  value: string | number;
}[];

interface FormikContextProps {
  [name: string]: string | number;
  // values: string;
}

const AppSelect: React.FC<SelectProps> = ({
  name,
  options,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  inputWidth,
  disabled,
}) => {
  const { handleChange, errors, values } =
    useFormikContext<FormikContextProps>();
  console.log("values[name]", values[name]);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div style={{ width: inputWidth ? inputWidth : "100%" }}>
        <select
          id={name}
          name={name}
          value={values[name]}
          onChange={handleChange}
          className="input-control"
          disabled={disabled ? true : false}
        >
          {options.map((val, index) => {
            return (
              <option
                key={index}
                value={val.value}
                label={val.name}
                // selected={val.value === values[name] ? true : false}
              />
            );
          })}
        </select>
      </div>
    </InputContainer>
  );
};

export default AppSelect;
