import { useRef, useState, useEffect } from "react";
import { convertTimeObject } from "../../../utils/datetime";
import useHourList from "./useHourList";
import useMinuteList from "./useMinuteList";
import useTimeList from "./useTimeList";

export interface StateTime {
  hour: number;
  minute: number;
  ampm: string;
}

type RefType = React.RefObject<HTMLDivElement>;

const useTimePicker = (
  onChange: (value: string) => void,
  toggleDropdown: () => void,
  dropdownRef: RefType,
  value?: Date,
  defaultValue?: string,
  minuteStep?: number,
  disabledHours?: number[],
  disabledMinutes?: number[],
  display?: boolean
) => {
  const hourRef = useRef(Array(12).fill(0));
  const minRef = useRef(Array(60).fill(0));
  const isFirstRun = useRef(true);

  const [time, setTime] = useState<StateTime>({
    hour: 0,
    minute: 0,
    ampm: "am",
  });

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    // setInitialValue();
    value && setTime(convertTimeObject(value));
  }, [value]);

  useEffect(() => {
    // defaultValue && setInitialValue();
    defaultValue && setTime(convertTimeObject(defaultValue));
  }, [defaultValue]);

  // const setInitialValue = () => {
  //   if (value) {
  //     setTime(convertTimeObject(value));
  //   } else if (defaultValue) {
  //     setTime(convertTimeObject(defaultValue));
  //   } else {
  //     setTime(convertTimeObject(new Date()));
  //   }
  // };

  const handleChange = (type: "hour" | "min" | "ampm", value: number) => {
    let valueHour =
      time.ampm === "pm" && time.hour < 12 ? time.hour + 12 : time.hour; // Default submit value
    let valueMin = time.minute.toString().padStart(2, "0"); // Default submit value
    if (type === "hour") {
      // For Hour
      setTimeout(() => {
        hourRef.current[value].scrollIntoView({ behavior: "smooth" });
      }, 50);
      setTime({ ...time, hour: value });
      valueHour = time.ampm === "pm" && value !== 12 ? value + 12 : value;
    } else if (type === "min") {
      // For Min
      // console.log("setTime min", time, value);
      setTimeout(() => {
        minRef.current[value].scrollIntoView({ behavior: "smooth" });
      }, 50);
      setTime({ ...time, minute: value });
      valueMin = value.toString().padStart(2, "0");
    } else {
      // For am pm
      // console.log("setTime ampm", time, value);
      if (value === 1) {
        setTime({ ...time, ampm: "am" });
        valueHour = time.hour > 12 ? time.hour - 12 : time.hour;
      } else {
        setTime({ ...time, ampm: "pm" });
        // console.log("set pm2");
        valueHour = time.hour < 12 ? time.hour + 12 : time.hour;
      }
    }
    onChange(
      valueHour.toString().padStart(2, "0") +
        ":" +
        valueMin.toString().padStart(2, "0")
    ); // Submit value
  };
  // console.log("time -----------", time);

  const { hourList, currentDisabledHour } = useHourList(
    time,
    hourRef,
    handleChange,
    disabledHours
  );

  const { minuteList } = useMinuteList(
    currentDisabledHour,
    time,
    minRef,
    handleChange,
    minuteStep,
    disabledMinutes
  );

  const { timeList } = useTimeList(
    dropdownRef,
    hourList,
    minuteList,
    time,
    handleChange,
    disabledHours,
    disabledMinutes
  );

  function handleToggle() {
    if (!display) {
      setTimeout(() => {
        hourRef.current &&
          time.hour &&
          hourRef.current[time.hour].scrollIntoView({
            block: "start",
            inline: "start",
          });
        minRef.current &&
          time.minute &&
          minRef.current[time.minute] &&
          minRef.current[time.minute].scrollIntoView({
            block: "start",
            inline: "start",
          });
      }, 50);
    }
    toggleDropdown();
  }

  return { handleToggle, timeList };
};

export default useTimePicker;
