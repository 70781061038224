import IconTableBooking from "../../assets/icons/Table_Booking.svg";
import IconVenueBooking from "../../assets/icons/Venue_Booking.svg";
import IconRoomBooking from "../../assets/icons/Room_Booking.svg";
import IconVirtualQueue from "../../assets/icons/Virtual_Queue.svg";
import IconDriveThru from "../../assets/icons/Drive_Thru.svg";
import IconAppointment from "../../assets/icons/Appointment.svg";
import IconReservation from "../../assets/icons/Reservation.svg";
import IconOrderPickup from "../../assets/icons/Order&Pickup.svg";

export const servicePeriodOption = [
  {
    name: "Same as business hour",
    desc: "The service period will be the same as your business hour",
    value: 1,
  },
  {
    name: "Custom service hour",
    desc: "The service period will be difference than your business hour",
    value: 2,
  },
  // {
  //   name: "Custom day and time",
  //   desc: "The service will only available on certain day and time",
  //   value: 3,
  // },
];

export const serviceType = [
  {
    value: 101,
    icon: IconAppointment,
    name: "Appointment",
    desc: "Services booked by customers for a one-to-one visit",
    meta: "for clinic, salon, personal consultant",
  },
  {
    value: 102,
    icon: IconReservation,
    name: "Reservation",
    desc: "Services booked by customers for available facilities or resources",
    meta: "For service provider",
  },
  {
    value: 103,
    icon: IconTableBooking,
    name: "Table Booking",
    desc: "Booking services for customers to reserve empty tables",
    meta: "For restaurants, cafe",
  },
  {
    value: 104,
    icon: IconVenueBooking,
    name: "Venue Booking",
    desc: "Manage the booking of your space",
    meta: "For event hall, studio, badminton court",
  },
  {
    value: 105,
    icon: IconRoomBooking,
    name: "Room Booking",
    desc: "Services booked by customers for a one-to-one visit",
    meta: "For meeting room, offices, conferences",
  },
  {
    value: 106,
    icon: IconVirtualQueue,
    name: "Virtual Queue",
    desc: "Control the crowd by managing the number of people for each time slot",
    meta: "For physical store, grocery market",
  },
  {
    value: 107,
    icon: IconDriveThru,
    name: "Drive Thru",
    desc: "Booking services for customers to pick up without leaving the car",
    meta: "For restaurants cafe, pharmacy",
  },
  {
    value: 108,
    icon: IconOrderPickup,
    name: "Order & Pickup",
    desc: "Alert customers to collect their items at the pickup counter",
    meta: "For food court, F&B Restaurant",
  },
];
