import classnames from "classnames";
import { FaChevronLeft, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PageLayout from "./PageLayout";
import styles from "./PageWithSteps.module.scss";

interface PageProps {
  activeStep: number; //start from 0
  backBtn?: boolean;
  steps: { title: string; list: string[] };
}

const PageWithSteps: React.FC<PageProps> = ({
  activeStep,
  backBtn,
  steps,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <div className={styles["outer"]}>
        <div className={styles["aside"]}>
          {backBtn && (
            <button onClick={() => navigate(-1)} className={styles["btn-back"]}>
              <FaChevronLeft />
            </button>
          )}
          <h3>{steps.title}</h3>
          <ul>
            {steps.list.map((step, index) => {
              return (
                <li
                  key={index}
                  className={classnames(
                    index < activeStep && styles["completed"],
                    index === activeStep && styles["active"]
                  )}
                >
                  <div className={styles["number"]}>
                    {index < activeStep ? <FaCheck /> : index + 1}
                  </div>
                  <div className={styles["name"]}>{step}</div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles["container"]}>{children}</div>
      </div>
    </PageLayout>
  );
};

export default PageWithSteps;
