// Check current registration steps and redirect user to the page accordingly
// Total 3 steps for registration
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authURL, personalURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const useRegisterStep = (step: number) => {
  const { registerStep } = useTypedSelector((state) => state.entities.register);
  const { authState } = useActions();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("registerStep", registerStep, step);
    registerStep !== step && redirect(registerStep);
    // registerStep && redirect(registerStep);
  }, [registerStep]);
  function redirect(step: number) {
    switch (step) {
      case 1:
        console.log("step 1 completed");
        navigate(authURL.RegisterEmail);
        break;
      case 2:
        console.log("step 2 completed");
        navigate(authURL.RegisterProfile);
        break;
      case 3:
        console.log("register completed");
        authState();
        navigate(personalURL.PersonalHome);
        break;
      default:
        navigate(authURL.Register);
        break;
    }
  }
};

export default useRegisterStep;
