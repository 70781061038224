import { useNavigate } from "react-router-dom";
import { personalURL } from "../../config/url";
import VerificationCode from "../form/VerificationCode";
import { IChangeEmailInputType } from "../../config/form/formChangeEmail";
import {
  InitialValues,
  Validation,
} from "../../config/form/formChangeEmailVerify";
import { AppButton, AppForm } from "../form";
import ResendCode from "../ui/ResendCode";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect, useState } from "react";
import AppErrors from "../form/AppErrors";
import { EBtnCategory } from "../form/AppButton";
import { ETokenType } from "../../config/types/TokenType";

interface PersonalChangeEmailVerifyProps {
  dataChangeEmail: IChangeEmailInputType;
  setStep(arg: number): void;
}

const PersonalChangeEmailVerify: React.FC<PersonalChangeEmailVerifyProps> = ({
  dataChangeEmail,
  setStep,
}) => {
  const navigate = useNavigate();
  const { tokenId, loading: userLoading } = useTypedSelector(
    (state) => state.entities.user
  );
  const { tokenId: newTokenId, loading: tokenLoading } = useTypedSelector(
    (state) => state.entities.token
  );
  const { changeEmailVerify, createToken } = useActions();
  const [initVal, setInitVal] = useState(InitialValues);

  useEffect(() => {
    if (tokenId) {
      console.log("tokenId", tokenId);
      setInitVal({
        ...initVal,
        ...dataChangeEmail,
        tokenId: tokenId,
      });
    }
  }, [tokenId]);

  useEffect(() => {
    if (newTokenId) {
      console.log("newTokenId", newTokenId);
      setInitVal({
        ...initVal,
        ...dataChangeEmail,
        tokenId: newTokenId,
      });
    }
  }, [newTokenId]);

  return (
    <AppForm
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        console.log("values", values);
        // onSubmit(values);
        // changeEmail({ ...values, newEmail });
        changeEmailVerify(values);
      }}
    >
      <VerificationCode size={6} name="code" />
      <div className="mb-30 mt-5 text-center">
        <ResendCode
          onClick={() =>
            createToken({
              tokenType: ETokenType.verifyEmail,
              email: dataChangeEmail.newEmail,
            })
          }
        />
      </div>
      <AppErrors />
      <AppButton
        className="float-left btn-empty pl-0"
        type="button"
        onClick={() => navigate(personalURL.PersonalProfile)}
      >
        Cancel
      </AppButton>
      <AppButton
        className="float-right"
        type="submit"
        category={EBtnCategory.Primary}
        disabled={userLoading || tokenLoading}
      >
        Submit
      </AppButton>
      <AppButton
        type="button"
        className="float-right btn-empty"
        onClick={() => {
          console.log("edit");
          setStep(1);
        }}
      >
        Edit email
      </AppButton>
    </AppForm>
  );
};

export default PersonalChangeEmailVerify;
