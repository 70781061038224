import { useEffect } from "react";
import {
	LayoutFullHeight,
	LayoutHeader,
} from "../../components/layout/LayoutFullHeight";
import CreateMeetup from "../../components/personal/CreateMeetup";
import Loader from "../../components/ui/Loader";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const PersonalCreateMeetupPage: React.FC = () => {
	const { getFriends, getPersonalProfile } = useActions();
	const { request: friendRequest } = useTypedSelector(
		(state) => state.entities.friend
	);
	const { request: userRequest } = useTypedSelector(
		(state) => state.entities.user
	);

	useEffect(() => {
		getFriends();
		getPersonalProfile();
	}, []);

	useDocumentTitle("New Personal Meetup");

	return (
		<LayoutFullHeight>
			<LayoutHeader
				title='New Meetup'
				desc='Schedule a personal meetup with friends'
			/>
			{(friendRequest?.loading || userRequest?.loading) && (
				<Loader alignCenter height='60vh' />
			)}
			{(friendRequest?.error || userRequest?.error) && (
				<div className='error-msg'>
					{friendRequest?.error}
					{userRequest?.error}
				</div>
			)}
			{!friendRequest && !userRequest && <CreateMeetup />}
		</LayoutFullHeight>
	);
};

export default PersonalCreateMeetupPage;
