import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useActions } from "./useActions";
import { useTypedSelector } from "./useTypedSelector";
import { authURL } from "../config/url";

const usePersonalRegisterStep = () => {
  const [loading, setLoading] = useState(true);
  const { createToken, setRegisterStep } = useActions();
  const { registerStep } = useTypedSelector((state) => state.entities.auth);
  const { tokenId } = useTypedSelector((state) => state.entities.token);
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (registerStep && registerStep < 3) {
      setRegisterStep(registerStep);
      if (registerStep === 1) {
        userData && createToken({ email: userData.email, tokenType: 1 });
      } else if (registerStep === 2) navigate(authURL.RegisterProfile);
    } else setLoading(false);
  }, [registerStep, userData]);

  useEffect(() => {
    tokenId && navigate(authURL.RegisterEmail);
  }, [tokenId]);

  return { loading };
};

export default usePersonalRegisterStep;
