import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import styles from "./TodayBooking.module.scss";
import { IBookingDataType } from "../../config/types/BookingDataType";
import {
  isMeetupType,
  IMeetupDataType,
} from "../../config/types/MeetupDataType";
import { getMeetupStatus, queueNumber } from "../../utils/booking";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface FilteredDataType {
  id: string;
  meetDateTime: Date;
  meetDateTimestamp: number;
  meetDate: string;
  queueNo?: string;
  subject: string;
  desc: string;
  status: number;
}

const cardWidth = 280; // Set the width of each card

const useTodayBooking = (data: IBookingDataType[] | IMeetupDataType[]) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { data: userData } = useTypedSelector((state) => state.entities.user);

  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(cardWidth);
  const [margin, setMargin] = useState(0);
  const [focusItem, setFocusItem] = useState(1);

  useEffect(() => {
    if (data && data.length > 0) {
      setSliderWidth(data.length * cardWidth);
      setWrapperWidth(wrapperRef.current!.getBoundingClientRect().width);
    }
  }, [data]);

  const IBookingDataType = (item: IBookingDataType) => {
    if (item.service) return item.serviceData.name;
    // else if (item.resource) return item.resource.name;
    // else if (item.provider) return item.provider.name;
    else return "";
  };

  const filteredData: FilteredDataType[] = [];
  data.forEach((item, index) => {
    const id = item._id;
    const meetDateTime = item.meetDateTime;
    const meetDateTimestamp = item.meetDateTimestamp;
    const meetDate = item.meetDate;
    const queueNo = !isMeetupType(item)
      ? queueNumber(item.bookingNo, item.serviceData.label)
      : "";
    const subject = !isMeetupType(item) ? item.businessData.name : item.subject;
    const desc = !isMeetupType(item)
      ? IBookingDataType(item)
      : "Personal Meetup";
    const status = isMeetupType(item)
      ? getMeetupStatus({
          meetupStatus: item.bookingStatus,
          participantStatus: item.userStatus,
          meetDateTime: item.meetDateTime,
        })
      : item.bookingStatus;

    filteredData[index] = {
      id,
      meetDateTime,
      meetDateTimestamp,
      meetDate,
      queueNo,
      subject,
      desc,
      status,
    };
  });

  const navPrev = focusItem !== 1 && (
    <div
      className={classNames(styles["nav"], styles["nav-prev"])}
      onClick={() => {
        setMargin(margin + cardWidth);
        setFocusItem(focusItem - 1);
      }}
    >
      <FaChevronLeft />
    </div>
  );

  const navNext = sliderWidth + margin > wrapperWidth && (
    <div
      className={classNames(styles["nav"], styles["nav-next"])}
      onClick={() => {
        setMargin(margin - cardWidth);
        setFocusItem(focusItem + 1);
      }}
    >
      <FaChevronRight />
    </div>
  );

  const nav = (
    <>
      {navPrev}
      {navNext}
    </>
  );

  return {
    nav,
    filteredData,
    wrapperRef,
    sliderWidth,
    margin,
    cardWidth,
  };
};

export default useTodayBooking;
