import { useEffect, useState } from "react";
import { ModalHeader } from "../ui/modal";
import { ApptStatus } from "../appointment/ApptStatus";
import CustomerAddNote from "../customer/CustomerAddNote";
import CustomerNoteDetails from "../customer/CustomerNoteDetails";
import BookingDetails from "./BookingDetails";
import { CustomerNoteType } from "../../config/types/CustomerDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../ui/Loader";

const useModalBookingContent = (onClose: () => void) => {
  const [note, setNote] = useState<"new" | CustomerNoteType | null>();
  const [activeTab, setActiveTab] = useState<number | null>();
  const { singleData, loading: resLoading } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    resLoading ? setLoading(true) : setLoading(false);
  }, [resLoading]);

  useEffect(() => {
    return () => setLoading(true);
  }, []);

  const modalBookingContent: () => JSX.Element = () => {
    if (!singleData || loading) {
      return <Loader alignCenter height="200px" />;
    } else if (note && note === "new") {
      //Notes
      return (
        <>
          <ModalHeader
            title={`New note for ${singleData.customerData.displayName}`}
            backURL={() => {
              setNote(null);
              setActiveTab(2);
            }}
          />
          <CustomerAddNote
            id={singleData._id}
            onCancel={() => {
              setNote(null);
              setActiveTab(2);
            }}
          />
        </>
      );
    }
    if (note) {
      //Note details
      return (
        <>
          <ModalHeader
            title={`Note for ${singleData.customerData.displayName}`}
            backURL={() => {
              setNote(null);
              setActiveTab(2);
            }}
          />
          <CustomerNoteDetails data={note} />
        </>
      );
    }
    console.log("singleData", singleData);
    return (
      <>
        <ModalHeader
          title={
            singleData.customerData.displayName
              ? singleData.customerData.displayName
              : singleData.customerData.email
          }
          label={
            <ApptStatus
              statusNo={singleData.bookingStatus}
              dateTime={singleData.meetDate}
              timestamp={singleData.meetDateTimestamp}
            />
          }
        />
        <BookingDetails
          data={singleData}
          onAddNote={() => setNote("new")}
          onViewNote={(note) => setNote(note)}
          activeTab={activeTab}
          onClose={onClose}
        />
      </>
    );
  };

  return { modalBookingContent };
};

export default useModalBookingContent;
