import useDocumentTitle from "../../hooks/useDocumentTitle";
import { AppForm } from "../form";
import { LayoutBody } from "../layout/LayoutFullHeight";
import BusinessRegisterFooter from "./BusinessRegisterFooter";
import { useActions } from "../../hooks/useActions";
import { InitialValues, Validation } from "../../config/form/formBusinessHours";
import BusinessHours from "../businessProfile/BusinessHours";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const BusinessRegisterBusinessHours: React.FC<{}> = () => {
  useDocumentTitle("Business Hours - AddPointment");
  const { error } = useTypedSelector((state) => state.entities.setup);

  const { setupHours } = useActions();

  return (
    <AppForm
      className="flex-layout"
      initialValues={InitialValues}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        setupHours(values);
      }}
    >
      <LayoutBody>
        <h3 className="mb-3">Business Hours</h3>
        <div className="desc mb-20">
          Enter your business contact so that customer can reach you easily.
        </div>
        {error && <p className="error-msg mb-20">{error}</p>}
        <BusinessHours />
      </LayoutBody>
      <BusinessRegisterFooter step={6} />
    </AppForm>
  );
};

export default BusinessRegisterBusinessHours;
