import * as reducers from "../reducer/invitationReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import {
  getInvitations as dataInvitation,
  getInvitation as singleInvitation,
} from "../../data/dataInvitation";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/invitation";

// Get invitation by someone
export const getInvitation =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { invitation: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.invitation;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        data: config.localENV && dataInvitation(),
        method: "get",
        onStart: reducers.invitationRequest.type,
        onSuccess: reducers.invitationReceived.type,
        onError: reducers.invitationRequestFailed.type,
      })
    );
  };

export const getSingleInvitation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("getSingleInvitation", id);
    return dispatch(
      apiCallBegan({
        url: url + "/single",
        data: config.localENV ? singleInvitation("invitation3") : { id },
        method: "post",
        onStart: reducers.singleRequest.type,
        onSuccess: reducers.singleReceived.type,
        onError: reducers.singleRequestFailed.type,
      })
    );
  };

// Accept
// TODO: handle accept
export const acceptStaffInvitation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url,
        data: dataInvitation(), // TODO: remove
        method: "post",
        onStart: reducers.invitationUpdating.type,
        onSuccess: reducers.invitationAccepted.type,
        onError: reducers.invitationUpdateFailed.type,
      })
    );
  };

// Reject
// TODO: handle reject
export const rejectStaffInvitation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url,
        data: dataInvitation(), // TODO: remove
        method: "post",
        onStart: reducers.invitationUpdating.type,
        onSuccess: reducers.invitationRejected.type,
        onError: reducers.invitationUpdateFailed.type,
      })
    );
  };

// Accept
export const acceptPersonalInvitation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/accept-personal",
        data: { id },
        method: "post",
        onStart: reducers.invitationUpdating.type,
        onSuccess: reducers.invitationAccepted.type,
        onError: reducers.invitationUpdateFailed.type,
      })
    );
  };

// Reject
// TODO: handle reject
export const rejectPersonsalInvitation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url,
        data: dataInvitation(), // TODO: remove
        method: "post",
        onStart: reducers.invitationUpdating.type,
        onSuccess: reducers.invitationRejected.type,
        onError: reducers.invitationUpdateFailed.type,
      })
    );
  };
