import { ICustomerDataType } from "../../config/types/CustomerDataType";
import Table, { TableDataType } from "../ui/table";
import TableCustomerActions from "./TableCustomerActions";
import prefixId, { idType } from "../../utils/prefixID";
import ShowPIN from "../ui/ShowPIN";

interface TableProps {
  data: TableDataType;
  onView(id: string): void;
  onAddNote(id: string, arg: boolean): void;
}

const TableCustomer: React.FC<TableProps> = ({ data, onView, onAddNote }) => {
  const columns = [
    {
      label: "No.",
      name: "no",
      colWidth: "60px",
      colMaxWidth: "90px",
      content: ({}, index: number) => <>{index + 1}</>,
    },
    {
      label: "Name",
      name: "name",
      // colWidth: "40%",
      // colMaxWidth: "40%",
      content: (customer: { displayName: string }) => (
        <div className="h5 text-wrap">{customer.displayName}</div>
      ),
    },
    // {
    //   label: "Customer ID",
    //   name: "id",
    //   colWidth: "20%",
    //   colMaxWidth: "25%",
    //   content: (customer: { customerUid: string }) => (
    //     <div className="h5">
    //       {prefixId(customer.customerUid, idType.Customer)}
    //     </div>
    //   ),
    // },
    {
      label: "Customer PIN",
      name: "id",
      colWidth: "20%",
      colMaxWidth: "25%",
      disableItemClick: true,
      content: (customer: { pin: string }) =>
        customer.pin && <ShowPIN pin={customer.pin} />,
    },
    // {
    //   label: "Tags",
    //   name: "tags",
    //   content: (customer: { tags: { name: string }[] }) =>
    //     customer.tags &&
    //     customer.tags.length > 0 && (
    //       <div className="desc">
    //         {customer.tags.map((item, index) => {
    //           return (
    //             <Fragment key={index}>
    //               {index > 0 && <>, </>}
    //               <span>{item.name}</span>
    //             </Fragment>
    //           );
    //         })}
    //       </div>
    //     ),
    // },
    // {
    //   name: "create",
    //   colWidth: "20%",
    //   colMaxWidth: "20%",
    //   disableItemClick: true,
    //   content: () => <div className="link">Create Booking</div>,
    // },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "90px",
      disableItemClick: true,
      content: (customer: ICustomerDataType) => (
        <TableCustomerActions
          id={customer._id}
          customer={customer}
          onAddNote={onAddNote}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        itemClick={(customer: { _id: string }) => onView(customer._id)}
      />
    </>
  );
};

export default TableCustomer;
