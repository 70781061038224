import { useContext } from "react";
import styles from "./Accounts.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { AppContext, ContextType } from "../../context/appContext";

import {
  authURL,
  personalURL,
  businessURL,
  businessSetupURL,
  businessProfileURL,
} from "../../config/url";
import { Dropdown } from "../ui/dropdown";
import classNames from "classnames";
import { AvatarPic } from "../ui/avatar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

interface AccountsProps {
  role: "personal" | "business";
  isHeader?: boolean;
}

const Accounts: React.FC<AccountsProps> = ({ role, isHeader }) => {
  const navigate = useNavigate();
  const { logout } = useActions();
  const { appState } = useContext(AppContext) as ContextType;
  const { data: personal } = useTypedSelector((state) => state.entities.user);
  const { data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );

  const personalName = personal?.displayName;
  const businessName = business?.name;

  const activeRole = {
    name: role === "business" ? businessName : personalName,
    profileLink:
      role === "business"
        ? businessProfileURL.Info
        : personalURL.PersonalProfile,
  };

  const activeProfilePic =
    role === "business" ? business?.profilePic : personal?.profilePic;

  const handleClick = (id: string) => {
    console.log("id", id);
    if (id === "personal") navigate(personalURL.PersonalHome);
    else navigate(businessURL.BusinessDashboard);
  };

  const menuList = (
    <>
      <div className={styles["current-role"]}>
        <div className={styles["pic"]}>
          <AvatarPic role={role} src={activeProfilePic} />
        </div>
        <div className={classNames(styles["name"], "h5")}>
          {activeRole.name}
        </div>
        <div className={classNames(styles["role"], "desc")}>{role}</div>
        <Link to={activeRole.profileLink} className="link">
          Manage {role} Profile
        </Link>
      </div>
      <ul className={styles["menu-list"]}>
        {role !== "personal" && (
          <li>
            <div
              className={styles["btn"]}
              role="button"
              onClick={() => handleClick("personal")}
            >
              <AvatarPic
                role="personal"
                name={personalName}
                size="small"
                src={personal?.profilePic}
              />
              <div className={styles["meta"]}>
                <div className={classNames("h5", styles["name"])}>
                  {personalName}
                </div>
                <div className="desc">Personal</div>
              </div>
            </div>
          </li>
        )}
        {role !== "business" &&
          (businessName ? (
            <li>
              <div
                className={styles["btn"]}
                role="button"
                onClick={() => handleClick("business")}
              >
                <AvatarPic
                  role="business"
                  src={business.profilePic}
                  name={businessName}
                  size="small"
                />
                <div className={styles["meta"]}>
                  <div className={classNames("h5", styles["name"])}>
                    {businessName}
                  </div>
                  <div className="desc">Business</div>
                </div>
              </div>
            </li>
          ) : (
            <li>
              <div
                role="button"
                onClick={() => navigate(businessSetupURL.Info)}
                className={classNames(styles["link"], "link")}
              >
                Create a business
              </div>
            </li>
          ))}
        <li>
          <div
            role="button"
            // onClick={() => navigate(authURL.Logout)}
            onClick={(e) => {
              e.stopPropagation();
              logout();
              // console.log("Logout");
            }}
            className={classNames(styles["link"], "link")}
          >
            Logout
          </div>
        </li>
      </ul>
    </>
  );

  return (
    <div
      className={classNames(
        styles["container"],
        appState.collapsed && styles["collapsed"]
      )}
    >
      <Dropdown
        menu={menuList}
        className={classNames(
          styles["trigger"],
          isHeader && styles["is-header"]
        )}
        fullWidth
      >
        <AvatarPic name={activeRole.name} size="small" src={activeProfilePic} />
        <div className={styles["current-name"]}>{activeRole.name}</div>
      </Dropdown>
    </div>
  );
};

export default Accounts;
