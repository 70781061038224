import { useState, useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const duration: number = 60;

interface ResendCodeProps {
  onClick: () => void;
}

const ResendCode: React.FC<ResendCodeProps> = ({ onClick }) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [resend, setResend] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const { tokenId } = useTypedSelector((state) => state.entities.token);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    if (seconds >= 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (tokenId) {
      setSeconds(duration);
      resend && setResendSuccess(true);
    }
  }, [tokenId]);

  const handleOnClick = () => {
    setResend(true);
    onClick();
  };

  const cta = () => {
    if (seconds > 0) {
      return (
        <>
          Resend a new code in{" "}
          <span className="text-semibold">{seconds} seconds</span>
        </>
      );
    } else {
      return (
        <button
          type="button"
          className="p-0 text-semibold btn-link"
          onClick={handleOnClick}
        >
          Resend code
        </button>
      );
    }
  };

  return (
    <>
      {resendSuccess && (
        <p className="success-msg text-semibold">New code has been sent</p>
      )}
      Didn't receive a code? {cta()}
    </>
  );
};

export default ResendCode;
