import * as reducers from "../reducer/setupReducer";
import { apiCallBegan } from "../middleware/api";
import { IBusinessInfoInputType } from "../../config/form/formBusinessProfile";
import {
  InitialValues as ContactValues,
  IBusinessContactInputType,
} from "../../config/form/formBusinessContact";
import { IBusinessHoursInputType } from "../../config/form/formBusinessHours";

interface DispatchType {
  type: string;
}

const url = "/setup";

export const clearSetupState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({
      type: reducers.setupClearState.type,
    });
  };

export const createBusiness =
  (data: IBusinessInfoInputType) => (dispatch: (arg: DispatchType) => void) => {
    const businessData = {
      ...data,
      location: { ...ContactValues },
    };
    console.log(businessData);
    return dispatch(
      apiCallBegan({
        url: url + "/",
        data,
        method: "post",
        onStart: reducers.setupSubmit.type,
        onSuccess: reducers.setupCreated.type,
        onError: reducers.setupSubmitFailed.type,
      })
    );
  };

export const checkBusinessUrl =
  (data: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("checkBusinessUrl", data);
    return dispatch(
      apiCallBegan({
        url: url + "/check-url",
        data: { businessUrl: data },
        method: "post",
        onStart: reducers.setupCheckURL.type,
        onSuccess: reducers.setupURLChecked.type,
        onError: reducers.setupCheckURLFailed.type,
      })
    );
  };

export const setupContact =
  (data: IBusinessContactInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log(data);
    return dispatch(
      apiCallBegan({
        url: url + "/contact",
        data,
        method: "post",
        onStart: reducers.setupSubmit.type,
        onSuccess: reducers.setupSubmitted.type,
        onError: reducers.setupSubmitFailed.type,
      })
    );
  };

export const setupHours =
  (data: IBusinessHoursInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log(data);
    return dispatch(
      apiCallBegan({
        url: url + "/hours",
        data,
        method: "post",
        onStart: reducers.setupSubmit.type,
        onSuccess: reducers.setupSubmitted.type,
        onError: reducers.setupSubmitFailed.type,
      })
    );
  };
