import { optionMeetingPoint } from "../../config/option/optionMeetingPoint";

const useMeetingPoint = () => {
  const getMeetingPointName = (type: number) => {
    const meetingPoint = optionMeetingPoint.find((item) => item.value === type);
    return meetingPoint ? meetingPoint.name : "Meeting Point";
  };

  return { getMeetingPointName };
};

export default useMeetingPoint;
