import { useState } from "react";

export type dataRangeType = { year?: number; month?: number };

const useCalendarNav = () => {
  const [output, setOutput] = useState<dataRangeType>({});
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const handleClick: (nav: string) => void = (nav) => {
    switch (nav) {
      case "prev":
        const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
        changeMonth(prevMonth, prevYear);
        break;
      case "today":
        const todayMonth = new Date().getMonth();
        const todayYear = new Date().getFullYear();
        changeMonth(todayMonth, todayYear);
        console.log("today");
        break;
      case "next":
        const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        changeMonth(nextMonth, nextYear);
        break;
      default:
        setCurrentMonth(new Date().getMonth());
        setCurrentYear(new Date().getFullYear());
        break;
    }
  };

  const changeMonth = (month: number, year: number) => {
    setOutput({ year, month });
    setCurrentMonth(month);
    setCurrentYear(year);
  };
  return { currentMonth, currentYear, output, handleClick };
};

export default useCalendarNav;
