import { useEffect, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import styles from "./CreateBookingSubmit.module.scss";
import Loader from "../ui/Loader";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classNames from "classnames";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { IOnsiteServiceInputType } from "../../config/form/formOnsiteService";
import { ICreateBookingCustomerInputType } from "../../config/form/formCreateBookingCustomer";
import { IProviderDataType } from "../../config/types/ProviderDataType";

export interface ICreateBookingInputType {
  selectedService: IServiceDataType;
  selectedProvider?: IProviderDataType;
  selectedDateTime: Date;
  selectedCustomer: ICreateBookingCustomerInputType;
  selectedLocation: ILocationDataType;
  onsiteService?: IOnsiteServiceInputType;
}

interface CreateBookingSubmitProps {
  onBack(): void;
  onReset(): void;
  bookingData: ICreateBookingInputType;
}

const CreateBookingSubmit: React.FC<CreateBookingSubmitProps> = ({
  bookingData,
  onBack,
  onReset,
}) => {
  const { createBusinessBooking, clearBusinessBookingState } = useActions();
  const [submitted, setSubmitted] = useState(false);
  const { createBooking } = useTypedSelector(
    (state) => state.entities.businessBooking
  );

  useEffect(() => {
    return () => {
      clearBusinessBookingState();
      setSubmitted(false);
    };
  }, []);

  useEffect(() => {
    bookingData && !submitted && onSubmit();
  }, [bookingData, submitted]);

  async function onSubmit() {
    console.log("bookingData", submitted);
    createBusinessBooking(bookingData);
    setSubmitted(true);
  }

  function LoadingScreen() {
    return (
      <div className="text-center">
        <Loader height="180px" />
        <h5 className="my-10">Submitting your booking request</h5>
        <button className="btn btn-link" onClick={() => onReset()}>
          Cancel
        </button>
      </div>
    );
  }

  function ErrorScreen() {
    return (
      <div className="text-center">
        <div className={classNames(styles["icon"], styles["icon-error"])}>
          <FaTimesCircle />
        </div>
        <h3 className="my-5">Error</h3>
        <p>{createBooking?.error}</p>
        <button className="btn btn-link" onClick={() => onBack()}>
          Go back
        </button>
      </div>
    );
  }

  function SuccessScreen() {
    return (
      <div className="text-center">
        <div className={classNames(styles["icon"], styles["icon-success"])}>
          <FaCheckCircle />
        </div>
        <h3 className="my-5">Success</h3>
        <p>Booking has been created</p>
        <button className="btn btn-link" onClick={() => onReset()}>
          Create another booking
        </button>
      </div>
    );
  }

  if (createBooking?.success) return <SuccessScreen />;
  else if (createBooking?.error) return <ErrorScreen />;
  else return <LoadingScreen />;
};

export default CreateBookingSubmit;
