import axios, { Method } from "axios";
import { createAction } from "@reduxjs/toolkit";
import config from "../../config/global";

interface apiDispatch {
  type?: string;
  payload?: object | string;
}

export interface IapiCallBegan {
  url?: string;
  method?: Method;
  data?: object | string | null | boolean | number;
  onStart?: string;
  onStartDispatch?: apiDispatch;
  onSuccess?: string;
  onSuccessDispatch?: apiDispatch;
  onError?: string;
  onErrorDispatch?: apiDispatch;
}

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}
export const apiLoad = createAction<string>("api/load");
export const apiCallBegan = createAction(
  "api/callBegan",
  withPayloadType<IapiCallBegan | string>()
);
export const apiCallSuccess = createAction<string>("api/callSuccess");
export const apiCallFailed = createAction<string>("api/callFailed");

const api =
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: { payload: IapiCallBegan; type: string }) => {
    if (action.type === apiLoad.type) {
      console.log("load here...");
      dispatch({ type: action.payload });
      return next(action);
    }
    if (action.type !== apiCallBegan.type) return next(action);

    const {
      url,
      method,
      data,
      onStart,
      onStartDispatch,
      onSuccess,
      onSuccessDispatch,
      onError,
      onErrorDispatch,
    } = action.payload;

    if (onStart) dispatch({ type: onStart });
    if (onStartDispatch)
      dispatch({
        type: onStartDispatch.type,
        payload: onStartDispatch.payload,
      });

    next(action);

    const sessionData = sessionStorage.getItem("addppointment");
    const tokens = sessionData ? JSON.parse(sessionData) : null;
    // console.log("tokens", tokens);

    try {
      // const response = { data };
      const response = config.localENV
        ? { data }
        : await axios.request({
            baseURL: config.api,
            // headers: tokens
            //   ? {
            //       "Content-Type": "application/json",
            //       authorization: tokens.accessToken,
            //       "x-refresh": tokens.refreshToken,
            //     }
            //   : { "Content-Type": "application/json" },
            url,
            method,
            data:
              typeof data === "object"
                ? { ...data, media: config.media }
                : data,
            withCredentials: true,
          });
      // console.log("response", response);
      // General
      if (response.data && !response.data.error) {
        dispatch(apiCallSuccess(response.data));
        if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
        else if (onSuccessDispatch)
          dispatch({
            type: onSuccessDispatch.type,
            payload: {
              data: response.data,
              actionData: onSuccessDispatch.payload,
            },
          });
      } else {
        const error =
          response.data && response.data.error ? response.data.error : "";
        dispatch(apiCallFailed(error));
        if (onError) dispatch({ type: onError, payload: error });
        else if (onErrorDispatch)
          dispatch({
            type: onErrorDispatch.type,
            payload: {
              error: error,
              actionData: onErrorDispatch.payload,
            },
          });
      }
    } catch (err: any) {
      console.log(err);
      let errorMsg = "Error occurred. Pease try again";

      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
        errorMsg = err.response.data.message;
      } else if (err.request) {
        // The request was made but no response was received
        // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", err.message);
        errorMsg = err.message;
      }

      dispatch(apiCallFailed(errorMsg));
      // Specific
      if (onError) {
        dispatch({ type: onError, payload: errorMsg });
      }
      if (onErrorDispatch) {
        console.log("error", errorMsg);
        dispatch({
          type: onErrorDispatch.type,
          payload: {
            error: errorMsg,
            actionData: onErrorDispatch.payload,
          },
        });
      }
    }
  };

export default api;
