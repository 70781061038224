import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppButton, AppInput, AppForm } from "../../components/form";
import { authURL } from "../../config/url";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import {
  TokenInitialValues,
  TokenValidation,
  ResetPwdInitialValues,
  ResetPwdValidation,
} from "../../config/form/formResetPwd";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import VerificationCode from "../../components/form/VerificationCode";
import ResendCode from "../../components/ui/ResendCode";
import { EBtnCategory } from "../../components/form/AppButton";
import { ETokenType } from "../../config/types/TokenType";

const AuthResetPasswordPage = () => {
  useDocumentTitle("Reset Password");
  const [error, setError] = useState("");
  const [tokenInitVal, setTokenInitVal] = useState(TokenInitialValues);
  const [resetPwdInitVal, setResetPwdInitVal] = useState(ResetPwdInitialValues);
  const {
    validateToken,
    resetPwd,
    createToken,
    clearTokenState,
    clearAuthState,
  } = useActions();
  const {
    passwordToken,
    passwordReset,
    error: authError,
  } = useTypedSelector((state) => state.entities.auth);
  const {
    valid,
    tokenId,
    error: tokenError,
  } = useTypedSelector((state) => state.entities.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (authError) {
      setError(authError);
      clearAuthState();
    }
    tokenError && setError(tokenError);
  }, [authError, tokenError]);

  useEffect(() => {
    valid && setError("");
  }, [valid]);

  useEffect(() => {
    passwordReset && navigate(authURL.Login);
  }, [passwordReset]);

  useEffect(() => {
    console.log("tokenId", tokenId);
    if (tokenId) {
      setTokenInitVal({ ...tokenInitVal, tokenId });
      setResetPwdInitVal({ ...resetPwdInitVal, tokenId });
    }
  }, [tokenId]);

  useEffect(() => {
    return () => {
      clearAuthState();
      clearTokenState();
    };
  }, []);

  useEffect(() => {
    console.log("passwordToken", passwordToken);
    if (passwordToken)
      setTokenInitVal({
        ...TokenInitialValues,
        ...passwordToken,
      });
    else navigate(authURL.ForgotPassword);
  }, [passwordToken]);

  if (!passwordToken) return null;

  if (valid) {
    return (
      <LayoutCenterBox>
        <div className="mb-30 text-center">
          <h1>Reset Your Password</h1>
          <p>Enter a new password for your account</p>
          <div className="h5">{passwordToken.email}</div>
          {error && <p className="error-msg">{error}</p>}
        </div>
        <AppForm
          initialValues={resetPwdInitVal}
          validationSchema={ResetPwdValidation}
          onSubmit={(values, actions) => {
            console.log("values", values);
            resetPwd(values);
            actions.setSubmitting(false);
          }}
        >
          <AppInput
            name="password"
            label="New Password"
            type="password"
            labelBlock
          />
          <AppInput
            name="reEnterPassword"
            label="Re-type Password"
            labelBlock
            type="password"
          />
          <div className="text-center mt-30">
            <AppButton
              type="submit"
              category={EBtnCategory.Primary}
              btnBlock
              size="large"
              // loading={loading}
            >
              Submit
            </AppButton>
            <Link to={authURL.Login} className="btn mt-20 btn-link">
              Back to login
            </Link>
          </div>
        </AppForm>
      </LayoutCenterBox>
    );
  }

  return (
    <LayoutCenterBox>
      <div className="text-center">
        <h1>Reset your password</h1>
        <p>Please enter the code send to your email</p>
        <div className="h5 mb-30">{passwordToken.email}</div>
        {error && <p className="error-msg">{error}</p>}
        <AppForm
          initialValues={tokenInitVal}
          validationSchema={TokenValidation}
          onSubmit={(values, actions) => {
            console.log("values", values);
            actions.setSubmitting(false);
            setResetPwdInitVal({ ...resetPwdInitVal, ...values });
            validateToken(values);
          }}
        >
          <VerificationCode size={6} name="code" />
          <div className="mb-30 mt-5 text-center">
            <ResendCode
              onClick={() =>
                createToken({
                  tokenType: ETokenType.resetPassword,
                  email: passwordToken.email,
                })
              }
            />
            .
          </div>
          <div className="text-center mt-30">
            <AppButton
              type="submit"
              btnBlock
              category={EBtnCategory.Primary}
              size="large"
              // loading={loading}
            >
              Submit
            </AppButton>
            <Link to={authURL.Login} className="btn mt-10 btn-link">
              Back to login
            </Link>
          </div>
        </AppForm>
      </div>
    </LayoutCenterBox>
  );
};

export default AuthResetPasswordPage;
