import { ProfilePic } from "../ui/avatar";
import ListItem from "../ui/ListItem";
import ReadMore from "../ui/ReadMore";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import { ILocationDataType } from "../../config/types/LocationDataType";

interface BookmarkBusinessInfoProps {
  business: IBusinessDataType;
  location: ILocationDataType;
}

const BookmarkBusinessInfo: React.FC<BookmarkBusinessInfoProps> = ({
  business,
  location,
}) => {
  return (
    <>
      <div className="mb-30">
        <ProfilePic
          role="business"
          name={business.name}
          imageSrc={business.profilePic}
        />
      </div>
      <ListItem label="Website">{location.website}</ListItem>
      <ListItem label="Phone">{location.phone}</ListItem>
      <ListItem label="Email">{location.email}</ListItem>
      <ReadMore charLimit={100}>{business.about}</ReadMore>
    </>
  );
};
export default BookmarkBusinessInfo;
