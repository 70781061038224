import PageWithAside from "../../components/layout/PageWithAside";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { LayoutFullHeight } from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessHoliday from "../../components/businessProfile/BusinessHoliday";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 3;
const BusinessHolidaysPage: React.FC = () => {
  useDocumentTitle("Profile - AddPointment");
  const { header } = useBusinessProfile(index, true);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <LayoutFullHeight>
        <div className="wrapper">
          {header()}
          <BusinessHoliday />
        </div>
      </LayoutFullHeight>
    </PageWithAside>
  );
};

export default BusinessHolidaysPage;
