import { ModalBody, ModalBox, ModalHeader, ModalFooter } from "../ui/modal";
import { AppButton, AppSelect, AppForm, AppDisplay } from "../form";
import {
  Validation,
  InitialValues,
} from "../../config/form/formStartMeetingPoint";
import { IBookingDataType } from "../../config/types/BookingDataType";
import useBusinessResource from "../resources/useBusinessResource";
import useBusinessProvider from "../providers/useBusinessProvider";

interface ModalBookingMeetingPointProps {
  onClose(): void;
  data: IBookingDataType;
}

// TODO: get meeting point data
const options = [
  { name: "Select Service", value: "Select Service" },
  { name: "Select Service 2", value: "Select Service 2" },
];

const labelWidth = "150px";

const ModalBookingMeetingPoint: React.FC<ModalBookingMeetingPointProps> = ({
  onClose,
  data,
}) => {
  const { getResourceName } = useBusinessResource();
  const { getProviderName } = useBusinessProvider();
  return (
    <ModalBox onClose={onClose} type="business" overlayClose>
      <ModalHeader title="Start Serving" />
      <AppForm
        className="flex-layout"
        initialValues={InitialValues}
        onSubmit={(value, actions) => {
          actions.setSubmitting(false);
          onClose();
          console.log("ModalBookingMeetingPoint value", value);
        }}
        validationSchema={Validation}
      >
        <ModalBody>
          <div className="wrapper mt-10">
            <AppDisplay
              displayValue={data.customerData.displayName}
              label="Customer"
              labelWidth={labelWidth}
            />
            <AppDisplay
              displayValue={data.bookingNo}
              label="Queue No"
              labelWidth={labelWidth}
            />
            {data.service && (
              <AppDisplay
                displayValue={data.serviceData.name}
                label="Service"
                labelWidth={labelWidth}
              />
            )}
            {/* {data.resource && (
              <AppDisplay
                displayValue={data.resource.name}
                label={getResourceName(data.resource.bookingType)}
                labelWidth={labelWidth}
              />
            )}
            {data.provider && (
              <AppDisplay
                displayValue={data.provider.name}
                label={getProviderName(data.provider.bookingType)}
                labelWidth={labelWidth}
              />
            )} */}
            {/* TODO: temporary hide */}
            {/* <AppDisplay
              displayValue={data.service.staff}
              label="Provider"
              labelWidth={labelWidth}
            /> */}
            <AppSelect
              name="meetingPoint"
              label="Meeting Point"
              labelWidth={labelWidth}
              inputWidth="300px"
              options={options}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <AppButton type="button" onClick={onClose} className="btn btn-link">
            Cancel
          </AppButton>
          <AppButton className="btn btn-primary" type="submit">
            Start
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalBookingMeetingPoint;
