import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import ServiceProvider from "../../components/services/ServiceProvider";

const ServiceProviderPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { request: requestProvider } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const { service, submenu, header } = useBusinessServicePage(id);
  const { getAdminProviders } = useActions();

  // console.log(requestProvider);

  useEffect(() => {
    getAdminProviders();
  }, []);

  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && requestProvider?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && !requestProvider && data && service && (
          <ServiceProvider service={service} />
        )}
      </div>
    </PageWithAside>
  );
};

export default ServiceProviderPage;
