import { getUser } from "./dataUsers";

const staff = [
  {
    _id: "staff1",
    displayName: "Muhammad",
    user: getUser("user1"),
    email: "ali@muhammad.com",
    role: 1,
    locationId: "location1",
    businessId: "business1",
  },
  {
    _id: "staff2",
    displayName: "Jonathan",
    user: getUser("user2"),
    email: "tan@jonathan.com",
    role: 2,
    locationId: "location1",
    businessId: "business1",
  },
  {
    _id: "staff3",
    displayName: "Ahmad",
    user: getUser("user3"),
    email: "alif@ahmad.com",
    role: 3,
    locationId: "location2",
    businessId: "business1",
  },
  {
    _id: "staff4",
    displayName: "Michael",
    user: getUser("user4"),
    email: "lee@michael.com",
    role: 2,
    locationId: "location2",
    businessId: "business1",
  },
];

export function getStaffs() {
  return staff;
}

export function getStaff(id) {
  return [
    {
      _id: "staff4",
      // user: { name: "Jonathan Tan2" },
      user: getUser("user4"),
      email: "tan2@jonathan.com",
      role: 4,
      locationId: "location2",
      businessId: "business1",
    },
  ];
  return Array.isArray(id)
    ? staff.filter((i) => id.includes(i.id))
    : staff.filter((m) => m.id === id);
  // return staff.find((m) => m.id === id);
}
