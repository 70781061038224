import classNames from "classnames";
import styles from "./ApptStatus.module.scss";
import bookingStatus, {
  EBookingStatusValue,
} from "../../config/types/BookingStatusType";
import { todayString } from "../../utils/datetime";
import { useEffect, useState } from "react";

export interface ApptStatusProps {
  statusNo: number;
  dateTime: string;
  timestamp: number;
  dot?: boolean;
}

export const getApptStatus = (statusNo: number) => {
  const result = bookingStatus.find((x) => x.value === statusNo);
  return result;
};

export const ApptStatus: React.FC<ApptStatusProps> = ({
  statusNo,
  dateTime,
  timestamp,
  dot,
}) => {
  // 1: upcoming, 2: today, 3: over
  const [statusType, setStatusType] = useState(0);
  const [statusValue, setStatusValue] = useState({ category: "", label: "" });

  useEffect(() => {
    if (dateTime === todayString) setStatusType(2);
    else {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTimestamp = today.getTime();
      if (timestamp < todayTimestamp) setStatusType(3);
      else setStatusType(1);
    }
  }, [dateTime, timestamp]);

  useEffect(() => {
    if (statusNo) {
      const result = getApptStatus(statusNo);
      result &&
        setStatusValue({ category: result.category, label: result.label });
    }
  }, [statusNo]);

  if (statusType === 0) return null;

  if (dot) {
    if (
      (statusType === 2 && statusNo < EBookingStatusValue.complete) ||
      (statusType === 1 && statusNo < EBookingStatusValue.book)
    )
      return (
        <div
          className={classNames(
            styles["dot"],
            styles[`dot-${statusValue.category}`]
          )}
        ></div>
      );
    return null;
  }
  return (
    <div
      className={classNames(
        styles["status"],
        statusType === 3
          ? styles[`status-past`]
          : styles[`status-${statusValue.category}`]
      )}
    >
      {statusType === 3 && statusNo < EBookingStatusValue.complete
        ? "Ended"
        : statusValue.label}
    </div>
  );
};
