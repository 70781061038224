import * as yup from "yup";

export const InitialValues = {
  subject: "",
  message: "",
  customerId: "",
};

// Validation
export const Validation = yup.object().shape({
  customerId: yup.string().required("Please enter note subject"),
  subject: yup
    .string()
    .max(30, "Maximum 30 characters")
    .required("Please enter note subject"),
  message: yup
    .string()
    .max(300, "Maximum 300 characters")
    .required("Note cannot be empty"),
});

export interface AddCustomerNoteType {
  subject: string;
  message: string;
}
