import { useEffect } from "react";
import styles from "./Calendar.module.scss";
import CalendarHead from "./CalendarHead";
import CalendarBody from "./CalendarBody";
import CalendarHeader from "./CalendarHeader";
import CalendarNav from "./CalendarNav";
import useCalendarNav, { dataRangeType } from "./useCalendarNav";
import {
  DataType,
  onClickBookingType,
  onSelectDateType,
} from "./useCalendarSlot";
import { ICustomDayDataType } from "../../../config/types/CustomDayDataType";

export type CalendarDataRangeType = dataRangeType;
export type CalendarDataType = DataType;
export type CalendarClickBookingType = onClickBookingType;

interface CalendarProps {
  data?: DataType[];
  type: "mini" | "full";
  minDate?: Date;
  maxDate?: Date;
  selectedDate?: Date;
  businessHours?: string;
  customBusinessHours?: ICustomDayDataType[];
  dataRange?: (arg: dataRangeType) => void;
  onClickBooking?: onClickBookingType;
  onSelectDate?: onSelectDateType;
  showNeighboringMonth?: boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  data,
  type,
  minDate,
  maxDate,
  selectedDate,
  businessHours,
  customBusinessHours,
  dataRange,
  onClickBooking,
  onSelectDate,
  showNeighboringMonth,
}) => {
  const { currentMonth, currentYear, output, handleClick } = useCalendarNav();
  useEffect(() => {
    output && dataRange && dataRange(output);
  }, [output]);

  return (
    <div className={styles[`calendar-${type}`]}>
      {/* <CalendarHeader /> */}
      <CalendarNav
        onClick={(nav) => handleClick(nav)}
        currentMonth={currentMonth}
        currentYear={currentYear}
      />
      <table className={styles["table"]}>
        <CalendarHead />
        <CalendarBody
          minDate={minDate}
          maxDate={maxDate}
          currentMonth={currentMonth}
          currentYear={currentYear}
          selectedDate={selectedDate}
          showNeighboringMonth={showNeighboringMonth}
          data={data}
          type={type}
          businessHours={businessHours}
          customBusinessHours={customBusinessHours}
          onClickBooking={onClickBooking}
          onSelectDate={onSelectDate}
        />
      </table>
    </div>
  );
};

export default Calendar;
