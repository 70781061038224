import { useEffect } from "react";
import { AppButton } from "../form";
import { LayoutFooter } from "../layout/LayoutFullHeight";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { EBtnCategory } from "../form/AppButton";

const businessContent = [
  {
    title: "Business Info",
    desc: "Some information to help customer understand about your business",
  },
  {
    title: "Business Contact",
    desc: "Enter your business contact so that customer can reach you easily",
  },
  {
    title: "Business Hours",
    desc: "Set your business hour to let your customers know when you're open for business",
  },
  {
    title: "QR Code",
    desc: "Use the QR Code for walk-in booking",
  },
  {
    title: "Holiday",
    desc: "Set holiday",
  },
  {
    title: "Location",
    desc: "Enter your business contact so that customer can reach you easily",
  },
  {
    title: "Staff",
    desc: "You can invite new staff member to access your AddPointment business account.",
  },
];

const useBusinessProfile = (index: number, isEdit?: boolean) => {
  const { getSelfBusiness } = useActions();
  const { loading } = useTypedSelector((state) => state.entities.businessAdmin);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelfBusiness();
  }, []);

  const header = () => {
    return isEdit ? (
      <>
        <div className="mt-15 page-title">
          <h1>{businessContent[index].title}</h1>
          <p className="desc">{businessContent[index].desc}</p>
        </div>
      </>
    ) : (
      <>
        <h3 className="mb-3">{businessContent[index].title}</h3>
        <div className="desc mb-20">{businessContent[index].desc}</div>
      </>
    );
  };

  const footer = () => {
    return (
      <AppButton
        category={EBtnCategory.Primary}
        className="my-20"
        type="submit"
        loading={loading}
      >
        Save
      </AppButton>
    );
  };

  return { header, footer };
};

export default useBusinessProfile;
