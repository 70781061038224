import * as reducers from "../reducer/personalBookingReducer";
import { apiCallBegan } from "../middleware/api";
import {
  getUpcomingBookings,
  getPastBookings,
  getOneBooking,
} from "../../data/dataAppts";
import { fetch } from "../storeConfig";
import config from "../../config/global";
import { getBusinessRecord } from "../../data/dataAppts";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { IBookingDataType } from "../../config/types/BookingDataType";

interface DispatchType {
  type: string;
}

const url = "/personal-booking";

export const clearPersonalBookingState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.bookingClearState.type });
  };

export const getPersonalUpcomingBooking =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { personalBooking: { upcoming: { lastFetch: number } } };
    }
  ) => {
    const { lastFetch } = getState().entities.personalBooking.upcoming;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/upcoming",
        data: config.localENV && [...getUpcomingBookings()],
        onStart: reducers.bookingRequest.type,
        onSuccess: reducers.bookingUpcomingReceived.type,
        onError: reducers.bookingRequestFailed.type,
      })
    );
  };

export const getPersonalPastBooking =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { personalBooking: { past: { lastFetch: number } } };
    }
  ) => {
    const { lastFetch } = getState().entities.personalBooking.past;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/past",
        data: config.localENV && getPastBookings(),
        onStart: reducers.bookingRequest.type,
        onSuccess: reducers.bookingPastReceived.type,
        onError: reducers.bookingRequestFailed.type,
      })
    );
  };

export const getBookingById =
  (id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        personalBooking: { singleData: IMeetupDataType & IBookingDataType };
      };
    }
  ) => {
    const { singleData } = getState().entities.personalBooking;
    if (singleData && singleData._id === id) return;
    return dispatch(
      apiCallBegan({
        url: url + "/single",
        method: "post",
        data: config.localENV ? getOneBooking(id) : { id },
        onStart: reducers.bookingLoading.type,
        onSuccess: reducers.bookingSingleReceived.type,
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const getParticipants =
  (id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        personalBooking: { meetupParticipants: { data: IMeetupDataType[] } };
      };
    }
  ) => {
    const { data } = getState().entities.personalBooking.meetupParticipants;
    if (data.length > 0 && id === data[0].host) return;
    return dispatch(
      apiCallBegan({
        url: url + "/participants",
        method: "post",
        data: { id },
        onStart: reducers.bookingParticipantsRequest.type,
        onSuccess: reducers.bookingParticipantsReceived.type,
        onError: reducers.bookingParticipantsError.type,
      })
    );
  };

export const getBookingRecord =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log(id);
    // TODO: api to handle
    return dispatch(
      apiCallBegan({
        url: url + "/record",
        method: "post",
        data: config.localENV ? getBusinessRecord("user1", id) : { id },
        onStart: reducers.bookingLoading.type,
        onSuccess: reducers.bookingRecordReceived.type,
        onError: reducers.bookingFailed.type,
      })
    );
  };
