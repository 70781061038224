import { useState } from "react";

const useUploadPicture = (type: "big" | "medium" | "small") => {
  const [image, setImage] = useState<string | ArrayBuffer | null>();
  const [error, setError] = useState<string | null>(null);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setError(null);
    if (
      file &&
      checkMimeType(file) &&
      checkFileSize(file)
      // checkFileDimension(file)
    ) {
      const min = type === "big" ? 1200 : type === "medium" ? 480 : 400;
      const max = type === "big" ? 2000 : type === "medium" ? 1440 : 1200;
      // const imgData = new FormData();
      // imgData.append("file", file);
      console.log("data", file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = () => {
      //   setImage(reader.result);
      // };
      reader.onload = function (e: any) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          if (image.width > max || image.height > max) {
            setError(`File dimension is too big. Max dimension is ${max}px.`);
            return false;
          } else if (image.width < min) {
            setError(`File dimension is too small. Min dimension is ${min}px.`);
            return false;
          }
          setImage(reader.result);
        };
      };
      // setImage(file);
    }
  };
  const checkMimeType = (file: { type: string }) => {
    // Allowed mime type
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (types.every((type) => file.type !== type)) {
      setError("File type not supported. Accept .jpg .gif and .png only.");
      return false;
    }
    return true;
  };
  const checkFileSize = (file: { size: number }) => {
    const maxSize = 1000000;
    const minSize = 2000;
    if (file.size > maxSize) {
      setError("File size is too big. Max file size 1MB.");
      return false;
    } else if (file.size < minSize) {
      setError("File size is too small. Min file size 2KB.");
      return false;
    }
    return true;
  };

  return { image, error, handleUpload };
};

export default useUploadPicture;
