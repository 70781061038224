import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppButton, AppInput, AppForm } from "../../components/form";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { authURL } from "../../config/url";
import { InitialValues, Validation } from "../../config/form/formForgotPwd";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { EBtnCategory } from "../../components/form/AppButton";
import {
  getRecaptchaToken,
  loadRecaptcha,
  removeRecaptcha,
} from "../../utils/googleRecaptcha";
import { EReCaptchaType } from "../../config/types/ReCaptchaType";

const AuthForgotPasswordPage: React.FC = () => {
  useDocumentTitle("Forgot Password");
  const { forgotPwd, clearAuthState } = useActions();
  const {
    loading,
    error: responseError,
    passwordToken,
  } = useTypedSelector((state) => state.entities.auth);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("passwordToken", passwordToken);
    if (passwordToken) {
      navigate(authURL.ResetPassword);
    }
  }, [passwordToken]);

  useEffect(() => {
    if (responseError) {
      setError(responseError);
      clearAuthState();
    }
  }, [responseError]);

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <LayoutCenterBox>
      <div className="text-center">
        <h1>Forgot your password?</h1>
        <p>
          If you have an account, we'll send you an email to reset your
          password.
        </p>
        {error && <p className="error-msg">{error}</p>}
      </div>
      <AppForm
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={async (values, actions) => {
          const recaptchaCode = await getRecaptchaToken(
            EReCaptchaType.ForgotPassword
          );
          if (!recaptchaCode) setError("Invalid recaptcha");
          else {
            forgotPwd({ ...values, recaptchaCode });
            actions.setSubmitting(false);
          }
        }}
      >
        <AppInput name="email" label="Email" type="email" labelBlock />
        <div className="display-hide">
          <AppInput name="recaptchaType" type="text" />
        </div>
        <div className="text-center mt-30">
          <AppButton
            type="submit"
            btnBlock
            category={EBtnCategory.Primary}
            size="large"
            loading={loading}
          >
            Submit
          </AppButton>
          <Link to={authURL.Login} className="btn mt-10 btn-link">
            Back to login
          </Link>
        </div>
      </AppForm>
    </LayoutCenterBox>
  );
};

export default AuthForgotPasswordPage;
