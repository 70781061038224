import { DropdownSelect } from "../ui/dropdown";
import useFilterServices from "./useFilterServices";

interface FilterServicesProps {
  label?: string;
  onClick: (id?: string, type?: string) => void;
}

const FilterServices: React.FC<FilterServicesProps> = ({ onClick, label }) => {
  const { list, selected } = useFilterServices(onClick, label);
  return (
    <DropdownSelect
      menuList={list}
      labelWidth={200}
      selected={selected ? selected : label ? label : "All"}
      fullWidth
      type="menu"
    />
  );
};

export default FilterServices;
