export enum ENotificationType {
  // Personal
  newMeetup = 101, // for friend
  newInvitationMeetup = 102, // for new user or new friend
  acceptMeetup = 103,
  confirmMeetup = 104,
  rejectMeetup = 105,
  notAttendMeetup = 106,
  cancelMeetup = 107,
  addCustomer = 111,

  // Business
  newBooking = 201,
  bookingCancelled = 221,
}

export enum EBusinessNotificationType {
  newBooking = 101,
  newWalkIn = 102,
}
