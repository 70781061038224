import * as reducers from "../reducer/userReducer";
import { getUser } from "../../data/dataUsers";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { IPersonalProfileInputType } from "../../config/form/formPersonalProfile";
import { IPersonalContactInputType } from "../../config/form/formPersonalContact";
import { IChangeEmailInputType } from "../../config/form/formChangeEmail";
import { IChangeEmailVerifyInputType } from "../../config/form/formChangeEmailVerify";
import { IChangePasswordInputType } from "../../config/form/formChangePassword";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/user";

export const clearUserState = () => (dispatch: (arg: DispatchType) => void) => {
  return dispatch({ type: reducers.userClearState.type });
};

export const getPersonalProfile =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { user: { lastFetch: number } } }
  ) => {
    console.log("getPersonalProfile");
    const { lastFetch } = getState().entities.user;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/me",
        method: "get",
        data: config.localENV && getUser("user1"),
        onStart: reducers.userRequest.type,
        onSuccess: reducers.userReceived.type,
        onError: reducers.userRequestFailed.type,
      })
    );
  };

export const savePersonalName =
  (data: IPersonalProfileInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    const profileData = { displayName: data.displayName };
    console.log("savePersonalName", profileData);
    return dispatch(
      apiCallBegan({
        url: url + "/name",
        method: "post",
        data: profileData,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userSaved.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const savePersonalContact =
  (data: IPersonalContactInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("savePersonalContact", data);
    return dispatch(
      apiCallBegan({
        url: url + "/contact",
        method: "post",
        data: data,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userSaved.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const searchUser =
  (data: { email: string }) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/search",
        method: "post",
        data: data,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userSearchResult.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const changeEmail =
  (data: IChangeEmailInputType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("changeEmail", data);
    return dispatch(
      apiCallBegan({
        url: url + "/change-email",
        method: "post",
        data,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userEmailVerifying.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const changeEmailVerify =
  (data: IChangeEmailVerifyInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("changeEmailVerify", data);
    return dispatch(
      apiCallBegan({
        url: url + "/change-email-verify",
        method: "post",
        data,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userEmailChanged.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const changePwd =
  (data: IChangePasswordInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("changePwd");
    return dispatch(
      apiCallBegan({
        url: url + "/change-password",
        method: "post",
        data: data,
        onStart: reducers.userLoading.type,
        onSuccess: reducers.userPwdChanged.type,
        onError: reducers.userFailed.type,
      })
    );
  };

export const uploadProfilePicture =
  (photo: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("photo", photo);
    return dispatch(
      apiCallBegan({
        url: url + "/upload-profile-photo",
        method: "post",
        data: { photo },
        onStart: reducers.userPhotoUploading.type,
        onSuccess: reducers.userPhotoUploaded.type,
        onError: reducers.userPhotoUploadFailed.type,
      })
    );
  };
