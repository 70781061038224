const location = [
  {
    _id: "location1",
    business: "business1",
    name: "Main Location",
    address: "Jln Hulubalang 31",
    address2: "Bandar Botanic",
    city: "Klang",
    country: "Malaysia",
    email: "raechan@simplicitic.com",
    hours:
      '[["Off","Off"],["10:30","19:30"],["09:30","19:30"],["00:30","19:30", "00:30","19:30"],["12:00","19:30"],["09:30","19:30"],["Off","Off"]]',
    hoursSum: "[0,540,480,480,480,480,240]",
    phone: "0987654321",
    postcode: "41200",
    state: "Selangor",
    website: "addpointment.com",
    isMain: true,
  },
  {
    _id: "location2",
    business: "business2",
    name: "Subang Jaya Branch",
    address: "Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park,",
    address2: "No. 1, Jalan Pengaturcara U1/51A, Seksyen U1,",
    city: "Shah Alam",
    country: "Malaysia",
    email: "ck@simplicitic.com",
    hours:
      '[["Off", "Off"],["08:30", "16:30"],["09:00", "17:00"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    hoursSum: "[0,540,480,480,480,480,240]",
    phone: "012345678",
    postcode: "40150",
    state: "Selangor",
    website: "",
    isMain: false,
  },
  // {
  //   _id: "location3",
  //   business: "business3",
  //   name: "Petaling Jaya Branch",
  //   address: "Petaling Here",
  //   address2: "",
  //   city: "Petaling Jaya",
  //   country: "Malaysia",
  //   email: "ck@simplicitic.com",
  //   hours:
  //     '[["Off", "Off"],["08:30", "16:30"],["09:00", "17:00"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
  //   hoursSum: "[0,540,480,480,480,480,240]",
  //   phone: "012345678",
  //   postcode: "40150",
  //   state: "Selangor",
  //   website: "www.wlclinic.com",
  //   isMain: false,
  // },
];

// export function getLocations() {
//   return locations;
//   return [];
// }

export function getLocation(businessId) {
  const locations = location.filter((l) => l.business === businessId);
  return locations;
}
