import classNames from "classnames";
import { HtmlHTMLAttributes, useState } from "react";
import styles from "./SendMail.module.scss";

interface MailProps {
  title: string;
  desc?: string;
}

const SendMail: React.FC<MailProps> = ({ title, desc }) => {
  const [email, setEmail] = useState();

  const handleSend = () => {
    console.log("Mail if Flying away!!", email);
  };

  return (
    <div className={styles["container"]}>
      <h2>{title}</h2>
      <p>{desc}</p>
      <div className={styles["send-container"]}>
        <input
          type="email"
          className={styles["input"]}
          placeholder="Email"
          onChange={(e: { target: { value: any } }) => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="button"
          className={classNames("btn btn-primary", styles["button"])}
          onClick={handleSend}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default SendMail;
