import styles from "./Table.module.scss";
import { TableColumnsType } from "./index";
import useTableColumn from "./useTableColumn";

interface TableHeaderProps {
  columns: TableColumnsType;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  const setWidth = useTableColumn();
  return (
    <header className={styles["header"]}>
      <ul>
        {columns.map((column, index) => (
          <li
            className={column.cellClass}
            key={index}
            style={setWidth(column.colWidth, column.colMaxWidth)}
          >
            {column.label}
          </li>
        ))}
      </ul>
    </header>
  );
};

export default TableHeader;
