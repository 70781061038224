import styles from "./Calendar.module.scss";
import { dayNameShort } from "../../../config/dateTimeFormat";

const CalendarHead: React.FC = () => {
  return (
    <thead>
      <tr>
        {dayNameShort.map((day) => {
          return (
            <th key={day}>
              <div className={styles["week-day"]}>{day}</div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default CalendarHead;
