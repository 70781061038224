import { getProvider } from "./dataProviders";
import { getResource } from "./dataResources";
import { getService } from "./dataServices";

const meetingPoints = [
  {
    _id: "meeting1",
    label: "1",
    type: 1,
    service: getService("service1"),
  },
  {
    _id: "meeting2",
    label: "2",
    type: 2,
    resource: getResource("resource1"),
  },
  {
    _id: "meeting3",
    label: "3",
    type: 3,
    provider: getProvider("provider1"),
  },
  {
    _id: "meeting4",
    label: "4",
    type: 4,
    provider: getProvider("provider2"),
  },
];

export function getMeetingPoints() {
  return meetingPoints;
}

export function getMeetingPoint(id) {
  const meetingPoint = meetingPoints.find((m) => m._id === id);
  return meetingPoint ? meetingPoint : meetingPoints[0];
}
