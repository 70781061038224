import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInvitationState {
  success: boolean;
  loading: boolean;
  error?: string;
}

const initialState: IInvitationState = {
  success: false,
  loading: true,
  error: undefined,
};

const slice = createSlice({
  name: "business-message",
  initialState,
  reducers: {
    messageClearState: (message) => {
      message.success = false;
      message.error = undefined;
      message.loading = false;
    },
    sendToCustomer: (message) => {
      console.log("sendToCustomer");
      message.loading = false;
      message.success = true;
    },
    messageSendFailed: (message, action: PayloadAction<string>) => {
      console.log("messageSendFailed");
      message.loading = false;
      message.error = action.payload;
    },
  },
});

export const { messageClearState, messageSendFailed, sendToCustomer } =
  slice.actions;

export default slice.reducer;
