import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationDataType } from "../../config/types/NotificationDataType";

interface IInvitationState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: number;
  success: boolean;
  data: INotificationDataType[];
  unopened: number;
}

const initialState: IInvitationState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  success: false,
  data: [],
  unopened: 0,
};

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationClearState: (notification) => {
      notification.success = false;
      notification.error = false;
    },
    notificationRequest: (notification) => {
      notification.loading = true;
    },
    notificationReceived: (
      notification,
      action: PayloadAction<INotificationDataType[]>
    ) => {
      console.log("notificationReceived", action.payload);
      const data = action.payload;
      notification.lastFetch = Date.now();
      notification.data = data;
      if (data.length > 0) {
        const numberUnopened = data.reduce(function (i, n) {
          return i + (n.opened === true ? 0 : 1);
        }, 0);
        notification.unopened = numberUnopened;
      }
      notification.success = true;
      notification.loading = false;
    },
    notificationRequestFailed: (
      notification,
      action: PayloadAction<string>
    ) => {
      notification.loading = false;
      notification.error = action.payload;
    },
    notificationOpened: (notification) => {
      console.log("notification opened");
      notification.unopened = 0;
    },
    notificationRead: (
      notification,
      action: PayloadAction<INotificationDataType>
    ) => {
      console.log("notification read");
      const index = notification.data.findIndex(
        (item) => item._id === action.payload._id
      );
      notification.data[index] = action.payload;
    },
    notificationReadAll: (notification) => {
      console.log("all notification read");
      notification.data.map((item) => {
        item.read = true;
      });
    },
    notificationNewCreated: (
      notification,
      action: PayloadAction<INotificationDataType>
    ) => {
      notification.data = [action.payload, ...notification.data];
      notification.unopened = notification.unopened + 1;
    },
  },
});

export const {
  notificationClearState,
  notificationRequest,
  notificationReceived,
  notificationRequestFailed,
  notificationOpened,
  notificationRead,
  notificationReadAll,
  notificationNewCreated,
} = slice.actions;

export default slice.reducer;
