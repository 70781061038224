import classNames from "classnames";
import getRange from "../../utils/range";
import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  number: number;
  unit?: string;
  hideLabel?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  number,
  unit,
  hideLabel,
}) => {
  const result = number > 100 ? 100 : number === 0 ? 1 : number;
  return (
    <div className={styles["container"]}>
      <div
        className={classNames(styles["bar"], styles[`bar-${getRange(result)}`])}
      >
        <div
          className={styles["progress"]}
          style={{ width: result + "%" }}
        ></div>
      </div>
      {!hideLabel && (
        <div className={styles["number"]}>
          {number}
          {unit}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
