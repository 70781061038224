import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config/global";

interface IRegisterState {
  loading: boolean;
  error?: string;
  registerStep: number;
  tokenId?: string;
  data?: {
    email: string;
    country: string;
  };
}

const initialState: IRegisterState = {
  loading: false,
  error: undefined,
  registerStep: 0,
  tokenId: undefined,
  data: undefined,
};

const slice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerClearState: (register) => {
      register.loading = false;
      register.error = undefined;
      register.tokenId = undefined;
      register.data = undefined;
    },
    registerSubmit: (register) => {
      register.error = undefined;
      register.loading = true;
    },
    registerFailed: (register, action: PayloadAction<string>) => {
      console.log("registerFailed", action.payload);
      register.loading = false;
      register.error = action.payload;
    },
    registerSuccessed: (
      register,
      action: PayloadAction<{
        data: { tokenId: string };
        actionData: { email: string; country: string };
      }>
    ) => {
      console.log("registerSuccessed", action.payload);
      register.loading = false;
      register.error = undefined;
      const { tokenId } = action.payload.data;
      if (!config.localENV) {
        register.tokenId = tokenId;
      } else register.tokenId = "61b1cf6d8d9e424fc1524d6b";
      register.registerStep = 1;
      register.data = action.payload.actionData;
    },
    registerProceed: (
      register,
      action: PayloadAction<{ registerStep: number }>
    ) => {
      console.log("registerProceed", action.payload);
      register.loading = false;
      register.error = undefined;
      if (action.payload.registerStep)
        register.registerStep = action.payload.registerStep;
      else register.error = "Failed to register. Please try again";
    },
    registerStepUpdate: (register, action: PayloadAction<number>) => {
      console.log("registerStepUpdate", action.payload);
      register.registerStep = action.payload;
    },
  },
});

export const {
  registerClearState,
  registerSubmit,
  registerFailed,
  registerSuccessed,
  registerProceed,
  registerStepUpdate,
} = slice.actions;

export default slice.reducer;
