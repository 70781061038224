// Validation schema for all form
import * as yup from "yup";
import { password, name, number } from "./patterns";

/*
 * Fields
 */
export const passwordField = yup
  .string()
  .min(8, "Password min. 8 characters")
  .max(30, "Password max. 30 characters")
  .matches(
    password,
    "Password must contain at least 1 uppercase and 1 lowercase letter, special character and number."
  );

export const phoneField = yup
  .string()
  .min(6, "Phone number is too short")
  .max(20, "Phone number is too long")
  .matches(number, "Please enter numbers only");

export const nameField = yup
  .string()
  .min(2, "Name is too short")
  .max(30, "Name is too long")
  .matches(name, "Allow letters only");

export const emailField = yup
  .string()
  .email("Please enter a valid email")
  .min(5, "Email is too short")
  .max(254, "Email is too long");

export const birthdateField = yup
  .date()
  .test("age", "You must be 12 or older", (birthdate) => {
    const cutoff = new Date();
    cutoff.setFullYear(cutoff.getFullYear() - 12);
    return birthdate <= cutoff;
  });

export const postcodeField = yup
  .string()
  .min(3, "Postcode is too short")
  .max(10, "Postcode is too long")
  .matches(number, "Please enter numbers only");
export const cityField = yup.string().max(100, "City name is too long");
export const stateField = yup.string().max(100, "State name is too long");
export const countryField = yup.string().max(100, "Country name is too long");
export const genderField = yup.string().max(1, "Invalide gender");
