import ListItem from "../ui/ListItem";
import { getDate, getDayName, getTime } from "../../utils/datetime";
import { IBookingDataType } from "../../config/types/BookingDataType";
import ReadMore from "../ui/ReadMore";
import prefixId, { idType } from "../../utils/prefixID";
import LocationDisplay from "../common/LocationDisplay";

interface PersonalBookingDetailsProps {
  data: IBookingDataType;
}

const PersonalBookingDetails: React.FC<PersonalBookingDetailsProps> = ({
  data,
}) => {
  return (
    <>
      <h4 className="mb-20">Booking Details</h4>
      <ListItem label="Booking ID">
        {prefixId(data.bookingUid, idType.Booking)}
      </ListItem>
      <ListItem label="Date">
        {getDate(data.meetDateTime)} ({getDayName(data.meetDateTime)})
      </ListItem>
      <ListItem label="Time">{getTime(data.meetDateTime)}</ListItem>
      <ListItem label="Service">{data.serviceData.name}</ListItem>
      <ListItem label="Business">{data.businessData.name}</ListItem>
      {data.onsiteService && (
        <ListItem label="Location">
          <LocationDisplay data={data.onsiteService} />
        </ListItem>
      )}
      <ListItem label="Customer ID">
        {prefixId(data.customerUid, idType.Customer)}
      </ListItem>
      {data.note && (
        <ListItem label="Note">
          <ReadMore charLimit={100}>{data.note}</ReadMore>
        </ListItem>
      )}
    </>
  );
};

export default PersonalBookingDetails;
