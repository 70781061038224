import { useState } from "react";
import styles from "./TableBookingToday.module.scss";
import Table from "../ui/table";
import { Col, Row } from "../ui/Grid";
import FilterBookings from "./FilterBookings";
import { ApptStatus } from "../appointment/ApptStatus";
import { getTime } from "../../utils/datetime";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import ApptTypeName from "../appointment/ApptTypeName";
import BookingActionMenu, { EBookingActionMenuType } from "./BookingActionMenu";
import { queueNumber } from "../../utils/booking";
import prefixId, { idType } from "../../utils/prefixID";

interface TableProps {
  data: IBookingDataType[];
  onApptView(id: string): void;
}

const TableBookingToday: React.FC<TableProps> = ({ data, onApptView }) => {
  const [selectedType, setSelectedType] = useState<string>();
  const columns = [
    {
      label: "Time",
      name: "time",
      colWidth: "120px",
      colMaxWidth: "120px",
      cellClass: styles["col-time"],
      content: (booking: {
        meetDateTime: Date;
        meetDate: string;
        bookingStatus: number;
        meetDateTimestamp: number;
      }) => (
        <div>
          <span className="mr-5">{getTime(booking.meetDateTime)}</span>
          <ApptStatus
            statusNo={booking.bookingStatus}
            dateTime={booking.meetDate}
            timestamp={booking.meetDateTimestamp}
            dot
          />
        </div>
      ),
    },
    {
      label: "Queue No.",
      name: "queue",
      colWidth: "100px",
      colMaxWidth: "120px",
      cellClass: styles["col-token"],
      content: (booking: {
        bookingNo: number;
        serviceData: { label: string };
      }) => <>{queueNumber(booking.bookingNo, booking.serviceData.label)}</>,
    },
    {
      label: "Customer",
      name: "customer",
      colWidth: "20%",
      // colMaxWidth: "15%",
      cellClass: styles["col-customer"],
      content: (booking: {
        customerData: { displayName?: string; email: string };
        customerUid: string;
      }) => (
        <>
          {booking.customerData.displayName && booking.customerData.displayName}
          {/* <div className="desc">
            {prefixId(booking.customerUid, idType.Customer)}
          </div> */}
        </>
      ),
    },
    {
      // label: "Service",
      name: "service",
      colWidth: "20%",
      // colMaxWidth: "30%",
      cellClass: styles["col-service"],
      content: (booking: IBookingDataType & IMeetupDataType) => (
        <ApptTypeName data={booking} />
      ),
    },
    {
      name: "status",
      colWidth: "150px",
      colMaxWidth: "200px",
      cellClass: styles["col-status"],
      content: (booking: {
        bookingStatus: number;
        meetDate: string;
        meetDateTimestamp: number;
      }) => (
        <ApptStatus
          statusNo={booking.bookingStatus}
          dateTime={booking.meetDate}
          timestamp={booking.meetDateTimestamp}
        />
      ),
    },
    {
      name: "action",
      colWidth: "80px",
      colMaxWidth: "80px",
      cellClass: styles["col-action"],
      disableItemClick: true,
      content: (booking: IBookingDataType) => (
        <BookingActionMenu
          data={booking}
          type={EBookingActionMenuType.TableAction}
          onView={onApptView}
        />
      ),
    },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "60px",
      cellClass: styles["col-actions"],
      disableItemClick: true,
      content: (booking: IBookingDataType) => (
        <BookingActionMenu
          data={booking}
          type={EBookingActionMenuType.Table}
          onView={onApptView}
        />
      ),
    },
  ];

  const getPageData = () => {
    let filtered: IBookingDataType[] = data;
    if (selectedType)
      filtered = data.filter((m) => m.bookingTypeId === selectedType);
    return { filtered };
  };

  const { filtered } = getPageData();

  return (
    <>
      <Row className="mb-10">
        <Col width="300px">
          <FilterBookings onClick={(id) => setSelectedType(id)} />
        </Col>
      </Row>
      <Table
        data={filtered}
        columns={columns}
        className={styles["table"]}
        itemClick={(booking: { _id: string }) => onApptView(booking._id)}
      />
    </>
  );
};

export default TableBookingToday;
