import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useToast from "../../components/ui/toasts/useToast";
import CreateServiceFooter from "./CreateServiceFooter";
import ServiceInfo from "../../components/services/ServiceInfo";
import ServiceBookingSlot from "../../components/services/ServiceBookingSlot";
import BookingPeriod from "../../components/common/BookingPeriod";
import ServicePeriod from "../../components/common/ServicePeriod";
import {
  IServiceInputType,
  InitialValues,
} from "../../config/form/formService";
import useBookingLabel from "../../hooks/useBookingLabel";
import { businessURL } from "../../config/url";
import useServiceCategory from "../../components/services/useServiceCategory";

export type routerStateType = {
  step: number;
  bookingType: number;
  serviceId?: string;
};

export enum ENewServiceSteps {
  ServiceInfo = 1,
  BookingSlot = 2,
  ServicePeriod = 3,
  BookingPeriod = 4,
}

const useCreateServicePage = () => {
  const navigate = useNavigate();
  const { notify } = useToast();
  const [initVal, setInitVal] = useState(InitialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const { clearAdminServiceState, getLocation, addAdminService } = useActions();
  const { success, error } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { data: location } = useTypedSelector(
    (state) => state.entities.location
  );
  const { presetLabel } = useBookingLabel();

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (presetLabel && business && location.length > 0)
      setInitVal({
        ...initVal,
        bookingType: 102, // TODO: booking type
        label: presetLabel,
        // businessUrl: business.businessUrl,
        servicePeriod: location[0].hours,
      });
  }, [presetLabel, business, location]);

  useEffect(() => {
    if (success) {
      notify("success", "Your service has been created successful");
      navigate(businessURL.BusinessServices);
      clearAdminServiceState();
    }
    if (error) {
      notify("error", "Failed to create the service. " + error);
      clearAdminServiceState();
    }
  }, [error, success]);

  const serviceSteps = [
    { name: "Service Info", content: <ServiceInfo /> },
    { name: "Booking Slot", content: <ServiceBookingSlot /> },
    { name: "Service Period", content: <ServicePeriod name="service" /> },
    { name: "Booking Period", content: <BookingPeriod /> },
  ];

  const steps = {
    title: "Create New Service",
    list: serviceSteps.map((s) => s.name),
  };

  const isCompleteSetup = currentStep < serviceSteps.length - 1 ? false : true;

  const { getCategoryName } = useServiceCategory();

  function handleContinue(value: IServiceInputType) {
    if (isCompleteSetup) {
      const categoryName = getCategoryName(value.category);
      value = { ...value, categoryName: `${categoryName}` };
      addAdminService(value);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const renderContent = serviceSteps[currentStep].content;

  const renderFooter = (
    <CreateServiceFooter step={currentStep} totalStep={serviceSteps.length} />
  );

  return {
    initVal,
    steps,
    handleContinue,
    currentStep,
    renderFooter,
    renderContent,
  };
};

export default useCreateServicePage;
