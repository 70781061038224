import * as yup from "yup";
import { objectId } from "./patterns";

export const InitialValues = {
  code: "",
  tokenId: "",
};

export const Validation = yup.object().shape({
  code: yup
    .string()
    .min(6, "Invalid code")
    .max(6, "Invalid code")
    .required("Please enter the verification code"),
  tokenId: yup
    .string()
    .required("Token ID is required. Please request a new one")
    .matches(objectId, "Invalid token. Please request a new one"),
});

export interface IRegisterEmailInputType {
  code: string;
  tokenId: string;
}
