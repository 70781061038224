import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmptyData from "../ui/EmptyData";
import TablePersonalBooking from "./TablePersonalBooking";
import { personalURL } from "../../config/url";
import iconCalendar from "../../assets/images/calendar.svg";
import ModalMakeBooking from "../personal/ModalMakeBooking";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../ui/Loader";

interface PersonalScheduleProps {
  type: "upcoming" | "past";
  onApptView(id: string): void;
}

const PersonalSchedule: React.FC<PersonalScheduleProps> = ({
  type,
  onApptView,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { request, upcoming, past } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  const { getPersonalUpcomingBooking, getPersonalPastBooking } = useActions();

  useEffect(() => {
    type === "past" ? getPersonalPastBooking() : getPersonalUpcomingBooking();
  }, [type]);

  if (request?.loading) return <Loader />;

  if (type === "past") {
    if (past.data.length === 0) {
      return (
        <EmptyData title="No Past Booking" image={iconCalendar}>
          <p>You do not have any past booking</p>
        </EmptyData>
      );
    } else {
      return (
        <TablePersonalBooking
          data={past.data}
          onApptView={onApptView}
          type={type}
        />
      );
    }
  } else {
    if (upcoming.data.length === 0) {
      return (
        <>
          <EmptyData title="No Upcoming Booking" image={iconCalendar}>
            <p>
              Schedule a personal{" "}
              <Link className="link" to={personalURL.CreateMeetup}>
                meet up
              </Link>{" "}
              with friends
              {/* or{" "}
              <button
                className="btn-link link"
                onClick={() => setModalVisible(true)}
              >
                book a service
              </button>{" "}
              with service providers */}
            </p>
          </EmptyData>
          {modalVisible && (
            <ModalMakeBooking onClose={() => setModalVisible(false)} />
          )}
        </>
      );
    } else {
      return (
        <TablePersonalBooking
          data={upcoming.data}
          onApptView={onApptView}
          type={type}
        />
      );
    }
  }
};

export default PersonalSchedule;
