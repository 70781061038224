import { useState } from "react";
import styles from "./ShowPIN.module.scss";

interface ShowPINProps {
  pin: string;
}

const ShowPIN: React.FC<ShowPINProps> = ({ pin }) => {
  const [show, setShow] = useState<boolean>(false);
  const output = show ? pin : pin.replace(/./g, "*");
  return (
    <div className={styles["container"]}>
      <span>{output}</span>
      <button onClick={() => setShow(show ? false : true)} className="btn-link">
        {show ? "Hide" : "Show"}
      </button>
    </div>
  );
};

export default ShowPIN;
