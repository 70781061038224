import { useState } from "react";
import { Col, Row } from "../ui/Grid";
import styles from "./ScheduleList.module.scss";
import FilterBookings from "./FilterBookings";
import TableScheduleList from "./TableScheduleList";
import iconCalendar from "../../assets/images/calendar-empty.svg";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IBookingDataType } from "../../config/types/BookingDataType";
import DatePicker from "../ui/DatePicker";
import { useActions } from "../../hooks/useActions";
import EmptyData from "../ui/EmptyData";
import { getDate } from "../../utils/datetime";
import Loader from "../ui/Loader";
import { FaTimes } from "react-icons/fa";
import LoadMoreButton from "../ui/LoadMoreButton";

interface ScheduleListProps {
  onApptView(id: string): void;
  onDateSelected(arg: boolean): void;
  data: IBookingDataType[];
}

const ScheduleList: React.FC<ScheduleListProps> = ({
  data,
  onApptView,
  onDateSelected,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>();
  const { getBusinessBookingByDate, getBusinessUpcomingBooking } = useActions();
  const { singleDay, upcoming, loadingMore } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const loadMore = selectedDate ? singleDay.loadMore : upcoming.loadMore;

  const getPageData = () => {
    let filtered: IBookingDataType[] = data;
    if (selectedType)
      filtered = data.filter((m) => m.bookingTypeId === selectedType);
    return { filtered };
  };

  const handleChangeDate = (date: Date | Date[]) => {
    if (date instanceof Date) {
      const dateInString = getDate(date, "YYYY MM DD", true);
      getBusinessBookingByDate(dateInString);
      onDateSelected(true);
      setSelectedDate(dateInString);
    }
  };

  const handleClear = () => {
    setSelectedDate("");
    onDateSelected(false);
  };

  const handleLoadMore = () => {
    selectedDate
      ? getBusinessBookingByDate(selectedDate, true)
      : getBusinessUpcomingBooking(true);
  };

  const { filtered } = getPageData();

  return (
    <>
      <Row className="mb-10">
        <Col width="300px">
          <div className={styles["date-picker"]}>
            <DatePicker
              value={selectedDate}
              onChange={(date) => handleChangeDate(date)}
              placeholder="Filter by date"
            />
            {selectedDate && (
              <button
                type="button"
                className={styles["clear-filter"]}
                onClick={() => handleClear()}
              >
                <FaTimes />
              </button>
            )}
          </div>
        </Col>
        <Col width="300px">
          <FilterBookings onClick={(id) => setSelectedType(id)} />
        </Col>
      </Row>
      {singleDay.request ? (
        <Loader />
      ) : !filtered || filtered.length === 0 ? (
        <EmptyData title="No booking" image={iconCalendar}></EmptyData>
      ) : (
        <TableScheduleList data={filtered} onApptView={onApptView} />
      )}

      {loadMore && (
        <LoadMoreButton onClick={handleLoadMore} loading={loadingMore} />
      )}
    </>
  );
};

export default ScheduleList;
