import { getLocation } from "./dataLocations";
import { getStaff } from "./dataStaff";

const providers = [
  {
    _id: "provider1",
    name: "Doctor Wong",
    title: "Junior Doctor",
    desc: "This is sample provider",
    profession: "Doctor",
    business: "business1",
    imageUrl: "https://picsum.photos/id/600/200/200",
    services: ["service1", "service2", "service3"],
    hours:
      '[["Off", "Off"],["11:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    hoursOption: 1,
  },
  {
    _id: "provider2",
    name: "Stylist John",
    title: "Senior stylist",
    business: "business1",
    desc: "Serving for new only",
    profession: "Stylist",
    imageUrl: "https://picsum.photos/id/602/200/200",
    services: ["service2"],
    hours:
      '[["Off", "Off"],["11:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    hoursOption: 2,
  },
  {
    _id: "provider3",
    name: "Mosh Hamenadi",
    title: "System Admin",
    profession: "Dentist",
    business: "business1",
    desc: "Serving for new only",
    photo: "https://picsum.photos/id/603/200/200",
    services: ["service3"],
    hours:
      '[["Off", "Off"],["12:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    hoursOption: 2,
  },
];

export function getProviders() {
  return providers;
}

export function getProvider(id) {
  return providers.find((m) => m._id === id);
}

export function getWalkInProviders() {
  const provider = providers.filter((m) => m.walkIn === true);
  return provider;
}
