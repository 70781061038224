import classNames from "classnames";
import styles from "./ListItem.module.scss";

interface ListItemProps {
  label?: string;
  labelWidth?: string;
  className?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  label,
  labelWidth,
  className,
  children,
}) => {
  if (!children) {
    return null;
  }
  return (
    <div className={classNames(styles["container"], className && className)}>
      {label && (
        <div
          className={styles["label"]}
          style={{ minWidth: labelWidth ? labelWidth : "auto" }}
        >
          {label}
        </div>
      )}
      <div className={styles["content"]}>{children}</div>
    </div>
  );
};

export default ListItem;
