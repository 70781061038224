import { BiPlusMedical } from "react-icons/bi";
import InputContainer from "./InputContainer";
import styles from "./AppPhoto.module.scss";
import { useEffect, useState } from "react";
import ModalUploadPicture from "../ui/avatar/ModalUploadPicture";
import Avatar from "../ui/avatar/AvatarPic";

interface InputProps {
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  name: string;
  type: "big" | "medium" | "small";
  imageSrc?: string;
  uploadLabel: string;
  uploaded?: boolean;
  onUploadPicture: (pic: string) => void;
  role?: "personal" | "business";
}

const AppPhoto: React.FC<InputProps> = ({
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  name,
  type,
  imageSrc,
  uploadLabel,
  uploaded,
  onUploadPicture,
  role = "business",
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  // console.log("service photo-----", imageSrc);

  useEffect(() => {
    uploaded && setModalVisible(false);
  }, [uploaded]);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      helperMsg={helperMsg}
    >
      <div
        className={styles["photo-input"]}
        onClick={() => setModalVisible(true)}
      >
        {imageSrc ? (
          <Avatar role={role} size={type} src={imageSrc} />
        ) : (
          <div className={styles["icon"]}>
            <BiPlusMedical />
          </div>
        )}
      </div>
      {modalVisible && (
        <ModalUploadPicture
          type={type}
          title={uploadLabel}
          onClose={() => setModalVisible(false)}
          onUploadPicture={onUploadPicture}
        />
      )}
    </InputContainer>
  );
};

export default AppPhoto;
