import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import {
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import { InitialValues, Validation } from "../../config/form/formServicePeriod";
import useBusinessServicePage from "./useBusinessServicePage";
import ServiceCustom from "../../components/services/ServiceCustom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";

const ServiceCustomPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { loading, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { service, submenu, header, footer } = useBusinessServicePage(id);
  const [initVal, setInitVal] = useState(InitialValues);

  useEffect(() => {
    if (service) {
      setInitVal({
        ...initVal,
        // TODO: feed correct value
        // servicePeriod: service.servicePeriod,
        // servicePeriodOption: service.servicePeriodOption,
      });
    }
  }, []);
  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      <AppForm
        initialValues={initVal}
        onSubmit={(values) => {
          console.log(values);
        }}
        validationSchema={Validation}
      >
        {header}
        <div className="wrapper">
          {!data || loading ? <Loader /> : <ServiceCustom />}
        </div>
        {/* <LayoutFullHeight>
          {header}
          <LayoutBody>
            {!data || loading ? <Loader /> : <ServiceCustom />}
          </LayoutBody>
          {footer}
        </LayoutFullHeight> */}
      </AppForm>
    </PageWithAside>
  );
};

export default ServiceCustomPage;
