import { useState } from "react";
import { AppButton } from "../../components/form";
import TableCustomer from "../../components/customer/TableCustomer";
import ModalCustomer from "../../components/customer/ModalCustomer";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import BusinessCustomerTags from "./BusinessCustomerTags";
import { Col, Row } from "../ui/Grid";
import { ICustomerDataType } from "../../config/types/CustomerDataType";
import ModalAddCustomer from "./ModalAddCustomer";
import EmptyData from "../ui/EmptyData";
import iconCustomer from "../../assets/images/customer.svg";
import { EBtnCategory } from "../form/AppButton";

const BusinessCustomer: React.FC = () => {
  const { data } = useTypedSelector((state) => state.entities.customer);
  const [selectedData, setSelectedData] = useState<ICustomerDataType | null>();
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<boolean>(false);

  const handleClick = (id: string, newNote?: boolean) => {
    const selectedData = data.find((val) => val._id === id);
    if (selectedData) {
      setSelectedData(selectedData);
      if (newNote) {
        setNewNote(newNote);
      }
    }
  };

  // const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log("Search >>>", e.target.value);
  // };

  // const handleTagFilter = (id: string) => {
  //   console.log(id);
  // };

  if (!data || data.length === 0)
    return (
      <>
        <EmptyData title="Add Customer" size="large" image={iconCustomer}>
          <p>
            Enable customer database under service to keep customer booking
            record or add new customer to your database
          </p>
          <button
            className="btn btn-primary-outline mt-10"
            type="button"
            onClick={() => setAddCustomer(true)}
          >
            Add New Customer
          </button>
        </EmptyData>
        {addCustomer && (
          <ModalAddCustomer onClose={() => setAddCustomer(false)} />
        )}
      </>
    );

  return (
    <>
      {/* <Row className="mb-20">
        <Col width="300px">
          <input
            placeholder="Search by Customer Id"
            onChange={handleOnSearch}
            className="input-control"
          />
        </Col>
        <Col>
          <BusinessCustomerTags onFilter={handleTagFilter} />
        </Col>
      </Row> */}
      <TableCustomer
        data={data}
        onView={handleClick}
        onAddNote={(id, arg) => handleClick(id, arg)}
      />
      <div className="mt-30">
        <AppButton
          type="button"
          category={EBtnCategory.PrimaryOutline}
          onClick={() => setAddCustomer(true)}
        >
          Add Customer
        </AppButton>
      </div>
      {selectedData && (
        <ModalCustomer
          data={selectedData}
          onClose={() => {
            setSelectedData(null);
            setNewNote(false);
          }}
          newNote={newNote}
        />
      )}
      {addCustomer && (
        <ModalAddCustomer onClose={() => setAddCustomer(false)} />
      )}
    </>
  );
};

export default BusinessCustomer;
