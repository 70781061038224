import React, { useState, useEffect } from "react";
import NotificationList from "./NotificationList";
import { useActions } from "../../../hooks/useActions";
import ModalPersonalBooking from "../../personalBooking/ModalPersonalBooking";
import { INotificationDataType } from "../../../config/types/NotificationDataType";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import config from "../../../config/global";
import NotificationToggle from "./NotificationToggle";
import NotificationItem from "./NotificationItem";
import usePersonalNotificationMessage from "./usePersonalNotificationMessage";

const PersonalNotification = () => {
  const [id, setId] = useState<string | null>(null);
  const {
    clearPersonalNotificationsState,
    getPersonalNotifications,
    openPersonalNotifications,
    readPersonalNotification,
    readAllPersonalNotification,
  } = useActions();
  const { unopened, data, loading } = useTypedSelector(
    (state) => state.entities.notification
  );

  useEffect(() => {
    getPersonalNotifications();
    clearPersonalNotificationsState();
  }, []);
  const { getNotificationMessage } = usePersonalNotificationMessage();

  const handleClick = (notification: INotificationDataType) => {
    console.log("personalNotification", notification);
    readPersonalNotification(notification._id);
    if (notification.businessUrl)
      window.open(`${config.bookingUrl}${notification.businessUrl}`);
    else setId(notification.entityId);
  };

  return (
    <>
      <NotificationToggle
        unopened={unopened}
        onOpen={() => unopened > 0 && openPersonalNotifications()}
      >
        <NotificationList
          onReadAll={readAllPersonalNotification}
          loading={loading}
        >
          {data.map((item, index) => {
            return (
              <NotificationItem
                key={index}
                onClick={() => handleClick(item)}
                opened={item.read}
                image={
                  item.userFromProfilePic
                    ? item.userFromProfilePic
                    : item.businessFromLogo
                }
                imageType={item.businessFromLogo ? "business" : "personal"}
                message={getNotificationMessage(item)}
                date={item.createdAt}
              />
            );
          })}
        </NotificationList>
      </NotificationToggle>
      {id && <ModalPersonalBooking id={id} onClose={() => setId(null)} />}
    </>
  );
};

export default PersonalNotification;
