import classnames from "classnames";
import styles from "./Switch.module.scss";

interface SwitchProps {
  checked: boolean;
  onChange(arg: boolean): void;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, children }) => {
  return (
    <div className={styles["container"]}>
      <button
        type="button"
        className={classnames(
          styles["switches"],
          checked && styles["switches-checked"]
        )}
        onClick={() => onChange(checked ? false : true)}
      >
        <span className={styles["switches-button"]}></span>
      </button>
      {children && <div className={styles["label"]}>{children}</div>}
    </div>
  );
};

export default Switch;
