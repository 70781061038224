import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface ServiceState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting service data
  success?: string;
  error?: string;
  lastFetch?: Date | number;
  data: IServiceDataType[];
  labels: { id: string; value: string }[];
  // entityId?: string; // for setup service
  newService?: IServiceDataType; // for setup service
  duplicateService?: IServiceDataType; // for duplicate service
  photoUpload?: { loading?: boolean; error?: string; success?: boolean };
}

const initialState: ServiceState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  success: undefined,
  lastFetch: undefined,
  labels: [],
  data: [],
  // entityId: undefined,
  newService: undefined,
  duplicateService: undefined,
  photoUpload: undefined,
};

const slice = createSlice({
  name: "serviceAdmin",
  initialState,
  reducers: {
    serviceClearState: (service) => {
      service.request = undefined;
      service.loading = false;
      service.error = undefined;
      service.success = undefined;
      // service.entityId = undefined;
      // service.newService = undefined;
      service.duplicateService = undefined;
      service.photoUpload = undefined;
    },
    serviceRequest: (service) => {
      service.request = { loading: true };
    },
    serviceReceived: (service, action: PayloadAction<IServiceDataType[]>) => {
      const services = action.payload;
      // console.log("serviceReceived", services);
      service.data = services;
      service.request = undefined;
      service.lastFetch = Date.now();
      if (services.length > 0) {
        const labels = services.map((i: IServiceDataType) => {
          return { value: i.label, id: i._id } || null;
        });
        service.labels = labels;
      }
    },
    serviceRequestFailed: (service, action: PayloadAction<string>) => {
      service.request = { error: action.payload };
    },
    serviceLoading: (service) => {
      service.loading = true;
      service.success = undefined;
      service.error = undefined;
    },
    serviceFailed: (service, action: PayloadAction<string>) => {
      service.error = action.payload;
    },
    serviceRemoving: (service, action: PayloadAction<string>) => {
      console.log("serviceRemoving", action.payload);
      service.loading = true;
      service.success = undefined;
      service.error = undefined;
      service.data = service.data?.filter(
        (item) => item._id !== action.payload
      );
    },
    serviceRemoved: (service) => {
      service.success = "Service has been removed";
    },
    serviceRemoveFailed: (
      service,
      action: PayloadAction<{ error: string; actionData: IServiceDataType[] }>
    ) => {
      service.loading = false;
      service.data = action.payload.actionData;
      service.error = action.payload.error;
    },
    serviceDeleted: (service, action: PayloadAction<string>) => {
      console.log("serviceDeleted", action.payload);
      service.data = service.data?.filter(
        (item) => item._id !== action.payload
      );
    },
    serviceAdded: (service, action: PayloadAction<IServiceDataType>) => {
      console.log("serviceAdded", action.payload);
      const newService = action.payload;
      service.data = [...service.data, newService];
      service.success = "Service has been added";
      service.labels = [
        ...service.labels,
        { value: newService.label, id: newService._id },
      ];
    },
    serviceDuplicated: (service, action: PayloadAction<IServiceDataType>) => {
      console.log("serviceAdded", action.payload);
      const newService = action.payload;
      service.data = [...service.data, newService];
      service.duplicateService = newService;
      service.labels = [
        ...service.labels,
        { value: newService.label, id: newService._id },
      ];
    },
    serviceUpdated: (service, action: PayloadAction<IServiceDataType>) => {
      console.log("serviceUpdated", action.payload);
      const updatedService = action.payload;
      service.data = service.data.map((i) => {
        if (i._id === updatedService._id) return updatedService;
        return i;
      });
      service.success = "Service has been updated";
    },
    serviceUpdating: (service, action) => {
      console.log("serviceUpdating", action.payload);
      service.loading = true;
      if (action.payload && action.payload.type) {
        const { type, id, value } = action.payload;
        const index = service.data.findIndex((item) => item._id === id);
        if (type === "walk-in") {
          service.data[index].walkIn = value;
        } else if (type === "online-booking") {
          service.data[index].onlineBooking = value;
        }
      }
    },
    serviceUpdateFailed: (
      service,
      action: PayloadAction<{ error: string; actionData: IServiceDataType[] }>
    ) => {
      service.loading = false;
      service.data = action.payload.actionData;
      service.error = action.payload.error;
    },
    serviceProviderRequest: (service) => {
      service.loading = true;
    },
    serviceProviderRequestFailed: (service, action: PayloadAction<string>) => {
      service.loading = false;
      service.error = action.payload;
    },
    serviceProviderAdded: (
      service,
      action: PayloadAction<IServiceDataType>
    ) => {
      console.log("service provider added", action.payload);
      const index = service.data.findIndex(
        (item) => item._id === action.payload._id
      );
      service.data[index] = action.payload;
      service.loading = false;
    },
    serviceProviderRemoved: (
      service,
      action: PayloadAction<IServiceDataType>
    ) => {
      console.log("service provider remove", action.payload);
      const index = service.data.findIndex(
        (item) => item._id === action.payload._id
      );
      service.data[index] = action.payload;
      service.loading = false;
    },
    servicePhotoUploading: (service) => {
      service.photoUpload = { loading: true };
    },
    servicePhotoUploaded: (
      service,
      action: PayloadAction<IServiceDataType>
    ) => {
      const index = service.data.findIndex(
        (item) => item._id === action.payload._id
      );
      console.log("servicePhotoUploaded");
      console.log("index", index);
      service.data[index] = action.payload;
      service.photoUpload = { success: true };
    },
    servicePhotoUploadFailed: (service, action: PayloadAction<string>) => {
      service.photoUpload = { error: action.payload };
    },
  },
});

export const {
  serviceClearState,
  serviceRequest,
  serviceReceived,
  serviceRequestFailed,
  serviceRemoving,
  serviceRemoved,
  serviceRemoveFailed,
  serviceDeleted,
  serviceLoading,
  serviceFailed,
  serviceAdded,
  serviceDuplicated,
  serviceUpdated,
  serviceUpdating,
  serviceUpdateFailed,
  serviceProviderRequest,
  serviceProviderRequestFailed,
  serviceProviderAdded,
  serviceProviderRemoved,
  servicePhotoUploading,
  servicePhotoUploaded,
  servicePhotoUploadFailed,
} = slice.actions;

export default slice.reducer;
