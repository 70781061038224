import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { IFriendDataType } from "../../config/types/FriendDataType";
import { IHostDataType } from "../../config/types/HostDataType";

interface IFriendState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting bookmark data
  success?: string;
  error?: string;
  lastFetch: Date | number | undefined;
  data: [] | IFriendDataType[];
  record: [] | IMeetupDataType[];
}

const initialState: IFriendState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  success: undefined,
  lastFetch: undefined,
  data: [],
  record: [],
};

const slice = createSlice({
  name: "friend",
  initialState,
  reducers: {
    friendClearState: (friend) => {
      friend.loading = false;
      friend.success = undefined;
      friend.error = undefined;
      friend.request = undefined;
      friend.record = [];
    },
    friendLoading: (friend) => {
      friend.loading = true;
      friend.success = undefined;
      friend.error = undefined;
    },
    friendFailed: (friend, action: PayloadAction<string>) => {
      friend.loading = false;
      friend.error = action.payload;
    },
    friendRequest: (friend) => {
      friend.request = { loading: true };
    },
    friendReceived: (friend, action: PayloadAction<IFriendDataType[]>) => {
      console.log("friendReceived", action.payload);
      friend.data = action.payload;
      friend.request = undefined;
      friend.lastFetch = Date.now();
    },
    friendRequestFailed: (friend, action: PayloadAction<string>) => {
      friend.request = { error: action.payload };
    },
    friendRemoving: (friend, action: PayloadAction<string>) => {
      console.log("friendRemoving", action.payload);
      friend.loading = true;
      friend.success = undefined;
      friend.error = undefined;
      friend.data = friend.data?.filter((item) => item._id !== action.payload);
    },
    friendRemoved: (friend) => {
      console.log("friendRemoved");
      friend.loading = false;
      friend.success = "The user has been removed from your friend list";
    },
    friendRemoveFailed: (
      friend,
      action: PayloadAction<{ error: string; actionData: IFriendDataType[] }>
    ) => {
      console.log("friendRemoveFailed", action.payload);
      friend.loading = false;
      friend.data = action.payload.actionData;
      friend.error = action.payload.error;
    },
    friendRecordReceived: (
      friend,
      action: PayloadAction<IMeetupDataType[]>
    ) => {
      friend.record = action.payload;
      friend.loading = false;
    },
  },
});

export const {
  friendClearState,
  friendLoading,
  friendFailed,
  friendRequest,
  friendReceived,
  friendRequestFailed,
  friendRemoving,
  friendRemoved,
  friendRemoveFailed,
  friendRecordReceived,
} = slice.actions;

export default slice.reducer;
