import styles from "./ModalAddFriend.module.scss";
import { AppForm, AppButton, AppInput } from "../form";
import { ModalBody, ModalBox } from "../ui/modal";
import { Validation, InitialValues } from "../../config/form/formAddFriend";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect } from "react";
import { EBtnCategory } from "../form/AppButton";
import { IParticipantsInputType } from "../../config/form/formNewMeetup";

interface ModalAddFriendProps {
  onClose(): void;
  onAddFriend(id: string): void;
  participants: IParticipantsInputType[];
}

const ModalAddFriend: React.FC<ModalAddFriendProps> = ({
  onClose,
  onAddFriend,
  participants,
}) => {
  const { searchUser } = useActions();
  const {
    searchResult,
    loading,
    error,
    data: user,
  } = useTypedSelector((state) => state.entities.user);
  const { data: friends } = useTypedSelector((state) => state.entities.friend);

  useEffect(() => {
    searchResult && onClose();
  }, [searchResult]);

  const handleSubmit = (values: { email: string }) => {
    console.log(values);
    const existingFriend = friends.find((f) => f.email === values.email);
    if (existingFriend) {
      onAddFriend(existingFriend.friend);
      onClose();
    } else if (values.email === user?.email) onClose();
    else {
      const existingParticipant =
        participants.length > 0 &&
        participants.find((p) => p.email === values.email);
      if (existingParticipant) onClose();
      else searchUser(values);
    }
  };
  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      size="small"
    >
      {/* <ModalHeader title="Add new friend" /> */}
      <AppForm
        className="flex-layout"
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          // searchUser(values);
          handleSubmit(values);
        }}
      >
        <ModalBody>
          <div className={styles["wrapper"]}>
            <h4 className="mb-10">Add new friend</h4>
            {error && <div className="error-msg">{error}</div>}
            <AppInput
              placeholder="Enter email address"
              autoFocus
              labelBlock
              name="email"
            />
            <AppButton
              type="submit"
              category={EBtnCategory.Primary}
              loading={loading}
            >
              Add
            </AppButton>
          </div>
        </ModalBody>
      </AppForm>
    </ModalBox>
  );
};

export default ModalAddFriend;
