import { useNavigate } from "react-router-dom";
import { personalURL } from "../../config/url";
import DialogBox from "../ui/DialogBox";

interface SetupCancelDialogProps {
  setVisible(arg: boolean): void;
  step: number;
}

const SetupCancelDialog: React.FC<SetupCancelDialogProps> = ({
  setVisible,
  step,
}) => {
  const navigate = useNavigate();

  const handleConfirm = {
    label: "Yes",
    onClick: () => {
      setVisible(false);
      navigate(personalURL.PersonalHome);
    },
  };

  const message =
    step === 1
      ? "Your business has not been created yet."
      : "Your business can accept booking only when you have completed the setup";

  return (
    <DialogBox
      title="Are you sure you want to cancel setup?"
      message={message}
      confirm={handleConfirm}
      cancel="No"
      onClose={() => setVisible(false)}
    />
  );
};

export default SetupCancelDialog;
