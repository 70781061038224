import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";

type IDataType = IMeetupDataType & IBookingDataType;

export enum EMeetupActionsType {
  Accept = "accept",
  Confirm = "confirm",
  Reject = "reject",
  Cancel = "cancel",
  NotAttend = "not-attend",
}

interface BookingState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting booking data
  error?: string;
  success?: string;
  data: [] | IDataType[];
  upcoming: {
    data: [] | IDataType[];
    lastFetch?: number;
  };
  past: {
    data: [] | IDataType[];
    lastFetch?: number;
  };
  record: IDataType[];
  singleData?: IDataType;
  meetupParticipants: {
    loading?: boolean;
    data?: IMeetupDataType[];
    error?: string;
  };
}

const initialState: BookingState = {
  loading: false,
  request: undefined,
  error: undefined,
  success: undefined,
  data: [],
  upcoming: { data: [] },
  past: { data: [] },
  record: [],
  singleData: undefined,
  meetupParticipants: { data: [] },
};

const slice = createSlice({
  name: "personalBooking",
  initialState,
  reducers: {
    bookingClearState: (booking) => {
      booking.request = undefined;
      booking.loading = false;
      booking.error = undefined;
      booking.success = undefined;
      booking.record = [];
    },
    bookingRequest: (booking) => {
      booking.request = { loading: true };
    },
    bookingRequestFailed: (booking, action: PayloadAction<string>) => {
      booking.error = action.payload;
    },
    bookingLoading: (booking) => {
      booking.loading = true;
      booking.success = undefined;
      booking.error = undefined;
    },
    bookingFailed: (booking, action: PayloadAction<string>) => {
      booking.loading = false;
      booking.error = action.payload;
    },
    bookingSingleReceived: (booking, action: PayloadAction<IDataType>) => {
      console.log("bookingSingleReceived", action.payload);
      booking.loading = false;
      booking.singleData = action.payload;
    },
    bookingParticipantsRequest: (booking) => {
      booking.meetupParticipants = { loading: true };
    },
    bookingParticipantsReceived: (
      booking,
      action: PayloadAction<IDataType[]>
    ) => {
      console.log("bookingPaticipantsReceived", action.payload);
      booking.meetupParticipants = { data: action.payload };
    },
    bookingParticipantsError: (booking, action: PayloadAction<string>) => {
      booking.meetupParticipants = { error: action.payload };
    },
    bookingUpcomingReceived: (booking, action: PayloadAction<IDataType[]>) => {
      console.log("bookingUpcomingReceived", action.payload);
      booking.upcoming = { data: action.payload, lastFetch: Date.now() };
      booking.request = undefined;
    },
    bookingPastReceived: (booking, action: PayloadAction<IDataType[]>) => {
      console.log("bookingPastReceived", action.payload);
      booking.past = { data: action.payload, lastFetch: Date.now() };
      booking.request = undefined;
    },
    bookingRecordReceived: (booking, action: PayloadAction<IDataType[]>) => {
      console.log("bookingRecordReceived", action.payload);
      booking.loading = false;
      booking.record = action.payload;
    },
    meetupCreated: (booking, action: PayloadAction<IDataType>) => {
      console.log("meetupCreated", action.payload);
      booking.loading = false;
      booking.success = "Meetup has been created";
      booking.upcoming = {
        data: [...booking.upcoming.data, { ...action.payload }],
        // lastFetch: booking.upcoming.lastFetch,
      };
    },
    meetupStatusUpdate: (
      booking,
      action: PayloadAction<{
        data: IDataType;
        actionData: EMeetupActionsType;
      }>
    ) => {
      console.log("meetupStatusUpdate", action.payload);
      booking.loading = false;
      booking.upcoming.data = booking.upcoming.data.map((i) => {
        if (i._id === action.payload.data._id) return action.payload.data;
        return i;
      });
      booking.singleData = action.payload.data;
      booking.meetupParticipants = {
        data:
          booking.meetupParticipants &&
          booking.meetupParticipants.data &&
          booking.meetupParticipants.data.map((i) => {
            if (i._id === action.payload.data._id) return action.payload.data;
            return i;
          }),
      };
      if (action.payload.actionData === EMeetupActionsType.Accept) {
        booking.success = "You have accepted the meetup";
      } else if (action.payload.actionData === EMeetupActionsType.Confirm) {
        booking.success = "You have confirmed the meetup";
      } else if (action.payload.actionData === EMeetupActionsType.Reject) {
        booking.success = "You have rejected the meetup";
      } else if (action.payload.actionData === EMeetupActionsType.NotAttend) {
        booking.success = "You will not attend the meetup";
      } else if (action.payload.actionData === EMeetupActionsType.Cancel) {
        booking.success = "You have cancelled the meetup";
      }
    },
    bookingStatusUpdate: (booking, action: PayloadAction<IDataType>) => {
      console.log("bookingStatusUpdate", action.payload);
      booking.upcoming.data = booking.upcoming.data.map((i) => {
        if (i._id === action.payload._id) return action.payload;
        return i;
      });
      if (booking.singleData?._id === action.payload._id) {
        booking.singleData = action.payload;
      }
    },
    bookingNewCreated: (booking, action: PayloadAction<IDataType>) => {
      booking.upcoming.data = [action.payload, ...booking.upcoming.data];
    },
    meetupNewCreated: (booking, action: PayloadAction<IDataType>) => {
      booking.upcoming.data = [action.payload, ...booking.upcoming.data];
    },
  },
});

export const {
  bookingClearState,
  bookingRequest,
  bookingRequestFailed,
  bookingLoading,
  bookingFailed,
  bookingUpcomingReceived,
  bookingRecordReceived,
  bookingPastReceived,
  meetupCreated,
  bookingSingleReceived,
  bookingParticipantsRequest,
  bookingParticipantsReceived,
  bookingParticipantsError,
  meetupStatusUpdate,
  bookingStatusUpdate,
  bookingNewCreated,
  meetupNewCreated,
} = slice.actions;

export default slice.reducer;
