import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ModalBody, ModalBox, ModalHeader } from "../ui/modal";
import { personalURL } from "../../config/url";

import styles from "./ModalMakeBooking.module.scss";
import { AvatarPic } from "../ui/avatar";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useBusiness from "../../hooks/useBusiness";
import { IBookmarkDataType } from "../../config/types/BookmarkDataType";

interface ModalProps {
  onClose(): void;
}

const ModalMakeBooking: React.FC<ModalProps> = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const { getBookmarks } = useActions();
  const navigate = useNavigate();
  const { data } = useTypedSelector((state) => state.entities.bookmark);
  const { getIndustryName } = useBusiness();

  useEffect(() => {
    getBookmarks();
  }, []);

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("This is Modal Value>>>", e.target.value);
    setSearchQuery(e.target.value.toLocaleLowerCase());
  };

  const getPageData = () => {
    let filtered: IBookmarkDataType[] = data;
    if (searchQuery && data && data.length > 0)
      filtered = data.filter((b) =>
        b.businessName.toLocaleLowerCase().includes(searchQuery)
      );
    return { filtered };
  };

  const handleOnClick = () => {
    navigate(personalURL.NewBooking);
  };

  const { filtered } = getPageData();

  return (
    <ModalBox onClose={onClose} type="personal" overlayClose>
      <ModalHeader title="New Business Booking" />
      <ModalBody>
        <div className={styles["container"]}>
          <input
            placeholder="Search Business"
            onChange={handleOnSearch}
            className="input-control"
          />
          {!filtered || filtered.length === 0 ? (
            <div className="py-20">
              <i>No result found</i>
            </div>
          ) : (
            <ul className={styles["list"]}>
              {filtered.map((item, index) => {
                return (
                  <li key={index} onClick={handleOnClick} role="button">
                    <div className={styles["avatar"]}>
                      <AvatarPic role="business" />
                    </div>
                    <div className={styles["business"]}>
                      <div className="h5">{item.businessName}</div>
                      <span className="desc">
                        {getIndustryName(item.industry)}
                      </span>
                    </div>
                    {/* <div className={styles["city"]}>{item.city}</div> */}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ModalBody>
    </ModalBox>
  );
};

export default ModalMakeBooking;
