import styles from "./DeleteAccount.module.scss";
import { Col, Row } from "../../components/ui/Grid";
import { useState } from "react";
import DeleteAccountDialog from "./DeleteAccountDialog";

const DeleteAccount: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <Row className={styles["delete-account"]}>
        <Col>
          <h4>Delete Account</h4>
          <div className="mt-5 desc">
            Your profile will be permanently deleted
          </div>
        </Col>
        <Col width="150px">
          <button
            className="btn btn-primary-outline"
            onClick={() => setShowDialog(true)}
          >
            Delete Account
          </button>
        </Col>
      </Row>
      {showDialog && <DeleteAccountDialog setVisible={setShowDialog} />}
    </>
  );
};

export default DeleteAccount;
