import { useState } from "react";
import { DropdownAction } from "../ui/dropdown";
import DialogBox from "../ui/DialogBox";
import { RiPencilFill, RiDeleteBin6Line } from "react-icons/ri";
import { useActions } from "../../hooks/useActions";

interface TableMeetingPointActionsProps {
  id: string;
}

const TableMeetingPointActions: React.FC<TableMeetingPointActionsProps> = ({
  id,
}) => {
  const [visible, setVisible] = useState<boolean>();
  const { removeMeetingPoint } = useActions();

  const menu = [
    {
      name: "Edit",
      icon: <RiPencilFill />,
    },
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      onClick: () => setVisible(true),
    },
  ];
  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      removeMeetingPoint(id);
      setVisible(false);
    },
  };
  return (
    <>
      <DropdownAction menu={menu} />
      {visible && (
        <DialogBox
          title="Are you sure you want to remove?"
          // message="Are you sure you want to remove this Meeting Point?"
          confirm={handleConfirm}
          cancel="Cancel"
          onClose={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default TableMeetingPointActions;
