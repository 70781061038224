import { useState } from "react";
import CalendarMini from "../ui/CalendarMini";
import { Col, Row } from "../ui/Grid";
// import Guideline from "../ui/Guideline";
import HolidayDetails from "./HolidayDetails";

const BusinessHoliday: React.FC = () => {
  const [date, setDate] = useState<Date | Date[]>([
    new Date(2021, 7, 1),
    new Date(2021, 7, 5),
    new Date(2021, 7, 10),
  ]);

  return (
    <Row>
      <Col width="60%">
        <CalendarMini
          disableYearNavigation={true}
          showNeighboringMonth={false}
          onChange={(val) => {
            console.log(val);
            // setDate(val);
          }}
        />
      </Col>
      <Col width="30%">
        {date && <HolidayDetails date={date} />}
        {/* <Guideline title="Holiday">
          <p>(No yet design)</p>
        </Guideline> */}
      </Col>
    </Row>
  );
};

export default BusinessHoliday;
