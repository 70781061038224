import { Col, Row } from "../ui/Grid";
import { useNavigate } from "react-router-dom";

import styles from "./DashboardGuide.module.scss";
import { businessProfileURL, businessURL } from "../../config/url";
import { BsDisplay } from "react-icons/bs";
import { MdOutlineCountertops } from "react-icons/md";
import { FaWalking, FaBriefcase, FaRegClock } from "react-icons/fa";
import { ImUserPlus } from "react-icons/im";
import { IoBriefcaseOutline, IoPeopleOutline } from "react-icons/io5";
// import { IoPeopleOutline } from "react-icons/io";

interface IDashboardGuide {
  isNew?: boolean;
}

const DashboardGuide: React.FC<IDashboardGuide> = ({ isNew }) => {
  const navigate = useNavigate();
  const serveList = [
    {
      icon: <MdOutlineCountertops />,
      title: "Set Meeting Point",
      desc: "You can notify your customer the place to meet by sending them the meeting point",
      btn: "Set Meeting Point",
      link: () => navigate(businessURL.BusinessMeetingPoint),
    },
    {
      icon: <BsDisplay />,
      title: "Show Now Serving",
      desc: "You can display the token number that you are currently serving to notify the customer",
      btn: "Show Now Serving",
      link: () => navigate(businessURL.BusinessNowServing),
    },
    {
      icon: <FaWalking />,
      title: "Manage Walk-in",
      desc: "You can register a walk-in customer or let them scan the QR code to register",
      btn: "Manage Walk-in",
      link: () => navigate(businessURL.BusinessWalkIn),
    },
  ];

  const startList = [
    {
      icon: <IoBriefcaseOutline />,
      title: "Create a service",
      desc: "To start accepting booking, create a service for your business",
      btn: "Create service",
      link: () => navigate(businessURL.BusinessServiceNew),
    },
    {
      icon: <IoPeopleOutline />,
      title: "Invite customer",
      desc: "Send an invitation to your existing customer with your booking page url",
      btn: "Add customer",
      link: () => navigate(businessURL.BusinessClientele),
    },
    {
      icon: <FaRegClock />,
      title: "Custom Business Hours",
      desc: "Keep your customers up to date by setting your day off and custom business hours",
      btn: "Set business hours",
      link: () => navigate(businessProfileURL.Hours),
    },
  ];

  return (
    <>
      <h3>{isNew ? "Start to accept booking" : "Ready to serve Customer?"}</h3>
      <ul className={styles["list"]}>
        {startList.map((item, index) => {
          return (
            <li key={index}>
              <Row>
                <Col width="50px" className={styles["icon"]}>
                  {item.icon}
                </Col>
                <Col stretch>
                  <div className="h5">{item.title}</div>
                  <div className="desc">{item.desc}</div>
                </Col>
                <Col width="180px">
                  <button
                    type="button"
                    className="btn btn-primary-outline"
                    onClick={item.link}
                  >
                    {item.btn}
                  </button>
                </Col>
              </Row>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default DashboardGuide;
