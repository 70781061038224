import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import styles from "./ParticipantList.module.scss";
import { AvatarPic } from "../ui/avatar";
import { ListTableDataType } from "../ui/ListTable";
import {
	IParticipantsInputType,
	EParticipantListType,
} from "../../config/form/formNewMeetup";
import { IFriendDataType } from "../../config/types/FriendDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useLocation } from "react-router-dom";

interface FormikContextProps {
	[name: string]: IParticipantsInputType[];
}

type TListNewFriends = {
	email: string;
	userId: string;
};

type TListFriends = {
	email: string;
	userId: string;
	displayName: string;
	profilePic: string;
};

const useParticipantList = () => {
	const { data: friendsData } = useTypedSelector(
		(state) => state.entities.friend
	);
	const { searchResult, data: userData } = useTypedSelector(
		(state) => state.entities.user
	);
	const { state } = useLocation();
	const { clearUserState } = useActions();
	// const { setFieldValue, values } = useFormikContext<FormikContextProps>();

	const [friendList, setFriendList] = useState<IFriendDataType[]>(friendsData);
	// const [listNewUser, setListNewUser] = useState<string[]>([]);
	// const [listNewFriends, setListNewFriends] = useState<TListNewFriends[]>([]);
	// const [listFriends, setListFriends] = useState<TListFriends[]>([]);
	const [participants, setParticipants] = useState<IParticipantsInputType[]>(
		[]
	);

	useEffect(() => {
		if (state && state instanceof Array) {
			console.log("state", state);
			// When create new appointment with preset participants
			state.forEach((friendId) => {
				handleAdd(friendId);
			});
		}
	}, [state]);

	useEffect(() => {
		if (searchResult) {
			// When add new friend
			// Check whether it has been added to the participant list
			handleSearchResult(searchResult);
		}
	}, [searchResult]);

	function handleSearchResult(searchResult: { email: string; _id?: string }) {
		console.log("handleSearchResult", searchResult);
		const exist =
			participants.length > 0
				? participants.find((p) => p.email === searchResult.email)
				: null;
		if (!exist) {
			// User not in the participant list
			if (searchResult._id) {
				// Registered user
				const newFriendData = {
					email: searchResult.email,
					userId: searchResult._id,
				};
				setParticipants([
					...participants,
					{ ...newFriendData, type: EParticipantListType.NewFriend },
				]);
				// setListNewFriends([...listNewFriends, newFriendData]);
			} else {
				// Unregistered user
				setParticipants([
					...participants,
					{ email: searchResult.email, type: EParticipantListType.NewUser },
				]);
				// setListNewUser([...listNewUser, searchResult.email]);
			}
		}
		clearUserState();
	}

	const handleDelete = (email: string) => {
		console.log("handleDelete", email);
		if (participants.length > 0) {
			const filteredList = participants.filter((item) => item.email !== email);
			const removeItem = participants.find((item) => item.email === email);
			setParticipants(filteredList);
			console.log("removeItem", removeItem);
			if (removeItem && removeItem.friendId) {
				const friend = friendsData.find(
					(item) => item._id === removeItem.friendId
				);
				console.log("friend", friend);
				friend && setFriendList([...friendList, friend]);
				// const filteredListFriends = listFriends.filter(
				//   (p) => p.email === email
				// );
				// filteredListFriends && setListFriends(filteredListFriends);
			}
		}
	};

	const handleAdd = (userId: string) => {
		console.log("handleAdd", userId);
		if (friendList.length > 0) {
			console.log("friendList", friendList);
			const friend = friendList.find((item) => item.friend === userId);
			const filteredFriendList = friendList.filter(
				(item) => item.friend !== userId
			);
			if (friend) {
				const friendData = {
					userId,
					email: friend.email,
					displayName: friend.displayName,
					profilePic: friend.profilePic,
				};
				console.log("friendData", friendData);
				setParticipants([
					...participants,
					{
						...friendData,
						friendId: friend._id,
						type: EParticipantListType.Friend,
					},
				]);
				// setListFriends([...listFriends, friendData]);
				console.log("filteredFriendList", filteredFriendList);
				setFriendList(filteredFriendList);
			}
		}
	};

	const participantList: ListTableDataType[] = [];
	if (userData) {
		// Add User as host
		participantList.push({
			id: userData?._id,
			notDelete: true,
			content: (
				<div className={styles["participant-list"]}>
					<div className={styles["participant-pic"]}>
						<AvatarPic role='personal' src={userData.profilePic} />
					</div>
					<div className='h5'>You</div>
				</div>
			),
		});
	}
	if (participants.length > 0) {
		// console.log("participants", participants);
		participants.forEach((participant) => {
			// if (!participant.isHost)
			participantList.push({
				id: participant.email,
				content: (
					<div className={styles["participant-list"]}>
						<div className={styles["participant-pic"]}>
							<AvatarPic role='personal' src={participant.profilePic} />
						</div>
						<div className='h5'>
							{participant.displayName
								? participant.displayName
								: participant.email}
						</div>
					</div>
				),
			});
		});
	}

	const panelList = friendList?.map((item) => ({
		id: item.friend,
		item: (
			<div className={styles["participant-list"]}>
				<AvatarPic role='personal' src={item.profilePic} size='small' />
				<div className='ml-10'>{item.displayName}</div>
			</div>
		),
		name: item.displayName,
		// desc: item.email,
		onClick: () => handleAdd(item.friend),
	}));

	return {
		participantList,
		panelList,
		// listNewUser,
		// listNewFriends,
		// listFriends,
		participants,
		handleAdd,
		handleDelete,
	};
};

export default useParticipantList;
