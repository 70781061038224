import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";

interface LocationRemoveDialogProps {
  id: string;
  setVisible(arg: null): void;
}

const LocationRemoveDialog: React.FC<LocationRemoveDialogProps> = ({
  setVisible,
  id,
}) => {
  const { removeLocation } = useActions();

  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      removeLocation(id);
      setVisible(null);
    },
  };

  return (
    <DialogBox
      title="Are you sure you want to remove?"
      message="Customer will not be able to select this location during the booking"
      confirm={handleConfirm}
      cancel="Cancel"
      onClose={() => setVisible(null)}
    />
  );
};

export default LocationRemoveDialog;
