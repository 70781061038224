import * as reducers from "../reducer/businessReducer";
import { apiCallBegan } from "../middleware/api";
import { getBusinessData } from "../../data/dataBusinesses";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/business";

export const getBusiness =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/id",
        method: "post",
        data: config.localENV ? getBusinessData(id) : { id },
        onStart: reducers.businessRequest.type,
        onSuccess: reducers.businessReceived.type,
        onError: reducers.businessRequestFailed.type,
      })
    );
  };
