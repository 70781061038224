import React from "react";
import BusinessNotification from "./BusinessNotification";
import PersonalNotification from "./PersonalNotification";

interface INotification {
  role: "personal" | "business";
}

const Notification: React.FC<INotification> = ({ role }) => {
  return role === "personal" ? (
    <PersonalNotification />
  ) : (
    <BusinessNotification />
  );
};

export default Notification;
