import * as reducers from "../reducer/locationReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getLocation as dataLocation } from "../../data/dataLocations";
import { IBusinessContactInputType } from "../../config/form/formBusinessContact";
import {
  InitialValues as HourValues,
  IBusinessHoursInputType,
} from "../../config/form/formBusinessHours";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { countDailyCapacity } from "../../utils/capacity";
import { ICustomDayInputType } from "../../config/types/CustomDayDataType";

interface DispatchType {
  type: string;
}

const url = "/location-admin";

export const clearLocationState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({
      type: reducers.locationClearState.type,
    });
  };

export const getLocation =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { location: { lastFetch: number } } }
  ) => {
    console.log("getLocation");
    const { lastFetch } = getState().entities.location;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        data: dataLocation("business1"), // TODO: temporary
        method: "get",
        onStart: reducers.locationRequest.type,
        onSuccess: reducers.locationReceived.type,
        onError: reducers.locationRequestFailed.type,
      })
    );
  };

export const updateBusinessContact =
  (data: IBusinessContactInputType, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("data", data);
    console.log("id", id);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data: { data, id },
        method: "post",
        onStart: reducers.locationLoading.type,
        onSuccess: reducers.locationUpdated.type,
        onError: reducers.locationFailed.type,
      })
    );
  };

export const updateBusinessHours =
  (data: IBusinessHoursInputType, id: string | undefined) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        location: { data: ILocationDataType[] };
        serviceAdmin: { data: IServiceDataType[] };
      };
    }
  ) => {
    // const { data: locationData } = getState().entities.location;
    const { data: serviceData } = getState().entities.serviceAdmin;
    console.log("data", id, data);
    let serviceToUpdate: {
      _id: string;
      dailyCapacity: string;
      servicePeriod: string;
      servicePeriodSum: string;
    }[] = [];
    if (serviceData.length > 0) {
      const result = serviceData.filter(
        (service) => service.servicePeriodOption === 1
      );
      console.log("result", result);
      if (result.length > 0) {
        result.forEach((item) => {
          const dailyCapacity = countDailyCapacity(
            item.bookingCapacity,
            item.slotInterval,
            data.hoursSum
          );
          serviceToUpdate.push({
            _id: item._id,
            dailyCapacity,
            servicePeriod: data.hours,
            servicePeriodSum: data.hoursSum,
          });
        });
      }
    }
    console.log("serviceToUpdate", serviceToUpdate);
    return dispatch(
      apiCallBegan({
        url: url + "/hours",
        data: { data, id, serviceToUpdate },
        method: "post",
        onStart: reducers.locationLoading.type,
        // onSuccess: reducers.locationUpdated.type,
        onSuccess: reducers.locationUpdated.type,
        onError: reducers.locationFailed.type,
      })
    );
  };

export const getBusinessHoursCustomDay =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/get-custom-day",
        method: "get",
        onStart: reducers.locationLoading.type,
        onSuccess: reducers.locationCustomDayReceived.type,
        onError: reducers.locationFailed.type,
      })
    );
  };

export const updateBusinessHoursCustomDay =
  (
    listEdit: ICustomDayInputType[],
    listNew: ICustomDayInputType[],
    removeIds: string[]
  ) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("listEdit", listEdit);
    console.log("listNew", listNew);
    console.log("removeIds", removeIds);
    return dispatch(
      apiCallBegan({
        url: url + "/update-custom-day",
        data: { listEdit, listNew, removeIds },
        method: "post",
        onStart: reducers.locationLoading.type,
        onSuccess: reducers.locationCustomDayUpdated.type,
        onError: reducers.locationFailed.type,
      })
    );
  };

export const addLocation =
  (data: IBusinessContactInputType) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        businessAdmin: { data: IBusinessDataType };
      };
    }
  ) => {
    console.log("addBusinessLocation", data);
    const { data: business } = getState().entities.businessAdmin;
    console.log("business", business);
    const businessData = {
      ...data,
      ...HourValues,
      businessUrl: business.businessUrl,
      businessName: business.name,
      industry: business.industry,
      profilePic: business.profilePic,
      isMain: false,
    };
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: businessData,
        method: "post",
        onStart: reducers.locationLoading.type,
        onSuccess: reducers.locationAdded.type,
        onError: reducers.locationFailed.type,
      })
    );
  };

export const removeLocation =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeBusinessLocation", id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: { id },
        method: "post",
        onStart: reducers.locationLoading.type,
        // onSuccess: reducers.locationRemoved.type,
        onSuccessDispatch: { type: reducers.locationRemoved.type, payload: id },
        onError: reducers.locationFailed.type,
      })
    );
  };
