import "normalize.css";
import "./App.scss";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
	authRoutes,
	personalRoutes,
	personalProfileRoutes,
	businessRoutes,
	businessSetupRoutes,
	businessProfileRoutes,
	businessServiceRoutes,
	businessProviderRoutes,
} from "./routes";
import ToastContainer from "./components/ui/toasts/ToastContainer";
import { useActions } from "./hooks/useActions";
import { useTypedSelector } from "./hooks/useTypedSelector";
import LayoutCenterBox from "./components/layout/LayoutCenterBox";
import Loader from "./components/ui/Loader";
import { loadGoogleAnalytics } from "./utils/googleAnalytics";
import { personalURL } from "./config/url";

function App() {
	const { authState } = useActions();
	const { stateLoading, stateError } = useTypedSelector(
		(state) => state.entities.auth
	);

	useEffect(() => {
		loadGoogleAnalytics();
		authState();
	}, []);

	if (stateLoading) return <Loader height='80vh' />;

	if (stateError)
		return (
			<LayoutCenterBox>
				<h3 className='text-center'>Error connecting to server</h3>
				<p className='text-center'>
					Make sure you are connected to the internet
				</p>
			</LayoutCenterBox>
		);

	return (
		<div className='App'>
			<ToastContainer />
			<Routes>
				{authRoutes()}
				{personalRoutes()}
				{personalProfileRoutes()}
				{businessRoutes()}
				{businessSetupRoutes()}
				{businessProfileRoutes()}
				{businessServiceRoutes()}
				{businessProviderRoutes()}
				<Route path='/' element={<Navigate to={personalURL.PersonalHome} />} />
			</Routes>
		</div>
	);
}

export default App;
