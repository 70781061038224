import { useEffect } from "react";
import { FaRegCalendar } from "react-icons/fa";
import TableRecord from "../common/TableRecord";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import EmptyData from "../ui/EmptyData";
import Loader from "../ui/Loader";

interface CustomerRecordProps {
  customerId: string;
}

const CustomerRecord: React.FC<CustomerRecordProps> = ({ customerId }) => {
  const { getCustomerRecord } = useActions();
  const { loading, record } = useTypedSelector(
    (state) => state.entities.customer
  );

  useEffect(() => {
    getCustomerRecord(customerId);
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const pageData = () => {
    if (loading) return <Loader />;
    else {
      if (record.length > 0) return <TableRecord data={record} />;
      return (
        <EmptyData icon={<FaRegCalendar />} title="No Record" size="small">
          Create a new booking with the customer
        </EmptyData>
      );
    }
  };

  return (
    <>
      <div className="h4 mb-10">Booking Record</div>
      {pageData()}
    </>
  );
};

export default CustomerRecord;
