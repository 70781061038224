import * as yup from "yup";
import { getDate } from "../../utils/datetime";
import { textArea } from "./patterns";

export const InitialValues = {
  subject: "",
  date: getDate(new Date()),
  time: "",
  location: "",
  note: "",
  participants: [],
  participantsData: [],
  listNewUser: [],
  listNewFriends: [],
  listFriends: [],
};

export const Validation = yup.object().shape({
  subject: yup.string().required("Plese enter name for the meetup"),
  date: yup
    .date()
    .test("date", "Date must be future date", (date) => {
      const cutoff = new Date();
      cutoff.setHours(0, 0, 0, 0);
      // cutoff.setFullYear(cutoff.getFullYear() - 12);
      return (date as Date) >= cutoff;
    })
    .required("Please select a date for the meetup"),
  time: yup.string().required("Please select a time for the meetup"),
  location: yup.string().required("Please enter a location for the meetup"),
  note: yup
    .string()
    .max(1000, "Maximum 1000 characters")
    .matches(textArea, "Special character is not allowed"),
});

export enum EParticipantListType {
  Friend = "friend",
  NewFriend = "new-friend",
  NewUser = "new-user",
}

export interface IParticipantsInputType {
  type: EParticipantListType;
  friendId?: string;
  userId?: string;
  email: string;
  displayName?: string;
  profilePic?: string;
  // bookingStatus: number;
  // isHost?: boolean;
}

export interface ICreateMeetupInputType {
  subject: string;
  date: string;
  time: string;
  location: string;
  note: string;
  participants: string[];
  // participantsData: IParticipantsData[];
  // listNewUser: [] | string[];
  // listNewFriends: [] | { email: string; id: string }[];
  // listFriends: [] | { email: string; id: string }[];
  listNewUser: IParticipantsInputType[];
  listNewFriends: IParticipantsInputType[];
  listFriends: IParticipantsInputType[];
}
