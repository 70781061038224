import React from "react";
import classnames from "classnames";
import { Portal } from "react-portal";
import { PositionType } from "./useDropdown";
import styles from "./Dropdown.module.scss";

interface DropdownProps {
  position: PositionType | undefined;
  display: boolean;
  minWidth?: number;
  maxWidth?: string | number;
  ref?: React.Ref<HTMLDivElement>;
  maxHeight?: number | string;
}

const DropdownWrapper: React.FC<DropdownProps> = React.forwardRef(
  (props, ref) => {
    const { position, display, minWidth, children, maxWidth, maxHeight } =
      props;
    // if (!display) return null;
    return (
      <>
        <div className={styles["dropdown-wrapper"]}>
          <div
            ref={ref}
            className={styles["menu"]}
            style={{
              ...position,
              // position: "fixed",
              zIndex: display === true ? 1200 : -100,
              visibility: display === true ? "visible" : "hidden",
              minWidth: minWidth ? minWidth : "0",
              maxWidth: maxWidth ? maxWidth : "none",
              // height: dropdownHeight ? dropdownHeight : "auto",
              maxHeight: maxHeight,
              overflow: "auto",
              // minWidth: minWidth && minWidth,
            }}
          >
            <div className={classnames(!display && styles["hidden"])}>
              {children}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default DropdownWrapper;
