import * as reducers from "../reducer/friendReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getBookmarkFriends } from "../../data/dataBookmarkFriends";
import { getFriendsRecord } from "../../data/dataAppts";
import { IFriendDataType } from "../../config/types/FriendDataType";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/friend";

export const clearFriendState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.friendClearState.type });
  };

export const getFriends =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { friend: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.friend;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/all",
        method: "get",
        data: config.localENV && getBookmarkFriends(),
        onStart: reducers.friendRequest.type,
        onSuccess: reducers.friendReceived.type,
        onError: reducers.friendRequestFailed.type,
      })
    );
  };

export const removeFriend =
  (id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { friend: { data: IFriendDataType[] } } }
  ) => {
    const { data } = getState().entities.friend;
    console.log("removeFriend", id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        method: "post",
        data: { id },
        onStartDispatch: { type: reducers.friendRemoving.type, payload: id },
        onSuccess: reducers.friendRemoved.type,
        onErrorDispatch: {
          type: reducers.friendRemoveFailed.type,
          payload: data,
        },
      })
    );
  };

export const getFriendRecord =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/record",
        method: "post",
        data: config.localENV ? getFriendsRecord(["user1"]) : { id },
        onStart: reducers.friendLoading.type,
        onSuccess: reducers.friendRecordReceived.type,
        onError: reducers.friendFailed.type,
      })
    );
  };
