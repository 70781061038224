import { useEffect, useState } from "react";
import PersonalBookingDetails from "./PersonalBookingDetails";
import PersonalBookingBusiness from "./PersonalBookingBusiness";
import { IBookingDataType } from "../../config/types/BookingDataType";
import TodayBookingNotification from "./TodayBookingNotification";
import BusinessRecords from "../common/BusinessRecords";
import { ApptStatus } from "../appointment/ApptStatus";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { isToday } from "../../utils/datetime";
import PersonalBookingProvider from "./PersonalBookingProvider";

const usePersonalBooking = (source: IBookingDataType) => {
  // const { data: userData } = useTypedSelector((state) => state.entities.user);
  const { singleData } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  const { clearPersonalBookingState } = useActions();
  const [data, setData] = useState(source);

  useEffect(() => {
    if (singleData) {
      setData(singleData);
      clearPersonalBookingState();
    }
  }, [singleData]);

  const label = (
    <ApptStatus
      statusNo={data.bookingStatus}
      dateTime={data.meetDate}
      timestamp={data.meetDateTimestamp}
    />
  );

  const title = data.businessData.name;

  const items = [
    {
      tabID: 1,
      name: "Booking",
      content: <PersonalBookingDetails data={data} />,
    },

    {
      tabID: 2,
      name: "Business",
      content: <PersonalBookingBusiness data={data} />,
    },
    // {
    //   tabID: 3,
    //   name: "Record",
    //   content: <BusinessRecords businessId={data.business as string} />,
    // },
  ];

  const itemToday = {
    tabID: 0,
    name: "Today",
    content: <TodayBookingNotification data={data} />,
  };

  if (isToday(data.meetDateTime)) {
    items.unshift(itemToday);
  }

  const itemProvider = {
    tabID: 3,
    name: "Provider",
    content: <PersonalBookingProvider data={data} />,
  };

  if (data.providerData) {
    items.push(itemProvider);
  }
  return { title, items, label };
};

export default usePersonalBooking;
