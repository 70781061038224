import React from "react";
import config from "../../config/global";

import Table, { TableDataType } from "../ui/table";

import styles from "./TableBookmark.module.scss";
import TableBookmarkActions from "./TableBookmarkActions";
import classNames from "classnames";
import { AvatarPic } from "../ui/avatar";
import useBusiness from "../../hooks/useBusiness";

interface TableBookmarkProps {
  data: TableDataType;
  onView(id: string): void;
}

const TableBookmark: React.FC<TableBookmarkProps> = ({ data, onView }) => {
  const { getIndustryName } = useBusiness();
  const columns = [
    {
      label: "Business",
      name: "business",
      colWidth: "40%",
      cellClass: styles["col-name"],
      content: (bookmark: { businessName: string; profilePic: string }) => (
        <div className={styles["avatar-name"]}>
          <div className={styles["avatar"]}>
            <AvatarPic role="business" src={bookmark.profilePic} />
          </div>
          <div className={classNames(styles["name"], "h5 text-wrap")}>
            {bookmark.businessName}
          </div>
        </div>
      ),
    },
    // {
    //   label: "City",
    //   name: "city",
    //   colWidth: "12%",
    //   cellClass: styles["col-city"],
    //   content: (bookmark: { locations: { city: string }[] }) =>
    //     bookmark.locations[0].city,
    // },
    {
      label: "Industry",
      name: "industry",
      colWidth: "15%",
      cellClass: styles["col-industry"],
      content: (bookmark: { industry: number }) =>
        getIndustryName(bookmark.industry),
    },
    {
      label: "",
      name: "make-appointment",
      colWidth: "140px",
      cellClass: styles["col-make-appt"],
      disableItemClick: true,
      content: (bookmark: { businessUrl: string }) => {
        return (
          <a
            className="link"
            href={`${config.bookingUrl}${bookmark.businessUrl}`}
            target="_blank"
          >
            Make Booking
          </a>
        );
      },
    },
    {
      name: "actions",
      colWidth: "60px",
      cellClass: styles["col-action"],
      disableItemClick: true,
      content: (bookmark: { _id: string; businessUrl: string }) => {
        return (
          <TableBookmarkActions
            type="bookmark"
            id={bookmark._id}
            businessUrl={bookmark.businessUrl}
          />
        );
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      className={styles["table"]}
      itemClick={(bookmark: { _id: string }) => onView(bookmark._id)}
    />
  );
};

export default TableBookmark;
