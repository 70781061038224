import * as reducers from "../reducer/capacityReducer";
import { apiCallBegan } from "../middleware/api";
import { getCapacity as testCapacity } from "../../data/dataCapacity";

interface DispatchType {
  type: string;
}

const url = "/capacity";

export const getCapacity =
  (businessId: string, entityId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("getCapacity", entityId);
    console.log("businessId", businessId);
    return dispatch(
      apiCallBegan({
        url,
        // data: {business: businessId, entityId},
        data: testCapacity(), // TODO: temporary
        onStart: reducers.capacityRequest.type,
        onSuccess: reducers.capacityReceived.type,
        onError: reducers.capacityRequestFailed.type,
      })
    );
  };

export const getCapacityByDate =
  (businessId: string, entityId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("getCapacity", entityId);
    console.log("businessId", businessId);
    return dispatch(
      apiCallBegan({
        url,
        // data: {business: businessId, entityId},
        data: testCapacity(), // TODO: temporary
        onStart: reducers.capacityRequest.type,
        onSuccess: reducers.capacityReceived.type,
        onError: reducers.capacityRequestFailed.type,
      })
    );
  };
