import { useNavigate } from "react-router";
import Table, { TableDataType } from "../ui/table";
import styles from "./TableServices.module.scss";
import ServiceActionMenu from "./ServiceActionMenu";
import TableServicesMethod from "./TableServicesMethod";
import { businessServiceURL, businessURL } from "../../config/url";
import useBusinessService from "./useBusinessService";

interface TableServicesProps {
  // data: IServiceDataType[];
  data: TableDataType;
}

const TableServices: React.FC<TableServicesProps> = ({ data }) => {
  const navigate = useNavigate();
  const { getServiceCategory } = useBusinessService();

  const columns = [
    {
      label: "Label",
      name: "label",
      colWidth: "60px",
      colMaxWidth: "90px",
      cellClass: styles["col-label"],
      content: (service: { label: string }) => <>{service.label}</>,
    },
    {
      label: "Name",
      name: "name",
      colWidth: "20%",
      cellClass: styles["col-name"],
      content: (service: { name: string }) => (
        <div className="h5">{service.name}</div>
      ),
    },
    {
      label: "Category",
      name: "category",
      colWidth: "20%",
      cellClass: styles["col-type"],
      content: (service: { category: string }) => (
        <>{getServiceCategory(service.category)}</>
      ),
    },
    {
      label: "Booking Period",
      name: "method",
      colWidth: "30%",
      cellClass: styles["col-method"],
      alignTop: true,
      content: (service: { onlineBooking: boolean; walkIn: boolean }) => {
        return (
          <TableServicesMethod
            advanceBooking={service.onlineBooking}
            walkIn={service.walkIn}
          />
        );
      },
    },
    // {
    //   label: "Service Hour",
    //   name: "servicehour",
    //   colWidth: "15%",
    //   cellClass: styles["col-hour"],
    //   content: (service: { servicePeriod: { option: number } }) => {
    //     return servicePeriodOption.find(
    //       (i) => i.value === service.servicePeriod.option
    //     )?.name;
    //   },
    // },
    {
      name: "actions",
      colMaxWidth: "60px",
      cellClass: styles["col-actions"],
      disableItemClick: true,
      content: (service: { _id: string; name: string }) => (
        <ServiceActionMenu id={service._id} name={service.name} type="table" />
      ),
    },
  ];

  return (
    <Table
      noDataMsg="No Service"
      data={data}
      columns={columns}
      className={styles["table"]}
      itemClick={(service: { _id: string }) =>
        navigate(
          `${businessURL.BusinessServices}/${service._id}/${businessServiceURL.Overview}`
        )
      }
    />
  );
};

export default TableServices;
