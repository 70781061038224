import { IProviderDataType } from "../../config/types/ProviderDataType";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import ListTable from "../ui/ListTable";
import useProviderServices from "./useProviderSercies";

interface ProviderServicesProps {
  provider: IProviderDataType;
}

const ProviderServices: React.FC<ProviderServicesProps> = ({ provider }) => {
  const { servicesList, handleDelete } = useProviderServices(provider);

  return (
    <>
      <h3 className="mb-3">Services</h3>
      <div className="desc mb-20">
        Allow customer to select a service during the booking
      </div>
      <Row>
        <Col width="50%">
          {servicesList.length > 0 ? (
            <ListTable data={servicesList} onDelete={handleDelete} />
          ) : (
            <div>No data</div>
          )}
        </Col>
        <Col width="30%">
          <Guideline title="Services">
            <p>
              Services will help customer understand the services provided by
              provider.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ProviderServices;
