import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import { LayoutFullHeight } from "../../components/layout/LayoutFullHeight";
import { Col, Row } from "../../components/ui/Grid";
import Guideline from "../../components/ui/Guideline";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessStaffList from "../../components/businessProfile/BusinessStaffList";
import BusinessAddStaff from "../../components/businessProfile/BusinessAddStaff";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { useActions } from "../../hooks/useActions";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 5;
const BusinessStaffPage: React.FC = () => {
  useDocumentTitle("Profile - AddPointment");
  const { getStaff, getStaffInvitation } = useActions();
  const { loading } = useTypedSelector((state) => state.entities.staff);
  const { header } = useBusinessProfile(index, true);
  useEffect(() => {
    getStaff();
    getStaffInvitation();
  }, []);
  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <LayoutFullHeight>
        <div className="wrapper">
          {header()}
          {loading ? (
            <Loader />
          ) : (
            <Row>
              <Col width="60%">
                <BusinessStaffList />
                <BusinessAddStaff />
              </Col>
              <Col width="30%">
                <Guideline title="Staff">
                  <p>
                    Add user to your business and let them manage their own
                    schedule. Only give access to people you trust, since staff
                    can see the schedule and other business information.
                  </p>
                  <p>
                    <b>Admin:</b> To manage all the booking schedule, services
                    and reports.
                  </p>
                  <p>
                    <b>Staff:</b> To view, manage and accept their own bookings
                    only.
                  </p>
                </Guideline>
              </Col>
            </Row>
          )}
        </div>
      </LayoutFullHeight>
    </PageWithAside>
  );
};

export default BusinessStaffPage;
