import { IBookingDataType } from "../../config/types/BookingDataType";
import config from "../../config/global";
import { EBtnCategory } from "../form/AppButton";
// import { useTypedSelector } from "../../hooks/useTypedSelector";
import MoreButton from "../ui/MoreButton";
import { useState } from "react";

import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { useActions } from "../../hooks/useActions";
import PersonalContactDialog from "./DialogMessageBusiness";

const usePersonalBookingActions = (data: IBookingDataType) => {
	// const { data: userData } = useTypedSelector((state) => state.entities.user);
	// console.log("name =====", data.businessData.name);

	const [contactDialog, setContactDialog] = useState(false);

	const menuList = [
		{
			name: "New Booking",
			onClick: () =>
				window.open(
					`${config.bookingUrl}${data.businessData.businessUrl}`,
					"_blank"
				),
			desc: `Make a new booking with ${data.businessData.name}`,
		},
		{
			name: "Contact Business",
			onClick: () => setContactDialog(!contactDialog),
			desc: `Send a message to ${data.businessData.name}`,
		},
	];

	const modalButton = (onClose: () => void) => {
		// if (participant?.isHost) {
		return (
			<>
				{contactDialog && (
					<PersonalContactDialog
						name={data.businessData.name}
						status={EBookingStatusValue.cancel}
						setVisible={(arg) => setContactDialog(arg)}
						businessId={data.business as string}
						bookingId={data._id}
					/>
				)}

				<MoreButton
					menuList={menuList}
					label='Close'
					category={EBtnCategory.PrimaryOutline}
					onClick={() => onClose()}
					minWidth={250}
				/>
			</>
		);
		// }
	};
	return { menuList, modalButton, contactDialog, setContactDialog };
};

export default usePersonalBookingActions;
