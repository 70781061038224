import * as reducers from "../reducer/staffReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getStaffs as dataStaff } from "../../data/dataStaff";
import { IUserDataType } from "../../config/types/UserDataType";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import { getInvitations as dataInvitation } from "../../data/dataInvitation";

interface DispatchType {
  type: string;
}

const url = "/staff";
// const data = getBookmarkFriends();

export const clearStaffState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({
      type: reducers.staffClearState.type,
    });
  };

export const getStaff =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        staff: { lastFetch: number };
        user: { data: IUserDataType };
      };
    }
  ) => {
    const { lastFetch } = getState().entities.staff;
    const { data: personal } = getState().entities.user;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag || !personal) return;
    return dispatch(
      apiCallBegan({
        url,
        data: dataStaff(), // TODO: remove
        method: "get",
        onStart: reducers.staffRequest.type,
        // onSuccess: reducers.staffReceived.type,
        onSuccessDispatch: {
          type: reducers.staffReceived.type,
          payload: { userId: personal._id },
        },
        onError: reducers.staffRequestFailed.type,
      })
    );
  };

export const addStaff =
  (email: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        business: { data: IBusinessDataType };
        user: { data: IUserDataType };
      };
    }
  ) => {
    const { data: business } = getState().entities.business;
    const { data: personal } = getState().entities.user;
    const data = {
      email,
      businessName: business.name,
      userName: personal.displayName,
    };
    console.log(data);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: email, // TODO: pass data
        method: "post",
        onStart: reducers.staffAdding.type,
        onSuccess: reducers.staffAdded.type,
        onError: reducers.staffAddFailed.type,
      })
    );
  };

export const updateStaffRole =
  (role: number, id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("updateStaffRole", role, id);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data: { role, id },
        method: "post",
        onStart: reducers.staffUpdating.type,
        onSuccess: reducers.staffUpdated.type,
        onError: reducers.staffUpdateFailed.type,
      })
    );
  };

export const removeStaff =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeStaff", id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: id,
        method: "post",
        onStart: reducers.staffUpdating.type,
        onSuccess: reducers.staffRemoved.type,
        onError: reducers.staffRemoveFailed.type,
      })
    );
  };

export const getStaffInvitation =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/invitation",
        data: dataInvitation(), // TODO: remove
        method: "get",
        // onStart: reducers.staffRequest.type,
        onSuccess: reducers.staffInvitationReceived.type,
        // onError: reducers.staffRequestFailed.type,
      })
    );
  };

// TODO: for business admin
// Get staff who pending approval
