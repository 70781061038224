import { useEffect } from "react";
import styles from "./CustomerAddNote.module.scss";
import { AppForm, AppButton, AppInput, AppTextArea } from "../form";
import { ModalBody, ModalFooter } from "../ui/modal";
import {
  InitialValues,
  Validation,
} from "../../config/form/formAddCustomerNote";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";
import { EBtnCategory } from "../form/AppButton";

interface CustomerAddNoteProps {
  id: string;
  onCancel(): void;
}

const CustomerAddNote: React.FC<CustomerAddNoteProps> = ({ id, onCancel }) => {
  const { addCustomerNote, clearCustomerState } = useActions();
  const { noteUpdate } = useTypedSelector((state) => state.entities.customer);
  const { notify } = useToast();

  useEffect(() => {
    if (noteUpdate) {
      if (noteUpdate.success) {
        notify("success", "Note has been added");
        clearCustomerState();
        onCancel();
      } else if (noteUpdate.error) {
        notify("error", "Error adding note. Please try again");
        clearCustomerState();
      }
    }
  }, [noteUpdate]);

  return (
    <>
      <AppForm
        className="flex-layout"
        initialValues={{ ...InitialValues, customerId: id }}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          console.log("values", values);
          actions.setSubmitting(false);
          addCustomerNote(values);
        }}
      >
        <ModalBody>
          <div className={styles["wrapper"]}>
            <AppInput name="subject" placeholder="Subject" />
            <AppTextArea name="message" placeholder="Note" maxCharacter={300} />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-link mr-10"
            onClick={onCancel}
          >
            Cancel
          </button>
          <AppButton type="submit" category={EBtnCategory.Primary}>
            Submit
          </AppButton>
        </ModalFooter>
      </AppForm>
    </>
  );
};

export default CustomerAddNote;
