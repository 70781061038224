import { useEffect } from "react";
import { IProviderDataType } from "../config/types/ProviderDataType";
import { ResourceType } from "../config/types/ResourceType";
import { useActions } from "./useActions";
import { useTypedSelector } from "./useTypedSelector";

type GetType = "none" | "services" | "resources" | "providers";

const useGetBookingType = (type?: GetType) => {
  const {
    getAdminServices,
    getAdminResources,
    getAdminProviders,
    clearAdminServiceState,
  } = useActions();
  const {
    request: requestService,
    data: services,
    labels: serviceLabels,
    error: serviceError,
  } = useTypedSelector((state) => state.entities.serviceAdmin);
  const { stateLoading } = useTypedSelector((state) => state.entities.auth);
  // const {
  //   loading: loadingResource,
  //   data: resources,
  //   labels: resourceLabels,
  // } = useTypedSelector((state) => state.entities.resourceAdmin);
  // const {
  //   loading: loadingProvider,
  //   data: providers,
  //   labels: providerLabels,
  // } = useTypedSelector((state) => state.entities.providerAdmin);

  useEffect(() => {
    if (type) {
      // type === "services" && getAdminServices();
      // type === "resources" && getAdminResources();
      // type === "providers" && getAdminProviders();
    } else {
      // getAdminServices();
      // getAdminResources();
      // getAdminProviders();
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAdminServices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const loading = requestService?.loading || loadingResource || loadingProvider;
  const loading = requestService?.loading;
  const error = serviceError;

  // TODO: temporary
  const resources: ResourceType[] = [];
  const providers: IProviderDataType[] = [];
  const resourceLabels: [] = [];
  const providerLabels: [] = [];
  const loadingResource = undefined;
  const loadingProvider = undefined;

  function clearBookingTypeState() {
    clearAdminServiceState();
  }

  return {
    services,
    resources,
    providers,
    serviceLabels,
    resourceLabels,
    providerLabels,
    loading,
    requestService,
    loadingResource,
    loadingProvider,
    error,
    clearBookingTypeState,
  };
};

export default useGetBookingType;
