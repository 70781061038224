import React from "react";

type locationType = {
  address: string;
  address2?: string;
  city?: string;
  postcode: string;
  country: string;
  state?: string;
};

interface ILocationDisplayProps {
  data: locationType;
}

const LocationDisplay: React.FC<ILocationDisplayProps> = ({ data }) => {
  return (
    <>
      {data.address}
      <br />
      {data.address2 && (
        <>
          {data.address2} <br />
        </>
      )}
      {data.postcode}
      {data.city && (
        <>
          {", "}
          {data.city} <br />
        </>
      )}
      {data.state && (
        <>
          {data.state} <br />
        </>
      )}
    </>
  );
};

export default LocationDisplay;
