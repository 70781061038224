import { Col, Row } from "../ui/Grid";
import {
  AppInput,
  AppTextArea,
  AppSelectPanel,
  AppSelectMenu,
  AppPhoto,
} from "../form";
import { SelectOptionsType } from "../form/AppSelectPanel";
import Guideline from "../ui/Guideline";
import useBookingLabel from "../../hooks/useBookingLabel";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useEffect, useState } from "react";
import useToast from "../ui/toasts/useToast";
import ModalServicesCategory from "./ModalServicesCategory";
import useServiceCategory from "./useServiceCategory";

const labelWidth = "120px";

interface ServiceInfoProps {
  service?: IServiceDataType;
}

const ServiceInfo: React.FC<ServiceInfoProps> = ({ service }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { labelOptions } = useBookingLabel();

  const { photoUpload } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { newCategory } = useTypedSelector(
    (state) => state.entities.serviceCategory
  );
  const { uploadAdminServicePhoto, clearAdminServiceState } = useActions();
  const { notify } = useToast();

  useEffect(() => {
    if (photoUpload?.success) {
      notify("success", "Photo has been updated");
      clearAdminServiceState();
    }
    if (photoUpload?.error) {
      notify("error", photoUpload.error);
      clearAdminServiceState();
    }
  }, [photoUpload]);

  useEffect(() => {
    newCategory && setModalVisible(false);
  }, [newCategory]);

  const { categoryList } = useServiceCategory();

  return (
    <>
      <h3 className="mb-3">Service Info</h3>
      <div className="desc mb-20">Basic information about your service</div>
      <Row>
        <Col width="50%">
          {service && (
            <AppPhoto
              name="imageUrl"
              label="Photo"
              labelWidth={labelWidth}
              type="medium"
              uploadLabel="Change Photo"
              imageSrc={service.imageUrl}
              uploaded={photoUpload?.success}
              onUploadPicture={(photo) =>
                uploadAdminServicePhoto(photo, service._id)
              }
            />
          )}
          <AppInput
            type="text"
            name="name"
            label="Name"
            labelWidth={labelWidth}
          />
          <AppSelectMenu
            name="label"
            label="Label"
            labelWidth={labelWidth}
            helperMsg="Label will appear in front of the token number. E.g: A001"
            options={labelOptions}
          />
          <AppSelectPanel
            name="category"
            label="Category"
            options={categoryList}
            cta="Add New Category"
            ctaClick={() => setModalVisible(true)}
            labelWidth={labelWidth}
            noPadding
          />
          {modalVisible && (
            <ModalServicesCategory onClose={() => setModalVisible(false)} />
          )}
          <AppTextArea
            name="desc"
            label="Description"
            helperMsg="Add a short description about your service."
            labelWidth={labelWidth}
            maxCharacter={300}
          />
        </Col>
        <Col width="30%">
          <Guideline title="Service Info">
            <p>
              Service info will help customer understand the service better when
              they make booking.
            </p>
            <p>
              Use a <b>Label</b> to differentiate each service to help customer
              easily make booking.
            </p>
            <p>
              You can explain how the service works or how long the service
              takes under <b>descriptions</b>.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServiceInfo;
