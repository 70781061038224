const tags = [
  {
    _id: "tag1",
    name: "New",
    business: "business1",
    creator: "user1",
    type: "customer",
  },
  {
    _id: "tag2",
    name: "Kids",
    business: "business1",
    creator: "user1",
    type: "customer",
  },
  {
    _id: "tag3",
    name: "Regular",
    business: "business1",
    creator: "user1",
    type: "customer",
  },
  {
    _id: "tag4",
    name: "New Way",
    business: "business1",
    creator: "user1",
    type: "customer",
  },
];

export function getTags(id) {
  return tags;
}
