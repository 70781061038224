import React, { useRef } from "react";
import classNames from "classnames";

import styles from "./Dropdown.module.scss";
import useDropdown from "./useDropdown";
import DropdownWrapper from "./DropdownWrapper";

interface DropdownProps {
  // menu: object;
  menu: JSX.Element;
  className?: string;
  menuAlign?: "left" | "right";
  // onToggle?: (arg: boolean) => void;
  minWidth?: number;
  maxWidth?: string;
  fullWidth?: boolean;
  labelWidth?: number;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  menu,
  className,
  children,
  // onToggle,
  menuAlign,
  minWidth,
  maxWidth,
  fullWidth,
  labelWidth,
  disabled,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { position, toggleDropdown, display, containerWidth, maxHeight } =
    useDropdown(wrapperRef, dropdownRef, listRef);

  const handleToggle = () => {
    console.log("handleToggle");
    toggleDropdown(menuAlign && menuAlign);
    // onToggle?.(display ? false : true);
  };

  return (
    <>
      <div ref={dropdownRef} className={styles["container"]}>
        <div
          ref={wrapperRef}
          role="button"
          className={classNames(className ? className : styles["button"])}
          onClick={(e) => {
            e.stopPropagation();
            !disabled && handleToggle();
          }}
          style={{ minWidth: labelWidth ? labelWidth : "0" }}
        >
          {children}
        </div>
        <div onClick={handleToggle}>
          <DropdownWrapper
            position={position}
            display={display}
            ref={listRef}
            minWidth={fullWidth ? containerWidth : minWidth}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          >
            {menu}
          </DropdownWrapper>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
