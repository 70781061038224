import * as reducers from "../reducer/servingReducer";
import { apiCallBegan } from "../middleware/api";
import { getServings } from "../../data/dataServing";

interface DispatchType {
  type: string;
}

const url = "/serving";

export const getAllServing = () => (dispatch: (arg: DispatchType) => void) => {
  return dispatch(
    apiCallBegan({
      url: url + "/all",
      data: getServings(), // TODO: temporary
      onStart: reducers.servingRequest.type,
      onSuccess: reducers.servingAllReceived.type,
      onError: reducers.servingRequestFailed.type,
    })
  );
};

export const getSingleServing =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("getSingleServing", id);
    return dispatch(
      apiCallBegan({
        url: url + "/single",
        // data: id,
        data: getServings(), // TODO: temporary
        onStart: reducers.servingRequest.type,
        onSuccess: reducers.servingSingleReceived.type,
        onError: reducers.servingRequestFailed.type,
      })
    );
  };
