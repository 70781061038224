import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import {
  ECustomDayType,
  ICustomDayInputType,
} from "../../config/types/CustomDayDataType";
import { generateRandomString } from "../../utils/string";
import {
  defaultHour,
  defaultHourSum,
} from "../../config/option/optionBusiness";
import { getDate } from "../../utils/datetime";

const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

const useCustomBusinessHours = () => {
  const { data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { data: location, customDay: data } = useTypedSelector(
    (state) => state.entities.location
  );
  const { updateBusinessHoursCustomDay, getBusinessHoursCustomDay } =
    useActions();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [listAll, setListAll] = useState<
    { _id: string; dateInString?: string }[]
  >([]); // For checking purpose
  const [listEdit, setListEdit] = useState<ICustomDayInputType[]>([]);
  const [listNew, setListNew] = useState<ICustomDayInputType[]>([]);
  const [errorRow, setErrorRow] = useState<
    | {
        _id: string;
        msg: string;
      }
    | undefined
  >(); // ID of the row
  const [removeIds, setRemoveIds] = useState<string[]>([]);

  function handleAddNew() {
    console.log("add new");
    const _id = generateRandomString(8);
    const dateInString = getDate(TOMORROW, "YYYY MM DD", true);
    business &&
      setListNew((oldList) => [
        ...oldList,
        {
          _id,
          business: business.name,
          location: location[0]._id,
          //   businessHours: '["09:30","18:30"]',
          businessHours: JSON.stringify(defaultHour),
          businessHoursSum: defaultHourSum,
          customType: ECustomDayType.BusinessHours,
          date: TOMORROW.getDate(),
          month: TOMORROW.getMonth() + 1,
          year: TOMORROW.getFullYear(),
          dateInString,
          monthInString: getDate(TOMORROW, "YYYY MM", true),
          fullDate: TOMORROW,
          timestamp: Date.parse(dateInString),
        },
      ]);
    setListAll((listAll) => [...listAll, { _id, dateInString }]);
  }

  function handleChangeDate(_id: string, dateInString: string) {
    console.log("listAll", listAll);
    if (listAll.some((i) => i.dateInString === dateInString)) {
      // Date exists. Set error
      setErrorRow({
        _id,
        msg: "The date already exists. Please select another date",
      });
    } else {
      setErrorRow(undefined);
      if (listAll.some((i) => i._id === _id)) {
        // Update existing date
        const listUpdated = listAll.map((item) =>
          item._id === _id ? { _id, dateInString } : item
        );
        setListAll(listUpdated);
      } else {
        // Add date
        setListAll((listAll) => [...listAll, { _id, dateInString }]);
      }
    }
  }

  function handleEdit(data: ICustomDayInputType) {
    const listUpdated = listEdit.map((item) =>
      item._id === data._id ? data : item
    );
    setListEdit(listUpdated);
  }

  function handleEditNew(data: ICustomDayInputType) {
    const listUpdated = listNew.map((item) =>
      item._id === data._id ? data : item
    );
    setListNew(listUpdated);
  }

  function handleSubmit() {
    console.log("submit", listAll);
    setErrorMsg("");
    const datesInString = listAll.map((item) => item.dateInString);
    const duplicateItem = datesInString.some(
      (i, index) => datesInString.indexOf(i) !== index
    );
    if (duplicateItem) {
      setErrorMsg("You cannot have more than one setting for the same day");
      return;
    }
    const empptyDateItem = listNew.find((i) => !i.dateInString);
    if (empptyDateItem) {
      setErrorRow({ _id: empptyDateItem._id, msg: "Please select a date" });
      return;
    }
    updateBusinessHoursCustomDay(listEdit, listNew, removeIds);
  }

  function handleRemove(id: string, isNew?: boolean) {
    setListAll(listAll.filter((i) => i._id !== id));
    isNew
      ? setListNew(listNew.filter((i) => i._id !== id))
      : setRemoveIds((oldIds) => [...oldIds, id]);
  }

  useEffect(() => {
    if (data.length > 0) {
      const datesInString = data.map(({ _id, dateInString }) => ({
        _id,
        dateInString,
      }));
      setListAll(datesInString);
      setListEdit(data);
    }
  }, [data]);

  useEffect(() => {
    getBusinessHoursCustomDay();
  }, []);

  return {
    handleRemove,
    handleSubmit,
    handleEditNew,
    handleEdit,
    handleChangeDate,
    handleAddNew,
    listEdit,
    listNew,
    errorRow,
    errorMsg,
    removeIds,
  };
};

export default useCustomBusinessHours;
