import classnames from "classnames";
import InputLabel from "./InputLabel";
import styles from "./InputContainer.module.scss";

interface InputContainerProps {
  name: string;
  error?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  className?: string;
}

const InputContainer: React.FC<InputContainerProps> = ({
  name,
  error,
  helperMsg,
  label,
  labelBlock,
  labelWidth,
  children,
  className,
}) => {
  return (
    <div
      className={classnames(
        styles["input-group"],
        !labelBlock && styles["input-inline"],
        className && className
      )}
    >
      {label && (
        <InputLabel name={name} labelWidth={labelWidth} labelBlock={labelBlock}>
          {label}
        </InputLabel>
      )}
      <div className={styles["input-container"]}>
        {children}
        {helperMsg && <div className="desc mt-3">{helperMsg}</div>}
        {error && <div className="desc mt-3 error-msg">{error}</div>}
      </div>
    </div>
  );
};

export default InputContainer;
