import { ModalBody, ModalBox, ModalHeader } from "../ui/modal";

import styles from "./ModalQRCode.module.scss";

interface QRProps {
  title: string;
  qr: string;
  onClose(): void;
}

const ModalQRCode: React.FC<QRProps> = ({ title, qr, onClose }) => {
  return (
    <ModalBox onClose={onClose} type="personal" overlayClose={true}>
      <ModalHeader title={title} />
      <ModalBody>
        <div className={styles["wrapper"]}>
          <img src={qr} alt="Service QR code" />
          <div className="desc text-center">
            Print this QR code to place it at the entrance of your store. Let
            customer scan using mobile phone before walk-in or make booking.
          </div>
        </div>
      </ModalBody>
    </ModalBox>
  );
};

export default ModalQRCode;
