import useGetBookingType from "./useGetBookingType";

const useBookingLabel = () => {
  const { serviceLabels, resourceLabels, providerLabels } = useGetBookingType();

  const labels = [...serviceLabels, ...resourceLabels, ...providerLabels];

  const alphabet = Array.from(Array(26)).map((e, i) => i + 65);
  const labelOptions = alphabet.map((x) => {
    return {
      name: String.fromCharCode(x),
      value: String.fromCharCode(x),
      disabled: false,
    };
  });
  if (labels && labels.length > 0) {
    labels.forEach((i) => {
      const keyIndex = labelOptions.findIndex((el) => el.name === i.value);
      // Disabled selected keycode, except for current service keycode
      //   labelOptions[keyIndex].disabled = i.id !== data.id ? true : false;
      labelOptions[keyIndex].disabled = true;
    });
  }
  const firstDisabled = labelOptions.find((i) => i.disabled === false);
  const presetLabel = firstDisabled && firstDisabled.value;
  return { labelOptions, presetLabel };
};

export default useBookingLabel;
