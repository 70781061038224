const notifications = [
  {
    _id: "notification1",
    userTo: "user1",
    userToEmail: "user1@gmail.com",
    userFrom: "user2",
    userFromName: "anwar",
    notificationType: 1,
    imageUrl: "https://picsum.photos/id/890/200/200",
    isPersonal: true,
    opened: false,
    read: true,
    entityId: "booking1",
    subject: "Lunch Meetup",
    expirationDate: new Date("2022-02-20T01:00:00.000Z"),
  },
];

// meetup
// join as staff

export function getNotifications() {
  return notifications;
}
