import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface ProviderRemoveDialogProps {
	id: string;
	setVisible(arg: boolean): void;
	onRemove?(): void;
	bookingType: string;
}

const ProviderRemoveDialog: React.FC<ProviderRemoveDialogProps> = ({
	setVisible,
	onRemove,
	id,
	bookingType,
}) => {
	const { removeAdminProvider } = useActions();

	const handleConfirm = {
		label: "Remove",
		onClick: () => {
			removeAdminProvider(id);
			setVisible(false);
			onRemove && onRemove();
		},
	};

	return (
		<DialogBox
			title='Are you sure you want to remove?'
			message={`User will not be able to make appointment with this ${bookingType}. All existing booking will remain unchanged.`}
			confirm={handleConfirm}
			cancel='Cancel'
			onClose={() => setVisible(false)}
		/>
	);
};

export default ProviderRemoveDialog;
