import React from "react";
import { Link } from "react-router-dom";
import { authURL } from "../../config/url";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";

const AuthResetSuccessPage = () => {
  return (
    <LayoutCenterBox>
      <div className="text-center">
        <h1>Password Changed</h1>
        <p>Please login with your new password.</p>
        <Link to={authURL.Login} className="btn mt-40 btn-primary">
          Login Now
        </Link>
      </div>
    </LayoutCenterBox>
  );
};

export default AuthResetSuccessPage;
