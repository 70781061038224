// Industry for Business Create Info
export const industry = [
  { name: "Food & Beverage", value: 1 },
  { name: "Health & Medical", value: 2 },
  { name: "Beauty & Wellness", value: 3 },
  { name: "Training & Consultation", value: 4 },
  { name: "Public Sectors", value: 5 },
  { name: "Education & Entertainment", value: 6 },
  { name: "Professional Service", value: 7 },
  { name: "Cleaning & Repair", value: 8 },
];

export const defaultHour = ["09:00", "18:00"];
export const defaultHourSum = 540; // total minutes for hour
export const defaultOffDay = ["Off", "Off"];

export const defaultHours = JSON.stringify([
  defaultOffDay,
  defaultHour,
  defaultHour,
  defaultHour,
  defaultHour,
  defaultHour,
  defaultOffDay,
]);
// Sun, Mon, Tue, Wed, Thu, Fri, Sat
export const defaultHoursSum = JSON.stringify([
  0,
  defaultHourSum,
  defaultHourSum,
  defaultHourSum,
  defaultHourSum,
  defaultHourSum,
  0,
]);
