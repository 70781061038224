import { useState, useEffect } from "react";
import Guideline from "../ui/Guideline";
import { Col, Row } from "../ui/Grid";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import ResendCode from "../ui/ResendCode";
import VerificationCode from "../form/VerificationCode";
import { ETokenType } from "../../config/types/TokenType";
import { AppForm } from "../form";
import { InitialValues, Validation } from "../../config/form/formRegisterEmail";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { LayoutBody } from "../layout/LayoutFullHeight";
import BusinessRegisterFooter from "./BusinessRegisterFooter";

const BusinessRegisterEmail: React.FC = () => {
  useDocumentTitle("Register a Business - Email Verification");

  const { clearTokenState, createToken, registerEmail } = useActions();

  const { tokenId: newTokenId, error: tokenError } = useTypedSelector(
    (state) => state.entities.token
  );
  const {
    error: regError,
    data: regData,
    tokenId,
  } = useTypedSelector((state) => state.entities.register);

  const [initVal, setInitVal] = useState(InitialValues);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    tokenId && setInitVal({ ...initVal, tokenId });
  }, [tokenId]);

  useEffect(() => {
    newTokenId && setInitVal({ ...initVal, tokenId: newTokenId });
  }, [newTokenId]);

  useEffect(() => {
    regData && setEmail(regData.email);
  }, [regData]);

  useEffect(() => {
    regError && setError(regError);
    tokenError && setError(tokenError);
  }, [regError, tokenError]);

  useEffect(() => {
    return () => {
      clearTokenState();
    };
  }, []);

  return (
    <AppForm
      className="flex-layout"
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        registerEmail(values);
      }}
    >
      <LayoutBody>
        <Row>
          <Col width="60%">
            <h3 className="mb-3">Email Verification</h3>
            <div className="mt-20 mb-10">
              Please enter the code sent to{" "}
              <span className="text-semibold">{email}</span>
            </div>
            {error && <p className="error-msg mb-20">{error}</p>}
            <VerificationCode isForm={true} size={6} name="code" />
            <div className="desc mt-5 mb-20">
              (Remember to check your Spam folder)
            </div>
            <div className="mb-30 ">
              <ResendCode
                onClick={() =>
                  createToken({
                    tokenType: ETokenType.registerEmail,
                    email,
                  })
                }
              />
            </div>
          </Col>
          <Col width="30%">
            <Guideline title="Email Verification">
              <p>
                Your email will be used to receive booking notification only
              </p>
            </Guideline>
          </Col>
        </Row>
      </LayoutBody>
      <BusinessRegisterFooter step={2} />
    </AppForm>
  );
};

export default BusinessRegisterEmail;
