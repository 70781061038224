import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import styles from "./AppTextArea.module.scss";
import useCharacterCount from "./useCharacterCount";
import classNames from "classnames";

interface InputProps {
	name: string;
	label?: string;
	labelBlock?: boolean;
	labelWidth?: string;
	helperMsg?: string | JSX.Element;
	placeholder?: string;
	row?: number;
	maxCharacter?: number;
}

interface FormikContextProps {
	[name: string]: string;
	values: string;
}

const AppTextArea: React.FC<InputProps> = ({
	name,
	helperMsg,
	label,
	labelWidth,
	placeholder,
	row = 4,
	labelBlock,
	maxCharacter,
}) => {
	const { handleChange, errors, values } =
		useFormikContext<FormikContextProps>();
	const { characterLeft } = useCharacterCount(values[name], maxCharacter);

	return (
		<InputContainer
			name={name}
			label={label}
			labelBlock={labelBlock}
			labelWidth={labelWidth}
			error={errors[name]}
			helperMsg={helperMsg}>
			<div className={styles["container"]}>
				<textarea
					id={name}
					rows={row}
					name={name}
					value={values[name]}
					maxLength={maxCharacter}
					onChange={handleChange}
					placeholder={placeholder}
					className={classNames("input-control", styles["input"])}
				/>
				{maxCharacter && (
					<span className={styles["count"]}>{characterLeft}</span>
				)}
			</div>
		</InputContainer>
	);
};

export default AppTextArea;
