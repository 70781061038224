import { useState } from "react";
import Tab from "../ui/tab";
import CustomerInfo from "./CustomerInfo";
import CustomerNotes from "./CustomerNotes";
import CustomerTags from "./CustomerTags";

import { ModalBody, ModalFooter } from "../ui/modal";
import MoreButton from "../ui/MoreButton";
import CustomerRecord from "./CustomerRecord";
import {
  CustomerNoteType,
  ICustomerDataType,
} from "../../config/types/CustomerDataType";
import CustomerRemoveDialog from "./CustomerRemoveDialog";

import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegStickyNote, FaRegCalendarPlus } from "react-icons/fa";
import { EBtnCategory } from "../form/AppButton";

interface CustomerDetailProps {
  data: ICustomerDataType;
  onClose(): void;
  onAddNote(): void;
  onViewNote(note: CustomerNoteType): void;
  activeTab?: number | null;
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  data,
  onClose,
  onAddNote,
  onViewNote,
  activeTab,
}) => {
  const [Active, setActive] = useState(activeTab ? activeTab : 0);
  const [dialog, setDialog] = useState(false);
  const Items = [
    {
      tabID: 0,
      name: "Customer",
      content: <CustomerInfo data={data} />,
    },
    {
      tabID: 1,
      name: "Record",
      content: <CustomerRecord customerId={data._id} />,
    },
    // {
    //   tabID: 2,
    //   name: "Notes",
    //   content: (
    //     <CustomerNotes
    //       customerId={data.customerId}
    //       onAddNote={onAddNote}
    //       onViewNote={onViewNote}
    //     />
    //   ),
    // },
    // {
    //   tabID: 3,
    //   name: "Tags",
    //   content: <CustomerTags tags={data.tags} customerId={data._id} />,
    // },
  ];

  const menuList = [
    // {
    //   icon: <FaRegStickyNote />,
    //   name: "Add Note",
    //   onClick: onAddNote,
    //   desc: "Add a note for your own reference",
    // },
    {
      icon: <RiDeleteBin6Line />,
      name: "Remove Customer",
      onClick: () => setDialog(true),
      desc: "Remove this customer from your list",
    },
    // {
    //   icon: <FaRegCalendarPlus />,
    //   name: "Create New Booking",
    //   onClick: () => console.log("Create New Booking"),
    //   desc: "Create a new booking with the customer",
    // },
  ];

  return (
    <>
      <ModalBody>
        <Tab
          items={Items}
          active={Active}
          type="vertical"
          onTabClick={(id: number) => setActive(id)}
        >
          {Items[Active]["content"]}
        </Tab>
      </ModalBody>
      <ModalFooter>
        <MoreButton
          menuList={menuList}
          label="More Actions"
          category={EBtnCategory.PrimaryOutline}
          minWidth={300}
        />
      </ModalFooter>
      {dialog && (
        <CustomerRemoveDialog
          setVisible={(arg) => setDialog(arg)}
          onClose={onClose}
          id={data._id}
        />
      )}
    </>
  );
};

export default CustomerDetail;
