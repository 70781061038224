import { FaExternalLinkAlt } from "react-icons/fa";
import { useState } from "react";
import { useFormikContext } from "formik";

import { Col, Row } from "../ui/Grid";
import { AppTextArea, AppSwitch, AppInput } from "../form";
import ModalQRCode from "./ModalQRCode";
import Guideline from "../ui/Guideline";

import styles from "./BookingPeriod.module.scss";
import { Link } from "react-router-dom";
import { businessURL } from "../../config/url";

interface FormikContextProps {
  [name: string]: boolean;
}

const labelWidth = "120px";
const BookingPeriod: React.FC = () => {
  const { values } = useFormikContext<FormikContextProps>();

  // const [viewQR, setViewQR] = useState(false);

  return (
    <>
      <h3 className="mb-3">Booking Period</h3>
      <div className="desc mb-20">
        Booking period will determine when can user make booking.
      </div>
      <Row>
        <Col width="60%">
          <div className={styles["wrapper"]}>
            <h4 className="mb-3">Online Booking</h4>
            <div className="desc mb-20">
              Allow customer to make booking in advance at your booking page.
            </div>
            <AppSwitch
              name="onlineBooking"
              label="Enable"
              labelWidth={labelWidth}
            />
            {values["onlineBooking"] && (
              <>
                <AppSwitch
                  name="onlineBookingPrivateBooking"
                  label="Private Booking"
                  labelWidth={labelWidth}
                  helperMsg={
                    <>
                      Allow only customers added to your{" "}
                      <Link to={businessURL.BusinessClientele} className="link">
                        list
                      </Link>{" "}
                      to make booking
                    </>
                  }
                />
                <AppInput
                  type="number"
                  name="onlineBookingMinDay"
                  label="Min. Day"
                  labelWidth={labelWidth}
                  inputWidth="120px"
                  suffix="Day(s)"
                  helperMsg="Minimum number of days in advance that clients can make booking before the meeting day"
                />
                <AppInput
                  type="number"
                  name="onlineBookingMaxDay"
                  label="Max. Day"
                  labelWidth={labelWidth}
                  inputWidth="120px"
                  suffix="Day(s)"
                  helperMsg="Maximum number of days in advance that clients can make booking before the meeting day"
                />
                <AppTextArea
                  name="onlineBookingNote"
                  label="Note"
                  helperMsg="Note for customer when they make booking"
                  labelWidth={labelWidth}
                />
              </>
            )}
          </div>
          <div className={styles["wrapper"]}>
            <h4 className="mb-3">Walk-in</h4>
            <div className="desc mb-20">
              Allow customer to make booking upon their arrival at your place.
            </div>
            <AppSwitch name="walkIn" label="Enable" labelWidth={labelWidth} />
            {values["walkIn"] === true && (
              <>
                <AppSwitch
                  name="walkInPrivateBooking"
                  label="Private Booking"
                  labelWidth={labelWidth}
                  helperMsg={
                    <>
                      Allow only customers added to your{" "}
                      <Link to={businessURL.BusinessClientele} className="link">
                        list
                      </Link>{" "}
                      to make booking
                    </>
                  }
                />
              </>
            )}
          </div>
        </Col>
        <Col width="30%">
          <Guideline title="Booking Period">
            <p>Booking period will determine when can user make booking.</p>
            <p>
              To have advance booking, enable the <b>Online Booking</b> to let
              customer make booking on your booking page.
            </p>
            <p>
              Enable <b>Walk-in</b> to have walk-in booking. Print the QR code
              and place it at your store. Customer can scan the QR code to make
              booking.
            </p>
          </Guideline>
        </Col>
      </Row>
      {/* {viewQR && (
        <ModalQRCode
          title={`QR code for ${values.name}`}
          onClose={() => setViewQR(false)}
          qr="https://www.researchgate.net/profile/Hafiza-Abas/publication/288303807/figure/fig1/AS:311239419940864@1451216668048/An-example-of-QR-code.png"
        />
      )} */}
    </>
  );
};

export default BookingPeriod;
