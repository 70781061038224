export const generateRandomString = (length: number) => {
  let chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const generateUID = () => {
  return (
    new Date().getTime().toString(36) + Math.floor(Math.random() * 90 + 10)
  );
};
