import { ModalBox, ModalBody } from "../ui/modal";
import useCreateBooking from "./useCreateBooking";
import { ICustomerDataType } from "../../config/types/CustomerDataType";

interface ModalAdminCreateBookingProps {
  onClose(): void;
  selectedCustomer?: ICustomerDataType;
}

const ModalAdminCreateBooking: React.FC<ModalAdminCreateBookingProps> = ({
  onClose,
  selectedCustomer,
}) => {
  const { renderStep } = useCreateBooking({
    preselectCustomer: selectedCustomer,
  });

  return (
    <ModalBox onClose={onClose} type="business" size="large">
      {/* <ModalHeader title="New Booking" /> */}
      <ModalBody>
        <div className="pt-10 pb-20 px-20">{renderStep()}</div>
      </ModalBody>
    </ModalBox>
  );
};

export default ModalAdminCreateBooking;
