const EVENTS = {
  connection: "connection",
  CLIENT: {
    USER_LOG_IN: "USER_LOG_IN",
    BUSINESS_LOG_IN: "BUSINESS_LOG_IN",
    BOOKING_LISTENER: "BOOKING_LISTENER",
    UPDATE_BOOKING_STATUS: "UPDATE_BOOKING_STATUS",
    UPDATE_MEETUP_STATUS: "UPDATE_MEETUP_STATUS",
  },
  SERVER: {
    MEETUP_CREATED: "MEETUP_CREATED",
    MEETUP_CANCELLED: "MEETUP_CANCELLED",
    MEETUP_STATUS_UPDATED: "MEETUP_STATUS_UPDATED",
    BOOKING_CREATED: "BOOKING_CREATED",
    // ADMIN_BOOKING_CREATED: "ADMIN_BOOKING_CREATED",
    BOOKING_STATUS_UPDATED: "BOOKING_STATUS_UPDATED",
    NOTIFICATION_CREATED: "NOTIFICATION_CREATED",
    BUSINESS_NOTIFICATION_CREATED: "BUSINESS_NOTIFICATION_CREATED",
  },
};

export default EVENTS;
