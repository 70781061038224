import * as reducers from "../reducer/resourceAdminReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getResources as testResources } from "../../data/dataResources";
import { ResourceType } from "../../config/types/ResourceType";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { StaffType } from "../../config/types/StaffType";
import { IServicePeriodType } from "../../config/form/formServicePeriod";
import { InitialValues } from "../../config/form/formResource";
import { IBookingPeriodType } from "../../config/form/formBookingPeriod";
import { IServiceDuplicateInputType } from "../../config/form/formServiceDuplicate";

interface DispatchType {
  type: string;
}

const url = "/resource";

export const clearResourceState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.resourceClearState.type });
  };

export const getAdminResources =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { resourceAdmin: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.resourceAdmin;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        method: "get",
        data: testResources(), // TODO: temporary
        onStart: reducers.resourceRequest.type,
        onSuccess: reducers.resourceReceived.type,
        onError: reducers.resourceRequestFailed.type,
      })
    );
  };

export const removeAdminResource =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeAdminResource", id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: id,
        method: "post",
        onStart: reducers.resourceRemoving.type,
        onSuccess: reducers.resourceRemoved.type,
        onError: reducers.resourceRemoveFailed.type,
      })
    );
  };

export const duplicateAdminResource =
  (data: IServiceDuplicateInputType, id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { resource: { data: ResourceType[] } } }
  ) => {
    console.log("duplicateAdminResource", id);
    const { data: resources } = getState().entities.resource;

    const resource = resources.find((s) => s._id === id);
    let duplicatedData;
    if (resource) {
      // duplicatedData = { ...service, ...data }; // TODO: temporary add id
      duplicatedData = { ...resource, ...data, id: "temp" };
    }
    console.log(duplicatedData);
    return dispatch(
      apiCallBegan({
        url: url + "/duplicate",
        data: duplicatedData,
        method: "post",
        onStart: reducers.resourceDuplicating.type,
        onSuccess: reducers.resourceDuplicated.type,
        onError: reducers.resourceDuplicateFailed.type,
      })
    );
  };

export const addAdminResource =
  (data: ResourceType) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        location: { data: ILocationDataType[] };
        staff: { currentUser: StaffType };
      };
    }
  ) => {
    const { data: locations } = getState().entities.location;
    const { currentUser: staff } = getState().entities.staff;
    console.log(staff);
    const resourceData = {
      ...data,
      id: "resource-new", // TODO: temporary
      location: [locations[0]._id],
      staff: [staff._id],
    };
    console.log("addAdminResource", resourceData);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        method: "post",
        data: resourceData,
        onStart: reducers.resourceAdding.type,
        onSuccess: reducers.resourceAdded.type,
        onError: reducers.resourceAddFailed.type,
      })
    );
  };

export const updateAdminResource =
  (data: ResourceType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("data", data);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data,
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const updateAdminResourcePeriod =
  (data: IServicePeriodType, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/service-period",
        // data: { servicePeriod: data, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const updateAdminResourceBookingPeriod =
  (data: IBookingPeriodType, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/booking-period",
        // data: { resourceData, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const addAdminResourceLocation =
  (resourceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("addAdminResourceLocation", resourceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/add-location",
        // data: { resourceId, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const removeAdminResourceLocation =
  (resourceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeAdminResourceLocation", resourceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-location",
        // data: { resourceId, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const addAdminResourceStaff =
  (resourceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("addAdminResourceStaff", resourceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/add-staff",
        // data: { resourceId, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const removeAdminResourceStaff =
  (resourceId: string, id: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeAdminResourceStaff", resourceId, id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-staff",
        // data: { resourceId, id },
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        onStart: reducers.resourceUpdating.type,
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const updateAdminResourceWalkIn =
  (id: string, value: boolean) => (dispatch: (arg: DispatchType) => void) => {
    console.log("updateAdminResourceWalkIn", value);
    return dispatch(
      apiCallBegan({
        // url: url + "/resource-period",
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        // onStart: reducers.resourceWalkInUpdating.type,
        onStartDispatch: {
          type: reducers.resourceUpdating.type,
          payload: { type: "walk-in", id, value },
        },
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };

export const updateAdminResourceOnlineBooking =
  (id: string, value: boolean) => (dispatch: (arg: DispatchType) => void) => {
    console.log("updateAdminResourceOnlineBooking", value);
    return dispatch(
      apiCallBegan({
        // url: url + "/resource-period",
        data: { ...InitialValues, id: "resource-new" }, // TODO: temporary
        method: "post",
        // onStart: reducers.resourceWalkInUpdating.type,
        onStartDispatch: {
          type: reducers.resourceUpdating.type,
          payload: { type: "online-booking", id, value },
        },
        onSuccess: reducers.resourceUpdated.type,
        onError: reducers.resourceUpdateFailed.type,
      })
    );
  };
