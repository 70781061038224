import App from "../../App";
import { ModalBox } from "../ui/modal";
import styles from "./BookingCancelDialog.module.scss";
import { AppButton, AppTextArea, AppForm } from "../form";
import { EBtnCategory } from "../form/AppButton";
import { useActions } from "../../hooks/useActions";
import * as yup from "yup";
import { useState } from "react";

interface BusinessCancelDialogProps {
	id: string;
	status: number;
	setVisible(arg: boolean): void;
}

const InitialValues = {
	message: "",
};

// Validation
const Validation = yup.object().shape({
	message: yup.string().max(300, "Maximum 300 characters"),
});

const BusinessCancelDialog: React.FC<BusinessCancelDialogProps> = ({
	id,
	status,
	setVisible,
}) => {
	const { cancelBusinessBooking } = useActions();

	const [errorMessage, setErrorMessage] = useState();

	return (
		<ModalBox onClose={() => setVisible(false)} type='dialog'>
			<AppForm
				initialValues={InitialValues}
				validationSchema={Validation}
				onSubmit={(values, actions) => {
					cancelBusinessBooking(id, status, values.message);
					setVisible(false);
				}}>
				<div className={styles["wrapper"]}>
					<h4>Are you sure you want to cancel the booking?</h4>

					{errorMessage && <div className='error-msg'>{errorMessage}</div>}

					<div className={styles["content"]}>
						<AppTextArea
							name='message'
							placeholder='Message to customer (optional)'
							maxCharacter={300}
						/>
					</div>
				</div>
				<div className={styles["footer"]}>
					<AppButton
						type='button'
						category={EBtnCategory.PrimaryOutline}
						size='small'
						onClick={() => setVisible(false)}>
						No
					</AppButton>

					<AppButton type='submit' category={EBtnCategory.Primary} size='small'>
						Yes
					</AppButton>
				</div>
			</AppForm>
		</ModalBox>
	);
};

export default BusinessCancelDialog;
