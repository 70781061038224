import { createSelector } from "reselect";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { getDate, todayString } from "../../utils/datetime";

// TODO: filter today booking
export const getTodayBooking = createSelector(
  (state: {
    entities: {
      personalBooking: {
        upcoming: { data: (IBookingDataType & IMeetupDataType)[] };
      };
    };
  }) => state.entities.personalBooking.upcoming,
  (upcoming) => upcoming.data.filter((i) => i.meetDate === todayString)
);
