import { IServiceDataType } from "../../config/types/ServiceDataType";
import { Col, Row } from "../ui/Grid";
// import { FormDateTime } from "../form/services";
import Guideline from "../ui/Guideline";
// import { AppSelectMenu } from "../form";
// import { servicePeriodOption } from "../../config/option/optionService";
import ServiceHour from "./ServiceHour";

interface ServicePeriodProps {
  name: string;
  service?: IServiceDataType;
}

const ServicePeriod: React.FC<ServicePeriodProps> = ({ name, service }) => {
  console.log("service", service);
  return (
    <>
      <h3 className="mb-3">Service Period</h3>
      <div className="desc mb-20">Set when is this {name} be available.</div>
      <Row>
        <Col width="60%">
          {/* <AppSelectMenu
            name="servicePeriodOption"
            inputWidth="300px"
            options={servicePeriodOption}
          /> */}
          {/* <FormDateTime /> */}
          <ServiceHour
            service={service}
            optionName="servicePeriodOption"
            hoursName="servicePeriod"
            hoursSumName="servicePeriodSum"
          />
        </Col>
        <Col width="30%">
          <Guideline title="Service Period">
            <p>Service period will determine when the service take place.</p>
            <p>
              Select <b>Custom service hour</b> if the service hour is
              difference than your business hour.
            </p>
            <p>
              Select <b>Custom day and time</b> if the service only take place
              on particular day and time.
            </p>
            {/* <p>
              if you are not sure when the service will take place, please
              select <b>Waiting List</b>.
            </p> */}
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServicePeriod;
