import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import PersonalFriend from "../../components/bookmark/PersonalFriend";
import Header from "../../components/layout/Header";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const PersonalFriendsPage: React.FC = () => {
  const { getFriends, clearFriendState } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.friend
  );
  const { notify } = useToast();

  useEffect(() => {
    getFriends();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearFriendState();
    } else if (error) {
      notify("error", error);
      clearFriendState();
    }
  }, [success, error]);

  return (
    <PageWithAside role="personal">
      <div className="wrapper">
        <Header
          pageTitle="Friends"
          pageDesc="People that you have made appointment with them before will auto added to your bookmark"
          role="personal"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && <PersonalFriend />}
        <MenuCreateNew role="personal" />
      </div>
    </PageWithAside>
  );
};
export default PersonalFriendsPage;
