import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import ServiceBookingSlot from "../../components/services/ServiceBookingSlot";
import {
  IServiceInputType,
  InitialValues,
  Validation,
} from "../../config/form/formService";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { countDailyCapacity } from "../../utils/capacity";

const ServiceBookingSlotPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { service, submenu, header, footer } = useBusinessServicePage(id);
  const [initVal, setInitVal] = useState<IServiceInputType>({
    ...InitialValues,
  });
  const { updateAdminService } = useActions();

  useEffect(() => {
    if (service) {
      console.log("service", service);
      setInitVal({
        ...initVal,
        ...service,
      });
    }
  }, [service]);

  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && service && (
          <AppForm
            initialValues={initVal}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              console.log(values);
              const dailyCapacity = countDailyCapacity(
                values.bookingCapacity,
                values.slotInterval,
                values.servicePeriodSum
              );
              updateAdminService({ ...values, dailyCapacity });
            }}
            validationSchema={Validation}
          >
            <ServiceBookingSlot service={service} />
            {footer}
          </AppForm>
        )}
      </div>
    </PageWithAside>
  );
};

export default ServiceBookingSlotPage;
