import { getLocation } from "./dataLocations";
import { getServices } from "./dataServices";
import { getResources } from "./dataResources";
import { getProviders } from "./dataProviders";

const businesses = [
  {
    _id: "business1",
    about: "test business",
    profilePic: "https://picsum.photos/id/890/200/200",
    businessUrl: "ckonebusines",
    creator: "user1",
    industry: 1,
    name: "CK One Business",
    registerStep: 1,
    businessUid: "biz-iWNKMaNrtQpiASCoS68MS",
  },
  {
    _id: "business2",
    about:
      "Established in 2017, this clinic is known for its affordable services that they provide in the Residential Area of Klang, Selangor, Malaysia. This includes the clinic's listed signature treatments like Infectious Diseases Treatment, Basic Suturing, Incision and Drainage, Dengue Test and Treatment an",
    profilePic: "https://picsum.photos/id/891/200/200",
    businessUrl: "gangnamstyle",
    creator: "user2",
    industry: 2,
    name: "Gangnam Style Hair Cut",
    registerStep: 1,
    businessUid: "biz-saadsgaetshfdah",
  },
];

export function getBusinesses() {
  return businesses;
}

export function getBusiness(id) {
  const business = businesses.find((m) => m._id === id);
  return business ? business : businesses[0];
}

export function getBusinessData(businessId) {
  const dataServices = getServices();
  let result = businesses.find((m) => m._id === businessId);
  result = result ? result : businesses[0];
  const output = {
    business: result,
    locations: getLocation(businessId),
    services: dataServices,
  };
  return output;
}
