import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../../components/ui/toasts/useToast";
import { AppButton, AppInput, AppForm } from "../../components/form";
import { EBtnCategory } from "../../components/form/AppButton";
import {
  InitialValues,
  Validation,
} from "../../config/form/formChangePassword";

const LabelWidth = "150px";
const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { notify } = useToast();
  const { changePwd, clearUserState } = useActions();
  const { loading, success, error } = useTypedSelector(
    (state) => state.entities.user
  );
  useEffect(() => {
    if (success) {
      notify("success", success);
      clearUserState();
    }
    if (error) {
      notify("error", error);
      clearUserState();
    }
  }, [success, error]);

  return (
    <AppForm
      initialValues={InitialValues}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        changePwd(values);
      }}
    >
      <h4 className="mb-15">Change Password</h4>
      <AppInput
        name="password"
        label="Existing Password"
        type="password"
        labelWidth={LabelWidth}
      />
      <AppInput
        name="newPassword"
        label="New Password"
        type="password"
        labelWidth={LabelWidth}
      />
      <AppInput
        name="reEnterPassword"
        label="Re-type Password"
        labelWidth={LabelWidth}
        type="password"
      />
      <AppButton
        className="my-20"
        type="submit"
        category={EBtnCategory.Primary}
        // loading={loading}
      >
        Save
      </AppButton>
    </AppForm>
  );
};

export default ChangePassword;
