import { useRef } from "react";
import { BsCalendar } from "react-icons/bs";
import styles from "./DatePicker.module.scss";
import CalendarMini from "./CalendarMini";
import { DropdownWrapper, useDropdown } from "../ui/dropdown";
import { getDate } from "../../utils/datetime";

interface DatePickerProps {
  value?: Date | string;
  initialValue?: Date;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (val: Date | Date[]) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  initialValue,
  placeholder,
  minDate,
  maxDate,
  onChange,
  ...props
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { position, toggleDropdown, display } = useDropdown(
    wrapperRef,
    dropdownRef,
    listRef
  );
  // console.log("value", value);

  const handleChage = (val: Date | Date[]) => {
    toggleDropdown();
    onChange(val);
  };

  return (
    <div className={styles["container"]}>
      <div ref={dropdownRef}>
        <div
          ref={wrapperRef}
          role="button"
          className={styles["date-picker"]}
          onClick={() => toggleDropdown()}
        >
          <input
            type="text"
            className="input-control"
            readOnly
            autoComplete="off"
            placeholder={placeholder}
            value={value ? getDate(value) : ""}
          />
          <div className={styles["icon"]}>
            <BsCalendar />
          </div>
        </div>
      </div>
      <DropdownWrapper position={position} display={display} ref={listRef}>
        <div ref={dropdownRef} className={styles["calendar-drop-down"]}>
          <CalendarMini
            {...props}
            minDate={minDate}
            maxDate={maxDate}
            initialValue={initialValue}
            onChange={(val) => handleChage(val)}
            value={value ? new Date(value) : new Date()}
          />
        </div>
      </DropdownWrapper>
    </div>
  );
};

export default DatePicker;
