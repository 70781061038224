// Fetech the same data after X minutes from last fetch;
const interval = 10;

export function fetch(lastFetch?: number) {
  if (!lastFetch) return true;
  const millis = Date.now() - lastFetch;
  const difInMinutes = Math.floor(millis / 60000);
  console.log("difInMinutes", difInMinutes);
  if (difInMinutes < interval) return false;
  return true;
}
