import styles from "./CustomerNoteDetails.module.scss";
import { getDate } from "../../utils/datetime";
import { CustomerNoteType } from "../../config/types/CustomerDataType";

interface CustomerNoteDetailsProps {
  data: CustomerNoteType;
}

const CustomerNoteDetails: React.FC<CustomerNoteDetailsProps> = ({ data }) => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["header"]}>
        <h4>{data.subject}</h4>
        <div className="desc text-right">{getDate(data.created, "MMM DD")}</div>
      </div>
      <div className={styles["content"]}>{data.message}</div>
      <div className="desc">
        {"By "}
        {data.author}
      </div>
    </div>
  );
};

export default CustomerNoteDetails;
