// Page Layout with Side menu
import { useContext, useEffect } from "react";
import PageLayout from "./PageLayout";
import styles from "./PageWithAside.module.scss";
import { FaBars } from "react-icons/fa";
import Aside from "./Aside";
import classNames from "classnames";
import SubMenu from "./SubMenu";
import { AppContext, ContextType } from "../../context/appContext";
import Notification from "../ui/notification";
import Accounts from "./Accounts";
import useIsMobileView from "../../hooks/useIsMobileView";
import Message from "../ui/message";

interface PageWithAsideProps {
  role: "personal" | "business";
  noPadding?: boolean;
  submenuName?: string;
  submenuList?: { name: string; url: string }[];
  goUpLevel?: string;
}

const PageWithAside: React.FC<PageWithAsideProps> = ({
  children,
  submenuName,
  noPadding,
  role,
  submenuList,
  goUpLevel,
}) => {
  const { appState, handleStateChange } = useContext(AppContext) as ContextType;

  const isMobileView = useIsMobileView();
  useEffect(() => {
    isMobileView && handleStateChange({ collapsed: true });
  }, []);

  return (
    <PageLayout>
      <div
        className={classNames(
          styles["outer"],
          appState.collapsed && styles["collapsed"]
        )}
      >
        <div className={styles["aside"]}>
          <Aside role={role} />
        </div>
        <div
          className={styles["overlay"]}
          onClick={() => {
            handleStateChange({ collapsed: true });
          }}
        ></div>
        <div
          className={classNames(
            styles["container"],
            submenuName && styles["with-submenu"]
          )}
        >
          {submenuName && submenuList && (
            <div className={styles["submenu"]}>
              <SubMenu
                role={role}
                name={submenuName}
                list={submenuList}
                goUpLevel={goUpLevel}
              />
            </div>
          )}
          <div className={styles["header"]}>
            <div
              className={styles["mobile-menu"]}
              role="button"
              onClick={() => {
                handleStateChange({ collapsed: false });
              }}
            >
              <FaBars />
            </div>
            <div className={styles["accounts"]}>
              <Accounts role={role} isHeader />
            </div>
            <Message role={role} />
            <Notification role={role} />
          </div>
          <div
            className={classNames(!noPadding && "container", styles["content"])}
          >
            {children}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PageWithAside;
