import * as yup from "yup";

// const onlineBookingValue = {
//   enable: true,
//   minDay: 1,
//   maxDay: 7,
//   note: "",
//   privateBooking: false,
// };
// const walkInValue = { walkIn: true, walkInPrivateBooking: false };

// For Service Form
// export const BookingPeriodInit = {
//   ...walkInValue,
//   onlineBooking: onlineBookingValue,
// };

// For Booking Period Form
export const InitialValues = {
  // ...onlineBookingValue,
  // ...walkInValue,

  onlineBooking: true,
  onlineBookingMinDay: 1,
  onlineBookingMaxDay: 7,
  onlineBookingNote: "",
  onlineBookingPrivateBooking: false,
  walkIn: true,
  walkInPrivateBooking: false,
};

export const BookingPeriodValidation = {
  // onlineBooking: yup.boolean(),
  onlineBooking: yup.boolean(),
  onlineBookingMinDay: yup
    .number()
    .min(1, "Minimum 1 day")
    .max(
      yup.ref("onlineBookingMaxDay"),
      "Minimum day cannot be more than maximum day"
    )
    .required("Minimum day is required"),
  onlineBookingMaxDay: yup
    .number()
    .min(
      yup.ref("onlineBookingMinDay"),
      "Maximum day cannot be less than Minimum day"
    )
    .max(30, "Maximum 30 days")
    .required("Maximum day is required"),
  // .moreThan(yup.ref("minDay"), "Maximum day should be more than Minimum day"),
  onlineBookingNote: yup.string().max(300, "Maximum 300 characters"),
  onlineBookingPrivateBooking: yup.boolean(),
  walkIn: yup.boolean(),
  walkInPrivateBooking: yup.boolean(),
};

export const Validation = yup.object().shape(BookingPeriodValidation);

export interface IBookingPeriodType {
  onlineBooking: boolean;
  onlineBookingMinDay: number;
  onlineBookingMaxDay: number;
  onlineBookingNote: string;
  onlineBookingPrivateBooking: boolean;
  walkIn: boolean;
  walkInPrivateBooking: boolean;
}
