import PageWithSteps from "../../components/layout/PageWithSteps";
import {
  LayoutBody,
  LayoutFullHeight,
  LayoutHeader,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessInfo from "../../components/businessProfile/BusinessInfo";
import { AppForm } from "../../components/form";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useActions } from "../../hooks/useActions";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessProfile";
import useBusinessSetup from "./useBusinessSetup";
import { setupBusiness } from "../../config/steps";
import BusinessSetupFooter from "../../components/business/BusinessSetupFooter";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const BusinessSetupPage: React.FC = () => {
  useDocumentTitle("Create a Business - AddPointment");
  const { header } = useBusinessProfile(0);
  const { createBusiness } = useActions();
  const { error } = useTypedSelector((state) => state.entities.setup);
  useBusinessSetup(1);

  return (
    <PageWithSteps activeStep={0} steps={setupBusiness}>
      <AppForm
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          createBusiness(values);
        }}
      >
        <LayoutFullHeight>
          <LayoutHeader title="New Business" />
          <LayoutBody>
            {header()}
            {error && <p className="error-msg">{error}</p>}
            <BusinessInfo />
          </LayoutBody>
          <BusinessSetupFooter step={1} />
        </LayoutFullHeight>
      </AppForm>
    </PageWithSteps>
  );
};

export default BusinessSetupPage;
