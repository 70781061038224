import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import BusinessProviders from "../../components/providers/BusinessProviders";
import Loader from "../../components/ui/Loader";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../../components/ui/toasts/useToast";

const BusinessProvidersPage: React.FC = () => {
  const { getAdminProviders, clearAdminProviderState } = useActions();
  const { error, success, data, request } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );

  const { notify } = useToast();

  useEffect(() => {
    getAdminProviders();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearAdminProviderState();
    }

    if (error) {
      notify("error", error);
      clearAdminProviderState();
    }
  }, [success, error]);

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Providers"
          pageDesc="List of your team members that customer can make booking with."
          role="business"
        />

        {request?.error && <div className="error-msg">{request.error}</div>}
        {request?.loading && <Loader />}
        {!request && data && <BusinessProviders />}
      </div>
    </PageWithAside>
  );
};

export default BusinessProvidersPage;
