const bookmarkBusiness = [
  {
    _id: "bookmarkbiz1",
    // business: getBusiness("business1"),
    business: "business1",
    creator: "user1",
    businessName: "CK One Business",
    profilePic: "https://picsum.photos/id/890/200/200",
    industry: 1,
    businessUrl: "ckonebusiness",
  },
  {
    _id: "bookmarkbiz2",
    // business: getBusiness("business2"),
    business: "business2",
    creator: "user1",
    website: "google.com",
    businessName: "Gangnam Style Hair Cut",
    profilePic: "https://picsum.photos/id/891/200/200",
    industry: 2,
    businessUrl: "wlclinic",
  },
];

export function getBookmarkBusinesses() {
  return bookmarkBusiness;
  // return [];
}
