import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import TableWalkIn from "./TableWalkIn";
import { businessURL } from "../../config/url";
import ModalQRCode from "../common/ModalQRCode";
import { useActions } from "../../hooks/useActions";
import useGetBookingType from "../../hooks/useGetBookingType";

const BusinessWalkIn: React.FC = () => {
  const [viewQR, setViewQR] = useState<string | null>(null);
  const { services, resources, providers } = useGetBookingType("none");

  const { updateAdminServiceWalkIn, updateAdminResourceWalkIn } = useActions();

  const handleToggle = (id: string, value: boolean, type: string) => {
    type === "services" && updateAdminServiceWalkIn(id, value);
    type === "resources" && updateAdminResourceWalkIn(id, value);
  };

  return (
    <>
      <Row>
        <Col width="60%">
          <Link
            className="btn btn-primary-outline mb-30"
            to={businessURL.BusinessWalkInRegistration}
            target="blank"
          >
            <span className="mr-10">Walk-in Registration for all</span>
            <FaExternalLinkAlt />
          </Link>
          {services.length > 0 && (
            <>
              <Row className="separator pb-5">
                <Col>
                  <h4>Services</h4>
                </Col>
                <Col>
                  <Link
                    className="btn btn-link"
                    to={businessURL.BusinessWalkInRegistration}
                    target="_blank"
                  >
                    <span className="mr-3">Registration for services</span>{" "}
                    <FaExternalLinkAlt />
                  </Link>
                </Col>
              </Row>
              <TableWalkIn
                data={services}
                onViewQR={(id) => setViewQR(id)}
                onToggle={(id, val) => handleToggle(id, val, "services")}
              />
            </>
          )}
          {resources.length > 0 && (
            <>
              <Row className="separator pb-5">
                <Col>
                  <h4>Resources</h4>
                </Col>
                <Col>
                  <Link
                    className="btn btn-link"
                    to={businessURL.BusinessWalkInRegistration}
                    target="_blank"
                  >
                    <span className="mr-3">Registration for resources</span>{" "}
                    <FaExternalLinkAlt />
                  </Link>
                </Col>
              </Row>
              <TableWalkIn
                data={resources}
                onViewQR={(id) => setViewQR(id)}
                onToggle={(id, val) => handleToggle(id, val, "resources")}
              />
            </>
          )}
          {providers.length > 0 && (
            <>
              <Row className="separator pb-5">
                <Col>
                  <h4>Providers</h4>
                </Col>
                <Col>
                  <Link
                    className="btn btn-link"
                    to={businessURL.BusinessWalkInRegistration}
                    target="_blank"
                  >
                    <span className="mr-3">Registration for providers</span>{" "}
                    <FaExternalLinkAlt />
                  </Link>
                </Col>
              </Row>
              <TableWalkIn
                data={providers}
                onViewQR={(id) => setViewQR(id)}
                onToggle={(id, val) => handleToggle(id, val, "providers")}
              />
            </>
          )}
        </Col>
        <Col width="30%">
          <Guideline title="Walk-in Registration">
            <p>
              You can <b>register</b> a walk-in customer or print out the{" "}
              <b>QR code</b> for the service. Customer can scan the QR code via{" "}
              <b>AddPointment App</b> to register themselves.
            </p>
          </Guideline>
        </Col>
      </Row>
      {viewQR && (
        <ModalQRCode
          title={`QR code for ${viewQR}`}
          onClose={() => setViewQR(null)}
          qr="https://www.researchgate.net/profile/Hafiza-Abas/publication/288303807/figure/fig1/AS:311239419940864@1451216668048/An-example-of-QR-code.png"
        />
      )}
    </>
  );
};

export default BusinessWalkIn;
