import { useState, useEffect } from "react";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { useActions } from "../../hooks/useActions";
import useGetBookingType from "../../hooks/useGetBookingType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  getTodayCompletedBusinessBooking,
  getTodayIncompleteBusinessBooking,
} from "../../store/selector";

const filterName = [
  "View By Services",
  "View By Resources",
  "View By Providers",
];

const typeName = ["services", "resources", "providers"];

type filteredData = {
  _id: string;
  name: string;
  incomplete: number;
  completed: number;
};

const useDashboardTodayBooking = () => {
  const { services, resources, providers } = useGetBookingType();
  const { getBusinessTodayBooking } = useActions();
  const [filter, setFilter] = useState<{ name: string }[]>([]);
  const [active, setActive] = useState<string>();
  const [serviceData, setServiceData] = useState<filteredData[]>();
  const [resourceData, setResourceData] = useState<filteredData[]>();
  const [providerData, setProviderData] = useState<filteredData[]>();
  const completedBookings: IBookingDataType[] = useTypedSelector(
    getTodayCompletedBusinessBooking
  );
  const incompleteBookings: IBookingDataType[] = useTypedSelector(
    getTodayIncompleteBusinessBooking
  );
  const { today } = useTypedSelector((state) => state.entities.businessBooking);

  useEffect(() => {
    console.log("getBusinessTodayBooking ------");
    getBusinessTodayBooking();
  }, []);

  const generateData = (type: number) => {
    // const source = [services, resources, providers];
    const source = [services, resources];
    const result = source[type].map((s) => {
      const incomplete = incompleteBookings
        ? incompleteBookings.filter((i) => i.service === s._id)
        : [];
      const completed = completedBookings
        ? completedBookings.filter((i) => i.service === s._id)
        : [];
      return {
        _id: s._id,
        name: s.name,
        label: s.label,
        incomplete: incomplete.length,
        completed: completed.length,
      };
    });
    return result;
  };

  const generateFilter = (type: number) => {
    return {
      name: filterName[type],
      onClick: () => setActive(typeName[type]),
    };
  };

  useEffect(() => {
    let filterList = [];
    if (today.length > 0) {
      if (services.length > 0) {
        // console.log("test-------");
        // filterList.push(generateFilter(0));
        const result = generateData(0);
        console.log("result", result);
        result && setServiceData(result);
      }
      // if (resources && resources.length > 0) {
      //   filterList.push(generateFilter(1));
      //   const result = generateData(1);
      //   result && setResourceData(result);
      // }
      // if (providers && providers.length > 0) {
      //   filterList.push(generateFilter(2));
      //   const result = generateData(2);
      //   result && setProviderData(result);
      // }
      // setFilter(filterList);
      // }, [services, resources, providers]);
    }
  }, [services, today]);

  const getTableData = () => {
    // let selected = filter.length > 0 ? filter[0].name : "";
    // let selected = "";
    // let data = serviceData || resourceData || providerData;
    // let data = serviceData;
    // if (active) {
    //   if (active === typeName[0]) {
    //     data = serviceData;
    //     selected = filterName[0];
    // } else if (active === typeName[1]) {
    //   data = resourceData;
    //   selected = filterName[1];
    // } else if (active === typeName[2]) {
    //   data = providerData;
    //   selected = filterName[2];
    // }
    // }
    const data =
      serviceData && serviceData.length > 5
        ? serviceData.slice(0, 5)
        : serviceData;
    const othersData = serviceData && serviceData.length > 5 ? true : false;
    return { data, othersData };
  };

  return { filter, getTableData };
};

export default useDashboardTodayBooking;
