import { Route } from "react-router-dom";
import { businessURL, businessProviderURL } from "../config/url";
import ProviderAvailablePeriodPage from "../pages/businessProvider/ProviderAvailablePeriodPage";
import ProviderInfoPage from "../pages/businessProvider/ProviderInfoPage";
import ProviderServicesPage from "../pages/businessProvider/ProviderServicesPage";
import BusinessRouter from "./BusinessRouter";

const businessProviderRoutes = () => {
	return (
		<>
			<Route path={businessURL.BusinessProviders} element={<BusinessRouter />}>
				<Route
					path={`${businessURL.BusinessProviders}/:id/${businessProviderURL.Info}`}
					element={<ProviderInfoPage />}
				/>
				<Route
					path={`${businessURL.BusinessProviders}/:id/${businessProviderURL.AvailablePeriod}`}
					element={<ProviderAvailablePeriodPage />}
				/>
				<Route
					path={`${businessURL.BusinessProviders}/:id/${businessProviderURL.Services}`}
					element={<ProviderServicesPage />}
				/>
			</Route>
		</>
	);
};

export default businessProviderRoutes;
