import { Fragment } from "react";
import { industry } from "../config/option/optionBusiness";
import { convertTimeString, convertToTimestamp } from "../utils/datetime";

const useBusiness = () => {
  const getIndustryName = (value: number) => {
    const type = industry.find((item) => item.value === value);
    return type ? type.name : "";
  };

  const displayBusinessHours = (item: string[]) => {
    const output = item.map((time, key) => {
      if (time === "Off") return key === 0 ? null : <span key={key}>Off</span>;
      return key % 2 === 0 ? (
        <span key={key}>{convertTimeString(time)} - </span>
      ) : (
        <Fragment key={key}>
          <span>{convertTimeString(time)} </span>
          <br />
        </Fragment>
      );
    });
    return output;
  };

  const calculateHoursSum = (value: string[]) => {
    let sum = 0;
    if (value[0] === "Off") {
      sum = 0;
    } else if (value.length === 2) {
      const result =
        convertToTimestamp(value[1]) - convertToTimestamp(value[0]);
      sum = result / 60000;
    } else if (value.length === 4) {
      const result =
        convertToTimestamp(value[3]) -
        convertToTimestamp(value[2]) +
        (convertToTimestamp(value[1]) - convertToTimestamp(value[0]));
      sum = result / 60000;
    } else if (value.length === 6) {
      const result =
        convertToTimestamp(value[5]) -
        convertToTimestamp(value[4]) +
        (convertToTimestamp(value[3]) - convertToTimestamp(value[2])) +
        (convertToTimestamp(value[1]) - convertToTimestamp(value[0]));
      sum = result / 60000;
    }
    return sum;
  };

  return { getIndustryName, displayBusinessHours, calculateHoursSum };
};

export default useBusiness;
