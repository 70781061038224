import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";

interface ServiceRemoveDialogProps {
  id: string;
  setVisible(arg: boolean): void;
  onRemove?(): void;
}

const ServiceRemoveDialog: React.FC<ServiceRemoveDialogProps> = ({
  setVisible,
  onRemove,
  id,
}) => {
  const { removeAdminService } = useActions();

  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      removeAdminService(id);
      setVisible(false);
      onRemove && onRemove();
    },
  };

  return (
    <DialogBox
      title="Are you sure you want to remove?"
      message="This service will stop receive new booking. All existing booking will remain unchanged."
      confirm={handleConfirm}
      cancel="Cancel"
      onClose={() => setVisible(false)}
    />
  );
};

export default ServiceRemoveDialog;
