enum EBookingStatusCategory {
  Scheduled = "scheduled",
  Confirmed = "confirmed",
  CheckedIn = "checked-in",
  Serving = "serving",
  Completed = "completed",
  Ended = "ended",
  Uncompleted = "uncompleted",
  NotAttend = "not-attend",
}

export enum EBookingStatusValue {
  create = 101,
  inviteByNewFriend = 102,
  inviteByFriend = 103,
  book = 111,
  createByBusiness = 112,
  createByFriend = 113,
  skip = 121,
  approve = 201,
  approveByBusiness = 202,
  confirm = 211,
  confirmByBusiness = 212,
  checkIn = 301,
  checkInByBusiness = 302,
  walkIn = 311,
  qrRegister = 312,
  serving = 401,
  complete = 501,
  end = 502,
  uncomplete = 503,
  reschedule = 511,
  transfer = 512,
  cancel = 601,
  noshow = 611,
  notAttend = 612,
  absent = 613,
  reject = 621,
}

const bookingStatus = [
  // created by host
  {
    value: EBookingStatusValue.create,
    category: EBookingStatusCategory.Scheduled,
    label: "Created",
  },

  // Invited by user (new friend)
  {
    value: EBookingStatusValue.inviteByNewFriend,
    category: EBookingStatusCategory.Scheduled,
    label: "Invited",
  },

  // Invited by user (existing friend)
  {
    value: EBookingStatusValue.inviteByFriend,
    category: EBookingStatusCategory.Scheduled,
    label: "Invited",
  },

  // Booking created by user
  {
    value: EBookingStatusValue.book,
    category: EBookingStatusCategory.Scheduled,
    label: "Scheduled",
  },

  // Booking created by service provider
  {
    value: EBookingStatusValue.createByBusiness,
    category: EBookingStatusCategory.Scheduled,
    label: "Scheduled",
  },

  // Booking created by friend
  {
    value: EBookingStatusValue.createByFriend,
    category: EBookingStatusCategory.Scheduled,
    label: "Scheduled",
  },

  // Skipped
  {
    value: EBookingStatusValue.skip,
    category: EBookingStatusCategory.Confirmed,
    label: "Scheduled",
  },

  // Approved by user
  {
    value: EBookingStatusValue.approve,
    category: EBookingStatusCategory.Confirmed,
    label: "Approved",
    timeline: "Approved",
  },

  // Approved by service provider
  {
    value: EBookingStatusValue.approveByBusiness,
    category: EBookingStatusCategory.Confirmed,
    label: "Approved",
  },

  // Confirmed by user
  {
    value: EBookingStatusValue.confirm,
    category: EBookingStatusCategory.Confirmed,
    label: "Confirmed",
  },
  // Confirmed by service provider
  {
    value: EBookingStatusValue.confirmByBusiness,
    category: EBookingStatusCategory.Confirmed,
    label: "Confirmed",
  },

  // Checked in by user
  {
    value: EBookingStatusValue.checkIn,
    category: EBookingStatusCategory.CheckedIn,
    label: "Checked-in",
    timeline: "Checked-in",
  },

  // Checked in by service provider
  {
    value: EBookingStatusValue.checkInByBusiness,
    category: EBookingStatusCategory.CheckedIn,
    label: "Checked-in",
  },

  // Walk-in register
  {
    value: EBookingStatusValue.walkIn,
    category: EBookingStatusCategory.CheckedIn,
    label: "Walk-in",
  },

  // QR Code register
  {
    value: EBookingStatusValue.qrRegister,
    category: EBookingStatusCategory.CheckedIn,
    label: "Walk-in",
  },

  // Serving
  {
    value: EBookingStatusValue.serving,
    category: EBookingStatusCategory.Serving,
    label: "Serving",
  },

  // Completed
  {
    value: EBookingStatusValue.complete,
    category: EBookingStatusCategory.Completed,
    label: "Completed",
  },

  // User Ended
  {
    value: EBookingStatusValue.end,
    category: EBookingStatusCategory.Ended,
    label: "Ended",
  },

  // Uncompleted
  {
    value: EBookingStatusValue.uncomplete,
    category: EBookingStatusCategory.Ended,
    label: "Ended",
  },

  // Re-scheduled
  {
    value: EBookingStatusValue.reschedule,
    category: EBookingStatusCategory.NotAttend,
    label: "Rescheduled",
  },

  // Transferred
  {
    value: EBookingStatusValue.transfer,
    category: EBookingStatusCategory.Completed,
    label: "Transferred",
  },

  // Cancelled
  {
    value: EBookingStatusValue.cancel,
    category: EBookingStatusCategory.Uncompleted,
    label: "Cancelled",
  },

  // Absent
  {
    value: EBookingStatusValue.absent,
    category: EBookingStatusCategory.Uncompleted,
    label: "Absent",
  },

  // Cannot attend
  {
    value: EBookingStatusValue.notAttend,
    category: EBookingStatusCategory.NotAttend,
    label: "Not Attend",
  },

  // No show
  {
    value: EBookingStatusValue.noshow,
    category: EBookingStatusCategory.Uncompleted,
    label: "No show",
  },

  // Rejected by user
  {
    value: EBookingStatusValue.reject,
    category: EBookingStatusCategory.NotAttend,
    label: "Rejected",
  },
];

export default bookingStatus;
