import styles from "./AvatarPic.module.scss";
import icons from "../../../config/icons";
import classNames from "classnames";

interface AvatarPicProps {
  src?: string;
  role?: "personal" | "business";
  size?: "big" | "small" | "medium";
  name?: string;
  square?: boolean;
}

const AvatarPic: React.FC<AvatarPicProps> = ({
  role,
  size,
  name,
  src,
  square,
}) => {
  const defaultImage =
    role === "business" ? icons.iconBusiness : icons.iconPersonal;

  return (
    <div className={classNames(styles["pic"], size && styles[`size-${size}`])}>
      <img src={src ? src : defaultImage} alt={name ? name : role} />
    </div>
  );
};

export default AvatarPic;
