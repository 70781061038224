import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { personalURL } from "../../config/url";
import { IChangeEmailInputType } from "../../config/form/formChangeEmail";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useToast from "../../components/ui/toasts/useToast";
import PersonalChangeEmailVerify from "../../components/personal/PersonalChangeEmailVerify";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import PersonalChangeEmailForm from "../../components/personal/PersonalChangeEmailForm";

const PersonalChangeEmailPage: React.FC = () => {
  const navigate = useNavigate();
  const { notify } = useToast();
  const { clearUserState, clearTokenState, changeEmail } = useActions();
  const [dataChangeEmail, setDataChangeEmail] =
    useState<IChangeEmailInputType>();
  const [step, setStep] = useState(1);
  const [resError, setResError] = useState<string>();
  const { tokenId, success, error } = useTypedSelector(
    (state) => state.entities.user
  );
  const { tokenId: newTokenId } = useTypedSelector(
    (state) => state.entities.token
  );

  useDocumentTitle("Change Email Address");

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearUserState();
      navigate(personalURL.PersonalProfile);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setResError(error);
      clearUserState();
    }
  }, [error]);

  useEffect(() => {
    tokenId && setStep(2);
  }, [tokenId]);

  useEffect(() => {
    newTokenId && setResError("");
  }, [newTokenId]);

  useEffect(() => {
    return () => {
      clearUserState();
      clearTokenState();
    };
  }, []);

  const handeOnChangeEmail = (value: IChangeEmailInputType) => {
    setResError("");
    setDataChangeEmail(value);
    changeEmail(value);
  };

  const message = () => {
    if (step === 1)
      return "Please enter your new email. We will send you a code to confirm your new email";
    else
      return (
        <>
          Please enter the code send to your new email.{" "}
          <span className="text-semibold">{dataChangeEmail?.newEmail}</span>
        </>
      );
  };
  return (
    <LayoutCenterBox close>
      <h1 className="text-center">Change Email Address</h1>
      <p className="text-center">{message()}</p>
      <div className="msg-container">
        {resError && <div className="text-center error-msg">{resError}</div>}
      </div>
      {step === 1 && (
        <PersonalChangeEmailForm
          onChangeEmail={handeOnChangeEmail}
          setError={setResError}
        />
      )}
      {step === 2 && dataChangeEmail && (
        <PersonalChangeEmailVerify
          dataChangeEmail={dataChangeEmail}
          setStep={setStep}
        />
      )}
    </LayoutCenterBox>
  );
};

export default PersonalChangeEmailPage;
