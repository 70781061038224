import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessDataType } from "../../config/types/BusinessDataType";

interface businessState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting business data
  error?: string;
  lastFetch?: number;
  success: boolean;
  data?: IBusinessDataType;
  logoUpload?: { loading?: boolean; success?: boolean; error?: string };
  coverUpload?: { loading?: boolean; success?: boolean; error?: string };
}

const initialState: businessState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  lastFetch: undefined,
  success: false,
  data: undefined,
  logoUpload: undefined,
  coverUpload: undefined,
};

const slice = createSlice({
  name: "businessAdmin",
  initialState,
  reducers: {
    businessClearState: (business) => {
      business.loading = false;
      business.error = undefined;
      business.success = false;
      business.logoUpload = undefined;
    },
    businessRequest: (business) => {
      business.request = { loading: true };
    },
    businessReceived: (business, action: PayloadAction<IBusinessDataType>) => {
      business.lastFetch = Date.now();
      console.log("businessReceived", action.payload);
      business.data = action.payload;
      business.request = undefined;
    },
    businessRequestFailed: (business, action) => {
      business.request = { error: action.payload };
    },
    businessUpdating: (business) => {
      business.loading = true;
      business.error = undefined;
      business.success = false;
    },
    businessUpdated: (business, action: PayloadAction<IBusinessDataType>) => {
      console.log("businessUpdated", action.payload);
      business.data = action.payload;
      business.loading = false;
      business.success = true;
    },
    businessUpdateFailed: (business, action: PayloadAction<string>) => {
      business.error = action.payload;
    },
    businessLogoUploading: (business) => {
      business.logoUpload = { loading: true };
    },
    businessLogoUploaded: (
      business,
      action: PayloadAction<IBusinessDataType>
    ) => {
      business.data = action.payload;
      business.logoUpload = { success: true };
    },
    businessLogoUploadFailed: (business, action: PayloadAction<string>) => {
      business.logoUpload = { error: action.payload };
    },
    businessCoverUploading: (business) => {
      business.coverUpload = { loading: true };
    },
    businessCoverUploaded: (
      business,
      action: PayloadAction<IBusinessDataType>
    ) => {
      business.data = action.payload;
      business.coverUpload = { success: true };
    },
    businessCoverUploadFailed: (business, action: PayloadAction<string>) => {
      business.coverUpload = { error: action.payload };
    },
  },
});

export const {
  businessClearState,
  businessRequest,
  businessReceived,
  businessRequestFailed,
  businessUpdating,
  businessUpdated,
  businessUpdateFailed,
  businessLogoUploading,
  businessLogoUploaded,
  businessLogoUploadFailed,
  businessCoverUploading,
  businessCoverUploaded,
  businessCoverUploadFailed,
} = slice.actions;

export default slice.reducer;
