import * as yup from "yup";

export const InitialValues = {
  name: "",
  profession: 0,
  title: "",
  desc: "",
  imageUrl: "",
  hours: "",
  hoursOption: 1,
};

export const Validation = yup.object().shape({
  name: yup
    .string()
    .max(50, "Maximum 50 characters")
    .required("Please enter the service name"),
  profession: yup.number().moreThan(0, "Please select a profession"),
  title: yup.string(),
  desc: yup.string().max(300, "Maximum 300 characters"),
  hoursOption: yup
    .number()
    .required("Please select a Provider Available Period"),
  hours: yup.string().required("Provider Available Period cannot be empty"),
});

export interface IProviderInputType {
  name: string;
  profession: number;
  title: string;
  desc: string;
  imageUrl: string;
  hours: string;
  hoursOption: number;
}
