import { useState } from "react";
// import { RiDeleteBin6Line } from "react-icons/ri";
import Tab from "../ui/tab";
import TableRecord from "../common/TableRecord";
import { ModalBody, ModalFooter } from "../ui/modal";
import CustomerNotes from "../customer/CustomerNotes";
import BookingInfo from "./BookingInfo";
import BookingTimeline from "../booking/BookingTimeline";
// import { BookingDetailsType } from "./ModalBooking";
// import MoreButton from "../ui/MoreButton";
import { IBookingDataType } from "../../config/types/BookingDataType";
import BookingActionMenu, { EBookingActionMenuType } from "./BookingActionMenu";
import BookingCustomerInfo from "../businessBooking/BookingCustomerInfo";

export type NotesType = {
  subject: string;
  message: string;
  author: string;
  created: string;
};

interface DetailProps {
  data: IBookingDataType;
  onClose(): void;
  onAddNote(): void;
  onViewNote(note: any): void;
  activeTab?: number | null;
}

const BookingDetails: React.FC<DetailProps> = ({
  data,
  onClose,
  onAddNote,
  onViewNote,
  activeTab,
}) => {
  const [Active, setActive] = useState(activeTab ? activeTab : 0); //Selected nav tab on top
  // const record = getPastBookings();

  const Items = [
    {
      tabID: 0,
      name: "Booking",
      content: <BookingInfo data={data} />,
    },
    {
      tabID: 1,
      name: "Customer",
      content: <BookingCustomerInfo data={data} />,
    },
    // {
    //   tabID: 2,
    //   name: "Timeline",
    //   content: <BookingTimeline data={data} viewBy="business" />,
    // },
    // {
    //   tabID: 3,
    //   name: "Notes",
    //   content: (
    //     <CustomerNotes
    //       customerId={data.customer}
    //       onAddNote={onAddNote}
    //       onViewNote={onViewNote}
    //     />
    //   ),
    // },
    // {
    //   tabID: 4,
    //   name: "Record",
    //   content: (
    //     <>
    //       <h4 className="mb-10">Booking Record</h4>
    //       {/* <TableRecord data={record} /> */}
    //     </>
    //   ),
    // },
  ];

  // const menuList = [
  //   {
  //     name: "Remove",
  //     icon: <RiDeleteBin6Line />,
  //     desc: "Remove from your friend list",
  //     onClick: () => console.log("Remove"),
  //   },
  // ];

  return (
    <>
      <ModalBody>
        <Tab
          items={Items}
          active={Active}
          type="vertical"
          onTabClick={(id: number) => setActive(id)}
        >
          {Items[Active]["content"]}
        </Tab>
      </ModalBody>
      <ModalFooter>
        <BookingActionMenu
          type={EBookingActionMenuType.Modal}
          data={data}
          onClose={onClose}
        />
      </ModalFooter>
    </>
  );
};

export default BookingDetails;
