import { AppButton, AppForm } from "../../components/form";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import VerificationCode from "../../components/form/VerificationCode";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  InitialValues,
  Validation,
  IRegisterPinInputType,
} from "../../config/form/formRegisterPin";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useRegisterStep from "./useRegisterStep";
import IconPin from "../../assets/icons/Pin.svg";
import { EBtnCategory } from "../../components/form/AppButton";

const AuthPinNumberPage: React.FC = () => {
  useDocumentTitle("PIN Number");
  const { registerPin } = useActions();
  const { loading, error } = useTypedSelector(
    (state) => state.entities.register
  );
  useRegisterStep(1);
  const initialValues: IRegisterPinInputType = InitialValues;
  return (
    <LayoutCenterBox>
      <div className="mb-30 text-center">
        <img src={IconPin} width="200" height="200" />
        <h1>PIN Number</h1>
        <p>
          PIN number will be used to verify your identity for your booking
          during the meet-up.
        </p>
        {error && <p className="error-msg">{error}</p>}
      </div>

      <AppForm
        initialValues={initialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          registerPin(values);
          actions.setSubmitting(false);
        }}
      >
        <div className="text-center">
          Enter any <b>4</b> digits that is easy to remember.
        </div>
        <VerificationCode size={4} name="inputCode" type="number" />
        <div className="mb-30 mt-5 text-center desc">
          (PIN Number can be last 4 digit of your NRIC)
        </div>
        <AppButton
          type="submit"
          btnBlock
          category={EBtnCategory.Primary}
          size="large"
          loading={loading}
        >
          Continue (Step 4 of 5)
        </AppButton>
      </AppForm>
    </LayoutCenterBox>
  );
};

export default AuthPinNumberPage;
