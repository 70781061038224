import { useEffect } from "react";
import { ModalBox, ModalHeader } from "../ui/modal";
import useMeetingPoint from "./useMeetingPoint";
import { MeetingPointType } from "../../config/types/MeetingPointType";
import MeetingPointForm from "./MeetingPointForm";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";

interface ModalMeetingPointProps {
  data: MeetingPointType | "new";
  onClose(): void;
}

const ModalMeetingPoint: React.FC<ModalMeetingPointProps> = ({
  data,
  onClose,
}) => {
  const { getMeetingPointName } = useMeetingPoint();
  const { add, update } = useTypedSelector(
    (state) => state.entities.meetingPoint
  );
  const { clearMeetingPointState } = useActions();
  const { notify } = useToast();

  useEffect(() => {
    if (add) {
      if (add.success) {
        notify("success", "New meeting point has been created");
        clearMeetingPointState();
        onClose();
      } else if (add.error) {
        notify("error", "Error creating meeting point. Please try again");
        clearMeetingPointState();
      }
    } else if (update) {
      if (update.success) {
        notify("success", "Meeting point has been updated");
        clearMeetingPointState();
        onClose();
      } else if (update.error) {
        notify("error", "Error saving changes. Please try again");
        clearMeetingPointState();
      }
    }
  }, [add, update]);

  const title =
    data === "new"
      ? "New Meeting Point"
      : `${getMeetingPointName(data.type)} ${data.label}`;

  return (
    <ModalBox onClose={onClose} type="personal" overlayClose={true}>
      <ModalHeader title={title} />
      <MeetingPointForm data={data} onClose={onClose} />
    </ModalBox>
  );
};

export default ModalMeetingPoint;
