import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBookmarkDataType } from "../../config/types/BookmarkDataType";

interface IBookmarkState {
	loading: boolean;
	request?: { loading?: boolean; error?: string }; // getting bookmark data
	success: boolean;
	error?: string;
	lastFetch?: Date | number;
	data: [] | IBookmarkDataType[];
}

const initialState: IBookmarkState = {
	loading: false,
	request: { loading: true },
	success: false,
	error: undefined,
	lastFetch: undefined,
	data: [],
};

const slice = createSlice({
	name: "bookmark",
	initialState,
	reducers: {
		bookmarkClearState: (bookmark) => {
			bookmark.loading = false;
			bookmark.success = false;
			bookmark.error = undefined;
		},
		bookmarkRequest: (bookmark) => {
			bookmark.request = { loading: true };
		},
		bookmarkReceived: (
			bookmark,
			action: PayloadAction<IBookmarkDataType[]>
		) => {
			console.log("bookmarkReceived", action.payload);
			bookmark.data = action.payload;
			bookmark.request = undefined;
			bookmark.lastFetch = Date.now();
		},
		bookmarkRequestFailed: (bookmark, action: PayloadAction<string>) => {
			bookmark.request = { error: action.payload };
		},
		bookmarkRemoving: (bookmark, action: PayloadAction<string>) => {
			console.log("bookmarkRemoving", action.payload);
			bookmark.loading = true;
			bookmark.data = bookmark.data?.filter(
				(item) => item._id !== action.payload
			);
		},
		bookmarkRemoved: (bookmark) => {
			console.log("bookmarkRemoved");
			bookmark.loading = false;
			bookmark.success = true;
		},
		bookmarkRemoveFailed: (
			bookmark,
			action: PayloadAction<{ error: string; actionData: IBookmarkDataType[] }>
		) => {
			console.log("bookmarkRemoveFailed", action.payload);
			bookmark.loading = false;
			bookmark.data = action.payload.actionData;
			bookmark.error = action.payload.error;
		},
	},
});

export const {
	bookmarkRequest,
	bookmarkReceived,
	bookmarkRequestFailed,
	bookmarkRemoving,
	bookmarkRemoved,
	bookmarkRemoveFailed,
	bookmarkClearState,
} = slice.actions;

export default slice.reducer;
