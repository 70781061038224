import { Navigate, Route } from "react-router-dom";
import { businessURL, businessProfileURL } from "../config/url";
import BusinessInfoPage from "../pages/businessProfile/BusinessInfoPage";
import BusinessContactPage from "../pages/businessProfile/BusinessContactPage";
import BusinessHoursPage from "../pages/businessProfile/BusinessHoursPage";
import BusinessHolidaysPage from "../pages/businessProfile/BusinessHolidaysPage";
import BusinessLocationsPage from "../pages/businessProfile/BusinessLocationsPage";
import BusinessStaffPage from "../pages/businessProfile/BusinessStaffPage";
import BusinessRouter from "./BusinessRouter";
import BusinessQRCodePage from "../pages/businessProfile/BusinessQRCodePage";

const businessProfileRoutes = () => {
  return (
    <>
      <Route path={businessURL.BusinessProfile} element={<BusinessRouter />}>
        <Route
          path={businessProfileURL.Staff}
          element={<BusinessStaffPage />}
        />
        <Route
          path={businessProfileURL.Holidays}
          element={<BusinessHolidaysPage />}
        />
        <Route
          path={businessProfileURL.Locations}
          element={<BusinessLocationsPage />}
        />
        <Route
          path={businessProfileURL.Hours}
          element={<BusinessHoursPage />}
        />
        <Route
          path={businessProfileURL.Contact}
          element={<BusinessContactPage />}
        />
        <Route
          path={businessProfileURL.QRCode}
          element={<BusinessQRCodePage />}
        />
        <Route path={businessProfileURL.Info} element={<BusinessInfoPage />} />
        <Route
          index
          element={<Navigate to={businessProfileURL.Info} replace />}
        />
        {/* Business Reports *
          <Route
            path={`${businessURL.BusinessReport}/:type/:id/:date`}
            element={<BusinessReportDailyPage />}
          />
          <Route
            path={`${businessURL.BusinessReport}/:type/:id`}
            element={<BusinessReportMonthlyPage />}
          />
          <Route
            path={`${businessURL.BusinessReport}/:type`}
            element={<BusinessReportPage />}
          />
          <Route
            path={businessURL.BusinessReport}
            element={<BusinessReportPage />}
  />*/}
      </Route>
    </>
  );
};

export default businessProfileRoutes;
