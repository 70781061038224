import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { authURL } from "../../config/url";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";

const AuthLogoutPage: React.FC<{}> = () => {
  const { clearAuthState, logout } = useActions();
  const navigate = useNavigate();
  const { user } = useTypedSelector((state) => state.entities.auth);
  useEffect(() => {
    logout();
  }, []);
  useEffect(() => {
    if (!user) {
      console.log("success logout");
      clearAuthState();
      navigate(authURL.Login);
    }
  }, [user]);
  return (
    <LayoutCenterBox>
      <h3 className="text-center">Loading</h3>
    </LayoutCenterBox>
  );
};

export default AuthLogoutPage;
