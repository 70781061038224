// TODO: temporary not in use
import { useState, useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Calendar, {
  CalendarDataRangeType,
  CalendarDataType,
} from "../../components/ui/calendar";
import { getBookingByMonth } from "../../data/dataAppts";
import styles from "./PersonalCalendarPage.module.scss";
import SwitchViewDropdown from "../../components/personalBooking/SwitchViewDropdown";
import Header from "../../components/layout/Header";
import ModalPersonalBooking from "../../components/personalBooking/ModalPersonalBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";

const PersonalCalendarPage: React.FC = () => {
  const [filteredData, setFilteredData] = useState<CalendarDataType[]>([]);
  const [bookingsData, setBookingsData] = useState<
    (IBookingDataType & IMeetupDataType)[]
  >([]);
  const [selectedAppt, setSelectedAppt] = useState<string | null>();

  useEffect(() => {
    const today = new Date();
    const bookings = getBookingByMonth(today.getMonth() + 1, today.getFullYear);
    // setBookingsData(bookings);
    handleData(bookings);
  }, []);

  const handleData = (bookings: any[]) => {
    if (bookings && bookings.length > 0) {
      const bookingsData: CalendarDataType[] = [];
      bookings.forEach((el) => {
        bookingsData.push({
          name: el.service.name,
          day: el.apptDay,
          id: el.id,
          status: el.status,
        });
      });
      setFilteredData(bookingsData);
    } else {
      setFilteredData([]);
    }
  };
  const handleDataRange = (range: CalendarDataRangeType) => {
    const bookings = getBookingByMonth(range.month, range.year);
    console.log("handleDataRange", bookings);
    // setBookingsData(bookings);
    handleData(bookings);
  };
  // const handleBooking = (id: string) => {
  //   console.log("id", id);
  //   const selected = bookingsData.find((appt) => appt._id === id);
  //   if (selected) {
  //     setSelectedAppt(selected);
  //   }
  // };
  return (
    <PageWithAside role="personal">
      <div className="wrapper">
        <Header
          pageTitle="Bookings"
          pageDesc="List of personal or businesses that have been added to your bookmark"
          role="personal"
        />
        <SwitchViewDropdown label="Calendar View" />
        <div className={styles["calendar"]}>
          <Calendar
            data={filteredData}
            type="full"
            dataRange={handleDataRange}
            onClickBooking={(id) => setSelectedAppt(id)}
          />
        </div>
        {selectedAppt && (
          <ModalPersonalBooking
            onClose={() => setSelectedAppt(null)}
            // data={selectedAppt}
            id={selectedAppt}
          />
        )}
      </div>
    </PageWithAside>
  );
};
export default PersonalCalendarPage;
