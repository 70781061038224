import * as yup from "yup";

export const InitialValues = {
	message: "",
};

// Validation
export const Validation = yup.object().shape({
	message: yup.string().max(300, "Maximum 300 characters"),
});
