const appts = [
  {
    _id: "booking1",
    apptType: "booking",
    access: ["user1", "user2"],
    meetDateTime: new Date("2022-02-17T03:00:00.000Z"),
    meetDateTimestamp: 1645066800000,
    meetDateTimeData: {
      year: 2022,
      month: 2,
      date: 17,
      day: 4,
      hour: 11,
      minute: 0,
      _id: "620c7a8380acb9370dab6119",
    },
    createDateTime: new Date("2022-02-16T04:15:59.178Z"),
    createDateTimestamp: 1644984959178,
    createDateTimeData: {
      year: 2022,
      month: 2,
      date: 16,
      day: 3,
      hour: 12,
      minute: 15,
      _id: "620c7a8380acb9370dab611a",
    },
    bookingType: 102,
    bookingStatus: 111,
    creator: "user1",
    creatorData: {
      displayName: "CK One",
      email: "ck@simplicitic.com",
      profilePic: "",
      _id: "620c7a8380acb9370dab611b",
    },
    unregisteredUser: [],
    timeline: [
      {
        bookingStatus: 111,
        creator: "user1",
        creatorName: "CK One",
        date: new Date("2022-02-16T04:16:03.165Z"),
        subject: "Booked",
        desc: "CK One has made the booking",
        priority: 2,
      },
      {
        bookingStatus: 202,
        creator: "user1",
        creatorName: "Gangnam style",
        date: new Date("2022-02-16T04:16:03.165Z"),
        subject: "Approved",
        desc: "Gangname Style has approved the booking",
        priority: 2,
      },
    ],
    note: "test",
    bookingNo: 1,
    bookingTypeId: "svc-N0I9hAGuU4N2EVJ_xrmNH",
    business: "business1",
    businessData: {
      name: "CK One Business",
      businessUrl: "ckonebusines",
      industry: 1,
      profilePic: "",
      _id: "620c7a8380acb9370dab611c",
    },
    businessUid: "biz-iWNKMaNrtQpiASCoS68MS",
    customer: "620b6e710e84baca4c9eb4ab",
    customerData: {
      pin: "9876",
      email: "ck@simplicitic.com",
      gender: "1",
      displayName: "CK One",
      _id: "620c7a8380acb9370dab611d",
    },
    customerUser: "user1",
    service: "service1",
    serviceData: {
      name: "Hair Cut",
      label: "A",
      desc: "Hair cut service",
      duration: 15,
      bookingCapacity: 5,
      slotInterval: 15,
      _id: "620c7a8380acb9370dab611e",
    },
    serviceUid: "svc-N0I9hAGuU4N2EVJ_xrmNH",
    location: "location1",
    locationData: {
      name: "CK One Business",
      address: "2 Lintang Hilir 3, Taman Gembira",
      address2: "",
      city: "Klang",
      postcode: "41100",
      state: "Wilayah Persekutuan (Labuan)",
      country: "Malaysia",
      email: "chawkyan@gmail.com",
      phone: "0123456789",
      hours:
        '[["Off","Off"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","12:00"],["Off","Off"]]',
      website: "https://www.bygaquvupofomir.co.uk",
      _id: "620c7a8380acb9370dab611f",
    },
    locationUid: "loc-ogMYqgR4BbsAY-VHCiYkT",
    bookingUid: "bkg-ObVrTjgsQy7WhGwlgT2Nj",
  },
  {
    _id: "booking2",
    apptType: "booking",
    access: ["user1", "user2"],
    meetDateTime: new Date("2022-02-17T03:00:00.000Z"),
    meetDateTimestamp: 1645066800000,
    meetDateTimeData: {
      year: 2022,
      month: 2,
      date: 17,
      day: 4,
      hour: 11,
      minute: 0,
      _id: "620c7c2480acb9370dab6130",
    },
    createDateTime: new Date("2022-02-16T04:15:59.178Z"),
    createDateTimestamp: 1644984959178,
    createDateTimeData: {
      year: 2022,
      month: 2,
      date: 16,
      day: 3,
      hour: 12,
      minute: 15,
      _id: "620c7c2480acb9370dab6131",
    },
    bookingType: 102,
    bookingStatus: 111,
    creator: "user1",
    creatorData: {
      displayName: "CK One",
      email: "ck@simplicitic.com",
      profilePic: "",
      _id: "620c7c2480acb9370dab6132",
    },
    unregisteredUser: [],
    timeline: [
      {
        bookingStatus: 111,
        creator: "user1",
        creatorName: "CK One",
        date: new Date("2022-02-16T04:16:03.165Z"),
        subject: "Booked",
        desc: "CK One has made the booking",
        priority: 2,
      },
    ],
    note: "test",
    bookingNo: 2,
    bookingTypeId: "svc-N0I9hAGuU4N2EVJ_xrmNH",
    business: "business1",
    businessData: {
      name: "CK One Business",
      businessUrl: "ckonebusines",
      industry: 1,
      profilePic: "",
      _id: "620c7c2480acb9370dab6133",
    },
    businessUid: "biz-iWNKMaNrtQpiASCoS68MS",
    customer: "620b6e710e84baca4c9eb4ab",
    customerData: {
      pin: "9876",
      email: "ck@simplicitic.com",
      gender: "1",
      displayName: "CK One",
      _id: "620c7c2480acb9370dab6134",
    },
    customerUser: "user1",
    service: "service1",
    serviceData: {
      name: "Hair Cut",
      label: "A",
      desc: "Hair cut service",
      duration: 15,
      bookingCapacity: 5,
      slotInterval: 15,
      _id: "620c7c2480acb9370dab6135",
    },
    serviceUid: "svc-N0I9hAGuU4N2EVJ_xrmNH",
    location: "location1",
    locationData: {
      name: "CK One Business",
      address: "2 Lintang Hilir 3, Taman Gembira",
      address2: "",
      city: "Klang",
      postcode: "41100",
      state: "Wilayah Persekutuan (Labuan)",
      country: "Malaysia",
      email: "chawkyan@gmail.com",
      phone: "0123456789",
      hours:
        '[["Off","Off"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","12:00"],["Off","Off"]]',
      website: "https://www.bygaquvupofomir.co.uk",
      _id: "620c7c2480acb9370dab6136",
    },
    locationUid: "loc-ogMYqgR4BbsAY-VHCiYkT",
    bookingUid: "bkg-ES0y_R4v2LOcDSsOx8eFm",
  },
  {
    _id: "booking3",
    apptType: "meetup",
    access: ["user1", "user2"],
    meetDateTime: new Date("2022-02-20T01:00:00.000Z"),
    meetDateTimeData: {
      year: 2022,
      month: 2,
      date: 17,
      day: 4,
      hour: 11,
      minute: 0,
      _id: "620c7c2480acb9370dab6130",
    },
    createDateTime: new Date("2022-02-16T04:15:59.178Z"),
    createDateTimestamp: 1644984959178,
    createDateTimeData: {
      year: 2022,
      month: 2,
      date: 16,
      day: 3,
      hour: 12,
      minute: 15,
      _id: "620c7c2480acb9370dab6131",
    },
    bookingType: 1,
    bookingStatus: 211,
    creator: "user1",
    creatorData: {
      displayName: "CK One",
      email: "ck@simplicitic.com",
      profilePic: "",
      _id: "620c7c2480acb9370dab6132",
    },
    unregisteredUser: [],
    timeline: [
      {
        bookingStatus: 101,
        creator: "user3",
        creatorName: "Amal Wood",
        date: new Date("2022-02-14T06:35:45.264Z"),
        subject: "Booked",
        desc: "CK One has made the booking",
        priority: 2,
      },
      {
        bookingStatus: 211,
        creator: "user1",
        creatorName: "CK One",
        date: new Date("2022-02-14T06:36:11.528Z"),
        subject: "Confirmed",
        desc: "CK One has confirmed the booking",
        priority: 2,
      },
    ],
    note: "test note",
    participants: ["user1", "user3"],
    participantsData: [
      {
        userId: "user3",
        email: "ck3@simplicitic.com",
        displayName: "Amal Wood",
        bookingStatus: 111,
        profilePic: "",
        isHost: true,
      },
      {
        email: "ck@simplicitic.com",
        userId: "user1",
        bookingStatus: 211,
      },
    ],
    subject: "Meetup with CK 1",
    venue: "test location",
  },
  {
    _id: "booking4",
    apptType: "meetup",
    access: ["user1", "user3"],
    meetDateTime: new Date("2022-02-21T01:00:00.000Z"),
    meetDateTimestamp: 1645405200000,
    meetDateTimeData: {
      year: 2022,
      month: 2,
      date: 17,
      day: 4,
      hour: 11,
      minute: 0,
    },
    createDateTime: new Date("2022-02-16T04:15:59.178Z"),
    createDateTimestamp: 1644984959178,
    createDateTimeData: {
      year: 2022,
      month: 2,
      date: 16,
      day: 3,
      hour: 12,
      minute: 15,
    },
    bookingType: 1,
    bookingStatus: 501,
    creator: "user1",
    creatorData: {
      displayName: "CK One",
      email: "ck@simplicitic.com",
      profilePic: "",
    },
    unregisteredUser: [],
    timeline: [
      {
        bookingStatus: 101,
        creator: "user1",
        creatorName: "CK One",
        date: "2022-02-14T06:41:11.878Z",
        subject: "Booked",
        desc: "CK One has made the booking",
        priority: 2,
      },
      {
        bookingStatus: 501,
        creator: "user1",
        creatorName: "CK One",
        date: "2022-02-14T07:03:24.884Z",
        subject: "Cancelled",
        desc: "CK One has cancelled the booking",
        priority: 2,
      },
    ],
    note: "test location",
    participants: ["user1", "user3"],
    participantsData: [
      {
        userId: "user1",
        email: "ck@simplicitic.com",
        displayName: "CK One",
        bookingStatus: 111,
        profilePic: "",
        isHost: true,
      },
      {
        email: "ck3@simplicitic.com",
        userId: "user3",
        bookingStatus: 102,
      },
    ],
    subject: "Second meetup",
    venue: "test",
  },
];

export function getBookings() {
  return appts;
}

export function getPastBookings() {
  return [appts[3], appts[2], appts[1]];
  // return [];
}

export function getUpcomingBookings() {
  return [appts[1], appts[2], appts[3]];
  // return [];
}

export function getTodayBookings() {
  return [appts[0], appts[1], appts[2]];
  // return [];
}

export function getBooking(ids) {
  return appts.filter((i) => ids.includes(i._id));
}

export function getOneBooking(id) {
  const booking = appts.find((m) => m._id === id);
  return booking ? booking : appts[0];
}

export function getServingBookings() {
  return [appts[0], appts[1]];
}

export function getBookingByMonth(month, year) {
  return appts.filter((m) => m.apptMonth === month && m.apptYear === year);
}
export function getReports() {
  return [appts[0], appts[1]];
}

export function getBusinessRecord(userId, businessId) {
  console.log("getBusinessRecord", userId, businessId);
  const records = appts.filter(
    (item) => item.customerId === userId && item.businessId === businessId
  );

  return records;
}

export function getCustomerRecord(customerId) {
  console.log("getCustomerRecord", customerId);
  const records = appts.filter((item) => item.customerId === customerId);

  return records;
  // return [];
}

export function getBookingsPersonal(user) {
  // return appts;
  const records = []; // Can ignore
  appts.forEach((booking) => {
    const exist =
      booking.participantIds && booking.participantIds.includes(user);
    exist && records.push(booking);
  });
  return records;
  // return [];
}

export function getFriendBooking(ids) {
  return appts.filter((i) => ids.includes(i._id));
}

export function getFriendsRecord(users) {
  // const bookings = getBookingsPersonal("user1");
  const records = []; // Can ignore
  appts.forEach((booking) => {
    const exist =
      booking.participants &&
      booking.participants.every((val) => users.includes(val));
    exist && records.push(booking);
  });
  return records;
}
