import { useEffect } from "react";
import TableRecord from "../common/TableRecord";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import Loader from "../ui/Loader";

interface FriendRecordProps {
  id: string;
}

const FriendRecord: React.FC<FriendRecordProps> = ({ id }) => {
  const { getFriendRecord, clearFriendState } = useActions();
  const { record, loading } = useTypedSelector(
    (state) => state.entities.friend
  );
  const { data: userData } = useTypedSelector((state) => state.entities.user);

  useEffect(() => {
    return () => {
      clearFriendState();
    };
  }, []);

  useEffect(() => {
    userData && getFriendRecord(id);
  }, [id, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader />;
  return (
    <>
      <div className="h5 mb-5">Meetup</div>
      {record.length > 0 ? (
        <TableRecord data={record} />
      ) : (
        <div className="text-italic">No Record</div>
      )}
    </>
  );
};

export default FriendRecord;
