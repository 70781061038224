import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config/global";

interface IAuthState {
  user: boolean;
  business: boolean;
  loading: boolean;
  error?: string;
  lastFetch?: Date | number;
  successed: boolean;
  registerStep?: number;
  stateLoading?: boolean;
  stateError?: string;
  passwordToken?: { tokenId: string; email: string };
  passwordReset?: boolean;
}

const initialState: IAuthState = {
  user: false, // check whtether user has logged in
  business: false, // check whtether user has business
  loading: false,
  error: undefined,
  lastFetch: undefined,
  successed: false,
  registerStep: undefined,
  stateLoading: true, // checking user state
  stateError: undefined,
  passwordToken: undefined,
  passwordReset: undefined,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authClearState: (auth) => {
      auth.loading = false;
      auth.error = undefined;
      auth.successed = false;
      auth.stateLoading = false;
      auth.stateError = undefined;
      auth.passwordToken = undefined;
      auth.passwordReset = undefined;
      console.log("authClearState");
    },

    authRequest: (auth) => {
      auth.loading = true;
    },
    authRequestFailed: (auth, action: PayloadAction<string>) => {
      console.log("authRequestFailed", action.payload);
      auth.loading = false;
      auth.error = action.payload;
    },

    authLogin: (
      auth,
      action: PayloadAction<{
        registerStep: number;
        business?: string;
        userId: string;
      }>
    ) => {
      console.log("logged in", action.payload);
      const { registerStep, business } = action.payload;
      auth.registerStep = registerStep;
      auth.user = true;
      auth.business = business ? true : false;
      auth.loading = false;
    },
    authLogout: (auth) => {
      console.log("authLogout");
      auth.user = false;
      auth.stateLoading = false;
    },
    authForgotPwd: (
      auth,
      action: PayloadAction<{
        data: { tokenId: string };
        actionData: { email: string };
      }>
    ) => {
      console.log("authForgotPwd", action.payload.data);
      auth.loading = false;
      auth.passwordToken = {
        tokenId: !config.localENV ? action.payload.data.tokenId : "1234",
        email: action.payload.actionData.email,
      };
      auth.lastFetch = Date.now();
    },
    authResetPwd: (auth) => {
      auth.loading = false;
      auth.error = undefined;
      auth.passwordReset = true;
    },
    authStateRequest: (auth) => {
      auth.stateLoading = true;
    },
    authStateReceived: (
      auth,
      action: PayloadAction<{
        registerStep: number;
        business?: string;
        userId: string;
      }>
    ) => {
      console.log("authStateReceived", action.payload);
      if (config.localENV) auth.user = true;
      else if (action.payload) {
        const { registerStep, business } = action.payload;
        auth.user = true;
        auth.business = business ? true : false;
        auth.registerStep = registerStep;
      }
      // auth.stateLoading = action.payload.registerStep === 3 ? false : true;
      auth.stateLoading = false;
    },
    authStateRequestFailed: (auth, action: PayloadAction<string>) => {
      console.log("authStateRequestFailed ===========", action.payload);
      auth.stateLoading = false;
      // auth.stateError = action.payload;
    },
    authRefreshToken: (auth, action: PayloadAction<string>) => {},
    authBusinessCreated: (auth) => {
      auth.business = true;
    },
    authDeleteAccount: (auth) => {
      console.log("authDeleteAccount");
      auth.user = false;
      auth.loading = false;
    },
  },
});

export const {
  authLogin,
  authLogout,
  authForgotPwd,
  authResetPwd,
  authClearState,

  authRequest,
  authRequestFailed,
  authStateRequest,
  authStateReceived,
  authStateRequestFailed,
  authRefreshToken,
  authBusinessCreated,
  authDeleteAccount,
} = slice.actions;

export default slice.reducer;
