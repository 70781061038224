import styles from "./HomeUpcomingTable.module.scss";
import {
  ApptTime,
  ApptDateCalendar,
  ApptDay,
} from "../appointment/ApptDateTime";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import Table from "../ui/table";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface InputProps {
  data: (IMeetupDataType & IBookingDataType)[];
  onApptView(id: string): void;
}

const HomeUpcomingTable: React.FC<InputProps> = ({ data, onApptView }) => {
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const columns = [
    {
      label: "Date",
      name: "date",
      colWidth: "50px",
      cellClass: styles["col-date"],
      content: (appt: { meetDateTime: Date }) => (
        <ApptDateCalendar date={appt.meetDateTime} />
      ),
    },
    {
      label: "Time",
      name: "time",
      colWidth: "70px",
      cellClass: styles["col-time"],
      content: (appt: { meetDateTime: Date }) => (
        <>
          <ApptTime time={appt.meetDateTime} />
          <div className="desc">
            <ApptDay day={appt.meetDateTime} />
          </div>
        </>
      ),
    },
    {
      label: "Name",
      name: "business",
      colWidth: "40%",
      content: (appt: {
        businessData: { name: string };
        locationData: { city: string };
        subject: string;
        participants: string[];
      }) => (
        <>
          {appt.businessData ? (
            <>
              <div className="h5 text-wrap">{appt.businessData.name}</div>
              <span className="desc">{appt.locationData.city}</span>
            </>
          ) : (
            <>
              <div className="h5 text-wrap">{appt.subject}</div>
              {/* <span className="desc">
                {appt.participants.length} participants
              </span> */}
            </>
          )}
        </>
      ),
    },
    {
      label: "Services",
      name: "services",
      colWidth: "30%",
      cellClass: styles["col-services"],
      content: (appt: { serviceData: { name: string }; subject: string }) => (
        <div className="h5 text-wrap">
          {appt.serviceData && appt.serviceData.name}
          {appt.subject && <>Personal Meetup</>}
        </div>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      className={styles["table"]}
      noHeader
      itemClick={(appt: { _id: string }) => onApptView(appt._id)}
    />
  );
};

export default HomeUpcomingTable;
