import { getDate } from "../../utils/datetime";
import styles from "./HolidayDetails.module.scss";

interface DetailsProps {
  date: Date | Date[];
}

const HolidayDetails: React.FC<DetailsProps> = ({ date }) => {
  return (
    <div className={styles["holiday-details-list"]}>
      <h4 className="mb-5">Date Selected:</h4>
      {Array.isArray(date) ? (
        date.map((val, index) => {
          return <div key={index}>{getDate(val, "DD MMMM YYYY")}</div>;
        })
      ) : (
        <div>{getDate(date, "DD MMMM YYYY")}</div>
      )}
    </div>
  );
};

export default HolidayDetails;
