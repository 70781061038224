import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
// import PhoneInput from "react-phone-input-2";
import InputContainer from "./InputContainer";
import "./AppPhoneInput.scss";
// import "react-phone-input-2/lib/bootstrap.css";

interface InputProps {
  name: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  inputWidth?: string;
  disabled?: boolean;
}

interface FormikContextProps {
  [key: string]: string;
}

const preferredCountries = ["my", "gb", "cn"]; //set country on top of the selection
// const onlyCountries = ["my", "gb", "cn"]; //only country in array will show in selection

const AppPhoneInput: React.FC<InputProps> = ({
  name,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  inputWidth,
  disabled,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    //auto detect location
    fetch("https://extreme-ip-lookup.com/json/")
      .then((res) => res.json())
      .then((response) => {
        // console.log("This is COunty: ", response);
        setCountryCode(response.countryCode);
      })
      .catch(() => {
        console.log("Fail to request location ");
      });
    //auto detect location end
  }, []);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div style={{ width: inputWidth ? inputWidth : "100%" }}>
        {/* <PhoneInput
          countryCodeEditable={false}
          country={countryCode.toLowerCase()}
          //   country="my"
          value={values[name]}
          onChange={(val) => setFieldValue(name, val)}
          //   onChange={handleChange}
          inputProps={{
            name: name,
            autoComplete: "off",
          }}
          placeholder="+60 12 3456-789"
          preferredCountries={preferredCountries}
          //   onlyCountries={onlyCountries}
          enableAreaCodes={true}
          disabled={disabled ? true : false}
        /> */}
      </div>
    </InputContainer>
  );
};

export default AppPhoneInput;
