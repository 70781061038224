import React, { useState } from "react";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { ModalBody, ModalFooter, ModalHeader } from "../ui/modal";
import Tab from "../ui/tab";
import usePersonalBooking from "./usePersonalBooking";
import usePersonalBookingActions from "./usePersonalBookingActions";

interface IPersonalBookingContent {
  onClose(): void;
  data: IBookingDataType;
}

const PersonalBookingContent: React.FC<IPersonalBookingContent> = ({
  data,
  onClose,
}) => {
  const [Active, setActive] = useState(0);

  const { title, items, label } = usePersonalBooking(data);
  const { modalButton } = usePersonalBookingActions(data);
  return (
    <>
      <ModalHeader label={label} title={title} />
      <ModalBody>
        <Tab
          items={items}
          active={Active}
          type="vertical"
          onTabClick={(id) => setActive(id)}
        >
          {items[Active]["content"]}
        </Tab>
      </ModalBody>
      <ModalFooter>{modalButton(onClose)}</ModalFooter>
    </>
  );
};

export default PersonalBookingContent;
