import styles from "./BusinessServingAllPage.module.scss";
import { useParams } from "react-router-dom";
import PageDisplay from "../../components/layout/PageDisplay";
import useBusinessServingAllPage, {
  WaitingListType,
} from "./useBusinessServingAllPage";
import Loader from "../../components/ui/Loader";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useMeetingPoint from "../../components/businessTools/useMeetingPoint";

const BusinessServingAllPage: React.FC = () => {
  const { type } = useParams() as {
    type?: "services" | "resources" | "providers";
  };
  const {
    loading,
    business,
    servingList,
    serviceList,
    resourceList,
    providerList,
  } = useBusinessServingAllPage(type);
  useDocumentTitle("Now Serving");
  const { getMeetingPointName } = useMeetingPoint();

  if (loading) return <Loader />;

  const waitingList = [...serviceList, ...resourceList, ...providerList];

  const listView = (list: WaitingListType[]) => {
    return (
      list.length > 0 &&
      list.map((item, index) => {
        return (
          <li
            key={index}
            className={index % 2 === 0 ? styles["alternative"] : ""}
          >
            <div className="h4">{item.name}</div>
            <div className="h4 color-secondary">{item.waiting}</div>
          </li>
        );
      })
    );
  };

  return (
    <PageDisplay title={business?.name}>
      <div className={styles["container"]}>
        {servingList.length > 1 && (
          <div className={styles["serving-list"]}>
            <h5>Serving</h5>
            <ul>
              {servingList.map((item, index) => {
                if (index === 0) return;
                return (
                  <li
                    key={index}
                    className={index % 2 === 0 ? styles["alternative"] : ""}
                  >
                    <div className="h4">{item.queueNo}</div>
                    <div className="h4 color-secondary">
                      {getMeetingPointName(item.meetingPoint.type)}{" "}
                      {item.meetingPoint.label}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {servingList.length > 0 && (
          <div className={styles["main"]}>
            <div className={styles["wrapper"]}>
              <div className={styles["number"]}>{servingList[0].queueNo}</div>
              <div className={styles["room"]}>
                {getMeetingPointName(servingList[0].meetingPoint.type)}{" "}
                {servingList[0].meetingPoint.label}
              </div>
            </div>
          </div>
        )}
        {waitingList.length > 0 && (
          <div className={styles["waiting-list"]}>
            <h5>Waiting</h5>
            <ul>
              {/* TODO: split into different list for long listing */}
              {(!type || type === "services") && listView(serviceList)}
              {(!type || type === "resources") && listView(resourceList)}
              {(!type || type === "providers") && listView(providerList)}
            </ul>
          </div>
        )}
      </div>
    </PageDisplay>
  );
};

export default BusinessServingAllPage;
