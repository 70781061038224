import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import BusinessToday from "../../components/businessBooking/BusinessToday";
import Loader from "../../components/ui/Loader";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useBookingStatusResponse from "../../components/businessBooking/useBookingStatusResponse";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const BusinessTodayPage: React.FC = () => {
  const { getBusinessTodayBooking } = useActions();
  const { request, today } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  useBookingStatusResponse();

  useEffect(() => {
    getBusinessTodayBooking();
  }, []);
  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Today Bookings"
          pageDesc="List of bookings for today you can start serving the client."
          role="business"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && today && <BusinessToday />}
        <MenuCreateNew role="business" />
      </div>
    </PageWithAside>
  );
};

export default BusinessTodayPage;
