import { AppInput } from "../form";

const labelWidth = "90px";

const CreateBookingNewCustomer = () => {
  return (
    <>
      <AppInput name="displayName" label="Name" labelWidth={labelWidth} />
      <AppInput
        name="email"
        label="Email"
        labelWidth={labelWidth}
        helperMsg="We will notify the customer via email if he/she doesn't have AddPointment account"
      />
    </>
  );
};

export default CreateBookingNewCustomer;
