import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { businessURL } from "../../config/url";
import ModalAdminCreateBooking from "../createBooking/ModalAdminCreateBooking";
import ModalAddCustomer from "../customer/ModalAddCustomer";
import styles from "./DashboardQuickLinks.module.scss";

const DashboardQuickLinks: React.FC = () => {
  const navigate = useNavigate();
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [createBooking, setCreateBooking] = useState(false);
  const links = [
    {
      name: "Create a service",
      link: () => navigate(businessURL.BusinessServiceNew),
    },
    // {
    //   name: "Create a resource",
    //   link: () => navigate(businessNewResourceURL.Create),
    // },
    // {
    //   name: "Create a provider",
    //   link: () => navigate(businessNewProviderURL.Create),
    // },
    { name: "Add a customer", link: () => setAddCustomer(true) },
    { name: "Create a booking", link: () => setCreateBooking(true) },
  ];
  return (
    <>
      <h3>Things You Can Do</h3>
      <ul className={styles["link-list"]}>
        {links.map((item, index) => {
          return (
            <li key={index}>
              <button className="btn btn-link link" onClick={item.link}>
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
      {addCustomer && (
        <ModalAddCustomer onClose={() => setAddCustomer(false)} />
      )}
      {createBooking && (
        <ModalAdminCreateBooking onClose={() => setCreateBooking(false)} />
      )}
    </>
  );
};

export default DashboardQuickLinks;
