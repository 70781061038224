import { useState } from "react";
import { useNavigate } from "react-router";
import { DropdownAction } from "../ui/dropdown";
import { businessURL } from "../../config/url";
import { RiFileCopyLine, RiPencilFill, RiDeleteBin6Line } from "react-icons/ri";
import ServiceRemoveDialog from "./ServiceRemoveDialog";
import ModalServiceDuplicate from "./ModalServiceDuplicate";
import MoreButton from "../ui/MoreButton";
import { EBtnCategory } from "../form/AppButton";

interface ServiceActionMenuProps {
  id: string;
  name: string;
  type: "table" | "details";
}

const ServiceActionMenu: React.FC<ServiceActionMenuProps> = ({
  id,
  name,
  type,
}) => {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState(false);
  const [duplicate, setDuplicate] = useState<string | null>(null);
  const editMenu = {
    name: "Edit",
    icon: <RiPencilFill />,
    onClick: () => navigate(`${businessURL.BusinessServices}/${id}/overview`),
  };
  let menu = [
    {
      name: "Duplicate",
      icon: <RiFileCopyLine />,
      onClick: () => setDuplicate(id),
    },
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      onClick: () => setDialog(true),
    },
  ];
  if (type === "table") {
    menu = [editMenu, ...menu];
  }
  return (
    <>
      {type === "table" ? (
        <DropdownAction menu={menu} minWidth={160} />
      ) : (
        <MoreButton
          menuList={menu}
          label="More Options"
          category={EBtnCategory.PrimaryOutline}
          // onClick={() => navigate(businessURL.BusinessServices)}
        />
      )}
      {dialog && (
        <ServiceRemoveDialog setVisible={(arg) => setDialog(arg)} id={id} />
      )}
      {duplicate && (
        <ModalServiceDuplicate
          id={duplicate}
          onClose={() => setDuplicate(null)}
          name={name}
        />
      )}
    </>
  );
};

export default ServiceActionMenu;
