import { useEffect, useState } from "react";
import styles from "./ModalReschedule.module.scss";
import { ModalBox, ModalHeader, ModalBody, ModalFooter } from "../ui/modal";
import { AppForm, AppButton } from "../form";
import BusinessBookingForm from "./BusinessBookingForm";
import {
  Validation,
  InitialValues,
  // BusinessBookingFormType,
} from "../../config/form/formBusinessBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";
// import FilterBookings from "./FilterBookings";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface ModalProps {
  onClose(): void;
  data: IBookingDataType;
}

const ModalReschedule: React.FC<ModalProps> = ({ onClose, data }) => {
  const [initVal, setInitVal] = useState(InitialValues);
  const { rescheduleBusinessBooking, clearBusinessBookingState } = useActions();
  const { success } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const { notify } = useToast();

  useEffect(() => {
    setInitVal({
      ...initVal,
      apptDate: new Date(),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearBusinessBookingState();
      onClose();
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  // const entity = data.service || data.provider || data.resource;
  const entity = data.service as IServiceDataType;

  return (
    <ModalBox onClose={onClose} type="business" overlayClose size="large">
      <ModalHeader
        title={`Reschedule for customer ${data.customerData.displayName}`}
      />
      <AppForm
        className="flex-layout"
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("submit reschedule value", values);
          rescheduleBusinessBooking(data, values);
        }}
        validationSchema={Validation}
      >
        <ModalBody>
          <ul className={styles["wrapper"]}>
            <li>
              <div className={styles["label"]}>
                {data.service && "Service"}
                {/* {data.provider && "Provider"}
                {data.resource && "Resource"} */}
              </div>
              <div className={styles["value"]}>{entity?.name}</div>
            </li>
            <li>
              <div className={styles["label"]}>Customer</div>
              <div className={styles["value"]}>
                {data.customerData.displayName}
              </div>
            </li>
          </ul>
          <BusinessBookingForm type="reschedule" entity={entity} />
        </ModalBody>
        <ModalFooter>
          <AppButton
            type="button"
            onClick={onClose}
            className="btn btn-link mr-10"
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            Reschedule
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalReschedule;
