import * as yup from "yup";
import { passwordField } from "./validationFields";

export const InitialValues = {
  password: "",
  newPassword: "",
  reEnterPassword: "",
};

export interface IChangePasswordInputType {
  password: string;
  newPassword: string;
  reEnterPassword: string;
}

export const Validation = yup.object().shape({
  password: yup.string().required("Please enter your existing password"),
  newPassword: passwordField.required("Please enter your new password"),
  reEnterPassword: yup
    .string()
    .required("Please re-enter your new password")
    .oneOf([yup.ref("newPassword"), null], "New password don't match"),
});
