import Header from "../../components/layout/Header";
import PageWithAside from "../../components/layout/PageWithAside";
import Loader from "../../components/ui/Loader";
import BusinessOnlineBooking from "../../components/businessTools/BusinessOnlineBooking";
import useGetBookingType from "../../hooks/useGetBookingType";
import useToast from "../../components/ui/toasts/useToast";
import { useEffect } from "react";

const BusinessOnlineBookingPage: React.FC = () => {
  const { loading, error, clearBookingTypeState } = useGetBookingType();
  const { notify } = useToast();

  useEffect(() => {
    if (error) {
      notify("error", error);
      clearBookingTypeState();
    }
  }, [error]);

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Online Booking"
          pageDesc="Let your customer make booking in advance through the booking page"
          role="business"
        />
        {loading ? <Loader /> : <BusinessOnlineBooking />}
      </div>
    </PageWithAside>
  );
};

export default BusinessOnlineBookingPage;
