import { useState } from "react";
import { Col, Row } from "../ui/Grid";
import { AppButton } from "../form";
import Guideline from "../ui/Guideline";
import TableMeetingPoint from "./TableMeetingPoint";
import ModalMeetingPoint from "./ModalMeetingPoint";
import { MeetingPointType } from "../../config/types/MeetingPointType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import EmptyData from "../ui/EmptyData";
import iconMeetingPoint from "../../assets/images/meeting-point.svg";
import { EBtnCategory } from "../form/AppButton";

const BusinessMeetingPoint: React.FC = () => {
  const { data } = useTypedSelector((state) => state.entities.meetingPoint);
  const [selectedData, setSelectedData] = useState<
    MeetingPointType | "new" | null
  >();

  const handleClick = (id: string) => {
    const selectedData = data.find((val) => val._id === id);
    if (selectedData) {
      setSelectedData(selectedData);
    }
  };

  if (!data || data.length === 0)
    return (
      <>
        <EmptyData
          title="Area to Receive Service"
          size="large"
          image={iconMeetingPoint}
        >
          <p>
            Guide your customers to complete their service by notifying their
            table number, room number or counter number.
          </p>
          <button
            className="btn btn-primary-outline mt-10"
            type="button"
            onClick={() => setSelectedData("new")}
          >
            Add Meeting Point
          </button>
        </EmptyData>

        {selectedData && (
          <ModalMeetingPoint
            data={selectedData}
            onClose={() => setSelectedData(null)}
          />
        )}
      </>
    );

  return (
    <>
      <Row>
        <Col width="60%">
          <TableMeetingPoint data={data} onView={handleClick} />
          <AppButton
            type="button"
            category={EBtnCategory.PrimaryOutline}
            className="mt-30"
            onClick={() => setSelectedData("new")}
          >
            Add Meeting Point
          </AppButton>
        </Col>
        <Col width="30%">
          <Guideline title="Meeting Point">
            <p>
              You can set the meeting point when you start serving the customer
              or preset it by services, resources or providers. The meeting
              point will display at <b>Now Serving</b> screen and customer
              mobile app.
            </p>
          </Guideline>
        </Col>
      </Row>
      {selectedData && (
        <ModalMeetingPoint
          data={selectedData}
          onClose={() => setSelectedData(null)}
        />
      )}
    </>
  );
};

export default BusinessMeetingPoint;
