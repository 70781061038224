import { Col, Row } from "../ui/Grid";
import { AppPhoto, AppInput, AppTextArea, AppSelectMenu } from "../form";
import Guideline from "../ui/Guideline";
import { providerType } from "../../config/option/optionProviders";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { useEffect } from "react";
import useToast from "../ui/toasts/useToast";

const labelWidth = "120px";

interface ProviderInfoProps {
  provider: IProviderDataType;
}

const ProviderInfo: React.FC<ProviderInfoProps> = ({ provider }) => {
  const { photoUpload } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );

  const { uploadAdminProviderPhoto, clearAdminProviderState } = useActions();

  const { notify } = useToast();
  useEffect(() => {
    if (photoUpload?.success) {
      notify("success", "Photo has been updated");
      clearAdminProviderState();
    }
    if (photoUpload?.error) {
      notify("error", photoUpload.error);
      clearAdminProviderState();
    }
  }, [photoUpload]);
  return (
    <>
      <h3 className="mb-3">{provider.name} Info</h3>
      <div className="desc mb-20">
        Basic information about the {provider.name}
      </div>
      <Row>
        <Col width="50%">
          <AppPhoto
            name="imageUrl"
            label="Photo"
            type="big"
            labelWidth={labelWidth}
            uploadLabel="Change Photo"
            imageSrc={provider.imageUrl}
            uploaded={photoUpload?.success}
            onUploadPicture={(photo) => {
              uploadAdminProviderPhoto(photo, provider._id);
            }}
          />
          <AppSelectMenu
            name="profession"
            label="Profession"
            labelWidth={labelWidth}
            options={providerType}
            placeholder="Select a profession"
          />
          <AppInput
            type="text"
            name="name"
            label="Name"
            labelWidth={labelWidth}
          />
          <AppInput
            type="text"
            name="title"
            label="Title"
            labelWidth={labelWidth}
            helperMsg="Title will appear under the name"
          />
          <AppTextArea
            name="desc"
            label="Description"
            helperMsg="Add a short description about this provider"
            labelWidth={labelWidth}
          />
        </Col>
        <Col width="30%">
          <Guideline title={`${provider.name} Info`}>
            <p>
              {provider.name} info will help customer understand the provider
              better when they make booking.
            </p>
            <p>
              Use a <b>Title</b> to differentiate each provider to help customer
              easily make booking.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ProviderInfo;
