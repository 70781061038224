import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import useBusinessServicePage from "./useBusinessServicePage";
import ServiceStaff from "../../components/common/ServiceStaff";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { ListTableType } from "../../components/common/useServiceListTable";

const ServiceStaffPage: React.FC = () => {
  // const { id } = useParams() as { id: string };
  // const { loading, data } = useTypedSelector(
  //   (state) => state.entities.serviceAdmin
  // );
  // const { service, submenu, header } = useBusinessServicePage(id);
  // const [staffSelected, setStaffSelected] = useState<ListTableType[]>();

  // useEffect(() => {
  //   if (service && service.staff && service.staff.length > 0) {
  //     // Restructure staff data to fit list table
  //     const staffData = service.staff.map((item) => {
  //       return { _id: item.id, name: item.displayName };
  //     });
  //     staffData && setStaffSelected(staffData);
  //   }
  // }, [service]);

  // return (
  //   <PageWithAside
  //     role="business"
  //     submenu="Services"
  //     submenuList={submenu}
  //     noPadding
  //     goUpLevel={businessURL.BusinessServices}
  //   >
  //     {header}
  //     <div className="wrapper">
  //       {!data || loading ? (
  //         <Loader />
  //       ) : (
  //         <ServiceStaff id={id} data={staffSelected} category="services" />
  //       )}
  //     </div>
  //   </PageWithAside>
  // );
  return null;
};

export default ServiceStaffPage;
