import { useEffect, useState } from "react";
import { ModalBox, ModalHeader } from "../ui/modal";
import { AppButton, AppForm, AppInput } from "../form";
import { InitialValues, Validation } from "../../config/form/formAddCustomer";
import { ModalBody, ModalFooter } from "../ui/modal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { EBtnCategory } from "../form/AppButton";

interface ModalAddCustomerProps {
  onClose(): void;
}

const ModalAddCustomer: React.FC<ModalAddCustomerProps> = ({ onClose }) => {
  const { addCustomer, clearCustomerState } = useActions();
  const [resError, setResError] = useState("");
  const { success, loading, error } = useTypedSelector(
    (state) => state.entities.customer
  );

  useEffect(() => {
    if (success) onClose();
    else if (error) {
      setResError(error);
      clearCustomerState();
    }
  }, [success, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      size="small"
    >
      <ModalHeader title="Add Customer" />
      <AppForm
        className="flex-layout"
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          console.log("values", values);
          actions.setSubmitting(false);
          addCustomer(values);
        }}
      >
        <ModalBody>
          <div className="px-25 pt-10 pb-5">
            Invite your customer to make booking with you.{" "}
            <div className="desc mt-5 mb-20">
              An email with your booking page url will be sent to your customer
            </div>
            {resError && <p className="desc error-msg">{resError}</p>}
            <AppInput name="email" placeholder="Email" labelBlock />
            <AppInput
              name="displayName"
              placeholder="Name"
              // helperMsg="Customer ID for reference. Leave it blank to auto generate the ID"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <AppButton
            type="button"
            onClick={onClose}
            className="mr-10"
            category={EBtnCategory.Link}
            loading={loading}
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            Add
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalAddCustomer;
