import * as React from "react";

type BusinessStateType = {
  location?: string;
};

export type ContextType = {
  businessState: BusinessStateType;
  handleStateChange(arg: BusinessStateType): void;
};
export const BusinessContext = React.createContext<ContextType | null>(null);

const BusinessProvider: React.FC = ({ children }) => {
  const [businessState, setBusinessState] = React.useState({});

  const handleStateChange = (arg: BusinessStateType) => {
    console.log("clicked", { ...businessState, ...arg });
    setBusinessState({ ...businessState, ...arg });
  };

  return (
    <BusinessContext.Provider value={{ businessState, handleStateChange }}>
      {children}
    </BusinessContext.Provider>
  );
};
export default BusinessProvider;
