import classNames from "classnames";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { queueNumber } from "../../utils/booking";
import { getTime } from "../../utils/datetime";
import { AvatarPic } from "../ui/avatar";
import styles from "./TodayBookingNotification.module.scss";

interface TodayBookingNotificationProps {
  data: IBookingDataType;
}

const TodayBookingNotification: React.FC<TodayBookingNotificationProps> = ({
  data,
}) => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["logo"]}>
        <AvatarPic
          role="business"
          size="big"
          src={data.businessData.profilePic}
        />
      </div>
      <div className={styles["container"]}>
        <div className={styles["token-no"]}>
          <div className="desc">Queue No.</div>
          <div className="h1">
            {queueNumber(data.bookingNo, data.serviceData.label)}
          </div>
        </div>
        {/* <div className={styles["waiting"]}>
          <b className="h2">50+</b> <span className="desc">waiting</span>
        </div> */}
        {data.service && (
          <div className={classNames(styles["service"], "h5")}>
            {data.serviceData.name}
          </div>
        )}
        <div className="desc mt-3">{getTime(data.meetDateTime)}</div>
      </div>
    </div>
  );
};

export default TodayBookingNotification;
