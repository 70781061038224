import * as reducers from "../reducer/personalBookingReducer";
import { apiCallBegan, apiLoad } from "../middleware/api";
import { ICreateMeetupInputType } from "../../config/form/formNewMeetup";
import { bookingDateTime, bookingLog } from "../../utils/booking";
import { IUserDataType } from "../../config/types/UserDataType";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { EBookingLogType } from "../../config/types/BookingLogType";
import { EBookingType } from "../../config/types/BookingType";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/meetup";

// export const clearMeetupState =
//   () => (dispatch: (arg: DispatchType) => void) => {
//     return dispatch({ type: reducers.meetupClearState.type });
//   };

export const createMeetup =
  (data: ICreateMeetupInputType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("createMeetup", data);
    dispatch(apiLoad(reducers.bookingLoading.type));
    const dateTime = bookingDateTime(new Date(data.date + " " + data.time));
    const bookingData = {
      ...dateTime,
      listNewUser: data.listNewUser,
      listNewFriends: data.listNewFriends,
      listFriends: data.listFriends,
      subject: data.subject,
      venue: data.location,
      note: data.note,
      media: config.media,
    };
    console.log("bookingData", bookingData);
    return dispatch(
      apiCallBegan({
        url: url + "/create",
        data: bookingData,
        method: "post",
        // onStart: reducers.createAppt.type,
        onSuccess: reducers.meetupCreated.type,
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const acceptMeetup =
  (meetupId: string, hostId: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { user: { data: IUserDataType } };
    }
  ) => {
    console.log("acceptMeetup", meetupId);
    const user = getState().entities.user.data;
    return dispatch(
      apiCallBegan({
        url: url + "/accept",
        data: {
          meetupId,
          hostId,
          bookingStatus: EBookingStatusValue.confirm,
          apptLog: {
            bookingStatus: EBookingStatusValue.confirm,
            creatorName: user.displayName,
            logType: EBookingLogType.Accepted,
          },
        },
        method: "post",
        onStart: reducers.bookingLoading.type,
        onSuccessDispatch: {
          type: reducers.meetupStatusUpdate.type,
          payload: reducers.EMeetupActionsType.Accept,
        },
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const rejectMeetup =
  (meetupId: string, hostId: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { user: { data: IUserDataType } };
    }
  ) => {
    console.log("rejectMeetup", meetupId);
    const user = getState().entities.user.data;
    const bookingStatus = EBookingStatusValue.reject;
    return dispatch(
      apiCallBegan({
        url: url + "/reject",
        data: {
          meetupId,
          hostId,
          bookingStatus,
          apptLog: {
            bookingStatus,
            creatorName: user.displayName,
            logType: EBookingLogType.Rejected,
          },
        },
        method: "post",
        onStart: reducers.bookingLoading.type,
        onSuccessDispatch: {
          type: reducers.meetupStatusUpdate.type,
          payload: reducers.EMeetupActionsType.Reject,
        },
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const confirmMeetup =
  (meetupId: string, hostId: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { user: { data: IUserDataType } };
    }
  ) => {
    console.log("confirmMeetup", meetupId);
    const user = getState().entities.user.data;
    const bookingStatus = EBookingStatusValue.confirm;
    return dispatch(
      apiCallBegan({
        url: url + "/confirm",
        data: {
          meetupId,
          hostId,
          bookingStatus,
          apptLog: {
            bookingStatus,
            creatorName: user.displayName,
            logType: EBookingLogType.Confirmed,
          },
        },
        method: "post",
        onStart: reducers.bookingLoading.type,
        onSuccessDispatch: {
          type: reducers.meetupStatusUpdate.type,
          payload: reducers.EMeetupActionsType.Confirm,
        },
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const notAttendMeetup =
  (meetupId: string, hostId: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { user: { data: IUserDataType } };
    }
  ) => {
    console.log("notAttendMeetup", meetupId);
    const user = getState().entities.user.data;
    const bookingStatus = EBookingStatusValue.notAttend;
    return dispatch(
      apiCallBegan({
        url: url + "/not-attend",
        data: {
          meetupId,
          hostId,
          bookingStatus,
          apptLog: {
            bookingStatus,
            creatorName: user.displayName,
            logType: EBookingLogType.NotAttended,
          },
        },
        method: "post",
        onStart: reducers.bookingLoading.type,
        onSuccessDispatch: {
          type: reducers.meetupStatusUpdate.type,
          payload: reducers.EMeetupActionsType.NotAttend,
        },
        onError: reducers.bookingFailed.type,
      })
    );
  };

export const cancelMeetup =
  (meetupId: string, hostId: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { user: { data: IUserDataType } };
    }
  ) => {
    console.log("cancelMeetup", meetupId);
    const user = getState().entities.user.data;
    const bookingStatus = EBookingStatusValue.cancel;
    return dispatch(
      apiCallBegan({
        url: url + "/cancel",
        data: {
          meetupId,
          hostId,
          bookingStatus,
          apptLog: {
            bookingStatus,
            creatorName: user.displayName,
            logType: EBookingLogType.Cancelled,
          },
        },
        method: "post",
        onStart: reducers.bookingLoading.type,
        onSuccessDispatch: {
          type: reducers.meetupStatusUpdate.type,
          payload: reducers.EMeetupActionsType.Cancel,
        },
        onError: reducers.bookingFailed.type,
      })
    );
  };
