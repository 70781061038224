import { useFormikContext } from "formik";
import { AppInput } from "../form";
import AppCountryRegion from "../form/AppCountryRegion";

interface BusinessContactFormProps {
  isEdit?: boolean;
}
interface FormikContextProps {
  [key: string]: string | [key: string];
}

const BusinessContactForm: React.FC<BusinessContactFormProps> = ({
  isEdit,
}) => {
  const { values } = useFormikContext<FormikContextProps>();
  return (
    <>
      {values["isMain"] && (
        <p className="desc">This is your business main location</p>
      )}
      {isEdit && (
        <AppInput
          name="name"
          label="Name"
          helperMsg="Name of the branch or outlet"
        />
      )}
      <AppInput
        name="phone"
        label="Phone"
        helperMsg="Your business contact number"
      />
      <AppInput
        name="email"
        label="Email"
        helperMsg="This will be your main contact for your business"
      />
      <AppInput name="website" label="Website" />
      <AppInput
        name="address"
        label="Address"
        placeholder="Business Address Line 1"
      />
      <AppInput
        name="address2"
        label=" "
        placeholder="Business Address Line 2"
      />
      <AppInput name="city" label="City" />
      <AppInput name="postcode" label="Postal Code" />
      {/* <AppInput name="country" label="Country" />
          <AppInput name="state" label="State" /> */}
      <AppCountryRegion name="country" label="Country" />
      <AppCountryRegion name="state" label="State" />
    </>
  );
};

export default BusinessContactForm;
