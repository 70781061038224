import { useState } from "react";
import ModalBookmark from "./ModalBookmark";
import TableBookmark from "./TableBookmark";
import { IBookmarkDataType } from "../../config/types/BookmarkDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import iconBusiness from "../../assets/images/business.svg";
import EmptyData from "../ui/EmptyData";
import ModalMakeBooking from "../personal/ModalMakeBooking";

const PersonalBookmark: React.FC = () => {
  const [selected, setSelected] = useState<IBookmarkDataType | null>();
  const [modalVisible, setModalVisible] = useState(false);
  const { data } = useTypedSelector((state) => state.entities.bookmark);

  const handleBookmarkView = (id: string) => {
    console.log("handleBookmarkView", id);
    const bookmark = data.find((biz) => biz._id === id);
    if (bookmark) {
      setSelected(bookmark);
    }
  };
  if (data.length === 0)
    return (
      <>
        <EmptyData title="No Data" size="large" image={iconBusiness}>
          <p>
            Businesses will be added to your list when you make a booking with
            them.
            {/* <button
              className="btn-link link"
              onClick={() => setModalVisible(true)}
            > */}
            {/* </button>{" "} */}
          </p>
        </EmptyData>
        {modalVisible && (
          <ModalMakeBooking onClose={() => setModalVisible(false)} />
        )}
      </>
    );
  return (
    <>
      <TableBookmark data={data} onView={handleBookmarkView} />
      <p className="desc mt-20">
        Business that you have made booking before will auto added to your
        bookmark
      </p>
      {selected && (
        <ModalBookmark onClose={() => setSelected(null)} data={selected} />
      )}
    </>
  );
};

export default PersonalBookmark;
