import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import ServicePeriod from "../../components/common/ServicePeriod";
import { InitialValues, Validation } from "../../config/form/formServicePeriod";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import { countDailyCapacity } from "../../utils/capacity";

const ServicePeriodPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const [initVal, setInitVal] = useState(InitialValues);
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { service, submenu, header, footer } = useBusinessServicePage(id);
  const { updateAdminServicePeriod } = useActions();

  useEffect(() => {
    if (service) {
      setInitVal({
        ...initVal,
        servicePeriodOption: service.servicePeriodOption,
        servicePeriod: service.servicePeriod,
        servicePeriodSum: service.servicePeriodSum,
      });
    }
  }, [service]);
  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && service && (
          <AppForm
            initialValues={initVal}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              const dailyCapacity = countDailyCapacity(
                service.bookingCapacity,
                service.slotInterval,
                values.servicePeriodSum
              );
              updateAdminServicePeriod({ ...values, dailyCapacity }, id);
            }}
            validationSchema={Validation}
          >
            <ServicePeriod name="service" service={service} />

            {!service.providers?.length && footer}
          </AppForm>
        )}
      </div>
    </PageWithAside>
  );
};

export default ServicePeriodPage;
