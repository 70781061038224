import styles from "./Calendar.module.scss";
import classNames from "classnames";
import useCalendarCountDay from "./useCalendarCountDay";
import { ICustomDayDataType } from "../../../config/types/CustomDayDataType";

export type DataType = {
  id: string;
  name: string;
  day: number;
  status: number;
};
export type onClickBookingType = (id: string) => void;
export type onSelectDateType = (val: number) => void;

type TCellData = {
  day: number;
  cell: JSX.Element;
};

interface IuseCalendarSlotProps {
  currentMonth: number;
  currentYear: number;
  type: "mini" | "full";
  data?: DataType[];
  minDate?: Date;
  maxDate?: Date;
  selectedDate?: Date;
  onClickBooking?: onClickBookingType;
  showNeighboringMonth?: boolean;
  businessHours?: string;
  customBusinessHours?: ICustomDayDataType[];
  onSelectDate?: onSelectDateType;
}

const useCalendarSlot = ({
  currentMonth,
  currentYear,
  type,
  data,
  minDate,
  maxDate,
  selectedDate,
  onClickBooking,
  showNeighboringMonth,
  businessHours,
  customBusinessHours,
  onSelectDate,
}: IuseCalendarSlotProps) => {
  const {
    firstDayOfMonth,
    lastDayOfMonth,
    lastDayOfPrevMonth,
    daysInMonth,
    today,
    minDay,
    maxDay,
    selectedDay,
  } = useCalendarCountDay(
    currentMonth,
    currentYear,
    minDate,
    maxDate,
    selectedDate
  );

  // Convert business hours to boolean array;
  // If open, return true
  // If off, return false
  const businessDays: boolean[] = businessHours
    ? JSON.parse(businessHours).map((day: string[]) => {
        if (day[0] === "Off") return false;
        return true;
      })
    : undefined;

  // Get this month custom business hours
  const currentCustomDays = customBusinessHours
    ? customBusinessHours.filter((i) => i.month === currentMonth + 1)
    : undefined;

  // Put custom days date into array
  const customDaysArray = currentCustomDays
    ? currentCustomDays.map((i) => i.date)
    : undefined;

  // Fill up the blank day for beginning
  let blankSlotStart: TCellData[] = [];
  for (let i = 1; i <= firstDayOfMonth; i++) {
    const d = lastDayOfPrevMonth - firstDayOfMonth + i;
    blankSlotStart.push({
      day: d,
      cell: (
        <div className={classNames(styles["cell"])}>
          {showNeighboringMonth && <div className={styles["day"]}>{d}</div>}
        </div>
      ),
    });
  }

  // Fill up the blank day for end
  let blankSlotEnd: TCellData[] = [];
  for (let i = lastDayOfMonth + 1; i < 7; i++) {
    const d = i - lastDayOfMonth;
    blankSlotEnd.push({
      day: d,
      cell: (
        <div className={classNames(styles["cell"])}>
          {showNeighboringMonth && <div className={styles["day"]}>{d}</div>}
        </div>
      ),
    });
  }

  let bookingDots = [];
  for (let i = 0; i < 3; i++) {
    bookingDots.push(<span className={styles["dot"]} key={i} />);
  }

  let daysInMonthSlot: TCellData[] = [];
  for (let day = 1; day <= daysInMonth; day++) {
    const bookings =
      data && data.length > 0 && data.filter((i) => i.day === day);
    daysInMonthSlot.push({
      day,
      cell: (
        <div
          className={classNames(
            styles["cell"],
            day === today && styles["today"],
            day === selectedDay && styles["selected"]
          )}
        >
          <div className={styles["day"]}>{day}</div>
          {type === "full" && (
            <div className={styles["bookings"]}>
              {/* <div className={styles["dots"]}>{bookingDots}</div> */}
              {bookings &&
                bookings.length > 0 &&
                bookings.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        styles["booking"],
                        styles[`status-${item.status}`]
                      )}
                      key={index}
                      onClick={() => onClickBooking && onClickBooking(item.id)}
                    >
                      {item.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ),
    });
  }

  const totalSlots = [...blankSlotStart, ...daysInMonthSlot, ...blankSlotEnd];

  const availablility = (day: number, key: number) => {
    let weekday = key;
    if (customDaysArray && customDaysArray.includes(day)) {
      // Has custom day for this day
      const customDay = currentCustomDays?.find((i) => i.date === day);
      if (customDay?.businessHoursSum === 0) return false;
      else return true;
    }
    if (key >= 28) weekday = key - 28;
    else if (key >= 21) weekday = key - 21;
    else if (key >= 14) weekday = key - 14;
    else if (key >= 7) weekday = key - 7;

    let output = true;
    if (businessDays && businessDays[weekday]) {
      if (minDay && day < minDay) output = false;
      if (maxDay && day > maxDay) output = false;
      if (!minDay && !maxDay) output = false;
    } else output = false;

    return output;
  };

  const handleSelectDate = (day: number) => {
    onSelectDate &&
      onSelectDate(new Date(currentYear, currentMonth, day).getTime());
  };

  const renderCell = (key: number, row: TCellData) => {
    const available = availablility(row.day, key);
    return (
      <td key={key}>
        <div
          onClick={() => available && handleSelectDate(row.day)}
          title={!available ? "Not available" : ""}
          className={classNames(
            available ? styles["available"] : styles["not-available"]
          )}
        >
          {row.cell}
        </div>
      </td>
    );
  };

  let rows: Object[] = [];
  let cells: Object[] = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(renderCell(i, row)); // if index not equal 7 that means not go to next week
    } else {
      rows.push(cells); // when reach next week we in all td in last week to rows
      cells = []; // empty container
      cells.push(renderCell(i, row)); // in current loop we still push current row to new container
    }
    if (i === totalSlots.length - 1) {
      // when end loop we add remain date
      rows.push(cells);
    }
  });

  return { rows };
};

export default useCalendarSlot;
