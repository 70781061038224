import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import ListTable from "../ui/ListTable";
import useServiceProviderList from "./useServiceProvider";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface ServiceProviderProps {
  service: IServiceDataType;
}

const ServiceProvider: React.FC<ServiceProviderProps> = ({ service }) => {
  const { panelList, providerList, handleAdd, handleDelete } =
    useServiceProviderList(service);

  return (
    <>
      <h3 className="mb-3">Provider</h3>
      <div className="desc mb-20">
        Allow customer to select the provider to serve them.
      </div>
      <Row>
        <Col width="60%">
          <div style={{ maxWidth: "500px" }}>
            <ListTable
              data={providerList}
              panelList={panelList}
              panelEmptyMsg="No result found."
              label="Add Service Provider"
              onDelete={handleDelete}
              // onEdit={}
              onClickPanel={() => handleAdd}
            />
          </div>
        </Col>
        <Col width="30%">
          <Guideline title="Provider">
            <p>
              You can allow customer to select which provider to serve them.
            </p>
            <p>
              Each provider can only take <b>1 booking</b> in each slot.
            </p>
            <p>
              Each provider required a user account. The user can login to
              manage his/her own booking.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServiceProvider;
