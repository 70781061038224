import * as yup from "yup";

export const InitialValues = {
	name: "",
	title: "",
};

export const Validation = yup.object().shape({
	name: yup
		.string()
		.max(50, "Maximum 50 characters")
		.required("Please enter the name"),
	title: yup.string().required("Please enter the title"),
});

export interface IProviderDuplicateInputType {
	name: string;
	title: string;
}
