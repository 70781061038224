export const bookingType = [
  {
    value: 1,
    name: "Meetup",
  },
  {
    value: 101,
    name: "Appointment",
  },
  {
    value: 102,
    name: "Reservation",
  },
  {
    value: 103,
    name: "Table Booking",
  },
  {
    value: 104,
    name: "Venue Booking",
  },
  {
    value: 105,
    name: "Room Booking",
  },
  {
    value: 106,
    name: "Virtual Queue",
  },
  {
    value: 107,
    name: "Drive Thru",
  },
  {
    value: 108,
    name: "Order & Pickup",
  },
  {
    name: "Room Reservation",
    value: 201,
  },
  {
    name: "Table Reservation",
    value: 202,
  },
  {
    name: "Facility Reservation",
    value: 203,
  },
  {
    name: "Venue Reservation",
    value: 204,
  },
  {
    name: "Appointment with Doctor",
    value: 301,
  },
  {
    name: "Appointment with Dentist",
    value: 302,
  },
  {
    name: "Appointment with Stylist",
    value: 303,
  },
  {
    name: "Appointment with Practitioner",
    value: 304,
  },
  {
    name: "Appointment with Specialist",
    value: 305,
  },
  {
    name: "Appointment with Trainer",
    value: 306,
  },
  {
    name: "Appointment with Technician",
    value: 307,
  },
  {
    name: "Appointment with Staff",
    value: 308,
  },
];
