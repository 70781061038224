import * as reducers from "../reducer/notificationReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getNotifications as dataNotifications } from "../../data/dataNotifications";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/notification";

export const clearPersonalNotificationsState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.notificationClearState.type });
  };

export const getPersonalNotifications =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { notification: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.notification;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        data: config.localENV && dataNotifications(),
        method: "get",
        onStart: reducers.notificationRequest.type,
        onSuccess: reducers.notificationReceived.type,
        onError: reducers.notificationRequestFailed.type,
      })
    );
  };

export const readPersonalNotification =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/read",
        data: { id },
        method: "post",
        onSuccess: reducers.notificationRead.type,
      })
    );
  };

export const readAllPersonalNotification =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/read-all",
        data: config.localENV && dataNotifications(),
        method: "post",
        onSuccess: reducers.notificationReadAll.type,
      })
    );
  };

export const openPersonalNotifications =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/open",
        method: "post",
        onSuccess: reducers.notificationOpened.type,
      })
    );
  };
