import { useEffect } from "react";
import TableRecord from "./TableRecord";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import Loader from "../ui/Loader";

interface BusinessRecordsProps {
  businessId: string;
}

const BusinessRecords: React.FC<BusinessRecordsProps> = ({ businessId }) => {
  const { getBookingRecord } = useActions();
  const { loading, record } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  useEffect(() => {
    getBookingRecord(businessId);
  }, [businessId]);

  if (loading) return <Loader />;
  return (
    <>
      <h4 className="mb-10">Booking</h4>
      {record.length > 0 ? (
        <TableRecord data={record} />
      ) : (
        <div className="text-italic">No Record</div>
      )}
    </>
  );
};

export default BusinessRecords;
