import * as reducers from "../reducer/businessMessageReducer";
import { apiCallBegan } from "../middleware/api";

interface DispatchType {
  type: string;
}

const url = "/business-message";

export const clearMessageState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.messageClearState.type });
  };

export const sendCustomerMessages =
  (message: string, customerId: string, bookingId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("sendCustomerMessages");
    return dispatch(
      apiCallBegan({
        url: url + "/send-to-customer",
        data: { message, customerId, bookingId },
        method: "post",
        // onStart: reducers.sendToCustomer.type,
        onSuccess: reducers.sendToCustomer.type,
        onError: reducers.messageSendFailed.type,
      })
    );
  };
