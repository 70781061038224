export const webURL = {
  terms: "https://addpointment.com/terms-of-service",
  policy: "https://addpointment.com/privacy-policy",
};

export const authURL = {
  Login: "/login",
  Logout: "/logout",
  Register: "/register",
  // RegisterStep2: "/register-phone",
  RegisterEmail: "/register-email",
  RegisterPin: "/register-pin",
  RegisterProfile: "/register-profile",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password",
  ResetSuccess: "/reset-success",
  ChangePassword: "/change-password",
  RegisterBusiness: "/register-business",
};
export const personalURL = {
  Personal: "/personal",
  PersonalHome: "/personal/home",
  PersonalSchedule: "/personal/schedule",
  PersonalCalendar: "/personal/schedule/calendar",
  PersonalFriends: "/personal/friends",
  PersonalProfile: "/personal/profile",
  PersonalProfileInfo: "/personal/profile/info",
  PersonalContact: "/personal/profile/contact",
  PersonalChangeEmail: "/personal/profile/change-email",
  PersonalChangePhone: "/personal/profile/change-phone",
  PersonalSetting: "/personal/profile/setting",
  PersonalProfilePin: "/personal/profile/pin-number",
  PersonalChangePassword: "/personal/profile/change-password",
  PersonalBookmark: "/personal/bookmark",
  CreateMeetup: "/personal/new-meetup",
  NewBooking: "/personal/new-booking",
};
export const businessURL = {
  Business: "/business",
  BusinessDashboard: "/business/dashboard",
  BusinessToday: "/business/bookings", //booking today page
  BusinessSchedule: "/business/bookings/schedule",
  BusinessWaitingList: "/business/bookings/waiting-list",
  BusinessServices: "/business/services",
  BusinessResources: "/business/resources",
  BusinessProviders: "/business/providers",
  BusinessClientele: "/business/clientele",
  BusinessProfile: "/business/profile",
  BusinessNowServing: "/business/tools", //now-serving page
  BusinessNowServingIndividual: "/business/tools/now-serving",
  BusinessNowServingAll: "/business/tools/now-serving/all",
  BusinessMeetingPoint: "/business/tools/meeting-point",
  BusinessWalkIn: "/business/tools/walk-in",
  BusinessWalkInRegistration: "/business/tools/walk-in/registration",
  BusinessOnlineBooking: "/business/tools/online-booking",
  BusinessReport: "/business/report",
  BusinessServiceNew: "/business/new-service",

  BusinessAppointments: "/business/appointments",
  BusinessSettings: "/business/settings",
};

export const businessSetupURL = {
  Info: "/setup-business",
  Contact: "/setup-business/contact",
  Hours: "/setup-business/hours",
};
export const businessProfileURL = {
  Info: "/business/profile/info",
  Contact: "/business/profile/contact",
  Hours: "/business/profile/hours",
  QRCode: "/business/profile/qrcode",
  Holidays: "/business/profile/holidays",
  Locations: "/business/profile/locations",
  Staff: "/business/profile/staff",
};

export const businessReportURL = {
  Services: "services",
  Resources: "resources",
  Providers: "providers",
  Staff: "staff",
  Customer: "customer",
  Usage: "usage",
};

export const businessServiceURL = {
  Overview: "overview",
  Info: "info",
  BookingSlot: "booking-slot",
  ServicePeriod: "service-period",
  BookingPeriod: "booking-period",
  Resources: "resources",
  Staff: "staff",
  Provider: "get-providers",
  Location: "location",
  Customer: "customer",
  Custom: "custom",
  Advanced: "advanced",
};
export const businessNewServiceURL = {
  Create: "/business/new-service",
  Info: "/business/new-service/info",
  BookingSlot: "/business/new-service/booking-slot",
  ServicePeriod: "/business/new-service/service-period",
  BookingPeriod: "/business/new-service/booking-period",
  Resources: "/business/new-service/resources",
  Provider: "/business/new-service/get-providers",
  Customer: "/business/new-service/customer",
};
export const businessResourceURL = {
  Overview: "overview",
  Info: "info",
  BookingSlot: "booking-slot",
  ServicePeriod: "service-period",
  BookingPeriod: "booking-period",
  Staff: "staff",
  Location: "location",
  Customer: "customer",
  Custom: "custom",
};
export const businessNewResourceURL = {
  Create: "/business/new-resource",
  Info: "/business/new-resource/info",
  BookingSlot: "/business/new-resource/booking-slot",
  ServicePeriod: "/business/new-resource/service-period",
  BookingPeriod: "/business/new-resource/booking-period",
  Customer: "/business/new-resource/customer",
};
export const businessProviderURL = {
  Info: "info",
  AvailablePeriod: "hours",
  Services: "services",
};
export const businessNewProviderURL = {
  Create: "/business/new-provider",
  Info: "/business/new-provider/info",
  AvailablePeriod: "/business/new-provider/hours",
  BookingSlot: "/business/new-provider/booking-slot",
  ServicePeriod: "/business/new-provider/service-period",
  BookingPeriod: "/business/new-provider/booking-period",
  Customer: "/business/new-provider/customer",
};
