import classNames from "classnames";
import styles from "./TimePicker.module.scss";
import { StateTime } from "./useTimePicker";

// type RefType<T> = React.RefObject<Array<T>>;
type RefType = React.RefObject<(HTMLLIElement | null)[]>;

const useHourList = (
  time: StateTime,
  // hourRef: RefType<HTMLLIElement | null>,
  hourRef: RefType | any,
  handleChange: (type: "hour" | "min" | "ampm", value: number) => void,
  disabledHours?: number[]
) => {
  let hourList: object[] = [];
  let lastDisabledHour = { hour: 0, ampm: "" };
  const disabledHoursAM: number[] = []; // Collection of disabled hour in AM
  const disabledHoursPM: number[] = []; // Collection of disabled hour in PM
  if (disabledHours && disabledHours.length > 0) {
    const lastDisabledHourItem = disabledHours[disabledHours.length - 1] + 1;
    if (lastDisabledHourItem > 12) {
      lastDisabledHour = { hour: lastDisabledHourItem - 12, ampm: "pm" };
    } else {
      lastDisabledHour = { hour: lastDisabledHourItem, ampm: "am" };
    }
    disabledHours.forEach((item) => {
      item < 13 ? disabledHoursAM.push(item) : disabledHoursPM.push(item - 12);
    });
  }
  for (let i: number = 1; i <= 12; i++) {
    const disabled =
      time.ampm === "am"
        ? disabledHoursAM.includes(i)
        : disabledHoursPM.includes(i);
    hourList[i] = (
      <li
        key={i}
        ref={(el) => (hourRef.current[i] = el)}
        role="button"
        className={classNames(
          disabled && styles["disabled"],
          time.hour === i && styles["selected"]
        )}
        onClick={() => (!disabled ? handleChange("hour", i) : false)}
      >
        {i}
      </li>
    );
  }
  const currentDisabledHour =
    time.hour === lastDisabledHour.hour && time.ampm === lastDisabledHour.ampm
      ? true
      : false;
  return { hourList, currentDisabledHour };
};

export default useHourList;
