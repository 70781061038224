import { useState } from "react";
import { DropdownAction } from "../ui/dropdown";
import CustomerRemoveDialog from "./CustomerRemoveDialog";

import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegCalendarPlus } from "react-icons/fa";
import ModalAdminCreateBooking from "../createBooking/ModalAdminCreateBooking";
import { ICustomerDataType } from "../../config/types/CustomerDataType";

interface TableCustomerActionsProps {
  id: string;
  onAddNote(id: string, arg: boolean): void;
  customer: ICustomerDataType;
}

const TableCustomerActions: React.FC<TableCustomerActionsProps> = ({
  id,
  onAddNote,
  customer,
}) => {
  const [dialog, setDialog] = useState(false);
  const [createBooking, setCreateBooking] = useState(false);
  const menu = [
    // {
    //   icon: <FaRegStickyNote />,
    //   name: "Add Note",
    //   onClick: () => onAddNote(id, true),
    //   desc: "Add a note for your own reference",
    // },
    {
      icon: <FaRegCalendarPlus />,
      name: "Create Booking",
      onClick: () => setCreateBooking(true),
      desc: "Create a new booking for this customer",
    },
    {
      icon: <RiDeleteBin6Line />,
      name: "Remove Customer",
      onClick: () => setDialog(true),
      desc: "Remove this customer from your list",
    },
  ];
  return (
    <>
      <DropdownAction menu={menu} />
      {dialog && (
        <CustomerRemoveDialog setVisible={(arg) => setDialog(arg)} id={id} />
      )}
      {createBooking && (
        <ModalAdminCreateBooking
          onClose={() => setCreateBooking(false)}
          selectedCustomer={customer}
        />
      )}
    </>
  );
};

export default TableCustomerActions;
