import { monthNameShort, monthName, dayName } from "../config/dateTimeFormat";

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);
export const todayString = getDate(new Date(), "YYYY MM DD", true);
export const thisMonthString = getDate(new Date(), "YYYY MM", true);

export function getDate(
  value: string | Date | number,
  format: string = "DD MMM YYYY",
  dash?: boolean
): string {
  const date = value instanceof Date ? value : new Date(value);
  const formatArray = format.split(" ");
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  let output: string = "";
  formatArray.forEach((el, index) => {
    if (index > 0 && !dash) output += " ";
    else if (index > 0 && dash) output += "-";
    if (el === "DD") output += day.toString().padStart(2, "0");
    else if (el === "MM") output += (month + 1).toString().padStart(2, "0");
    else if (el === "MMM") output += monthNameShort[month];
    else if (el === "MMMM") output += monthName[month];
    else if (el === "YYYY") output += year.toString();
    else if (el === "YY")
      output += date.getFullYear().toString().padStart(2, "0");
  });
  return output;
}

// Convert date time format to time
export function getTime(value: string | Date): string {
  const time = value instanceof Date ? value : new Date(value);
  const hour = time.getHours();
  const minute = time.getMinutes().toString();
  let hour12 = hour > 12 ? hour - 12 : hour;
  hour12 = hour12 === 0 ? 12 : hour12;
  const ampm = hour >= 12 ? "pm" : "am";
  return hour12 + ":" + minute.padStart(2, "0") + " " + ampm;
}

// Convert date time format to day name
export function getDayName(value: string | Date | number): string {
  const newDate = value instanceof Date ? value : new Date(value);
  return dayName[newDate.getDay()];
}

export function isToday(value: string | Date): boolean {
  const newDate = value instanceof Date ? value : new Date(value);
  // console.log("today", newDate.getMonth(), TODAY.getMonth());
  return (
    newDate.getDate() === TODAY.getDate() &&
    newDate.getMonth() === TODAY.getMonth() &&
    newDate.getFullYear() === TODAY.getFullYear()
  );
}

export function isPast(value: string | Date): boolean {
  const newDate = value instanceof Date ? value : new Date(value);
  // const today = new Date();
  return newDate < TODAY;
}

export function isUpcoming(value: string | Date): boolean {
  const newDate = value instanceof Date ? value : new Date(value);
  const today = new Date();
  // console.log(new Date(today.setDate(today.getDate() + 1)));
  // return newDate > new Date(today.setDate(today.getDate() + 1));
  return newDate > TODAY;
}

export enum EMeetupDay {
  today = 3,
  upcoming = 5,
  past = 1,
}

export function checkMeetupDay(value: string | Date): number {
  if (isToday(value)) return EMeetupDay.today;
  else if (isUpcoming(value)) return EMeetupDay.upcoming;
  else if (isPast(value)) return EMeetupDay.past;
  else return 0;
}

// Convert 14:00 to 2:00pm
export function convertTimeString(value: string): string {
  const time = value.split(":");
  // const time = new Date(todayString + value);
  const hour = parseInt(time[0]);
  let hour12;
  if (hour > 12) hour12 = hour - 12;
  else if (hour === 0) hour12 = 12;
  else hour12 = hour;

  const ampm = hour > 11 ? "pm" : "am";
  if (time.length > 1) {
    const minute = time[1];
    return hour12 + ":" + minute.padStart(2, "0") + " " + ampm;
  }
  return hour12 + " " + ampm;
}

// Convert to {hour, minute, ampm}
export function convertTimeObject(value: Date | string): {
  hour: number;
  minute: number;
  ampm: string;
} {
  const time =
    value instanceof Date ? value : new Date(todayString + " " + value);
  const hour = time.getHours();
  const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
  const minute = time.getMinutes();
  const ampm = hour >= 12 ? "pm" : "am";
  // console.log({ hour: hour12, minute, ampm });
  return { hour: hour12, minute, ampm };
}

// Convert 11:30 to 1609471800000
export function convertToTimestamp(value: string | number) {
  const time = new Date(todayString + " " + value).getTime();
  return time;
}

// Generate 24 hours: 12am, 1am, 2am... 11pm
export function generate24Hours() {
  let dayHours: string[] = [];
  for (let i = 0; i < 24; i++) {
    if (i === 0) {
      dayHours.push("12 AM");
    } else if (i === 12) {
      dayHours.push("12 PM");
    } else if (i < 12) {
      dayHours.push(i + " AM");
    } else {
      dayHours.push(i - 12 + " PM");
    }
  }
  return dayHours;
}

export function timeSince(value: string) {
  const date = new Date(value).getTime();
  // const seconds = Math.floor(new Date().getTime() / 1000 - date);
  const seconds = Math.floor((new Date().getTime() - date) / 1000);
  // const seconds = Math.floor(new Date().getTime()/1000 - date);

  let interval = seconds / 31536000;

  if (interval >= 1) {
    const year = Math.floor(interval);
    return year > 1 ? `${year} years ago` : `${year} year ago`;
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    const month = Math.floor(interval);
    return month > 1 ? `${month} months ago` : `${month} month ago`;
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    const day = Math.floor(interval);
    return day > 1 ? `${day} days ago` : `${day} day ago`;
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    const hour = Math.floor(interval);
    return hour > 1 ? `${hour} hours ago` : `${hour} hours ago`;
  }
  interval = seconds / 60;
  if (interval >= 1) {
    const minute = Math.floor(interval);
    return minute > 1 ? `${minute} minutes ago` : `${minute} minute ago`;
  }
  return Math.floor(seconds) + " seconds ago";
}
