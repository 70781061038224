import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { businessSetupURL, businessURL } from "../../config/url";
import useToast from "../../components/ui/toasts/useToast";

const useBusinessSetup = (step: number) => {
  const { clearSetupState, businessCreated, getSelfBusiness } = useActions();
  const navigate = useNavigate();
  const { success, error } = useTypedSelector((state) => state.entities.setup);

  const { notify } = useToast();

  useEffect(() => {
    if (success) {
      clearSetupState();
      if (step === 1) {
        businessCreated();
        navigate(businessSetupURL.Contact);
      } else if (step === 2) {
        navigate(businessSetupURL.Hours);
      } else {
        console.log("success tep 3");
        getSelfBusiness(true);
        navigate(businessURL.BusinessDashboard);
      }
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      notify("error", error);
      clearSetupState();
    }
  }, [error]);
};

export default useBusinessSetup;
