import * as yup from "yup";
import { ICustomerDataType } from "../types/CustomerDataType";
import { emailField, nameField } from "./validationFields";

export const InitialValues = {
  // displayName: "",
  // email: "",
  // pin: "",
  // gender: "",
  // note: "",
  // customerId: "",
  // customerUser: "",

  _id: "",
  business: "",
  creator: "",
  displayName: "",
  customerUid: "",
  email: "",
  customer: "",
  gender: "",
  pin: "",
  note: "",
};
export const Validation = yup.object().shape({
  displayName: nameField.required("Customer's name is required"),
  email: emailField,
  note: yup.string().max(300),
});

// export interface ICreateBookingInputType {
//   displayName: string;
//   email?: string;
//   pin?: string;
//   gender?: string;
//   note: string;
//   customerId?: string;
//   customerUser?: string;
// }

export interface ICreateBookingCustomerInputType {
  _id?: string;
  business?: string;
  creator?: string;
  displayName: string;
  customerUid?: string;
  email?: string;
  customer?: string;
  gender?: string;
  pin?: string;
  note?: string;
}
