import * as yup from "yup";
import { postcodeField } from "./validationFields";

// Initial Value
export const InitialValues = {
  address: "",
  address2: "",
  city: "",
  postcode: "",
  country: "",
  state: "",
};

// Validation
export const Validation = yup.object().shape({
  address: yup
    .string()
    .max(1024, "Maximum 1024 characters for address")
    .required("Please enter your Address"),
  address2: yup.string().max(200, "Maximum 200 characters for address"),
  city: yup
    .string()
    .max(100, "Maximum 100 characters for city")
    .required("Please enter your City"),
  postcode: postcodeField.required("Please enter your Postal Code"),
  country: yup
    .string()
    .max(100, "Maximum 100 characters for country")
    .required("Please enter your Country"),
  state: yup
    .string()
    .max(100, "Maximum 100 characters for state")
    .required("Please enter your State"),
});

// Type
export interface IOnsiteServiceInputType {
  address: string;
  address2?: string;
  city: string;
  postcode: string;
  country: string;
  state: string;
}
