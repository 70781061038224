import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FormikProps } from "formik";
import styles from "./CreateBookingCustomer.module.scss";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AppButton, AppForm, AppTextArea } from "../form";
import { Col, Row } from "../ui/Grid";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import {
  InitialValues,
  Validation,
  ICreateBookingCustomerInputType,
} from "../../config/form/formCreateBookingCustomer";
import CreateBookingDetails from "./CreateBookingDetails";
import { ILocationDataType } from "../../config/types/LocationDataType";
import CreateBookingExistingCustomer from "./CreateBookingExistingCustomer";
import CreateBookingNewCustomer from "./CreateBookingNewCustomer";
import CreateBookingSelectedCustomer from "./CreateBookingSelectedCustomer";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../ui/Loader";
import CreateBookingTitle from "./CreateBookingTitle";
import { ICustomerDataType } from "../../config/types/CustomerDataType";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { IOnsiteServiceInputType } from "../../config/form/formOnsiteService";
import { ESteps } from "./useCreateBooking";

const labelWidth = "90px";

interface CreateBookingCustomerProps {
  onNext(data: ICreateBookingCustomerInputType): void;
  onBack(toStep?: number): void;
  service: IServiceDataType;
  provider?: IProviderDataType;
  dateTime: Date;
  onsiteService?: IOnsiteServiceInputType;
  location: ILocationDataType;
  selectedCustomer?: ICreateBookingCustomerInputType;
}

const CreateBookingCustomer: React.FC<CreateBookingCustomerProps> = ({
  onNext,
  onBack,
  service,
  provider,
  dateTime,
  onsiteService,
  location,
  selectedCustomer,
}) => {
  const formikRef = useRef<FormikProps<any>>();
  const [initVal, setInitVal] = useState(InitialValues);
  const { getCustomers } = useActions();
  const { data: customers, request } = useTypedSelector(
    (state) => state.entities.customer
  );
  const [newCustomer, setNewCustomer] = useState(true);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    formikRef.current?.resetForm();
    formikRef.current?.setErrors({});
  }, [newCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      setInitVal({
        ...initVal,
        ...selectedCustomer,
      });
    }
  }, [selectedCustomer]);
  console.log("selectedCustomer", selectedCustomer);

  if (request) {
    return <Loader height="300px" />;
  }

  const customerFields = () => {
    if (!newCustomer && customers.length > 0)
      return <CreateBookingExistingCustomer data={customers} />;
    else return <CreateBookingNewCustomer />;

    // if (selectedCustomer)
    //   return <CreateBookingSelectedCustomer data={selectedCustomer} />;
    // else if (!newCustomer && customers.length > 0)
    //   return <CreateBookingExistingCustomer data={customers} />;
    // else return <CreateBookingNewCustomer />;
  };

  const customerToggle = () => {
    // if (selectedCustomer) return null;
    if (customers.length === 0) return null;
    else
      return (
        <button
          type="button"
          className={classNames("btn btn-link", styles["switch"])}
          onClick={() => {
            setNewCustomer(newCustomer ? false : true);
            setInitVal(InitialValues);
          }}
        >
          {newCustomer ? (
            <>
              Existing customer
              <FiChevronRight />
            </>
          ) : (
            <>
              <FiChevronLeft />
              New customer
            </>
          )}
        </button>
      );
  };

  return (
    <>
      <CreateBookingTitle
        title="Customer Info"
        onBack={() =>
          service.onsiteService ? onBack() : onBack(ESteps.SelectDateTime)
        }
      />
      <AppForm
        innerRef={formikRef}
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={(values) => onNext(values)}
        className={styles["container"]}
      >
        <Row>
          <Col width="50%">
            {customerFields()}
            <AppTextArea
              labelWidth={labelWidth}
              label="Note"
              name="note"
              helperMsg="Note for customer"
            />
          </Col>
          <Col width="42%">
            <CreateBookingDetails
              service={service}
              provider={provider}
              dateTime={dateTime}
              location={location}
              onsiteService={onsiteService}
            />
          </Col>
        </Row>
        <Row>
          <Col>{customerToggle()}</Col>
          <Col>
            <div className="float-right">
              <AppButton className="btn btn-primary" size="large" type="submit">
                Submit
              </AppButton>
            </div>
          </Col>
        </Row>
      </AppForm>
    </>
  );
};

export default CreateBookingCustomer;
