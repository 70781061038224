import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getTodayIncompleteBusinessBooking } from "../../store/selector";
import styles from "./useBusinessWalkInReg.module.scss";

const useBusinessWalkInReg = () => {
  const incompleteBookings = useTypedSelector(
    getTodayIncompleteBusinessBooking
  );

  function numberOfWaiting(bookingTypeId: string) {
    return (
      <>
        <span className={styles["desc"]}>Next available slot</span>{" "}
        <span className={styles["label"]}>10:30 am</span>
      </>
    );
    return (
      <>
        <span className={styles["label"]}>5+</span>{" "}
        <span className={styles["desc"]}>waiting</span>
      </>
    );
  }

  return { numberOfWaiting };
};

export default useBusinessWalkInReg;
