import { useParams } from "react-router-dom";
import styles from "./BusinessServingSinglePage.module.scss";
import PageDisplay from "../../components/layout/PageDisplay";
import useBusinessServingSinglePage from "./useBusinessServingSinglePage";
import { getTime } from "../../utils/datetime";
import Loader from "../../components/ui/Loader";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const BusinessServingSinglePage: React.FC = () => {
  const { id, type } = useParams() as { type: string; id: string };
  const { loading, servingList, entity } = useBusinessServingSinglePage(
    type,
    id
  );
  useDocumentTitle("Now Serving");

  if (loading) return <Loader />;

  return (
    <PageDisplay title={entity?.name}>
      <div className={styles["container"]}>
        <div className={styles["main"]}>
          <div className={styles["wrapper"]}>
            {servingList.length > 0 ? (
              <>
                <div className={styles["number"]}>{servingList[0].queueNo}</div>
                {/* TODO: if virtual queue, hide time */}
                <div className={styles["time"]}>
                  {getTime(servingList[0].booking.meetDateTime)}
                </div>
              </>
            ) : (
              <div className="h1">Welcome</div>
            )}
          </div>
        </div>
        {servingList.length > 1 && (
          <div className={styles["next-list"]}>
            <h5>Next</h5>
            <ul>
              {servingList.map((item, index) => {
                if (index === 0) return;
                return (
                  <li
                    key={index}
                    className={index % 2 === 0 ? styles["alternative"] : ""}
                  >
                    <div className={styles["number"]}>{item.queueNo}</div>
                    <div className={styles["time"]}>
                      {getTime(item.booking.meetDateTime)}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </PageDisplay>
  );
};

export default BusinessServingSinglePage;
