import { useEffect } from "react";
import { Col, Row } from "../../components/ui/Grid";
import Guideline from "../../components/ui/Guideline";
import PageWithAside from "../../components/layout/PageWithAside";
import { LayoutFullHeight } from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessLocationList from "../../components/businessProfile/BusinessLocationList";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 4;
const BusinessLocationsPage: React.FC = () => {
  useDocumentTitle("Profile - AddPointment");
  const { clearLocationState, getLocation } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.location
  );

  const { header } = useBusinessProfile(index, true);
  const { notify } = useToast();

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearLocationState();
    }
    if (error) {
      notify("error", error);
      clearLocationState();
    }
  }, [success, error]);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <LayoutFullHeight>
        <div className="wrapper">
          {header()}
          {request?.loading && <Loader />}
          {request?.error && <div className="error-msg">{request.error}</div>}
          {!request && data && (
            <Row>
              <Col width="60%">
                <BusinessLocationList />
              </Col>
              <Col width="30%">
                <Guideline title="Location">
                  <p>
                    You can have more than one location for customer to select
                    when they are making a booking.
                  </p>
                </Guideline>
              </Col>
            </Row>
          )}
        </div>
      </LayoutFullHeight>
    </PageWithAside>
  );
};

export default BusinessLocationsPage;
