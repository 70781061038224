import classNames from "classnames";
import styles from "./DropdownItemList.module.scss";

export type DropdownItemListType = {
  name?: string;
  desc?: string;
  icon?: JSX.Element;
  separator?: boolean;
  label?: string;
  onClick?(): void;
  disabled?: boolean;
};

interface DropdownItemListProps {
  menuList?: DropdownItemListType[];
  selected?: string;
}

const DropdownItemList: React.FC<DropdownItemListProps> = ({
  menuList,
  selected,
}) => {
  return (
    <div className={styles["menu"]}>
      <ul>
        {menuList?.map((item, index) => {
          if (item.separator)
            return <li key={index} className={styles["separator"]}></li>;
          if (item.label)
            return (
              <li key={index} className={styles["label"]}>
                {item.label}
              </li>
            );
          return (
            <li
              key={index}
              onClick={(e) => {
                if (item.onClick != undefined && !item.disabled) {
                  // e.stopPropagation();
                  console.log("clicked");
                  // item.onClick(item, index);
                  item.onClick();
                }
              }}
            >
              <div
                className={classNames(
                  styles["item"],
                  item.disabled && styles["disabled"],
                  item.name === selected && styles["selected"]
                )}
                role="button"
              >
                {item.icon && <div className={styles["icon"]}>{item.icon}</div>}
                <div className={styles["action"]}>
                  <div className="h5">{item.name}</div>
                  {item.desc && <div className="desc">{item.desc}</div>}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropdownItemList;
