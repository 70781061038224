import PageWithAside from "../../components/layout/PageWithAside";
import { Col, Row } from "../../components/ui/Grid";
import MenuCreateNew from "../../components/ui/MenuCreateNew";
import DashboardTodayBooking from "../../components/business/DashboardTodayBooking";
import DashboardGuide from "../../components/business/DashboardGuide";
// import DashboardCapacity from "../../components/business/DashboardCapacity";

import Header from "../../components/layout/Header";
import DashboardQuickLinks from "../../components/business/DashboardQuickLinks";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useBusinessRegisterStep from "../../hooks/useBusinessRegisterStep";
import Loader from "../../components/ui/Loader";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";

const BusinessDashboardPage: React.FC = () => {
  const { getAdminServices } = useActions();
  const { data: services } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { loading } = useBusinessRegisterStep();
  useEffect(() => {
    getAdminServices();
  }, []);

  if (loading) return <Loader height="80vh" />;

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header pageTitle="Dashboard" role="business" />
        <Row>
          <Col width="62.5%">
            {services.length > 0 && (
              <div className="mb-30">
                <DashboardTodayBooking />
              </div>
            )}
            <DashboardGuide isNew={services.length === 0} />
          </Col>
          <Col width="30%">
            {/* {services.length > 0 && (
              <div className="mb-30">
                <DashboardCapacity />
              </div>
            )} */}
            <DashboardQuickLinks />
          </Col>
        </Row>
      </div>
      <MenuCreateNew role="business" />
    </PageWithAside>
  );
};

export default BusinessDashboardPage;
