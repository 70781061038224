import { useEffect, useState } from "react";
// import Tab from "../ui/tab";
// import Calendar from "../ui/calendar";
import ScheduleList from "./ScheduleList";
// import ScheduleDay from "./ScheduleDay";
// import ScheduleWeek from "./ScheduleWeek";
import ModalBooking from "../businessBooking/ModalBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

const BusinessSchedule: React.FC = () => {
  const { singleDay, upcoming } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const [selectedId, setSelectedId] = useState<string | null>();
  const { clearBusinessBookingSingleDayState } = useActions();
  const [dateSelected, setDateSelected] = useState(false);
  // console.log("selectedId", selectedId);
  useEffect(() => {
    return () => {
      clearBusinessBookingSingleDayState();
    };
  }, []);

  // const tabItems = [
  //   {
  //     tabID: 0,
  //     name: "List",
  //     content: <ScheduleList onApptView={handleClick} />,
  //   },
  //   {
  //     tabID: 1,
  //     name: "Day",
  //     content: <ScheduleDay data={data} onApptView={handleClick} />,
  //   },
  //   {
  //     tabID: 2,
  //     name: "Week",
  //     content: <ScheduleWeek data={data} />,
  //   },
  //   {
  //     tabID: 3,
  //     name: "Month",
  //     content: <Calendar type="full" />,
  //   },
  // ];
  // console.log("singleDay -----", singleDay);

  return (
    <>
      {/* <Tab
        type="horizontal"
        items={tabItems}
        onTabClick={(id: number) => setActiveTab(id)}
        active={activeTab}
      >
        {tabItems[activeTab]["content"]}
      </Tab> */}
      <ScheduleList
        data={
          singleDay.data && dateSelected
            ? (singleDay.data as IBookingDataType[])
            : (upcoming.data as IBookingDataType[])
        }
        onDateSelected={(arg) => setDateSelected(arg)}
        onApptView={(id: string) => setSelectedId(id)}
      />

      {selectedId && (
        <ModalBooking id={selectedId} onClose={() => setSelectedId(null)} />
      )}
    </>
  );
};

export default BusinessSchedule;
