import * as yup from "yup";
import {
  InitialValues as ChangeEmailInitialValues,
  IChangeEmailInputType,
} from "./formChangeEmail";
import { objectId } from "./patterns";
import { ETokenType } from "../types/TokenType";

export const InitialValues = {
  ...ChangeEmailInitialValues,
  tokenId: "",
  code: "",
  tokenType: ETokenType.verifyEmail,
};
export const Validation = yup.object().shape({
  code: yup
    .string()
    .required("Please enter the verification code")
    .min(6, "Invalid code")
    .max(6, "Invalid code"),
  tokenId: yup
    .string()
    .required("Missing token. Please request a new one")
    .matches(objectId, "Invalid token. Please request a new one"),
});

export interface IChangeEmailVerifyInputType extends IChangeEmailInputType {
  code: string;
  tokenId: string;
  tokenType: number;
}
