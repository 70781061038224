import { createSelector } from "reselect";
import { ResourceType } from "../../config/types/ResourceType";

export const getWalkInResources = createSelector(
  (state: { entities: { resourceAdmin: { data: ResourceType[] } } }) =>
    state.entities.resourceAdmin.data,
  (resources) => null
  // resources
  //   ? resources.filter((resourceAdmin) => resourceAdmin.walkIn === true)
  //   : null
);
