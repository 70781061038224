import * as yup from "yup";
import { EReCaptchaType } from "../types/ReCaptchaType";
import { countryField, emailField, passwordField } from "./validationFields";

// Initial Value
export const InitialValues = {
  country: "",
  email: "",
  password: "",
  recaptchaType: EReCaptchaType.Register,
  recaptchaCode: "",
};

// Validation
export const Validation = yup.object().shape({
  country: countryField.required("Please enter your country"),
  email: emailField.required("Please enter your email"),
  password: passwordField.required("Please enter your password"),
});

// Type
export interface IRegisterInputType {
  country: string;
  email: string;
  password: string;
  recaptchaType: string;
  recaptchaCode: string;
}
