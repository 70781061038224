import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServicesCategoryDataType } from "../../config/types/ServiceCategoryDataType";

interface ServiceCategoryState {
  loading: boolean;
  error?: string;
  success?: boolean;
  data: IServicesCategoryDataType[];
  lastFetch?: Date | number;
  newCategory?: string;
}

const initialState: ServiceCategoryState = {
  loading: false,
  error: undefined,
  success: false,
  data: [],
  lastFetch: undefined,
  newCategory: undefined,
};

const slice = createSlice({
  name: "serviceCategoryAdmin",
  initialState,
  reducers: {
    serviceCategoryClearState: (category) => {
      category.loading = false;
      category.error = undefined;
      category.success = false;
      category.newCategory = undefined;
    },
    serviceCategoryRequest: (category) => {
      category.loading = true;
    },
    serviceCategoryFailed: (category, action: PayloadAction<string>) => {
      category.loading = false;
      category.error = action.payload;
    },
    serviceCategoryReceived: (
      category,
      action: PayloadAction<IServicesCategoryDataType[]>
    ) => {
      console.log("getCategory", action.payload);
      category.loading = false;
      category.data = action.payload;
      category.lastFetch = Date.now();
    },
    serviceCategoryAdded: (
      category,
      action: PayloadAction<IServicesCategoryDataType>
    ) => {
      const newCategory = action.payload;
      console.log("addCategory", action.payload);
      category.loading = false;
      category.data = [newCategory, ...category.data];
      category.newCategory = newCategory._id;
    },
    serviceCategoryRemoving: (category, action: PayloadAction<string>) => {
      console.log("categoryRemoving", action.payload);
      category.loading = true;
      category.success = undefined;
      category.error = undefined;
      category.data = category.data?.filter(
        (item) => item._id !== action.payload
      );
    },
    serviceCategoryRemoved: (category) => {
      // category.success = true;
    },
    serviecCategoryRemoveFailed: (
      category,
      action: PayloadAction<{
        error: string;
        actionData: IServicesCategoryDataType[];
      }>
    ) => {
      category.loading = false;
      category.data = action.payload.actionData;
      category.error = action.payload.error;
    },
    serviceCategoryUpdating: (
      category,
      action: PayloadAction<IServicesCategoryDataType>
    ) => {
      console.log("categoryUdating", action.payload);
      category.loading = true;
      category.success = undefined;
      category.error = undefined;
      category.data = category.data?.map((i) => {
        if (i._id === action.payload._id) return action.payload;
        return i;
      });
    },
    serviceCategoryUpdated: (category) => {
      // category.success = true;
    },
    serviecCategoryUpdateFailed: (
      category,
      action: PayloadAction<{
        error: string;
        actionData: IServicesCategoryDataType[];
      }>
    ) => {
      category.loading = false;
      category.data = action.payload.actionData;
      category.error = action.payload.error;
    },
  },
});

export const {
  serviceCategoryClearState,
  serviceCategoryRequest,
  serviceCategoryFailed,
  serviceCategoryReceived,
  serviceCategoryAdded,
  serviceCategoryRemoving,
  serviceCategoryRemoved,
  serviecCategoryRemoveFailed,
  serviceCategoryUpdating,
  serviceCategoryUpdated,
  serviecCategoryUpdateFailed,
} = slice.actions;

export default slice.reducer;
