import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import {
  InitialValues,
  IProviderInputType,
  Validation,
} from "../../config/form/formProvider";
import useBusinessProviderPage from "./useBusinessProviderPage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import ProviderInfo from "../../components/providers/ProviderInfo";

const ProviderInfoPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const [initVal, setInitVal] = useState<IProviderInputType>(InitialValues);
  const { request, data } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const { provider, submenu, header, footer } = useBusinessProviderPage(id);

  const { updateAdminProvider } = useActions();

  useEffect(() => {
    if (provider) {
      setInitVal({
        ...initVal,
        ...provider,
      });
    }
  }, [provider]);

  return (
    <PageWithAside
      role="business"
      submenuName="Provider"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessProviders}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && provider && (
          <AppForm
            initialValues={initVal}
            onSubmit={(values, actions) => {
              console.log(values);
              actions.setSubmitting(false);
              updateAdminProvider(values);
            }}
            validationSchema={Validation}
          >
            <ProviderInfo provider={provider} />
            {footer}
          </AppForm>
        )}
      </div>
    </PageWithAside>
  );
};

export default ProviderInfoPage;
