import React from "react";
import { FaChevronDown } from "react-icons/fa";
import styles from "./LoadMoreButton.module.scss";

interface ILoadMoreButtonProps {
  onClick(): void;
  loading?: boolean;
}

const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  onClick,
  loading,
}) => {
  return (
    <div className="py-10">
      <button
        className={`btn btn-link ${styles["btn"]}`}
        type="button"
        onClick={() => onClick()}
        disabled={loading}
      >
        Load More
        <FaChevronDown />
      </button>
    </div>
  );
};

export default LoadMoreButton;
