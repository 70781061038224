import { Col, Row } from "../ui/Grid";
import CustomDetails from "./CustomDetails";
import { useState } from "react";
import CalendarMini from "../ui/CalendarMini";
import { getDate } from "../../utils/datetime";

const ServiceCustom: React.FC = () => {
  const [dateTitle, setDateTitle] = useState(new Date());

  return (
    <>
      <h3 className="mb-3">Custom</h3>
      <div className="desc mb-20">
        Customise the capacity and service hour for certain day.
      </div>
      <Row>
        <Col width="60%">
          <CalendarMini onChange={(val: any) => setDateTitle(val)} />
        </Col>
        <Col width="40%">
          <CustomDetails title={getDate(dateTitle, "DD MMMM YYYY")} />
        </Col>
      </Row>
    </>
  );
};

export default ServiceCustom;
