import * as yup from "yup";
import { EReCaptchaType } from "../types/ReCaptchaType";
import { emailField } from "./validationFields";

// Initial Value
export const InitialValues = {
	username: "",
	password: "",
	recaptchaType: EReCaptchaType.Login,
	recaptchaCode: "",
	// remember: false,
};

// Validation
export const Validation = yup.object().shape({
	username: emailField.required("Please enter your email"),
	password: yup.string().required("Please enter your password"),
});

// Type
export interface ILoginInputType {
	username: string;
	password: string;
	recaptchaType: string;
	recaptchaCode: string;
	// remember: boolean;
}
