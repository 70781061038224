import useDocumentTitle from "../../hooks/useDocumentTitle";
import PageWithSteps from "../../components/layout/PageWithSteps";
import {
  LayoutBody,
  LayoutFullHeight,
  LayoutHeader,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessHours from "../../components/businessProfile/BusinessHours";
import { AppForm } from "../../components/form";
import { InitialValues, Validation } from "../../config/form/formBusinessHours";
import { useActions } from "../../hooks/useActions";
import useBusinessSetup from "./useBusinessSetup";
import { setupBusiness } from "../../config/steps";
import BusinessSetupFooter from "../../components/business/BusinessSetupFooter";

const BusinessSetupHoursPage: React.FC = () => {
  useDocumentTitle("Create a Business - AddPointment");
  const { header } = useBusinessProfile(2);
  const { setupHours } = useActions();
  useBusinessSetup(3);

  return (
    <PageWithSteps activeStep={2} steps={setupBusiness}>
      <AppForm
        initialValues={InitialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          setupHours(values);
        }}
        validationSchema={Validation}
        className="flex-layout"
      >
        <LayoutFullHeight>
          <LayoutHeader title="New Business" />
          <LayoutBody>
            {header()}
            <BusinessHours />
          </LayoutBody>
          <BusinessSetupFooter step={3} />
        </LayoutFullHeight>
      </AppForm>
    </PageWithSteps>
  );
};

export default BusinessSetupHoursPage;
