import * as yup from "yup";
import { defaultHours, defaultHoursSum } from "../option/optionBusiness";
import { ILocationInfoType } from "../types/LocationDataType";
import { emailField, phoneField, postcodeField } from "./validationFields";

// Form - Business Contact
export const InitialValues = {
  // id: "",
  name: "",
  phone: "",
  hours: defaultHours,
  hoursSum: defaultHoursSum,
  email: "",
  website: "",
  address: "",
  address2: "",
  city: "",
  postcode: "",
  country: "",
  state: "",
  isMain: false,
};

export const Validation = yup.object().shape({
  name: yup
    .string()
    .max(50, "Maximum 50 characters for Location name")
    .required("Please enter location name"),
  phone: phoneField,
  email: emailField,
  website: yup.string().max(1024, "Maximum 1024 characters for website url"),
  address: yup
    .string()
    .max(1024, "Maximum 1024 characters for address")
    .required("Please enter your Address"),
  address2: yup.string().max(200, "Maximum 200 characters for address"),
  city: yup
    .string()
    .max(100, "Maximum 100 characters for city")
    .required("Please enter your City"),
  postcode: postcodeField.required("Please enter your Postal Code"),
  country: yup
    .string()
    .max(100, "Maximum 100 characters for country")
    .required("Please enter your Country"),
  state: yup
    .string()
    .max(100, "Maximum 100 characters for state")
    .required("Please enter your State"),
  isMain: yup.boolean(),
});

export interface IBusinessContactInputType extends ILocationInfoType {}
