import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
	AppButton,
	AppForm,
	AppTextArea,
	AppInput,
	AppDatePicker,
	AppTimePicker,
} from "../form";
import { LayoutBody, LayoutFooter } from "../layout/LayoutFullHeight";
import { Row, Col } from "../ui/Grid";
import {
	EParticipantListType,
	InitialValues,
	IParticipantsInputType,
	Validation,
	ICreateMeetupInputType,
} from "../../config/form/formNewMeetup";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { personalURL } from "../../config/url";
import useToast from "../ui/toasts/useToast";
import { EBtnCategory } from "../form/AppButton";
import useParticipantList from "./useParticipantList";
import ModalAddFriend from "./ModalAddFriend";
import ListTable from "../ui/ListTable";

const TODAY = new Date();
// const TOMORROW = new Date();
// TOMORROW.setDate(TOMORROW.getDate() + 1);
const MAX = 10;

const CreateMeetup: React.FC = () => {
	const [addModal, setAddModal] = useState(false);
	const [error, setError] = useState("");
	const { createMeetup, clearPersonalBookingState } = useActions();
	const { participantList, panelList, handleAdd, handleDelete, participants } =
		useParticipantList();
	const {
		loading,
		success,
		error: resError,
	} = useTypedSelector((state) => state.entities.personalBooking);

	const { notify } = useToast();
	const navigate = useNavigate();
	const listFulled = participants.length >= MAX ? true : false;

	useEffect(() => {
		if (success) {
			notify("success", "Meetup has been created");
			clearPersonalBookingState();
			navigate(personalURL.PersonalSchedule);
		}
	}, [success]);

	useEffect(() => {
		if (resError) {
			setError(resError);
			clearPersonalBookingState();
		}
	}, [resError]);

	const handleSubmit = (values: ICreateMeetupInputType) => {
		setError("");
		console.log("participants", participants);
		if (participants.length === 0) {
			setError("At least 1 participant is required");
		} else {
			let listNewUser: IParticipantsInputType[] = [];
			let listNewFriends: IParticipantsInputType[] = [];
			let listFriends: IParticipantsInputType[] = [];
			participants.forEach((p) => {
				if (p.type === EParticipantListType.Friend) listFriends.push(p);
				else if (p.type === EParticipantListType.NewFriend)
					listNewFriends.push(p);
				else listNewUser.push(p);
			});
			createMeetup({
				...values,
				listNewUser,
				listNewFriends,
				listFriends,
			});
		}
	};

	return (
		<AppForm
			className='flex-layout'
			initialValues={InitialValues}
			validationSchema={Validation}
			onSubmit={(values, actions) => {
				handleSubmit(values);
				// actions.setSubmitting(false);
			}}>
			<LayoutBody>
				{error && <p className='error-msg'>{error}</p>}
				<Row>
					<Col width='45%' className='mb-30'>
						<h3>Details</h3>
						<AppInput
							autoFocus
							name='subject'
							label='Meetup Name'
							helperMsg='Choose a name that will give people a clear idea of what the meetup is about.'
						/>
						<AppDatePicker
							name='date'
							label='Date'
							minDate={TODAY}
							// initialValue={TOMORROW}
						/>
						<AppTimePicker
							name='time'
							label='Time'
							minuteStep={5}
							defaultValue='8:00'
						/>
						<AppInput name='location' label='Location' />
						<AppTextArea name='note' label='Note' row={4} />
					</Col>
					<Col width='45%'>
						<h3>Participants</h3>
						<ListTable
							data={participantList}
							panelList={!listFulled ? panelList : undefined}
							panelLabel='Add new friend'
							panelEmptyMsg='No result found.'
							label={!listFulled ? "Add participant" : undefined}
							onDelete={handleDelete}
							onClickPanel={() => setAddModal(true)}
						/>
						{listFulled && <p className='desc'>Maximum {MAX} participants</p>}
					</Col>
				</Row>
			</LayoutBody>
			<LayoutFooter backBtn>
				<div className='float-right'>
					<AppButton
						category={EBtnCategory.Primary}
						type='submit'
						loading={loading}>
						Create Meetup
					</AppButton>
				</div>
			</LayoutFooter>
			{/* <ModalAddParticipant onClose={() => console.log("close")} /> */}
			{addModal && (
				<ModalAddFriend
					onClose={() => setAddModal(false)}
					onAddFriend={handleAdd}
					participants={participants}
				/>
			)}
		</AppForm>
	);
};

export default CreateMeetup;
