import { useEffect, useState } from "react";
import { ModalBox, ModalHeader } from "../ui/modal";
import { useActions } from "../../hooks/useActions";
import useModalBookingContent from "./useModalBookingContent";
// import { useTypedSelector } from "../../hooks/useTypedSelector";
// import useBookingStatusResponse from "./useBookingStatusResponse";

interface ModalProps {
  id: string;
  onClose: () => void;
}

const ModalBooking: React.FC<ModalProps> = ({ id, onClose }) => {
  const { getSingleBusinessBooking } = useActions();
  const { modalBookingContent } = useModalBookingContent(onClose);
  // useBookingStatusResponse();

  useEffect(() => {
    id && getSingleBusinessBooking(id);
  }, [id]);

  return (
    <ModalBox onClose={onClose} type="business" overlayClose={true}>
      {modalBookingContent()}
    </ModalBox>
  );
};

export default ModalBooking;
