import { useEffect, useState } from "react";
import useGetBookingType from "../../hooks/useGetBookingType";
import { DropdownItemListType } from "../ui/dropdown/DropdownItemList";

const useFilterBookings = (
  onClick: (id?: string, type?: string) => void,
  label?: string
) => {
  const { services, resources, providers } = useGetBookingType();

  const [list, setList] = useState<DropdownItemListType[]>();
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    let filtered: DropdownItemListType[] = [];
    if (services && services.length > 0) {
      // filtered.push({
      //   label: "Services",
      // });
      services.forEach((s) => {
        filtered.push({
          name: s.name,
          onClick: () => {
            onClick(s.serviceUid, "service");
            setSelected(s.name);
          },
        });
      });
    }
    // if (resources && resources.length > 0) {
    //   filtered.push({
    //     label: "Resources",
    //   });
    //   resources.forEach((r) => {
    //     filtered.push({
    //       name: r.name,
    //       onClick: () => {
    //         onClick(r._id, "resource");
    //         setSelected(r.name);
    //       },
    //     });
    //   });
    // }
    // if (providers && providers.length > 0) {
    //   filtered.push({
    //     label: "Providers",
    //   });
    //   providers.forEach((p) => {
    //     filtered.push({
    //       name: p.name,
    //       onClick: () => {
    //         onClick(p._id, "provider");
    //         setSelected(p.name);
    //       },
    //     });
    //   });
    // }
    if (filtered.length > 0 && !label) {
      filtered.unshift({
        name: "All",
        onClick: () => {
          onClick();
          setSelected("All");
        },
      });
    }
    setList(filtered);
    // }, [services, resources, providers, label]); // eslint-disable-line react-hooks/exhaustive-deps
  }, [services, label]); // eslint-disable-line react-hooks/exhaustive-deps

  return { list, selected };
};

export default useFilterBookings;
