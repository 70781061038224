import { Link } from "react-router-dom";
import ListItem from "../ui/ListItem";
import styles from "./ServiceOverview.module.scss";
import { businessServiceURL, businessURL } from "../../config/url";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import useBusinessService from "./useBusinessService";

interface ServiceOverviewProps {
  data: IServiceDataType;
  id: string;
}

const serviceURL = businessURL.BusinessServices;

const ServiceOverview: React.FC<ServiceOverviewProps> = ({ data, id }) => {
  const { getBookingTypeName, getServiceHourName } = useBusinessService();
  console.log(data);

  const serviceHourName = getServiceHourName(data.servicePeriodOption);
  const bookingPeriod = () => {
    if (data.walkIn && data.onlineBooking) return `Online booking & walk-in`;
    else if (data.walkIn) return "Walk-in";
    else if (data.onlineBooking) return "Online Booking";
    else return "Disabled";
    // return "Disabled";
  };
  const slotInterval =
    data.slotInterval === -1 ? "All-day" : `${data.slotInterval} minutes`;

  const slotURL = `${serviceURL}/${id}/${businessServiceURL.BookingSlot}`;

  const overviewList = [
    {
      label: "Booking Type",
      value: getBookingTypeName(data.bookingType),
    },
    {
      label: "Label",
      value: data.label,
    },
    // {
    //   label: "Category",
    //   value: data.category,
    // },
    {
      label: "Duration",
      value: (
        <Link to={slotURL} className="link">
          {data.duration === -1 ? "Unlimited" : `${data.duration} minutes`}
        </Link>
      ),
    },
    {
      label: "Booking Slot",
      value: (
        <Link to={slotURL} className="link">
          {slotInterval}, {data.bookingCapacity} booking per slot
        </Link>
      ),
      // editable: () => {
      //   console.log("Edit button is clicked!!!");
      // },
    },
    // {
    //   label: "Slot Selection",
    //   value: (
    //     <Link to={slotURL} className="link">
    //       Maximum {data.maxSlotSelection} slot(s) per booking
    //     </Link>
    //   ),
    // },
    {
      label: "Service Period",
      value: (
        <Link
          to={`${serviceURL}/${id}/${businessServiceURL.ServicePeriod}`}
          className="link"
        >
          {serviceHourName}
        </Link>
      ),
    },
    {
      label: "Booking Period",
      value: (
        <Link
          to={`${serviceURL}/${id}/${businessServiceURL.BookingPeriod}`}
          className="link"
        >
          {bookingPeriod()}
        </Link>
      ),
    },
    // {
    //   label: resourceName,
    //   value: (
    //     <Link
    //       to={`${serviceURL}/${id}/${businessServiceURL.Resources}`}
    //       className="link"
    //     >
    //       {data.resources
    //         ? `${data.resources.length} ${resourceName}(s)`
    //         : "Disabled"}
    //     </Link>
    //   ),
    // },
    // {
    //   label: "Staff",
    //   value: (
    //     <Link
    //       to={`${serviceURL}/${id}/${businessServiceURL.Staff}`}
    //       className="link"
    //     >
    //       {data.staff ? `${data.staff.length} staff(s)` : "Disabled"}
    //     </Link>
    //   ),
    // },
    // {
    //   label: "Location",
    //   value: (
    //     <Link
    //       to={`${serviceURL}/${id}/${businessServiceURL.Location}`}
    //       className="link"
    //     >
    //       {data.location ? `${data.location.length} location(s)` : "Disable"}
    //     </Link>
    //   ),
    // },
    // {
    //   label: "Customer",
    //   value: (
    //     <Link
    //       to={`${serviceURL}/${id}/${businessServiceURL.Customer}`}
    //       className="link"
    //     >
    //       {values["customerDatabase"] ? "Enabled" : "Disabled"}
    //     </Link>
    //   ),
    // },
  ];

  return (
    <div className={styles["wrapper"]}>
      <h3 className="mb-10">Service Overview</h3>
      {overviewList.map((val, index) => {
        return (
          <div className={styles["item"]} key={index}>
            <ListItem labelWidth="160px" label={val.label}>
              <div className={styles["value"]}>{val.value}</div>
            </ListItem>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceOverview;
