import * as reducers from "../reducer/providerAdminReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getProviders as testProviders } from "../../data/dataProviders";
import { IProviderDuplicateInputType } from "../../config/form/formProviderDuplicate";
import config from "../../config/global";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { IBusinessDataType } from "../../config/types/BusinessDataType";

interface DispatchType {
  type: string;
}

const url = "/provider-admin";

export const clearAdminProviderState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.providerClearState.type });
  };

export const getAdminProviders =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { providerAdmin: { lastFetch: number } } }
  ) => {
    const { lastFetch } = getState().entities.providerAdmin;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url: url + "/me",
        method: "get",
        data: config.localENV ? testProviders() : null,
        onStart: reducers.providerRequest.type,
        onSuccess: reducers.providerReceived.type,
        onError: reducers.providerRequestFailed.type,
      })
    );
  };

export const removeAdminProvider =
  (_id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => { entities: { providerAdmin: { data: IProviderDataType } } }
  ) => {
    console.log("removeProvider", _id);
    const { data } = getState().entities.providerAdmin;
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        data: { _id },
        method: "post",
        onStartDispatch: { type: reducers.providerRemoving.type, payload: _id },
        onSuccess: reducers.providerRemoved.type,
        onErrorDispatch: {
          type: reducers.providerRemoveFailed.type,
          payload: data,
        },
      })
    );
  };

export const duplicateAdminProvider =
  (data: IProviderDuplicateInputType, id: string) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { providerAdmin: { data: IProviderDataType[] } };
    }
  ) => {
    const { data: providers } = getState().entities.providerAdmin;

    let duplicatedData;
    if (providers) {
      const provider = providers.find((s) => s._id === id);
      duplicatedData = {
        ...provider,
        ...data,
        _id: undefined,
        providerUid: undefined,
      };
    }
    console.log(duplicatedData);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        data: duplicatedData,
        method: "post",
        onStart: reducers.providerLoading.type,
        onSuccess: reducers.providerDuplicated.type,
        onError: reducers.providerFailed.type,
      })
    );
  };

export const addAdminProvider =
  (data: IProviderDataType) =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: {
        businessAdmin: { data: IBusinessDataType };
      };
    }
  ) => {
    const { data: business } = getState().entities.businessAdmin;
    const providerData = {
      name: data.name,
      profession: data.profession,
      title: data.title,
      desc: data.desc,
      businessUrl: business.businessUrl,
      hours: data.hours,
      hoursOption: data.hoursOption,
    };
    console.log("addProvider", providerData);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        method: "post",
        data: providerData,
        onStart: reducers.providerLoading.type,
        onSuccess: reducers.providerAdded.type,
        onError: reducers.providerFailed.type,
      })
    );
  };

export const updateAdminProvider =
  (data: IProviderDataType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("data", data);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        data,
        method: "post",
        onStart: reducers.providerLoading.type,
        onSuccess: reducers.providerUpdated.type,
        onError: reducers.providerFailed.type,
      })
    );
  };

export const uploadAdminProviderPhoto =
  (photo: string, _id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("uploadAdminProviderPhoto", _id);
    return dispatch(
      apiCallBegan({
        url: url + "/upload-photo",
        data: { photo, _id },
        method: "post",
        onStart: reducers.providerPhotoUploading.type,
        onSuccess: reducers.providerPhotoUploaded.type,
        onError: reducers.providerPhotoUploadFailed.type,
      })
    );
  };

export const removeAdminProviderService =
  (_id: string, serviceId: string) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("removeProviderServices", _id, serviceId);
    return dispatch(
      apiCallBegan({
        url: url + "/remove-service",
        data: { _id, serviceId },
        method: "post",
        onStart: reducers.providerLoading.type,
        onSuccess: reducers.providerServiceRemoved.type,
        onError: reducers.providerFailed.type,
      })
    );
  };
