import { AppHours } from "../form";
import Guideline from "../ui/Guideline";
import { Col, Row } from "../ui/Grid";
import LocationSelection from "./LocationSelection";

interface BusinessHoursProps {
  isEdit?: boolean;
}
const BusinessHours: React.FC<BusinessHoursProps> = ({ isEdit }) => {
  return (
    <Row>
      <Col width="70%">
        {isEdit && <LocationSelection />}
        <AppHours name="hours" hoursSumName="hoursSum" />
      </Col>
      <Col width="30%">
        <Guideline title="Business Hours">
          <p>
            Your business hours will determine the time you start and stop
            serving customer.
          </p>
        </Guideline>
      </Col>
    </Row>
  );
};

export default BusinessHours;
