import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import useBusinessProvider from "../providers/useBusinessProvider";
import useBusinessResource from "../resources/useBusinessResource";

interface ApptTypeProps {
  data: IBookingDataType & IMeetupDataType;
}

const ApptTypeName: React.FC<ApptTypeProps> = ({ data }) => {
  const { getResourceName } = useBusinessResource();
  const { getProviderName } = useBusinessProvider();
  return (
    <>
      {data.service && (
        <div>
          {/* <span className="text-secondary">Service: </span> */}
          {data.serviceData.name}
        </div>
      )}
      {/* {data.resource && (
        <div>
          <span className="text-secondary">
            {getResourceName(data.bookingType)}:{" "}
          </span>
          {data.resource.name}
        </div>
      )}
      {data.provider && (
        <div>
          <span className="text-secondary">
            {getProviderName(data.bookingType)}:{" "}
          </span>
          {data.provider.name}
        </div>
      )} */}
      {data.subject && (
        <div>
          {/* <span className="text-secondary">Meetup:</span>  */}
          Personal Meetup
        </div>
      )}
    </>
  );
};

export default ApptTypeName;
