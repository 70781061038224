import { DropdownAction } from "../ui/dropdown";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import usePersonalMeetupActions from "./usePersonalMeetupActions";

interface IPersonalMeetupActions {
  data: IMeetupDataType;
  onApptView?: (id: string) => void;
}

const PersonalMeetupActions: React.FC<IPersonalMeetupActions> = ({
  data,
  onApptView,
}) => {
  const { tableMenuList } = usePersonalMeetupActions(data, onApptView);
  return <DropdownAction menu={tableMenuList} />;
};

export default PersonalMeetupActions;
