import React from "react";
import useReleaseOutsite from "../../../hooks/useReleaseOutsite";
import styles from "./NotificationToggle.module.scss";
import { FaBell } from "react-icons/fa";

interface INotificationToggleProps {
  onOpen(): void;
  unopened: number;
}

const NotificationToggle: React.FC<INotificationToggleProps> = ({
  onOpen,
  unopened,
  children,
}) => {
  const { btnRef, wrapperRef, toggle, setToggle } = useReleaseOutsite();
  function onToggle() {
    if (toggle) {
      setToggle(false);
    } else {
      onOpen();
      setToggle(true);
    }
  }
  return (
    <>
      <div
        className={styles["container"]}
        role="button"
        onClick={() => onToggle()}
        ref={btnRef}
      >
        <div className={styles["btn"]}>
          <FaBell />
          {unopened > 0 && <div className={styles["badge"]}>{unopened}</div>}
        </div>
      </div>
      {toggle && (
        <div className={styles["list"]} ref={wrapperRef}>
          {children}
        </div>
      )}
    </>
  );
};

export default NotificationToggle;
