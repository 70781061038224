import classNames from "classnames";
import styles from "./TimePicker.module.scss";
import { StateTime } from "./useTimePicker";

type RefType = React.RefObject<HTMLDivElement>;

const useTimeList = (
  dropdownRef: RefType,
  hourList: object[],
  minuteList: object[],
  time: StateTime,
  handleChange: (type: "hour" | "min" | "ampm", value: number) => void,
  disabledHours?: number[],
  disabledMinutes?: number[]
) => {
  const disableAM = disabledHours?.includes(11);
  const timeList = (
    <div ref={dropdownRef} className={styles["timepicker"]}>
      <div className={styles["time-selection"]}>
        <ul className={classNames(styles["list"], styles["hour-list"])}>
          {hourList}
        </ul>
        <ul className={classNames(styles["list"], styles["minute-list"])}>
          {minuteList}
        </ul>

        <ul className={classNames(styles["list"], styles["ampm-list"])}>
          <li
            role="button"
            className={classNames(
              disableAM && styles["disabled"],
              time.ampm === "am" && styles["selected"]
            )}
            onClick={() => (!disableAM ? handleChange("ampm", 1) : false)}
          >
            AM
          </li>
          <li
            role="button"
            className={classNames(time.ampm === "pm" && styles["selected"])}
            onClick={() => handleChange("ampm", 2)}
          >
            PM
          </li>
        </ul>
      </div>
    </div>
  );
  return { timeList };
};

export default useTimeList;
