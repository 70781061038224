import React from "react";
import { RiServiceFill } from "react-icons/ri";
import { FiClock } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { ImUser } from "react-icons/im";
import { BiCalendar } from "react-icons/bi";
import { GrUserManager, GrLocation } from "react-icons/gr";
import styles from "./CreateBookingDetails.module.scss";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { getDate, getDayName, getTime } from "../../utils/datetime";
import Loader from "../ui/Loader";
import { IOnsiteServiceInputType } from "../../config/form/formOnsiteService";
import { ICreateBookingCustomerInputType } from "../../config/form/formCreateBookingCustomer";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import LocationDisplay from "../common/LocationDisplay";

const renderItem = (
  icon: JSX.Element,
  title: string,
  desc: string | JSX.Element
) => {
  return (
    <li>
      <div className={styles["icon"]}>{icon}</div>
      <div className={styles["info"]}>
        <h5>{title}</h5>
        <div className="desc">{desc}</div>
      </div>
    </li>
  );
};

interface CreateBookingDetailsProps {
  location?: ILocationDataType;
  onsiteService?: IOnsiteServiceInputType;
  service: IServiceDataType;
  provider?: IProviderDataType;
  customer?: ICreateBookingCustomerInputType;
  dateTime: Date;
}

const CreateBookingDetails: React.FC<CreateBookingDetailsProps> = ({
  service,
  provider,
  dateTime,
  location,
  customer,
  onsiteService,
}) => {
  return (
    <ul className={styles["booking-details"]}>
      {renderItem(<RiServiceFill />, service.name, `Label: ${service.label}`)}
      {renderItem(<BiCalendar />, getDate(dateTime), getDayName(dateTime))}
      {renderItem(
        <FiClock />,
        getTime(dateTime),
        `${service.duration} minutes`
      )}
      {provider && renderItem(<GrUserManager />, provider.name, provider.title)}
      {!service.onsiteService &&
        location &&
        renderItem(
          <GrLocation />,
          location.name,
          <LocationDisplay data={location} />
        )}
      {service.onsiteService &&
        onsiteService &&
        renderItem(
          <GrLocation />,
          "Service Location",
          <LocationDisplay data={onsiteService} />
        )}
      {customer && renderItem(<ImUser />, customer.displayName, "Customer")}
    </ul>
  );
  return <Loader />;
};

export default CreateBookingDetails;
