import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authURL, personalURL } from "../config/url";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";

const BusinessRouter: React.FC = () => {
  const { user, business } = useTypedSelector((state) => state.entities.auth);
  const { getPersonalProfile, getSelfBusiness } = useActions();

  useEffect(() => {
    if (user) {
      console.log("user", user);
      getPersonalProfile();
      getSelfBusiness();
    }
  }, [user]);

  if (!user) return <Navigate to={authURL.Login} />;
  else if (business) return <Outlet />;

  return <Navigate to={personalURL.PersonalHome} />;
};

export default BusinessRouter;
