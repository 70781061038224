import { Buffer } from "buffer";
import classNames from "classnames";
import styles from "./ModalUploadPicture.module.scss";
import "cropperjs/dist/cropper.css";
import { AppButton } from "../../form";

import { ModalBody, ModalBox, ModalFooter } from "../modal";
import ImageCropper from "./ImageCropper";
import useUploadPicture from "./useUploadPicture";
import { EBtnCategory } from "../../form/AppButton";
import { useActions } from "../../../hooks/useActions";
import { useState } from "react";

interface ModalUploadPictureProps {
  type: "big" | "medium" | "small";
  title: string;
  onClose(): void;
  onUploadPicture: (pic: string) => void;
}

const ModalUploadPicture: React.FC<ModalUploadPictureProps> = ({
  type,
  title,
  onClose,
  onUploadPicture,
}) => {
  const [submitError, setSubmitError] = useState("");
  const [cropper, setCropper] = useState<any>();
  const { image, error, handleUpload } = useUploadPicture(type);

  const handleSubmit = () => {
    if (typeof cropper !== "undefined") {
      // Upload Image
      const photo = cropper.getCroppedCanvas().toDataURL();
      // console.log(photo);
      const photoString = Buffer.from(photo.substring(photo.indexOf(",") + 1));
      // console.log("l-----", photoString.length);
      const photoSize = photoString.length / 1000000;
      if (photoSize > 2)
        setSubmitError("File size is too big. Please make the image smaller.");
      else onUploadPicture(photo);
    }
  };

  return (
    <ModalBox onClose={onClose} size="small">
      <ModalBody>
        <div className={styles["container"]}>
          <h4>{title}</h4>
          <div
            className={classNames(
              styles["upload-photo"]
              // loading && styles["disabled"]
            )}
          >
            Upload New Image
            <input
              onChange={handleUpload}
              type="file"
              name="file"
              accept=".gif,.jpg,.png"
              // disabled={loading}
            />
          </div>
          <div className="desc py-10">
            Max. file size: 1MB. <br />
            Accept jpg or png format. <br />
            {type === "big" ? (
              <div>Min. dimension 1200px x 400px</div>
            ) : type === "medium" ? (
              <div>Min. dimension 480px x 240px</div>
            ) : (
              <div>Min. dimension 400px x 400px</div>
            )}
          </div>
          {error && <div className="py-10 error-msg">{error}</div>}
          {submitError && <div className="py-10 error-msg">{submitError}</div>}
        </div>
        {image && (
          <>
            <div className="px-5 py-5">
              <ImageCropper
                type={type}
                data={image.toString()}
                setCropper={setCropper}
              />
            </div>
            <ModalFooter>
              <AppButton
                type="button"
                category={EBtnCategory.Primary}
                onClick={() => handleSubmit()}
              >
                Upload
              </AppButton>
            </ModalFooter>
          </>
        )}
      </ModalBody>
    </ModalBox>
  );
};

export default ModalUploadPicture;
