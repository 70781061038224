export enum idType {
  Customer = "CID-",
  Booking = "BID-",
}

const prefixId = (id: string, type: idType) => {
  return `${type}${id}`;
};

export default prefixId;
