import React from "react";
import classnames from "classnames";
import styles from "./NotificationItem.module.scss";
import { timeSince } from "../../../utils/datetime";
import { AvatarPic } from "../avatar";

interface INotificationItem {
  onClick(): void;
  opened?: boolean;
  image?: string;
  imageType: "personal" | "business";
  message?: JSX.Element;
  date: string;
}

const NotificationItem: React.FC<INotificationItem> = ({
  onClick,
  opened,
  image,
  imageType,
  message,
  date,
}) => {
  return (
    <div
      className={classnames(styles["notification"], opened && styles["read"])}
      role="button"
      onClick={() => onClick()}
    >
      <div className={styles["image"]}>
        <AvatarPic src={image} role={imageType} />
      </div>
      <div className={styles["text"]}>
        {message && message}
        <div className={classnames("desc", styles["date"])}>
          {timeSince(date)}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
