import styles from "./BusinessProfile.module.scss";
import Guideline from "../ui/Guideline";
import { Col, Row } from "../ui/Grid";
import LocationSelection from "./LocationSelection";
import BusinessContactForm from "./BusinessContactForm";

interface BusinessContactProps {
  isEdit?: boolean;
}

const BusinessContact: React.FC<BusinessContactProps> = ({ isEdit }) => {
  return (
    <Row>
      <Col width="60%">
        <div className={styles["wrapper"]}>
          {isEdit && <LocationSelection />}
          <BusinessContactForm />
        </div>
      </Col>
      <Col width="30%">
        <Guideline title="Business Contact">
          <p>Let your customer know where your location is.</p>
        </Guideline>
      </Col>
    </Row>
  );
};

export default BusinessContact;
