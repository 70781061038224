import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useToast from "../../components/ui/toasts/useToast";
import PersonalContact from "../../components/personal/PersonalContact";
import { personalProfileSubmenu } from "../../config/submenu";

const PersonalContactPage: React.FC = () => {
  const { getPersonalProfile, clearUserState } = useActions();
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.user
  );
  const { notify } = useToast();

  useEffect(() => {
    clearUserState();
    getPersonalProfile();
  }, []);

  useEffect(() => {
    if (success) {
      notify("success", "Your profile has been saved.");
      clearUserState();
    }
    if (error) {
      notify("error", "Failed to save your profile. Please try again.");
      clearUserState();
    }
  }, [success, error]);

  return (
    <PageWithAside
      role="personal"
      submenuName="Profile"
      submenuList={personalProfileSubmenu}
      noPadding
    >
      <div className="wrapper container">
        <Header
          pageTitle="Personal Contact"
          pageDesc="Your contact info will be use for verification only. AddPointment will never share
            or sell individual personal information or personal identifiable details."
          role="personal"
        />
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && <PersonalContact />}
      </div>
    </PageWithAside>
  );
};

export default PersonalContactPage;
