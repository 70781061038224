import { DropdownSelect } from "../ui/dropdown";
import useFilterBookings from "./useFilterBookings";

interface FilterBookingsProps {
  // selected?: string;
  label?: string;
  onClick: (id?: string, type?: string) => void;
}

const FilterBookings: React.FC<FilterBookingsProps> = ({
  onClick,
  label,
  // selected,
}) => {
  const { list, selected } = useFilterBookings(onClick, label);

  if (!list) return null;

  return (
    <DropdownSelect
      menuList={list}
      labelWidth={200}
      selected={selected ? selected : label ? label : "All"}
      fullWidth
      type="menu"
    />
  );
};

export default FilterBookings;
