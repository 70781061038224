import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import TableOnlineBooking from "./TableOnlineBooking";
import SendMail from "../../components/ui/SendMail";
import { useActions } from "../../hooks/useActions";
import useGetBookingType from "../../hooks/useGetBookingType";

const BusinessOnlineBooking: React.FC = () => {
  const { services, resources, providers } = useGetBookingType("none");

  const { updateAdminServiceOnlineBooking, updateAdminResourceOnlineBooking } =
    useActions();

  const handleToggle = (id: string, value: boolean, type: string) => {
    type === "services" && updateAdminServiceOnlineBooking(id, value);
    type === "resources" && updateAdminResourceOnlineBooking(id, value);
  };

  console.log("services", services);

  return (
    <Row>
      <Col width="60%">
        <Link className="btn btn-primary-outline mb-10" to="">
          <span className="mr-10">View Booking Page</span>
          <FaExternalLinkAlt />
        </Link>
        <div className="desc mb-30">
          Share this booking page with your customer
        </div>
        {services.length > 0 && (
          <>
            <h4 className="separator pb-5">Services</h4>
            <TableOnlineBooking
              data={services}
              onToggle={(id, val) => handleToggle(id, val, "services")}
            />
          </>
        )}
        {resources.length > 0 && (
          <>
            <h4 className="separator pb-5">Resources</h4>
            <TableOnlineBooking
              data={resources}
              onToggle={(id, val) => handleToggle(id, val, "resources")}
            />
          </>
        )}
        {providers.length > 0 && (
          <>
            <h4 className="separator pb-5">Providers</h4>
            <TableOnlineBooking
              data={providers}
              onToggle={(id, val) => handleToggle(id, val, "providers")}
            />
          </>
        )}
      </Col>
      <Col width="30%">
        <div className="mb-20">
          <Guideline title="Online Booking">
            <p>
              You can let your customer make advance booking through online. A
              reminder will auto send out. You will get customer confirmation 24
              hours before the meet up.
            </p>
          </Guideline>
        </div>
        <div>
          <SendMail
            title="Share Booking Link"
            desc="Share your advance booking business link to let customer make booking with you"
          />
        </div>
      </Col>
    </Row>
  );
};

export default BusinessOnlineBooking;
