import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProviderDataType } from "../../config/types/ProviderDataType";

interface ProviderState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; //get providers data
  error?: string;
  success?: string;
  lastFetch?: Date | number;
  data: IProviderDataType[];
  duplicateProvider?: IProviderDataType;
  photoUpload?: { loading?: boolean; success?: boolean; error?: string };
}

const initialState: ProviderState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  success: undefined,
  lastFetch: undefined,
  data: [],
  duplicateProvider: undefined,
};

const slice = createSlice({
  name: "providerAdmin",
  initialState,
  reducers: {
    providerClearState: (provider) => {
      provider.request = undefined;
      provider.loading = false;
      provider.error = undefined;
      provider.success = undefined;
      provider.duplicateProvider = undefined;
      provider.photoUpload = undefined;
    },
    providerRequest: (provider) => {
      provider.request = { loading: true };
    },
    providerReceived: (
      provider,
      action: PayloadAction<IProviderDataType[]>
    ) => {
      provider.data = action.payload;
      provider.request = undefined;
      provider.lastFetch = Date.now();
    },
    providerRequestFailed: (provider, action: PayloadAction<string>) => {
      provider.request = { error: action.payload };
    },
    providerRemoving: (provider, action: PayloadAction<string>) => {
      console.log("providerRemoving");
      provider.loading = true;
      provider.success = undefined;
      provider.error = undefined;
      provider.data = provider.data?.filter(
        (item) => item._id !== action.payload
      );
    },
    providerRemoved: (provider) => {
      provider.loading = false;
      provider.success = "Provider has been removed";
    },
    providerRemoveFailed: (
      provider,
      action: PayloadAction<{ error: string; actionData: IProviderDataType[] }>
    ) => {
      provider.data = action.payload.actionData;
      provider.loading = false;
      provider.error = action.payload.error;
    },
    providerLoading: (provider) => {
      provider.loading = true;
      provider.success = undefined;
      provider.error = undefined;
    },
    providerFailed: (provider, action: PayloadAction<string>) => {
      provider.loading = false;
      provider.error = action.payload;
    },
    providerDuplicated: (
      provider,
      action: PayloadAction<IProviderDataType>
    ) => {
      console.log("providerAdded", action.payload);
      provider.loading = false;
      const newProvider = action.payload;
      provider.data = [...provider.data, newProvider];
      provider.duplicateProvider = newProvider;
    },
    providerUpdated: (provider, action: PayloadAction<IProviderDataType>) => {
      console.log("providerUpdated", action.payload);
      provider.loading = false;
      const index = provider.data.findIndex(
        (item) => item._id === action.payload._id
      );
      provider.data[index] = action.payload;
      provider.success = "Provider has been updated";
    },
    providerAdded: (provider, action: PayloadAction<IProviderDataType>) => {
      console.log("providerAdded", action.payload);
      const newProvider = action.payload;
      provider.data = [...provider.data, newProvider];
      provider.success = "Provider has been added";
      provider.loading = false;
    },
    providerServiceRemoved: (
      provider,
      action: PayloadAction<IProviderDataType>
    ) => {
      console.log("providerServiceRemoved", action.payload);
      const index = provider.data.findIndex(
        (item) => item._id === action.payload._id
      );
      provider.data[index] = action.payload;
      provider.loading = false;
    },
    providerPhotoUploading: (provider) => {
      provider.photoUpload = { loading: true };
    },
    providerPhotoUploaded: (
      provider,
      action: PayloadAction<IProviderDataType>
    ) => {
      const index = provider.data.findIndex(
        (item) => item._id === action.payload._id
      );
      console.log("providerPhotoUploaded", action.payload);
      provider.data[index] = action.payload;
      provider.photoUpload = { success: true };
    },
    providerPhotoUploadFailed: (provider, action: PayloadAction<string>) => {
      provider.photoUpload = { error: action.payload };
    },
  },
});

export const {
  providerClearState,
  providerRequest,
  providerReceived,
  providerRequestFailed,
  providerRemoving,
  providerRemoved,
  providerRemoveFailed,
  providerLoading,
  providerFailed,
  providerDuplicated,
  providerUpdated,
  providerAdded,
  providerServiceRemoved,
  providerPhotoUploading,
  providerPhotoUploaded,
  providerPhotoUploadFailed,
} = slice.actions;

export default slice.reducer;
