import styles from "./LayoutFullHeight.module.scss";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FaChevronLeft } from "react-icons/fa";

const LayoutFooter: React.FC<{ backBtn?: boolean }> = ({
  children,
  backBtn,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles["footer"]}>
      <div className={styles["footer-action"]}>
        <div className="wrapper">
          {backBtn && (
            <div className="float-left">
              <button
                type="button"
                className={classNames(styles["btn-back"], "btn btn-link")}
                onClick={() => navigate(-1)}
              >
                <FaChevronLeft />
                Back
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;
