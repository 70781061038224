import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";
import { ModalBody, ModalBox, ModalFooter } from "../ui/modal";
import { AppButton, AppForm, AppInput } from "../form";
import { EBtnCategory } from "../form/AppButton";
import { useEffect, useState } from "react";
import { InitialValues, Validation } from "../../config/form/formDeleteAccount";
import { useTypedSelector } from "../../hooks/useTypedSelector";

export type BookmarkType = "friend" | "bookmark";

interface DeleteAccountDialogProps {
  setVisible(arg: boolean): void;
}

const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({
  setVisible,
}) => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const { deleteAccount, clearAuthState } = useActions();
  const { loading, successed, error } = useTypedSelector(
    (state) => state.entities.auth
  );
  useEffect(() => {
    if (error) {
      setErrorMsg(error);
      clearAuthState();
    }
  }, [error]);

  return (
    <ModalBox onClose={() => setVisible(false)} size="small">
      <ModalBody>
        <div className="py-25 px-20">
          <h3>Are you sure you want to delete your account?</h3>
          <p>To delete your account, please enter your account password</p>
          {errorMsg && <p className="error-msg">{errorMsg}</p>}
          <AppForm
            initialValues={InitialValues}
            validationSchema={Validation}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              deleteAccount(values);
            }}
          >
            <AppInput placeholder="Password" name="password" type="password" />
            <div className="mt-20">
              <AppButton
                category={EBtnCategory.Primary}
                type="submit"
                disabled={loading}
              >
                Delete
              </AppButton>
              <button
                onClick={() => setVisible(false)}
                className="btn btn-primary-outline ml-10"
              >
                Cancel
              </button>
            </div>
          </AppForm>
        </div>
      </ModalBody>
    </ModalBox>
  );
};

export default DeleteAccountDialog;
