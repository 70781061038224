import * as reducers from "../reducer/registerReducer";
import { apiCallBegan, IapiCallBegan } from "../middleware/api";
import { IRegisterInputType } from "../../config/form/formRegister";
// import { RegisterPhoneType } from "../../config/form/formRegisterPhone";
import { IRegisterEmailInputType } from "../../config/form/formRegisterEmail";
import { IRegisterProfileInputType } from "../../config/form/formRegisterProfile";
import { IRegisterPinInputType } from "../../config/form/formRegisterPin";
import { ETokenType } from "../../config/types/TokenType";
import config from "../../config/global";

interface DispatchType {
  type: string;
  payload?: IapiCallBegan | string | number;
}

const url = "/register";

export const clearRegisterState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.registerClearState.type });
  };

export const setRegisterStep =
  (step: number) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.registerStepUpdate.type, payload: step });
  };

export const register =
  (data: IRegisterInputType) => (dispatch: (arg: DispatchType) => void) => {
    const userData = {
      ...data,
      registerStep: 1,
      media: config.media,
    };
    console.log(userData);
    return dispatch(
      apiCallBegan({
        url,
        data: userData,
        method: "post",
        onStart: reducers.registerSubmit.type,
        // onSuccess: reducers.registerSuccessed.type,
        onSuccessDispatch: {
          type: reducers.registerSuccessed.type,
          payload: { email: data.email, country: data.country },
        },
        onError: reducers.registerFailed.type,
      })
    );
  };

export const registerEmail =
  (data: IRegisterEmailInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("registerEmail", data);
    const userData = {
      code: data.code,
      tokenId: data.tokenId,
      registerStep: 2,
      tokenType: ETokenType.registerEmail,
    };
    return dispatch(
      apiCallBegan({
        url: url + "/email",
        data: userData,
        method: "post",
        onStart: reducers.registerSubmit.type,
        onSuccess: reducers.registerProceed.type,
        onError: reducers.registerFailed.type,
      })
    );
  };

export const registerPin =
  (data: IRegisterPinInputType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("registerPin", data);
    // const userData = { ...data, registerStep: 5 };
    // return dispatch(
    //   apiCallBegan({
    //     url,
    //     data: userData,
    //     method: "post",
    //     onStart: reducers.registerSubmit.type,
    //     onSuccess: reducers.registerSuccessed.type,
    //     onError: reducers.registerFailed.type,
    //   })
    // );
  };

export const registerProfile =
  (data: IRegisterProfileInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - parseInt(data.age);

    const userData = {
      ...data,
      birthYear,
      registerStep: 3,
    };
    console.log("userData", userData);
    return dispatch(
      apiCallBegan({
        url: url + "/profile",
        data: userData,
        method: "post",
        onStart: reducers.registerSubmit.type,
        onSuccess: reducers.registerProceed.type,
        onError: reducers.registerFailed.type,
      })
    );
  };
