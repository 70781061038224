import { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { BsPlayCircle, BsChatDots } from "react-icons/bs";
import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { HiOutlineSearch } from "react-icons/hi";

import { DropdownAction } from "../ui/dropdown";

import BookingCancelDialog from "./BookingCancelDialog";

import ModalAdminCreateBooking from "../createBooking/ModalAdminCreateBooking";
import ModalReschedule from "./ModalReschedule";
import ModalBookingMeetingPoint from "./ModalBookingMeetingPoint";
import ModalTransfer from "./ModalTransfer";
import { IBookingDataType } from "../../config/types/BookingDataType";
import MoreButton from "../ui/MoreButton";
// import useBookingActionMenu from "./useBookingActionMenu";
// import { AppButton } from "../form";
import { useActions } from "../../hooks/useActions";
import { isToday } from "../../utils/datetime";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { DropdownItemListType } from "../ui/dropdown/DropdownItemList";
import { EBtnCategory } from "../form/AppButton";
import BookingContactDialog from "./DialogMessageCustomer";

export enum EBookingActionMenuType {
	Table = "table",
	Modal = "modal",
	TableAction = "table-action",
}

interface BookingActionMenuProps {
	type: EBookingActionMenuType;
	data: IBookingDataType;
	onClose?: () => void;
	onView?: (id: string) => void;
}

const BookingActionMenu: React.FC<BookingActionMenuProps> = ({
	type,
	data,
	onClose,
	onView,
}) => {
	const [cancelDialog, setCancelDialog] = useState(false);
	const [contactDialog, setContactDialog] = useState(false);

	const [viewMeetingPoint, setViewMeetingPoint] = useState(false);
	// const { isToday, isPast, isOver } = useBookingActionMenu(data);
	const [viewCreateNewBooking, setViewCreateNewBooking] = useState(false);
	const [viewReschedule, setViewReschedule] = useState(false);
	const [viewTransfer, setViewTransfer] = useState(false);
	const {
		completeBusinessBooking,
		startBusinessBooking,
		skipBusinessBooking,
		cancelBusinessBooking,
	} = useActions();
	const ISTODAY = isToday(data.meetDateTime);

	const itemComplete = {
		name: "Complete",
		desc: "End the service for this booking",
		icon: <RiCheckboxCircleLine />,
		onClick: () =>
			completeBusinessBooking(data._id, EBookingStatusValue.complete),
	};

	// const itemStartWithMeetingPoint = {
	//   name: "Start with Meeting Point",
	//   desc: "Enter the Meeting Point to start",
	//   icon: <FaStreetView />,
	//   onClick: () => setViewMeetingPoint(true),
	// }

	const itemStart = {
		name: "Start",
		desc: "Start serving the customer",
		icon: <BsPlayCircle />,
		onClick: () => startBusinessBooking(data._id, EBookingStatusValue.serving),
	};

	// const itemExtend = {
	//     name: "Extend",
	//     icon: <BsArrowsAngleExpand />,
	//   }

	const itemSkip = {
		name: "Skip",
		desc: "Move to the next customer",
		icon: <FaAngleDoubleRight />,
		onClick: () => skipBusinessBooking(data._id, EBookingStatusValue.skip),
	};

	// const itemTransfer = {
	//   name: "Transfer",
	//   desc: "Assign the customer to other services",
	//   icon: <BiShuffle />,
	//   onClick: () => setViewTransfer(true),
	// }

	const itemCancel = {
		name: "Cancel",
		desc: "Cancel this booking and notify the customer",
		icon: <RiCloseCircleLine />,
		onClick: () => setCancelDialog(true),
	};

	// const itemReschedule = {
	//   name: "Reschedule",
	//   icon: <BsArrowRepeat />,
	//   onClick: () => setViewReschedule(true),
	// }

	// const itemMessageCustomer = {
	// 	name: "Contact Customer",
	// 	desc: `Send a message to`,
	// 	icon: <BsChatDots />,
	// };

	// const itemBlockCustomer = {
	//   name: "Block Customer",
	//   desc: "Stop receiving booking from this customer",
	//   icon: <MdNotInterested />,
	// },

	// const itemReportCustomer = {
	//   name: "Report Customer",
	//   desc: "Tell us about a problem with this customer",
	//   icon: <MdReportProblem />,
	// },

	const menu: DropdownItemListType[] = [
		// {
		//   name: "Create New",
		//   desc: "Create a new booking for another day",
		//   icon: <BiCalendarPlus />,
		//   onClick: () => setViewCreateNewBooking(true),
		// },
	];

	if (data.bookingStatus < EBookingStatusValue.complete) {
		menu.unshift(itemCancel);
		if (ISTODAY) {
			menu.unshift(itemComplete);
		}
		if (data.bookingStatus === EBookingStatusValue.serving) {
			menu.unshift(itemSkip);
		}
		if (ISTODAY && data.bookingStatus < EBookingStatusValue.serving) {
			menu.unshift(itemStart);
		}
	}

	const tableDropdownMenu = [
		...menu,
		{
			name: "View",
			desc: "View booking details",
			icon: <HiOutlineSearch />,
			onClick: () => onView && onView(data._id),
		},
		{
			name: "Contact Customer",
			desc: `Send a message to the ${data.customerData.displayName}`,
			icon: <BsChatDots />,
			onClick: () => setContactDialog(!contactDialog),
		},
	];

	const tableActions = () => {
		if (ISTODAY && data.bookingStatus < EBookingStatusValue.serving)
			return (
				<button
					className='btn btn-link'
					onClick={(e) => {
						e.stopPropagation();
						startBusinessBooking(data._id, EBookingStatusValue.serving);
					}}>
					Start
				</button>
			);
		else if (data.bookingStatus === EBookingStatusValue.serving)
			return (
				<button
					className='btn btn-link'
					onClick={(e) => {
						e.stopPropagation();
						completeBusinessBooking(data._id, EBookingStatusValue.complete);
					}}>
					Complete
				</button>
			);
		return (
			<button
				className='btn btn-link'
				onClick={(e) => {
					e.stopPropagation();
					onView && onView(data._id);
				}}>
				View
			</button>
		);
	};
	return (
		<>
			{cancelDialog && (
				<BookingCancelDialog
					setVisible={(arg) => setCancelDialog(arg)}
					id={data._id}
					status={EBookingStatusValue.cancel}
				/>
			)}

			{contactDialog && (
				<BookingContactDialog
					name={data.customerData.displayName}
					customerId={data.customer}
					bookingId={data._id}
					status={EBookingStatusValue.cancel}
					setVisible={(arg) => setContactDialog(arg)}
				/>
			)}

			{type === EBookingActionMenuType.Table && (
				<DropdownAction menu={tableDropdownMenu} />
			)}
			{type === EBookingActionMenuType.Modal ? (
				ISTODAY && data.bookingStatus < EBookingStatusValue.serving ? (
					<MoreButton
						menuList={menu}
						label='Start'
						category={EBtnCategory.Primary}
						minWidth={300}
						onClick={() =>
							startBusinessBooking(data._id, EBookingStatusValue.serving)
						}
					/>
				) : (
					<MoreButton
						menuList={menu}
						label='Close'
						category={EBtnCategory.PrimaryOutline}
						onClick={() => onClose && onClose()}
						minWidth={300}
					/>
				)
			) : null}

			{type === EBookingActionMenuType.TableAction && tableActions()}
			{viewCreateNewBooking && (
				<ModalAdminCreateBooking
					// data={data}
					onClose={() => setViewCreateNewBooking(false)}
				/>
			)}
			{viewReschedule && (
				<ModalReschedule data={data} onClose={() => setViewReschedule(false)} />
			)}
			{viewMeetingPoint && (
				<ModalBookingMeetingPoint
					data={data}
					onClose={() => setViewMeetingPoint(false)}
				/>
			)}
			{viewTransfer && (
				<ModalTransfer data={data} onClose={() => setViewTransfer(false)} />
			)}
		</>
	);
};

export default BookingActionMenu;
