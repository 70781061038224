import { useEffect } from "react";
import styles from "./BusinessAddStaff.module.scss";
import { AppButton, AppForm, AppInput } from "../form";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessAddStaff";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";
import { EBtnCategory } from "../form/AppButton";

const BusinessAddStaff: React.FC = () => {
  const { addStaff, clearStaffState } = useActions();
  const { add } = useTypedSelector((state) => state.entities.staff);

  const { notify } = useToast();

  useEffect(() => {
    if (add) {
      if (add.success) {
        notify("success", "An invitation has been sent to the user");
        clearStaffState();
      } else if (add.error) {
        notify("error", add.error);
        clearStaffState();
      }
    }
  }, [add]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h4 className="mt-20 mb-5">Add new staff</h4>
      <AppForm
        className={styles["form-add-user"]}
        initialValues={InitialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("values", values);
          addStaff(values);
        }}
        validationSchema={Validation}
      >
        <AppInput name="email" placeholder="Email address" />
        <AppButton
          type="submit"
          category={EBtnCategory.Primary}
          loading={add?.loading}
        >
          Submit
        </AppButton>
      </AppForm>
      <p className="desc">A confirmation email will be sent to the user</p>
    </>
  );
};

export default BusinessAddStaff;
