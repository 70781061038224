import * as yup from "yup";
import { IUserProfileType } from "../types/UserDataType";
import { nameField } from "./validationFields";

export const BaseInitialValues = {
  profilePic: "",
  displayName: "",
  gender: "",
};

export const InitialValues = {
  ...BaseInitialValues,
  birthYear: 0,
  email: "",
};

export const BaseValidation = {
  displayName: nameField.required("Please enter your name"),
  // birthdate: birthdateField.required("Please enter your birthdate"),
  // gender: genderField.required("Please select your gender"),
};
export const Validation = yup.object().shape({
  ...BaseValidation,
});

export interface IPersonalProfileInputType extends IUserProfileType {}
