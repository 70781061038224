import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";

interface CustomerRemoveDialogProps {
  id: string;
  setVisible(arg: boolean): void;
  onClose?(): void;
}

const CustomerRemoveDialog: React.FC<CustomerRemoveDialogProps> = ({
  setVisible,
  onClose,
  id,
}) => {
  const { removeCustomer } = useActions();

  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      removeCustomer(id);
      setVisible(false);
      onClose && onClose();
    },
  };

  return (
    <DialogBox
      title="Are you sure you want to remove this customer?"
      // message="This customer can still make booking on your booking page."
      confirm={handleConfirm}
      cancel="Cancel"
      onClose={() => setVisible(false)}
    />
  );
};

export default CustomerRemoveDialog;
