import Calendar from "react-calendar";
import "./CalendarMini.scss";

interface CalendarMiniProps {
  disableYearNavigation?: boolean;
  showNeighboringMonth?: boolean;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  initialValue?: Date;
  onChange: (val: Date | Date[]) => void;
}

const CalendarMini: React.FC<CalendarMiniProps> = ({
  onChange,
  value,
  minDate,
  maxDate,
  disableYearNavigation = false,
  showNeighboringMonth = true,
  initialValue,
  ...otherProps
}) => {
  return (
    <Calendar
      {...otherProps}
      onChange={onChange}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      // defaultValue={initialValue}
      defaultActiveStartDate={initialValue}
      next2Label={disableYearNavigation ? null : "»"}
      prev2Label={disableYearNavigation ? null : "«"}
      minDetail={disableYearNavigation ? "month" : "century"}
      showNeighboringMonth={showNeighboringMonth}
    />
  );
};
export default CalendarMini;
