import DialogBox from "../ui/DialogBox";
import { useActions } from "../../hooks/useActions";

interface StaffRemoveDialogProps {
  id: string;
  setVisible(arg: null): void;
}

const StaffRemoveDialog: React.FC<StaffRemoveDialogProps> = ({
  setVisible,
  id,
}) => {
  const { removeStaff } = useActions();

  const handleConfirm = {
    label: "Remove",
    onClick: () => {
      removeStaff(id);
      setVisible(null);
    },
  };

  return (
    <DialogBox
      title="Are you sure you want to remove this user?"
      message="All booking manage by this user will be transferred to business owner."
      confirm={handleConfirm}
      cancel="Cancel"
      onClose={() => setVisible(null)}
    />
  );
};

export default StaffRemoveDialog;
