import PageWithAside from "../../components/layout/PageWithAside";
import BusinessWaitingList from "../../components/businessBooking/BusinessWaitingList";
import Header from "../../components/layout/Header";

const BusinessWaitingListPage: React.FC = () => {
  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Waiting List"
          pageDesc="List of customer who has been registered for service"
          role="business"
        />
        <BusinessWaitingList />
      </div>
    </PageWithAside>
  );
};

export default BusinessWaitingListPage;
