import * as yup from "yup";
import { phoneNumber } from "./patterns";
import { phoneField } from "./validationFields";

export const InitialValues = {
  phone: "",
};
export const Validation = yup.object().shape({
  phone: phoneField
    .matches(phoneNumber, "Please enter a valid number")
    .required("Please enter your phone number"),
});
export const InitialCode = {
  inputCode: "",
};
export const ValidateCode = yup.object().shape({
  inputCode: yup
    .string()
    .min(6, "Invalid code")
    .max(6, "Invalid code")
    .required("Please enter the verification code"),
});
