// Hours > TimeSlot > Slot > TimeOptions
// Handle JSON parse and JSON stringify
import React, { useState, useEffect } from "react";
import Timeslot from "./Timeslot";
import { defaultHours } from "../../../config/option/optionBusiness";
import { convertToTimestamp } from "../../../utils/datetime";
import styles from "./Timeslot.module.scss";
import useBusiness from "../../../hooks/useBusiness";

const days = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

interface CurrentTargetType {
	name: string;
	value: string[];
}
interface HoursProps {
	data: string;
	onChange(hours: string, hoursSum: string): void;
}

const Hours: React.FC<HoursProps> = ({ data, onChange }) => {
	const [hours, setHours] = useState(JSON.parse(defaultHours));
	const { calculateHoursSum } = useBusiness();

	useEffect(() => {
		if (data) setHours(JSON.parse(data));
	}, [data]);

	const handleChange = ({
		currentTarget,
	}: {
		currentTarget: CurrentTargetType;
	}) => {
		let hoursArray: { [key: string]: string[] } = hours;
		hoursArray[currentTarget.name] = currentTarget.value;

		let hoursSumArray: number[] = [];
		Object.entries(hoursArray).forEach(([key, value]) => {
			hoursSumArray.push(calculateHoursSum(value));
		});
		// console.log("hoursSumArray", hoursSumArray);

		onChange(JSON.stringify(hoursArray), JSON.stringify(hoursSumArray));
	};
	// console.log("data", data);
	return (
		<>
			{days.map((day, index) => {
				return (
					<div key={index} className='mb-20'>
						<Timeslot
							name={index.toString()}
							value={hours[index]}
							onChange={handleChange}>
							<div className={styles["label"]}>{day}</div>
						</Timeslot>
					</div>
				);
			})}
			{/* <Timeslot name="1" value={hours[1]} onChange={handleChange}>
        Monday
      </Timeslot>
      <Timeslot name="2" value={hours[2]} onChange={handleChange}>
        Tuesday
      </Timeslot>
      <Timeslot name="3" value={hours[3]} onChange={handleChange}>
        Wednesday
      </Timeslot>
      <Timeslot name="4" value={hours[4]} onChange={handleChange}>
        Thursday
      </Timeslot>
      <Timeslot name="5" value={hours[5]} onChange={handleChange}>
        Friday
      </Timeslot>
      <Timeslot name="6" value={hours[6]} onChange={handleChange}>
        Saturday
      </Timeslot>
      <Timeslot name="0" value={hours[0]} onChange={handleChange}>
        Sunday
      </Timeslot> */}
		</>
	);
};

export default Hours;
