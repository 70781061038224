import React, { useRef, useState } from "react";
import styles from "./DropdownPanel.module.scss";
import { BsSearch } from "react-icons/bs";
import useDropdown from "./useDropdown";
import DropdownWrapper from "./DropdownWrapper";
import classNames from "classnames";

interface DropdownPanelProps {
  menuList: DropdownPanelMenuType[];
  menuEmptyMsg?: string;
  filter?: string;
  cta?: React.ButtonHTMLAttributes<HTMLButtonElement> | null;
  className?: string;
  noPadding?: boolean; // Padding around the dropdown.
  fixWidth?: boolean;
}

export type DropdownPanelMenuType = {
  id: string | number;
  item: string | JSX.Element;
  name: string;
  desc?: string;
  onClick(): void;
};

const DropdownPanel: React.FC<DropdownPanelProps> = ({
  menuList,
  menuEmptyMsg,
  filter,
  cta,
  children,
  className,
  noPadding,
  fixWidth,
}) => {
  const [searchText, setSearchText] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { position, toggleDropdown, display, containerWidth } = useDropdown(
    wrapperRef,
    dropdownRef,
    listRef
  );

  const handleOnFilter = () => {
    let filteredList = menuList;
    if (searchText.length > 0) {
      filteredList = menuList.filter((f) =>
        f.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return filteredList;
  };
  const filteredList = handleOnFilter();
  return (
    <>
      <div ref={dropdownRef} className={styles["container"]}>
        <div
          ref={wrapperRef}
          role="button"
          className={classNames(styles["button"], className && className)}
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown("left", true, noPadding);
          }}
        >
          {children}
        </div>
        <DropdownWrapper
          position={position}
          display={display}
          minWidth={containerWidth}
          maxWidth={fixWidth ? containerWidth : undefined}
          ref={listRef}
        >
          <div className={styles["btn-wrapper"]}>
            <div
              className={classNames(styles["button"], className && className)}
              style={{ minWidth: containerWidth }}
            >
              {children}
            </div>
          </div>
          {filter && (
            <div ref={dropdownRef} className={styles["search"]}>
              <span className={styles["search-icon"]}>
                <BsSearch />
              </span>
              <input
                placeholder={filter}
                onChange={(e) => setSearchText(e.target.value)}
                className="input-control"
              />
            </div>
          )}
          {filteredList.length > 0 ? (
            <ul className={styles["list"]}>
              {filteredList.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      item.onClick();
                      toggleDropdown();
                    }}
                  >
                    <div className="h5">{item.item}</div>
                    {item.desc && <div className="desc">{item.desc}</div>}
                  </li>
                );
              })}
            </ul>
          ) : (
            menuEmptyMsg && (
              <div className={styles["msg-empty"]}>{menuEmptyMsg}</div>
            )
          )}
          {cta && (
            <div className={styles["cta"]} onClick={() => toggleDropdown()}>
              {cta}
            </div>
          )}
        </DropdownWrapper>
      </div>
    </>
  );
};

export default DropdownPanel;
