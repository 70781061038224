import React, { useContext } from "react";
import styles from "./MainMenu.module.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoBriefcaseOutline } from "react-icons/io5";
import { TiArrowForwardOutline, TiArrowBackOutline } from "react-icons/ti";
import { personalMenu, businessMenu } from "../../config/menu";
import classNames from "classnames";
import MainMenuItem from "./MainMenuItem";
import { AppContext, ContextType } from "../../context/appContext";
import config from "../../config/global";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Link } from "react-router-dom";
import { businessSetupURL, businessURL } from "../../config/url";

interface MainMenuProps {
  role: "business" | "personal";
}
const MainMenu: React.FC<MainMenuProps> = ({ role }) => {
  const { appState } = useContext(AppContext) as ContextType;
  const { data: businessData } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  return (
    <nav
      className={classNames(
        appState.collapsed && styles["collapsed"],
        styles["menu-container"]
      )}
    >
      <ul>
        {role === "business"
          ? businessMenu.map((data, index) => {
              return (
                <li key={index}>
                  <MainMenuItem data={data} />
                </li>
              );
            })
          : personalMenu.map((data, index) => {
              return (
                <li key={index}>
                  <MainMenuItem data={data} />
                </li>
              );
            })}
        {role === "business" && businessData ? (
          <>
            <li
              className={classNames(
                styles["separate-link"],
                styles["sub-links"]
              )}
            >
              <a
                href={`${config.bookingUrl}${businessData.businessUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                Booking Page <FaExternalLinkAlt />
              </a>
            </li>
            <li className={styles["sub-links"]}>
              <Link to={businessSetupURL.Info}>
                <span className={styles["name"]}>Back to personal</span>
                <span className={styles["icon"]}>
                  <TiArrowBackOutline />
                </span>
              </Link>
            </li>
          </>
        ) : (
          <li
            className={classNames(styles["separate-link"], styles["sub-links"])}
          >
            {businessData ? (
              <Link to={businessURL.BusinessDashboard}>
                <span className={styles["name"]}>Go to your business</span>
                <span className={styles["icon"]}>
                  <TiArrowForwardOutline />
                </span>
              </Link>
            ) : (
              <Link to={businessSetupURL.Info}>
                <span className={styles["name"]}>Start a new business</span>
                <span className={styles["icon"]}>
                  <IoBriefcaseOutline />
                </span>
              </Link>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
};
export default MainMenu;
