import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import styles from "./BusinessBookingForm.module.scss";
import AppTimeslotPicker from "../form/AppTimeslotPicker";
import { Col, Row } from "../ui/Grid";
import AppCalendar from "../form/AppCalendar";
import FilterBookings from "./FilterBookings";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { ResourceType } from "../../config/types/ResourceType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useGetBookingType from "../../hooks/useGetBookingType";
import Loader from "../ui/Loader";

const timeRage = '["08:00", "17:00"]'; // TODO: demo

interface BusinessBookingFormProps {
  type: "reschedule" | "create" | "transfer";
  // entity?: IServiceDataType | ProviderType | ResourceType;
  entity?: IServiceDataType;
}

interface FormikProps {
  [name: string]: Date & string;
}

const BusinessBookingForm: React.FC<BusinessBookingFormProps> = ({
  type,
  entity,
}) => {
  const { services, resources, providers } = useGetBookingType();
  const { loading, data: dataCapacity } = useTypedSelector(
    (state) => state.entities.capacity
  );
  const { data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const [bookingType, setBookingType] = useState(entity);
  const { getCapacity } = useActions();
  const { errors, setFieldValue } = useFormikContext<FormikProps>();

  useEffect(() => {
    business && bookingType && getCapacity(business._id, bookingType._id);
  }, [bookingType]);

  const handleBookingType = (id?: string, type?: string) => {
    if (id && type) {
      console.log(id, type);
      if (type === "service") {
        const result = services.find((item) => item._id === id);
        setBookingType(result);
        // } else if (type === "resource") {
        //   const result = resources.find((item) => item._id === id);
        //   setBookingType(result);
        // } else if (type === "provider") {
        //   const result = providers.find((item) => item._id === id);
        //   setBookingType(result);
      }
      setFieldValue(type, id);
    }
  };

  console.log("bookingType", bookingType);

  return (
    <>
      {type !== "reschedule" && (
        <div className={styles["form"]}>
          <div className={styles["wrapper"]}>
            <div className={styles["label"]}>
              {type === "transfer" && "Transfer to: "}
              {type === "create" && "New booking: "}
            </div>
            <div className={styles["dropdown"]}>
              <FilterBookings
                onClick={(id, type) => handleBookingType(id, type)}
                label=" "
              />
            </div>
          </div>
        </div>
      )}
      {loading && <Loader />}
      {bookingType && !loading && (
        <div className={styles["date-time"]}>
          {errors && (
            <div className="desc error-msg mb-10 text-center">
              {errors[Object.keys(errors)[0]]}
            </div>
          )}
          <Row>
            <Col width="50%">
              <AppCalendar name="apptDate" />
            </Col>
            <Col width="40%">
              <AppTimeslotPicker
                name="meetDateTime"
                timeRange={timeRage}
                interval={bookingType.slotInterval}
                dateName="apptDate"
                capacity={bookingType.bookingCapacity}
                dataCapacity={dataCapacity}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default BusinessBookingForm;
