import { useEffect, useState } from "react";
import styles from "./ProfilePic.module.scss";
import ModalUploadPicture from "./ModalUploadPicture";
import AvatarPic from "./AvatarPic";

interface ProfilePicProps {
  role: "personal" | "business";
  name: string;
  desc?: string;
  imageSrc?: string;
  uploadLabel?: string;
  uploaded?: boolean;
  onUploadPicture?: (pic: string) => void;
}

const ProfilePic: React.FC<ProfilePicProps> = ({
  role,
  name,
  desc,
  imageSrc,
  uploadLabel,
  uploaded,
  onUploadPicture,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    uploaded && setModalVisible(false);
  }, [uploaded]);
  return (
    <div className={styles["container"]}>
      <AvatarPic role={role} size="big" src={imageSrc} />
      <div className={styles["meta"]}>
        <div className="h4 mb-2 text-wrap">{name}</div>
        {uploadLabel && (
          <button
            type="button"
            className="btn-empty btn-link"
            onClick={() => setModalVisible(true)}
          >
            {uploadLabel}
          </button>
        )}
        {desc && <div className="desc">{desc}</div>}
      </div>
      {modalVisible && uploadLabel && onUploadPicture && (
        <ModalUploadPicture
          type="small"
          title={uploadLabel}
          onClose={() => setModalVisible(false)}
          onUploadPicture={onUploadPicture}
        />
      )}
    </div>
  );
};

export default ProfilePic;
