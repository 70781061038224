import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ModalBody, ModalBox, ModalFooter, ModalHeader } from "../ui/modal";
import { ProfilePic } from "../ui/avatar";
import MoreButton from "../ui/MoreButton";
import { IFriendDataType } from "../../config/types/FriendDataType";
import { personalURL } from "../../config/url";
import BookmarkRemoveDialog from "./BookmarkRemoveDialog";
import FriendRecord from "./FriendRecord";
import { EBtnCategory } from "../form/AppButton";

interface ModalFriendProps {
  onClose(): void;
  data: IFriendDataType;
}

const ModalFriend: React.FC<ModalFriendProps> = ({ onClose, data }) => {
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();

  const menuList = [
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      desc: "Remove from your friend list",
      onClick: () => setDialog(true),
    },
  ];
  return (
    <>
      <ModalBox onClose={onClose} type="personal" overlayClose={true}>
        <ModalHeader title={data.displayName} />
        <ModalBody>
          <div className="px-30 py-20">
            <div className="mb-20">
              <ProfilePic
                role="personal"
                imageSrc={data.profilePic}
                name={data.displayName}
                desc={data.email}
              />
            </div>
            <FriendRecord id={data.friend} />
          </div>
        </ModalBody>
        <ModalFooter>
          <MoreButton
            menuList={menuList}
            label="Create meetup"
            category={EBtnCategory.PrimaryOutline}
            minWidth={250}
            onClick={() =>
              navigate(personalURL.CreateMeetup, { state: [data.friend] })
            }
          />
        </ModalFooter>
      </ModalBox>
      {dialog && (
        <BookmarkRemoveDialog
          setVisible={(arg) => setDialog(arg)}
          onRemove={onClose}
          id={data._id}
          type="friend"
        />
      )}
    </>
  );
};

export default ModalFriend;
