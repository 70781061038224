import PersonalMeetupDetails from "./PersonalMeetupDetails";
import PersonalMeetupParticipants from "./PersonalMeetupParticipants";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { ApptStatus } from "../appointment/ApptStatus";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";

const usePersonalMeetup = (data: IMeetupDataType) => {
  const label = (
    <ApptStatus
      statusNo={
        data.bookingStatus < EBookingStatusValue.serving
          ? data.userStatus
          : data.bookingStatus
      }
      dateTime={data.meetDate}
      timestamp={data.meetDateTimestamp}
    />
  );

  const title = data.subject;
  const items = [
    {
      tabID: 0,
      name: "Meet Up",
      content: <PersonalMeetupDetails data={data} />,
    },
    {
      tabID: 1,
      name: "Participant",
      content: <PersonalMeetupParticipants data={data} />,
    },
  ];
  return { title, items, label };
};

export default usePersonalMeetup;
