import { useEffect } from "react";
import { useFormikContext } from "formik";
import BusinessHourDisplay from "../business/BusinessHourDisplay";
import { AppHours } from "../form";
import { AppSelectMenu } from "../form";
import { providerAvailablePeriodOption } from "../../config/option/optionProviders";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import Loader from "../ui/Loader";
import { IProviderDataType } from "../../config/types/ProviderDataType";
// import { defaultHours } from "../../config/option/optionBusiness";

interface FormikContextProps {
  [name: string]: boolean & string;
}

interface ProviderHourProps {
  optionName: string;
  hoursName: string;
}

const ProviderHour: React.FC<ProviderHourProps> = ({
  optionName,
  hoursName,
}) => {
  const { getLocation } = useActions();
  const { data: locationData, request } = useTypedSelector(
    (state) => state.entities.location
  );
  const { setFieldValue, values } = useFormikContext<FormikContextProps>();

  useEffect(() => {
    if (values[optionName] === 1 && locationData.length > 0) {
      console.log("same business hour");
      setFieldValue(hoursName, locationData[0].hours);
    }
    // else {
    // setFieldValue(name, defaultHours);
    // }
  }, [values, locationData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (request || locationData.length === 0) return <Loader />;

  // if (values.servicePeriodOption === 2) {
  //   return (
  //     <div className={styles["wrapper"]}>
  //       <h5 className="mb-10">Custom Service Hours</h5>
  //       <AppHours name="servicePeriod" />
  //     </div>
  //   );
  // }
  // return (
  //   <div className={styles["wrapper"]}>
  //     <h5 className="mb-10">Business Hours</h5>
  //     <BusinessHourDisplay data={businessHours} />
  //   </div>
  // );
  return (
    <>
      <AppSelectMenu
        name={optionName}
        inputWidth="300px"
        options={providerAvailablePeriodOption}
      />
      {values[optionName] === 1 && (
        <BusinessHourDisplay data={locationData[0].hours} />
      )}
      {values[optionName] === 2 && <AppHours name={hoursName} />}
    </>
  );
};

export default ProviderHour;
