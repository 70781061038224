import React, { useState, useEffect } from "react";
import { AppForm, AppInput } from "../form";
import { authURL } from "../../config/url";
import styles from "./BusinessRegister.module.scss";
import Guideline from "../ui/Guideline";
import { Col, Row } from "../ui/Grid";
import { useActions } from "../../hooks/useActions";
import { InitialValues, Validation } from "../../config/form/formRegister";
import AppCountryRegion from "../form/AppCountryRegion";
import {
  getRecaptchaToken,
  loadRecaptcha,
  removeRecaptcha,
} from "../../utils/googleRecaptcha";
import { EReCaptchaType } from "../../config/types/ReCaptchaType";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import BusinessRegisterFooter from "./BusinessRegisterFooter";
import { LayoutBody } from "../layout/LayoutFullHeight";
import { Link } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const BusinessRegisterUser: React.FC = () => {
  const [error, setError] = useState("");
  useDocumentTitle("Register a Business - User Account");
  const { register } = useActions();
  const { error: responseError } = useTypedSelector(
    (state) => state.entities.register
  );

  useEffect(() => {
    responseError && setError(responseError);
  }, [responseError]);

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <AppForm
      className="flex-layout"
      initialValues={InitialValues}
      validationSchema={Validation}
      onSubmit={async (values, actions) => {
        const recaptchaCode = await getRecaptchaToken(EReCaptchaType.Register);
        if (!recaptchaCode)
          setError("Invalid recaptcha. Please refresh the page and try again");
        else {
          register({ ...values, recaptchaCode });
          actions.setSubmitting(false);
        }
      }}
    >
      <LayoutBody>
        <Row>
          <Col width="60%">
            <h3 className="mb-3">User Account</h3>
            <div className="mb-20 desc">
              Already have an account?{" "}
              <Link to={authURL.Login} className="link">
                Login
              </Link>
            </div>
            {error && <p className="error-msg mb-20">{error}</p>}
            <div className={styles["wrapper"]}>
              <AppCountryRegion
                name="country"
                label="Country"
                helperMsg="Select your current country"
              />
              <AppInput
                name="email"
                type="email"
                label="Email"
                helperMsg="This will be your username"
              />
              <AppInput
                name="password"
                type="password"
                label="Password"
                helperMsg="Use 8 or more characters with a mix of letters, numbers & symbols"
              />
              <div className="display-hide">
                <AppInput name="recaptchaType" type="text" />
              </div>
            </div>
          </Col>
          <Col width="30%">
            <Guideline title="User Account">
              <p>
                You need to have a user account in order to set up your
                business. If you already have an user account, you can{" "}
                <Link to={authURL.Login}>log in</Link> and set up your business.
              </p>
            </Guideline>
          </Col>
        </Row>
      </LayoutBody>
      <BusinessRegisterFooter step={1} />
    </AppForm>
  );
};

export default BusinessRegisterUser;
