import { useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import BusinessServices from "../../components/services/BusinessServices";
import useToast from "../../components/ui/toasts/useToast";
import MenuCreateNew from "../../components/ui/MenuCreateNew";

const BusinessServicesPage: React.FC = () => {
	const { getAdminServices, clearAdminServiceState } = useActions();
	const { request, error, data, success } = useTypedSelector(
		(state) => state.entities.serviceAdmin
	);

	const { notify } = useToast();

	useEffect(() => {
		getAdminServices();
	}, []);

	useEffect(() => {
		if (success) {
			notify("success", success);
			clearAdminServiceState();
		}
		if (error) {
			notify("error", error);
			clearAdminServiceState();
		}
	}, [success, error]);

	return (
		<PageWithAside role='business'>
			<div className='wrapper'>
				<Header
					pageTitle='Services'
					pageDesc='List of services for customer to make booking'
					role='business'
				/>
				{request?.loading && <Loader />}
				{request?.error && <div className='error-msg'>{request.error}</div>}
				{!request && data && <BusinessServices />}
				<MenuCreateNew role='business' />
			</div>
		</PageWithAside>
	);
};

export default BusinessServicesPage;
