import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessNotificationDataType } from "../../config/types/BusinessNotificationDataType";

interface IBUsinessNotificationState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: number;
  success: boolean;
  data: IBusinessNotificationDataType[];
  unopened: number;
}

const initialState: IBUsinessNotificationState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  success: false,
  data: [],
  unopened: 0,
};

const slice = createSlice({
  name: "businessNotification",
  initialState,
  reducers: {
    businessNotificationClearState: (notification) => {
      notification.success = false;
      notification.error = false;
    },
    businessNotificationRequest: (notification) => {
      notification.loading = true;
    },
    businessNotificationReceived: (
      notification,
      action: PayloadAction<IBusinessNotificationDataType[]>
    ) => {
      console.log("businessNotificationReceived", action.payload);
      const data = action.payload;
      notification.lastFetch = Date.now();
      notification.data = data;
      if (data.length > 0) {
        const numberUnopened = data.reduce(function (i, n) {
          return i + (n.opened === true ? 0 : 1);
        }, 0);
        notification.unopened = numberUnopened;
      }
      notification.success = true;
      notification.loading = false;
    },
    businessNotificationRequestFailed: (
      notification,
      action: PayloadAction<string>
    ) => {
      notification.loading = false;
      notification.error = action.payload;
    },
    businessNotificationOpened: (notification) => {
      console.log("business notification opened");
      notification.unopened = 0;
    },
    businessNotificationRead: (
      notification,
      action: PayloadAction<IBusinessNotificationDataType>
    ) => {
      console.log("business notification read", action.payload);
      const index = notification.data.findIndex(
        (item) => item._id === action.payload._id
      );
      notification.data[index] = action.payload;
    },
    businessNotificationReadAll: (notification) => {
      console.log("all business notification read");
      notification.data.map((item) => {
        item.read = true;
      });
    },
    businessNotificationNewCreated: (
      notification,
      action: PayloadAction<IBusinessNotificationDataType>
    ) => {
      notification.data = [action.payload, ...notification.data];
      notification.unopened = notification.unopened + 1;
    },
  },
});

export const {
  businessNotificationClearState,
  businessNotificationRequest,
  businessNotificationReceived,
  businessNotificationRequestFailed,
  businessNotificationOpened,
  businessNotificationRead,
  businessNotificationReadAll,
  businessNotificationNewCreated,
} = slice.actions;

export default slice.reducer;
