import React from "react";
import { getTime } from "../../utils/datetime";
import ProgressBar from "./ProgressBar";
import styles from "./TimeslotPicker.module.scss";
import classNames from "classnames";
import { DataCapacityType } from "../../config/types/CapacityType";

const useTimeslotPicker = (
  timeRange: string,
  date: string,
  capacity: number,
  interval: number,
  dataCapacity?: DataCapacityType
) => {
  function calculateCapacity(itemHour: number, itemMinutes: number) {
    let currentCapacity = 0;
    if (dataCapacity && itemHour in dataCapacity) {
      if (interval <= 5) {
        currentCapacity = dataCapacity[itemHour][itemMinutes];
      } else {
        // If interval more than 5, get the highest capacity
        const capacityArray = [];
        for (let i = itemMinutes; i <= itemMinutes + interval; i += 5) {
          const result = dataCapacity[itemHour][i]
            ? dataCapacity[itemHour][i]
            : 0;
          capacityArray.push(result);
        }
        currentCapacity = Math.max(...capacityArray);
      }
    }
    console.log(itemHour, itemMinutes, "currentCapacity", currentCapacity);
    return currentCapacity && currentCapacity > 0
      ? (currentCapacity / capacity) * 100
      : 0;
  }

  function generateSeperator(flag: number, itemHour: number, item: Date) {
    return flag !== itemHour ? (
      <li className={styles.separator}>
        {new Date(item).toLocaleString("en-AU", {
          hour: "numeric",
          hour12: true,
        })}
      </li>
    ) : (
      false
    );
  }

  function generateTimeValue() {
    const range = JSON.parse(timeRange);
    const startTimeObject = new Date(date + " " + range[0]);
    const endTimeObject = new Date(date + " " + range[1]);
    let timeValue: Date[] = [];
    for (
      let d = startTimeObject;
      d < endTimeObject;
      d.setTime(d.getTime() + interval * 60 * 1000)
    ) {
      timeValue.push(new Date(d));
    }
    return timeValue;
  }

  function generateList(onChange: (val: Date) => void, value?: Date) {
    const timeValue = generateTimeValue();

    let flag = 1; // Hour flag
    const output = timeValue.map((item, index) => {
      const itemHour = item.getHours();
      const itemMinutes = item.getMinutes();
      const capacityPercent = calculateCapacity(itemHour, itemMinutes);

      const separator = generateSeperator(flag, itemHour, item);
      flag = itemHour;
      return (
        <React.Fragment key={index}>
          {separator && separator}
          <li
            className={classNames(
              styles["item"],
              value && getTime(value) === getTime(item) && styles["selected"]
            )}
            onClick={() => onChange(item)}
          >
            <div className={styles["time"]}>{getTime(item)}</div>
            <div className={styles["bar"]}>
              <ProgressBar number={capacityPercent} hideLabel />
            </div>
          </li>
        </React.Fragment>
      );
    });
    return output;
  }
  return { generateList };
};

export default useTimeslotPicker;
