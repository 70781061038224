import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./AuthLoginPage.module.scss";
import { AppButton, AppInput, AppForm } from "../../components/form";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { Col, Row } from "../../components/ui/Grid";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { InitialValues, Validation } from "../../config/form/formLogin";
import { authURL, personalURL } from "../../config/url";
import useToast from "../../components/ui/toasts/useToast";
import { EBtnCategory } from "../../components/form/AppButton";
import {
  getRecaptchaToken,
  loadRecaptcha,
  removeRecaptcha,
} from "../../utils/googleRecaptcha";
import { EReCaptchaType } from "../../config/types/ReCaptchaType";

const AuthLoginPage: React.FC<{}> = () => {
  useDocumentTitle("Login - AddPointment");
  const navigate = useNavigate();
  const { login, clearAuthState } = useActions();
  const {
    loading,
    error: responseError,
    user,
    passwordReset,
  } = useTypedSelector((state) => state.entities.auth);
  const [error, setError] = useState("");
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const { notify } = useToast();

  useEffect(() => {
    if (passwordReset) {
      clearAuthState();
      notify(
        "success",
        "Your password has been updated. Please log in with your new password"
      );
    }
  }, [passwordReset]);

  useEffect(() => {
    console.log("user", user);
    user && navigate(personalURL.PersonalHome);
  }, [user]);

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  useEffect(() => {
    if (responseError) {
      setError(responseError);
      clearAuthState();
    }
  }, [responseError]);

  return (
    <LayoutCenterBox>
      <div className="mb-30 text-center">
        <h1>Login</h1>
        <p>
          New to AddPointment?{" "}
          <Link to={authURL.Register} className="link">
            Create an account
          </Link>
        </p>
        {error && <p className="error-msg">{error}</p>}
      </div>
      <AppForm
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={async (values, actions) => {
          setRecaptchaLoading(true);
          const recaptchaCode = await getRecaptchaToken(EReCaptchaType.Login);
          if (!recaptchaCode)
            setError(
              "Invalid recaptcha. Make sure you have internet connection and login again."
            );
          else {
            actions.setSubmitting(false);
            login({ ...values, recaptchaCode });
          }
          setRecaptchaLoading(false);
        }}
      >
        <AppInput name="username" label="Username" labelBlock />
        <AppInput name="password" label="Password" type="password" labelBlock />
        <div className="display-hide">
          <AppInput name="recaptchaType" type="text" />
        </div>
        <Row className={styles["login-meta"]}>
          {/* <Col>
            <AppCheckBox name="remember">Remember Me</AppCheckBox>
          </Col> */}
          <Col>
            <Link to={authURL.ForgotPassword} className="link">
              Forgot Password
            </Link>
          </Col>
        </Row>
        <AppButton
          type="submit"
          btnBlock
          category={EBtnCategory.Primary}
          size="large"
          loading={loading || recaptchaLoading}
        >
          Login
        </AppButton>
      </AppForm>
    </LayoutCenterBox>
  );
};

export default AuthLoginPage;
