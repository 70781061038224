import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownAction } from "../ui/dropdown";
import { BiCalendarPlus } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import config from "../../config/global";
import { personalURL } from "../../config/url";
import BookmarkRemoveDialog, { BookmarkType } from "./BookmarkRemoveDialog";

interface BookmarkProps {
  type: BookmarkType;
  id: string;
  businessUrl?: string;
}

const TableBookmarkActions: React.FC<BookmarkProps> = ({
  type,
  id,
  businessUrl,
}) => {
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();

  const friendMenu = [
    {
      name: "New Meetup",
      desc: "Create a new meetup with the friend",
      icon: <BiCalendarPlus />,
      onClick: () => navigate(personalURL.CreateMeetup, { state: [id] }),
    },
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      desc: "Remove from your friend list",
      onClick: () => setDialog(true),
    },
  ];

  const bookmarkMenu = [
    {
      name: "Make Booking",
      desc: "Make a new booking with the business",
      icon: <BiCalendarPlus />,
      onClick: () => window.open(`${config.bookingUrl}${businessUrl}`),
    },
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      desc: "Remove from your bookmark list",
      onClick: () => setDialog(true),
    },
  ];

  return (
    <>
      <DropdownAction menu={type === "friend" ? friendMenu : bookmarkMenu} />
      {dialog && (
        <BookmarkRemoveDialog
          setVisible={(arg) => setDialog(arg)}
          id={id}
          type={type}
        />
      )}
    </>
  );
};

export default TableBookmarkActions;
