import * as reducers from "../reducer/businessNotificationReducer";
import { fetch } from "../storeConfig";
import { apiCallBegan } from "../middleware/api";
import { getNotifications as dataNotifications } from "../../data/dataNotifications";
import config from "../../config/global";

interface DispatchType {
  type: string;
}

const url = "/business-notification";

export const clearBusinessNotificationsState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.businessNotificationClearState.type });
  };

export const getBusinessNotifications =
  () =>
  (
    dispatch: (arg: DispatchType) => void,
    getState: () => {
      entities: { businessNotification: { lastFetch: number } };
    }
  ) => {
    const { lastFetch } = getState().entities.businessNotification;
    const fetchFlag = fetch(lastFetch);
    if (!fetchFlag) return;
    return dispatch(
      apiCallBegan({
        url,
        data: config.localENV && dataNotifications(),
        method: "get",
        onStart: reducers.businessNotificationRequest.type,
        onSuccess: reducers.businessNotificationReceived.type,
        onError: reducers.businessNotificationRequestFailed.type,
      })
    );
  };

export const readBusinessNotification =
  (id: string) => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/read",
        data: { id },
        method: "post",
        onSuccess: reducers.businessNotificationRead.type,
      })
    );
  };

export const readAllBusinessNotification =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/read-all",
        data: config.localENV && dataNotifications(),
        method: "post",
        onSuccess: reducers.businessNotificationReadAll.type,
      })
    );
  };

export const openBusinessNotifications =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch(
      apiCallBegan({
        url: url + "/open",
        method: "post",
        onSuccess: reducers.businessNotificationOpened.type,
      })
    );
  };
