import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AppButton } from "../form";
import { EBtnCategory } from "../form/AppButton";
import { AvatarPic } from "../ui/avatar";
import Loader from "../ui/Loader";
import styles from "./CreateBookingProvider.module.scss";
import CreateBookingTitle from "./CreateBookingTitle";

interface CreateBookingProviderProps {
  service: IServiceDataType;
  onBack(): void;
  onNext(provider: IProviderDataType): void;
}

const CreateBookingProvider: React.FC<CreateBookingProviderProps> = ({
  service,
  onBack,
  onNext,
}) => {
  const { getAdminProviders } = useActions();
  const { request, data: providers } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const [providerList, setProviderList] = useState<IProviderDataType[]>([]);

  useEffect(() => {
    getAdminProviders();
  }, []);

  useEffect(() => {
    const filterList: IProviderDataType[] = [];
    providers &&
      providers.length > 0 &&
      providers.map((provider) => {
        if (service.providers?.includes(provider._id)) {
          filterList.push(provider);
        }
      });
    setProviderList(filterList);
  }, [service, providers]);

  if (request) return <Loader />;
  else if (providerList.length === 0)
    return (
      <div className="text-center">
        <p>No provider for this service</p>
        <button className="btn btn-link" type="button" onClick={() => onBack()}>
          Go back
        </button>
      </div>
    );
  return (
    <>
      <CreateBookingTitle title="Select a Provider" onBack={onBack} />
      <ul className={styles["list"]}>
        {providerList.map((provider, index) => {
          return (
            <li key={index}>
              <div className={styles["label"]}>
                <AvatarPic src={provider.imageUrl} size="small" />
              </div>
              <div className={styles["name"]}>{provider.name}</div>
              <div className={classNames("desc", styles["title"])}>
                {provider.title}
              </div>
              <div className={styles["btn"]}>
                <AppButton
                  category={EBtnCategory.PrimaryOutline}
                  size="small"
                  onClick={() => onNext(provider)}
                >
                  Select
                </AppButton>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CreateBookingProvider;
