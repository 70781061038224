import { BiPlusMedical } from "react-icons/bi";
import InputContainer from "./InputContainer";
import styles from "./AppCoverPhoto.module.scss";
import { useEffect, useState } from "react";
import ModalUploadPicture from "../ui/avatar/ModalUploadPicture";

interface InputProps {
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  name: string;
  imageSrc?: string;
  uploadLabel: string;
  uploaded?: boolean;
  onUploadPicture: (pic: string) => void;
}

const AppCoverPhoto: React.FC<InputProps> = ({
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  name,
  imageSrc,
  uploaded,
  uploadLabel,
  onUploadPicture,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    uploaded && setModalVisible(false);
  }, [uploaded]);

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      helperMsg={helperMsg}
    >
      <div
        className={styles["photo-input"]}
        onClick={() => setModalVisible(true)}
      >
        {imageSrc ? (
          <img src={imageSrc} />
        ) : (
          <div className={styles["icon"]}>
            <BiPlusMedical />
          </div>
        )}
      </div>

      {modalVisible && (
        <ModalUploadPicture
          type="big"
          title={uploadLabel}
          onClose={() => setModalVisible(false)}
          onUploadPicture={onUploadPicture}
        />
      )}
    </InputContainer>
  );
};

export default AppCoverPhoto;
