import React from "react";
import TabHeader from "./TabHeader";
import TabBody from "./TabBody";
import styles from "./Tab.module.scss";
import classNames from "classnames";

export type TabItemsType = {
  number?: number;
  tabID: number;
  name: string;
  content: JSX.Element;
}[];

interface InputProps {
  items: TabItemsType;
  active: number;
  type: "horizontal" | "vertical";
  onTabClick(id: number): void;
  className?: string;
}

const Tab: React.FC<InputProps> = ({
  children,
  items,
  active,
  type = "horizontal",
  onTabClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        type ? styles[type] : "horizontal",
        className && className
      )}
    >
      <TabHeader items={items} active={active} onTabClick={onTabClick} />
      <TabBody>{children}</TabBody>
    </div>
  );
};

export default Tab;
