const reportUsage = [
  {
    _id: "report1",
    business: "business1",
    usageDate: new Date("2021-1"),
    usageMonth: 1,
    usageYear: 2021,
    usagePackage1: 20,
    usagePackage2: 35,
    usagePackage3: 21,
    usagePackage4: 9,
  },
];

export function getUsage() {
  return reportUsage[0];
}
