import classNames from "classnames";
import React, { useEffect } from "react";
import { FaRegClock } from "react-icons/fa";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AppButton } from "../form";
import { EBtnCategory } from "../form/AppButton";
import Loader from "../ui/Loader";
import styles from "./CreateBookingService.module.scss";
import CreateBookingTitle from "./CreateBookingTitle";
import { ESteps } from "./useCreateBooking";

interface CreateBookingServiceProps {
  onNext(service: IServiceDataType, stepTo?: number): void;
}

const CreateBookingService: React.FC<CreateBookingServiceProps> = ({
  onNext,
}) => {
  const { getAdminServices } = useActions();
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );

  useEffect(() => {
    getAdminServices();
  }, []);

  if (request) return <Loader />;
  else if (!data || data.length === 0)
    return <div className="text-center">No service ready for booking</div>;
  return (
    <>
      <CreateBookingTitle title="Select a Service" />
      <ul className={styles["list"]}>
        {data.map((service, index) => {
          return (
            <li key={index}>
              <div className={styles["label"]}>
                <span>{service.label}</span>
              </div>
              <div className={styles["name"]}>{service.name}</div>
              <div className={classNames("desc", styles["duration"])}>
                <span className={styles["icon"]}>
                  <FaRegClock />
                </span>{" "}
                {service.duration} mins
              </div>
              <div className={styles["btn"]}>
                <AppButton
                  category={EBtnCategory.PrimaryOutline}
                  size="small"
                  onClick={() => {
                    service.providers && service.providers.length > 0
                      ? onNext(service)
                      : onNext(service, ESteps.SelectDateTime);
                  }}
                >
                  Select
                </AppButton>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CreateBookingService;
