import { useState, useEffect, useContext } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessHours from "../../components/businessProfile/BusinessHours";
import { AppForm } from "../../components/form";
import Loader from "../../components/ui/Loader";
import { InitialValues, Validation } from "../../config/form/formBusinessHours";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { BusinessContext, ContextType } from "../../context/businessContext";
import useToast from "../../components/ui/toasts/useToast";
import { businessProfileSubmenu } from "../../config/submenu";
import ModalCustomBusinessHours from "../../components/businessProfile/ModalCustomBusinessHours";

const index = 2;
const BusinessHoursPage: React.FC = () => {
  useDocumentTitle("Business Hours - AddPointment");
  const { businessState } = useContext(BusinessContext) as ContextType;
  const { updateBusinessHours, clearLocationState, getLocation } = useActions();
  const { header, footer } = useBusinessProfile(index, true);
  const [initVal, setInitVal] = useState(InitialValues);
  const [modalCustom, setModalCustom] = useState(false);
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.location
  );
  const { notify } = useToast();
  const selectedLocation = businessState.location; // Selected location id

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      if (selectedLocation) {
        const result = data.filter((i) => i._id === selectedLocation)[0];
        setInitVal({
          ...initVal,
          hours: result.hours,
        });
      } else {
        setInitVal({
          ...initVal,
          hours: data[0].hours,
        });
      }
    }
  }, [data, selectedLocation]);

  useEffect(() => {
    if (success) {
      notify("success", "Business hours has been updated.");
      clearLocationState();
    } else if (error) {
      notify("error", error);
      clearLocationState();
    }
  }, [success, error]);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <AppForm
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("values", values);
          updateBusinessHours(values, selectedLocation);
        }}
        validationSchema={Validation}
      >
        <LayoutFullHeight>
          <LayoutBody>
            {header()}
            {request?.loading && <Loader />}
            {request?.error && <div className="error-msg">{request.error}</div>}
            {!request && data && (
              <>
                <div className="separator mb-20">
                  <BusinessHours isEdit />
                  {footer()}
                </div>
                <h4>Custom Business Hours</h4>
                <p className="mb-10">
                  Set your opening hours for a specific day that is different
                  from your normal business hours
                </p>
                <button
                  type="button"
                  className="btn btn-primary-outline mb-30"
                  onClick={() => setModalCustom(true)}
                >
                  Set custom business hours
                </button>
              </>
            )}
          </LayoutBody>
        </LayoutFullHeight>
      </AppForm>
      {modalCustom && (
        <ModalCustomBusinessHours onClose={() => setModalCustom(false)} />
      )}
    </PageWithAside>
  );
};

export default BusinessHoursPage;
