export const countDailyCapacity = (
  capacity: number,
  interval: number,
  servicePeriodSum: string
) => {
  let dailyCapacity: number[] = [];
  const hours: number[] = JSON.parse(servicePeriodSum);
  hours.forEach((item) => {
    dailyCapacity.push((item / interval) * capacity);
  });
  return JSON.stringify(dailyCapacity);
};
