import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";

import styles from "./ServiceLocation.module.scss";
import { AppSwitch } from "../form";

interface ServiceLocationProps {}

const ServiceLocation: React.FC<ServiceLocationProps> = ({}) => {
  return (
    <>
      <h3 className="mb-3">Location</h3>
      <div className="desc mb-20">
        Allow customers to enter their location to receive the service.
      </div>
      <Row>
        <Col width="60%">
          <div className={styles["wrapper"]}>
            <AppSwitch name="onsiteService" label="Onsite Service" />
            {/* <ServiceLocationList id={id} data={data} category={category} /> */}
          </div>
        </Col>
        <Col width="30%">
          <Guideline title="Onsite Service">
            <p>
              Allow customers to enter their location to receive the service.
            </p>
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServiceLocation;
