import * as React from "react";

type AppStateType = {
  collapsed?: boolean;
};

export type ContextType = {
  appState: AppStateType;
  handleStateChange(arg: AppStateType): void;
};
export const AppContext = React.createContext<ContextType | null>(null);

const AppProvider: React.FC = ({ children }) => {
  const [appState, setAppState] = React.useState({ collapsed: false });

  const handleStateChange = (arg: AppStateType) => {
    console.log("clicked", { ...appState, ...arg });
    setAppState({ ...appState, ...arg });
  };

  return (
    <AppContext.Provider value={{ appState, handleStateChange }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;
