import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import TableNowServing from "./TableNowServing";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { businessURL } from "../../config/url";
import useGetBookingType from "../../hooks/useGetBookingType";

const BusinessNowServing: React.FC = () => {
  const { services, resources, providers } = useGetBookingType("none");

  return (
    <Row>
      <Col width="60%">
        <Link
          className="btn btn-primary-outline mb-30"
          to={businessURL.BusinessNowServingAll}
          target="blank"
        >
          <span className="mr-10">View all Now Serving</span>
          <FaExternalLinkAlt />
        </Link>
        {services.length > 0 && (
          <>
            <Row className="separator pb-5">
              <Col>
                <h4>Services</h4>
              </Col>
              {/* <Col>
            <Link
              className="btn btn-link"
              to={`${businessURL.BusinessNowServingAll}/services`}
              target="_blank"
            >
              <span className="mr-3">View by services</span>{" "}
              <FaExternalLinkAlt />
            </Link>
          </Col> */}
            </Row>
            <TableNowServing data={services} type="service" />
          </>
        )}
        {resources.length > 0 && (
          <>
            <Row className="separator pb-5">
              <Col>
                <h4>Resources</h4>
              </Col>
              {/* <Col>
            <Link
              className="btn btn-link"
              to={`${businessURL.BusinessNowServingAll}/resources`}
              target="_blank"
            >
              <span className="mr-3">View by resources</span>{" "}
              <FaExternalLinkAlt />
            </Link>
          </Col> */}
            </Row>
            <TableNowServing data={resources} type="resource" />
          </>
        )}
        {resources.length > 0 && (
          <>
            <Row className="separator pb-5">
              <Col>
                <h4>Providers</h4>
              </Col>
              {/* <Col>
            <Link
              className="btn btn-link"
              to={`${businessURL.BusinessNowServingAll}/providers`}
              target="_blank"
            >
              <span className="mr-3">View by providers</span>{" "}
              <FaExternalLinkAlt />
            </Link>
          </Col> */}
            </Row>
            <TableNowServing data={providers} type="provider" />
          </>
        )}
      </Col>
      <Col width="30%">
        <Guideline title="Now Serving">
          <p>
            You can <b>register</b> a walk-in customer or print out the{" "}
            <b>QR code</b> for the service.Customer can scan the QR code via{" "}
            <b>AddPointment App</b> to register themselves
          </p>
        </Guideline>
      </Col>
    </Row>
  );
};

export default BusinessNowServing;
