import classNames from "classnames";
import styles from "./Loader.module.scss";

interface iLoaderProps {
  alignCenter?: boolean;
  height?: string;
}
const Loader: React.FC<iLoaderProps> = ({ alignCenter, height }) => {
  return (
    <div
      className={classNames(styles["center"])}
      style={{ minHeight: height ? height : "300px" }}
    >
      <div className={styles["container"]}>
        <div className={styles["icon"]}></div>
      </div>
    </div>
  );
};

export default Loader;
