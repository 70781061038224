import { Route } from "react-router-dom";
import { authURL } from "../config/url";
import AuthLoginPage from "../pages/auth/AuthLoginPage";
import AuthLogoutPage from "../pages/auth/AuthLogoutPage";
import AuthRegisterPage from "../pages/auth/AuthRegisterPage";
import AuthRegisterProfilePage from "../pages/auth/AuthRegisterProfilePage";
import AuthResetPasswordPage from "../pages/auth/AuthResetPasswordPage";
// import AuthPhoneVerificationPage from "../../pages/auth/AuthPhoneVerificationPage";
import AuthRegisterEmailPage from "../pages/auth/AuthRegisterEmailPage";
import AuthPinNumberPage from "../pages/auth/AuthPinNumberPage";
import AuthForgotPasswordPage from "../pages/auth/AuthForgotPasswordPage";
import AuthResetSuccessPage from "../pages/auth/AuthResetSuccessPage";
import AuthRegisterBusinessPage from "../pages/auth/AuthRegisterBusinessPage";

const authRoutes = () => {
  return (
    <>
      <Route path={authURL.Login} element={<AuthLoginPage />} />
      <Route path={authURL.Logout} element={<AuthLogoutPage />} />
      <Route path={authURL.Register} element={<AuthRegisterPage />} />
      <Route path={authURL.RegisterEmail} element={<AuthRegisterEmailPage />} />
      <Route path={authURL.RegisterPin} element={<AuthPinNumberPage />} />
      <Route
        path={authURL.RegisterProfile}
        element={<AuthRegisterProfilePage />}
      />
      <Route
        path={authURL.ForgotPassword}
        element={<AuthForgotPasswordPage />}
      />
      <Route path={authURL.ResetSuccess} element={<AuthResetSuccessPage />} />
      <Route path={authURL.ResetPassword} element={<AuthResetPasswordPage />} />
      <Route
        path={authURL.RegisterBusiness}
        element={<AuthRegisterBusinessPage />}
      />
    </>
  );
};

export default authRoutes;
