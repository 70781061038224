import * as reducers from "../reducer/serviceCategoryAdminReducer";
import { apiCallBegan } from "../middleware/api";
import config from "../../config/global";
import { getServicesCategory as testServicesCategory } from "../../data/dataServicesCategory";

interface DispatchType {
  type: string;
}

const url = "/service-category-admin";

export const clearAdminServicesCategoryState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.serviceCategoryClearState.type });
  };

export const getAdminServicesCategory =
  () => (dispatch: (arg: DispatchType) => void) => {
    console.log("getAdminServicesCategory");
    return dispatch(
      apiCallBegan({
        url: url + "/me",
        method: "get",
        data: config.localENV ? testServicesCategory() : null,
        onStart: reducers.serviceCategoryRequest.type,
        onSuccess: reducers.serviceCategoryReceived.type,
        onError: reducers.serviceCategoryFailed.type,
      })
    );
  };

export const addAdminServicesCategory =
  (name: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("addServiceCategory", name);
    return dispatch(
      apiCallBegan({
        url: url + "/add",
        method: "post",
        data: { name },
        onStart: reducers.serviceCategoryRequest.type,
        onSuccess: reducers.serviceCategoryAdded.type,
        onError: reducers.serviceCategoryFailed.type,
      })
    );
  };

export const removeAdminServicesCategory =
  (_id: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("removeServiceCategory", _id);
    return dispatch(
      apiCallBegan({
        url: url + "/remove",
        method: "post",
        data: { _id },
        onStartDispatch: {
          type: reducers.serviceCategoryRemoving.type,
          payload: _id,
        },
        // onStart: reducers.serviceCategoryRemoving.type,
        onSuccess: reducers.serviceCategoryRemoved.type,
        onError: reducers.serviecCategoryRemoveFailed.type,
      })
    );
  };

export const updateAdminServicesCategory =
  (_id: string, name: string) => (dispatch: (arg: DispatchType) => void) => {
    console.log("updateServiceCategory", name, _id);
    return dispatch(
      apiCallBegan({
        url: url + "/update",
        method: "post",
        data: { _id, name },
        onStartDispatch: {
          type: reducers.serviceCategoryUpdating.type,
          payload: { _id, name },
        },
        // onStart: reducers.serviceCategoryRequest.type,
        onSuccess: reducers.serviceCategoryUpdated.type,
        onError: reducers.serviecCategoryUpdateFailed.type,
      })
    );
  };
