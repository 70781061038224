import styles from "../personal/ParticipantList.module.scss";
import { ListTableDataType } from "../ui/ListTable";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { useEffect, useState } from "react";
import { IServiceDataType } from "../../config/types/ServiceDataType";

const useProviderServices = (provider: IProviderDataType) => {
  const { removeAdminProviderService } = useActions();
  const { data: services } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const [providerServices, setProviderServices] = useState<string[]>(
    provider.services
  );

  // remove service
  const handleDelete = (serviceId: string) => {
    console.log("handleDelete", serviceId);
    const filteredList = providerServices?.filter((id) => id !== serviceId);
    setProviderServices(filteredList);
    removeAdminProviderService(provider._id, serviceId);
  };

  const servicesList: ListTableDataType[] = [];

  // console.log("provider services", providerServices);
  if (providerServices.length > 0) {
    services.map((service: IServiceDataType) => {
      if (providerServices.includes(service._id)) {
        servicesList.push({
          id: service._id,
          content: (
            <div className={styles["participant-list"]}>
              <div className="h5">{service.name}</div>
            </div>
          ),
        });
      }
    });
  }

  return {
    servicesList,
    handleDelete,
  };
};

export default useProviderServices;
