import React from "react";
import DropdownAction from "../dropdown/DropdownAction";
import EmptyData from "../EmptyData";
import Loader from "../Loader";
import styles from "./NotificationList.module.scss";

interface INotificationList {
  onReadAll(): void;
  loading?: boolean;
}

const NotificationList: React.FC<INotificationList> = ({
  onReadAll,
  loading,
  children,
}) => {
  const menu = [
    {
      name: "Mark all as read",
      onClick: () => onReadAll(),
    },
  ];

  if (loading) return <Loader />;
  else if (children) {
    return (
      <>
        <div className={styles["header"]}>
          <div className="h4 py-5 px-10">Notifications</div>
          <div>
            <DropdownAction minWidth={250} menu={menu} />
          </div>
        </div>
        {children}
      </>
    );
  }
  return (
    <EmptyData title="No Notification">
      <p>We will let you know when you have a booking or meetup</p>
    </EmptyData>
  );
};

export default NotificationList;
