import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import classnames from "classnames";
import styles from "./Table.module.scss";
// import { DropdownMenu } from "../dropdown";
import { useState } from "react";

export type TableColumnsType = {
  name: string;
  label?: string;
  cellClick?(): void;
  cellClass?: string;
  colWidth?: string;
  colMaxWidth?: string;
  alignTop?: boolean;
  disableItemClick?: boolean;
  content(arg: Object, index: number): React.ReactNode;
}[];

export type TableDataType = { _id: string }[];

interface TableProps {
  noDataMsg?: string;
  columns: TableColumnsType;
  data: TableDataType;
  className?: string;
  itemClick?(arg: { _id: string }): void;
  noHeader?: boolean;
}

const Table: React.FC<TableProps> = ({
  noDataMsg,
  columns,
  data,
  className,
  itemClick,
  noHeader,
}) => {
  // console.log("data length IS: ", data.length);

  const [page, setPage] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);

  // console.log("THIS IS DATA!!! ", data);
  const pagenum = ["1", "2", "3", "4", "5"];
  const menuList = [
    {
      name: "10",
      onClick: () => {
        setRowLimit(10);
      },
    },
    {
      name: "20",
      onClick: () => {
        setRowLimit(20);
      },
    },
    {
      name: "30",
      onClick: () => {
        setRowLimit(30);
      },
    },
    {
      name: "40",
      onClick: () => {
        setRowLimit(40);
      },
    },
    {
      name: "50",
      onClick: () => {
        setRowLimit(50);
      },
    },
  ];

  return (
    <>
      <div className={classnames(styles["container"], className && className)}>
        {!noHeader && <TableHeader columns={columns} />}

        <TableBody
          // rowLimit={rowLimit}
          // pageSelect={page}
          columns={columns}
          data={data}
          itemClick={itemClick}
        />

        {data.length === 0 && <p>{noDataMsg}</p>}
      </div>
      {/* <div className={styles["pagination"]}>
        <div className={styles["row-select"]}>
          <span className="mr-10">Row per page</span>{" "}
          <DropdownMenu menuList={menuList} />
        </div>
        <div className={styles["page-seleect"]}>
          <button
            type="button"
            className={styles["first-page-button"]}
          >{`<<`}</button>
          <button
            type="button"
            className={styles["prev-page-button"]}
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
          >{`<`}</button>
          {pagenum.length > 1 &&
            pagenum.map((val, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  className={styles["page-number"]}
                  onClick={() => {
                    setPage(index + 1);
                    console.log("Page Click>>> ", index + 1);
                  }}
                >
                  {index + 1}
                </button>
              );
            })}
          <button
            type="button"
            className={styles["next-page-button"]}
            onClick={() => {
              // setPage(page + 1);
            }}
          >{`>`}</button>
          <button
            type="button"
            className={styles["last-page-button"]}
          >{`>>`}</button>
        </div>
      </div> */}
    </>
  );
};

export default Table;
