import config from "../config/global";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const loadGoogleAnalytics = () => {
  const script = document.createElement("script");

  script.src = `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalytics}`;
  script.id = "ga-script";
  script.async = true;

  document.body.append(script);
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag(arg: any, arg2: any): void {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", config.googleAnalytics);
};
