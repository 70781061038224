import { useFormikContext } from "formik";
import classnames from "classnames";

import styles from "./AppRadioButton.module.scss";
import InputContainer from "./InputContainer";

interface RadioButtonProps {
  name: string;
  label?: string;
  labelWidth?: string;
  helperMsg?: string;
  options: { name: string; value: string | number }[];
  labelBlock?: boolean;
  radioGroup?: boolean;
}

interface FormikContextProps {
  [name: string]: string;
  values: string;
}

const AppRadioButton: React.FC<RadioButtonProps> = ({
  name,
  label,
  labelWidth,
  helperMsg,
  options,
  labelBlock,
  radioGroup,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();
  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      {options.map((option, index) => (
        <div
          className={classnames(
            styles["radio-button"],
            radioGroup && styles["radio-group"]
          )}
          key={index}
        >
          <input
            id={option.value.toString()}
            type="radio"
            name={name}
            onChange={() => setFieldValue(name, option.value)}
            checked={values[name] === option.value ? true : false}
          />
          <label htmlFor={option.value.toString()}>{option.name}</label>
        </div>
      ))}
    </InputContainer>
  );
};

export default AppRadioButton;
