const customers = [
  {
    _id: "customer1",
    tags: [
      {
        id: "tag1",
        name: "New",
      },
      {
        id: "tag2",
        name: "Kids",
      },
    ],
    customerId: "1020",
    displayName: "First Ipsum is simply dummy te",
    user: "user1",
    pin: "1234",
  },
  {
    _id: "customer2",
    tags: [
      {
        id: "tag1",
        name: "New",
      },
    ],
    customerId: "1021",
    displayName: "Second Ipsum is simply dummy text of the printing",
    user: "user2",
    pin: "1234",
  },
  {
    _id: "customer3",
    tags: [],
    customerId: "1022",
    displayName: "Third User",
    user: "user3",
    pin: "1234",
  },
  {
    _id: "customer4",
    tags: [
      {
        id: "tag3",
        name: "Regular",
      },
    ],
    customerId: "1023",
    displayName: "4th User",
    user: "user5",
    pin: "1234",
  },
];

export function getCustomers() {
  return customers;
  // return [];
}

export function getSingleCustomer(id) {
  const customer = customers.find((m) => m._id === id);
  return customer ? customer : customers[0];
}

export function getCustomer(id) {
  return Array.isArray(id)
    ? customers.filter((i) => id.includes(i._id))
    : customers.filter((m) => m._id === id);
}
