const capacity = {
  _id: "capacity-1",
  entityId: "service1",
  entityType: "service",
  capacity: "2021-01-01",
  capacityDay: 1,
  capacityMonth: 1,
  capacityYear: 2021,
  capacity: {
    10: {
      0: 2,
      5: 2,
      10: 3,
      15: 3,
      20: 2,
      25: 2,
      30: 3,
      35: 3,
      40: 3,
      // 45: 3,
      // 50: 1,
      // 55: 1,
    },
    11: {
      // 0: 2,
      // 5: 2,
      10: 4,
      15: 4,
      20: 4,
      25: 4,
      30: 0,
      35: 0,
      40: 1,
      45: 1,
      50: 1,
      55: 1,
    },
  },
};

export function getCapacity() {
  return capacity;
}
