import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import BusinessRegisterUser from "../../components/businessRegister/BusinessRegisterUser";
import BusinessRegisterEmail from "../../components/businessRegister/BusinessRegisterEmail";
import BusinessRegisterProfile from "../../components/businessRegister/BusinessRegisterProfile";
import BusinessRegisterBusinessInfo from "../../components/businessRegister/BusinessRegisterBusinessInfo";
import BusinessRegisterBusinessContact from "../../components/businessRegister/BusinessRegisterBusinessContact";
import BusinessRegisterBusinessHours from "../../components/businessRegister/BusinessRegisterBusinessHours";

const useRegisterBusinessStep = () => {
  const [step, setStep] = useState(0);
  const {
    getSelfBusiness,
    clearRegisterState,
    clearSetupState,
    businessCreated,
    authState,
  } = useActions();
  const { registerStep: setupStep } = useTypedSelector(
    (state) => state.entities.setup
  );
  const { registerStep } = useTypedSelector((state) => state.entities.register);

  useEffect(() => {
    registerStep && setStep(registerStep);
  }, [registerStep]);

  useEffect(() => {
    return () => {
      clearSetupState();
      clearRegisterState();
      businessCreated();
    };
  }, []);

  useEffect(() => {
    //business register step 4-6
    if (setupStep) {
      if (setupStep === 3) {
        authState();
        getSelfBusiness(true);
      } else setStep(setupStep + 3);
    }
  }, [setupStep]);

  const registerBusinessContent = [
    <BusinessRegisterUser />,
    <BusinessRegisterEmail />,
    <BusinessRegisterProfile />,
    <BusinessRegisterBusinessInfo />,
    <BusinessRegisterBusinessContact />,
    <BusinessRegisterBusinessHours />,
  ];

  return { registerBusinessContent, step };
};

export default useRegisterBusinessStep;
