import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import ServiceOverview from "../../components/services/ServiceOverview";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import ServiceActionMenu from "../../components/services/ServiceActionMenu";

const ServiceOverviewPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { service, header, submenu } = useBusinessServicePage(id);
  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && service && (
          <>
            <ServiceOverview data={service} id={id} />
            <ServiceActionMenu id={id} name={service.name} type="details" />
          </>
        )}
      </div>
    </PageWithAside>
  );
};

export default ServiceOverviewPage;
