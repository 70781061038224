import * as yup from "yup";

export const InitialValues = {
  type: 0,
  label: "",
  service: "",
  provider: "",
  resource: "",
};

export const Validation = yup.object().shape({
  type: yup
    .number()
    .min(1, "Please select a type")
    .required("Please select a type"),
  label: yup
    .string()
    .max(6, "Maximum 6 characters for label")
    .required("Please enter a label"),
  // TODO: at least one
  service: yup.string().notRequired(),
  resource: yup.string().notRequired(),
  provider: yup.string().notRequired(),
});

export type MeetingPointFormType = {
  //   id: string;
  type: number;
  label: string;
  service?: string;
  resource?: string;
  provider?: string;
};
