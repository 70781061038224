import { createSlice } from "@reduxjs/toolkit";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import { ILocationDataType } from "../../config/types/LocationDataType";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface BusinessState {
  loading: boolean;
  error: boolean | string;
  data?: {
    business: IBusinessDataType;
    services: IServiceDataType[];
    locations: ILocationDataType[];
  };
}

const initialState: BusinessState = {
  loading: true,
  error: false,
  data: undefined,
};

const slice = createSlice({
  name: "business",
  initialState,
  reducers: {
    businessRequest: (business) => {
      business.loading = true;
    },
    businessReceived: (business, action) => {
      console.log("businessReceived", action.payload);
      business.data = action.payload;
      business.loading = false;
    },
    businessRequestFailed: (business, action) => {
      business.loading = false;
      business.error = action.payload;
    },
  },
});

export const { businessRequest, businessReceived, businessRequestFailed } =
  slice.actions;

export default slice.reducer;
