import Table, { TableDataType } from "../ui/table";
import styles from "./TableWalkInRegistration.module.scss";
import classNames from "classnames";
import useBusinessWalkInReg from "./useBusinessWalkInReg";

type BookingType = "Services" | "Resources" | "Providers";

interface TableProps {
  data: TableDataType;
  onView(id: string, type: BookingType): void;
  type: BookingType;
}

const TableWalkInRegistration: React.FC<TableProps> = ({
  data,
  onView,
  type,
}) => {
  const { numberOfWaiting } = useBusinessWalkInReg();
  const columns = [
    {
      label: type,
      name: "service",
      colWidth: "50%",
      content: (tools: { label: string; name: string }) => (
        <div className={styles["service"]}>
          <div className={styles["label"]}>{tools.label}</div>
          <div>{tools.name}</div>
        </div>
      ),
    },
    {
      label: "Status",
      name: "status",
      colWidth: "30%",
      content: (tools: { bookingTypeId: string }) => (
        <div>{numberOfWaiting(tools.bookingTypeId)}</div>
      ),
    },
    // {
    //   label: "Waiting",
    //   name: "waiting",
    //   colWidth: "20%",
    //   content: (tools: { wating: number }) => <div>{tools.wating}</div>,
    // },
    {
      name: "actions",
      colWidth: "20%",
      content: () => (
        <button
          type="button"
          className={classNames("btn btn-empty", styles["button"])}
        >
          Book Now
        </button>
      ),
    },
  ];
  return (
    <>
      <Table
        data={data}
        columns={columns}
        className={classNames(styles["table"], "my-30")}
        itemClick={(tools: { _id: string }) => onView(tools._id, type)}
      />
    </>
  );
};

export default TableWalkInRegistration;
