import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import {
	LayoutBody,
	LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import { InitialValues, Validation } from "../../config/form/formServicePeriod";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import useBusinessService from "../../components/services/useBusinessService";
import { businessURL } from "../../config/url";

const ServiceResourcesPage: React.FC = () => {
	const { id } = useParams() as { id: string };
	const { loading, data } = useTypedSelector(
		(state) => state.entities.serviceAdmin
	);
	const [initVal, setInitVal] = useState(InitialValues);
	const { service, submenu, header, footer, bookingType } =
		useBusinessServicePage(id);

	// const { resourcesPage } = useBusinessService();

	useEffect(() => {
		if (service) {
			setInitVal({
				...initVal,
				// TODO: feed correct value
				// servicePeriod: service.servicePeriod,
				// servicePeriodOption: service.servicePeriodOption,
			});
		}
	}, [service]);
	return (
		<PageWithAside
			role="business"
			submenuName="Services"
			submenuList={submenu}
			noPadding
			goUpLevel={businessURL.BusinessServices}
		>
			<AppForm
				initialValues={initVal}
				onSubmit={(values) => {
					console.log(values);
				}}
				validationSchema={Validation}
			>
				{header}
				<div className="wrapper">
					{" "}
					{!data || loading ? (
						<Loader />
					) : (
						<>
							{/* {resourcesPage(bookingType)} */}
							{footer}
						</>
					)}
				</div>
				{/* <LayoutFullHeight>
          {header}
          <LayoutBody>
            {!data || loading ? <Loader /> : resourcesPage()}
          </LayoutBody>
          {footer}
        </LayoutFullHeight> */}
			</AppForm>
		</PageWithAside>
	);
};

export default ServiceResourcesPage;
