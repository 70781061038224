import classNames from "classnames";
import styles from "./TableServicesMethod.module.scss";

interface MethodProps {
  advanceBooking: boolean;
  walkIn: boolean;
}

const advanceBookingLabel = (
  <div className={classNames(styles["status"], styles["advance"])}>
    Advance Booking
  </div>
);

const walkInLabel = (
  <div className={classNames(styles["status"], styles["walk-in"])}>Walk-in</div>
);

const TableServicesMethod: React.FC<MethodProps> = ({
  advanceBooking,
  walkIn,
}) => {
  return (
    <>
      {advanceBooking && advanceBookingLabel}
      {walkIn && walkInLabel}
    </>
  );
};
export default TableServicesMethod;
