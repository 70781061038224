import React, { useContext } from "react";
import styles from "./Aside.module.scss";
import { AppContext, ContextType } from "../../context/appContext";
import classNames from "classnames";
import MainMenu from "./MainMenu";
import { FaChevronLeft } from "react-icons/fa";
import Accounts from "./Accounts";

interface AsideProps {
  role: "business" | "personal";
}
const Aside: React.FC<AsideProps> = ({ role }) => {
  const { appState, handleStateChange } = useContext(AppContext) as ContextType;
  const handleOnClick = () => {
    appState.collapsed
      ? handleStateChange({ collapsed: false })
      : handleStateChange({ collapsed: true });
  };
  return (
    <aside
      className={classNames(
        styles["aside-container"],
        appState.collapsed && styles["collapsed"],
        styles[`${role}`]
      )}
    >
      <Accounts role={role} />
      <MainMenu role={role} />
      <button
        type="button"
        className={styles["btn"]}
        onClick={() => handleOnClick()}
      >
        <FaChevronLeft />
      </button>
    </aside>
  );
};

export default Aside;
