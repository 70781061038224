import { Route } from "react-router-dom";
import { businessURL, businessServiceURL } from "../config/url";
import ServiceInfoPage from "../pages/businessService/ServiceInfoPage";
import ServiceBookingSlotPage from "../pages/businessService/ServiceBookingSlotPage";
import ServicePeriodPage from "../pages/businessService/ServicePeriodPage";
import ServiceOverviewPage from "../pages/businessService/ServiceOverviewPage";
import ServiceBookingPeriodPage from "../pages/businessService/ServiceBookingPeriodPage";
import ServiceResourcesPage from "../pages/businessService/ServiceResourcesPage";
import ServiceStaffPage from "../pages/businessService/ServiceStaffPage";
import ServiceLocationPage from "../pages/businessService/ServiceLocationPage";
import ServiceCustomerPage from "../pages/businessService/ServiceCustomerPage";
import ServiceCustomPage from "../pages/businessService/ServiceCustomPage";
import BusinessRouter from "./BusinessRouter";
import ServiceAdvancedPage from "../pages/businessService/ServiceAdvancedPage";
import ServiceProviderPage from "../pages/businessService/ServiceProviderPage";

const businessServiceRoutes = () => {
	return (
		<>
			<Route path={businessURL.BusinessServices} element={<BusinessRouter />}>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Overview}`}
					element={<ServiceOverviewPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Info}`}
					element={<ServiceInfoPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.BookingSlot}`}
					element={<ServiceBookingSlotPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.ServicePeriod}`}
					element={<ServicePeriodPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.BookingPeriod}`}
					element={<ServiceBookingPeriodPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Provider}`}
					element={<ServiceProviderPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Resources}`}
					element={<ServiceResourcesPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Staff}`}
					element={<ServiceStaffPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Location}`}
					element={<ServiceLocationPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Advanced}`}
					element={<ServiceAdvancedPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Customer}`}
					element={<ServiceCustomerPage />}
				/>
				<Route
					path={`${businessURL.BusinessServices}/:id/${businessServiceURL.Custom}`}
					element={<ServiceCustomPage />}
				/>
			</Route>
		</>
	);
};

export default businessServiceRoutes;
