import { getDate } from "../../utils/datetime";
import { AppButton } from "../form";
import Table, { TableDataType } from "../ui/table";
import TableWaitingListActions from "./TableWaitingListActions";
// import useBusinessProvider from "../providers/useBusinessProvider";
// import useBusinessResource from "../resources/useBusinessResource";
import ApptTypeName from "../appointment/ApptTypeName";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";

interface TableProps {
  data: TableDataType;
  onView(id: string): void;
}

const TableWaitingList: React.FC<TableProps> = ({ data, onView }) => {
  // const { getResourceName } = useBusinessResource();
  // const { getProviderName } = useBusinessProvider();
  const columns = [
    {
      label: "Customer",
      name: "customer",
      colWidth: "20%",
      content: (booking: { customer: { customerId: string } }) => (
        <>{booking.customer.customerId}</>
      ),
    },
    {
      label: "Service",
      name: "service",
      colWidth: "30%",
      colMaxWidth: "40%",
      content: (booking: IBookingDataType & IMeetupDataType) => (
        <ApptTypeName data={booking} />
      ),
    },
    {
      label: "Register Date",
      name: "date",
      colWidth: "120px",
      colMaxWidth: "160px",
      content: (bookings: { timestamp: { created: string } }) => (
        <div className="desc">{getDate(bookings.timestamp.created)}</div>
      ),
    },
    {
      name: "create",
      colWidth: "200px",
      colMaxWidth: "220px",
      content: () => (
        <AppButton
          type="button"
          className="btn btn-link"
          onClick={() => {
            console.log("Create New Booking is clicked");
          }}
        >
          Create New Booking
        </AppButton>
      ),
    },
    {
      name: "action",
      colWidth: "60px",
      colMaxWidth: "90px",
      content: () => <TableWaitingListActions />,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      itemClick={(bookings: { _id: string }) => onView(bookings._id)}
    />
  );
};

export default TableWaitingList;
