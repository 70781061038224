import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { DropdownItemListType } from "../ui/dropdown/DropdownItemList";

const useFilterServices = (
  onClick: (id?: string, type?: string) => void,
  label?: string
) => {
  const { data } = useTypedSelector((state) => state.entities.serviceCategory);

  const [list, setList] = useState<DropdownItemListType[] | any>();
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    let filtered: DropdownItemListType[] = [];
    if (data && data.length > 0) {
      // filtered.push({
      //   label: "Categories",
      // });
      data?.forEach((i) => {
        filtered.push({
          name: i.name,
          onClick: () => {
            onClick(i._id, "category");
            setSelected(i.name);
          },
        });
      });
    }

    if (filtered.length > 0 && !label) {
      filtered.unshift({
        name: "All",
        onClick: () => {
          onClick();
          setSelected("All");
        },
      });
    }
    setList(filtered);
  }, [data]);

  return { list, selected };
};

export default useFilterServices;
