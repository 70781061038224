// TODO: temporary has error
import { useState } from "react";

import TableWaitingList from "./TableWaitingList";
import { getBookings } from "../../data/dataAppts";
import ModalBooking from "../businessBooking/ModalBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";

const BusinessWaitingList: React.FC = () => {
  const data = getBookings();
  const [selectedId, setSelectedId] = useState<string | null>();

  if (!data || data.length === 0) return <h4>No Data</h4>;
  return (
    <>
      <h3>Waiting List</h3>
      <TableWaitingList
        data={data}
        onView={(id: string) => setSelectedId(id)}
      />
      {selectedId && (
        <ModalBooking id={selectedId} onClose={() => setSelectedId(null)} />
      )}
    </>
  );
};

export default BusinessWaitingList;
