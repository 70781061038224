import * as React from "react";
import { Formik, FormikProps } from "formik";

interface AppFormProps {
  initialValues: object;
  onSubmit: (values: any, actions?: any) => void;
  innerRef?: any;
  validationSchema: object;
  className?: string;
}

const AppForm: React.FC<AppFormProps> = (props) => {
  const {
    initialValues,
    onSubmit,
    validationSchema,
    innerRef,
    children,
    ...rest
  } = props;

  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      {...rest}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className={props.className}>
          {children}
        </form>
      )}
    </Formik>
  );
};

export default AppForm;
