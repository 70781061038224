import styles from "./Grid.module.scss";
import classnames from "classnames";

interface RowProps {
  className?: string;
}

export const Row: React.FC<RowProps> = ({ children, className }) => {
  return (
    <div className={classnames(styles["row"], className && className)}>
      {children}
    </div>
  );
};

interface ColProps {
  width?: string;
  className?: string;
  stretch?: boolean;
}
export const Col: React.FC<ColProps> = ({
  children,
  width,
  className,
  stretch,
}) => {
  const style = {
    // flex: width ? `0 1 ${width}` : "1",
    width: width ? width : "auto",
  };
  return (
    <div
      className={classnames(
        styles["col"],
        className && className,
        stretch && styles["stretch"]
      )}
      style={style}
    >
      {children}
    </div>
  );
};
