// Layout with Full height
// Header will stay on top
// Footer will stay at bottom

import styles from "./LayoutFullHeight.module.scss";

export const LayoutFullHeight: React.FC = ({ children }) => {
  return <div className={styles["outer"]}>{children}</div>;
};

export { default as LayoutHeader } from "./LayoutHeader";
export { default as LayoutBody } from "./LayoutBody";
export { default as LayoutFooter } from "./LayoutFooter";
