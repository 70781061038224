import classNames from "classnames";
import styles from "./LayoutFullHeight.module.scss";

const LayoutBody: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames(styles["body"], className && className)}>
      <div className="wrapper">{children}</div>
    </div>
  );
};

export default LayoutBody;
