import { ModalBody, ModalBox } from "../ui/modal";
import { AppButton } from "../form";
import styles from "./ModalWalkInReg.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { EBtnCategory } from "../form/AppButton";

interface ModalWalkInRegProps {
  onClose(): void;
  data: { name: string };
}

const ModalWalkInReg: React.FC<ModalWalkInRegProps> = ({ onClose, data }) => {
  const { register } = useTypedSelector((state) => state.entities.walkIn);
  return (
    <ModalBox
      onClose={onClose}
      type="business"
      overlayClose={true}
      size="small"
    >
      <ModalBody>
        <div className="py-30 px-20 text-center">
          <div className="h4 mb-20">{data.name}</div>
          <div className={styles["desc"]}>Queue No.</div>
          <div className={styles["number"]}>
            {register?.loading && "Loading"}
            {register?.code && register.code}
          </div>
          <AppButton
            category={EBtnCategory.Primary}
            size="large"
            onClick={onClose}
          >
            Done
          </AppButton>
        </div>
      </ModalBody>
    </ModalBox>
  );
};

export default ModalWalkInReg;
