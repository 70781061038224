import { useState } from "react";
import { Link } from "react-router-dom";
import TableFriend from "./TableFriend";
import ModalFriend from "./ModalFriend";
import { IFriendDataType } from "../../config/types/FriendDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import iconFriend from "../../assets/images/friend.svg";
import EmptyData from "../ui/EmptyData";
import { personalURL } from "../../config/url";

const PersonalFriend: React.FC = () => {
  const [modalPersonal, setModalPersonal] = useState<IFriendDataType | null>();
  const { data } = useTypedSelector((state) => state.entities.friend);

  const handlePersonalView = (id: string) => {
    const friend = data.find((user) => user._id === id);
    friend && setModalPersonal(friend);
  };

  if (data.length === 0)
    return (
      <EmptyData title="Add Friend" size="large" image={iconFriend}>
        <p>
          Your friends will be added to your list when you{" "}
          <Link className="link" to={personalURL.CreateMeetup}>
            schedule a meet up
          </Link>{" "}
          with them.
        </p>
      </EmptyData>
    );
  return (
    <>
      <TableFriend data={data} onView={handlePersonalView} />
      {modalPersonal && (
        <ModalFriend
          onClose={() => setModalPersonal(null)}
          data={modalPersonal}
        />
      )}
    </>
  );
};

export default PersonalFriend;
