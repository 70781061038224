import { useEffect, useState } from "react";
import { AppForm, AppInput, AppRadioButton } from "../form";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  InitialValues,
  Validation,
} from "../../config/form/formRegisterProfile";
import { gender } from "../../config/option/optionProfile";
import Guideline from "../ui/Guideline";
import styles from "./BusinessRegister.module.scss";
import AppCountryRegion from "../form/AppCountryRegion";
import AppInputCode from "../form/AppInputCode";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LayoutBody } from "../layout/LayoutFullHeight";
import BusinessRegisterFooter from "./BusinessRegisterFooter";
import { Col, Row } from "../ui/Grid";

const BusinessRegisterProfile: React.FC = () => {
  useDocumentTitle("Register a Business - Personal Profile");
  const [initVal, setInitVal] = useState(InitialValues);
  const { registerProfile } = useActions();
  const { data, error } = useTypedSelector((state) => state.entities.register);

  useEffect(() => {
    if (data) {
      setInitVal({
        ...initVal,
        country: data.country,
      });
    }
  }, [data]);

  return (
    <AppForm
      className="flex-layout"
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        registerProfile(values);
        actions.setSubmitting(false);
      }}
    >
      <LayoutBody>
        <h3 className="mb-3">Personal Profile</h3>
        <div className="desc mb-20">
          This information will be used for verification when you make booking
        </div>
        {error && <p className="error-msg mb-20">{error}</p>}
        <Row>
          <Col width="60%">
            <div className={styles["wrapper"]}>
              <AppInput name="displayName" label="Name" />
              <AppRadioButton
                name="gender"
                label="Gender"
                radioGroup={true}
                options={gender}
              />
              <AppInput name="age" label="Age" type="number" />
              <AppInput name="city" label="City" />
              <AppInput name="postcode" label="Postal Code" />
              <AppCountryRegion name="country" label="Country" />
              <AppCountryRegion name="state" label="State" />
              <AppInputCode
                name="pin"
                codeLength={4}
                // type="number"
                isForm
                label="PIN Number"
                helperMsg="Enter last 4 digits of your NRIC. PIN number will be used to
                verify your identity during the meet-up and cannot be changed
                later"
              />
            </div>
          </Col>
          <Col width="30%">
            <Guideline title="Personal Profile">
              <p>
                Your personal profile will be used for verification when you
                make booking. AddPointment will never share your information
                without your permission.
              </p>
            </Guideline>
          </Col>
        </Row>
      </LayoutBody>
      <BusinessRegisterFooter step={3} />
    </AppForm>
  );
};

export default BusinessRegisterProfile;
