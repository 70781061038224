import { useEffect, useState } from "react";
import Table from "../ui/table";
import {
	ApptTime,
	ApptDateCalendar,
	ApptDay,
} from "../appointment/ApptDateTime";
import styles from "./TablePersonalBooking.module.scss";
import { ApptStatus, ApptStatusProps } from "../appointment/ApptStatus";
import { Col, Row } from "../ui/Grid";
import { DropdownSelect } from "../ui/dropdown";
import PersonalBookingActions from "./PersonalBookingActions";
import { IBookingDataType } from "../../config/types/BookingDataType";
import {
	IMeetupDataType,
	isMeetupType,
} from "../../config/types/MeetupDataType";
import useBusinessService from "../services/useBusinessService";
import ApptTypeName from "../appointment/ApptTypeName";
import PersonalMeetupActions from "./PersonalMeetupActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";
import { getMeetupStatus } from "../../utils/booking";
import { CheckBox } from "../form";
import classNames from "classnames";
import EmptyData from "../ui/EmptyData";
import { GrInbox } from "react-icons/gr";
import Tooltips from "../ui/tooltip/Tooltip";

interface TablePersonalBookingProps {
	data: (IMeetupDataType & IBookingDataType)[];
	onApptView(id: string): void;
	type: "upcoming" | "past";
}

const TablePersonalBooking: React.FC<TablePersonalBookingProps> = ({
	data,
	onApptView,
	type,
}) => {
	const [typeList, setTypeList] = useState<number[]>([]);
	const [showAll, setShowAll] = useState(false);
	const [selectedType, setSelectedType] = useState<number | null>(null);
	const { getBookingTypeName } = useBusinessService();
	const { data: userData } = useTypedSelector((state) => state.entities.user);

	useEffect(() => {
		if (data && data.length > 0) {
			const typeArray: number[] = [];
			data.forEach((item) => {
				typeArray.indexOf(item.bookingType) === -1 &&
					typeArray.push(item.bookingType);
			});
			setTypeList(typeArray);
		}
	}, [data]);

	const columns = [
		{
			label: "Date",
			name: "date",
			colWidth: "90px",
			cellClass: styles["col-date"],
			content: (appt: { meetDateTime: Date }) => (
				<ApptDateCalendar date={appt.meetDateTime} />
			),
		},
		{
			label: "Time",
			name: "time",
			colWidth: "120px",
			cellClass: styles["col-time"],
			content: (appt: { meetDateTime: Date }) => (
				<>
					<ApptTime time={appt.meetDateTime} />
					<div className='desc'>
						<ApptDay day={appt.meetDateTime} />
					</div>
				</>
			),
		},
		{
			label: "Name",
			name: "business",
			colWidth: "20%",
			cellClass: styles["col-name"],
			content: (appt: {
				businessData: { name: string };
				locationData: { city: string };
				subject: string;
			}) => (
				<>
					{appt.businessData ? (
						<>
							<div className='h5 text-wrap'>{appt.businessData.name}</div>
							{appt.locationData.city && (
								<span className='desc'>{appt.locationData.city}</span>
							)}
						</>
					) : (
						<div className='h5'>{appt.subject}</div>
					)}
				</>
			),
		},
		{
			// label: "Services",
			name: "services",
			colWidth: "20%",
			cellClass: styles["col-services"],
			content: (appt: IBookingDataType & IMeetupDataType) => (
				<ApptTypeName data={appt} />
			),
		},
		{
			label: "Status",
			name: "status",
			colWidth: "160px",
			cellClass: styles["col-status"],
			content: (appt: {
				bookingStatus: ApptStatusProps["statusNo"];
				userStatus: number;
				meetDate: string;
				meetDateTimestamp: number;
			}) => {
				if (
					appt.bookingStatus < EBookingStatusValue.serving &&
					appt.userStatus
				) {
					return (
						<ApptStatus
							statusNo={appt.userStatus}
							dateTime={appt.meetDate}
							timestamp={appt.meetDateTimestamp}
						/>
					);
				} else {
					return (
						<ApptStatus
							statusNo={appt.bookingStatus}
							dateTime={appt.meetDate}
							timestamp={appt.meetDateTimestamp}
						/>
					);
				}
			},
		},
		{
			name: "actions",
			colWidth: "60px",
			cellClass: styles["col-action"],
			disableItemClick: true,
			content: (appt: IMeetupDataType & IBookingDataType) => {
				if (isMeetupType(appt))
					return (
						<PersonalMeetupActions
							data={appt}
							onApptView={(id: string) => onApptView(id)}
						/>
					);
				else return <PersonalBookingActions data={appt} />;
			},
		},
	];

	const filterData = () => {
		let filtered = data;
		if (!showAll) {
			filtered = [];
			data.forEach((appt) => {
				if (appt.bookingStatus < EBookingStatusValue.cancel) {
					if (appt.userStatus) {
						if (appt.userStatus < EBookingStatusValue.cancel) {
							filtered.push(appt);
						}
					} else filtered.push(appt);
				}
			});
		}
		if (selectedType)
			filtered = filtered.filter((b) => b.bookingType === selectedType);

		return { filtered };
	};

	const menuList = [{ name: "All", onClick: () => setSelectedType(null) }];
	if (typeList.length > 0) {
		// console.log("typeList", typeList);
		typeList.forEach((item) => {
			const name = getBookingTypeName(item);
			menuList.push({ name, onClick: () => setSelectedType(item) });
		});
	}

	const { filtered } = filterData();

	return (
		<>
			<Row className='mb-10'>
				<Col>
					{/* {type === "upcoming" && (
            <div className="pt-5">
              You have <span className="text-semibold">{data.length}</span>{" "}
              upcoming bookings
            </div>
          )} */}
					<div
						className={classNames(styles["show-all"])}
						role='checkbox'
						aria-checked={showAll ? true : false}
						onClick={() => setShowAll(showAll ? false : true)}>
						<CheckBox checked={showAll ? true : false} />
						Show all bookings{" "}
						<Tooltips
							message='Show Cancelled and Rescheduled bookings'
							type='tips'
						/>
					</div>
				</Col>
				<Col width='240px'>
					{typeList.length > 1 && (
						<DropdownSelect
							menuList={menuList}
							labelWidth={200}
							selected={selectedType ? getBookingTypeName(selectedType) : "All"}
							fullWidth
							type='menu'
						/>
					)}
				</Col>
			</Row>
			{filtered.length > 0 ? (
				<Table
					data={filtered}
					columns={columns}
					className={styles["table"]}
					itemClick={(appt: { _id: string }) => onApptView(appt._id)}
				/>
			) : (
				<EmptyData title='No Data' icon={<GrInbox />}>
					<p>You do not have any data at the moment</p>
				</EmptyData>
			)}
		</>
	);
};

export default TablePersonalBooking;
