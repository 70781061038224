import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config/global";

interface ITokenState {
  loading: boolean;
  error?: string;
  valid?: boolean;
  tokenId?: string;
}

const initialState: ITokenState = {
  loading: false,
  error: undefined,
  valid: undefined,
  tokenId: undefined,
};

const slice = createSlice({
  name: "token",
  initialState,
  reducers: {
    tokenClearState: (token) => {
      token.loading = false;
      token.error = undefined;
      token.valid = undefined;
      token.tokenId = undefined;
    },
    tokenCreating: (token) => {
      token.loading = true;
      token.error = undefined;
    },
    tokenCreated: (token, action: PayloadAction<{ tokenId: string }>) => {
      console.log("tokenCreated", action.payload.tokenId);
      token.loading = false;
      token.tokenId = !config.localENV
        ? action.payload.tokenId
        : "61b1cf6d8d9e424fc1524d6b";
    },
    tokenCreateFailed: (token, action: PayloadAction<string>) => {
      token.loading = false;
      token.error = action.payload;
    },
    tokenValidating: (token) => {
      token.loading = true;
      token.error = undefined;
    },
    tokenValidated: (token, action: PayloadAction<boolean>) => {
      token.loading = false;
      token.valid = true;
    },
    tokenValidateFailed: (token, action: PayloadAction<string>) => {
      token.loading = false;
      token.error = action.payload;
    },
  },
});

export const {
  tokenClearState,
  tokenCreating,
  tokenCreated,
  tokenCreateFailed,
  tokenValidating,
  tokenValidated,
  tokenValidateFailed,
} = slice.actions;

export default slice.reducer;
