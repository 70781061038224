import ListItem from "../ui/ListItem";
import { getDate, getTime } from "../../utils/datetime";
import { IMeetupDataType } from "../../config/types/MeetupDataType";

interface PersonalMeetupDetailsProps {
  data: IMeetupDataType;
}

const PersonalMeetupDetails: React.FC<PersonalMeetupDetailsProps> = ({
  data,
}) => {
  return (
    <>
      <h4 className="mb-20">Meet Up Details</h4>
      <ListItem label="Date">{getDate(data.meetDateTime)}</ListItem>
      <ListItem label="Time">{getTime(data.meetDateTime)}</ListItem>
      <ListItem label="Subject">{data.subject}</ListItem>
      <ListItem label="Location">{data.venue}</ListItem>
      <ListItem label="Note">{data.note}</ListItem>
      {/* <p className="desc">
        Created at {getDate(data.createdAt)} {getTime(data.createdAt)}
      </p> */}
    </>
  );
};

export default PersonalMeetupDetails;
