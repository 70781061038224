import { servicePeriodOption } from "../../config/option/optionService";
import { bookingType } from "../../config/option/optionBookingType";
import ServiceResource from "../../components/services/ServiceResource";
import ServiceProvider from "../../components/services/ServiceProvider";
import ServiceFacilities from "../../components/services/ServiceFacilities";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const useBusinessService = () => {
  const { data: categories } = useTypedSelector(
    (state) => state.entities.serviceCategory
  );
  function getServiceHourName(val: number) {
    const servicePeriod = servicePeriodOption.find((x) => x.value === val);
    return servicePeriod ? servicePeriod.name : "Same as business hours";
  }

  function getBookingTypeName(val: number) {
    const typeOption = bookingType.find((x) => x.value === val);
    return typeOption ? typeOption.name : "";
  }

  function getServiceCategory(_id: string) {
    const categoryOption = categories.find((x) => x._id === _id);
    // console.log("category", categoryOption?.name);
    return categoryOption ? categoryOption.name : "";
  }

  // function resourcesPage(bookingTypeNo: number) {
  // 	if (bookingTypeNo === 1) return <ServiceProvider  />;
  // 	else if (bookingTypeNo === 2) return <ServiceFacilities />;
  // 	else return <ServiceResource />;
  // }

  return {
    getServiceHourName,
    getBookingTypeName,
    getServiceCategory,
    // resourcesPage,
  };
};

export default useBusinessService;
