import { ProfilePic } from "../ui/avatar";
import ListItem from "../ui/ListItem";
import { IBookingDataType } from "../../config/types/BookingDataType";
import useBusinessProvider from "../providers/useBusinessProvider";

interface PersonalBookingProviderProps {
  data: IBookingDataType;
}

const PersonalBookingProvider: React.FC<PersonalBookingProviderProps> = ({
  data,
}) => {
  const { getProviderName } = useBusinessProvider();
  if (!data.providerData) return null;

  const { providerData } = data;

  return (
    <>
      <div className="mb-20">
        <ProfilePic
          role="personal"
          name={providerData.name}
          imageSrc={providerData.imageUrl}
        />
      </div>
      {providerData.profession && (
        <ListItem label="Profession">
          {getProviderName(providerData.profession)}
        </ListItem>
      )}
      {providerData.title && (
        <ListItem label="Title">{providerData.title}</ListItem>
      )}
      {providerData.desc && (
        <ListItem label="Description">{providerData.desc}</ListItem>
      )}
    </>
  );
};

export default PersonalBookingProvider;
