import { resourceType } from "../../config/option/optionResources";

const useBusinessResource = () => {
  const getResourceName = (type: number) => {
    // console.log(type);
    const resource = resourceType.find((item) => item.value === type);
    return resource ? resource.name : "Resource";
  };

  return { getResourceName };
};

export default useBusinessResource;
