import { createSlice } from "@reduxjs/toolkit";
import { ServingType } from "../../config/types/ServingType";

interface ServingState {
  loading: boolean;
  error: boolean | string;
  all: ServingType[];
  single: ServingType[];
}

const initialState: ServingState = {
  loading: true,
  error: false,
  all: [],
  single: [],
};

const slice = createSlice({
  name: "serving",
  initialState,
  reducers: {
    servingClearState: (serving) => {
      serving.error = false;
    },
    servingRequest: (serving) => {
      serving.loading = true;
    },
    servingAllReceived: (serving, action) => {
      console.log("servingReceived", action.payload);
      serving.all = action.payload;
      serving.loading = false;
    },
    servingSingleReceived: (serving, action) => {
      console.log("servingReceived", action.payload);
      serving.single = action.payload;
      serving.loading = false;
    },
    servingRequestFailed: (serving, action) => {
      serving.loading = false;
      serving.error = action.payload;
    },
  },
});

export const {
  servingClearState,
  servingRequest,
  servingAllReceived,
  servingSingleReceived,
  servingRequestFailed,
} = slice.actions;

export default slice.reducer;
