import { ICustomerDataType } from "../../config/types/CustomerDataType";
import ListItem from "../ui/ListItem";
import ShowPIN from "../ui/ShowPIN";
import styles from "./CustomerInfo.module.scss";
import prefixId, { idType } from "../../utils/prefixID";

interface CustomerInfoProps {
  data: ICustomerDataType;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ data }) => {
  return (
    <div className={styles["container"]}>
      <h4 className="mb-15">Customer Info</h4>
      {/* <ListItem label="Name">{data.name}</ListItem> */}
      <ListItem label="Customer ID">
        {prefixId(data.customerUid, idType.Customer)}
      </ListItem>
      <ListItem label="Name">{data.displayName}</ListItem>
      {data.pin && (
        <ListItem label="PIN">
          <ShowPIN pin={data.pin} />
        </ListItem>
      )}
    </div>
  );
};

export default CustomerInfo;
