import { useFormikContext } from "formik";
import { BsClock } from "react-icons/bs";

import styles from "./AppTimePicker.module.scss";
import TimePicker from "../ui/timepicker";
import InputContainer from "./InputContainer";
import { convertTimeString } from "../../utils/datetime";

interface TimePickerProps {
  name: string; // use for multiple=false only
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element; // Messsage for input hint
  minuteStep?: number;
  defaultValue?: string;
}

interface FormikContextProps {
  [name: string]: string;
  values: string;
}

const AppTimePicker: React.FC<TimePickerProps> = ({
  name,
  helperMsg,
  label,
  labelBlock,
  labelWidth,
  minuteStep,
  defaultValue,
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();

  const handleChange = (time: string) => {
    setFieldValue(name, time);
  };

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className={styles["time-picker"]}>
        <TimePicker
          onChange={handleChange}
          minuteStep={minuteStep}
          defaultValue={defaultValue}
        >
          <input
            type="text"
            className="input-control"
            readOnly
            autoComplete="off"
            value={values[name] && convertTimeString(values[name])}
          />
        </TimePicker>
        <div className={styles["icon"]}>
          <BsClock />
        </div>
      </div>
    </InputContainer>
  );
};
export default AppTimePicker;
