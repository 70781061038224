import { getLocation } from "./dataLocations";
import { getStaff } from "./dataStaff";

const resources = [
  {
    _id: "resource1",
    name: "Table for 5",
    quantity: 5,
    onlineBooking: {
      enable: true,
      minDay: 5,
      maxDay: 10,
      note: "Don't be late",
    },
    bookingType: 202,
    business: "business2",
    bookingCapacity: 5,
    createdOn: "April 22, 2019",
    // createdOn: "April 22, 2019 at 4:53:54 PM UTC+8",
    desc: "Serving for new only",
    slotInterval: 30,
    label: "D",
    location: getLocation("location2"),
    maxSlotSelection: 2,
    photo: "https://picsum.photos/id/604/200/200",
    services: [],
    servicePeriod:
      '[["Off", "Off"],["11:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    servicePeriodOption: 1,
    staff: [
      {
        id: "staff2",
        displayName: "Jonathan",
        user: "user2",
      },
    ],
    userId: "user2",
    walkIn: true,
    customerRecord: false,
    privateBooking: false,
  },
  {
    _id: "resource2",
    name: "Swimming Pool",
    quantity: 1,
    onlineBooking: {
      enable: true,
      minDay: 1,
      maxDay: 30,
      note: "Don't be late",
    },
    bookingType: 203,
    business: "business1",
    bookingCapacity: 3,
    slotInterval: 15,
    createdOn: "April 22, 2019",
    // createdOn: "April 22, 2019 at 4:53:54 PM UTC+8",
    desc: "Serving for new only",
    label: "E",
    location: getLocation("location3"),
    maxSlotSelection: 3,
    photo: "https://picsum.photos/id/605/200/200",
    services: [],
    servicePeriod:
      '[["Off", "Off"],["11:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    servicePeriodOption: 2,
    staff: [
      {
        id: "staff2",
        displayName: "Jonathan",
        user: "user2",
      },
      {
        id: "staff3",
        displayName: "Ahmad",
        user: "user3",
      },
    ],
    userId: "user1",
    walkIn: true,
    customerRecord: true,
    privateBooking: true,
  },
  {
    _id: "resource3",
    name: "Rutget Meeting Room",
    quantity: 4,

    onlineBooking: {
      enable: true,
      minDay: 1,
      maxDay: 15,
      note: "Don't be late",
    },
    bookingType: 201,
    slotInterval: 5,
    business: "business1",
    bookingCapacity: 10,
    createdOn: "April 22, 2019",
    // createdOn: "April 22, 2019 at 4:53:54 PM UTC+8",
    desc: "Serving for new only",
    duration: 30,
    label: "F",
    location: getLocation(["location1", "location2"]),
    maxSlotSelection: 1,
    photo: "https://picsum.photos/id/605/200/200",
    services: [],
    servicePeriod:
      '[["Off", "Off"],["12:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    servicePeriodOption: 2,
    staff: [
      {
        id: "staff2",
        displayName: "Jonathan",
        user: "user2",
      },
      {
        id: "staff3",
        displayName: "Ahmad",
        user: "user3",
      },
    ],
    userId: "user1",
    walkIn: false,
    customerRecord: true,
    privateBooking: false,
  },
  {
    _id: "resource4",
    name: "Biggest Meeting Room",
    quantity: 4,

    onlineBooking: {
      enable: true,
      minDay: 1,
      maxDay: 15,
      note: "Don't be late",
    },
    bookingType: 201,
    slotInterval: 5,
    business: "business1",
    bookingCapacity: 10,
    createdOn: "April 22, 2019",
    // createdOn: "April 22, 2019 at 4:53:54 PM UTC+8",
    desc: "Serving for new only",
    duration: 30,
    label: "J",
    location: getLocation(["location1", "location2"]),
    maxSlotSelection: 1,
    photo: "https://picsum.photos/id/606/200/200",
    services: [],
    servicePeriod:
      '[["Off", "Off"],["12:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["09:30", "18:30"],["Off", "Off"]]',
    servicePeriodOption: 2,
    staff: [
      {
        id: "staff2",
        displayName: "Jonathan",
        user: "user2",
      },
      {
        id: "staff3",
        displayName: "Ahmad",
        user: "user3",
      },
    ],
    userId: "user1",
    walkIn: false,
    customerRecord: true,
    privateBooking: false,
  },
];

export function getResources() {
  return resources;
}

export function getResource(id) {
  const resource = resources.find((m) => m._id === id);
  return resource ? resource : resources[0];
}

export function getWalkInResources() {
  const resource = resources.filter((m) => m.walkIn === true);
  return resource;
}
