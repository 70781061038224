import { useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import { monthName } from "../../../config/dateTimeFormat";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

type CalendarNavProps = {
  onClick(nav: "prev" | "today" | "next"): void;
  currentMonth: number;
  currentYear: number;
};

const CalendarNav: React.FC<CalendarNavProps> = ({
  currentMonth,
  currentYear,
  onClick,
}) => {
  return (
    <>
      <div className={styles["header"]}>
        <h3>
          {monthName[currentMonth]} {currentYear}
        </h3>
      </div>
      <div className={styles["nav"]}>
        <button onClick={() => onClick("prev")}>
          <FaChevronLeft />
        </button>
        <button onClick={() => onClick("today")}>Today</button>
        <button onClick={() => onClick("next")}>
          <FaChevronRight />
        </button>
      </div>
    </>
  );
};

export default CalendarNav;
