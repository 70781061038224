import { useState, useEffect } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import Header from "../../components/layout/Header";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MenuCreateNew from "../../components/ui/MenuCreateNew";
import Tab from "../../components/ui/tab";
import PersonalSchedule from "../../components/personalBooking/PersonalSchedule";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { IBookingDataType } from "../../config/types/BookingDataType";
import ModalPersonalBooking from "../../components/personalBooking/ModalPersonalBooking";
import useToast from "../../components/ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";

const PersonalSchedulePage: React.FC = () => {
  const { upcoming, past, success, error } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const [activeTab, setActiveTab] = useState(0);
  const [selected, setSelected] = useState<string | null>();
  const { clearPersonalBookingState } = useActions();
  const { notify } = useToast();

  useEffect(() => {
    if (success) {
      console.log("success-----");
      notify("success", success);
      clearPersonalBookingState();
    }
    if (error) {
      notify("error", error);
      clearPersonalBookingState();
    }
  }, [success, error]);

  // const handlePastView = (id: string) => {
  //   if (past && past.data) {
  //     const pastBooking = past.data.find((appt) => appt._id === id);
  //     pastBooking && setSelected(pastBooking);
  //   }
  // };

  // const handleUpcomingView = (id: string) => {
  //   if (upcoming && upcoming.data) {
  //     const upcomingBooking = upcoming.data.find((appt) => appt._id === id);
  //     upcomingBooking && setSelected(upcomingBooking);
  //   }
  // };

  const filterNoOfUpcoming = () => {
    if (upcoming?.data.length > 0) {
      let filtered: (IMeetupDataType & IBookingDataType)[] = [];
      upcoming.data.forEach((appt) => {
        if (appt.bookingStatus < EBookingStatusValue.cancel) {
          if (appt.userStatus && appt.userStatus < EBookingStatusValue.cancel)
            filtered.push(appt);
          else filtered.push(appt);
        }
      });
      return filtered.length;
    }
    return 0;
  };

  const tabItems = [
    {
      tabID: 0,
      name: "Upcoming",
      number: filterNoOfUpcoming(),
      content: (
        <PersonalSchedule
          onApptView={(id) => setSelected(id)}
          type="upcoming"
        />
      ),
    },
    {
      tabID: 1,
      name: "Past",
      content: (
        <PersonalSchedule onApptView={(id) => setSelected(id)} type="past" />
      ),
    },
  ];

  return (
    <PageWithAside role="personal">
      <div className="wrapper">
        <Header
          pageTitle="Schedule"
          pageDesc="List of personal or businesses that have been added to your bookmark"
          role="personal"
        />
        <Tab
          type="horizontal"
          items={tabItems}
          onTabClick={(id: number) => setActiveTab(id)}
          active={activeTab}
        >
          {tabItems[activeTab]["content"]}
        </Tab>
        {selected && (
          <ModalPersonalBooking
            onClose={() => setSelected(null)}
            // data={selected}
            id={selected}
          />
        )}
        <MenuCreateNew role="personal" />
      </div>
    </PageWithAside>
  );
};

export default PersonalSchedulePage;
