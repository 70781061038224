const notes = [
  {
    _id: "note1",
    subject: "New Subject1",
    message:
      "Explaination for subject one bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
    author: "Staff 1",
    entityId: "customer1",
    businessId: "business1",
    userId: "user1",
    createdBy: "user1",
    created: "2020-02-21T10:00:00",
  },
  {
    _id: "note2",
    subject: "New Subject2",
    message:
      "Explaination for subject two bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
    author: "Staff 2",
    entityId: "customer1",
    businessId: "business1",
    userId: "user1",
    createdBy: "user1",
    created: "2020-07-11T10:00:00",
  },
  {
    _id: "note3",
    subject: "New Subject3",
    message:
      "Explaination for subject three bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
    author: "Staff 3",
    entityId: "customer1",
    businessId: "business1",
    userId: "user1",
    createdBy: "user2",
    created: "2020-02-11T11:00:00",
  },
  {
    _id: "note4",
    subject: "New Subject4",
    message:
      "Explaination for subject four bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
    author: "Staff 4",
    entityId: "customer1",
    businessId: "business1",
    userId: "user1",
    createdBy: "user1",
    created: "2020-12-21T10:00:00",
  },
];

export function getNotes() {
  return notes;
  // return [];
}

export function getNote(id) {
  return Array.isArray(id)
    ? notes.filter((i) => id.includes(i._id))
    : notes.filter((m) => m._id === id);
  // return location.find((m) => m.id === id);
}
