import * as yup from "yup";

export const InitialValues = {
  inputCode: "",
};

export const Validation = yup.object().shape({
  inputCode: yup
    .number()
    .test(
      "maxDigits",
      "PIN number must be 4 digit",
      (number) => String(number).length === 4
    )
    .required("Please enter your PIN number"),
});

export interface IRegisterPinInputType {
  inputCode: string;
}
