import { useNavigate } from "react-router";
import { AppButton } from "../../components/form";
import TableServices from "../../components/services/TableServices";
import { businessURL } from "../../config/url";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import EmptyData from "../ui/EmptyData";
import iconService from "../../assets/images/service.svg";
import { EBtnCategory } from "../form/AppButton";
import { useEffect, useState } from "react";
import ModalServicesCategory from "./ModalServicesCategory";
import { useActions } from "../../hooks/useActions";
import { Row, Col } from "../ui/Grid";
import FilterServices from "./FilterServices";
import { IServiceDataType } from "../../config/types/ServiceDataType";

const BusinessServices: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useTypedSelector((state) => state.entities.serviceAdmin);

  const [selectedType, setSelectedType] = useState<string>();
  const { getAdminServicesCategory } = useActions();

  useEffect(() => {
    getAdminServicesCategory();
  }, []);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const getPageData = () => {
    let filtered: IServiceDataType[] = data;
    if (selectedType)
      filtered = data.filter((i) => i.category === selectedType);
    return { filtered };
  };

  const { filtered } = getPageData();

  if (!data || data.length === 0)
    return (
      <EmptyData title="Manage your services" size="large" image={iconService}>
        <p>This is where you can manage your services to accept booking.</p>
        <button
          className="btn btn-primary-outline mt-10"
          type="button"
          onClick={() =>
            navigate(businessURL.BusinessServiceNew, {
              state: { step: 0, bookingType: 102 },
            })
          }
        >
          Add Service
        </button>
      </EmptyData>
    );

  return (
    <>
      <Row className="mb-10">
        <Col width="300px">
          <AppButton
            type="button"
            category={EBtnCategory.PrimaryOutline}
            className="mb-30"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Categories
          </AppButton>
        </Col>

        {modalVisible && (
          <ModalServicesCategory onClose={() => setModalVisible(false)} />
        )}

        <Col>
          <FilterServices onClick={(id) => setSelectedType(id)} />
        </Col>
      </Row>

      <TableServices data={filtered} />

      <AppButton
        type="button"
        category={EBtnCategory.PrimaryOutline}
        className="mt-30"
        onClick={() =>
          // navigate(businessURL.BusinessServiceNew, {
          //   state: { step: 0, bookingType: 102 },
          // })
          navigate(businessURL.BusinessServiceNew)
        }
      >
        Add New Service
      </AppButton>
    </>
  );
};

export default BusinessServices;
