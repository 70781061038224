import { Route } from "react-router-dom";
import { businessSetupURL } from "../config/url";
import BusinessSetupPage from "../pages/setup/BusinessSetupPage";
import BusinessSetupContactPage from "../pages/setup/BusinessSetupContactPage";
import BusinessSetupHoursPage from "../pages/setup/BusinessSetupHoursPage";
import PersonalRouter from "./PersonalRouter";

const businessSetupRoutes = () => {
  return (
    <>
      <Route path={businessSetupURL.Info} element={<PersonalRouter />}>
        <Route index element={<BusinessSetupPage />} />
        <Route
          path={businessSetupURL.Hours}
          element={<BusinessSetupHoursPage />}
        />
        <Route
          path={businessSetupURL.Contact}
          element={<BusinessSetupContactPage />}
        />
      </Route>
    </>
  );
};

export default businessSetupRoutes;
