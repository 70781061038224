import { Navigate, Route } from "react-router-dom";
import { businessURL } from "../config/url";
import BusinessCustomerPage from "../pages/business/BusinessCustomerPage";
import BusinessServicesPage from "../pages/business/BusinessServicesPage";
import BusinessResourcesPage from "../pages/business/BusinessResourcesPage";
import BusinessTodayPage from "../pages/business/BusinessTodayPage";
import BusinessSchedulePage from "../pages/business/BusinessSchedulePage";
import BusinessWaitingListPage from "../pages/business/BusinessWaitingListPage";
import BusinessDashboardPage from "../pages/business/BusinessDashboardPage";
import CreateResourcePage from "../pages/businessResource/CreateResourcePage";
// Business Tools
import BusinessNowServingPage from "../pages/businessTools/BusinessNowServingPage";
import BusinessMeetingPointPage from "../pages/businessTools/BusinessMeetingPointPage";
import BusinessWalkInPage from "../pages/businessTools/BusinessWalkInPage";
import BusinessOnlineBookingPage from "../pages/businessTools/BusinessOnlineBookingPage";
import BusinessWalkInRegPage from "../pages/businessTools/BusinessWalkInRegPage";
import BusinessServingSinglePage from "../pages/businessTools/BusinessServingSinglePage";
import BusinessServingAllPage from "../pages/businessTools/BusinessServingAllPage";
import CreateServicePage from "../pages/businessService/CreateServicePage";
import BusinessRouter from "./BusinessRouter";
import BusinessProvidersPage from "../pages/business/BusinessProvidersPage";

const businessRoutes = () => {
  return (
    <>
      <Route path={businessURL.Business} element={<BusinessRouter />}>
        <Route
          path={businessURL.BusinessOnlineBooking}
          element={<BusinessOnlineBookingPage />}
        />
        <Route
          path={businessURL.BusinessMeetingPoint}
          element={<BusinessMeetingPointPage />}
        />
        <Route
          path={businessURL.BusinessWalkInRegistration}
          element={<BusinessWalkInRegPage />}
        />
        <Route
          path={businessURL.BusinessWalkIn}
          element={<BusinessWalkInPage />}
        />
        <Route
          path={`${businessURL.BusinessNowServingAll}/:type`}
          element={<BusinessServingAllPage />}
        />
        <Route
          path={businessURL.BusinessNowServingAll}
          element={<BusinessServingAllPage />}
        />
        <Route
          path={`${businessURL.BusinessNowServingIndividual}/:type/:id`}
          element={<BusinessServingSinglePage />}
        />
        <Route
          path={businessURL.BusinessNowServing}
          element={<BusinessNowServingPage />}
        />

        <Route
          path={businessURL.BusinessServiceNew}
          element={<CreateServicePage />}
        />
        <Route
          path={businessURL.BusinessServices}
          element={<BusinessServicesPage />}
        />
        <Route
          path={businessURL.BusinessProviders}
          element={<BusinessProvidersPage />}
        />
        <Route
          path={businessURL.BusinessClientele}
          element={<BusinessCustomerPage />}
        />
        <Route
          path={businessURL.BusinessSchedule}
          element={<BusinessSchedulePage />}
        />
        <Route
          path={businessURL.BusinessWaitingList}
          element={<BusinessWaitingListPage />}
        />
        <Route
          path={businessURL.BusinessToday}
          element={<BusinessTodayPage />}
        />
        <Route
          path={businessURL.BusinessDashboard}
          element={<BusinessDashboardPage />}
        />
        <Route
          index
          element={<Navigate to={businessURL.BusinessDashboard} replace />}
        />
      </Route>
    </>
  );
};

export default businessRoutes;
