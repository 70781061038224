import React from "react";
import styles from "./Modal.module.scss";

interface ModalBodyProps {
  padding?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, padding }) => {
  return (
    <div
      className={styles["body"]}
      // style={{ padding: padding ? padding : "2rem" }}
    >
      {children}
    </div>
  );
};
