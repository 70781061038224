import { useEffect } from "react";
import { AppButton, AppForm } from "../form";
import { ModalBox, ModalHeader, ModalBody, ModalFooter } from "../ui/modal";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessContact";
import BusinessContactForm from "./BusinessContactForm";
import styles from "./ModalLocation.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

interface ModalProps {
  data: { _id: string | null };
  onClose(): void;
}

const ModalLocation: React.FC<ModalProps> = ({ data, onClose }) => {
  const title = data._id ? "Edit Location" : "New Location";
  const buttonName = data._id ? "Save" : "Create";
  const { addLocation, updateBusinessContact } = useActions();
  const { success } = useTypedSelector((state) => state.entities.location);

  useEffect(() => {
    success && onClose();
  }, [success]);

  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      // size="small"
    >
      <ModalHeader title={title} />
      <AppForm
        className="flex-layout"
        initialValues={data._id ? data : InitialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          console.log("values", values);
          data._id
            ? updateBusinessContact(values, values._id)
            : addLocation(values);
        }}
        validationSchema={Validation}
      >
        <ModalBody>
          <div className={styles["wrapper"]}>
            {!data._id && (
              <>
                <h4>Add Location for booking</h4>
                <p className="desc mb-20">
                  You can add business location to your services or resources.
                  So that user can select their preferred location when making a
                  booking
                </p>
              </>
            )}
            <BusinessContactForm isEdit />
            <div className={styles["button"]}></div>
          </div>
        </ModalBody>
        <ModalFooter>
          <AppButton
            type="button"
            className="btn btn-link mr-10"
            onClick={onClose}
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            {buttonName}
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalLocation;
