import { useState, useEffect } from "react";
import classNames from "classnames";
import ListTable, { ListTableDataType } from "../ui/ListTable";
import styles from "./BusinessStaffList.module.scss";
import StaffRemoveDialog from "./StaffRemoveDialog";
import { useActions } from "../../hooks/useActions";
// import { StaffType } from "../../config/types/StaffType";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface BusinessStaffListProps {}

const BusinessStaffList: React.FC<BusinessStaffListProps> = () => {
  const [dialog, setDialog] = useState<string | null>(null);
  const [list, setList] = useState<ListTableDataType[]>([]);

  const { updateStaffRole } = useActions();
  const { data, invitation } = useTypedSelector(
    (state) => state.entities.staff
  );

  const roles = (id: string, role: number, userRole: number) => {
    return (
      <button
        type="button"
        className={classNames("btn", role === userRole && styles["selected"])}
        onClick={() => role !== userRole && updateStaffRole(role, id)}
      >
        {role === 3 ? "Staff" : "Admin"}
      </button>
    );
  };

  useEffect(() => {
    const users: ListTableDataType[] = [];
    if (data && data.length > 0) {
      data.forEach((el) => {
        users.push({
          id: el._id,
          content: (
            <div className={styles["content"]}>
              <div className={styles["name"]}>
                <div className="h5">{el.user.displayName}</div>
                <div className="desc">{el.user.email}</div>
              </div>
              <div className={styles["actions"]}>
                {el.role === 1 || !el.role ? (
                  el.role === 1 ? (
                    <i className="desc">Owner</i>
                  ) : (
                    <i className="desc">Pending confirmation</i>
                  )
                ) : (
                  <>
                    {roles(el._id, 3, el.role)}
                    {roles(el._id, 2, el.role)}
                  </>
                )}
              </div>
            </div>
          ),
          notDelete: el.role === 1 || !el.role ? true : false,
        });
      });
    }
    if (invitation && invitation.length > 0) {
      invitation.forEach((el) => {
        users.push({
          id: el._id,
          content: (
            <div className={styles["content"]}>
              <div className={styles["name"]}>{el.userToEmail}</div>
              <div className={styles["actions"]}>
                <i className="desc">Pending</i>
              </div>
            </div>
          ),
          notDelete: true,
        });
      });
    }
    users.length > 0 && setList(users);
  }, [data, invitation]); // eslint-disable-line react-hooks/exhaustive-deps

  if (list.length === 0) return null;

  return (
    <>
      <ListTable
        title="List of Staff Member"
        data={list}
        onDelete={(id) => setDialog(id)}
      />
      {dialog && (
        <StaffRemoveDialog setVisible={(arg) => setDialog(arg)} id={dialog} />
      )}
    </>
  );
};

export default BusinessStaffList;
