import React, { useState, useEffect, useRef } from "react";
import styles from "./Message.module.scss";
import { BsChatFill } from "react-icons/bs";
import MessageList from "./MessageList";
import { useActions } from "../../../hooks/useActions";
import ModalPersonalBooking from "../../personalBooking/ModalPersonalBooking";
import { INotificationDataType } from "../../../config/types/NotificationDataType";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import config from "../../../config/global";

interface IMessage {
  role: "personal" | "business";
}

const Message: React.FC<IMessage> = ({ role }) => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const btnRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  // const { getNotifications, openNotifications, readNotification } =
  //   useActions();
  const {
    loading,
    data: notifications,
    unopened,
  } = useTypedSelector((state) => state.entities.notification);

  useEffect(() => {
    // role === "personal" && getNotifications();
  }, [role]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        (wrapperRef.current &&
          wrapperRef.current.contains(event.target as Node)) ||
        (btnRef.current && btnRef.current.contains(event.target as Node))
      ) {
        return;
      }
      setShow(false);
    };
    document.addEventListener("click", listener, { capture: true });
    return () => {
      document.removeEventListener("click", listener, { capture: true });
    };
  }, []);

  const handleToggle = () => {
    setShow(show ? false : true);
    // unopened > 0 && openNotifications();
  };

  const handleClick = (notification: INotificationDataType) => {
    // readNotification(notification._id);
    if (notification.businessUrl)
      window.open(`${config.bookingUrl}${notification.businessUrl}`);
    else setId(notification.entityId);
    setShow(false);
  };

  if (role === "personal")
    return (
      <>
        <div
          className={styles["container"]}
          role="button"
          onClick={() => handleToggle()}
          ref={btnRef}
        >
          <div className={styles["btn"]}>
            <BsChatFill />
            {unopened > 0 && <div className={styles["badge"]}>{unopened}</div>}
          </div>
        </div>
        {show && (
          <div className={styles["list"]} ref={wrapperRef}>
            <MessageList onClick={handleClick} />
          </div>
        )}
        {id && <ModalPersonalBooking id={id} onClose={() => setId("")} />}
      </>
    );
  return null;
};

export default Message;
