import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { AppButton, AppPhoneInput, AppForm } from "../../components/form";
// import useToast from "../../components/ui/toasts/useToast";
// import PersonalChangeEmailVerify from "../../components/personal/PersonalChangeEmailVerify";
import { personalURL } from "../../config/url";
import { InitialValues, Validation } from "../../config/form/formChangePhone";
import { EBtnCategory } from "../../components/form/AppButton";
// import { useTypedSelector } from "../../hooks/useTypedSelector";
// import { useActions } from "../../hooks/useActions";

const PersonalChangePhonePage: React.FC = () => {
  const navigate = useNavigate();
  // const { notify } = useToast();
  // const { sendUserPIN, clearUserPINState } = useActions();
  // const [newPhone, setNewPhone] = useState<string | undefined>();
  const [step, setStep] = useState(1);
  // const { PINSend, PINVerify } = useTypedSelector(
  //   (state) => state.entities.user
  // );

  // useEffect(() => {
  //   if (PINSend) {
  //     if (PINSend.success) {
  //       setStep(2);
  //       clearUserPINState();
  //     } else if (PINSend.error) {
  //       notify("error", PINSend.error);
  //     }
  //   }
  //   if (PINVerify) {
  //     if (PINVerify.success) {
  //       notify("success", "Your phone number has been updated successful.");
  //       clearUserPINState();
  //       navigate(personalURL.PersonalProfile);
  //     } else if (PINVerify.error) {
  //       notify("error", PINVerify.error);
  //     }
  //   }
  // }, [PINSend, PINVerify]);

  const msg =
    step === 1 ? (
      <>Please enter your new phone number.</>
    ) : (
      <>Please enter the code send to your phone.</>
    );

  return (
    <LayoutCenterBox close>
      <h1 className="text-center">Change Phone Number </h1>
      <p className="mb-20 text-center">{msg}</p>
      <AppForm
        validationSchema={Validation}
        initialValues={InitialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          // sendUserPIN(values.phone, "phone");
          // setNewPhone(values.phone);
        }}
      >
        <AppPhoneInput name="phone" disabled={step === 2 && true} />
        {step === 1 && (
          <>
            <AppButton
              className="float-left btn-link pl-0"
              type="button"
              onClick={() => navigate(personalURL.PersonalProfile)}
            >
              Cancel
            </AppButton>
            <AppButton
              className="float-right"
              type="submit"
              category={EBtnCategory.Primary}
            >
              Next
            </AppButton>
          </>
        )}
      </AppForm>
      {/* {step === 2 && newPhone && (
        <PersonalChangeEmailVerify
          data={newPhone}
          type="phone"
          setStep={setStep}
        />
      )} */}
    </LayoutCenterBox>
  );
};

export default PersonalChangePhonePage;
