import { useState } from "react";
import styles from "./ReadMore.module.scss";

interface ReadMoreProps {
  charLimit: number;
  children: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ children, charLimit }) => {
  const [readMore, setReadMore] = useState(false);

  if (children.length <= charLimit + 5) return <>{children}</>;

  const shortString =
    children.substring(0, charLimit).replace(/<[^>]*>?/gm, "") + "...";
  const btnLabel = readMore ? "Read Less" : "Read More";
  return (
    <>
      {readMore ? children : shortString}{" "}
      <button
        className={styles["btn"]}
        onClick={() => setReadMore(readMore ? false : true)}
      >
        {btnLabel}
      </button>
    </>
  );
};

export default ReadMore;
