import { useState } from "react";
import { AppButton } from "../form";
import { LayoutFooter } from "../layout/LayoutFullHeight";
import BusinessRegisterCancelDialog from "./BusinessRegisterCancelDialog";
import { EBtnCategory } from "../form/AppButton";

interface BusinessSetupFooterProps {
  step: number;
}
const BusinessRegisterFooter: React.FC<BusinessSetupFooterProps> = ({
  step,
}) => {
  const [dialog, setDialog] = useState<boolean>(false);
  return (
    <>
      <LayoutFooter>
        <div className="float-right">
          <AppButton
            type="button"
            className="btn-link mr-20"
            onClick={() => setDialog(true)}
          >
            Cancel
          </AppButton>
          <AppButton category={EBtnCategory.Primary} type="submit">
            Continue (step {step} of 6)
          </AppButton>
        </div>
      </LayoutFooter>
      {dialog && (
        <BusinessRegisterCancelDialog
          setVisible={(arg) => setDialog(arg)}
          step={step}
        />
      )}
    </>
  );
};

export default BusinessRegisterFooter;
