import { EApptType, IApptDataType } from "./ApptDataType";
import { IBookingDataType } from "./BookingDataType";

export interface IParticipantData {
  email: string;
  displayName?: string;
  profilePic?: string;
}

export interface IMeetupDataType extends IApptDataType {
  _id: string;
  // participants: IParticipantData[];
  // attendees: string[];
  // // participants: string[];
  // // participantsData: IParticipantData[];
  // subject: string;
  // venue: string;
  userStatus: number;
  subject: string;
  venue: string;
  isHost?: boolean;
  user: string;
  userData: IParticipantData;
  host: string;
}

export function isMeetupType(
  object: IMeetupDataType | IBookingDataType
): object is IMeetupDataType {
  return object.apptType && object.apptType === EApptType.MEETUP ? true : false;
}
