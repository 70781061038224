import ListItem from "../ui/ListItem";
import ShowPIN from "../ui/ShowPIN";
import { IBookingDataType } from "../../config/types/BookingDataType";
import ReadMore from "../ui/ReadMore";
import prefixId, { idType } from "../../utils/prefixID";

interface InfoProps {
  data: IBookingDataType;
}

const BookingCustomerInfo: React.FC<InfoProps> = ({ data }) => {
  return (
    <>
      {data.customerUid && (
        <ListItem label="Customer ID">
          {prefixId(data.customerUid, idType.Customer)}
        </ListItem>
      )}
      <ListItem label="Name">{data.customerData.displayName}</ListItem>
      {data.customerData.pin && (
        <ListItem label="PIN">
          <ShowPIN pin={data.customerData.pin} />
        </ListItem>
      )}
      {data.note && (
        <>
          <div className="desc">Note</div>
          <ReadMore charLimit={100}>{data.note}</ReadMore>
        </>
      )}
    </>
  );
};

export default BookingCustomerInfo;
