import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";
import styles from "./ServiceAdvanced.module.scss";

import { AppSwitch } from "../form";

interface ServiceAdvancedProps {
  // id: string;
  // data?: ListTableType[];
  // category: ListTableCategoryType;
}

const ServiceAdvanced: React.FC<ServiceAdvancedProps> = (
  {
    // id,
    // data,
    // category,
  }
) => {
  return (
    <div className={styles["wrapper"]}>
      <h3 className="mb-20">Advanced</h3>
      <h4 className="mb-10">Privacy</h4>
      <Row className="mb-15 pb-15">
        <Col>
          <div className="h5 mb-3">Auto delete booking record</div>
          <div className="desc">
            All booking record will keep for 6 months. You can choose to auto
            delete the record 6 month after the meetup day
          </div>
        </Col>
        <Col width="50px">
          <AppSwitch name="autoDelete" />
        </Col>
      </Row>
      {/* <Row className="mb-30">
        <Col>
          <div className="h5 mb-3">
            Request customer's name for each booking
          </div>
          <div className="desc">
            To protect customer privacy, an unique customer ID will be generated
            for each customer
          </div>
        </Col>
        <Col width="50px">
          <AppSwitch name="requestCustomerName" />
        </Col>
      </Row> */}
    </div>
  );
};

export default ServiceAdvanced;
