import { createSelector } from "reselect";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";

// TODO: filter today booking
export const getTodayCompletedBusinessBooking = createSelector(
  (state: { entities: { businessBooking: { today: IBookingDataType[] } } }) =>
    state.entities.businessBooking.today,
  // (upcoming) => upcoming.data.filter((item: IBookingDataType) => item.serviceType === 1)
  (today) =>
    today.filter((i) => i.bookingStatus >= EBookingStatusValue.complete)
);

export const getTodayIncompleteBusinessBooking = createSelector(
  (state: { entities: { businessBooking: { today: IBookingDataType[] } } }) =>
    state.entities.businessBooking.today,
  // (upcoming) => upcoming.data.filter((item: IBookingDataType) => item.serviceType === 1)
  (today) => today.filter((i) => i.bookingStatus < EBookingStatusValue.complete)
);

export const getTodayServingBusinessBooking = createSelector(
  (state: { entities: { businessBooking: { today: IBookingDataType[] } } }) =>
    state.entities.businessBooking.today,
  // (upcoming) => upcoming.data.filter((item: IBookingDataType) => item.serviceType === 1)
  (today) =>
    today.filter((i) => i.bookingStatus === EBookingStatusValue.serving)
);
// export const getTodayBooking = (state: IBookingDataType) => {
//   const items = state;
//   console.log("items", items);
//   // return items.filter(i => i.isOnSale)
// };

// const upcomingBooking = (state) => state.data
