const useCalendarCountDay = (
  currentMonth: number,
  currentYear: number,
  minDate?: Date,
  maxDate?: Date,
  selectedDate?: Date
) => {
  const firstDayOfMonth: number = new Date(
    currentYear,
    currentMonth,
    1
  ).getDay();

  const lastDayOfMonth: number = new Date(
    currentYear,
    currentMonth + 1,
    0
  ).getDay();

  const lastDayOfPrevMonth: number = new Date(
    currentYear,
    currentMonth,
    0
  ).getDate();

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const todayMonth = new Date().getMonth();
  const todayYear = new Date().getFullYear();
  const today =
    currentMonth === todayMonth && currentYear === todayYear
      ? new Date().getDate()
      : null;

  //   console.log("date", date);
  console.log("currentMonth", currentMonth);
  const minDay =
    minDate &&
    minDate.getMonth() === currentMonth &&
    minDate.getFullYear() === currentYear
      ? minDate.getDate()
      : null;

  const maxDay =
    maxDate &&
    maxDate.getMonth() === currentMonth &&
    maxDate.getFullYear() === currentYear
      ? maxDate.getDate()
      : null;
  console.log("minDay", minDay);
  console.log("maxDay", maxDay);

  const selectedDay =
    selectedDate &&
    selectedDate.getMonth() === currentMonth &&
    selectedDate.getFullYear() === currentYear
      ? selectedDate.getDate()
      : null;
  console.log("selectedDay", selectedDay);

  return {
    firstDayOfMonth,
    lastDayOfMonth,
    lastDayOfPrevMonth,
    daysInMonth,
    today,
    // currentMonth,
    minDay,
    maxDay,
    selectedDay,
  };
};

export default useCalendarCountDay;
