import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { personalURL } from "../../config/url";
import {
  InitialValues,
  Validation,
  IChangeEmailInputType,
} from "../../config/form/formChangeEmail";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AppButton, AppInput, AppForm } from "../../components/form";
import { EBtnCategory } from "../../components/form/AppButton";

interface IPersonalChangeEmailFormProps {
  onChangeEmail(value: IChangeEmailInputType): void;
  setError(msg: string): void;
}

const PersonalChangeEmailForm: React.FC<IPersonalChangeEmailFormProps> = ({
  onChangeEmail,
  setError,
}) => {
  const navigate = useNavigate();
  const [initVal, setSetInitVal] = useState(InitialValues);
  const { data: userData, loading: userLoading } = useTypedSelector(
    (state) => state.entities.user
  );

  useEffect(() => {
    userData && setSetInitVal({ ...initVal, email: userData.email });
  }, [userData]);
  return (
    <AppForm
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        console.log(values);
        if (userData && userData.email === values.newEmail) {
          setError("Please enter a another email");
        } else {
          actions.setSubmitting(false);
          onChangeEmail(values);
        }
      }}
    >
      <div className="separator mb-20">
        <AppInput name="newEmail" label="New Email" type="email" />
      </div>
      <AppInput
        name="password"
        label="Password"
        type="password"
        helperMsg="Enter your account password for verification"
      />
      <AppButton
        className="float-left btn btn-empty pl-0"
        type="button"
        onClick={() => navigate(personalURL.PersonalProfile)}
      >
        Cancel
      </AppButton>
      <AppButton
        className="float-right"
        type="submit"
        category={EBtnCategory.Primary}
        disabled={userLoading}
      >
        Send Verification Email
      </AppButton>
    </AppForm>
  );
};

export default PersonalChangeEmailForm;
