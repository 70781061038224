import React, { useEffect } from "react";
import { ModalBody, ModalBox } from "../ui/modal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
// import useToast from "../ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";
import Loader from "../ui/Loader";
import PersonalMeetupContent from "./PersonalMeetupContent";
import { isMeetupType } from "../../config/types/MeetupDataType";
import PersonalBookingContent from "./PersonalBookingContent";

interface ModalPersonalBookingProps {
  onClose(): void;
  id: string;
}

const ModalPersonalBooking: React.FC<ModalPersonalBookingProps> = ({
  onClose,
  id,
}) => {
  const { singleData } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  const { getBookingById } = useActions();

  useEffect(() => {
    getBookingById(id);
  }, [id]);

  return (
    <ModalBox onClose={onClose} type="personal" overlayClose={true}>
      {!singleData ? (
        <ModalBody>
          <Loader alignCenter height="200px" />
        </ModalBody>
      ) : isMeetupType(singleData) ? (
        <PersonalMeetupContent data={singleData} onClose={onClose} />
      ) : (
        <PersonalBookingContent data={singleData} onClose={onClose} />
      )}
    </ModalBox>
  );
};

export default ModalPersonalBooking;
