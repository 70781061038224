import { createSlice } from "@reduxjs/toolkit";
import { IInvitationDataType } from "../../config/types/InvitationDataType";

interface invitationState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: number;
  success: boolean;
  data: IInvitationDataType[];
  update?: {
    loading?: boolean;
    error?: string;
    accepted?: boolean;
    rejected?: boolean;
  };
  single?: {
    loading?: boolean;
    data?: IInvitationDataType;
    error?: string;
  };
}

const initialState: invitationState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  success: false,
  data: [],
  update: undefined,
  single: undefined,
};

const slice = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    invitationClearState: (invitation) => {
      invitation.success = false;
      invitation.error = false;
      invitation.update = undefined;
    },
    invitationRequest: (invitation) => {
      invitation.loading = true;
    },
    invitationReceived: (invitation, action) => {
      console.log("invitationReceived", action.payload);
      invitation.lastFetch = Date.now();
      invitation.data = action.payload;
      invitation.success = true;
      invitation.loading = false;
    },
    invitationRequestFailed: (invitation, action) => {
      invitation.loading = false;
      invitation.error = action.payload;
    },
    invitationUpdating: (invitation) => {
      invitation.update = { loading: true };
    },
    invitationAccepted: (invitation, action) => {
      // TODO: accept
      invitation.update = { accepted: true };
      invitation.data = invitation.data.filter((i) => i._id !== action.payload);
    },
    invitationRejected: (invitation) => {
      // TODO: reject
      invitation.update = { rejected: true };
    },
    // invitationUpdated: (invitation, action) => {
    //   const index = invitation.data.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   invitation.update = { success: action.payload };
    // },
    invitationUpdateFailed: (invitation, action) => {
      invitation.update = { error: action.payload };
    },

    singleRequest: (invitation) => {
      invitation.single = { loading: true };
    },
    singleReceived: (invitation, action) => {
      console.log("singleReceived", action.payload);
      invitation.single = { data: action.payload };
    },
    singleRequestFailed: (invitation, action) => {
      console.log("singleRequestFailed");
      invitation.single = { error: action.payload };
    },
    // invitationRemoving: (invitation) => {
    //   invitation.remove = { loading: true };
    // },
    // invitationRemoved: (invitation, action) => {
    //   invitation.data = invitation.data.filter(
    //     (item) => item.id !== action.payload
    //   );
    //   invitation.remove = { success: true };
    // },
    // invitationRemoveFailed: (invitation, action) => {
    //   invitation.remove = { error: action.payload };
    // },
    // invitationAdding: (invitation) => {
    //   invitation.add = { loading: true };
    // },
    // invitationAdded: (invitation, action) => {
    //   invitation.data = [...invitation.data, action.payload];
    //   invitation.add = { success: true };
    // },
    // invitationAddFailed: (invitation, action) => {
    //   invitation.add = { error: action.payload };
    // },
  },
});

export const {
  invitationClearState,
  invitationRequest,
  invitationReceived,
  invitationRequestFailed,
  invitationUpdating,
  //   invitationUpdated,
  invitationUpdateFailed,
  invitationAccepted,
  invitationRejected,
  //   invitationRemoving,
  //   invitationRemoved,
  //   invitationRemoveFailed,
  //   invitationAdding,
  //   invitationAdded,
  //   invitationAddFailed,
  singleRequest,
  singleReceived,
  singleRequestFailed,
} = slice.actions;

export default slice.reducer;
