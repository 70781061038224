import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithAside from "../../components/layout/PageWithAside";
import { AppForm } from "../../components/form";
import ServiceInfo from "../../components/services/ServiceInfo";
import {
  IServiceInputType,
  InitialValues,
  Validation,
} from "../../config/form/formService";
import useBusinessServicePage from "./useBusinessServicePage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import useServiceCategory from "../../components/services/useServiceCategory";

const ServiceInfoPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const [initVal, setInitVal] = useState<IServiceInputType>(InitialValues);
  const { request, data } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const { newCategory } = useTypedSelector(
    (state) => state.entities.serviceCategory
  );

  const { updateAdminService, getAdminServicesCategory } = useActions();
  const { service, submenu, header, footer } = useBusinessServicePage(id);

  useEffect(() => {
    getAdminServicesCategory();
  }, []);

  useEffect(() => {
    if (service) {
      setInitVal({
        ...initVal,
        ...service,
      });
    }
  }, [service]);

  useEffect(() => {
    if (newCategory) {
      setInitVal({
        ...initVal,
        category: newCategory,
      });
    }
  }, [newCategory]);

  const { getCategoryName } = useServiceCategory();

  return (
    <PageWithAside
      role="business"
      submenuName="Services"
      submenuList={submenu}
      noPadding
      goUpLevel={businessURL.BusinessServices}
    >
      {header}
      <div className="wrapper">
        {request?.loading && <Loader />}
        {request?.error && <div className="error-msg">{request.error}</div>}
        {!request && data && service && (
          <AppForm
            initialValues={initVal}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              console.log(values);
              const categoryName = getCategoryName(values.category);
              values = { ...values, categoryName: `${categoryName}` };
              updateAdminService(values);
            }}
            validationSchema={Validation}
          >
            <ServiceInfo service={service} />
            {footer}
          </AppForm>
        )}
      </div>
    </PageWithAside>
  );
};

export default ServiceInfoPage;
