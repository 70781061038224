import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBookingDataType } from "../../config/types/BookingDataType";
import {
  CustomerNoteType,
  ICustomerDataType,
  TagType,
} from "../../config/types/CustomerDataType";

interface CustomerState {
  loading: boolean;
  request?: { loading?: boolean; error?: string }; // getting customer data
  error?: string;
  success?: string;
  lastFetch?: Date | number;
  data: [] | ICustomerDataType[];
  record: [] | IBookingDataType[];
  notes?: {
    loading?: boolean;
    data?: CustomerNoteType[];
    error?: string;
  };
  allTags?: {
    loading?: boolean;
    data?: TagType[];
    error?: string;
  };
  tagUpdate?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  noteUpdate?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
}

const initialState: CustomerState = {
  loading: false,
  request: { loading: true },
  error: undefined,
  success: undefined,
  lastFetch: undefined,
  data: [],
  record: [],
  notes: undefined,
  allTags: undefined,
  tagUpdate: undefined,
  // add: undefined,
  // remove: undefined,
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    customerClearState: (customer) => {
      customer.loading = false;
      customer.success = undefined;
      customer.error = undefined;
      customer.request = undefined;
      // customer.noteUpdate = undefined;
    },

    customerRequest: (customer) => {
      customer.request = { loading: true };
    },
    customerReceived: (
      customer,
      action: PayloadAction<ICustomerDataType[]>
    ) => {
      console.log("customerReceived", action.payload);
      customer.data = action.payload;
      customer.request = undefined;
      customer.lastFetch = Date.now();
    },
    customerRequestFailed: (customer, action: PayloadAction<string>) => {
      customer.request = { error: action.payload };
    },
    customerLoading: (customer) => {
      customer.loading = true;
      customer.success = undefined;
      customer.error = undefined;
    },
    customerFailed: (customer, action: PayloadAction<string>) => {
      customer.loading = false;
      customer.error = action.payload;
    },
    customerRecordReceived: (
      customer,
      action: PayloadAction<IBookingDataType[]>
    ) => {
      console.log("customerRecordReceived", action.payload);
      customer.loading = false;
      customer.record = action.payload;
    },
    customerNotesRequest: (customer) => {
      customer.notes = { loading: true };
    },
    customerNotesReceived: (customer, action) => {
      console.log("customerNotesReceived", action.payload);
      customer.notes = { data: action.payload };
    },
    customerNotesFailed: (customer, action) => {
      customer.notes = { error: action.payload };
    },
    allTagsRequest: (customer) => {
      customer.allTags = { loading: true };
    },
    allTagsReceived: (customer, action) => {
      console.log("allTagsReceived", action.payload);
      customer.allTags = { data: action.payload };
    },
    allTagsFailed: (customer, action) => {
      customer.allTags = { error: action.payload };
    },
    customerAdded: (
      customer,
      action: PayloadAction<{ data?: ICustomerDataType; exist?: boolean }>
    ) => {
      const { data, exist } = action.payload;
      if (data) {
        customer.data = [...customer.data, data];
        customer.success = "The user has been added to your customer list";
      } else if (exist) customer.success = "The customer already in your list";
      customer.loading = false;
    },
    customerRemoving: (customer, action: PayloadAction<string>) => {
      console.log("customerRemoving", action.payload);
      customer.loading = true;
      customer.success = undefined;
      customer.error = undefined;
      customer.data = customer.data?.filter(
        (item) => item._id !== action.payload
      );
    },
    customerRemoved: (customer) => {
      customer.loading = false;
      customer.success = "The user has been removed from your customer list";
    },
    customerRemoveFailed: (
      customer,
      action: PayloadAction<{ actionData: ICustomerDataType[]; error: string }>
    ) => {
      customer.data = action.payload.actionData;
      customer.loading = false;
      customer.error = action.payload.error;
    },
    tagUpdating: (customer) => {
      customer.tagUpdate = { loading: true };
    },
    tagUpdated: (customer) => {
      customer.tagUpdate = { success: true };
    },
    tagUpdateFailed: (customer, action) => {
      customer.tagUpdate = { error: action.payload };
    },
    customerNoteAdding: (customer) => {
      customer.noteUpdate = { loading: true };
    },
    customerNoteAdded: (customer, action) => {
      console.log("customerNoteAdded", action.payload);
      customer.noteUpdate = { success: true };
    },
    customerNoteAddFailed: (customer, action) => {
      customer.noteUpdate = { error: action.payload };
    },
  },
});

export const {
  customerClearState,
  customerRequest,
  customerReceived,
  customerRequestFailed,
  customerLoading,
  customerFailed,
  customerRecordReceived,
  customerNotesRequest,
  customerNotesReceived,
  customerNotesFailed,
  allTagsRequest,
  allTagsReceived,
  allTagsFailed,
  customerAdded,
  customerRemoving,
  customerRemoved,
  customerRemoveFailed,
  tagUpdating,
  tagUpdated,
  tagUpdateFailed,
  customerNoteAdding,
  customerNoteAdded,
  customerNoteAddFailed,
} = slice.actions;

export default slice.reducer;
