import * as yup from "yup";

export const InitialValues = {
  servicePeriodOption: 1,
  servicePeriod: "",
  servicePeriodSum: "",
};

export const ServicePeriodValidation = {
  servicePeriodOption: yup.number().required("Please select a Service Period"),
  servicePeriod: yup.string().required("Service Period cannot be empty"),
};

export const Validation = yup.object().shape(ServicePeriodValidation);
export interface IServicePeriodType {
  servicePeriod: string;
  servicePeriodOption: number;
  servicePeriodSum: string;
}
