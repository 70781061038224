import * as yup from "yup";
import { emailField } from "./validationFields";

// Initial Value
export const InitialValues = {
  password: "",
};

// Validation
export const Validation = yup.object().shape({
  password: yup.string().required("Please enter your password"),
});

// Type
export interface IDeleteAccountInputType {
  password: string;
}
