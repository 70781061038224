import { Link } from "react-router-dom";
import { useEffect } from "react";
import styles from "./PersonalHomePage.module.scss";
import HomeUpcoming from "../../components/personalHome/HomeUpcoming";
import TodayBooking from "../../components/personalHome/TodayBooking";
import PageWithAside from "../../components/layout/PageWithAside";
import { Col, Row } from "../../components/ui/Grid";
import MenuCreateNew from "../../components/ui/MenuCreateNew";
import Header from "../../components/layout/Header";
import { personalURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../../components/ui/toasts/useToast";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import usePersonalRegisterStep from "../../hooks/usePersonalRegisterStep";
import Loader from "../../components/ui/Loader";
import HomeStartBusiness from "../../components/personalHome/HomeStartBusiness";

const PersonalHomePage: React.FC = () => {
  const { getPersonalUpcomingBooking, clearPersonalBookingState } =
    useActions();
  const { success, error } = useTypedSelector(
    (state) => state.entities.personalBooking
  );
  const { notify } = useToast();

  useEffect(() => {
    getPersonalUpcomingBooking();
    // getBookmarks();
  }, []);

  useEffect(() => {
    if (success) {
      console.log("success-----");
      notify("success", success);
      clearPersonalBookingState();
    }
    if (error) {
      notify("error", error);
      clearPersonalBookingState();
    }
  }, [success, error]);

  const { loading } = usePersonalRegisterStep();

  if (loading) return <Loader height="80vh" />;

  return (
    <PageWithAside role="personal">
      <div className="wrapper">
        <Header pageTitle="Home" role="personal" />

        <TodayBooking />
        <Row>
          <Col width="60%">
            <div className={styles["header"]}>
              <h3>Upcoming</h3>
              <Link to={personalURL.PersonalSchedule} className="link">
                View All
              </Link>
            </div>
            <HomeUpcoming />
          </Col>
          <Col width="30%">
            {/* <NotificationList /> */}
            {/* <HomeInvitation /> */}
            <HomeStartBusiness />
            {/* <div className={styles["header"]}>
              <h3>Bookmark</h3>
              <Link to={personalURL.PersonalBookmark} className="link">
                View All
              </Link>
            </div>
            <HomeBookmark /> */}
          </Col>
        </Row>
        <MenuCreateNew role="personal" />
      </div>
    </PageWithAside>
  );
};

export default PersonalHomePage;
