import styles from "./PersonalMeetupParticipants.module.scss";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import { ApptStatus } from "../appointment/ApptStatus";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import Loader from "../ui/Loader";

interface PersonalMeetupParticipantsProps {
  data: IMeetupDataType;
}

const PersonalMeetupParticipants: React.FC<PersonalMeetupParticipantsProps> = ({
  data,
}) => {
  const { getParticipants } = useActions();
  const { data: userData } = useTypedSelector((state) => state.entities.user);
  const { meetupParticipants, loading } = useTypedSelector(
    (state) => state.entities.personalBooking
  );

  useEffect(() => {
    getParticipants(data.host);
  }, []);

  console.log("PersonalMeetupParticipants", data);

  const renderUserInfo = (participant: IMeetupDataType, index: number) => {
    if (data.isHost) {
      // If you are the host
      // show all participants details
      return (
        <>
          {participant.userData.displayName
            ? participant.userData.displayName
            : participant.userData.email}
          {participant.user === userData?._id && (
            <span className={styles["label"]}>You</span>
          )}
          {participant.userData.displayName && (
            <div className="desc">{participant.userData.email}</div>
          )}
        </>
      );
    } else {
      // If you are not the host
      return (
        <>
          {participant.userData.displayName ? (
            participant.userData.displayName
          ) : (
            <i className="text-secondary">Unregistered user</i>
          )}
          {participant.user === userData?._id ? (
            <span className={styles["label"]}>You</span>
          ) : (
            participant.isHost && <span className={styles["label"]}>Host</span>
          )}
        </>
      );
    }
  };

  if (meetupParticipants && meetupParticipants.data)
    return (
      <>
        <h4 className="mb-20">Participants</h4>
        <ul className={styles["participants"]}>
          {meetupParticipants.data.map((participant, index) => {
            return (
              <li key={index}>
                <div className={styles["name"]}>
                  {renderUserInfo(participant, index)}
                </div>
                <div className={styles["status"]}>
                  {participant.userStatus && (
                    <ApptStatus
                      statusNo={participant.userStatus}
                      dateTime={participant.meetDate}
                      timestamp={participant.meetDateTimestamp}
                    />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  return <Loader />;
};

export default PersonalMeetupParticipants;
