import { useState, useEffect } from "react";
import { ModalBox, ModalHeader } from "../ui/modal";
import CustomerDetails from "./CustomerDetails";
import CustomerAddNote from "./CustomerAddNote";
import CustomerNoteDetails from "./CustomerNoteDetails";
import {
  CustomerNoteType,
  ICustomerDataType,
} from "../../config/types/CustomerDataType";
import prefixId, { idType } from "../../utils/prefixID";

type ModalType = "new" | CustomerNoteType | null;

interface ModalCustomerProps {
  data: ICustomerDataType;
  onClose(): void;
  newNote?: boolean;
}

const ModalCustomer: React.FC<ModalCustomerProps> = ({
  data,
  onClose,
  newNote,
}) => {
  const [modal, setModal] = useState<ModalType>();
  const [activeTab, setActiveTab] = useState<number | null>();

  useEffect(() => {
    newNote && setModal("new");
  }, [newNote]);

  const modalContent: () => JSX.Element = () => {
    if (modal && modal === "new") {
      return (
        <>
          <ModalHeader
            title={`New note for ${data.displayName}`}
            backURL={() => {
              setModal(null);
              setActiveTab(2);
            }}
          />
          <CustomerAddNote
            id={data._id}
            onCancel={() => {
              setModal(null);
              setActiveTab(2);
            }}
          />
        </>
      );
    }
    if (modal) {
      return (
        <>
          <ModalHeader
            title={`Note for ${data.displayName}`}
            backURL={() => {
              setModal(null);
              setActiveTab(2);
            }}
          />
          <CustomerNoteDetails data={modal} />
        </>
      );
    }
    return (
      <>
        <ModalHeader title={data.displayName} />
        <CustomerDetails
          data={data}
          onClose={onClose}
          onAddNote={() => setModal("new")}
          onViewNote={(note) => setModal(note)}
          activeTab={activeTab}
        />
      </>
    );
  };

  return (
    <ModalBox onClose={onClose} type="personal" overlayClose={true}>
      {modalContent()}
    </ModalBox>
  );
};

export default ModalCustomer;
