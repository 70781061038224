// Validation Patterns
export const alphanumeric = /^[a-zA-Z0-9]*$/;
export const telephone =
  /^[\+]?[(]?[0-9]{2,3}[)]?[-\s]?[0-9]{2,3}[-\s]?[0-9]{2,7}$/;
export const phoneNumber =
  /^[\+]?[(]?[0-9]{2,3}[)]?[-\s]?[0-9]{2,3}[-\s]?[0-9]{2,7}$/;
export const number = /^[0-9]*$/;
export const pin = /^[0-9]{4}$/;
export const name = /^[a-zA-Z\s]*$/; // allow letter or spaces only
export const password =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~])[A-Za-z\d@$!%*#?&~]{8,}$/;
export const slug = /^[a-z\d-]{8,20}$/;
export const email = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
export const profileName = /^[a-zA-Z0-9\s]*$/;
export const regNo = /^[a-zA-Z0-9]{3,20}$/;
export const normaltext = /^[\w\s!@#$&(),.]{5,50}$/;
export const textArea = /^[\w\s!@#$&(),.]{2,}$/;
export const address = /^[\w\s,\/\.]{10,150}$/;
export const postcode = /^[\w]{4,10}$/;
export const city = /^[\w\s]{3,100}$/;
export const url =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const objectId = /^[a-fA-F0-9]{24}$/;
export const badwords = [
  /ahole/g,
  /anus/g,
  /ash0le/g,
  /ash0les/g,
  /asholes/g,
  /ass/g,
  /Ass Monkey/g,
  /Assface/g,
  /assh0le/g,
  /assh0lez/g,
  /asshole/g,
  /assholes/g,
  /assholz/g,
  /asswipe/g,
  /azzhole/g,
  /bassterds/g,
  /bastard/g,
  /bastards/g,
  /bastardz/g,
  /basterds/g,
  /basterdz/g,
  /Biatch/g,
  /bitch/g,
  /bitches/g,
  /Blow Job/g,
  /boffing/g,
  /butthole/g,
  /buttwipe/g,
  /boobies/g,
  /b00bies/g,
  /c0ck/g,
  /c0cks/g,
  /c0k/g,
  /Carpet Muncher/g,
  /cawk/g,
  /cawks/g,
  /Clit/g,
  /cnts/g,
  /cntz/g,
  /cock/g,
  /cockhead/g,
  /cock-head/g,
  /cocks/g,
  /CockSucker/g,
  /cock-sucker/g,
  /crap/g,
  /cum/g,
  /cunt/g,
  /cunts/g,
  /cuntz/g,
  /dick/g,
  /dild0/g,
  /dild0s/g,
  /dildo/g,
  /dildos/g,
  /dilld0/g,
  /dilld0s/g,
  /dominatricks/g,
  /dominatrics/g,
  /dominatrix/g,
  /dyke/g,
  /enema/g,
  /fuck/g,
  /fucker/g,
  /f u c k/g,
  /f u c k e r/g,
  /fag/g,
  /fag1t/g,
  /faget/g,
  /fagg1t/g,
  /faggit/g,
  /faggot/g,
  /fagit/g,
  /fags/g,
  /fagz/g,
  /faig/g,
  /faigs/g,
  /fart/g,
  /flipping the bird/g,
  /fuck/g,
  /fucker/g,
  /fuckin/g,
  /fucking/g,
  /fucks/g,
  /Fudge Packer/g,
  /fuk/g,
  /Fukah/g,
  /Fuken/g,
  /fuker/g,
  /Fukin/g,
  /Fukk/g,
  /Fukkah/g,
  /Fukken/g,
  /Fukker/g,
  /Fukkin/g,
  /g00k/g,
  /gay/g,
  /gayboy/g,
  /gaygirl/g,
  /gays/g,
  /gayz/g,
  /God-damned/g,
  /h00r/g,
  /h0ar/g,
  /h0re/g,
  /hells/g,
  /hoar/g,
  /hoor/g,
  /hoore/g,
  /jackoff/g,
  /jap/g,
  /japs/g,
  /jerk-off/g,
  /jisim/g,
  /jiss/g,
  /jizm/g,
  /jizz/g,
  /knob/g,
  /knobs/g,
  /knobz/g,
  /kunt/g,
  /kunts/g,
  /kuntz/g,
  /Lesbian/g,
  /Lezzian/g,
  /Lipshits/g,
  /Lipshitz/g,
  /masochist/g,
  /masokist/g,
  /massterbait/g,
  /masstrbait/g,
  /masstrbate/g,
  /masterbaiter/g,
  /masterbate/g,
  /masterbates/g,
  /MothaFucker/g,
  /MothaFuker/g,
  /MothaFukkah/g,
  /MothaFukker/g,
  /MotherFucker/g,
  /MotherFukah/g,
  /MotherFuker/g,
  /MotherFukkah/g,
  /MotherFukker/g,
  /mother-fucker/g,
  /Mutha Fucker/g,
  /Mutha Fukah/g,
  /Mutha Fuker/g,
  /Mutha Fukkah/g,
  /Mutha Fukker/g,
  /n1gr/g,
  /nastt/g,
  /nigger/g,
  /nigur/g,
  /niiger/g,
  /niigr/g,
  /orafis/g,
  /orgasim/g,
  /orgasm/g,
  /orgasum/g,
  /oriface/g,
  /orifice/g,
  /orifiss/g,
  /packi/g,
  /packie/g,
  /packy/g,
  /paki/g,
  /pakie/g,
  /paky/g,
  /pecker/g,
  /peeenus/g,
  /peeenusss/g,
  /peenus/g,
  /peinus/g,
  /pen1s/g,
  /penas/g,
  /penis/g,
  /penis-breath/g,
  /penus/g,
  /penuus/g,
  /Phuc/g,
  /Phuck/g,
  /Phuk/g,
  /Phuker/g,
  /Phukker/g,
  /polac/g,
  /polack/g,
  /polak/g,
  /Poonani/g,
  /pr1c/g,
  /pr1ck/g,
  /pr1k/g,
  /pusse/g,
  /pussee/g,
  /pussy/g,
  /puuke/g,
  /puuker/g,
  /queer/g,
  /queers/g,
  /queerz/g,
  /qweers/g,
  /qweerz/g,
  /qweir/g,
  /recktum/g,
  /rectum/g,
  /retard/g,
  /sadist/g,
  /scank/g,
  /schlong/g,
  /screwing/g,
  /semen/g,
  /sex/g,
  /sexy/g,
  /Sh!t/g,
  /sh1t/g,
  /sh1ter/g,
  /sh1ts/g,
  /sh1tter/g,
  /sh1tz/g,
  /shit/g,
  /shits/g,
  /shitter/g,
  /Shitty/g,
  /Shity/g,
  /shitz/g,
  /Shyt/g,
  /Shyte/g,
  /Shytty/g,
  /Shyty/g,
  /skanck/g,
  /skank/g,
  /skankee/g,
  /skankey/g,
  /skanks/g,
  /Skanky/g,
  /slut/g,
  /sluts/g,
  /Slutty/g,
  /slutz/g,
  /son-of-a-bitch/g,
  /tit/g,
  /turd/g,
  /va1jina/g,
  /vag1na/g,
  /vagiina/g,
  /vagina/g,
  /vaj1na/g,
  /vajina/g,
  /vullva/g,
  /vulva/g,
  /w0p/g,
  /wh00r/g,
  /wh0re/g,
  /whore/g,
  /xrated/g,
  /xxx/g,
  /b!+ch/g,
  /bitch/g,
  /blowjob/g,
  /clit/g,
  /arschloch/g,
  /fuck/g,
  /shit/g,
  /ass/g,
  /asshole/g,
  /b!tch/g,
  /b17ch/g,
  /b1tch/g,
  /bastard/g,
  /bi+ch/g,
  /boiolas/g,
  /buceta/g,
  /c0ck/g,
  /cawk/g,
  /chink/g,
  /cipa/g,
  /clits/g,
  /cock/g,
  /cum/g,
  /cunt/g,
  /dildo/g,
  /dirsa/g,
  /ejakulate/g,
  /fatass/g,
  /fcuk/g,
  /fuk/g,
  /fux0r/g,
  /hoer/g,
  /hore/g,
  /jism/g,
  /kawk/g,
  /l3itch/g,
  /3i+ch/g,
  /lesbian/g,
  /masturbate/g,
  /masterbat/g,
  /masterbat3/g,
  /motherfucker/g,
  /s.o.b./g,
  /mofo/g,
  /nazi/g,
  /nigga/g,
  /nigger/g,
  /nutsack/g,
  /phuck/g,
  /pimpis/g,
  /pusse/g,
  /pussy/g,
  /scrotum/g,
  /sh!t/g,
  /shemale/g,
  /shi+/g,
  /sh!+/g,
  /slut/g,
  /smut/g,
  /teets/g,
  /tits/g,
  /boobs/g,
  /b00bs/g,
  /teez/g,
  /testical/g,
  /testicle/g,
  /titt/g,
  /w00se/g,
  /jackoff/g,
  /wank/g,
  /whoar/g,
  /whore/g,
  /damn/g,
  /dyke/g,
  /fuck/g,
  /shit/g,
  /@$$/g,
  /amcik/g,
  /andskota/g,
  /arse/g,
  /assrammer/g,
  /ayir/g,
  /bi7ch/g,
  /bitch/g,
  /bollock/g,
  /breasts/g,
  /butt-pirate/g,
  /cabron/g,
  /cazzo/g,
  /chraa/g,
  /chuj/g,
  /Cock/g,
  /cunt/g,
  /d4mn/g,
  /daygo/g,
  /dego/g,
  /dick/g,
  /dike/g,
  /dupa/g,
  /dziwka/g,
  /ejackulate/g,
  /Ekrem/g,
  /Ekto/g,
  /enculer/g,
  /faen/g,
  /fag/g,
  /fanculo/g,
  /fanny/g,
  /feces/g,
  /feg/g,
  /Felcher/g,
  /ficken/g,
  /fitt/g,
  /Flikker/g,
  /foreskin/g,
  /Fotze/g,
  /Fu/g,
  /fuk/g,
  /futkretzn/g,
  /gay/g,
  /gook/g,
  /guiena/g,
  /h0r/g,
  /h4x0r/g,
  /hell/g,
  /helvete/g,
  /hoer/g,
  /honkey/g,
  /Huevon/g,
  /hui/g,
  /injun/g,
  /jizz/g,
  /kanker/g,
  /kike/g,
  /klootzak/g,
  /kraut/g,
  /knulle/g,
  /kuk/g,
  /kuksuger/g,
  /Kurac/g,
  /kurwa/g,
  /kusi/g,
  /kyrpa/g,
  /lesbo/g,
  /mamhoon/g,
  /masturbat/g,
  /merd/g,
  /mibun/g,
  /monkleigh/g,
  /mouliewop/g,
  /muie/g,
  /mulkku/g,
  /muschi/g,
  /nazis/g,
  /nepesaurio/g,
  /nigger/g,
  /orospu/g,
  /paska/g,
  /perse/g,
  /picka/g,
  /pierdol/g,
  /pillu/g,
  /pimmel/g,
  /piss/g,
  /pizda/g,
  /poontsee/g,
  /poop/g,
  /porn/g,
  /p0rn/g,
  /pr0n/g,
  /preteen/g,
  /pula/g,
  /pule/g,
  /puta/g,
  /puto/g,
  /qahbeh/g,
  /queef/g,
  /rautenberg/g,
  /schaffer/g,
  /scheiss/g,
  /schlampe/g,
  /schmuck/g,
  /screw/g,
  /sh!t/g,
  /sharmuta/g,
  /sharmute/g,
  /shipal/g,
  /shiz/g,
  /skribz/g,
  /skurwysyn/g,
  /sphencter/g,
  /spic/g,
  /spierdalaj/g,
  /splooge/g,
  /b00b/g,
  /testicle/g,
  /titt/g,
  /twat/g,
  /vittu/g,
  /wank/g,
  /wetback/g,
  /wichser/g,
  /wop/g,
  /yed/g,
  /zabourah/g,
];
export const website =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\.]{1}[a-zA-Z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
