import { ToastContainer as Container } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const ToastContainer: React.FC = () => {
  return (
    <Container
      position="top-right"
      autoClose={10000}
      hideProgressBar={true}
      pauseOnHover={true}
    />
  );
};

export default ToastContainer;
