import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import styles from "./DropdownAction.module.scss";
import DropdownItemList, { DropdownItemListType } from "./DropdownItemList";

interface DropdownActionProps {
  menu: DropdownItemListType[];
  minWidth?: number;
}

const DropdownAction: React.FC<DropdownActionProps> = ({ menu, minWidth }) => {
  return (
    <Dropdown
      menu={<DropdownItemList menuList={menu} />}
      className={styles["button"]}
      maxWidth="400px"
      minWidth={minWidth ? minWidth : 350}
    >
      <MdMoreHoriz />
    </Dropdown>
  );
};

export default DropdownAction;
