import App from "../../App";
import { ModalBox } from "../ui/modal";
import styles from "../businessBooking/BookingCancelDialog.module.scss";
import { AppButton, AppTextArea, AppForm } from "../form";
import { EBtnCategory } from "../form/AppButton";
import { useActions } from "../../hooks/useActions";
import * as yup from "yup";
import { FiSend } from "react-icons/fi";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { clearMessageState } from "../../store/actions";
import { useEffect, useState } from "react";
import { InitialValues, Validation } from "../../config/form/formDialogMessage";

interface DialogMessageBusinessProps {
  name: string;
  status: number;
  businessId: string;
  bookingId: string;
  setVisible(arg: boolean): void;
}

const DialogMessageBusiness: React.FC<DialogMessageBusinessProps> = ({
  name,
  status,
  businessId,
  bookingId,
  setVisible,
}) => {
  const { sendBusinessMessages } = useActions();

  const { success, loading, error } = useTypedSelector(
    (state) => state.entities.message
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (success) {
      clearMessageState();
      setVisible(false);
    } else if (error) {
      setErrorMessage(error);
      clearMessageState();
    }
  }, [success, error]);

  return (
    <ModalBox onClose={() => setVisible(false)} type="dialog">
      <AppForm
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          sendBusinessMessages(values.message, businessId, bookingId);
        }}
      >
        <div className={styles["wrapper"]}>
          <h4>Send a messge to {name}</h4>
          {errorMessage && <div className="error-msg">{errorMessage}</div>}
          <div className={styles["content"]}>
            <AppTextArea
              name="message"
              placeholder="Message"
              maxCharacter={300}
            />
          </div>
        </div>
        <div className={styles["footer"]}>
          <AppButton
            type="button"
            category={EBtnCategory.PrimaryOutline}
            size="small"
            onClick={() => setVisible(false)}
          >
            Cancel
          </AppButton>

          <AppButton type="submit" category={EBtnCategory.Primary} size="small">
            Send Message
          </AppButton>
        </div>
      </AppForm>
    </ModalBox>
  );
};

export default DialogMessageBusiness;
