import styles from "./BusinessWalkInRegPage.module.scss";
import PageDisplay from "../../components/layout/PageDisplay";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import BusinessWalkInReg from "../../components/businessTools/BusinessWalkInReg";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Loader from "../../components/ui/Loader";

const BusinessWalkInRegPage: React.FC = () => {
  useDocumentTitle("Walk-in Registration");
  const { loading: businessLoading, data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { loading: walkInLoading } = useTypedSelector(
    (state) => state.entities.walkIn
  );
  const { getWalkIn } = useActions();

  useEffect(() => {
    getWalkIn();
  }, []);

  const loading = businessLoading || walkInLoading;

  return (
    <PageDisplay title={business?.name}>
      <div className={styles["container"]}>
        <div className="wrapper">
          {loading ? <Loader /> : <BusinessWalkInReg />}
        </div>
      </div>
    </PageDisplay>
  );
};

export default BusinessWalkInRegPage;
