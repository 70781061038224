import { INotificationDataType } from "../../../config/types/NotificationDataType";
import { ENotificationType } from "../../../config/types/NotificationType";

const usePersonalNotificationMessage = () => {
  const getNotificationMessage = (data: INotificationDataType) => {
    const notificationMessage = (type: number) =>
      ({
        [ENotificationType.newMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> has
            invited you for a meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.newInvitationMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> has
            invited you for a meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.acceptMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> will
            attend the meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.confirmMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> will
            attend the meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.rejectMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> will not
            attend the meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.notAttendMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> will not
            attend the meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.cancelMeetup]: (
          <>
            <span className="text-semibold">{data.userFromName}</span> has
            cancel the meetup:{" "}
            <span className="text-semibold">{data.subject}</span>
          </>
        ),
        [ENotificationType.addCustomer]: (
          <>
            You can start make booking at{" "}
            <span className="text-semibold">{data.businessFromName}</span>
          </>
        ),
        [ENotificationType.newBooking]: (
          <>
            You have a new booking with{" "}
            <span className="text-semibold">{data.businessFromName}</span>
          </>
        ),
        [ENotificationType.bookingCancelled]: (
          <>
            You booking with{" "}
            <span className="text-semibold">{data.businessFromName}</span> has
            been cancelled
          </>
        ),
      }[type]);
    return notificationMessage(data.notificationType);
  };

  return { getNotificationMessage };
};

export default usePersonalNotificationMessage;
