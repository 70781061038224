import { useEffect } from "react";
import Header from "../../components/layout/Header";
import PageWithAside from "../../components/layout/PageWithAside";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import BusinessMeetingPoint from "../../components/businessTools/BusinessMeetingPoint";
import Loader from "../../components/ui/Loader";
import { useActions } from "../../hooks/useActions";
import useToast from "../../components/ui/toasts/useToast";

const BusinessMeetingPointPage: React.FC = () => {
  const { getMeetingPoints, clearMeetingPointState } = useActions();
  const { loading, error, data, remove } = useTypedSelector(
    (state) => state.entities.meetingPoint
  );
  const { notify } = useToast();

  useEffect(() => {
    getMeetingPoints();
  }, []);

  useEffect(() => {
    if (remove) {
      if (remove.success) {
        notify("success", "The meeting point has been removed successful");
        clearMeetingPointState();
      } else if (remove.error) {
        notify("error", "Error removing meeting point. Please try again");
        clearMeetingPointState();
      }
    }
  }, [remove]);

  return (
    <PageWithAside role="business">
      <div className="wrapper">
        <Header
          pageTitle="Meeting Point"
          pageDesc="Guide your customer to complete their service at the correct meeting point"
          role="business"
        />
        {!data || loading ? <Loader /> : <BusinessMeetingPoint />}
      </div>
    </PageWithAside>
  );
};

export default BusinessMeetingPointPage;
