import React, { useRef, useEffect } from "react";
import { DropdownWrapper, useDropdown } from "../dropdown";
import useTimePicker from "./useTimePicker";
import styles from "./TimePicker.module.scss";

interface TimePickerProps {
  defaultValue?: string;
  value?: Date; // value: Wed Jan 01 2020 18:30:00 GMT+0800 (Malaysia Time)
  onChange(value: string): void; // value: 18:30
  disabledHours?: number[];
  disabledMinutes?: number[];
  onClose?(): void;
  minuteStep?: number;
}

const TimePicker: React.FC<TimePickerProps> = ({
  defaultValue,
  value,
  children,
  onChange,
  disabledHours,
  disabledMinutes,
  onClose,
  minuteStep = 1,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { position, toggleDropdown, display } = useDropdown(
    wrapperRef,
    dropdownRef,
    listRef
  );

  const { handleToggle, timeList } = useTimePicker(
    onChange,
    toggleDropdown,
    dropdownRef,
    value,
    defaultValue,
    minuteStep,
    disabledHours,
    disabledMinutes,
    display
  );

  useEffect(() => {
    if (!display && onClose) {
      onClose();
    }
  }, [display]);

  return (
    <div className={styles["container"]}>
      <div ref={dropdownRef}>
        <div ref={wrapperRef} role="button" onClick={() => handleToggle()}>
          {children}
        </div>
      </div>
      <DropdownWrapper position={position} display={display} ref={listRef}>
        {timeList}
      </DropdownWrapper>
    </div>
  );
};

export default TimePicker;
