import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { todayString } from "../../utils/datetime";

const ITEMS_PER_PAGE = 20;

enum EBookingReceived {
  Today = "today",
  Upcoming = "upcoming",
}

export enum BookingActionType {
  Start = "start",
  Skip = "skip",
  Complete = "complete",
  Transfer = "transfer",
  Reschedule = "reschedule",
  Cancel = "cancel",
}

interface BookingState {
  loading: boolean;
  loadingMore: boolean;
  request?: { loading?: boolean; error?: string }; // getting booking data
  error?: string;
  lastFetch: { today?: number; upcoming?: number };
  singleDay: {
    data?: [] | IBookingDataType[];
    error?: string;
    loadMore?: boolean;
    request?: boolean;
  };
  today: [] | IBookingDataType[];
  upcoming: {
    data: [] | IBookingDataType[];
    loadMore?: boolean;
    lastFetch?: number;
  };
  success?: string;
  singleData?: IBookingDataType;
  createBooking?: { loading?: boolean; success?: string; error?: string };
}

const initialState: BookingState = {
  loading: false,
  loadingMore: false,
  request: { loading: true },
  error: undefined,
  lastFetch: { today: undefined, upcoming: undefined },
  singleDay: { data: undefined },
  today: [],
  upcoming: { data: [] },
  success: undefined,
  singleData: undefined,
  createBooking: undefined,
};

const slice = createSlice({
  name: "businessBooking",
  initialState,
  reducers: {
    bookingSingleDayClearState: (booking) => {
      booking.singleDay = { data: undefined };
    },
    bookingClearState: (booking) => {
      booking.loading = false;
      booking.error = undefined;
      booking.success = undefined;
      booking.request = undefined;
      booking.createBooking = undefined;
    },
    bookingRequest: (booking) => {
      booking.request = { loading: true };
    },
    bookingReceived: (
      booking,
      action: PayloadAction<{
        data: IBookingDataType[] & IBookingDataType;
        actionData: EBookingReceived;
      }>
    ) => {
      console.log("bookingReceived", action.payload);
      booking.request = undefined;
      if (action.payload.actionData === EBookingReceived.Today) {
        booking.today = action.payload.data;
        booking.lastFetch.today = Date.now();
      } else if (action.payload.actionData === EBookingReceived.Upcoming) {
        booking.upcoming.data = action.payload.data;
        booking.upcoming.lastFetch = Date.now();
        console.log("length", action.payload.data.length);
        if (action.payload.data.length < ITEMS_PER_PAGE)
          booking.upcoming.loadMore = false;
        else booking.upcoming.loadMore = true;
      }
    },
    bookingLoadingMore: (booking) => {
      booking.loadingMore = true;
    },
    bookingUpcomingReceived: (
      booking,
      action: PayloadAction<{
        data: IBookingDataType[];
        actionData: { loadMore?: boolean };
      }>
    ) => {
      console.log("bookingUpcomingReceived", action.payload);
      if (action.payload.actionData.loadMore) {
        booking.upcoming.data = [
          ...booking.upcoming.data,
          ...action.payload.data,
        ];
      } else {
        booking.upcoming.data = action.payload.data;
        booking.upcoming.lastFetch = Date.now();
      }
      console.log("length", action.payload.data.length);
      if (action.payload.data.length < ITEMS_PER_PAGE)
        booking.upcoming.loadMore = false;
      else booking.upcoming.loadMore = true;
      booking.loadingMore = false;
      booking.request = undefined;
    },
    bookingRequestFailed: (booking, action) => {
      booking.request = { error: action.payload };
    },
    bookingStatusAdminUpdate: (
      booking,
      action: PayloadAction<{
        data: IBookingDataType;
        actionData: BookingActionType;
      }>
    ) => {
      console.log(action.payload.actionData);
      booking.upcoming.data = booking.upcoming.data.map((i) => {
        if (i._id === action.payload.data._id) return action.payload.data;
        return i;
      });
      booking.today = booking.today.map((i) => {
        if (i._id === action.payload.data._id) return action.payload.data;
        return i;
      });
      booking.singleData = action.payload.data;
      if (action.payload.actionData === BookingActionType.Start) {
        booking.success = "Start serving";
      } else if (action.payload.actionData === BookingActionType.Skip) {
        booking.success = "Skip the booking";
      } else if (action.payload.actionData === BookingActionType.Complete) {
        booking.success = "The service has completed";
      } else if (action.payload.actionData === BookingActionType.Transfer) {
        booking.success = "The booking has been transferred";
      } else if (action.payload.actionData === BookingActionType.Reschedule) {
        booking.success = "The booking has been rescheduled";
      } else if (action.payload.actionData === BookingActionType.Cancel) {
        booking.success = "The booking has been cancelled";
      }
    },
    bookingCreating: (booking) => {
      booking.createBooking = { loading: true };
    },
    bookingCreated: (booking, action: PayloadAction<IBookingDataType>) => {
      booking.createBooking = { success: "Booking has been created" };
      const newBooking = action.payload;
      if (newBooking.meetDate === todayString)
        booking.today = [...booking.today, newBooking];
      else booking.upcoming.data = [...booking.upcoming.data, newBooking];
    },
    bookingCreateFailed: (booking, action: PayloadAction<string>) => {
      booking.createBooking = { error: action.payload };
    },
    bookingLoading: (booking) => {
      booking.loading = true;
      booking.success = undefined;
      booking.error = undefined;
      booking.singleData = undefined;
    },
    bookingByDateRequest: (booking) => {
      booking.singleDay = { request: true };
    },
    bookingByDateLoadingMore: (booking) => {
      booking.loadingMore = true;
    },
    bookingByDateReceived: (
      booking,
      action: PayloadAction<{
        data: IBookingDataType[];
        actionData: { loadMore?: boolean };
      }>
    ) => {
      console.log("bookingByDateReceived", action.payload);
      if (action.payload.actionData.loadMore) {
        if (booking.singleDay.data)
          booking.singleDay.data = [
            ...booking.singleDay.data,
            ...action.payload.data,
          ];
      } else booking.singleDay.data = action.payload.data;
      if (action.payload.data.length < ITEMS_PER_PAGE)
        booking.singleDay.loadMore = false;
      else booking.singleDay.loadMore = true;
      booking.loadingMore = false;
      booking.singleDay.request = false;
    },
    bookingByDateFailed: (booking, action: PayloadAction<string>) => {
      booking.singleDay = { error: action.payload };
    },
    bookingSingleReceived: (
      booking,
      action: PayloadAction<IBookingDataType>
    ) => {
      console.log("bookingSingleReceived", action.payload);
      booking.singleData = action.payload;
      booking.loading = false;
    },
    bookingFailed: (booking, action: PayloadAction<string>) => {
      booking.loading = false;
      booking.error = action.payload;
    },
  },
});

export const {
  bookingSingleDayClearState,
  bookingClearState,
  bookingRequest,
  bookingReceived,
  bookingLoadingMore,
  bookingUpcomingReceived,
  bookingRequestFailed,
  bookingStatusAdminUpdate,
  bookingLoading,
  bookingCreating,
  bookingCreated,
  bookingCreateFailed,
  bookingByDateRequest,
  bookingByDateLoadingMore,
  bookingByDateReceived,
  bookingByDateFailed,
  bookingSingleReceived,
  bookingFailed,
} = slice.actions;

export default slice.reducer;
