import { useState } from "react";
import styles from "../personal/ParticipantList.module.scss";
import { AvatarPic } from "../ui/avatar";
import { ListTableDataType } from "../ui/ListTable";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { DropdownPanelMenuType } from "../ui/dropdown/DropdownPanel";

const useServiceProvider = (service: IServiceDataType) => {
  const { addAdminServicesProvider, removeAdminServicesProvider } =
    useActions();

  const { data: providers } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );

  const [serviceProviders, setServiceProviders] = useState<
    string[] | undefined
  >(service.providers);

  const handleDelete = (providerId: string) => {
    console.log("handleDelete", providerId);
    const filteredList = serviceProviders?.filter((id) => id !== providerId);
    setServiceProviders(filteredList);
    removeAdminServicesProvider(service._id, providerId);
  };

  const handleAdd = (providerId: string) => {
    console.log("handleAdd", providerId);
    if (panelList.length > 0) {
      const provider = providers.find((item) => item._id === providerId);
      if (provider) {
        serviceProviders
          ? setServiceProviders([...serviceProviders, provider._id])
          : setServiceProviders([provider._id]);
      }
    }
    addAdminServicesProvider(service._id, providerId);
  };

  // panelList
  const panelList: DropdownPanelMenuType[] = [];

  // panelList filter
  if (providers.length > 0) {
    providers.map((item) => {
      if (!serviceProviders || !serviceProviders.includes(item._id)) {
        panelList.push({
          id: item._id,
          item: (
            <div className={styles["participant-list"]}>
              <AvatarPic role="personal" src={item.imageUrl} size="small" />
              <div className="ml-10">{item.name}</div>
            </div>
          ),
          name: item.name,
          onClick: () => handleAdd(item._id),
        });
      }
    });
  }

  // Provider List
  const providerList: ListTableDataType[] = [];

  if (serviceProviders) {
    providers.map((provider) => {
      if (serviceProviders.includes(provider._id)) {
        providerList.push({
          id: provider._id,
          content: (
            <div className={styles["participant-list"]}>
              <div className={styles["participant-pic"]}>
                <AvatarPic role="personal" src={provider.imageUrl} />
              </div>
              <div>
                <div className="h5">{provider.name}</div>
                <div className="desc">{provider.title}</div>
              </div>
            </div>
          ),
        });
      }
    });
    // console.log("providerList", providerList);
  }

  return {
    panelList,
    providerList,
    handleDelete,
    handleAdd,
  };
};

export default useServiceProvider;
