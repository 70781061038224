import { useEffect, useState } from "react";
import { AppButton, AppForm, AppInput, AppSelectMenu } from "../form";
import { ModalFooter, ModalBody } from "../ui/modal";
import styles from "./ModalMeetingPoint.module.scss";
import { optionMeetingPoint } from "../../config/option/optionMeetingPoint";
import useMeetingPointForm from "./useMeetingPointForm";
import { useActions } from "../../hooks/useActions";
import { MeetingPointType } from "../../config/types/MeetingPointType";
import {
  InitialValues,
  Validation,
  MeetingPointFormType,
} from "../../config/form/formMeetingPoint";

const inputWidth = "300px";

interface MeetingPointFormProps {
  data: MeetingPointType | "new";
  onClose(): void;
}

const MeetingPointForm: React.FC<MeetingPointFormProps> = ({
  data,
  onClose,
}) => {
  const { addMeetingPoint, updateMeetingPoint } = useActions();
  const { optionServices, optionResources, optionProviders } =
    useMeetingPointForm();
  const [initVal, setInitVal] = useState<MeetingPointFormType>(InitialValues);

  useEffect(() => {
    if (data !== "new") {
      setInitVal({
        type: data.type,
        label: data.label,
        service: data.service && data.service._id,
        provider: data.provider && data.provider._id,
        resource: data.resource && data.resource._id,
      });
    }
  }, [data]);

  return (
    <AppForm
      className="flex-layout"
      initialValues={initVal}
      onSubmit={(values, actions) => {
        console.log("values", values);
        actions.setSubmitting(false);
        data === "new"
          ? addMeetingPoint(values)
          : updateMeetingPoint(data._id, values);
      }}
      validationSchema={Validation}
    >
      <ModalBody>
        <div className={styles["wrapper"]}>
          <AppSelectMenu
            name="type"
            label="Type"
            options={optionMeetingPoint}
            inputWidth={inputWidth}
          />
          <AppInput
            label="Label"
            name="label"
            maxCharacter={6}
            helperMsg="Label or number for the meeting point"
            inputWidth={inputWidth}
          />
          <h4 className="mb-5 mt-20">Preset</h4>
          <div className="desc pb-20">
            All new booking for below service, resource and provider will
            indicate this meeting point
          </div>
          {optionServices.length > 0 && (
            <AppSelectMenu
              options={optionServices}
              inputWidth={inputWidth}
              label="Services"
              name="service"
            />
          )}
          {optionResources.length > 0 && (
            <AppSelectMenu
              inputWidth={inputWidth}
              options={optionResources}
              label="Resources"
              name="resource"
            />
          )}
          {optionProviders.length > 0 && (
            <AppSelectMenu
              options={optionProviders}
              inputWidth={inputWidth}
              label="Providers"
              name="provider"
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <AppButton
          type="button"
          className="btn btn-link mr-10"
          onClick={onClose}
        >
          Cancel
        </AppButton>
        <AppButton type="submit" className="btn btn-primary">
          Save
        </AppButton>
      </ModalFooter>
    </AppForm>
  );
};

export default MeetingPointForm;
