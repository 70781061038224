import ProviderHour from "../common/ProviderHour";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";

interface ProviderAvailablePeriodProps {
  providerName: string;
}

const ProviderAvailablePeriod: React.FC<ProviderAvailablePeriodProps> = ({
  providerName,
}) => {
  return (
    <>
      <h3 className="mb-3">Available Period</h3>
      <div className="desc mb-20">
        Set when is this {providerName} be available.
      </div>
      <Row>
        <Col width="60%">
          <ProviderHour optionName="hoursOption" hoursName="hours" />
        </Col>
        <Col width="30%">
          <Guideline title="Provider Available Period">
            <p>
              Provider available period will determine when the provider take
              place.
            </p>
            <p>
              Select <b>Custom provider available hour</b> if the provider
              available hour is difference than your business hour.
            </p>
            <p>
              Select <b>Custom day and time</b> if the provider only take place
              on particular day and time.
            </p>
            {/* <p>
              if you are not sure when the service will take place, please
              select <b>Waiting List</b>.
            </p> */}
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ProviderAvailablePeriod;
