// Location: Business > Bookings > Actions(...) > Start with Meeting Point

import * as yup from "yup";
import { nameField, emailField } from "./validationFields";

export const InitialValues = {
  customerName: "",
  tokenNo: "",
  provider: "",
  meetingPoint: "",
};

// Validation
export const Validation = yup.object().shape({
  meetingPoint: yup.string().required("Please select a meeting point"),
});
