import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useToast from "../ui/toasts/useToast";
import { useActions } from "../../hooks/useActions";

const useBookingStatusResponse = () => {
  const { clearBusinessBookingState } = useActions();
  const { success } = useTypedSelector(
    (state) => state.entities.businessBooking
  );
  const { notify } = useToast();

  useEffect(() => {
    if (success) {
      notify("success", success);
      clearBusinessBookingState();
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useBookingStatusResponse;
