import { useEffect, useState } from "react";
import {
  AppButton,
  AppInput,
  AppForm,
  // AppDatePicker,
  AppRadioButton,
} from "../../components/form";
import LayoutCenterBox from "../../components/layout/LayoutCenterBox";
import { gender } from "../../config/option/optionProfile";
import {
  InitialValues,
  Validation,
} from "../../config/form/formRegisterProfile";
import { useActions } from "../../hooks/useActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useRegisterStep from "./useRegisterStep";
import AppCountryRegion from "../../components/form/AppCountryRegion";
import AppInputCode from "../../components/form/AppInputCode";
import { EBtnCategory } from "../../components/form/AppButton";

const AuthRegisterProfilePage: React.FC = () => {
  useDocumentTitle("Personal Profile");
  const [initVal, setInitVal] = useState(InitialValues);
  const { registerProfile } = useActions();
  const { loading, error, data } = useTypedSelector(
    (state) => state.entities.register
  );
  useRegisterStep(2);

  useEffect(() => {
    if (data) {
      setInitVal({
        ...initVal,
        country: data.country,
      });
    }
  }, [data]);

  return (
    <>
      <AppForm
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          registerProfile(values);
          actions.setSubmitting(false);
        }}
      >
        <LayoutCenterBox>
          <div className="mb-20 text-center">
            <h1>Personal Profile</h1>
            <p>
              This information will be used for verification when you make
              booking
            </p>
            {error && <p className="error-msg">{error}</p>}
          </div>
          <AppInput
            name="displayName"
            label="Name"
            helperMsg="Your name will be used for booking"
          />
          <AppRadioButton
            name="gender"
            label="Gender"
            radioGroup={true}
            options={gender}
          />
          {/* <AppDatePicker
            name="birthdate"
            label="Birthdate"
            initialValue={new Date(1990, 0, 1)}
          /> */}
          <AppInput name="age" label="Age" type="number" />
          <AppInput name="city" label="City" />
          <AppInput name="postcode" label="Postal Code" />
          <AppCountryRegion name="country" label="Country" />
          <AppCountryRegion name="state" label="State" />
          <AppInputCode
            name="pin"
            codeLength={4}
            // type="number"
            isForm
            label="PIN Number"
            helperMsg="Enter last 4 digits of your NRIC. PIN number will be used to
                verify your identity during the meet-up and cannot be changed
                later"
          />
          <AppButton
            category={EBtnCategory.Primary}
            type="submit"
            btnBlock
            className="mt-20"
            size="large"
            loading={loading}
          >
            Get Started! (Step 3 of 3)
          </AppButton>
          <p className="desc text-center">
            AddPointment will never share your information without your
            permission.
          </p>
        </LayoutCenterBox>
      </AppForm>
    </>
  );
};

export default AuthRegisterProfilePage;
