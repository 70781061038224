import { useState, useEffect, useContext } from "react";
import { DropdownSelect } from "../ui/dropdown";
import styles from "./LocationSelection.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { BusinessContext, ContextType } from "../../context/businessContext";

interface LocationSelectionProps {
  onChange?(id: string): void;
}

const LocationSelection: React.FC<LocationSelectionProps> = ({ onChange }) => {
  const { businessState, handleStateChange } = useContext(
    BusinessContext
  ) as ContextType;
  const { data } = useTypedSelector((state) => state.entities.location);
  const [currentLabel, setCurrentLabel] = useState<string | undefined>("");

  useEffect(() => {
    if (data && data.length > 0) {
      if (businessState.location) {
        const result = data.find((i) => i._id === businessState.location);
        result ? setCurrentLabel(result?.name) : setCurrentLabel(data[0].name);
      } else {
        handleStateChange({ location: data[0]._id });
        // setCurrentLabel(data && data[0].name);
      }
    }
  }, [data, businessState]);

  const handleClick = (id: string) => {
    onChange && onChange(id);
    handleStateChange({ location: id });
  };

  if (data && data.length > 1) {
    const menuList = data.map((val, index) => {
      return {
        ...val,
        onClick: () => {
          setCurrentLabel(val.name);
          // localStorage.setItem("label", val.name);
          handleClick(val._id);
        },
      };
    });

    return (
      <div className={styles["dropdown"]}>
        <DropdownSelect
          menuList={menuList}
          selected={currentLabel}
          type="menu"
        />
      </div>
    );
  }
  return null;
};

export default LocationSelection;
