import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { getTodayIncompleteBusinessBooking } from "../../store/selector";
import useGetBookingType from "../../hooks/useGetBookingType";

export type WaitingListType = {
  id: string;
  name: string;
  waiting: number;
};

const useBusinessServingAllPage = (type?: string) => {
  const {
    requestService,
    loadingResource,
    loadingProvider,
    services,
    resources,
    providers,
  } = useGetBookingType();
  const { request: requestBusiness, data: business } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { loading: servingLoading, all: servingList } = useTypedSelector(
    (state) => state.entities.serving
  );
  const incompleteBookings = useTypedSelector(
    getTodayIncompleteBusinessBooking
  );

  const [serviceList, setServiceList] = useState<WaitingListType[]>([]);
  const [resourceList, setResourceList] = useState<WaitingListType[]>([]);
  const [providerList, setProviderList] = useState<WaitingListType[]>([]);
  const { getBusinessTodayBooking, getAllServing } = useActions();

  useEffect(() => {
    getBusinessTodayBooking();
    getAllServing();
  }, []);

  const loading =
    requestBusiness?.loading ||
    requestService?.loading ||
    loadingResource ||
    loadingProvider ||
    servingLoading;

  //   let waitingList: WaitingListType[] = [];

  function GenerateServiceList() {
    console.log("GenerateServiceList");
    let List: WaitingListType[] = [];
    services.forEach((service) => {
      const filtered = incompleteBookings.filter(
        // Find appointment in service
        (el) => service._id === (el?.service as string)
      );
      List.push({
        id: service._id,
        name: service.name,
        waiting: filtered ? filtered.length : 0, // Number of appoinment for the service
      });
    });
    setServiceList(List);
  }

  function GenerateResourceList() {
    console.log("GenerateResourceList");
    let List: WaitingListType[] = [];
    // if (resources.length > 0) {
    //   resources.forEach((resource) => {
    //     const filtered = incompleteBookings.filter(
    //       // Find appointment in resource
    //       (el) => resource._id === el?.resource?._id
    //     );
    //     List.push({
    //       id: resource._id,
    //       name: resource.name,
    //       waiting: filtered ? filtered.length : 0, // Number of appoinment for the service
    //     });
    //   });
    //   setResourceList(List);
    // }
  }

  function GenerateProviderList() {
    console.log("GenerateProviderList");
    let List: WaitingListType[] = [];
    // providers.forEach((provider) => {
    //   const filtered = incompleteBookings.filter(
    //     // Find appointment in provider
    //     (el) => provider._id === el?.provider?._id
    //   );
    //   List.push({
    //     id: provider._id,
    //     name: provider.name,
    //     waiting: filtered ? filtered.length : 0, // Number of appoinment for the service
    //   });
    // });
    // setProviderList(List);
  }

  useEffect(() => {
    if (services && services.length > 0) {
      GenerateServiceList();
    }
    if (resources && resources.length > 0) {
      GenerateResourceList();
    }
    if (providers && providers.length > 0) {
      GenerateProviderList();
    }
  }, [services, resources, providers]);

  return {
    loading,
    business,
    servingList,
    serviceList,
    resourceList,
    providerList,
  };
};

export default useBusinessServingAllPage;
