import { Link } from "react-router-dom";
import { Col, Row } from "../ui/Grid";
import ReadMore from "../ui/ReadMore";
import styles from "./ModalBookmark.module.scss";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface BookmarkBusinessServicesProps {
  data: IServiceDataType[];
}

const BookmarkBusinessServices: React.FC<BookmarkBusinessServicesProps> = ({
  data,
}) => {
  return (
    <>
      <h4 className="mb-10">Services</h4>
      {data.map((item, index) => {
        return (
          <Row className={styles["list"]} key={index}>
            <Col>
              <div className="h5 mb-5">{item.name}</div>
              <div className="desc">
                <ReadMore charLimit={100}>{item.desc}</ReadMore>
              </div>
            </Col>
            {/* <Col width="100px" className="text-right">
              <Link to="#" className="link">
                Book Now
              </Link>
            </Col> */}
          </Row>
        );
      })}
    </>
  );
};

export default BookmarkBusinessServices;
