import { providerType } from "../../config/option/optionProviders";

const useBusinessProvider = () => {
  const getProviderName = (type?: number) => {
    if (!type) return "Provider";
    const provider = providerType.find((item) => item.value === type);
    return provider ? provider.name : "Provider";
  };

  return { getProviderName };
};

export default useBusinessProvider;
