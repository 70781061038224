import { useEffect, useState } from "react";
import { AppButton } from "../form";
import { EBtnCategory } from "../form/AppButton";
import { ModalBody, ModalBox, ModalFooter, ModalHeader } from "../ui/modal";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import styles from "./ModalServicesCategory.module.scss";
import ServicesCategoryItem from "./ServicesCategoryItem";
import classNames from "classnames";

interface ModalServicesCategoryProps {
  onClose(): void;
}

const ModalServicesCategory: React.FC<ModalServicesCategoryProps> = ({
  onClose,
}) => {
  const { clearAdminServicesCategoryState, getAdminServicesCategory } =
    useActions();
  const { success, error } = useTypedSelector(
    (state) => state.entities.serviceCategory
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data } = useTypedSelector((state) => state.entities.serviceCategory);

  useEffect(() => {
    getAdminServicesCategory();
  }, []);

  useEffect(() => {
    if (success) {
      console.log("success");
      clearAdminServicesCategoryState();
      onClose();
    } else if (error) {
      clearAdminServicesCategoryState();
      setErrorMessage(error);
    }
  }, [error, success]);

  return (
    <ModalBox onClose={onClose} type="business" autoHeight>
      <ModalHeader title="Categories" className="py-10" />
      <ModalBody>
        <div className={classNames(styles["container"], styles["new"])}>
          {errorMessage && <p className="desc error-msg">{errorMessage}</p>}
          <ServicesCategoryItem />
        </div>
        <div className={styles["container"]}>
          {data.length > 0 &&
            data.map((category, index) => (
              <ServicesCategoryItem key={index} category={category} />
            ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <AppButton
          type="button"
          category={EBtnCategory.Primary}
          onClick={() => onClose()}
          size="small"
        >
          Done
        </AppButton>
      </ModalFooter>
    </ModalBox>
  );
};

export default ModalServicesCategory;
