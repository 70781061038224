import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../ui/table";
import styles from "./TableBookmark.module.scss";
import TableBookmarkActions from "./TableBookmarkActions";
import classNames from "classnames";
import { AvatarPic } from "../ui/avatar";
import { personalURL } from "../../config/url";
import { IFriendDataType } from "../../config/types/FriendDataType";

interface TableFriendProps {
  data: IFriendDataType[];
  onView(id: string): void;
}

const TableFriend: React.FC<TableFriendProps> = ({ data, onView }) => {
  const navigate = useNavigate();
  const columns = [
    {
      label: "Name",
      name: "name",
      colWidth: "50%",
      cellClass: styles["col-name"],
      content: (bookmark: { displayName: string; profilePic: string }) => (
        <div className={styles["avatar-name"]}>
          <div className={styles["avatar"]}>
            <AvatarPic role="personal" src={bookmark.profilePic} />
          </div>
          <div className={classNames(styles["name"], "h5 text-wrap")}>
            {bookmark.displayName}
          </div>
        </div>
      ),
    },
    {
      label: "",
      name: "make-appointment",
      colWidth: "15%",
      cellClass: styles["col-make-appt"],
      disableItemClick: true,
      content: (friend: { friend: string }) => {
        return (
          <button
            type="button"
            className="btn btn-link link"
            onClick={() =>
              navigate(personalURL.CreateMeetup, {
                state: [friend.friend],
              })
            }
          >
            Create Meetup
          </button>
        );
      },
    },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "60px",
      cellClass: styles["col-action"],
      disableItemClick: true,
      content: (friend: { _id: string }) => {
        return <TableBookmarkActions type="friend" id={friend._id} />;
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      className={styles["table"]}
      itemClick={(user: { _id: string }) => onView(user._id)}
    />
  );
};

export default TableFriend;
