import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import DatePicker from "../ui/DatePicker";

interface DatePickerProps {
  initialValue?: Date;
  name: string;
  helperMsg?: string;
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  inputDay?: string;
  inputMonth?: string;
  inputYear?: string;
  minDate?: Date;
  maxDate?: Date;
}

interface FormikProps {
  [name: string]: Date & string;
}

const AppDatePicker: React.FC<DatePickerProps> = ({
  initialValue,
  name,
  helperMsg,
  label,
  labelBlock,
  labelWidth,
  inputDay,
  inputMonth,
  inputYear,
  minDate,
  maxDate,
  // ...props
}) => {
  const { setFieldValue, errors, values } = useFormikContext<FormikProps>();

  const handleChange = (val: Date | Date[]) => {
    if (val && val instanceof Date) {
      setFieldValue(name, val.toDateString());
      inputDay && setFieldValue(inputDay, val.getDate());
      inputMonth && setFieldValue(inputMonth, val.getMonth() + 1);
      inputYear && setFieldValue(inputYear, val.getFullYear());
    }
  };

  // console.log(new Date("1980-05-30").toDateString());

  return (
    <>
      <InputContainer
        name={name}
        label={label}
        labelBlock={labelBlock}
        labelWidth={labelWidth}
        helperMsg={helperMsg}
        error={errors[name]}
      >
        <DatePicker
          value={values[name]}
          onChange={handleChange}
          initialValue={initialValue}
          minDate={minDate}
          maxDate={maxDate}
        />
      </InputContainer>
    </>
  );
};

export default AppDatePicker;
