import { createSlice } from "@reduxjs/toolkit";
import { StaffType } from "../../config/types/StaffType";
import { IInvitationDataType } from "../../config/types/InvitationDataType";

interface staffState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: number;
  success: boolean;
  data: StaffType[];
  update?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  remove?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  add?: {
    loading?: boolean;
    success?: boolean;
    error?: string;
  };
  currentUser?: StaffType;
  role?: number;
  invitation: IInvitationDataType[];
}

const initialState: staffState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  success: false,
  data: [],
  update: undefined,
  remove: undefined,
  add: undefined,
  currentUser: undefined, // User's staff
  invitation: [],
};

const slice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    staffClearState: (staff) => {
      staff.success = false;
      staff.update = undefined;
      staff.remove = undefined;
      staff.add = undefined;
    },
    staffRequest: (staff) => {
      staff.loading = true;
    },
    staffReceived: (staff, action) => {
      staff.lastFetch = Date.now();
      staff.data = action.payload.data;
      staff.success = true;
      staff.loading = false;
      const userId = action.payload.actionData.userId;
      staff.currentUser = action.payload.data.find(
        (i: StaffType) => i.user._id === userId
      );
    },
    staffInvitationReceived: (staff, action) => {
      staff.invitation = action.payload;
    },
    staffRequestFailed: (staff, action) => {
      staff.loading = false;
      staff.error = action.payload;
    },
    staffUpdating: (staff) => {
      staff.update = { loading: true };
    },
    staffUpdated: (staff, action) => {
      const index = staff.data.findIndex(
        (item) => item._id === action.payload.id
      );
      staff.data[index].role = action.payload.role;
      staff.update = { success: action.payload };
    },
    staffUpdateFailed: (staff, action) => {
      staff.update = { error: action.payload };
    },
    staffRemoving: (staff) => {
      staff.remove = { loading: true };
    },
    staffRemoved: (staff, action) => {
      staff.data = staff.data.filter((item) => item._id !== action.payload);
      staff.remove = { success: true };
    },
    staffRemoveFailed: (staff, action) => {
      staff.remove = { error: action.payload };
    },
    staffAdding: (staff) => {
      staff.add = { loading: true };
    },
    staffAdded: (staff, action) => {
      staff.data = [...staff.data, action.payload];
      staff.add = { success: true };
    },
    staffAddFailed: (staff, action) => {
      staff.add = { error: action.payload };
    },
  },
});

export const {
  staffClearState,
  staffRequest,
  staffReceived,
  staffInvitationReceived,
  staffRequestFailed,
  staffUpdating,
  staffUpdated,
  staffUpdateFailed,
  staffRemoving,
  staffRemoved,
  staffRemoveFailed,
  staffAdding,
  staffAdded,
  staffAddFailed,
} = slice.actions;

export default slice.reducer;
