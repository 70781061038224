import styles from "./Header.module.scss";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

interface HeaderProps {
  pageTitle: string;
  pageDesc?: string;
  backButton?: boolean;
  role: "personal" | "business";
}

const Header: React.FC<HeaderProps> = ({
  pageTitle,
  pageDesc,
  role,
  backButton,
}) => {
  useDocumentTitle(`${pageTitle} - AddPointment`);
  const navigate = useNavigate();
  return (
    <header className={styles["container"]}>
      <div className="page-title">
        <h1>
          {backButton && (
            <button onClick={() => navigate(-1)} className={styles["btn-back"]}>
              <FaChevronLeft />
            </button>
          )}
          {pageTitle}
        </h1>
        <p className="desc">{pageDesc}</p>
      </div>
    </header>
  );
};

export default Header;
