import { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import config from "../../config/global";

import { ModalBody, ModalBox, ModalFooter, ModalHeader } from "../ui/modal";
import Tab from "../ui/tab";
import MoreButton from "../ui/MoreButton";
import { IBookmarkDataType } from "../../config/types/BookmarkDataType";
import BookmarkRemoveDialog from "./BookmarkRemoveDialog";
import BookmarkBusinessInfo from "./BookmarkBusinessInfo";
import BookmarkBusinessLocation from "./BookmarkBusinessLocation";
import BookmarkBusinessServices from "./BookmarkBusinessServices";
import BookmarkBusinessResources from "./BookmarkBusinessResources";
import BusinessRecords from "../common/BusinessRecords";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../ui/Loader";
import BookmarkBusinessProviders from "./BookmarkBusinessProviders";
import { EBtnCategory } from "../form/AppButton";

interface ModalBookmarkProps {
  onClose(): void;
  data: IBookmarkDataType;
}

const ModalBookmark: React.FC<ModalBookmarkProps> = ({ onClose, data }) => {
  const [dialog, setDialog] = useState(false);
  const [tab, setTab] = useState(0);
  const { getBusiness } = useActions();
  const { loading, data: businessData } = useTypedSelector(
    (state) => state.entities.business
  );

  useEffect(() => {
    getBusiness(data.business);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || !businessData)
    return (
      <ModalBox onClose={onClose} type="personal" overlayClose={true}>
        <ModalHeader title={data.businessName} />
        <Loader />
      </ModalBox>
    );

  const Items = [
    {
      tabID: 0,
      name: "Business",
      content: (
        <BookmarkBusinessInfo
          business={businessData.business}
          location={businessData.locations[0]}
        />
      ),
    },
    {
      tabID: 1,
      name: "Location",
      content: <BookmarkBusinessLocation data={businessData.locations} />,
    },
  ];

  const menuList = [
    {
      name: "Remove",
      icon: <RiDeleteBin6Line />,
      desc: "Remove from your bookmark list",
      onClick: () => setDialog(true),
    },
  ];

  if (businessData.services && businessData.services.length > 0) {
    Items.push({
      tabID: 3,
      name: "Services",
      content: <BookmarkBusinessServices data={businessData.services} />,
    });
  }
  // if (business.resources && business.resources.length > 0) {
  //   Items.push({
  //     tabID: 4,
  //     name: "Resources",
  //     content: <BookmarkBusinessResources data={business.resources} />,
  //   });
  // }
  // if (business.providers && business.providers.length > 0) {
  //   Items.push({
  //     tabID: 5,
  //     name: "Providers",
  //     content: <BookmarkBusinessProviders data={business.providers} />,
  //   });
  // }
  Items.push({
    tabID: 2,
    name: "Record",
    content: <BusinessRecords businessId={data.business} />,
  });

  return (
    <>
      <ModalBox onClose={onClose} type="personal" overlayClose={true}>
        <ModalHeader title={data.businessName} />
        <ModalBody>
          <Tab
            items={Items}
            active={tab}
            type="vertical"
            onTabClick={(id: number) => setTab(id)}
          >
            {Items[tab]["content"]}
          </Tab>
        </ModalBody>
        <ModalFooter>
          <MoreButton
            menuList={menuList}
            label="Make Booking"
            category={EBtnCategory.PrimaryOutline}
            onClick={() =>
              window.open(`${config.bookingUrl}${data.businessUrl}`)
            }
            minWidth={260}
          />
        </ModalFooter>
      </ModalBox>
      {dialog && (
        <BookmarkRemoveDialog
          setVisible={(arg) => setDialog(arg)}
          onRemove={onClose}
          id={data._id}
          type="bookmark"
        />
      )}
    </>
  );
};

export default ModalBookmark;
