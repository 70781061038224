import { DropdownAction } from "../ui/dropdown";
import { IBookingDataType } from "../../config/types/BookingDataType";
import usePersonalBookingActions from "./usePersonalBookingActions";
import PersonalContactDialog from "./DialogMessageBusiness";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";

interface IPersonalBookingActions {
	data: IBookingDataType;
}

const PersonalBookingActions: React.FC<IPersonalBookingActions> = ({
	data,
}) => {
	const { menuList, contactDialog, setContactDialog } =
		usePersonalBookingActions(data);

	return (
		<>
			{contactDialog && (
				<PersonalContactDialog
					name={data.businessData.name}
					status={EBookingStatusValue.cancel}
					setVisible={(arg) => setContactDialog(arg)}
					businessId={data.business as string}
					bookingId={data._id}
				/>
			)}
			<DropdownAction menu={menuList} />
		</>
	);
};

export default PersonalBookingActions;
