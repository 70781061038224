import { useState, useEffect, useContext } from "react";
import PageWithAside from "../../components/layout/PageWithAside";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  LayoutBody,
  LayoutFullHeight,
} from "../../components/layout/LayoutFullHeight";
import useBusinessProfile from "../../components/businessProfile/useBusinessProfile";
import BusinessContact from "../../components/businessProfile/BusinessContact";
import { AppForm } from "../../components/form";
import Loader from "../../components/ui/Loader";
import {
  InitialValues,
  Validation,
} from "../../config/form/formBusinessContact";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { BusinessContext, ContextType } from "../../context/businessContext";
import useToast from "../../components/ui/toasts/useToast";
import { businessProfileSubmenu } from "../../config/submenu";

const index = 1;
const BusinessContactPage: React.FC = () => {
  useDocumentTitle("Business Contact - AddPointment");
  const { businessState } = useContext(BusinessContext) as ContextType;
  const { updateBusinessContact, clearLocationState, getLocation } =
    useActions();
  const { header, footer } = useBusinessProfile(index, true);
  const { request, error, data, success } = useTypedSelector(
    (state) => state.entities.location
  );
  const { notify } = useToast();
  const [initVal, setInitVal] = useState(InitialValues);

  const selected = businessState.location; // Selected location id

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      if (selected) {
        const result = data.filter((i) => i._id === selected)[0];
        setInitVal({
          ...initVal,
          ...result,
        });
      } else {
        setInitVal({
          ...initVal,
          ...data[0],
        });
      }
    }
  }, [data, selected]);

  useEffect(() => {
    if (success) {
      notify("success", "Business contact has been updated.");
      clearLocationState();
    }
    if (error) {
      notify("error", error);
      clearLocationState();
    }
  }, [success, error]);

  return (
    <PageWithAside
      role="business"
      submenuName="Profile"
      submenuList={businessProfileSubmenu}
      noPadding
    >
      <AppForm
        initialValues={initVal}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          updateBusinessContact(values, values._id);
        }}
        validationSchema={Validation}
      >
        <LayoutFullHeight>
          <LayoutBody>
            {header()}
            {request?.loading && <Loader />}
            {request?.error && <div className="error-msg">{request.error}</div>}
            {!request && data && (
              <>
                <BusinessContact isEdit />
                {footer()}
              </>
            )}
          </LayoutBody>
        </LayoutFullHeight>
      </AppForm>
    </PageWithAside>
  );
};

export default BusinessContactPage;
