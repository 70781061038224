import * as yup from "yup";
import { emailField } from "./validationFields";

export const InitialValues = {
  email: "",
  newEmail: "",
  password: "",
};

export const Validation = yup.object().shape({
  newEmail: emailField.required("Please enter your new email"),
  password: yup.string().required("Please enter your password"),
});

export interface IChangeEmailInputType {
  email: string;
  newEmail: string;
  password: string;
}
