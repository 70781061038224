import { BsArrowRepeat } from "react-icons/bs";
import { FaRegAngry } from "react-icons/fa";
import { MdNotInterested } from "react-icons/md";

import { DropdownAction } from "../ui/dropdown";

interface DropdownProps {
  data?: any;
}

const TableWaitingListActions: React.FC<DropdownProps> = ({ data }) => {
  const menu = [
    {
      label: "Cancel",
      icon: <MdNotInterested />,
    },
    {
      label: "Reschedule",
      icon: <BsArrowRepeat />,
    },
    {
      label: "No show",
      icon: <FaRegAngry />,
    },
  ];
  return (
    <>
      <DropdownAction menu={menu} />
    </>
  );
};

export default TableWaitingListActions;
