import { useFormikContext } from "formik";
import InputContainer from "./InputContainer";
import styles from "./AppCountryRegion.module.scss";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { DropdownSelect } from "../ui/dropdown";
import country from "../../config/option/optionCountry";

interface AppCountryRegionProps {
  name: "country" | "state";
  label?: string;
  labelBlock?: boolean;
  labelWidth?: string;
  helperMsg?: string | JSX.Element;
  disabled?: boolean;
}

interface FormikContextProps {
  [key: string]: string;
}

const AppCountryRegion: React.FC<AppCountryRegionProps> = ({
  name,
  helperMsg,
  label,
  labelWidth,
  labelBlock,
  disabled,
  // ...otherProps
}) => {
  const { setFieldValue, errors, values } =
    useFormikContext<FormikContextProps>();
  // const selected = country.find((x) => x.countryName === values[name]);

  const handleChange = (name: string, value: string) => {
    if (name === "country") {
      setFieldValue("state", "");
    }
    setFieldValue(name, value);
  };

  const countryList = country.map((item) => ({
    name: item.countryName,
    onClick: () => handleChange(name, item.countryName),
  }));

  const countryObject = values["country"]
    ? country.find((x) => x.countryName === values["country"])
    : "";
  const regionList = countryObject
    ? countryObject["regions"].map((item) => ({
        name: item.name,
        onClick: () => handleChange(name, item.name),
      }))
    : [{ name: "", onClick: () => handleChange(name, "") }];

  return (
    <InputContainer
      name={name}
      label={label}
      labelBlock={labelBlock}
      labelWidth={labelWidth}
      error={errors[name]}
      helperMsg={helperMsg}
    >
      <div className={styles["selector"]}>
        {name === "country" ? (
          <DropdownSelect
            menuList={countryList}
            // menuAlign={menuAlign}
            fullWidth
            // placeholder={placeholder}
            selected={values[name]}
            disabled={disabled}
          />
        ) : (
          <DropdownSelect
            menuList={regionList}
            // menuAlign={menuAlign}
            fullWidth
            // placeholder={placeholder}
            selected={values[name]}
            disabled={!values["country"] && true}
          />
        )}
      </div>
    </InputContainer>
  );
};

export default AppCountryRegion;
