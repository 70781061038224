import { createSlice } from "@reduxjs/toolkit";
import { DataCapacityType } from "../../config/types/CapacityType";

interface CapacityState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: Date | number;
  data: DataCapacityType;
}

const initialState: CapacityState = {
  loading: false,
  error: false,
  lastFetch: undefined,
  data: {},
};

const slice = createSlice({
  name: "capacity",
  initialState,
  reducers: {
    capacityClearState: (capacity) => {
      capacity.error = false;
    },
    capacityRequest: (capacity) => {
      capacity.loading = true;
    },
    capacityReceived: (capacity, action) => {
      console.log("capacityReceived", action.payload);
      capacity.data = action.payload.capacity;
      capacity.loading = false;
      capacity.lastFetch = Date.now();
    },
    capacityRequestFailed: (capacity, action) => {
      capacity.loading = false;
      capacity.error = action.payload;
    },
  },
});

export const {
  capacityClearState,
  capacityRequest,
  capacityReceived,
  capacityRequestFailed,
} = slice.actions;

export default slice.reducer;
