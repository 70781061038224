import { EBookingStatusValue } from "./BookingStatusType";

export enum EBookingLogType {
  Created = 10,
  Booked = 11,
  Skipped = 12,
  Approved = 20,
  Confirmed = 21,
  Accepted = 22,
  CheckedIn = 31,
  Serving = 40,
  Completed = 50,
  Ended = 51,
  Rescheduled = 52,
  Transferred = 53,
  Cancelled = 60,
  Absent = 61,
  NotAttended = 62,
  NoShown = 64,
  Rejected = 65,
}

export function bookingLog({
  bookingStatus,
  creatorName,
}: {
  bookingStatus: number;
  creatorName: string;
}) {
  if (bookingStatus === EBookingStatusValue.create)
    return {
      bookingStatus,
      creatorName,
      title: "Created",
      desc: `${creatorName} created a meetup`,
      priority: 2,
    };
  else if (
    bookingStatus === EBookingStatusValue.book ||
    bookingStatus === EBookingStatusValue.createByBusiness
  )
    return {
      bookingStatus,
      creatorName,
      title: "Booked",
      desc: `${creatorName} made a booking`,
      priority: 2,
    };
  else if (bookingStatus === 121)
    return {
      bookingStatus,
      creatorName,
      title: "Skipped",
      priority: 4,
    };
  else if (bookingStatus === 201 || bookingStatus === 202)
    return {
      bookingStatus,
      creatorName,
      title: "Approved",
      desc: `${creatorName} approved`,
      priority: 3,
    };
  else if (bookingStatus === 211)
    return {
      bookingStatus,
      creatorName,
      title: "Confirmed",
      desc: `${creatorName} confirmed attend`,
      priority: 3,
    };
  else if (bookingStatus === 301)
    return {
      bookingStatus,
      creatorName,
      title: "Checked-in",
      desc: `${creatorName} checked-in`,
      priority: 3,
    };
  else if (bookingStatus === 321)
    return {
      bookingStatus,
      creatorName,
      title: "Serving",
      desc: `Started serving`,
      priority: 2,
    };
  else if (bookingStatus === 401)
    return {
      bookingStatus,
      creatorName,
      title: "Completed",
      desc: `Service has completed`,
      priority: 2,
    };
  else if (bookingStatus === 402)
    return {
      bookingStatus,
      creatorName,
      title: "Ended",
      desc: `Service has ended`,
      priority: 3,
    };
  else if (bookingStatus === 411)
    return {
      bookingStatus,
      creatorName,
      title: "Rescheduled",
      desc: `Booking has rescheduled`,
      priority: 3,
    };
  else if (bookingStatus === 412)
    return {
      bookingStatus,
      creatorName,
      title: "Transferred",
      desc: `Booking has transferred`,
      priority: 3,
    };
  else if (bookingStatus === 501)
    return {
      bookingStatus,
      creatorName,
      title: "Cancelled",
      desc: `Booking has cancelled`,
      priority: 3,
    };
  else if (bookingStatus === 512)
    return {
      bookingStatus,
      creatorName,
      title: "Not attend",
      desc: `${creatorName} not attend the meetup`,
      priority: 3,
    };
  else if (bookingStatus === 511)
    return {
      bookingStatus,
      creatorName,
      title: "No show",
      desc: `Customer no show`,
      priority: 3,
    };
  else if (bookingStatus === 521)
    return {
      bookingStatus,
      creatorName,
      title: "Rejected",
      desc: `${creatorName} rejected`,
      priority: 3,
    };
}
