export interface ICustomDayDataType extends ICustomDayInputType {
  business: string;
  creator: string;
}

export interface ICustomDayInputType {
  _id: string;
  location: string;
  customType: ECustomDayType;
  date: number;
  month: number;
  year: number;
  businessHours: string;
  businessHoursSum: number;
  dateInString: string; // "2022-07-09"
  monthInString: string; // "2022-07"
  fullDate: Date;
  timestamp: number;
}

export enum ECustomDayType {
  BusinessHours,
  ServiceHours,
}
