import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { ICustomerDataType } from "../../config/types/CustomerDataType";
import prefixId, { idType } from "../../utils/prefixID";
import { DropdownPanel } from "../ui/dropdown";
import { Col, Row } from "../ui/Grid";
import styles from "./CreateBookingExistingCustomer.module.scss";

interface FormikContextProps {
  [key: string]: string | undefined;
}

interface ExistingCustomerProps {
  data: ICustomerDataType[];
}

const CreateBookingExistingCustomer: React.FC<ExistingCustomerProps> = ({
  data,
}) => {
  const { setValues, errors, values } = useFormikContext<FormikContextProps>();
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (values["displayName"]) setSelected(values["displayName"]);
    else setSelected("");
  }, [values]);

  const menuList = data.map((customer, index) => {
    return {
      id: customer._id,
      item: (
        <div>
          {customer.displayName}
          <div className="desc">
            {customer.pin ? (
              <>PIN: {customer.pin}</>
            ) : (
              <span className="text-italic">Unregistered user</span>
            )}
          </div>
        </div>
      ),
      name: customer.displayName,
      onClick: () => {
        setValues({
          ...customer,
          note: values["note"],
        });
      },
    };
  });
  return (
    <div className="mb-10">
      <Row>
        <Col width="90px">
          <h5 className="pt-5">Customer</h5>
        </Col>
        <Col stretch>
          <DropdownPanel
            className={styles["button"]}
            menuList={menuList}
            menuEmptyMsg="No data"
            filter="Search by name"
          >
            {selected}
          </DropdownPanel>
          {errors && errors["displayName"] && (
            <div className="desc error-msg">Please select one customer</div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CreateBookingExistingCustomer;
