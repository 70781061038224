import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import config from "../../config/global";
import styles from "./BusinessIDInput.module.scss";
import { alphanumeric } from "../../config/form/patterns";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaExternalLinkAlt,
} from "react-icons/fa";
import AppInput from "../form/AppInput";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import spinner from "../../assets/images/spinner.gif";

const name = "businessUrl";

interface InputProps {
  // bizName: string;
  labelWidth: string;
  isEdit?: boolean;
}

interface FormikContextProps {
  [key: string]: string;
}

const BusinessIDInput: React.FC<InputProps> = ({
  // bizName,
  labelWidth,
  isEdit,
}) => {
  const { checkBusinessUrl, clearSetupState } = useActions();
  const { checkURL } = useTypedSelector((state) => state.entities.setup);
  const { data: businessData } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const { setFieldValue, values, setFieldError } =
    useFormikContext<FormikContextProps>();
  const [status, setStatus] = useState<number>(0); // 1: loading, 2: failed, 3: passed
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    if (checkURL) {
      const { loading, pass, failed } = checkURL;
      if (loading) {
        setStatus(1);
        setFieldError(name, undefined);
      } else if (failed) {
        setStatus(2);
        setFieldError(name, failed);
      } else if (pass) {
        setStatus(3);
        setFieldValue(name, value);
      }
    } else setStatus(0);
  }, [checkURL]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   let bizUrl = "";
  //   const filtered = bizName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  //   if (filtered.length === 0) bizUrl = "";
  //   else if (filtered.length < 6)
  //     bizUrl = `${filtered.toLowerCase()}${generateRandomString(
  //       6 - filtered.length
  //     )}`;
  //   else bizUrl = filtered.substring(0, 12);

  //   if (bizUrl) {
  //     setValue(bizUrl);
  //     checkBusinessUrl(bizUrl);
  //   }
  // }, [bizName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlur = () => {
    const result = values[name];
    if (result.length < 6 || result.length > 12) {
      setStatus(2);
      setFieldError(name, "Business ID must be between 6 to 12 characters");
    } else if (!alphanumeric.test(result)) {
      setStatus(2);
      setFieldError(name, "Business ID must be letter or number only");
    } else {
      setFieldError(name, undefined);
      setValue(result);
      checkBusinessUrl(values[name]);
    }
  };

  const iconUpdate = () => {
    if (status === 1)
      return <img src={spinner} className={styles["loading"]} alt="loading" />;
    if (status === 2) return <FaTimesCircle className={styles["error"]} />;
    if (status === 3) return <FaCheckCircle className={styles["success"]} />;

    return null;
  };

  const helperMsg = isEdit ? (
    <>
      View your{" "}
      <a
        href={`${config.bookingUrl}${businessData?.businessUrl}`}
        target="_blank"
      >
        booking page <FaExternalLinkAlt />
      </a>
      .
    </>
  ) : (
    "Your business’s globally unique identifier, used in your booking page url, QR code, and more. You cannot change your business ID after business creation."
  );

  return (
    <div className={styles["container"]}>
      <AppInput
        name={name}
        label="Business ID"
        labelWidth={labelWidth}
        disabled={isEdit}
        readonly={isEdit ? true : false}
        helperMsg={helperMsg}
        maxCharacter={!isEdit ? 12 : undefined}
        onBlur={() => !isEdit && handleBlur()}
        onClick={() => !isEdit && clearSetupState()}
      />
      <div className={styles["icon"]}>{iconUpdate()}</div>
    </div>
  );
};

export default BusinessIDInput;
