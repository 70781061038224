import { useEffect, useState } from "react";
import styles from "./PersonalProfile.module.scss";
import { AppButton, AppInput, AppForm } from "../form";
import {
  Validation,
  InitialValues,
} from "../../config/form/formPersonalContact";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AppCountryRegion from "../form/AppCountryRegion";
import { useActions } from "../../hooks/useActions";
import { EBtnCategory } from "../form/AppButton";

const PersonalContact: React.FC = () => {
  const [initVal, setInitVal] = useState(InitialValues);
  const { savePersonalContact } = useActions();
  const { loading, data } = useTypedSelector((state) => state.entities.user);

  useEffect(() => {
    if (data) {
      console.log("personal data", data);
      setInitVal({
        ...initVal,
        city: data.city,
        country: data.country,
        postcode: data.postcode,
        state: data.state,
      });
    }
  }, [data]);

  return (
    <AppForm
      initialValues={initVal}
      validationSchema={Validation}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        // onSubmit(values);
        savePersonalContact(values);
      }}
    >
      <div className={styles["wrapper"]}>
        <AppInput name="city" label="City" />
        <AppInput name="postcode" label="Postal Code" />
        <AppCountryRegion name="state" label="State" />
        <AppCountryRegion name="country" label="Country" disabled />
      </div>
      <AppButton
        type="submit"
        category={EBtnCategory.Primary}
        loading={loading}
        className="float-left mt-20"
      >
        Save
      </AppButton>
    </AppForm>
  );
};

export default PersonalContact;
