import classNames from "classnames";
import styles from "./ApptDateTime.module.scss";
import { getDate, getDayName, getTime } from "../../utils/datetime";

export const ApptDateCalendar: React.FC<{ date: Date }> = ({ date }) => {
  return (
    <div className={classNames(styles["calendar-date"])}>
      <div className={styles["calendar-month"]}>{getDate(date, "MMM")}</div>
      <div className={styles["calendar-day"]}>{getDate(date, "DD")}</div>
    </div>
  );
};

export const ApptDate: React.FC<{ date: Date }> = ({ date }) => {
  return <div className={styles["date"]}>{getDate(date)}</div>;
};

export const ApptTime: React.FC<{ time: Date }> = ({ time }) => {
  return <div className={styles["time"]}>{getTime(time)}</div>;
};

export const ApptDay: React.FC<{ day: Date }> = ({ day }) => {
  return <div className={styles["day"]}>{getDayName(day)}</div>;
};
