import Table, { TableDataType } from "../ui/table";
import { Switch } from "../form";

import TableOnlineBookingActions from "./TableOnlineBookingActions";

interface TableProps {
  data: TableDataType;
  onToggle: (id: string, arg: boolean) => void;
}

const TableOnlineBooking: React.FC<TableProps> = ({ data, onToggle }) => {
  const columns = [
    {
      name: "label",
      colWidth: "50px",
      colMaxWidth: "60px",
      content: (item: { label: string }) => <>{item.label}</>,
    },
    {
      name: "item",
      // cellClass: styles["col-item"],
      content: (item: { name: string }) => (
        <div className="h5">{item.name}</div>
      ),
    },
    {
      name: "status",
      colWidth: "180px",
      colMaxWidth: "180px",
      content: (item: { onlineBooking: boolean }) =>
        item.onlineBooking === false && (
          <i className="desc">Online Booking Disabled</i>
        ),
    },
    {
      name: "actions",
      colWidth: "90px",
      colMaxWidth: "90px",
      content: (item: { _id: string; onlineBooking: boolean }) => (
        <Switch
          checked={item.onlineBooking}
          onChange={(arg) => onToggle(item._id, arg)}
        />
      ),
    },
    // {
    //   name: "actions",
    //   colWidth: "60px",
    //   colMaxWidth: "90px",
    //   content: () => <TableOnlineBookingActions />,
    // },
  ];
  return <Table data={data} columns={columns} noHeader className="mb-30" />;
};

export default TableOnlineBooking;
