import classNames from "classnames";
import styles from "./TimePicker.module.scss";
import { StateTime } from "./useTimePicker";

type RefType = React.RefObject<(HTMLLIElement | null)[]>;

const useMinuteList = (
  currentDisabledHour: boolean,
  time: StateTime,
  minRef: RefType | any,
  handleChange: (type: "hour" | "min" | "ampm", value: number) => void,
  minuteStep?: number,
  disabledMinutes?: number[]
) => {
  let minuteList: object[] = [];

  for (let j = 0; j < 60; j += minuteStep ? minuteStep : 1) {
    const disabled = currentDisabledHour ? disabledMinutes?.includes(j) : false;
    minuteList[j] = (
      <li
        key={j}
        ref={(el) => (minRef.current[j] = el)}
        role="button"
        className={classNames(
          disabled && styles["disabled"],
          time.minute === j && styles["selected"]
        )}
        onClick={() => (!disabled ? handleChange("min", j) : false)}
      >
        {j.toString().padStart(2, "0")}
      </li>
    );
  }

  return { minuteList };
};

export default useMinuteList;
