import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SelectOptionsType } from "../form/AppSelectPanel";

const useServiceCategory = () => {
  const { newCategory, data: servicesCategory } = useTypedSelector(
    (state) => state.entities.serviceCategory
  );

  const categoryList: SelectOptionsType[] = [];

  if (servicesCategory && servicesCategory.length > 0)
    servicesCategory.map((category) => {
      categoryList.push({
        name: category.name,
        value: category._id,
      });
    });

  const getCategoryName = (id?: string) => {
    if (!id) return undefined;
    const categoryName = servicesCategory.find((item) => item._id === id);
    return categoryName ? categoryName.name : undefined;
  };
  return { getCategoryName, categoryList };
};

export default useServiceCategory;
