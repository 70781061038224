import * as yup from "yup";
import { defaultHours, defaultHoursSum } from "../option/optionBusiness";
import { ILocationHoursType } from "../types/LocationDataType";

// Form - Business Settings
export const InitialValues = {
  hours: defaultHours,
  hoursSum: defaultHoursSum,
};

export const Validation = yup.object().shape({
  hours: yup
    .string()
    .max(1024, "Error for business hours")
    .required("Please set your business hours"),
});

export interface IBusinessHoursInputType extends ILocationHoursType {}
