import PageWithSteps from "../../components/layout/PageWithSteps";
import { registerBusiness } from "../../config/steps";
import useRegisterBusinessStep from "./useRegisterBusinessStep";
import {
  LayoutFullHeight,
  LayoutHeader,
} from "../../components/layout/LayoutFullHeight";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { businessURL } from "../../config/url";
import Loader from "../../components/ui/Loader";

const AuthRegisterBusinessPage: React.FC = () => {
  const { step, registerBusinessContent } = useRegisterBusinessStep();
  const { data, request } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );
  const navigate = useNavigate();

  useEffect(() => {
    data && navigate(businessURL.BusinessDashboard);
  }, [data]);

  if (step >= 6 && request) return <Loader height="80vh" />;

  return (
    <PageWithSteps activeStep={step} steps={registerBusiness}>
      <LayoutFullHeight>
        <LayoutHeader title="Register a business" />
        {/* {getBodyContent(steps)} */}
        {registerBusinessContent[step]}
      </LayoutFullHeight>
    </PageWithSteps>
  );
};

export default AuthRegisterBusinessPage;
