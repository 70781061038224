import * as yup from "yup";
import { IBusinessInfoType } from "../types/BusinessDataType";
import { alphanumeric } from "./patterns";

export const InitialValues = {
  name: "",
  businessUrl: "",
  industry: 0,
  about: "",
  profilePic: "",
};

export const Validation = yup.object().shape({
  name: yup
    .string()
    .min(6, "Business Name must be at least 6 characters")
    .max(30, "Business Name must not longer than 30 characters")
    .required("Please enter your Business Name"),
  businessUrl: yup
    .string()
    .min(6, "Business ID must be at least 6 characters")
    .max(12, "Business ID must not longer than 12 characters")
    .required("Please enter your Business ID")
    .matches(
      alphanumeric,
      "Business ID must be letter or number only and allow letters or numbers only"
    ),
  industry: yup
    .number()
    .min(1, "Please select your Industry")
    .required("Please select your Industry"),
  about: yup.string().max(300),
  profilePic: yup.string(),
});

export interface IBusinessInfoInputType extends IBusinessInfoType {}
