import ReadMore from "../ui/ReadMore";
import { ProfilePic } from "../ui/avatar";
import ListItem from "../ui/ListItem";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IBusinessDataType } from "../../config/types/BusinessDataType";
import LocationDisplay from "../common/LocationDisplay";

interface PersonalBookingBusinessProps {
  data: IBookingDataType;
}

const PersonalBookingBusiness: React.FC<PersonalBookingBusinessProps> = ({
  data,
}) => {
  return (
    <>
      {/* <h4 className="mb-20">Business Details</h4> */}
      <div className="mb-20">
        <ProfilePic
          role="business"
          name={data.businessData.name}
          imageSrc={data.businessData.profilePic}
        />
      </div>
      <ListItem label="Address">
        <LocationDisplay data={data.locationData} />
      </ListItem>
      {data.locationData.website && (
        <ListItem label="Website">{data.locationData.website}</ListItem>
      )}
      {data.locationData.phone && (
        <ListItem label="Phone">{data.locationData.phone}</ListItem>
      )}
      {data.locationData.email && (
        <ListItem label="Email">{data.locationData.email}</ListItem>
      )}
      {data.serviceData.onlineBookingNote && (
        <>
          <div className="desc">Note</div>
          <ReadMore charLimit={100}>
            {data.serviceData.onlineBookingNote}
          </ReadMore>
        </>
      )}
    </>
  );
};

export default PersonalBookingBusiness;
