import * as yup from "yup";
import { nameField, emailField } from "./validationFields";

export const InitialValues = {
  displayName: "",
  email: "",
};

// Validation
export const Validation = yup.object().shape({
  displayName: yup
    .string()
    .max(30, "Maximum 30 characters")
    .required("Please enter customer name"),
  email: emailField.required("Please enter customer email"),
});

export interface AddCustomerInputType {
  displayName: string;
  email: string;
}
