import { Switch } from "../form";
import Table, { TableDataType } from "../ui/table";

interface TableProps {
  data: TableDataType;
  onViewQR(id: string): void;
  onToggle: (id: string, arg: boolean) => void;
}

const TableWalkIn: React.FC<TableProps> = ({ data, onViewQR, onToggle }) => {
  const columns = [
    {
      name: "label",
      colWidth: "50px",
      colMaxWidth: "60px",
      content: (item: { label: string }) => <>{item.label}</>,
    },
    {
      name: "item",
      // cellClass: styles["col-item"],
      content: (item: { name: string }) => (
        <div className="h5">{item.name}</div>
      ),
    },
    {
      name: "status",
      colWidth: "120px",
      colMaxWidth: "150px",
      content: (item: { walkIn: boolean }) =>
        item.walkIn === false && <i className="desc">Walk-in Disabled</i>,
    },
    {
      name: "actions",
      colWidth: "90px",
      colMaxWidth: "90px",
      content: (item: { _id: string; walkIn: boolean }) => (
        <Switch
          checked={item.walkIn}
          onChange={(arg) => onToggle(item._id, arg)}
        />
      ),
    },
  ];
  return <Table data={data} columns={columns} noHeader className="mb-30" />;
};

export default TableWalkIn;
