import { useEffect, useState } from "react";

type RefType = React.RefObject<HTMLDivElement>;
export type PositionType = {
  [name: string]: string | number;
};

const useDropdown = (
  wrapperRef: RefType,
  dropdownRef: RefType,
  listRef: RefType
) => {
  const [position, setPosition] = useState<PositionType>();
  const [display, setDisplay] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | string>("90vh");
  const [containerWidth, setContainerWidth] = useState<number>();

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        event.target &&
        dropdownRef.current.contains(event.target as Node)
        // wrapperRef.current &&
        // event.target &&
        // wrapperRef.current.contains(event.target as Node)
      ) {
        // console.log("dropdown stay-----");
        return;
      }
      // console.log("dropdown close------");
      setDisplay(false);
    };
    document.addEventListener("click", listener, { capture: true });
    return () => {
      document.removeEventListener("click", listener, { capture: true });
    };
  }, []);

  const toggleDropdown = (
    pos?: "left" | "right",
    isPanel?: boolean,
    noPadding?: boolean
  ) => {
    getLocation(pos, isPanel, noPadding);
    // pos && setPosition(pos);
    display ? setDisplay(false) : setDisplay(true);
  };

  function getLocation(
    pos?: "left" | "right",
    isPanel?: boolean,
    noPadding?: boolean
  ) {
    const viewportWidth = document.body.clientWidth,
      viewportHeight = window.innerHeight,
      bodyRect = document.body.getBoundingClientRect(),
      container = wrapperRef.current!.getBoundingClientRect(),
      listContainer = listRef.current!.getBoundingClientRect(),
      containerTop = container.top,
      containerBottom = container.bottom,
      containerLeft = container.left,
      containerRight = container.right;
    console
      .log
      // "viewportHeight",
      // viewportHeight,
      // "top",
      // containerTop
      // "right",
      // container.right,
      // "bottom",
      // containerBottom
      // "left",
      // container.left,
      // "right",
      // container.right,
      // "width",
      // container.width,
      // "viewportWidth",
      // viewportWidth
      // "listContainer",
      // listContainer.width
      // "top",
      // containerTop
      ();
    setContainerWidth(container.width);
    let VPos: PositionType | null;
    let HPos: PositionType | null;
    const paddingValue = noPadding ? 0 : 5;
    // containerTop < viewportHeight / 2
    //   ? (VPos = { top: containerBottom - bodyRect.top })
    //   : (VPos = {
    //       top: containerTop - bodyRect.top - listContainer.height - 10,
    //     });

    // pos === "left" || containerLeft < viewportWidth / 2
    //   ? (HPos = { left: containerLeft })
    //   : (HPos = { right: viewportWidth - containerRight });

    if (pos === "left" || containerLeft < viewportWidth / 2) {
      // HPos = isPanel
      //   ? { left: containerLeft - paddingValue }
      //   : { left: containerLeft };
      HPos = { left: 0 };
    } else {
      // HPos = isPanel
      //   ? { right: viewportWidth - containerRight - paddingValue }
      //   : { right: viewportWidth - containerRight };
      HPos = { right: 0 };
    }

    if (containerTop < viewportHeight / 2) {
      const top = isPanel ? 0 : container.height + paddingValue;
      VPos = { top };
      // VPos = { top: container.height + paddingValue };
      setMaxHeight(viewportHeight - containerBottom - 30);
    } else {
      const bottom = isPanel ? 0 - container.height : 0 + paddingValue;
      VPos = { bottom };
      // VPos = { bottom: 0 + paddingValue };
      // console.log("max height", viewportHeight, containerTop);
      setMaxHeight(containerTop - 30);
    }

    // console.log("maxHeight", maxHeight);

    setPosition({ ...VPos, ...HPos });
  }

  return { position, toggleDropdown, display, containerWidth, maxHeight };
};

export default useDropdown;
