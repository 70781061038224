import { useState } from "react";

import iconCalendar from "../../assets/images/calendar-empty.svg";
import Tab from "../../components/ui/tab";
import TableBookingToday from "./TableBookingToday";
import ModalBooking from "../businessBooking/ModalBooking";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  getTodayCompletedBusinessBooking,
  getTodayIncompleteBusinessBooking,
  getTodayServingBusinessBooking,
} from "../../store/selector";
import EmptyData from "../ui/EmptyData";

const BusinessToday: React.FC = () => {
  const [selectedId, setSelectedId] = useState<string | null>();
  const [activeTab, setActiveTab] = useState(0); //Selected nav tab on top

  const { today } = useTypedSelector((state) => state.entities.businessBooking);
  const completedBookings: IBookingDataType[] = useTypedSelector(
    getTodayCompletedBusinessBooking
  );
  const incompleteBookings: IBookingDataType[] = useTypedSelector(
    getTodayIncompleteBusinessBooking
  );
  const servingBookings: IBookingDataType[] = useTypedSelector(
    getTodayServingBusinessBooking
  );

  if (!today || today.length === 0)
    return (
      <EmptyData title="No booking for today" size="large" image={iconCalendar}>
        <p>You do not have any booking for today</p>
      </EmptyData>
    );

  const tabItems = [
    {
      tabID: 0,
      number: incompleteBookings.length,
      name: "Incomplete",
      content: (
        <TableBookingToday
          data={incompleteBookings}
          onApptView={setSelectedId}
        />
      ),
    },
    {
      tabID: 1,
      number: servingBookings.length,
      name: "Serving",
      content: (
        <TableBookingToday data={servingBookings} onApptView={setSelectedId} />
      ),
    },
    {
      tabID: 2,
      number: completedBookings.length,
      name: "Completed",
      content: (
        <TableBookingToday
          data={completedBookings}
          onApptView={setSelectedId}
        />
      ),
    },
  ];

  return (
    <>
      <Tab
        type="horizontal"
        items={tabItems}
        onTabClick={(id: number) => setActiveTab(id)}
        active={activeTab}
      >
        {tabItems[activeTab]["content"]}
      </Tab>
      {selectedId && (
        <ModalBooking id={selectedId} onClose={() => setSelectedId(null)} />
      )}
    </>
  );
};

export default BusinessToday;
