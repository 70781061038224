import {
  AppForm,
  AppTextArea,
  AppButton,
  AppInput,
  AppSelectMenu,
} from "../form";
import { EBtnCategory } from "../form/AppButton";
import { ModalBox } from "../ui/modal";
import styles from "../businessBooking/BookingCancelDialog.module.scss";
import { providerType } from "../../config/option/optionProviders";

import { InitialValues, Validation } from "../../config/form/formProvider";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect, useState } from "react";

interface ModalAddProviderProps {
  onClose(): void;
}

const ModalAddProvider: React.FC<ModalAddProviderProps> = ({ onClose }) => {
  const { addAdminProvider, clearAdminProviderState } = useActions();
  const [initVal, setInitVal] = useState(InitialValues);
  const { success, error, loading } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const { data: locationData } = useTypedSelector(
    (state) => state.entities.location
  );
  const { getLocation } = useActions();

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (locationData.length > 0) {
      setInitVal({ ...initVal, hours: locationData[0].hours });
    }
  }, [locationData]);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (success) {
      console.log("success");
      clearAdminProviderState();
      onClose();
    } else if (error) {
      clearAdminProviderState();
      setErrorMessage(error);
    }
  }, [error, success]);

  return (
    <ModalBox onClose={onClose} type="dialog">
      <AppForm
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          addAdminProvider(values);
          actions.setSubmitting(false);
        }}
      >
        <div className={styles["wrapper"]}>
          {errorMessage && <div className="error-msg">{errorMessage}</div>}
          <h4>New Provider</h4>

          <div className={styles["content"]}>
            <AppSelectMenu
              name="profession"
              label="Profession"
              options={providerType}
              placeholder="Select a profession"
            />
            <AppInput name="name" label="Name" />
            <AppInput
              name="title"
              label="Title"
              helperMsg="This will appear under the name"
            />
            <AppTextArea
              label="Desctiption"
              name="desc"
              helperMsg="Add a short description about this provider"
              maxCharacter={300}
            />
          </div>
        </div>
        <div className={styles["footer"]}>
          <AppButton
            type="button"
            category={EBtnCategory.PrimaryOutline}
            size="small"
            onClick={() => onClose()}
          >
            Cancel
          </AppButton>

          <AppButton type="submit" category={EBtnCategory.Primary} size="small">
            Add
          </AppButton>
        </div>
      </AppForm>
    </ModalBox>
  );
};

export default ModalAddProvider;
