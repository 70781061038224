import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ModalBody, ModalBox, ModalHeader, ModalFooter } from "../ui/modal";
import { AppButton, AppForm, AppInput } from "../form";
import {
  InitialValues,
  Validation,
} from "../../config/form/formProviderDuplicate";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { EBtnCategory } from "../form/AppButton";
import { businessProviderURL, businessURL } from "../../config/url";

interface ModalDuplicateProviderProps {
  id: string;
  name: string;
  onClose(): void;
  bookingType: string;
}

const ModalDuplicateProvider: React.FC<ModalDuplicateProviderProps> = ({
  id,
  name,
  onClose,
  bookingType,
}) => {
  const { duplicateAdminProvider, clearAdminProviderState } = useActions();
  const { error, success, duplicateProvider } = useTypedSelector(
    (state) => state.entities.providerAdmin
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (duplicateProvider) {
      onClose();
      clearAdminProviderState();
      navigate(
        `${businessURL.BusinessProviders}/${duplicateProvider._id}/${businessProviderURL.Info}`
      );
    }
  }, [duplicateProvider, error, success]);
  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      size="small"
    >
      <ModalHeader title={`Duplicate ${name}`} />
      <AppForm
        className="flex-layout"
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          console.log("values", values);
          duplicateAdminProvider(values, id);
          actions.setSubmitting(false);
        }}
      >
        <ModalBody>
          <div className="px-25 pt-10 pb-5">
            <AppInput name="name" placeholder={`New ${bookingType} name`} />
            <AppInput name="title" placeholder={`New ${bookingType} title`} />
          </div>
        </ModalBody>
        <ModalFooter>
          <AppButton
            type="button"
            onClick={onClose}
            className="mr-10"
            category={EBtnCategory.Link}
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            Duplicate
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalDuplicateProvider;
