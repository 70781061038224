import { useState, useEffect } from "react";
import { SelectOptionsType } from "../form/AppSelectMenu";
import useGetBookingType from "../../hooks/useGetBookingType";

const useMeetingPointForm = () => {
  const { services, resources, providers } = useGetBookingType();
  const [optionServices, setOptionServices] = useState<SelectOptionsType[]>([]);
  const [optionResources, setOptionResources] = useState<SelectOptionsType[]>(
    []
  );
  const [optionProviders, setOptionProviders] = useState<SelectOptionsType[]>(
    []
  );

  useEffect(() => {
    if (services && services.length > 0) {
      const list: SelectOptionsType[] = [];
      services.forEach((s) => {
        list.push({ name: s.name, value: s._id });
      });
      setOptionServices(list);
    }
    if (resources && resources.length > 0) {
      const list: SelectOptionsType[] = [];
      resources.forEach((r) => {
        list.push({ name: r.name, value: r._id });
      });
      setOptionResources(list);
    }
    if (providers && providers.length > 0) {
      const list: SelectOptionsType[] = [];
      providers.forEach((p) => {
        list.push({ name: p.name, value: p._id });
      });
      setOptionProviders(list);
    }
  }, [services, providers, resources]);

  return { optionServices, optionResources, optionProviders };
};
export default useMeetingPointForm;
