import React, { useEffect, useState } from "react";
import Timeslot from "../common/hours/Timeslot";
import { convertToTimestamp, getDate, getDayName } from "../../utils/datetime";
import useBusiness from "../../hooks/useBusiness";
import styles from "./CustomBusinessHoursItem.module.scss";
import DatePicker from "../ui/DatePicker";
import { ICustomDayInputType } from "../../config/types/CustomDayDataType";
import { RiDeleteBinLine } from "react-icons/ri";
import { defaultHour } from "../../config/option/optionBusiness";

// const TOMORROW = new Date();
// TOMORROW.setDate(TOMORROW.getDate() + 1);

interface ICustomBusinessHoursItemProps {
  data: ICustomDayInputType;
  isNew?: boolean;
  error?: string;
  // onChangeHours(hours: string): void;
  onChange(data: ICustomDayInputType): void;
  onChangeDate(id: string, date: string): void;
  onRemove(): void;
}

const CustomBusinessHoursItem: React.FC<ICustomBusinessHoursItemProps> = ({
  data,
  isNew = false,
  error,
  // onChangeHours,
  onChange,
  onChangeDate,
  onRemove,
}) => {
  const { displayBusinessHours } = useBusiness();
  const [isEdit, setIsEdit] = useState(false);
  const [hours, setHours] = useState(defaultHour);
  const { calculateHoursSum } = useBusiness();

  useEffect(() => {
    data.businessHours && setHours(JSON.parse(data.businessHours));
  }, [data]);

  const handleChangeHours = ({
    currentTarget,
  }: {
    currentTarget: { name: string; value: string[] };
  }) => {
    console.log("name", currentTarget.name, "value", currentTarget.value);
    const hoursValue = currentTarget.value;
    setHours(hoursValue);
    // Calculate sum of hours
    const businessHoursSum = calculateHoursSum(hoursValue);
    console.log("hoursSum", businessHoursSum);
    onChange({
      ...data,
      businessHours: JSON.stringify(hoursValue),
      businessHoursSum,
    });
  };

  const handleChangeDate = (date: Date | Date[]) => {
    if (date instanceof Date) {
      const dateInString = getDate(date, "YYYY MM DD", true);
      console.log(dateInString);
      onChangeDate(data._id, dateInString);
      onChange({
        ...data,
        date: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        dateInString,
        monthInString: getDate(date, "YYYY MM", true),
        fullDate: date,
        timestamp: Date.parse(dateInString),
      });
    }
  };

  if (isNew || !data.fullDate)
    return (
      <>
        <div className={styles["container"]}>
          <Timeslot name="0" value={hours} onChange={handleChangeHours}>
            <div className={styles["label"]}>
              <DatePicker
                onChange={handleChangeDate}
                value={data.fullDate}
                minDate={new Date()}
              />
            </div>
          </Timeslot>
          <div className={styles["remove"]}>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => onRemove()}
            >
              <RiDeleteBinLine />
            </button>
          </div>
        </div>
        {error && <div className="mt-5 desc error-msg">{error}</div>}
      </>
    );

  if (isEdit)
    return (
      <div className={styles["container"]}>
        <Timeslot name="0" value={hours} onChange={handleChangeHours}>
          <div className={styles["label"]}>
            <div className="text-semibold">{getDate(data.fullDate)}</div>
            <div className="desc">{getDayName(data.fullDate)}</div>
          </div>
        </Timeslot>
        <div className={styles["remove"]}>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => onRemove()}
          >
            <RiDeleteBinLine />
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles["container"]}>
      <div className={styles["slot-display"]}>
        <div className={styles["label"]}>
          <div className="text-semibold">{getDate(data.fullDate)}</div>
          <div className="desc">{getDayName(data.fullDate)}</div>
        </div>
        <div className={styles["timeslot"]}>{displayBusinessHours(hours)}</div>
        <div className={styles["action"]}>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsEdit(true)}
          >
            Edit
          </button>
        </div>
      </div>
      <div className={styles["remove"]}>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => onRemove()}
        >
          <RiDeleteBinLine />
        </button>
      </div>
    </div>
  );
};

export default CustomBusinessHoursItem;
