import React from "react";
import { DataCapacityType } from "../../config/types/CapacityType";
import styles from "./TimeslotPicker.module.scss";
import useTimeslotPicker from "./useTimeslotPicker";

interface TimeslotPickerProps {
  interval: number;
  date?: string;
  value?: Date;
  timeRange: string;
  capacity: number;
  onChange: (val: Date) => void;
  dataCapacity?: DataCapacityType;
}

const TimeslotPicker: React.FC<TimeslotPickerProps> = ({
  timeRange,
  date = "2020-01-01",
  value,
  interval,
  capacity,
  onChange,
  dataCapacity,
}) => {
  const { generateList } = useTimeslotPicker(
    timeRange,
    date,
    capacity,
    interval,
    dataCapacity
  );

  return (
    <div className={styles["list"]}>
      <ul>{generateList(onChange, value)}</ul>
    </div>
  );
};

export default TimeslotPicker;
