import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ModalBody, ModalBox, ModalHeader, ModalFooter } from "../ui/modal";
import { AppButton, AppForm, AppInput, AppSelectMenu } from "../form";
import {
  InitialValues,
  Validation,
} from "../../config/form/formServiceDuplicate";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { businessURL } from "../../config/url";
import useBookingLabel from "../../hooks/useBookingLabel";
import { EBtnCategory } from "../form/AppButton";

interface ModalServiceDuplicateProps {
  id: string;
  name: string;
  onClose(): void;
}

const ModalServiceDuplicate: React.FC<ModalServiceDuplicateProps> = ({
  id,
  name,
  onClose,
}) => {
  const { duplicateAdminService, clearAdminServiceState } = useActions();
  const { error, duplicateService } = useTypedSelector(
    (state) => state.entities.serviceAdmin
  );
  const navigate = useNavigate();
  const { labelOptions } = useBookingLabel();
  useEffect(() => {
    if (duplicateService) {
      onClose();
      clearAdminServiceState();
      navigate(
        `${businessURL.BusinessServices}/${duplicateService._id}/overview`
      );
    }
  }, [error, duplicateService]);
  return (
    <ModalBox
      onClose={onClose}
      type="personal"
      overlayClose={true}
      size="small"
    >
      <ModalHeader title={`Duplicate ${name}`} />
      <AppForm
        // className="flex-layout"
        initialValues={InitialValues}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          console.log("values", values);
          actions.setSubmitting(false);
          duplicateAdminService(values, id);
        }}
      >
        {/* <ModalBody> */}
        <div className="px-25 pt-10 pb-5">
          <AppInput name="name" placeholder="New serive name" />
          <AppSelectMenu
            name="label"
            helperMsg="Label will appear in front of the token number. E.g: A001"
            options={labelOptions}
          />
        </div>
        {/* </ModalBody> */}
        <ModalFooter>
          <AppButton
            type="button"
            onClick={onClose}
            className="mr-10"
            category={EBtnCategory.Link}
          >
            Cancel
          </AppButton>
          <AppButton type="submit" className="btn btn-primary">
            Duplicate
          </AppButton>
        </ModalFooter>
      </AppForm>
    </ModalBox>
  );
};

export default ModalServiceDuplicate;
