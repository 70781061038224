// Page for display
import styles from "./PageDisplay.module.scss";

interface PageProps {
  title?: string;
}

const PageDisplay: React.FC<PageProps> = ({ children, title }) => {
  return (
    <div className={styles["outer"]}>
      <header className={styles["header"]}>{title}</header>
      <div className={styles["container"]}>
        <div className={styles["content"]}>{children}</div>
      </div>
    </div>
  );
};

export default PageDisplay;
