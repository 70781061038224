import { AppSwitch, AppDurationHour } from "../form";
import styles from "./CustomDetails.module.scss";
import AppButton from "../form/AppButton";
import { AppInput } from "../form";

interface InputProps {
  title: string;
}

const CustomDetails: React.FC<InputProps> = ({ title }) => {
  return (
    <div className={styles["custom-edit-container"]}>
      <h4>{title}</h4>
      <AppSwitch label="Holiday" name="holiday" />
      <AppInput
        type="number"
        label="Capacity"
        name="capacity"
        inputWidth="100px"
      />
      <AppDurationHour label="Service Hour" name="servicePeriod" />
      <div className={styles["button"]}>
        <AppButton className="btn btn-primary">Save</AppButton>
      </div>
    </div>
  );
};

export default CustomDetails;
