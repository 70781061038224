import { useEffect, useState } from "react";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { AppInput, AppDuration } from "../form";
import { Col, Row } from "../ui/Grid";
import Guideline from "../ui/Guideline";

interface ServiceBookingSlotProps {
  service?: IServiceDataType;
}

const labelWidth = "120px";
const ServiceBookingSlot: React.FC<ServiceBookingSlotProps> = ({ service }) => {
  const [numberOfProviders, setNumberOfProviders] = useState<number>();

  useEffect(() => {
    service?.providers &&
      service.providers.length > 0 &&
      setNumberOfProviders(service.providers.length);
  }, [service]);

  console.log("numberOfProviders", numberOfProviders);

  return (
    <>
      <h3 className="mb-3">Booking Slot</h3>
      <div className="desc mb-20">
        Set how frequent the service be available for booking.
      </div>
      <Row>
        <Col width="60%">
          <AppDuration
            name="duration"
            label="Duration"
            labelWidth={labelWidth}
            // checkBoxLabel="Unlimited"
            // checkBoxValue={-1}
            helperMsg="Determine how long the service end"
          />
          <AppDuration
            name="slotInterval"
            label="Slot Interval"
            labelWidth={labelWidth}
            // checkBoxLabel="All-day"
            // checkBoxValue={-1}
            helperMsg="Determine the time between each slot"
          />
          {numberOfProviders ? (
            <AppInput
              name="bookingCapacity"
              type="number"
              label="Booking Capacity"
              labelWidth={labelWidth}
              inputWidth="120px"
              helperMsg="The booking capacity will be based on the number of providers"
              suffix="per slot"
              disabled={true}
              defaultValue={numberOfProviders}
            />
          ) : (
            <AppInput
              name="bookingCapacity"
              type="number"
              label="Booking Capacity"
              labelWidth={labelWidth}
              inputWidth="120px"
              helperMsg="Determine the number of booking to handle in each slot"
              suffix="per slot"
            />
          )}
          {/* <AppInput
            name="maxSlotSelection"
            type="number"
            label="Slot Selection"
            labelWidth={labelWidth}
            inputWidth="100px"
            helperMsg="Set maximum number of slots can a customer book continuously."
          /> */}
          {/* <div className="mt-20">
            This service can take maximum <b>30</b> bookings per day
          </div> */}
        </Col>
        <Col width="30%">
          <Guideline title="Booking slot">
            <p>
              Booking slot will determine the number of booking you can take.
            </p>
            <p>
              <b>Duration</b> will determine how long the service be completed.
              Select <b>Unlimited</b> if you want to end the service manually.
            </p>
            <p>
              <b>Slot interval</b> will determine the time between each slot.
              Eg: a 15 minute of slot intervals will appear as 1:15, 1:30, 1:45
              in booking page.
            </p>
            <p>
              Each slot can have a maximum number of booking (<b>capacity</b>).
            </p>
            {/* <p>
              You can allow a customer to select more than one slot (
              <b>Slot Selection</b>).
            </p> */}
          </Guideline>
        </Col>
      </Row>
    </>
  );
};

export default ServiceBookingSlot;
