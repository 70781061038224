import { useState } from "react";
import { AppButton } from "../../components/form";
import ServiceAmount from "../../components/services/ServiceAmount";
import { LayoutFooter } from "../../components/layout/LayoutFullHeight";
import ServiceCancelSetupDialog from "../../components/services/ServiceCancelSetupDialog";
import { EBtnCategory } from "../../components/form/AppButton";

interface CreateServiceFooterProps {
  step: number;
  totalStep: number;
}

const CreateServiceFooter: React.FC<CreateServiceFooterProps> = ({
  step,
  totalStep,
}) => {
  const [dialog, setDialog] = useState(false);
  return (
    <>
      <LayoutFooter>
        <div className="float-left">
          <ServiceAmount />
        </div>
        <div className="float-right">
          <button
            type="button"
            className="btn btn-primary-outline mr-10"
            onClick={() => setDialog(true)}
          >
            Cancel
          </button>
          <AppButton category={EBtnCategory.Primary} type="submit">
            Continue (step {step + 1} of {totalStep})
          </AppButton>
        </div>
      </LayoutFooter>
      {dialog && (
        <ServiceCancelSetupDialog setVisible={(arg) => setDialog(arg)} />
      )}
    </>
  );
};

export default CreateServiceFooter;
