import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import styles from "./BusinessProfile.module.scss";
import { AppInput, AppSelectMenu, AppTextArea } from "../form";
import { ProfilePic } from "../ui/avatar";
import Guideline from "../ui/Guideline";
import { Col, Row } from "../ui/Grid";
import useToast from "../ui/toasts/useToast";
import { industry } from "../../config/option/optionBusiness";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import BusinessIDInput from "./BusinessIDInput";
import { useActions } from "../../hooks/useActions";
import config from "../../config/global";
import AppCoverPhoto from "../form/AppCoverPhoto";

const labelWidth = "150px";

interface BusinessInfoProps {
  isEdit?: boolean;
}

interface FormikContextProps {
  [key: string]: string;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({ isEdit }) => {
  const { uploadBusinessLogo, uploadBusinessCover, clearBusinessState } =
    useActions();
  const { data, logoUpload, coverUpload } = useTypedSelector(
    (state) => state.entities.businessAdmin
  );

  // const { values } = useFormikContext<FormikContextProps>();
  // const [bizName, setBizName] = useState("");
  const { notify } = useToast();

  useEffect(() => {
    if (logoUpload?.success) {
      notify("success", "Logo has been updated");
      clearBusinessState();
    }
    if (logoUpload?.error) {
      notify("error", logoUpload.error);
      clearBusinessState();
    }
  }, [logoUpload]);

  if (!data) return null;

  return (
    <Row>
      <Col width="60%">
        <div className={styles["wrapper"]}>
          {isEdit && (
            <div className="mb-10">
              <ProfilePic
                role="personal"
                name={data.name}
                uploadLabel="Change Logo"
                imageSrc={data.profilePic}
                uploaded={logoUpload?.success}
                onUploadPicture={uploadBusinessLogo}
              />
            </div>
          )}
          <AppInput
            name="name"
            label="Business Name"
            labelWidth={labelWidth}
            // onBlur={() => !isEdit && setBizName(values["name"])}
            maxCharacter={30}
          />
          <BusinessIDInput
            isEdit={isEdit}
            labelWidth={labelWidth}
            // bizName={bizName}
          />
          <AppSelectMenu
            name="industry"
            label="Industry"
            options={industry}
            labelWidth={labelWidth}
            placeholder="Select an industry"
          />
          <AppCoverPhoto
            name="cover"
            label="Cover Photo"
            uploadLabel="Change Cover"
            labelWidth={labelWidth}
            imageSrc={data.coverPhoto}
            uploaded={coverUpload?.success}
            onUploadPicture={uploadBusinessCover}
          />
          <AppTextArea
            name="about"
            label="About Business"
            helperMsg="Tell your customer about your business"
            labelWidth={labelWidth}
            maxCharacter={300}
          />
        </div>
      </Col>
      <Col width="30%">
        <Guideline title="Business Info">
          <p>Provide some information about your business.</p>
          <p>The information will be displayed on your booking page.</p>
          <p>
            The <b>Business ID</b> is a globally unique identifier, used in your
            booking page url, QR code, and more. You cannot change your business
            ID after business creation.
          </p>
        </Guideline>
      </Col>
    </Row>
  );
};

export default BusinessInfo;
