import styles from "./Guideline.module.scss";
import { FaRegLightbulb } from "react-icons/fa";

interface HintProps {
  title: string;
}

const Guideline: React.FC<HintProps> = ({ title, children }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["icon"]}>
        <FaRegLightbulb />
      </div>
      <h4 className={styles["title"]}>{title}</h4>
      <div className={styles["message"]}>{children}</div>
    </div>
  );
};

export default Guideline;
