import { IProviderDataType } from "../../config/types/ProviderDataType";
import { ResourceType } from "../../config/types/ResourceType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import Table, { TableDataType } from "../ui/table";
import TableMeetingPointActions from "./TableMeetingPointActions";
import useMeetingPoint from "./useMeetingPoint";

interface TableMeetingPointProps {
  data: TableDataType;
  onView(id: string): void;
}

const TableMeetingPoint: React.FC<TableMeetingPointProps> = ({
  data,
  onView,
}) => {
  const { getMeetingPointName } = useMeetingPoint();

  const columns = [
    {
      label: "Meeting Point",
      name: "meetingPoint",
      colWidth: "25%",
      colMaxWidth: "25%",
      content: (tools: { type: number; label: string }) => (
        <div className="h5">
          {getMeetingPointName(tools.type)} {tools.label}
        </div>
      ),
    },
    {
      label: "Preset for",
      name: "services",
      content: (tools: {
        service: IServiceDataType;
        resource: ResourceType;
        provider: IProviderDataType;
      }) => (
        <>
          {tools.service && (
            <div>
              <span className="text-secondary">Service:</span>{" "}
              {tools.service.name}
            </div>
          )}
          {tools.resource && (
            <div>
              <span className="text-secondary">Resource:</span>{" "}
              {tools.resource.name}
            </div>
          )}
          {tools.provider && (
            <div>
              <span className="text-secondary">Provider:</span>{" "}
              {tools.provider.name}
            </div>
          )}
        </>
      ),
    },
    {
      name: "actions",
      colWidth: "60px",
      colMaxWidth: "90px",
      content: (tools: { _id: string }) => (
        <TableMeetingPointActions id={tools._id} />
      ),
    },
  ];

  if (!data || data.length === 0) return <h4>No Meeting Point</h4>;
  return (
    <>
      <Table
        data={data}
        columns={columns}
        // className={styles["table"]}
        itemClick={(tools: { _id: string }) => onView(tools._id)}
      />
    </>
  );
};

export default TableMeetingPoint;
