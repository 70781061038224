import { IBusinessNotificationDataType } from "../../../config/types/BusinessNotificationDataType";
import { EBusinessNotificationType } from "../../../config/types/NotificationType";

const useBusinessNotificationMessage = () => {
  const getBusinessNotificationMessage = (
    data: IBusinessNotificationDataType
  ) => {
    const notificationMessage = (type: number) =>
      ({
        [EBusinessNotificationType.newBooking]: (
          <>
            You have a new booking with{" "}
            <span className="text-semibold">{data.userFromName}</span>
          </>
        ),
        [EBusinessNotificationType.newWalkIn]: (
          <>
            You have a new booking with{" "}
            <span className="text-semibold">{data.userFromName}</span>
          </>
        ),
      }[type]);
    return notificationMessage(data.notificationType);
  };

  return { getBusinessNotificationMessage };
};

export default useBusinessNotificationMessage;
