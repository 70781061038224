import { getOneBooking } from "./dataAppts";
import { getMeetingPoint } from "./dataMeetingPoint";

const serving = [
  {
    _id: "serving1",
    entityId: "service1",
    queueNo: 8080,
    booking: getOneBooking("booking1"),
    meetingPoint: getMeetingPoint("meeting1"),
  },
  {
    _id: "serving2",
    entityId: "service2",
    queueNo: 8081,
    booking: getOneBooking("booking2"),
    meetingPoint: getMeetingPoint("meeting2"),
  },
  {
    _id: "serving3",
    entityId: "service3",
    queueNo: 8082,
    booking: getOneBooking("booking3"),
    meetingPoint: getMeetingPoint("meeting3"),
  },
];

export function getServings() {
  return serving;
}
