export interface IDateTimeData {
  year: number;
  month: number;
  date: number;
  day: number;
  hour: number;
  minute: number;
  // timestamp: number;
}

interface CreatorData {
  displayName: string;
  email: string;
  profilePic?: string;
}

interface TimelineData {
  bookingStatus: number;
  creator: string;
  creatorName: string;
  date: Date;
  priority: number;
  subject: string;
  desc: string;
}

export enum EApptType {
  MEETUP = "meetup",
  BOOKING = "booking",
  HOST = "meetup-host",
}

export interface IApptDataType {
  _id: string;
  access: string[];
  meetDate: string;
  meetMonth: string;
  meetDateTime: Date;
  meetDateTimestamp: number;
  meetDateTimeData: IDateTimeData;
  createDateTime: Date;
  createDateTimestamp: number;
  createDateTimeData: IDateTimeData;
  bookingType: number;
  bookingStatus: number;
  creator: string;
  creatorData: CreatorData;
  unregisteredUser: string[];
  timeline: TimelineData[];
  note: string;
  apptType: EApptType;
}
