import Table, { TableDataType } from "../ui/table";
import styles from "./TableRecord.module.scss";
import { ApptDate, ApptTime } from "../appointment/ApptDateTime";
import { ApptStatus, ApptStatusProps } from "../appointment/ApptStatus";
import { EApptType } from "../../config/types/ApptDataType";

interface TableRecordProps {
  data: TableDataType;
}

const TableRecord: React.FC<TableRecordProps> = ({ data }) => {
  const columns = [
    {
      name: "date",
      colWidth: "25%",
      cellClass: styles["col-date"],
      content: (appt: { meetDateTime: Date }) => (
        <>
          <ApptDate date={appt.meetDateTime} />
          <div className="desc">
            <ApptTime time={appt.meetDateTime} />
          </div>
        </>
      ),
    },
    {
      name: "services",
      colWidth: "50%",
      cellClass: styles["col-services"],
      content: (appt: { serviceData: { name: string }; subject: string }) => (
        <div className="h5">
          {appt.serviceData ? appt.serviceData.name : appt.subject}
        </div>
      ),
    },
    {
      name: "status",
      colWidth: "25%",
      cellClass: styles["col-status"],
      content: (appt: {
        bookingStatus: ApptStatusProps["statusNo"];
        meetDateTime: ApptStatusProps["dateTime"];
        meetDateTimestamp: number;
        apptType: EApptType;
      }) => {
        if (appt.apptType !== "meetup")
          return (
            <ApptStatus
              statusNo={appt.bookingStatus}
              dateTime={appt.meetDateTime}
              timestamp={appt.meetDateTimestamp}
            />
          );
      },
    },
  ];
  console.log("record", data);
  if (!data || data.length === 0)
    return (
      <p>
        <i>No record</i>
      </p>
    );
  return (
    <>
      {/* <div className="desc pb-10 separator">Upcoming</div> */}
      <Table data={data} columns={columns} noHeader />
    </>
  );
};

export default TableRecord;
