import { useState } from "react";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { ResourceType } from "../../config/types/ResourceType";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import TableWalkInRegistration from "./TableWalkInRegistration";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ModalWalkInReg from "./ModalWalkInReg";
import { useActions } from "../../hooks/useActions";

const BusinessWalkInReg: React.FC = () => {
  const { data } = useTypedSelector((state) => state.entities.walkIn);
  const { registerWalkIn } = useActions();
  const [selected, setSelected] = useState<
    IServiceDataType | IProviderDataType | ResourceType | null
  >();
  const services = data.services;
  const resources = data.resources;
  const providers = data.providers;

  if (services.length === 0 && resources.length === 0 && providers.length === 0)
    return <div>No walk-in</div>;

  const handleClick = (id: string, type: string) => {
    console.log(id, type);
    if (type === "Services" && services) {
      const service = services.find((m) => m._id === id);
      if (service) {
        setSelected(service);
        registerWalkIn({ service });
      }
    } else if (type === "Resources" && resources) {
      const resource = resources.find((m) => m._id === id);
      if (resource) {
        setSelected(resource);
        registerWalkIn({ resource });
      }
    } else if (type === "Providers" && providers) {
      const provider = providers.find((m) => m._id === id);
      if (provider) {
        // setSelected(provider);
        registerWalkIn({ provider });
      }
    }
  };
  return (
    <>
      {services && (
        <TableWalkInRegistration
          data={services}
          onView={handleClick}
          type="Services"
        />
      )}
      {resources && (
        <TableWalkInRegistration
          data={resources}
          onView={handleClick}
          type="Resources"
        />
      )}
      {providers && (
        <TableWalkInRegistration
          data={providers}
          onView={handleClick}
          type="Providers"
        />
      )}
      {selected && (
        <ModalWalkInReg data={selected} onClose={() => setSelected(null)} />
      )}
    </>
  );
};

export default BusinessWalkInReg;
