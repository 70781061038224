import { getOneBooking } from "./dataAppts";
import { getBusiness } from "./dataBusinesses";

const invitations = [
  {
    active: 1, // 0: delete. 1: invite. 2: approve. 3: reject
    _id: "invitation1",
    business: getBusiness("business1"),
    email: "new@user.com",
    hostName: "User first name",
    host: "user1",
    invitationType: 11, // Staff
    invitationName: "Gangnam style salon",
    // user: getUser("user2"),
    expired: new Date(),
  },
  {
    _id: "invitation2",
    business: getBusiness("business2"),
    email: "second@user.com",
    hostName: "User second name",
    host: "user2",
    invitationType: 11, // Staff
    invitationName: "Klinik Tan",
    // user: getUser("user1"),
    expired: new Date(),
  },
  {
    _id: "invitation3",
    booking: getOneBooking("booking9"),
    email: "third@user.com",
    hostName: "User third name",
    host: "user2",
    invitationType: 1, // Personal Meetup
    invitationName: "Lunch catchup",
    // user: getUser("user1"),
    expired: new Date(),
  },
];

export function getInvitations() {
  return invitations;
}

export function getInvitation(id) {
  const invitation = invitations.find((m) => m._id === id);
  return invitation ? invitation : invitations[0];
}
