import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../ui/Loader";
import Calendar from "../ui/calendar";
import CreateBookingTime from "./CreateBookingTime";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import { Col, Row } from "../ui/Grid";
import CreateBookingTitle from "./CreateBookingTitle";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { ESteps } from "./useCreateBooking";

interface CreateBookingDateTimeProps {
  onNext(date: Date, stepTo?: number): void;
  onBack(stepTo?: number): void;
  service: IServiceDataType;
  provider?: IProviderDataType;
}

const CreateBookingDateTime: React.FC<CreateBookingDateTimeProps> = ({
  service,
  provider,
  onNext,
  onBack,
}) => {
  const [selectedDate, setSelectedDate] = useState<number>(); // timestamp
  const { getBusinessHoursCustomDay } = useActions();
  const { request, customDay } = useTypedSelector(
    (state) => state.entities.location
  );
  const hours = provider ? provider.hours : service.servicePeriod;
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);

  useEffect(() => {
    getBusinessHoursCustomDay();
  }, []);
  if (request) return <Loader />;
  return (
    <>
      <CreateBookingTitle
        title="Select Date &amp; Time"
        onBack={() => (provider ? onBack() : onBack(ESteps.SelectService))}
      />
      <Row>
        <Col width="380px">
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate ? new Date(selectedDate) : undefined}
            type="mini"
            businessHours={hours}
            customBusinessHours={customDay}
            onSelectDate={(val) => setSelectedDate(val)}
          />
        </Col>
        <Col stretch>
          {selectedDate && (
            <CreateBookingTime
              selectedDate={selectedDate}
              onBook={(date) =>
                service.onsiteService
                  ? onNext(date)
                  : onNext(date, ESteps.UserInfo)
              }
              selectedService={service}
              selectedProvider={provider}
              customBusinessHours={customDay}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CreateBookingDateTime;
