import { useNavigate } from "react-router-dom";
import DialogBox from "../ui/DialogBox";
import { businessURL } from "../../config/url";
import { useActions } from "../../hooks/useActions";

interface ServiceCancelSetupDialogProps {
  setVisible(arg: boolean): void;
}

const ServiceCancelSetupDialog: React.FC<ServiceCancelSetupDialogProps> = ({
  setVisible,
}) => {
  const navigate = useNavigate();
  const handleConfirm = {
    label: "Yes",
    onClick: () => {
      setVisible(false);
      navigate(businessURL.BusinessServices);
    },
  };

  return (
    <DialogBox
      title="Are you sure you want to cancel setup?"
      message="This service has not been created yet"
      confirm={handleConfirm}
      cancel="No"
      onClose={() => setVisible(false)}
    />
  );
};

export default ServiceCancelSetupDialog;
