import { useState } from "react";
import { Link } from "react-router-dom";
import { IBookingDataType } from "../../config/types/BookingDataType";
import { IMeetupDataType } from "../../config/types/MeetupDataType";
import HomeUpcomingTable from "./HomeUpcomingTable";
import ModalPersonalBooking from "../personalBooking/ModalPersonalBooking";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Loader from "../../components/ui/Loader";
import EmptyData from "../ui/EmptyData";
import { personalURL } from "../../config/url";
import iconCalendar from "../../assets/images/calendar.svg";
import ModalMakeBooking from "../personal/ModalMakeBooking";
import { EBookingStatusValue } from "../../config/types/BookingStatusType";

const HomeUpcoming: React.FC = () => {
  const [selected, setSelected] = useState<string | null>();
  const [modalVisible, setModalVisible] = useState(false);
  const { upcoming, request } = useTypedSelector(
    (state) => state.entities.personalBooking
  );

  const filterData = () => {
    let filtered: (IMeetupDataType & IBookingDataType)[] = [];
    if (upcoming && upcoming.data.length > 0) {
      upcoming.data.forEach((appt) => {
        if (appt.bookingStatus < EBookingStatusValue.cancel) {
          if (appt.userStatus) {
            if (appt.userStatus < EBookingStatusValue.cancel) {
              filtered.push(appt);
            }
          } else filtered.push(appt);
        }
      });
    }

    return { filtered };
  };

  // const handleUpcomingView = (id: string) => {
  //   if (upcoming && upcoming.data) {
  //     const upcomingBooking = upcoming.data.find((appt) => appt._id === id);
  //     upcomingBooking && setSelected(upcomingBooking);
  //   }
  // };
  if (request?.loading) return <Loader height="300px" alignCenter />;

  const { filtered } = filterData();

  console.log("filtered", filtered);

  if (filtered.length === 0)
    return (
      <>
        <EmptyData title="No Upcoming Booking" image={iconCalendar}>
          <p>
            Schedule a personal{" "}
            <Link className="link" to={personalURL.CreateMeetup}>
              meet up
            </Link>{" "}
            with friends
            {/* or{" "}
            <button
              className="btn-link link"
              onClick={() => setModalVisible(true)}
            >
              book a service
            </button>{" "}
            with service providers */}
          </p>
        </EmptyData>
        {modalVisible && (
          <ModalMakeBooking onClose={() => setModalVisible(false)} />
        )}
      </>
    );

  return (
    <>
      <HomeUpcomingTable
        data={upcoming?.data}
        onApptView={(id) => setSelected(id)}
      />
      {selected && (
        <ModalPersonalBooking
          onClose={() => setSelected(null)}
          // data={selected}
          id={selected}
        />
      )}
    </>
  );
};

export default HomeUpcoming;
