import styles from "./DropdownSelect.module.scss";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";
import Dropdown from "./Dropdown";
import DropdownItemList, { DropdownItemListType } from "./DropdownItemList";

interface DropdownSelectProps {
  menuList: DropdownItemListType[];
  selected?: string;
  className?: string;
  fullWidth?: boolean;
  menuAlign?: "left" | "right";
  labelWidth?: number;
  placeholder?: string;
  type?: "menu";
  disabled?: boolean;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  menuList,
  selected,
  className,
  menuAlign,
  labelWidth,
  placeholder,
  type,
  disabled,
}) => {
  return (
    <Dropdown
      menu={<DropdownItemList menuList={menuList} selected={selected} />}
      className={classNames(
        className && className,
        styles["button"],
        disabled && styles["disabled"]
      )}
      fullWidth
      menuAlign={menuAlign}
      labelWidth={labelWidth}
      disabled={disabled}
    >
      {selected ? (
        <span
          className={classNames(
            styles["label"],
            "text-wrap",
            type === "menu" && styles["menu-label"]
          )}
        >
          {selected}
        </span>
      ) : (
        placeholder && (
          <span className={classNames(styles["placeholder"], "text-wrap")}>
            {placeholder}
          </span>
        )
      )}
      <span className={styles["arrow-icon"]}>
        <FaChevronDown />
      </span>
    </Dropdown>
  );
};

export default DropdownSelect;
