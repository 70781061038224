import React, { useEffect, useState } from "react";
import { AppButton, AppDisplay, AppForm, AppInput } from "../form";
import { Col, Row } from "../ui/Grid";
import { IServiceDataType } from "../../config/types/ServiceDataType";
import {
  InitialValues,
  Validation,
  IOnsiteServiceInputType,
} from "../../config/form/formOnsiteService";
import CreateBookingDetails from "./CreateBookingDetails";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CreateBookingTitle from "./CreateBookingTitle";
import AppCountryRegion from "../form/AppCountryRegion";
import { IProviderDataType } from "../../config/types/ProviderDataType";

interface CreateBookingLocationProps {
  onNext(data?: IOnsiteServiceInputType): void;
  onBack(): void;
  service: IServiceDataType;
  provider?: IProviderDataType;
  dateTime: Date;
}

const CreateBookingLocation: React.FC<CreateBookingLocationProps> = ({
  onNext,
  onBack,
  service,
  provider,
  dateTime,
}) => {
  const [initVal, setInitVal] = useState(InitialValues);
  const { data: user } = useTypedSelector((state) => state.entities.user);

  useEffect(() => {
    if (user) {
      setInitVal({
        ...initVal,
        country: user.country,
      });
    }
  }, [user]);

  return (
    <>
      <CreateBookingTitle title="Service Location" onBack={onBack} />
      <AppForm
        initialValues={initVal}
        validationSchema={Validation}
        onSubmit={(values) => onNext(values)}
      >
        <Row>
          <Col width="50%">
            <AppInput
              name="address"
              label="Address"
              placeholder="Address Line 1"
            />
            <AppInput name="address2" label=" " placeholder="Address Line 2" />
            <AppInput name="city" label="City" />
            <AppInput name="postcode" label="Postal Code" />
            <AppCountryRegion name="state" label="State" />
            <AppDisplay name="country" label="Country" />
          </Col>
          <Col width="42%">
            <CreateBookingDetails
              service={service}
              dateTime={dateTime}
              provider={provider}
            />
            <div className="float-right mt-10">
              <AppButton className="btn btn-primary" size="large" type="submit">
                Next
              </AppButton>
            </div>
          </Col>
        </Row>
      </AppForm>
    </>
  );
};

export default CreateBookingLocation;
