import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import api from "./middleware/api";
import bookingMiddleware from "./middleware/bookingMiddleware";
// import logger from "./middleware/logger";
// import func from "./middleware/func";

// export default function () {
//   return configureStore({
//     reducer,
//     // middleware: [
//     //   ...getDefaultMiddleware({ serializableCheck: false }),
//     //   logger({ destination: "console" }),
//     //   func,
//     //   // auth,
//     //   // appt,
//     // ],
//   });
// }

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      // }).concat([api]),
    }).concat([api, bookingMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
