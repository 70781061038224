import * as reducers from "../reducer/tokenReducer";
import { ITokenValidateInputType } from "../../config/form/formResetPwd";
import { apiCallBegan, IapiCallBegan } from "../middleware/api";

interface DispatchType {
  type: string;
  payload?: IapiCallBegan | string;
}

type TCreateTokenType = {
  email: string;
  tokenType: number;
};

const url = "/token";

export const clearTokenState =
  () => (dispatch: (arg: DispatchType) => void) => {
    return dispatch({ type: reducers.tokenClearState.type });
  };

export const createToken =
  (data: TCreateTokenType) => (dispatch: (arg: DispatchType) => void) => {
    console.log("createToken", data);
    return dispatch(
      apiCallBegan({
        url: url + "/create",
        data,
        method: "post",
        onStart: reducers.tokenCreating.type,
        onSuccess: reducers.tokenCreated.type,
        onError: reducers.tokenCreateFailed.type,
      })
    );
  };

export const validateToken =
  (data: ITokenValidateInputType) =>
  (dispatch: (arg: DispatchType) => void) => {
    console.log("tokenValidate", data);
    return dispatch(
      apiCallBegan({
        url: url + "/validate",
        data,
        method: "post",
        onStart: reducers.tokenValidating.type,
        onSuccess: reducers.tokenValidated.type,
        onError: reducers.tokenValidateFailed.type,
      })
    );
  };
