import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Table, { TableDataType } from "../ui/table";
import { businessURL } from "../../config/url";

interface TableProps {
  data: TableDataType;
  type: "service" | "provider" | "resource";
}

const TableNowServing: React.FC<TableProps> = ({ data, type }) => {
  const columns = [
    {
      name: "label",
      colWidth: "50px",
      colMaxWidth: "60px",
      content: (item: { label: string }) => <>{item.label}</>,
    },
    {
      name: "item",
      // cellClass: styles["col-item"],
      content: (item: { name: string }) => (
        <div className="h5">{item.name}</div>
      ),
    },
    {
      name: "view",
      colWidth: "80px",
      colMaxWidth: "80px",
      content: (item: { id: number }) => (
        <Link
          className="btn btn-link"
          to={`${businessURL.BusinessNowServingIndividual}/${type}/${item.id}`}
          target="_blank"
        >
          <span className="mr-3">View</span> <FaExternalLinkAlt />
        </Link>
      ),
    },
  ];
  return <Table data={data} columns={columns} className="mb-30" noHeader />;
};

export default TableNowServing;
