import { createSlice } from "@reduxjs/toolkit";
import { IProviderDataType } from "../../config/types/ProviderDataType";
import { ResourceType } from "../../config/types/ResourceType";
import { IServiceDataType } from "../../config/types/ServiceDataType";

interface WalkInState {
  loading: boolean;
  error: boolean | string;
  lastFetch?: Date | number;
  data: {
    services: IServiceDataType[];
    resources: ResourceType[];
    providers: IProviderDataType[];
  };
  register?: {
    loading?: boolean;
    code?: string;
    error?: string;
  };
}

const initialState: WalkInState = {
  loading: true,
  error: false,
  lastFetch: undefined,
  data: { services: [], resources: [], providers: [] },
  register: undefined,
};

const slice = createSlice({
  name: "walkIn",
  initialState,
  reducers: {
    walkInClearState: (walkIn) => {
      walkIn.error = false;
    },
    walkInRequest: (walkIn) => {
      walkIn.loading = true;
    },
    walkInReceived: (walkIn, action) => {
      console.log("walkInReceived", action.payload);
      walkIn.data = action.payload;
      walkIn.loading = false;
      walkIn.lastFetch = Date.now();
    },
    walkInRequestFailed: (walkIn, action) => {
      walkIn.loading = false;
      walkIn.error = action.payload;
    },
    walkInRegister: (walkIn) => {
      walkIn.register = { loading: true };
    },
    walkInRegistered: (walkIn, action) => {
      walkIn.register = { code: action.payload };
    },
    walkInRegisterFailed: (walkIn, action) => {
      walkIn.register = { error: action.payload };
    },
  },
});

export const {
  walkInClearState,
  walkInRequest,
  walkInReceived,
  walkInRequestFailed,
  walkInRegister,
  walkInRegistered,
  walkInRegisterFailed,
} = slice.actions;

export default slice.reducer;
