import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import useGetBookingType from "../../hooks/useGetBookingType";

const useBusinessServingSinglePage = (type: string, id: string) => {
  const {
    requestService,
    loadingResource,
    loadingProvider,
    services,
    resources,
    providers,
  } = useGetBookingType("none");
  const { loading: servingLoading, single: servingList } = useTypedSelector(
    (state) => state.entities.serving
  );
  const {
    getAdminServices,
    getAdminResources,
    getAdminProviders,
    getBusinessTodayBooking,
    getAllServing,
  } = useActions();

  useEffect(() => {
    if (type) {
      type === "service" && getAdminServices();
      // type === "resource" && getAdminResources();
      // type === "provider" && getAdminProviders();
      getBusinessTodayBooking();
      getAllServing();
    }
  }, [type]);

  const loading =
    (type === "service" && requestService?.loading) ||
    (type === "resource" && loadingResource) ||
    (type === "provider" && loadingProvider) ||
    servingLoading;

  function getEntity() {
    if (type === "service") {
      return services.find((m) => m._id === id);
    } else if (type === "resource") {
      return resources.find((m) => m._id === id);
    } else if (type === "provider") {
      return providers.find((m) => m._id === id);
    }
  }

  const entity = getEntity();

  return { loading, servingList, entity };
};

export default useBusinessServingSinglePage;
