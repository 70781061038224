const bookmarkFriends = [
  {
    _id: "bookmarkFriend1",
    // friend: getUser("user1"),
    friend: "user1",
    creator: "user1",
    displayName: "First Ipsum is simply dummy te",
    email: "test1@gmail.com",
    profilePic: "https://picsum.photos/id/890/200/200",
  },
  {
    _id: "bookmarkFriend2",
    // friend: getUser("user2"),
    friend: "user2",
    creator: "user1",
    displayName: "Second Ipsum is simply dummy text of the printing",
    email: "test2@gmail.com",
    profilePic: "https://picsum.photos/id/891/200/200",
  },
  {
    _id: "bookmarkFriend3",
    // friend: getUser("user3"),
    friend: "user3",
    creator: "user1",
    displayName: "Third User",
    email: "user3@gmail.com",
    profilePic: "https://picsum.photos/id/891/200/200",
  },
  {
    _id: "bookmarkFriend4",
    // friend: getUser("user3"),
    friend: "user4",
    creator: "user1",
    displayName: "4th User",
    profilePic: "https://picsum.photos/id/891/200/200",
    email: "test2@gmail.com",
  },
];

export function getBookmarkFriends() {
  return bookmarkFriends;
  // return [];
}
