import { EBookingStatusValue } from "../config/types/BookingStatusType";
import { getDate } from "./datetime";

export function bookingDateTime(meetDateTime: Date) {
  // const today = new Date();
  // const expiryDate = new Date(meetDateTime);
  // expiryDate.setMonth(expiryDate.getMonth() + 6);
  const dateTime = {
    meetDateTime,
    meetDateTimestamp: meetDateTime.getTime(),
    meetDateTimeData: {
      year: meetDateTime.getFullYear(),
      month: meetDateTime.getMonth() + 1,
      date: meetDateTime.getDate(),
      day: meetDateTime.getDay(),
      hour: meetDateTime.getHours(),
      minute: meetDateTime.getMinutes(),
    },
    // createDateTime: today,
    // createDateTimestamp: today.getTime(),
    // createDateTimeData: {
    //   year: today.getFullYear(),
    //   month: today.getMonth() + 1,
    //   date: today.getDate(),
    //   day: today.getDay(),
    //   hour: today.getHours(),
    //   minute: today.getMinutes(),
    // },
    meetMonth: getDate(meetDateTime, "YYYY MM", true),
    meetDate: getDate(meetDateTime, "YYYY MM DD", true),
    // timezoneOffset: new Date().getTimezoneOffset(),

    // expiryDate: getDate(expiryDate, "YYYY MM DD", true),
    // expiryDateTime: expiryDate,
    // expiryDateTimestamp: expiryDate.getTime(),
  };
  return dateTime;
}

export function bookingLog({
  bookingStatus,
  creatorName,
}: {
  bookingStatus: number;
  creatorName: string;
}) {
  if (bookingStatus === EBookingStatusValue.create)
    return {
      bookingStatus,
      creatorName,
      title: "Created",
      desc: `${creatorName} created a meetup`,
      priority: 2,
    };
  else if (
    bookingStatus === EBookingStatusValue.book ||
    bookingStatus === EBookingStatusValue.createByBusiness
  )
    return {
      bookingStatus,
      creatorName,
      title: "Booked",
      desc: `${creatorName} made a booking`,
      priority: 2,
    };
  else if (bookingStatus === 121)
    return {
      bookingStatus,
      creatorName,
      title: "Skipped",
      priority: 4,
    };
  else if (bookingStatus === 201 || bookingStatus === 202)
    return {
      bookingStatus,
      creatorName,
      title: "Approved",
      desc: `${creatorName} approved`,
      priority: 3,
    };
  else if (bookingStatus === 211)
    return {
      bookingStatus,
      creatorName,
      title: "Confirmed",
      desc: `${creatorName} confirmed attend`,
      priority: 3,
    };
  else if (bookingStatus === 301)
    return {
      bookingStatus,
      creatorName,
      title: "Checked-in",
      desc: `${creatorName} checked-in`,
      priority: 3,
    };
  else if (bookingStatus === 321)
    return {
      bookingStatus,
      creatorName,
      title: "Serving",
      desc: `Started serving`,
      priority: 2,
    };
  else if (bookingStatus === 401)
    return {
      bookingStatus,
      creatorName,
      title: "Completed",
      desc: `Service has completed`,
      priority: 2,
    };
  else if (bookingStatus === 402)
    return {
      bookingStatus,
      creatorName,
      title: "Ended",
      desc: `Service has ended`,
      priority: 3,
    };
  else if (bookingStatus === 411)
    return {
      bookingStatus,
      creatorName,
      title: "Rescheduled",
      desc: `Booking has rescheduled`,
      priority: 3,
    };
  else if (bookingStatus === 412)
    return {
      bookingStatus,
      creatorName,
      title: "Transferred",
      desc: `Booking has transferred`,
      priority: 3,
    };
  else if (bookingStatus === 501)
    return {
      bookingStatus,
      creatorName,
      title: "Cancelled",
      desc: `Booking has cancelled`,
      priority: 3,
    };
  else if (bookingStatus === 512)
    return {
      bookingStatus,
      creatorName,
      title: "Not attend",
      desc: `${creatorName} not attend the meetup`,
      priority: 3,
    };
  else if (bookingStatus === 511)
    return {
      bookingStatus,
      creatorName,
      title: "No show",
      desc: `Customer no show`,
      priority: 3,
    };
  else if (bookingStatus === 521)
    return {
      bookingStatus,
      creatorName,
      title: "Rejected",
      desc: `${creatorName} rejected`,
      priority: 3,
    };
}

export const getMeetupStatus = ({
  meetupStatus,
  participantStatus,
  meetDateTime,
}: {
  meetupStatus: number;
  participantStatus: number;
  meetDateTime: Date;
}) => {
  // TODO: handlemeetDateTime
  if (meetupStatus < EBookingStatusValue.complete) {
    return participantStatus;
  }
  return meetupStatus;
};

export const queueNumber = (bookingNo: number, label: string) => {
  return `${bookingNo.toString().padStart(4, "0")} ${label}`;
};
